import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import alt from '../alt';
import Auth from '../auth/Auth';
import { successToast, alertToast } from '../components/Toast';

import { CANDIDATES_STATUS } from '../utils/constants';
import {
  appendCharS,
  mxTrackEvent,
  removeWhiteSpaceForStringElement,
} from '../utils/utils';
import CommonApiCalls from '../utils/CommonApiCalls';
import config from '../config/config';

class CandidateActions {
  constructor() {
    this.setAutoPlay = () => true;
    this.gettingCandidates = () => true;
    this.gettingCandidatesForWidgit = () => true;
    this.setDataLoading = bool => bool;
    this.getCandidatesSuccess = data => data;
    this.getSingleCandidatesSuccess = data => data;
    this.getCandidatesFail = msg => msg;
    this.gettingVideoUrl = () => true;
    this.getVideoUrlFail = errMsg => errMsg;
    this.getVideoUrlSuccess = data => data;
    this.setVideoCache = data => data;
    this.updateIndex = data => data;
    this.shortlistCandidateUpdate = (candidateId, value) => ({ candidateId, value });
    this.hiredCandidateUpdate = (candidateId, value) => ({ candidateId, value });
    this.kivCandidateUpdate = (candidateId, value) => ({ candidateId, value });
    this.unrejectCandidatesSuccess = candidateIds => candidateIds;
    this.likeCandidateUpdate = (userId, candidateId, value) => ({ userId, candidateId, value });
    this.dislikeCandidateUpdate = (userId, candidateId, value) => ({ userId, candidateId, value });
    this.changeSliderValue = (value, key) => ({ key, value });
    this.changeScoreParams = (type, key, value) => ({ type, key, value });
    this.toggleSortFilter = () => true;
    this.changeRejectedCheckbox = e => e.target.checked;
    this.changeShowShortlistedCheckbox = e => e.target.checked; //
    this.setShowShortlistedCandidate = data => data; // SET_SHOW_SHORTLISTED_CANDIDATE
    this.changeShowHiredSelected = data => data;
    this.changeStartDateFilter = (selectedDates, dateStr, instance) => (
      { selectedDates, dateStr, instance });
    this.changeEndDateFilter = (selectedDates, dateStr, instance) => (
      { selectedDates, dateStr, instance });
    this.changeAdditionalFormFieldFilter = (question, answer) => ({ question, answer });
    this.changeKeywordFilter = keywords => keywords;
    this.resetFilters = () => true;
    this.changeLocationFilter = e => e.target.value;
    this.updateCandidateScheduleInterviewDate = data => data;
    this.updateCandidateCalendlyScheduleInterviewDate = data => data;
    this.rejectingCandidate = () => true;
    this.rejectCandidatesSuccess = data => data;
    this.rejectCandidateFail = () => true;
    this.openRejectCandidateModal = () => true;
    this.closeRejectCandidateModal = () => true;
    this.candidateAssessmentUpdated = (candidateId, assessmentScore) => ({
      candidateId,
      assessmentScore,
    });
    this.updateDisplayDataForCandidateViewed = index => index;
    this.collapseList = () => true;
    this.resetScoreSettings = () => true;
    this.setStarredProfile = () => true;

    /* add candidate screen actions */
    this.addNewCandidateNameChange = e => e.target.value;
    this.addNewCandidateEmailChange = e => e.target.value;
    this.addNewCandidatePhoneChange = e => e.target.value;
    this.submittingNewCandidate = () => true;
    this.submitNewCandidateSuccess = data => data;
    this.submitNewCandidateFailure = () => true;
    this.cancelAddNewCandidate = () => true;
    this.addNewCandidate = () => true;
    this.removingMultipleCandidates = () => true;
    this.removeMultipleCandidatesSuccess = deletedCandidatesList => deletedCandidatesList;
    this.removeMultipleCandidatesFailure = () => true;
    this.changeAllCandidateCheckbox = e => e.target.checked;
    this.changeCandidateCheckbox = (candidateId, event) => ({ candidateId, event });
    this.removingCandidateFromList = index => index;
    this.removeCandidateFromListSuccess = candidateId => candidateId;
    this.removeCandidateFromListFailure = () => true;
    this.sendingReminderEmail = () => true;
    this.sendReminderEmailSuccess = () => true;
    this.sendReminderEmailFailure = () => true;
    this.updateCandidateToSent = canSendCandidates => canSendCandidates;
    this.applyFilter = () => true;
    this.changeShowRespondedStatusCheckbox = e => e.target.checked;
    this.changeShowNewStatusCheckbox = e => e.target.checked;
    this.changeShowKivCheckbox = e => e.target.checked;
    this.changeCandidatesView = e => e.target.value;
    this.eventTypeData = eventData => eventData;
    this.chosenEventTypeUrl = url => url;
    this.setCurrentNameAttribute = name => name;
    this.sortCandidates = data => ({ ...data });
    this.changeResumeScoreWeightage = data => data;
    this.changeWorkmapScoreWeightage = data => data;
    this.changeVideoScoreWeightage = data => data;
    this.handleSaveScoreSettings = () => true;

    /* edit candidate details */
    this.editingCandidateDetails = () => true;
    this.editCandidateDetailsSuccess = data => data;
    this.editCandidateDetailsFailure = () => true;

    /** candidate listing filters slider */
    this.changeWorkmapScoreSlider = data => data;
    this.changeResumeScoreSlider = data => data;
    this.changeVideoScoreSlider = data => data;
    this.setWorkmapScoreParameter = data => data;
    // this.changeWorkmapFilterCheckbox = data => data;
    this.checkWorkmapFilterParameter = data => data;
    this.checkResumeScoreFilterParameter = data => data;
    this.checkVideoScoreFilterParameter = data => data;
    /* AI video filters slider */
    this.changeVideoProfessionalismValue = data => data;
    this.changeVideoContentRelevancyValue = data => data;
    this.changeVideoSociabilityValue = data => data;
    this.changeVideoCommunicationValue = data => data;
    this.changeVideoPositiveAttitudeValue = data => data;
    this.changeIsSavingScoreSettings = data => data;
    this.setCandidateDomainScoreSuccess = data => data;
    this.setCandidateDomainScoreFailure = () => true;
    this.setUserNotAuthFromAIA = data => data;
    this.setCandidateDetailsLoader = data => data;
    this.updateWatchedVideoList = data => data;
    this.setRecentResponses = data => data;
    this.setFunnelCount = data => data;
    this.setWeeklyReportCounts = data => data;
    this.setFetchRecentResponsesProcessing = data => data;
    this.setRecentResponsesCount = data => data;
    this.setIsInterviewCreated = data => data;
    this.setSampleCandidatesViewed = data => data;
    this.setClickedOnDownload = value => value;
    this.setSearchCandidate = value => value;
    this.setSearchedCandidateText = value => value;
    this.setSearchedCandidateListingText = value => value;
    this.setSearchedIconClicked = value => value;
    this.setSearchedIconClickedOnDetails = value => value;
    this.setUpdatedCandidateResponse = value => value;
    this.setCandidateIndexes = value => value;
    this.setNewCandidateSuccess = data => data;
    this.setFetchingNewCandidate = value => value;
    this.setFetchingCandidateDetailsOnClick = value => value;
    this.setApplicationstatus = value => value;
    this.setFetchingCandidateIndexes = (candidateId, value) => ({ candidateId, value });
    this.setCurrentPage = value => value;
    this.changeVideoPaceAndClarityValue = data => data;
    this.changeVideoArticulationValue = data => data;
    this.changeVideoSentimentsValue = data => data;
    this.changeVideoGrammarValue = data => data;
    this.changeSociabilitySentimentValue = data => data;
    this.changeSociabilityEnergyValue = data => data;
    this.changeSociabilityEmotionValue = data => data;
    this.changeProfessionalismDressingValue = data => data;
    this.changeProfessionalismGroomingValue = data => data;
    this.changeProfessionalismBodyLanguageValue = data => data;
    this.fetchCountrySuccess = value => value;
    this.setCountryId = value => value;
    this.removeSelectedCountry = obj => obj;
    this.selectFilterCandidateStatus = value => value;
    this.removeSelectedStatus = value => value;
    this.selectFilterRecruiterStatus = value => value;
    this.removeSelectedRecruiterStatus = value => value;
    this.setFilterIntroDropdown = data => data;
    this.setSelectedIntroQuestionsOptions = (dropdownValue, checkBoxValue) => ({ dropdownValue, checkBoxValue }); //eslint-disable-line
    this.setResetFilters = () => true;
    this.setAdditionalIntroQuestion = value => value;
    this.setFilterIconClicked = value => value;
    this.setActivePanel = value => value;
    this.resetCandidateAndRecruiterStatus = () => true;
    this.removeSelectedIntroQuestionChoice = (question, choice) => ({ question, choice });
    this.setSearchedCandidateNameOnDetails = value => value;
    this.setOverviewMessageObject = value => value;
    this.setCandidateSharedToList = data => data;
    this.setCandidateFiltersOpen = bool => bool;
    this.selectFilterRelevancyScore = value => value;
    this.fetchCandidateEmailInviteCount = value => value;
    this.setStartCandidateBulkDownload = value => value;
    this.setCandidateAddLimit = value => value;
    this.closeAddCandidateModal = () => true;
  }


  async changeFilterIntroQuestion(e) {
    this.setFilterIntroDropdown(e.target.value);
  }

  // eslint-disable-next-line class-methods-use-this
  async shareCandidatesList(candidates, emails, listName, interviewId, interviewName,
    language, accessCode, emailSubject, note = null, removeBranding, isListNameShow) {
    try {
      const req = await fetch('/api/candidate/share-list', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          emails,
          sharer: Auth.getProfileInfo('firstName') || Auth.getProfileInfo('email'),
          sharerUserId: Auth.getProfileInfo('userId'),
          candidates: candidates.map(candidate => candidate.candidateid),
          listName,
          interviewId,
          interviewName,
          language,
          accessCode,
          emailSubject,
          candidateCount: candidates.length,
          candidateData: candidates.map((candidate) => {
            const data = {};
            data.thumbnail = candidate.img || null;
            data.candidateName = candidate.name || null;
            data.summary = (candidate.metadata && candidate.metadata.candidate_summary ? candidate.metadata.candidate_summary : '').slice(0, 400);
            data.candidateLink = `${config.default.DOMAIN}/interview/${interviewId}/${candidate.candidateid}/candidates`;
            return data;
          }),
          note,
          removeBranding,
          isListNameShow,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        successToast('Candidates list shared via emails');
        mxTrackEvent('Candidates list shared via emails');
      } else {
        throw new Error(req.statusText);
      }
    } catch (err) {
      alertToast('Failed to share candidates list via emails');
      mxTrackEvent('Failed to share candidates list', {
        userId: Auth.getProfileInfo('userId'),
      });
      console.log(err);
      console.error(err.toString());
    }
  }

  async getCandidates(
    interviewId,
    isCandidateDetailScreen = false,
    defaultCandidateEmail,
    start,
    end,
    applicationstatus,
    countries = [],
    selectCandidateStatusToFilter = [],
    selectRecruiterStatusToFilter = [],
    filterStartDate = null,
    filterEndDate = null,
    introQuestionDpObject = {},
    filterButtonClicked = false,
    additionalQuestionsType = {},
    selectedSortingButton = '',
    sortingName = '',
  ) {
    this.gettingCandidates();
    if (filterButtonClicked) {
      this.setFilterIconClicked(true);
      this.setSearchCandidate(true);
    }
    const areAllEmptyArrays = Object.values(introQuestionDpObject).every(arr => Array.isArray(arr)
      && arr.length === 0);
    const filterConditions = {
      searchText: '',
      startDate: filterStartDate,
      endDate: filterEndDate,
      country: countries,
      candidateStatus: selectCandidateStatusToFilter,
      recruiterStatus: selectRecruiterStatusToFilter,
      introQuestions: !areAllEmptyArrays ? introQuestionDpObject : [],
      additionalQuestionsType,
      sortingOrder: selectedSortingButton,
      sortingBy: sortingName,
    };
    try {
      const req = await fetch(`/api/interview/${interviewId}/candidates?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          filterConditions,
          applicationstatus,
        }),
      });
      if (req.status >= 200 && req.status <= 304) {
        const res = await req.json();
        // Update candidateData if each candidate is eligible for Calendly workflow
        const [newCandidateData, calendlyToken] = await CommonApiCalls
          .getCalendlyEligibleCandidatesAndToken(
            interviewId, res.candidates,
          );

        if (calendlyToken) {
          await this.getCalendlyEventTypes(calendlyToken);
        }

        this.setCandidateIndexes(res.candidateIndexes);

        this.getCandidatesSuccess({
          data: { ...res, candidates: newCandidateData },
          interviewId,
          isCandidateDetailScreen,
          defaultCandidateEmail,
          orderCandidates: true,
          hired: newCandidateData.filter(x => x.hired),
          totalCandidates: res.totalCandidates,

        });
        if (defaultCandidateEmail && newCandidateData && newCandidateData.length > 0) {
          let index = newCandidateData.findIndex(
            candidate => candidate.email === defaultCandidateEmail,
          );
          if (index === -1) {
            index = 0;
          }
          const candidateQuestions = newCandidateData[index].questions;
          this.getVideoUrl(interviewId, index, 0, newCandidateData, candidateQuestions, null);
        }
        mxTrackEvent('Get candidates successful', {
          interviewId,
        });
      } else if (req.status === 401) {
        Auth.signOut();
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setSearchCandidate(false);
    }

    return true;
  }

  async getSingleCandidate(
    interviewId,
    isCandidateDetailScreen = false,
    defaultCandidateEmail,
    candidateId,
  ) {
    this.gettingCandidates();
    try {
      const req = await fetch(`/signUpApi/interview/${interviewId}/candidate/${candidateId}`, {
        method: 'GET',
        // headers: {
        //   Authorization: `Bearer ${Auth.getIdToken()}`,
        // },
      });
      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        // Update candidateData if each candidate is eligible for Calendly workflow
        // const [newCandidateData, calendlyToken] = await CommonApiCalls
        //   .getCalendlyEligibleCandidatesAndToken(
        //     interviewId, res.candidates,
        //   );

        // if (calendlyToken) {
        //   await this.getCalendlyEventTypes(calendlyToken);
        // }

        this.getSingleCandidatesSuccess({
          data: { ...res },
          interviewId,
          isCandidateDetailScreen,
          defaultCandidateEmail,
          orderCandidates: true,
          // hired: newCandidateData.filter(x => x.hired),
        });
        // if (defaultCandidateEmail && newCandidateData && newCandidateData.length > 0) {
        //   let index = newCandidateData.findIndex(
        //     candidate => candidate.email === defaultCandidateEmail,
        //   );
        //   if (index === -1) {
        //     index = 0;
        //   }
        //   const candidateQuestions = newCandidateData[index].questions;
        //   this.getVideoUrl(interviewId, index, 0, newCandidateData, candidateQuestions, null);
        // }
        mxTrackEvent('Get candidates successful', {
          interviewId,
        });
      } else if (req.status === 401) {
        this.setUserNotAuthFromAIA(true);
        this.setCandidateDetailsLoader(false);
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setFetchingNewCandidate(false);
    }
    return true;
  }

  async getCandidatesListing(
    interviewId,
    candidateId,
    start,
    end,
    countries = [],
    selectCandidateStatusToFilter = [],
    selectRecruiterStatusToFilter = [],
    filterStartDate = null,
    filterEndDate = null,
    introQuestionDpObject = {},
    additionalQuestionsType = {},
  ) {
    this.setFetchingNewCandidate(true);
    const areAllEmptyArrays = Object.values(introQuestionDpObject).every(arr => Array.isArray(arr)
      && arr.length === 0);
    const filterConditions = {
      searchText: '',
      startDate: filterStartDate,
      endDate: filterEndDate,
      country: countries,
      candidateStatus: selectCandidateStatusToFilter,
      recruiterStatus: selectRecruiterStatusToFilter,
      introQuestions: !areAllEmptyArrays ? introQuestionDpObject : [],
      additionalQuestionsType,
    };
    try {
      const req = await fetch(`/api/candidatelisting/${interviewId}/candidates?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'PATCH',
        body: JSON.stringify({
          filterConditions,
        }),
      });
      if (req.status >= 200 && req.status <= 304) {
        const res = await req.json();
        this.setNewCandidateSuccess({
          data: { ...res },
          interviewId,
          // isCandidateDetailScreen,
          // defaultCandidateEmail,
          orderCandidates: true,
          // hired: newCandidateData.filter(x => x.hired),
          totalCandidates: res.totalCandidates,
        });
      } else if (req.status === 401) {
        Auth.signOut();
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setFetchingNewCandidate(false);
    }

    return true;
  }

  async getCandidateDetail(
    interviewId,
    candidateId,
    isCandidateDetailScreen = false,
    defaultCandidateEmail,
    onClickingCandidate,
  ) {
    if (onClickingCandidate) {
      this.setFetchingCandidateDetailsOnClick(true);
    } else {
      this.gettingCandidates();
    }
    try {
      const req = await fetch(`/api/candidatedetail/${interviewId}/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      if (req.status >= 200 && req.status <= 304) {
        const res = await req.json();
        // Update candidateData if each candidate is eligible for Calendly workflow
        const [newCandidateData, calendlyToken] = await CommonApiCalls
          .getCalendlyEligibleCandidatesAndToken(
            interviewId, res.candidates,
          );

        if (calendlyToken) {
          await this.getCalendlyEventTypes(calendlyToken);
        }

        this.getCandidatesSuccess({
          data: { ...res, candidates: newCandidateData },
          interviewId,
          isCandidateDetailScreen,
          defaultCandidateEmail,
          orderCandidates: true,
          hired: newCandidateData.filter(x => x.hired),
          totalCandidates: res.totalCandidates,

        });
        this.setCandidateSharedToList(res.candidateSharedWith);
        if (candidateId && newCandidateData && newCandidateData.length > 0) {
          let index = newCandidateData.findIndex(
            candidate => candidate.candidateid === candidateId,
          );
          if (index === -1) {
            index = 0;
          }
          const candidateQuestions = newCandidateData[index].questions;
          this.getVideoUrl(interviewId, index, 0, newCandidateData, candidateQuestions, null);
        }
        mxTrackEvent('Get candidates successful', {
          interviewId,
        });
      } else if (req.status === 401) {
        Auth.signOut();
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setFetchingCandidateDetailsOnClick(false);
    }

    return true;
  }

  async getVideoUrl(
    interviewId,
    candidateIndex,
    questionIndex,
    data,
    questions,
    videoCache,
  ) {
    this.gettingVideoUrl();
    if (!data || !data.length) {
      this.getVideoUrlFail('Sorry, there aren\'t any candidates that match your filters.');
      return false;
    }

    if (!questions || !questions.length) {
      if (data[candidateIndex].status === 'New') {
        if (data[candidateIndex].candidateapply && data[candidateIndex].dateresponded === null) {
          this.getVideoUrlFail('Candidate not responded to the interview as yet.');
        } else {
          this.getVideoUrlFail('Email invitation for the interview not sent.');
        }
      } else {
        this.getVideoUrlFail('Candidate not responded to the interview as yet.');
      }
      return false;
    }

    const candidateId = data[candidateIndex].candidateid;
    const candidateSlug = data[candidateIndex].slug;
    const candidateName = data[candidateIndex].name;
    const questionId = questions[questionIndex].questionid;
    const candidateStatus = data[candidateIndex].status.toUpperCase();
    const candidateEmail = data[candidateIndex].email;

    // if candidate status not responded yet, just set status to fail and return
    if (candidateStatus !== CANDIDATES_STATUS.EVALUATED
      && candidateStatus !== CANDIDATES_STATUS.RESPONDED) {
      console.log('candidate not responded / evaluated yet');
      this.getVideoUrlFail('Candidate has not responded or evaluated yet');
      return false;
    }

    try {
      // get from cache if being set and still within cache time (30 mins)
      if (videoCache
        && videoCache[candidateId]
        && videoCache[candidateId][questionId]
        && (new Date() - new Date(videoCache[candidateId][questionId].cacheTime)) / 1000 <= 1800) {
        console.log(`Getting cache for candidateId: ${candidateId}, questionId: ${questionId}`);
        this.getVideoUrlSuccess({
          videoUrl: videoCache[candidateId][questionId].signedUrl,
          videoCaption: videoCache[candidateId][questionId].captionUrl,
        });
      } else {
        const questionIds = questions.map(question => question.questionid);
        const queryParamsForQuestionIds = questionIds.join('&questionIds=');
        console.log(`Getting presigned URL for candidateId: ${candidateId}`);
        const req = await fetch(`/api/getS3VideoPresignedUrl?interviewId=${interviewId}&candidateId=${candidateId}&candidateSlug=${candidateSlug}&candidateStatus=${candidateStatus}&questionIds=${queryParamsForQuestionIds}&email=${candidateEmail}`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
        });
        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          console.log(`Setting video url and cache for candidateId: ${candidateId}`);
          this.getVideoUrlSuccess({
            videoUrl: res[questionId].signedUrl,
            videoCaption: res[questionId].captionUrl,
          });
          this.setVideoCache({
            candidateId,
            videoCache: res,
          });
        } else {
          throw new Error(req.statusText);
        }
      }
    } catch (e) {
      console.log(e);
      this.getVideoUrlFail(`Failed to retrieve video for ${candidateName}`);
      return '';
    }

    return true;
  }

  async getVideoUrlForAIA(
    interviewId,
    candidateIndex,
    questionIndex,
    data,
    questions,
    videoCache,
  ) {
    this.gettingVideoUrl();
    if (!data || !data.length) {
      this.getVideoUrlFail('Sorry, there aren\'t any candidates that match your filters.');
      return false;
    }

    if (!questions || !questions.length) {
      if (data[candidateIndex].status === 'New') {
        if (data[candidateIndex].candidateapply && data[candidateIndex].dateresponded === null) {
          this.getVideoUrlFail('Candidate not responded to the interview as yet.');
        } else {
          this.getVideoUrlFail('Email invitation for the interview not sent.');
        }
      } else {
        this.getVideoUrlFail('Candidate not responded to the interview as yet.');
      }
      return false;
    }

    const candidateId = data[candidateIndex].candidateid;
    const candidateSlug = data[candidateIndex].slug;
    const candidateName = data[candidateIndex].name;
    const questionId = questions[questionIndex].questionid;
    const candidateStatus = data[candidateIndex].status.toUpperCase();
    const candidateEmail = data[candidateIndex].email;

    // if candidate status not responded yet, just set status to fail and return
    if (candidateStatus !== CANDIDATES_STATUS.EVALUATED
      && candidateStatus !== CANDIDATES_STATUS.RESPONDED) {
      console.log('candidate not responded / evaluated yet');
      this.getVideoUrlFail('Candidate has not responded or evaluated yet');
      return false;
    }

    try {
      // get from cache if being set and still within cache time (30 mins)
      if (videoCache
        && videoCache[candidateId]
        && videoCache[candidateId][questionId]
        && (new Date() - new Date(videoCache[candidateId][questionId].cacheTime)) / 1000 <= 1800) {
        console.log(`Getting cache for candidateId: ${candidateId}, questionId: ${questionId}`);
        this.getVideoUrlSuccess({
          videoUrl: videoCache[candidateId][questionId].signedUrl,
          videoCaption: videoCache[candidateId][questionId].captionUrl,
        });
      } else {
        const questionIds = questions.map(question => question.questionid);
        const queryParamsForQuestionIds = questionIds.join('&questionIds=');
        console.log(`Getting presigned URL for candidateId: ${candidateId}`);
        const req = await fetch(`/shareApi/getS3VideoPresignedUrl?interviewId=${interviewId}&candidateId=${candidateId}&candidateSlug=${candidateSlug}&candidateStatus=${candidateStatus}&questionIds=${queryParamsForQuestionIds}&email=${candidateEmail}`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
        });
        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          this.getVideoUrlSuccess({
            videoUrl: res[questionId].signedUrl,
            videoCaption: res[questionId].captionUrl,
          });
          this.setVideoCache({
            candidateId,
            videoCache: res,
          });
        } else {
          throw new Error(req.statusText);
        }
      }
    } catch (e) {
      console.log(e);
      this.getVideoUrlFail(`Failed to retrieve video for ${candidateName}`);
      return '';
    }

    return true;
  }

  async shortlistCandidate(interviewId, candidateId, value) {
    const updateValue = !value;
    try {
      this.shortlistCandidateUpdate(candidateId, updateValue);
      this.kivCandidateUpdate(candidateId, false);
      this.unrejectCandidatesSuccess([candidateId]);
      this.hiredCandidateUpdate(candidateId, false);
      const req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/shortlist`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          value: updateValue,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        if (updateValue) {
          successToast('Candidate shortlisted');
          mxTrackEvent('Candidate shortlisted');
        } else {
          successToast('Candidate removed from shortlist');
          mxTrackEvent('Candidate removed from shortlist');
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      if (updateValue) {
        alertToast('Unable to shortlist candidate :( Please try again later');
        mxTrackEvent('Shortlist candidate failed');
      } else {
        alertToast('Unable to remove candidate from shortlist :( Please try again later');
        mxTrackEvent('Remove candidate from shortlist failed');
      }
      this.shortlistCandidateUpdate(candidateId, value);
      this.kivCandidateUpdate(candidateId, false);
      this.hiredCandidateUpdate(candidateId, false);
    }
    return true;
  }

  // hire candidate
  async hireCandidate(interviewId, candidateId, value) {
    const updateValue = !value;
    try {
      this.hiredCandidateUpdate(candidateId, updateValue);
      this.kivCandidateUpdate(candidateId, false);
      this.shortlistCandidateUpdate(candidateId, false);
      this.unrejectCandidatesSuccess([candidateId]);
      const req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/hire`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          value: updateValue,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        if (updateValue) {
          successToast('Candidate Hired');
          mxTrackEvent('Candidate Hired');
        } else {
          successToast('Candidate removed from Hired');
          mxTrackEvent('Candidate removed from Hired');
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      if (updateValue) {
        alertToast('Unable to Hire a candidate :( Please try again later');
        mxTrackEvent('Hire candidate failed');
      } else {
        alertToast('Unable to remove candidate from hired stage :( Please try again later');
        mxTrackEvent('Remove candidate from hired failed');
      }
      this.hiredCandidateUpdate(candidateId, value);
      this.kivCandidateUpdate(candidateId, false);
      this.shortlistCandidateUpdate(candidateId, false);
    }
    return true;
  }

  async kivCandidate(interviewId, candidateId, value) {
    const updateValue = !value;
    try {
      this.kivCandidateUpdate(candidateId, updateValue);
      this.shortlistCandidateUpdate(candidateId, false);
      this.unrejectCandidatesSuccess([candidateId]);
      this.hiredCandidateUpdate(candidateId, false);
      const req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/kiv`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PUT',
        body: JSON.stringify({
          value: updateValue,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        if (updateValue) {
          successToast('Candidate KIVed');
          mxTrackEvent('Candidate KIVed');
        } else {
          successToast('Candidate removed from KIV');
          mxTrackEvent('Candidate removed from KIV');
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      if (updateValue) {
        alertToast('Unable to KIV candidate :( Please try again later');
        mxTrackEvent('KIV candidate failed');
      } else {
        alertToast('Unable to remove candidate from KIV :( Please try again later');
        mxTrackEvent('Remove candidate from KIV failed');
      }
      this.kivCandidateUpdate(candidateId, value);
      this.shortlistCandidateUpdate(candidateId, false);
      this.hiredCandidateUpdate(candidateId, false);
    }
    return true;
  }

  async likeCandidate(userId, interviewId, candidateId, previousValue) {
    const updateValue = !previousValue;
    try {
      this.likeCandidateUpdate(userId, candidateId, updateValue);
      let req = null;
      if (previousValue) { // candidate previously liked, now revert the changes
        req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/like`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
          method: 'delete',
        });
      } else {
        req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/like`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'post',
          body: JSON.stringify({
            action: 1,
          }),
        });
      }

      if (req.status >= 200 && req.status < 300) {
        if (previousValue) {
          mxTrackEvent('Reverted liked candidate');
        } else {
          mxTrackEvent('Liked candidate');
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      if (previousValue) {
        mxTrackEvent('Reverted liked candidate failed');
      } else {
        mxTrackEvent('Liked candidate failed');
      }
      this.likeCandidateUpdate(userId, candidateId, previousValue);
    }

    return true;
  }

  async dislikeCandidate(userId, interviewId, candidateId, previousValue) {
    const updateValue = !previousValue;
    try {
      this.dislikeCandidateUpdate(userId, candidateId, updateValue);
      let req = null;
      if (previousValue) { // candidate previously disliked, now revert the changes
        req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/like`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
          method: 'delete',
        });
      } else {
        req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}/like`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'post',
          body: JSON.stringify({
            action: 2,
          }),
        });
      }

      if (req.status >= 200 && req.status < 300) {
        if (previousValue) {
          mxTrackEvent('Reverted disliked candidate');
        } else {
          mxTrackEvent('Disliked candidate');
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      if (previousValue) {
        mxTrackEvent('Reverted disliked candidate failed');
      } else {
        mxTrackEvent('Disliked candidate failed');
      }
      this.dislikeCandidateUpdate(userId, candidateId, previousValue);
    }

    return true;
  }

  async rejectCandidates(
    candidates, interviewId,
    interviewName, emailData,
    isEmailRequired, interviewLanguage, removeBranding,
  ) {
    this.rejectingCandidate();
    if (candidates && candidates.length === 0) {
      return;
    }
    const candidateIds = candidates.map(c => c.candidateid);
    candidateIds.forEach((iterator) => {
      this.kivCandidateUpdate(iterator, false);
      this.shortlistCandidateUpdate(iterator, false);
      this.hiredCandidateUpdate(iterator, false);
    });
    try {
      const req = await fetch('/api/candidate/applications/reject', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidates,
          emailData,
          interviewId,
          interviewName,
          isEmailRequired,
          language: interviewLanguage,
          removeBranding,
        }),
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        const { errors } = res;
        if (errors && errors.length > 0) {
          alertToast(`Error in rejecting ${errors.join(', ')} candidate(s). Please try again later.`);
          mxTrackEvent('Reject candidates failure', {
            candidateNames: candidateIds.toString(),
            interviewId,
          });
          this.rejectCandidateFail();
          return;
        }
        successToast(`Successfully rejected ${candidates.length} candidate(s)`);
        mxTrackEvent('Rejected candidates', { candidateIds: candidateIds.toString(), interviewId });
        this.rejectCandidatesSuccess(candidateIds);
      } else {
        throw new Error(req.statusText);
      }
    } catch (error) {
      console.error(error);
      alertToast('Error in rejecting candidates. Please try again later.');
      mxTrackEvent('Reject candidates failure', {
        candidateIds: candidateIds.toString(),
        interviewId,
        error: error.toString(),
      });
      this.rejectCandidateFail();
    }
  }

  async updateCandidateViewed(candidateIndex, interviewId, candidate) {
    if (!candidate.viewed) {
      const candidateId = candidate.candidateid;
      try {
        const req = await fetch(`/api/interview/${interviewId}/updateCandidateViewed`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            candidateId,
          }),
        });

        if (req.status < 200 && req.status >= 300) {
          throw new Error(req.statusText);
        }

        mxTrackEvent('Update candidates viewed success', {
          interviewId,
          candidateId,
        });

        this.updateDisplayDataForCandidateViewed(candidateIndex);
      } catch (e) {
        console.log(e);
        mxTrackEvent('Update candidates viewed failure', {
          interviewId,
          candidateId,
        });
      }
    }
  }

  async submitNewCandidate(interviewId, newCandidates, candidatesList,
    interviewName, coachingCandidateIdLabel) {
    const numOfCandidates = newCandidates.length;
    if (candidatesList && candidatesList.length > 0) {
      const duplicateCandidates = newCandidates.filter(newCandidate => candidatesList.find(
        existingCandidate => newCandidate.email === existingCandidate.email,
      ));
      if (duplicateCandidates.length > 0) {
        alertToast(`Candidates are already in the invitation list. Please try again
          ${duplicateCandidates.map(candidate => `\n${candidate.email}`)}
        `, { autoClose: 6000 });
        return;
      }

      if (coachingCandidateIdLabel) {
        const duplicateCandidateUniqueId = newCandidates.filter(newCandidate => candidatesList.find(
          existingCandidate => newCandidate.coaching_candidate_id === existingCandidate.coaching_candidate_id, // eslint-disable-line
        ));
        if (duplicateCandidateUniqueId.length > 0) {
          alertToast(`${coachingCandidateIdLabel} : ${duplicateCandidateUniqueId[0].coaching_candidate_id} 
           is already exists in the list
        `, { autoClose: 6000 });
          return;
        }
      }

      const duplicateCandidatesPhone = newCandidates.filter(newCandidate => candidatesList.find(
        (existingCandidate) => {
          if (existingCandidate.phone !== '') {
            return newCandidate.phone === existingCandidate.phone;
          }
          return false;
        },
      ));
      if (duplicateCandidatesPhone.length > 0) {
        alertToast(`Candidates are already in the invitation list. Please try again
          ${duplicateCandidatesPhone.map(candidate => `\n${candidate.phone}`)}
        `, { autoClose: 6000 });
        return;
      }
    }

    this.submittingNewCandidate();
    const cleanedCandidates = newCandidates.map(candidate => (
      {
        name: removeWhiteSpaceForStringElement(candidate.name),
        email: removeWhiteSpaceForStringElement(candidate.email),
        phone: candidate.phone && removeWhiteSpaceForStringElement(candidate.phone),
        coachingCandidateId: candidate.coachingCandidateId
          && removeWhiteSpaceForStringElement(candidate.coachingCandidateId),
        eventId: candidate.event_id,
      }
    ));

    try {
      const req = await fetch(`/api/interview/${interviewId}/candidates`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidatesList: cleanedCandidates,
          interviewName,
          coachingCandidateIdLabel,
        }),
      });

      if (req.status === 402) {
        alertToast('You have exhausted the limit for applicants. Please contact us');
        this.closeAddCandidateModal();
        return;
      }

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        this.submitNewCandidateSuccess(res);
        successToast(`Successfully add ${appendCharS(numOfCandidates, 'candidate')}!`);
        mxTrackEvent('Add candidates success', {
          interviewId,
          numberOfCandidates: numOfCandidates,
        });
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      alertToast('Error adding candidate. Please try again later');
      mxTrackEvent('Add candidates failure', {
        interviewId,
        numberOfCandidates: numOfCandidates,
      });
      this.submitNewCandidateFailure();
    }
  }

  async saveScoreWeightage(scoreParamters, interviewId) {
    try {
      this.changeIsSavingScoreSettings(true);
      const request = await fetch('/api/settings/user/update/score-setting', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          scoreParamters,
          interviewId,
        }),
      });
      if (request.status === 200) {
        successToast('Score settings saved successfully');
      }
      this.changeIsSavingScoreSettings(false);
    } catch (e) {
      alertToast('Failed to save score settings');
    }
  }

  async editCandidateDetails(interviewId, candidateId, name, email,
    phone, coachingCandidateId, coachingCandidateIdLabel, eventId) {
    this.editingCandidateDetails();
    try {
      const req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          name,
          email,
          phone,
          coachingCandidateId,
          coachingCandidateIdLabel,
          eventId,
        }),
      });
      const res = await req.json();
      if (req.status >= 200 && req.status < 300) {
        const candidatesList = res.candidates;
        this.editCandidateDetailsSuccess(candidatesList);
        successToast('Successfully updated details for candidate');
        mxTrackEvent('Edit candidate details success', {
          candidateId,
          name,
          email,
          coachingCandidateId,
        });
      } else {
        throw new Error(res.error);
      }
    } catch (e) {
      console.log(e);
      alertToast(`Error updating candidate detail. ${e.message}. Please try again later`);
      this.editCandidateDetailsFailure();
    }
  }

  async removeMultipleCandidates(interviewId, candidatesList) {
    this.removingMultipleCandidates();
    try {
      const candidateIds = candidatesList.map(candidate => candidate.candidateid);
      const req = await fetch(`/api/interview/${interviewId}/candidates`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
        body: JSON.stringify({
          candidateIds,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        successToast(res.msg);
        this.removeMultipleCandidatesSuccess(candidatesList);
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      alertToast(`Error removing candidates. ${e.message}. Please try again later`);
      this.removeMultipleCandidatesFailure();
    }
    return true;
  }

  async removeCandidateFromList(index, interviewId, candidateId) {
    this.removingCandidateFromList(index);
    try {
      const req = await fetch(`/api/interview/${interviewId}/candidate/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'DELETE',
      });

      if (req.status >= 200 && req.status < 300) {
        successToast('Candidate removed');
        this.removeCandidateFromListSuccess(candidateId);
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      alertToast('Error removing candidate. Please try again later');
      this.removeCandidateFromListFailure();
    }
  }

  async getCalendlyEventTypes(calendlyToken) {
    try {
      const calendlyRes = await fetch('https://calendly.com/api/v1/users/me/event_types', {
        headers: {
          'X-TOKEN': calendlyToken,
        },
      });
      const calendlyResArr = await calendlyRes.json();
      const arr = calendlyResArr.data.filter(event => event.attributes.active);
      // Set the event types
      this.eventTypeData(arr);
      if (arr.length > 0) {
        // Set the name attribute for the dropdown button
        this.setCurrentNameAttribute(arr[0].attributes.name);
        // Set the default event type to index 0 if exists
        this.chosenEventTypeUrl(arr[0].attributes.url);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchFilter(interviewId) {
    try {
      const req = await fetch(`/api/job-function/getSkillByInterview?interviewId=${interviewId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (req.status === 401) {
        setTimeout(() => Auth.signOut(), 1000);
      } else {
        const { skillList } = await req.json();
        this.setWorkmapScoreParameter(skillList);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async fetchDomainQuestionResponse(interviewId, candidateEmail) {
    try {
      const req = await fetch(`/api/candidate/fetchCandidateTestResult/${interviewId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidateEmail,
        }),
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        const {
          domainQuestionResults,
        } = res;
        this.setCandidateDomainScoreSuccess(domainQuestionResults);
      } else {
        this.setCandidateDomainScoreFailure();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async identityFraudFeedback(rating, candId) {      //eslint-disable-line
    try {
      await fetch(`/api/candidate/${candId}/rateIdentityFraud/${rating}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'PATCH',
      });
    } catch (e) {
      console.error(e);
    }
  }

  async updateWatchVideo(interviewId, candidateId, questionId) {      //eslint-disable-line
    try {
      const req = await fetch(`/signUpApi/update-watched-videos/${interviewId}/${candidateId}/${questionId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'PATCH',
      });
      const res = await req.json();
      const {
        watchedVideos,
        candidateid,
      } = res;
      this.updateWatchedVideoList({ watchedVideos, candidateid });
    } catch (e) {
      console.error(e);
    }
  }
  async fetchCandidateRecentResponse(start, end = 4) {      //eslint-disable-line
    this.setFetchRecentResponsesProcessing(true);
    try {
      const req = await fetch(`/api/recentresponses?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (!req.ok) {
        throw new Error(req.error);
      }
      const res = await req.json();
      const {
        candidates,
        count,
      } = res;
      this.setRecentResponses(candidates);
      this.setRecentResponsesCount(count);
    } catch (e) {
      console.error(e);
    } finally {
      this.setFetchRecentResponsesProcessing(false);
    }
  }

  async fetchFunnelCount() {
    try {
      const req = await fetch('/api/overviewfunnel', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (!req.ok) {
        throw new Error(req.error);
      }
      const res = await req.json();
      const {
        funnelData,
        isInterviewCreated,
        sampleCandidatesViewed,
      } = res;
      this.setFunnelCount(funnelData);
      this.setIsInterviewCreated(isInterviewCreated);
      this.setSampleCandidatesViewed(sampleCandidatesViewed);
    } catch (e) {
      console.error(e);
    }
  }

  async fetchWeeklyReportCount() {
    try {
      const req = await fetch('/api/overviewstats', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (!req.ok) {
        throw new Error(req.error);
      }
      const res = await req.json();
      const {
        pastWeekApplicantsCount,
        pastWeekInterviewCount,
        pastWeekVideoResponsesCount,
        totalApplicants,
        totalInterviews,
        totalVideoResponses,
      } = res;

      this.setWeeklyReportCounts({
        pastWeekApplicantsCount,
        pastWeekInterviewCount,
        pastWeekVideoResponsesCount,
        totalApplicants,
        totalInterviews,
        totalVideoResponses,
      });
    } catch (e) {
      console.error(e);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async fetchCandidateInChunk(limit, offset, interviewId, candidateList = []) {
    try {
      const response = await fetch(`/api/candidatereport/${interviewId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidateList,
          offset,
          limit,
        }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch candidates');
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching candidates:', error);
      throw error;
    }
  }

  async downloadCandidateReport(candidateList, interviewId, totalCandidateCount = 0) {
    this.setClickedOnDownload(true);
    try {
      if (candidateList && candidateList.length > 0) {
        const request = await fetch(`/api/candidatereport/${interviewId}`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            candidateList,
          }),
        });
        const response = await request.json();
        return response.candidates;
      }

      const limit = 1000;
      const candidates = [];
      const offsets = [];
      for (let i = 0; i <= totalCandidateCount; i += limit) {
        offsets.push(i);
      }
      // eslint-disable-next-line class-methods-use-this
      const fetchPromises = offsets.map(offset => this.fetchCandidateInChunk(
        limit,
        offset,
        interviewId,
        candidateList,
      ));
      const responses = await Promise.all(fetchPromises);
      responses.forEach(response => candidates.push(...response.candidates));
      return candidates || [];
    } catch (error) {
      console.error('Error downloading candidate report:', error);
      return [];
    }
  }


  async searchCandidate(
    interviewId,
    isCandidateDetailScreen = false,
    defaultCandidateEmail,
    searchedText,
    start,
    end,
    reset,
    applicationstatus,
    countries = [],
    selectCandidateStatusToFilter = [],
    selectRecruiterStatusToFilter = [],
    filterStartDate = null,
    filterEndDate = null,
    introQuestionDpObject = {},
    filterButtonClicked = false,
    additionalQuestionsType = {},
    selectedSortingButton = '',
    sortingName = '',
    selectRelevancyScoreToFilter,
  ) {
    if (filterButtonClicked) {
      this.setFilterIconClicked(true);
      this.setSearchCandidate(true);
    } else {
      this.setSearchCandidate(true);
      if (reset) {
        this.setSearchedCandidateText('');
      } else {
        this.setSearchedCandidateText(searchedText);
      }
    }
    const areAllEmptyArrays = Object.values(introQuestionDpObject).every(arr => Array.isArray(arr)
      && arr.length === 0);
    const filterConditions = {
      searchText: searchedText,
      startDate: filterStartDate,
      endDate: filterEndDate,
      country: countries,
      candidateStatus: selectCandidateStatusToFilter,
      recruiterStatus: selectRecruiterStatusToFilter,
      introQuestions: !areAllEmptyArrays ? introQuestionDpObject : [],
      additionalQuestionsType,
      sortingOrder: selectedSortingButton,
      sortingBy: sortingName,
      relevancyScore: selectRelevancyScoreToFilter,
    };

    try {
      const req = await fetch(`/api/interview/${interviewId}/candidates?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          filterConditions,
          applicationstatus,
        }),
      });
      if (req.status >= 200 && req.status <= 304) {
        const res = await req.json();
        // Update candidateData if each candidate is eligible for Calendly workflow
        const [newCandidateData, calendlyToken] = await CommonApiCalls
          .getCalendlyEligibleCandidatesAndToken(
            interviewId, res.candidates,
          );

        if (calendlyToken) {
          await this.getCalendlyEventTypes(calendlyToken);
        }

        this.getCandidatesSuccess({
          data: { ...res, candidates: newCandidateData },
          interviewId,
          isCandidateDetailScreen,
          defaultCandidateEmail,
          hired: newCandidateData.filter(x => x.hired),
          totalCandidates: res.totalCandidates,

        });
        if (defaultCandidateEmail && newCandidateData && newCandidateData.length > 0) {
          let index = newCandidateData.findIndex(
            candidate => candidate.email === defaultCandidateEmail,
          );
          if (index === -1) {
            index = 0;
          }
          const candidateQuestions = newCandidateData[index].questions;
          this.getVideoUrl(interviewId, index, 0, newCandidateData, candidateQuestions, null);
        }
        mxTrackEvent('Get candidates successful', {
          interviewId,
        });
      } else if (req.status === 401) {
        Auth.signOut();
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setSearchCandidate(false);
    }

    return true;
  }

  async getCandidateIndexesForInterview(
    interviewId,
    candidateId,
  ) {                                    //eslint-disable-line

    this.setFetchingCandidateIndexes(candidateId, true);

    try {
      const req = await fetch(`/api/candidateIndexes/${interviewId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const response = await req.json();
      return response.candidateIndexes;
    } catch (e) {
      console.error(e);
      console.log(e.toString());
    }
    return true;
  }

  async getSearchedCandidatesListing(
    interviewId,
    start,
    end,
    searchedText,
    reset,
    countries = [],
    selectCandidateStatusToFilter = [],
    selectRecruiterStatusToFilter = [],
    filterStartDate = null,
    filterEndDate = null,
    introQuestionDpObject = {},
    filterButtonClicked = false,
    additionalQuestionsType = {},
    selectRelevancyScoreToFilter = [],
    selectedSortingButton = '',
    sortingName = '',
  ) {
    this.setFetchingNewCandidate(true);
    if (filterButtonClicked) {
      this.setFilterIconClicked(true);
      this.setSearchCandidate(true);
    } else {
      this.setSearchCandidate(true);
      if (reset) {
        this.setSearchedCandidateListingText('');
      } else {
        this.setSearchedCandidateListingText(searchedText);
      }
    }
    const areAllEmptyArrays = Object.values(introQuestionDpObject).every(arr => Array.isArray(arr)
      && arr.length === 0);

    const filterConditions = {
      searchText: searchedText,
      startDate: filterStartDate,
      endDate: filterEndDate,
      country: countries,
      candidateStatus: selectCandidateStatusToFilter,
      recruiterStatus: selectRecruiterStatusToFilter,
      introQuestions: !areAllEmptyArrays ? introQuestionDpObject : [],
      additionalQuestionsType,
      sortingOrder: selectedSortingButton,
      sortingBy: sortingName,
      relevancyScore: selectRelevancyScoreToFilter,
    };
    try {
      const req = await fetch(`/api/candidatelisting/${interviewId}/candidates?start=${start}&end=${end}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify({
          filterConditions,
        }),
      });
      if (req.status >= 200 && req.status <= 304) {
        const res = await req.json();
        this.setNewCandidateSuccess({
          data: { ...res },
          interviewId,
          // isCandidateDetailScreen,
          // defaultCandidateEmail,
          orderCandidates: true,
          // hired: newCandidateData.filter(x => x.hired),
          totalCandidates: res.totalCandidates,
        });
      } else if (req.status === 401) {
        Auth.signOut();
      } else if (req.status === 404) {
        throw new Error('Interview not found or you don\'t have permission to view this interview');
      } else {
        throw new Error('Error loading interview and candidates information. :(');
      }
    } catch (e) {
      this.getCandidatesFail(e.message);
      console.error(e);
      mxTrackEvent('Get candidates failed', {
        interviewId,
      });
      console.log(e.toString());
    } finally {
      this.setFetchingNewCandidate(false);
    }

    return true;
  }

  async fetchCountryOnSearch(location) {
    try {
      const req = await fetch(`/api/location/country/${location}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });

      if (req.status === 200) {
        const res = await req.json();
        const { countryList } = res;
        this.fetchCountrySuccess(countryList);
      } else {
        throw Error(req.statusText);
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  async getAdditionalIntroQuestions(interviewid) {
    try {
      const request = await fetch(`/api/category/addition-questions/${interviewid}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });
      const response = await request.json();
      this.setAdditionalIntroQuestion(response);
    } catch (e) {
      console.log(e);
    }
  }

  async getOverviewMessage(lang) {
    try {
      const request = await fetch(`/api/overviewMessage/${lang}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });
      const response = await request.json();
      this.setOverviewMessageObject(response.messageObject);
    } catch (e) {
      console.log(e);
    }
  }

  async storeCandidateViewedByHiringManger(candidateId, listId, hiringManagerEmail) { //eslint-disable-line
    try {
      await fetch(`
      /shareApi/candidate/viewed/${hiringManagerEmail}/${candidateId}/${listId}
      `, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'POST',
      });
    } catch (e) {
      console.log(e);
    }
  }

  async updateOverviewScore(scoreParamters, interviewId, reset) {   //eslint-disable-line
    try {
      await fetch(`/api/interview/${interviewId}/update-overall-score`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          interviewId,
          scoreParamters,
          reset,
        }),
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getCandidateEmailInviteCount(interviewId) {
    try {
      const request = await fetch(`
      /api/email/getCandidateInviteCount?isAllCandidateSelectedFlag=true
      `, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          interviewId,
        }),
        method: 'POST',
      });
      const response = await request.json();
      this.fetchCandidateEmailInviteCount(response.msg);
    } catch (e) {
      console.log(e);
    }
  }

  async reactiveCandidateLink(candidateId) {   //eslint-disable-line
    try {
      const request = await fetch(`/api/candidate/reactivatelink/${candidateId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      });
      if (request.status === 200) {
        successToast(request.message || 'Candidate link reactivated successfully!');
      }
    } catch (e) {
      alertToast('Failed to reactivated candidate link, please try again');
      console.log(e);
    }
  }

  async bulkDownloadCandidateCsv() {   //eslint-disable-line
    this.setStartCandidateBulkDownload(true);
    try {
      const request = await fetch('/api/org-candidatereport', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
      });
      const response = await request.json();
      return response.candidates;
    } catch (e) {
      console.log(e);
    } finally {
      this.setStartCandidateBulkDownload(false);
    }
  }
}


export default alt.createActions(CandidateActions);
