export default {
  cancel: 'Abbrechen',
  save: 'Speichern',
  back: 'Zurück',
  create: 'Erstellen',
  edit: 'Bearbeiten',
  delete: 'Löschen',
  Submit: 'Absenden',
  submitting: 'Senden …',
  confirm: 'Confirm',
  share: 'Teilen',
  marking: 'Markieren …',
  markAllAsRead: 'Alle als gelesen markieren',
  adding: 'Hinzufügen …',
  error: 'Fehler',
  clear: 'Klar',
  all: 'Alle',
  navbarSection: {
    overviewTab: 'Übersicht',
    interviewsTab: 'Interviews',
    insights: 'Einblicke',
    talentpoolTab: 'Talent Pool',
    applicantpoolTab: 'Bewerberpool',
    usersTab: 'Benutzer',
    buyCredits: 'Credits kaufen',
    'Upgrade Plan': 'Tarif upgraden',
    viewAll: 'Alle anzeigen',
    notifications: 'Benachrichtigungen',
    help: 'Hilfe',
    notificationInfo1: 'Sie haben keine neuen Benachrichtigungen',
    today: 'Heute',
    yesterday: 'Gestern',
    english: 'English',
    vietnamese: 'Vietnamese',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
  },
  overviewPage: {
    messageText1: 'Willkommen! Übernehmen Sie die Verantwortung für Ihre Karriereseite und bauen Sie Ihre Präsenz aus.',
    messageText2: 'Sie können Ihre Karriereseite mit Ihrer Website verlinken.',
    messageButton: 'Beanspruchen Sie Ihre Karriereseite',
    ratingError: 'Bewertungssterne sind erforderlich.',
    feedback: 'Rückmeldung',
    feedbackQuestion: 'Tell us about your experience with Interviewer.AI. How statisfied are you ?',
    veryUnsatisfied: 'Sehr unbefriedigt',
    NitherSatUsat: 'Weder zufrieden noch unzufrieden',
    verySatisfied: 'Sehr zufrieden',
    feedbackTextLabel: 'Haben Sie weitere Anmerkungen für uns? Bitte hinterlassen Sie sie unten:',
    surveyText: 'Willkommen! Helfen Sie uns, die Plattform besser zu verstehen. Unsere Umfrage sollte weniger als 30 Sekunden dauern. Wir würden uns freuen, von Ihnen zu hören!',
    thankyouSurveyText: 'Danke für Ihre Rückmeldung.',
    whatDoYouThink: 'Erzählen Sie uns, was Sie denken',
    secondsAgo: '{{seconds}} Sekunden zuvor',
    minuteAgo: '1 vor einer Minute',
    hourAgo: '1 vor einer Stunde ^',
    monthAgo: '1 letzten Monat',
    yearAgo: '1 vor einem Jahr',
    getStarted: 'Erste Schritte',
    totalInterviews: 'Gesamtzahl der Interviews',
    totalApplicants: 'Gesamtzahl der Bewerber',
    totalVideoResponses: 'Gesamtzahl der Videoantworten',
    viewMoreInsights: 'Weitere Einblicke anzeigen',
    weeklyOverall: 'Wöchentlich insgesamt',
    jobInterview: 'Bewerbungsgespräch',
    sourcingFunnel: 'Rekrutierungstrichter',
    applicantFunnel: 'Bewerber-Trichterl',
    totalCandidates: 'Bewerber insgesamt',
    yetToRespond: 'Antwort ausstehend',
    createInterview: '+ Interview erstellen',
    text1: 'Treten Sie Pulse of Hiring auf LinkedIn bei, um Einblicke und Themen aus der Welt der Arbeit und Technologie zu erhalten.',
    text2: 'abonnieren',
    text3: 'zum Thema Identitätsbetrug',
    responded: 'Geantwortet',
    rated: 'Bewertet',
    newResponses: 'Neue Antworten',
    shortlisted: 'Engere Auswahl',
    hello: 'Hallo',
    welcome: 'Willkommen',
    recentResponses: 'Kürzliche Antworten',
    noRecentResponses: 'Keine kürzlichen Antworten',
    applications: 'Bewerbung',
    videoResponses: 'Videoantworten',
    createFirstInterview: 'Erstellen Sie Ihr erstes Bewerbungsgespräch',
    addCompanyLogo: 'Firmenlogo hinzufügen',
    viewSampleProfile: 'Bespielprofil anzeigen',
    addTeamMember: 'Teammitglieder hinzufügen',
    instruction1: 'Verpassen Sie nicht unsere neuesten und aktualisierten',
    instruction2: 'Erfahrung mit Benachrichtigungen',
  },
  interviewListing: {
    archieveInterview: 'Archivinterview',
    deleteInterview: 'Interview löschen',
    duplicateInterview: 'Doppeltes Interview',
    editInterview: 'Interview bearbeiten',
    manageApplicants: 'Bewerber verwalten',
    archiveInterview: 'Archivinterview',
    interviewOwner: 'Interview-Inhaber',
    searchInterview: 'Bewerbungsgespräch suchen',
    details: 'Details',
    created: 'Erstellt',
    expiry: 'Ablaufdatum',
    candidates: 'Bewerber',
    applicants: 'Bewerber',
    responses: 'Antworten',
    analyzed: 'Analysiert',
    shortlisted: 'Shortlisted',
    createdBy: 'Erstellt von',
    status: 'Status',
    share: 'Share',
    filter: 'Filter',
    newJobInterview: 'Neues Bewerbungsgespräch',
    newInterview: 'Neues Interview',
    manageCandidates: 'Bewerber verwalten',
    assignTo: 'Zuweisen an',
    changeBaground: 'Hintergrund ändern',
    editJobInterview: 'Bewerbungsgespräch bearbeiten',
    duplicateJobInterview: 'Bewerbungsgespräch duplizieren',
    deleteJobInterview: 'Bewerbungsgespräch löschen',
    archieveJobInterview: 'Bewerbungsgespräch archivieren',
    downloadQRCode: 'QR-Code herunterladen',
    results: 'Ergebnisse',
    jobOwner: 'Stelleninhaber',
    contributor: 'Mitwirkender',
    randomiseInfo1: 'Fragen zur Randomisierung auswählen',
    youHaveSelected: 'Sie haben ausgewählt',
    questions: 'Fragen',
    interviewStatus: 'Interview Status',
    filterForInterview: 'Filtern Sie nach Vorstellungsgespräch',
    pleaseSelect: '-- Bitte auswählen --',
    expiryMessage: 'Dieses Interview läuft sofort ab und Kandidaten können nicht mehr auf das Interview antworten.',
    sureMessage: 'Möchten Sie das Interview wirklich archivieren?',
    archieving: 'Archivierung...',
  },
  userList: {
    team: 'Team',
    inviteNewUsers: 'Neue Benutzer einladen',
    emailAddresses: 'E-Mail-Adressen',
    organizationMembers: 'Organisationsmitglieder',
    addEmailAddress: 'E-Mail-Adressen hier einfügen',
    addEmail: 'Drücken Sie Enter oder Tab, um E-Mail-Adressen hinzuzufügen',
    cancel: 'Stornieren',
    share: 'Share',
    emails: 'E-Mails',
    sentInvitesTo: 'Einladungen erfolgreich gesendet an',
    inviteError: 'Fehler beim Senden der Einladungen,bitte später erneut versuchen',
    confirm: 'Bestätigen',
    adminUserMessage: 'Administratoren können Benutzer und alle in der Organisation erstellten Bewerbungsgespräche verwalten',
    inOrganization: 'in Ihrer Organisation?',
    areSureYouWantToAssignPermissionTo: 'Möchten Sie wirklich administrative Berechtigungen zuweisen an',
    toAdmin: 'zu Administrator',
    upgrade: 'Upgrade',
    resendInvitation: 'Möchten Sie wirklich erneut eine Einladung senden an',
    fromOrganization: 'von Ihrer Organisation?',
    resendInviteTo: 'Einladung erneut senden an',
    eraseAccessMessage: 'von Ihrer Organisation? Dadurch werden das Konto und der Zugang von unserer Plattform gelöscht.',
    irreversibleMessage: 'Dieser Vorgang kann nicht rückgängig gemacht werden!',
    removeUserMessage: 'Möchten Sie diese Person wirklich entfernen:',
    remove: 'Entfernen',
    noMemberFound: 'Keine Mitglieder gefunden. Laden Sie per E-Mail neue Mitglieder zu Ihrer Organisation ein',
    joined: 'Beigetreten',
    status: 'Status',
    email: 'E-Mail',
    role: 'Rolle',
    members: 'Mitglieder',
    removeUser: 'Benutzer entfernen',
    resendInvite: 'Einladung erneut senden',
    upgradeToAdmin: 'Upgrade auf Administrator',
    exceededUserAdditionMessage: 'Die Anzahl der Administratoren in Ihrer Organisation wurde überschritten.',
    membersContactUsPageProps: {
      contactUsMessage: 'Teamverwaltung und Mitgliedereinladung sind eine erweiterte Funktion. Bitte kontaktieren Sie uns für Einzelheiten. Oder klicken Sie auf die Schaltfläche unten, damit wir Sie per E-Mail kontaktieren können!',
      contactUsTitle: 'Team',
      contactUsFeature: 'Organisationsverwaltungsfunktion',
    },
    searchMember: 'Suchen Sie nach Name oder E-Mail',
    inviteMember: 'Suche nach Mitgliedern, E-Mails …',
    memberLimitExceededMessage: 'Die Höchstzahl der Mitglieder Ihrer Organisation wurde überschritten',
    incorrectEmailMessage: 'Falsche E-Mail-Adresse eingegeben',
    sameCompanyDomainMessage: 'Bitte geben Sie eine oder mehrere E-Mail-Adressen der gleichen Firmen-Domain ein',
    hasAlreadyInvited: 'wurde bereits eingeladen',
  },
  planDetails: {
    planEntitlements: 'Tarifleistungen',
    superAdmin: 'Super-Administrator',
    admin: 'Administrator',
    user: 'Benutzer',
    contributors: 'Mitwirkende',
    yourPlan: 'Ihr Tarif',

  },
  newInterview: {
    createNewInterview: 'Neues Interview erstellen',
    createInterview: 'Erstellen Sie ein Interview',
    creatingInterview: 'Interview erstellen...',
    updateInterview: 'Interivew aktualisieren',
    updatingInterview: 'Interview wird aktualisiert...',
    newInterview: 'Neues Interview',
    basicInfo: 'Basic Info',
    instituteLogo: 'Logo des Instituts',
    addInstituteLogo: 'Institutslogo hinzufügen',
    instLogoRequired: 'Das Logo des Instituts ist erforderlich',
    instituteName: 'Name des Instituts',
    instituteNameRequired: 'Der Name des Instituts ist erforderlich',
    departmentTitle: 'Abteilungstitel',
    departmentTitleRequired: 'Abteilungstitel ist erforderlich',
    chooseFunction: 'Wählen Sie eine Funktion',
    createFunction: 'Erstellen Sie eine benutzerdefinierte Funktion',
    functionName: 'Funktionsname',
    interviewExpiryDate: 'Ablaufdatum des Vorstellungsgesprächs',
    interviewDescription: 'Interviewbeschreibung',
    applicantRetakeText: 'Kann der Bewerber das gesamte Vorstellungsgespräch mehrmals wiederholen?',
    applicantResumeText: 'Ist bei der Bewerbung des Bewerbers eine Bewertung des Lebenslaufs erforderlich?',
    applicantResponseText: 'Erwartete Antwortsprache des Bewerbers',
    enableSocialMediaText: 'Entscheiden Sie sich dafür, Social-Media-Profile von Kandidaten zu fragen',
    workmapErrorMessage: '(Bitte wählen Sie mindestens eine Fähigkeit aus)',
    selectQuestions: 'Fragen auswählen',
    selectTwoQuestions: 'Bitte wählen Sie mindestens 2 Fragen aus.',
    selectAllQuestions: 'Alle Fragen auswählen',
    updateJobInterview: 'Bewerbungsgespräch aktualisieren',
    createJobInterview: 'Bewerbungsgespräch erstellen',
    identityFraudToggleText: 'Ist eine Identitätsprüfung erforderlich?',
    newJobInterview: 'Neues Vorstellungsgespräch',
    workmapAssessment: 'Workmap-Bewertung',
    addIntroQuestions: 'Zusätzliche Formularfragen',
    addVideoQuestions: 'Videofragen hinzufügen',
    editYourQuestion: 'Bearbeiten Sie die zusätzliche Formularfrage',
    editingYourQuestion: 'Bearbeitung...',
    noIntroCategories: 'Noch keine Kategorien erstellt...',
    interviewSettings: 'Gesprächseinstellungen',
    basicJobInfo: 'Grundlegende Informationen zur Stelle',
    qualifierQuestion: 'Qualifikationsfrage',
    qualifierQuestionErrorMessage: 'Die Qualifikationsfrage ist erforderlich, wenn sie aktiviert ist.',
    addLogo: 'Logo hinzufügen',
    companyLogo: 'Firmenlogo',
    addCompanyLogo: 'Firmenlogo hinzufügen',
    logoChangeInstruction: 'Klicken Sie auf das Logo, um es zu ändern oder hinzuzufügen.',
    logoChangeInstruction2: 'max. 500kb',
    companyLogoRequired: 'Firmenlogo erforderlich',
    companyName: 'Firmenname',
    companyNameRequired: 'Firmenname erforderlich.',
    jobTitleInstruction1: 'Stellenbezeichnung',
    jobTitleInstruction2: 'Interview-Name erforderlich.',
    jobFunction: 'Tätigkeitsbereich',
    chooseJobFunction: 'Wählen Sie einen Tätigkeitsbereich',
    createCustomJobFunction: 'Benutzerdefinierten Tätigkeitsbereich erstellen',
    questions: 'Fragen',
    jobFunctionInstruction: {
      instruction1: 'Name der Jobfunktion*',
      instruction2: 'Der Name der Jobfunktion ist erforderlich.',
    },
    updatingJobInterview: 'Bewerbungsgespräch aktualisieren …',
    workmapSkills: {
      instruction1: 'Fähigkeiten auswählen',
      instruction2: 'Die Workmap-Bewertung verwendet die Likert-Skala. Eine Likert-Skala wird üblicherweise verwendet, um Einstellungen, Wissen, Wahrnehmungen, Werte und Verhaltensänderungen zu messen. Eine Likert-Skala umfasst eine Reihe von Aussagen, aus denen die Befragten auswählen können, um ihre Antworten auf Bewertungsfragen zu bewerten (Vogt, 1999).',
      instruction3: 'Fähigkeiten sind erforderlich.',
      instruction4: 'Mehrere auswählen:',
      relevantSkills: 'Relevante Fähigkeiten',
      instruction5: 'List of skills that will be tested in Interviewer.AI Workmap Assessment. The WorkMap Assessment is a psychometric tool designed to measure skills and competencies that are linked to success factors of specific jobs.',
      instruction6: 'Wählen Sie Mehrere',
      workmapAssessmentEnabled: 'Workmap-Bewertung aktiviert',
      instruction7: 'Likert-Skala',

    },
    creating: 'Erstellen',
    create: 'Erstellen',
    interviewStartDate: 'Anfangsdatum des Interviews',
    jobExpiry: 'Ablaufdatum der Stelle',
    expirydateRequired: 'Das Ablaufdatum ist erforderlich.',
    jobLocationInstruction: {
      jobLocation: 'Arbeitsort',
      instruction1: 'Land ist erforderlich.',
      instruction2: 'Land ist erforderlich.',
    },
    jobVisibility: {
      jobVisibilityInstruction: 'Sichtbarkeit der Stelle',
      jobVisibilityInstruction1: 'Die Sichtbarkeit von Stellen steuert, welche Jobs auf Ihrer Karriereseite angezeigt werden.',
      jobVisibilityInstruction2: 'Die Stelle ist',

    },
    private: 'privat',
    public: 'öffentlich',
    qrCodeInstructions: {
      qrCodeInstructions1: 'QR-Code-Sichtbarkeit',
      qrCodeInstructions2: 'QR-Code-Sichtbarkeit zeigt den QR-Code bei Stellenangeboten',
      qrCodeInstructions3: 'QR-Code',
    },
    enabled: 'Aktiviert',
    disabled: 'Deaktiviert',
    roleType: 'Rollentyp',
    roleTypeRequired: 'Der Rollentyp ist erforderlich.',
    uniqueCandidatesLabel: 'Eindeutige Bewerberkennung',
    uniqueCandidatesLabel2: 'Dies kann ein beliebiger eindeutiger Wert sein, den Sie einem Bewerber zugewiesen haben, um ihn zu identifizieren.',
    uniqueCandidatesLabel3: '1 - Der angegebene Wert wird im Bewerbungsformular als Label mit Eingabefeld angezeigt.',
    uniqueCandidatesLabel4: '2 - Unsere Empfehlung: Geben Sie in diesem Feld keinen Wert an, wenn Sie nicht über eine solche eindeutige ID verfügen oder diese IDs den Bewerbern nicht mitgeteilt haben, da dieses Feld für die Bewerber obligatorisch ist.',
    candidateCoachingId: 'z. B. Sitzplatznummer / Bewerbernummer / Rollennummer',
    jobDescription: {
      jobDescription: 'Stellenbeschreibung',
      jobDescriptionInstruction1: 'Die Beschreibung ist erforderlich.',
      jobDescriptionInstruction2: 'Versendet nach Abschluss der Bewertung Berichte an die Bewerber',
      jobDescriptionInstruction3: 'Wenn Sie diese Funktion aktivieren, können Sie Bewerbern nach Beendigung des Video-Interviews individuelle Berichte zusenden.',
    },
    moreOptions: 'Weitere Optionen',
    resumeInstructions: {
      resumeInstructions1: 'Ist bei der Bewerbung ein Lebenslauf erforderlich?',
      resumeInstructions2: 'Machen Sie die LinkedIn-Profil-URL obligatorisch',
      resumeInstructions3: 'Können Bewerber das gesamte Gespräch mehrmals wiederholen?',
      resumeInstructions4: 'Machen Sie die Facebook-Profil-URL obligatorisch',
      resumeInstructions5: 'Machen Sie die Instagram-Profil-URL obligatorisch',
      resumeInstructions6: 'Machen Sie die ideale Antwort obligatorisch',
      resumeInstructions7: 'Inhaltsrelevanz-Wertung Umschalter',

    },
    showLinkedin: '{{option}} LinkedIn-URL-Bereich',
    showFacebook: '{{option}} Facebook-URL-Bereich',
    showInstagram: '{{option}} Instagram-URL-Bereich',
    videoScoreInstruction: 'Möchten Sie eine Video-Mindestpunktzahl für eine Wiederholung des Bewerbungsgesprächs festlegen?',
    thresholdInstruction: 'Der Schwellenwert sollte größer als 20 sein',
    thresholdInstruction1: 'Bewerber daran hindern, über das Bewerbungsformular hinaus fortzufahren',
    thresholdInstruction2: 'Bitte beachten Sie, dass bei Aktivierung dieser Option die Bewerber nicht über das Bewerbungsformular hinausgehen können. Bewerber benötigen eine Einladung von Ihnen, um fortfahren zu können.',
    overwriteInterviewCompanyName: 'Name der interviewenden Firma überschreiben',
    overwriteCompanyName: 'Firmenname überschreiben',
    customiseInterviewLogo: 'Interview-Logo anpassen (JPG, JPEG, PNG)',
    redirectURL: 'Weiterleitungs-URL',
    redirectUrlInstructions: 'Dies legt die Weiterleitungs-URL fest, wenn der Bewerber das Video-Interview beendet hat, z. B.: Die LinkedIn-Seite Ihres Unternehmens. Der URL muss das Protokoll http oder https vorangestellt werden.',
    manualRatingParameter: 'Manuelle Bewertungsparameter',
    instruction5: 'Drücken Sie Enter oder Tab, um einen separaten Bewertungsparameter hinzuzufügen.',
    instruction6: 'Erwartete Antwortsprache des Bewerbers (Beta)',
    instruction7: 'Diese Konfiguration stellt die erwartete Sprache der Antwort des Bewerbers ein und wandelt die Sprache entsprechend in Text um. Bitte beachten Sie, dass einige Bewertungen (z. B. Kommunikation) bei anderen Sprachen als Englisch deaktiviert werden.',
    english: 'Englisch',
    mandarin: 'Mandarin',
    polish: 'Polnisch',
    spanish: 'Spanisch',
    bahasa: 'Bahasa (Indonesisch)',
    french: 'Französisch (Frankreich)',
    vietnamese: 'Vietnamesisch',
    german: 'German',
    country: 'Land',
    city: 'Stadt',
    cognitiveAssessment: 'Bewertung der kognitiven Fähigkeiten',
    selectTime: 'Zeit auswählen',
    categoryName: 'Kategoriename',
    categoryDescription: 'Kategoriebeschreibung',
    enterCtaegoryDescription: 'Kategoriebeschreibung eingeben',
    createCategory: 'Kategorie erstellen',
    categoryNameInstruction: 'Name für diese Gruppe von Fragen',
    listMore: 'Mehr anzeigen +',
    listLess: 'Weniger anzeigen -',
    expired: 'Abgelaufen',
    copyJobLink: ' Job-Link kopieren',
    required: 'Erforderlich',
    'On-site': 'Vor Ort', // Purposely kept key like this
    Remote: 'Remote', // Purposely kept key like this
    Hybrid: 'Hybrid', // Purposely kept key like this
    'Part Time': 'Teilzeit', // Purposely kept key like this
    'Full Time': 'Vollzeit', // Purposely kept key like this
    Internship: 'Praktikum', // Purposely kept key like this
    Contract: 'Vertrag', // Purposely kept key like this
    Training: 'Schulung', // Purposely kept key like this
    'Mock Interview': 'Probeinterview', // Purposely kept key like this
  },
  introQuestions: {
    introQuestionsTitle: 'Zusätzliche Formularfragen',
    addQuestions: 'Fragen hinzufügen',
    instruction1: 'Bewerber können mehrere Optionen auswählen',
    instruction2: 'Bewerber können nur eine Option auswählen',
    edit: 'Edit',
    question: 'Frage',
    instruction3: 'Auswahl von mehr als einer Option ermöglichen',
    notNow: 'Nicht jetzt',
    addYourQuestion: 'Ihre Frage hinzufügen',
    randomize: 'Zufallsauswahl',
    selectTime: 'Select Time',
    selectCategory: 'Kategorie auswählen',
    videoQuestionBank: 'Videofragendatenbank',

  },
  editInterview: {
    editInterviewTemplate: 'Interview-Vorlage bearbeiten',
    interviewExpiry: 'Ablauf des Bewerbungsgesprächs',
    modifyQuestions: 'Ändern Sie Ihre Fragen und wählen Sie bis zu',
    withLimit: 'mit einer Grenze von',
  },
  profileSlider: {
    logout: 'Abmelden',
    userProfile: 'Benutzerprofil',
    companyProfile: 'Firmenprofil',
    instituteProfile: 'Institutsprofil',
    billing: 'Abrechnung',
    integrations: 'Integration',
    notificationSettings: 'Benachrichtigungseinstellungen',
    promoCode: 'Aktionscode',
    contactSupport: 'Support kontaktieren',
    shareFeedback: 'Feedback geben',
    changePassword: 'Passwort ändern',
    termsAndConditions: 'Geschäftsbedingungen',
    help: 'Help',
    videoTutorial: 'Videoanleitungen',
    howToCreateAnInterview: 'Ein Interview erstellen',
    howToAddQuestionsToAnInterview: 'Fragen zu einem Interview hinzufügen',
    howToInviteCandidates: 'Bewerber einladen',
    howToShareCandidates: 'Bewerber teilen',
    aFeelOfTheDashboard: 'Ein Gefühl für das Dashboard',
    reCalibrateAIMatrics: 'Neukalibrierung Ihrer KI-Metriken',
    knowMoreAboutTalentPool: 'Mehr über den Talentpool erfahren',
    addTeamMember: 'Teammitglieder hinzufügen',
  },
  userProfile: {
    back: 'Back',
    editProfile: 'Profil bearbeiten',
    profileImage: 'Profilbild',
    upload: 'Hochladen',
    maxImgSize: 'Maximale Bildgröße 500 x 500*',
    personalInfo: 'Persönliche Angaben',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    phoneNumber: 'Telefonnummer',
    save: 'Save',
    saving: 'Speichern …',
    placeHolderFirstName: 'Vorname eingeben',
    placeHolderLastName: 'Nachname eingeben',
  },
  companyProfile: {
    back: 'Back',
    myCompany: 'Mein Unternehmen',
    myInstitute: 'Mein Institut',
    basicInformation: 'Grundlegende Informationen',
    orgIntroVideo: 'Organisatorisches Vorstellungsvideo',
    choose: 'Wählen',
    orgIntroVideoFormat: 'Video hochladen (<30 MB, .mp4)',
    remove: '(Entfernen)',
    companyLogo: 'Firmenlogo',
    instituteLogo: 'Logo des Instituts',
    upload: 'Upload',
    businessInformation: 'Geschäftsinformationen',
    companyName: 'Company Name',
    instituteName: 'Name des Instituts',
    companyNamePlaceholder: 'Firmenname eingeben',
    yourCareerPage: 'Ihre Karriereseite',
    yourPageIs: 'Ihre Karriereseite ist',
    public: 'public',
    private: 'private',
    categories: 'Kategorien *',
    industryType: 'Bitte wählen Sie den Branchentyp aus.',
    description: 'Beschreibung',
    maxCharacter: '(max. {{count}} Zeichen)',
    siteNamePlaceHolder: 'Standortname eingeben',
    createNew: 'Neu erstellen',
    subOrganisation: 'Unterorganisationen',
    urlCopyurl: 'URL in die Zwischenablage kopiert',
    saveProfile: 'Profil speichern',
    saving: 'Saving',
    hideEmploymentType: 'Verbergen Sie die Informationen zum Beschäftigungstyp im Bewerbungsformular des Kandidaten',
    hideLocation: 'Verbergen Sie die Informationen zum Standort der Stelle im Bewerbungsformular des Kandidaten.',
  },
  billingPage: {
    overall: 'Gesamt',
    byInterview: 'Per Interview',
    heading: 'Jetzt bist du dran',
    changePlan: 'Tarif ändern',
    particulars: 'Einzelheiten',
    credits: 'Credits',
    used: 'Verwendet',
    totalCredits: 'Gesamt Credits',
    to: 'Zu',
    balance: 'Guthaben',
    numberOfLiveInterview: 'Anzahl der Live-Interviews',
    numberOfUser: 'Anzahl der Benutzer',
    candidateResponse: 'Antworten von Bewerbern (Credits)',
    billingInformation: 'Abrechnungsinformationen',
    nextBillingDate: 'Nächstes Abrechnungsdatum',
    on: 'am',
    freeCredit: 'Kostenlose Credits im Wert von 100 $',
    continue: 'Fortfahren',
    usePromoCode: 'Nutzen Sie unseren Aktionscode für 20 % Rabatt auf einen beliebigen Tarif',
    copiedSuccess: 'Erfolgreich kopiert',
    notApplicable: 'Nicht anwendbar',
    hiringIsSeasonal: 'Uns ist klar, dass Neueinstellungen saisonabhängig sind',
    hiringDesc1: 'Entdecken Sie unsere neue jährliche Preisgestaltung auf Basis von Credits. Erhalten Sie Zugang zu unbegrenzten Jobs',
    hiringDesc2: 'und unbegrenzten Nutzern, wenn Sie mindestens 100 Credits kaufen.',
  },
  integrationPage: {
    calendayApiKey: 'CALENDLY-API-SCHLÜSSEL',
    save: 'Save',
    zapierIntegration: 'ZAPIER-INTEGRATION',
    beta: 'Beta',
    generate: 'Erzeugen',
    whatZapier: 'WAS KANN MAN MIT ZAPIER MACHEN?',
    placeHolderCalendly: 'Calendly-API eingeben',
    placeholderGenerateZapierKey: 'Klicken Sie auf „Erzeugen“, um den Zapier-API-Schlüssel zu generieren.',
  },
  notificationPreference: {
    heading: 'Candidate Summary Email Notification Preference',
    daily: 'Täglich',
    weekly: 'Wöchentlich',
    monthly: 'Monatlich',
    save: 'Save',
  },
  resetPassword: {
    heading: 'Passwort zurücksetzen',
    enterEmailInstr: 'Geben Sie Ihre E-Mail-Adresse ein, damit wir Ihnen einen Link zum Zurücksetzen Ihres Passworts schicken können.',
    email: 'Email',
    sendResetLink: 'E-Mail zum Zurücksetzen des Passworts senden',
    login: 'Anmelden',
    resetting: 'Zurücksetzen …',
  },
  talentpool: {
    resume: 'Wieder aufnehmen',
    viewResume: 'Lebenslauf anzeigen',
    'date applied': 'Antragsdatum',
    talentpoolInsights: 'Talentpool-Einblicke',
    errorOccured: 'Es ist ein Fehler aufgetreten. Bitte kontaktieren Sie uns für weitere Details.',
    reachOut: 'Wir melden uns bald!',
    hi: 'Hallo!',
    contactUsMessage: `Sind Sie es leid, durch Interviews zu blättern, um auf Ihre eigenen Kandidaten zuzugreifen?
  
    Die Talentpool-Funktion macht es Ihnen einfach! Greifen Sie auf einer Seite mit Filtern auf alle Ihre Kandidaten zu, um Ihre Suche einzugrenzen und Ihr Interesse zu finden.
  
    Um Zugang zu dieser Premium-Funktion zu erhalten, kontaktieren Sie uns bitte für weitere Einzelheiten. Oder klicken Sie auf die Schaltfläche unten, damit wir Sie per E-Mail kontaktieren können!
    `,
    contactUsTitle: 'Talent Pool',
    demographicData: 'Demografische Daten',
    applicationsBtn: 'Anwendung',
    usage: 'Verwendung',
    educationBackground: 'Bildungshintergrund',
    emailPerformance: 'E-Mail-Leistung',
    dataWithPhoneNumber: 'Daten mit Telefonnummerr',
    appliedToMultipleRoles: 'Wird auf mehrere Rollen angewendet',
    timeToHire: 'Zeit, jemanden einzustellen',
    totalLiveInterviews: 'Gesamtzahl der Live-Interviews',
    totalUsers: 'Gesamtzahl der Benutzer',
    allJobs: 'Alle Jobs',
    contactUsToUpgrade: 'Kontaktieren Sie uns für ein Upgrade',
    contactUsFeature: 'Talent Pool Insights Feature',
    interested: 'Ich habe Interesse!',
    companyOverall: 'Unternehmen insgesamt',
    interviews: 'Interviews',
    applicants: 'Bewerber',
    resumeScoring: 'Lebenslaufbewertung',
    workmapScoring: 'Workmap-Bewertung',
    videoScoring: 'Video-Interview',
    shortlisted: 'Shortlisted',
    scheduled: 'Geplant',
    rejected: 'Abgelehnt',
    kiv: 'Vorgemerkt',
    completedApplications: 'Bewerbung abgeschlossen',
    incompleteApplications: 'Bewerbung unvollständig',
    searchCandidatePlaceholder: 'Bewerber suchen',
    allJobTitle: 'Alle Stellenbezeichnungen',
    allLocations: 'Alle Standorte',
    allStatus: 'Alle Status',
    allApplication: 'Alle Bewerbungen',
    complete: 'Abgeschlossen',
    inComplete: 'Unvollständig',
    name: 'Name',
    jobTitle: 'Stellenbezeichnung',
    'job title': 'Stellenbezeichnung',
    company: 'Firma',
    location: 'Standort',
    interviewName: 'Interview-Name',
    'interview name': 'Interview Name',
    status: 'Status',
    applications: 'Ứng dụng',
    all: 'Alle',
    completed: 'Abgeschlossen',
    incomplete: 'Unvollständig',
    videoInterview: 'Video-Interview',
    'Action Pending': 'Aktion ausstehend',
    Shortlist: 'In die engere Auswahl genommen',
    Rejected: 'Abgelehnt',
    Kiv: 'Vormerken',
    application: 'Status',
  },
  candidateDetails: {
    idealAnswer: 'Ideale Antwort',
    idealAnsNotAvail: 'Ideale Antwort nicht verfügbar',
    idVerifyTrueText: 'ID-Verifizierung bestätigt',
    idVerifyFalseText: 'ID-Verifizierung nicht überprüft',
    audioDetectedTrue: 'Audio erkannt',
    audioDetectedFalse: 'Kein Ton erkannt',
    browserToggleTrue: 'Browserumschaltung erkannt',
    browserToggleFalse: 'Keine Browserumschaltung erkannt',
    pastedAnsTrue: 'Eingefügte Antwort erkannt',
    pastedAnsFalse: 'Keine eingefügte Antwort gefunden',
    thirdPartyVoiceTrue: 'Die Stimme eines Drittanbieters wurde erkannt',
    thirdPartyVoiceFalse: 'Keine Stimme eines Drittanbieters erkannt',
    eyeContactTrue: 'Augenkontakt festgestellt',
    eyeContactFalse: 'Kein Augenkontakt festgestellt',
    suspiciousObjectTrue: 'Verdächtiges Objekt erkannt',
    suspiciousObjectFalse: 'Kein verdächtiges Objekt erkannt',
    shareWith: 'Geteilt mit',
    sendReportCoaching: 'Bericht an Antragsteller senden',
    applicantShortlisted: 'Bewerber in die engere Auswahl genommen',
    shortlistApplicant: 'Bewerber in die engere Auswahl nehmen',
    candidateKived: 'Der Kandidat hat gekündigt',
    kivCandidate: 'KIV-Kandidat',
    applicants: 'Bewerber',
    searchApplicants: 'SucheBewerber',
    selectMe: 'wähle mich aus',
    selected: 'SAusgewählt',
    applicantSummary: 'Bewerberzusammenfassung',
    all: 'Alle',
    candidates: 'Kandidaten',
    searchCandidates: 'Kandidaten suchen',
    scoreSettings: 'Bewertungseinstellungen',
    resumeScore: 'Lebenslaufbewertung',
    workmapScore: 'Workmap-Bewertung',
    aiVideoScore: 'AKI-Videobewertung',
    professionalism: 'Professionalität',
    positiveAttitude: 'Positive Einstellung',
    communication: 'Kommunikation',
    sociability: 'Sozialkompetenz',
    recommended: 'Empfohlen',
    contentRelevancy: 'Inhaltsrelevanz',
    reset: 'Zurücksetzen',
    saving: 'Saving...',
    save: 'Save',
    new: 'Neu',
    unlockCandidate: 'Diesen Bewerber freischalten',
    scoreSettingsInfo: 'Damit können Sie die Gewichtung von Lebenslauf, WorkMap, and KI-Videobewertung steuern. Wählen Sie die gewünschte Gewichtung so, dass sie in der Summe 100 ergibt.',
    deleteCandidateInfo: 'Der Bewerber hat sein Profil gelöscht, daher können Sie nicht mehr auf seine Informationen zugreifen.',
    overallScore: 'Gesamtbewertung',
    overall: 'Gesamt',
    hired: 'Eingestellt',
    hire: 'Mich einstellen',
    appliedDate: 'Bewerbungsdatum',
    changeSchedule: 'Plan ändern',
    scheduleInterview: 'Bewerbungsgespräch planen',
    downloadReport: 'Bericht herunterladen',
    shareProfile: 'Dieses Profil teilen',
    sendReport: 'Bericht an Bewerber schicken',
    candidateShortlisted: 'Bewerber in die engere Auswahl genommen',
    shortlistCandidate: 'Bewerber in die engere Auswahl nehmen',
    keepInView: 'Vormerken',
    keptInView: 'Vorgemerkt',
    candidateRejected: 'Bewerber abgelehnt',
    rejectCandidate: 'Bewerber ablehnen',
    rejected: 'Rejected',
    reject: 'Ablehnen',
    inviteActivity: 'Aktivität einladen',
    interviewScheduledOn: ' Bewerbungsgespräch geplant am',
    activity: 'Aktivität',
    contactedUser: 'Benutzer kontaktiert am',
    noQuestionsAttempted: 'Keine Fragen versucht',
    videoTranscript: 'Videoabschrift',
    candidateSummary: 'Zusammenfassung des Bewerbers',
    insightsDisclaimer: 'Disclaimer: Bitte verwenden Sie die Erkenntnisse nach eigenem Ermessen, um Bewerber für Vorstellungsgespräche oder Einstellungsanrufe in die engere Auswahl zu nehmen.',
    skill: 'Kompetenz',
    education: 'Bildung',
    experience: 'Erfahrung',
    shareShortlist: 'Engere Auswahl teilen',
    inviteSent: 'Einladung gesendet',
    interviewVideo: 'Interview-Video',
    location: 'Location',
    email: 'Email',
    phone: 'Telefonnummer',
    linkedIn: 'LinkedIn',
    includeEmail: 'E-Mail beifügen',
    rejectCandidateInfo: 'Bitte bestätigen Sie, dass Sie die folgenden Bewerber ablehnen möchten:',
    emailSubject: 'E-Mail-Betreff',
    dear: 'Sehr geehrte(r)',
    rejectReason: 'Ablehnungsgrund',
    rejecting: 'Ablehnen',
    emailPreview: 'E-Mail-Vorschau',
    selectEventType: 'Calendly-Termintyp auswählen',
    calendlyEventCreated: 'Vergewissern Sie sich, dass Sie einen Calendly-Termin erstellt haben, oder erstellen Sie einen hier.',
    noCalendlyEventFound: 'Kein Calendly-Termin gefunden',
    sender: 'Absender',
    editEmailSubject: 'E-Mail-Betreff bearbeiten',
    checkEditEmailContent: 'E-Mail-Inhalt prüfen und bearbeiten',
    lookForwardMeetingYou: 'wir freuen uns darauf, Sie bald kennenzulernen',
    sending: 'Senden …',
    scheduleAndSend: 'Planen und senden',
    yesRejectCandidate: 'Ja, Bewerber ablehnen',
    andSendEmail: ' und E-Mail senden',
    yourFeedback: 'Ihr Feedback',
    feedbackInstruction1: 'Maximale Zeichenzahl: 5000',
    overallFeedback: 'Allgemeines Feedback',
    hiringManager: 'Personalverantwortliche/r',
    notes: 'Anmerkungen',
    status: 'Status',
    noSummary: 'Keine Zusammenfassung',
    videoScoreTooltip: 'Das firmeneigene Framework von Interviewer.AI liefert Bewertungen für die gesprochenen Worte, die Stimme  sowie die Mimik der Bewerber während des Vorstellungsgesprächs. Bewerber erhalten für jeden Bewertungsparameter eine Punktzahl auf einer Skala von 1 bis 100. Diese Punktzahlen werden zu einer Gesamtpunktzahl für das Videointerview zusammengerechnet. Eine höhere Punktzahl auf dem Balken bedeutet, dass der Bewerber auf der Grundlage objektiver Bewertungen seines Verhaltens im Videointerview besser abgeschnitten hat als bei Videointerviews, die auf der Plattform aufgenommen wurden, sowie im Vergleich zu öffentlichen Datensätzen.',
    professionalismTooltip: 'Es wird anhand des Verhaltens des Bewerbers (formell/informell) gemessen und untersucht, wie der Bewerber sich im Video präsentiert. Außerdem wird erfasst, wo der Augenkontakt während der Videoantwort aufrechterhalten wird und  ob Unruhe zu beobachten ist. Ja, eine formelle Kleidung wäre von Vorteil.',
    positiveAttitudeTooltip: 'Einstellung, wie der Name schon sagt, sucht nach Gefühlen zusammen mit einer Kombination aus Energie und Emotion. Dieses Modell betrachtet eine Kombination von Metriken aus Kommunikation und Soziabilität (z. B. zuversichtlich, ermutigt, positiv)',
    communicationTooltip: 'Hier wird in erster Linie die Klarheit der Sprache des Bewerbers gemessen. Außerdem werden der Redefluss und die Modulation der Sprache bewertet. Deutliches Sprechen und ein angemessenes Sprechtempo sind hier von Vorteil. Variationen in der Tonlage an angemessenen Stellen im Video wären von Vorteil.',
    sociabilityTooltip: 'Dieses KI-Modell konzentriert sich mehr auf die Bewertung der Gefühlslage des Bewerbers und berücksichtigt, wie der Kandidat kommuniziert und positive zwischenmenschliche Fähigkeiten und Taktgefühl zeigt. Es sucht in der Regel nach positiven Gefühlswörtern und dem Energieniveau eines Bewerbers.',
    contentRelevancyTooltip: 'Die Relevanz der Antwort wird als Prozentsatz angezeigt, um den Umfang zu kennzeichnen, in dem sie die Frage beantwortet. Ein höherer Prozentsatz deutet auf ein höheres Maß an Relevanz hin, während ein niedrigerer Prozentsatz möglicherweise auf eine weniger relevante Antwort hinweist. Diese Informationen helfen Ihnen dabei, die Genauigkeit und Eignung der bereitgestellten Antwort einzuschätzen.',
  },
  scheduleInterview: {
    email: 'Email Preview',
    selectDateTimeDuration: '1. Select date time and duration',
    scheduleDescription: 'You can schedule an interview invite with candidate here.We will send an email to you and your candidate.',
    dateAndTime: 'Date and time',
    duration: 'Duration',
    sender: '2. Sender',
    editEmailSubject: ' 3. Edit the Email Subject',
    checkEditEmailContent: '4. Check and edit the Email Content',
  },
  candidateListing: {
    removeSelectedApplicants: 'Ausgewählte Bewerber entfernen',
    removeSelectedApplicantInfo: 'Sind Sie sicher, dass Sie diese Bewerber entfernen möchten?',
    editApplicantDetail: 'Bewerberdaten bearbeiten',
    unlockApplicant: 'Schalten Sie diesen Bewerber frei',
    kivApplicant: 'KIV-Antragsteller',
    deleteApplicant: 'Bewerber löschen',
    copyApplicantInterviewLink: 'Link zum Bewerberinterview kopieren',
    editApplicant: 'Bewerber bearbeiten',
    applicantDateBetween: 'Bewerberdatum zwischen',
    filterApplicants: 'Bewerber filtern',
    selectToDeleteCoaching: 'Wählen Sie Bewerber zum Löschen aus',
    deleteSelectedCandidatesCoaching: 'Ausgewählte Bewerber löschen',
    downloadApplicantDetails: 'Bewerberdaten herunterladen',
    selectToRejectCoaching: 'Wählen Sie Bewerber zum Ablehnen aus',
    rejectSelectedCoaching: 'Ausgewählte Bewerber ablehnen',
    selectToShareCoaching: 'Wählen Sie Bewerber zum Teilen aus',
    shareSelectedCoaching: 'Ausgewählte Bewerber teilen',
    addApplicant: 'Neuen Bewerber hinzufügen',
    addApplicantToInterview: 'Add the following applicant to the interview',
    enterCandidateName: 'Geben Sie den Kandidatennamen ein',
    enterApplicantName: 'Geben Sie den Namen des Bewerbers ein',
    enterCandidateEmail: 'Geben Sie die E-Mail-Adresse des Kandidaten ein',
    enterApplicanteEmail: 'Geben Sie die E-Mail-Adresse des Bewerbers ein',
    enterCandidateNumber: 'Geben Sie die Kontaktnummer des Kandidaten ein',
    enterApplicantNumber: 'Geben Sie die Kontaktnummer des Bewerbers ein',
    applicantsTo: 'Bewerber für',
    notifyApplicants: 'Bewerber benachrichtigen',
    sendMessagesToMultipleApplicants: 'Senden Sie von hier aus Nachrichten an mehrere Bewerber.',
    searchApplicants: 'Bewerber suchen',
    newApplicant: 'Neuer Bewerber',
    totalApplicants: 'Gesamtzahl der Bewerber',
    totalCandidates: 'Gesamtkandidat',
    yetToRespond: 'Noch keine Antwort',
    editCandidate: 'Bewerber bearbeiten',
    copyInterviewLink: 'Link zum Bewerbungsgespräch kopieren',
    deleteCandidate: 'Bewerber löschen',
    editCandidateDetail: 'Bewerberdaten bearbeiten',
    name: 'Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    selectToDelete: 'Bewerber zum Löschen auswählen',
    deleteSelectedCandidates: 'Ausgewählte Bewerber löschen',
    selectToReject: 'Bewerber zum Ablehnen auswählen',
    rejectSelected: 'Ausgewählte Bewerber ablehnen',
    selectToShare: 'Bewerber zum Teilen auswählen',
    shareSelected: 'Ausgewählte Bewerber teilen',
    newCandidate: 'Neuer Bewerber',
    videoScore: 'Videopunktzahl',
    sendVideoInterviewLink: 'Link zum Video-Interview senden',
    select: 'Auswählen',
    selected: 'ausgewählt',
    candidate: 'Kandidat',
    candidatesTo: 'Bewerber zum',
    applicant: 'Bewerber',
    notify: 'Benachrichtigen',
    importByCSV: 'Per CSV importieren',
    addCandidate: 'Neuen Bewerber hinzufügen',
    addCandidateToInterview: 'Folgenden Bewerber zum Interview hinzufügen',
    enterContactNumber: 'Kontaktnummer des Bewerbers eingeben',
    removeSelectedCandidates: 'Ausgewählte Bewerber entfernen',
    removeSelectedCandidateInfo: 'Möchten Sie diese Bewerber wirklich entfernen?',
    noCandidatesInfo: 'Noch keine Bewerber. Senden Sie jetzt Interviews an Bewerber!',
    notifyCandidate: 'Bewerber benachrichtigen',
    sendMessagesToMultipleCandidates: 'Senden Sie von hier aus Nachrichten an mehrere Bewerber.',
    sendEmailsTo: 'E-Mails senden an',
    emailPreviewInfo: 'Unten sehen Sie eine Vorschau der E-Mail, die an die Bewerber geschickt wird. Sie können den gelben Bereich ändern, damit er besser zu Ihren Nachrichten passt.',
    emailPreviewInfo2: 'Bitte klicken Sie auf den nachstehenden Link und nehmen Sie Ihre Antwort auf.',
    recordResponse: 'Antwort aufnehmen',
    lookForwardForResponse: 'Wir freuen uns auf Ihre Antwort, viel Glück!',
    filterCandidates: 'Bewerber filtern',
    downloadCandidateDetails: 'Bewerberdetails herunterladen',
    importCandidates: 'Bewerber importieren',
    fileName: 'Dateiname',
    bulkImportCandidateInfo1: 'Der CSV-Inhalt sollte E-Mail-Adresse, Name und Telefonnummer (optional) enthalten. Die Reihenfolge ist nicht relevant.',
    actions: 'Aktionen',
    chooseFile: 'Datei auswählen',
    addCandidates: 'Bewerber hinzufügen',
    candidateImportInfo1: 'Keine zu importierenden Bewerberdaten',
    duplicateEntry: 'Eintrag für E-Mail-Adresse duplizieren',
    duplicateEntryPhone: 'Eintrag für Telefonnummer duplizieren',
    duplicateEntryInfo: 'Eintrag duplizieren für',
    inCSVsheet: 'in CSV-Datei',
    alreadyExist: 'existiert bereits in Bewerberliste',
    enterValidName: 'Bitte gültigen Namen eingeben',
    enterValidEmail: 'Bitte gültige E-Mail-Adresse eingeben',
    enterValidPhone: 'Bitte gültige Telefonnummer eingeben',
    enterValid: 'Bitte gültig eingeben:',
    invalidEmail: 'Der CSV-Inhalt enthält eine ungültige E-Mail-Adresse',
    invalidPhone: 'Der CSV-Inhalt enthält eine ungültige Telefonnummer',
    isMandatoryForAllCandidates: 'ist für alle Bewerber obligatorisch',
    csvContailsInvalid: 'Der CSV-Inhalt enthält ungültige',
    csvImportInfo1: 'Die Datei ist keine CSV- oder Excel-Datei',
    csvImport2: 'Die CSV/Excel-Datei muss Name oder E-Mail-Adresse enthalten',
    columnInHeader: 'Spalte in der Kopfzeile',
    reactivateCandidateLink: 'Kandidatenlink reaktivieren',
    reactivateApplicantLink: 'Bewerberlink reaktivieren',
  },
  insights: {
    candidateLocation: 'Standort des Bewerbers',
    genderDiversity: 'Geschlechtervielfalt',
    jobTitle: 'Job Title',
    colleges: 'Hochschulen',
    educationGraph: 'Bildungsgrafik',
    sourceOfApplication: 'Quelle der Bewerbung',
    resetMap: 'Karte zurücksetzen',
    emailSentOpened: 'E-Mails gesendet, geöffnet und beantwortet',
    showingLatest: 'Die 5 letzten Interviews werden angezeigt',
    emptyGenderInfo: 'Noch keine Angaben zum Geschlecht',
    insights: 'Insights',
    interested: 'I\'m Interested!',
    contactUs: 'Kontakt',
    noEmailEventInfo: 'Noch keine Informationen zu E-Mail-Ereignissen',
    noSourceInfo: 'Noch keine Quellenangaben',
  },
  footer: {
    copyRightText: 'Copyright © {{currentYear}} Interviewer.AI. Alle Rechte vorbehalten.',
  },
  shareProfile: {
    shareThisProfile: 'Dieses Profil teilen',
    shareCandidates: 'Bewerber teilen',
    enterEmailSubject: 'E-Mail-Betreff eingeben',
    selectedCandidate: 'Ausgewählter Bewerber',
    listName: 'Name der Liste',
    shareWith: 'Teilen mit',
    info1: 'Drücken Sie Enter, um separate E-Mails hinzuzufügen.',
    secureAccess: 'Sicherer Zugang',
    optional: 'optional',
    enterAccessCodeHere: 'Zugangscode hier eingeben',
    shareLink: 'Link teilen',
    copyLink: 'Link kopieren',
    note: 'Anmerkung',
    enterNote: 'Anmerkung eingeben',
    undoKIV: 'Vormerkung aufheben',
    shortlist: 'Engere Auswahl',
    undoShortlist: 'Engere Auswahl aufheben',
    undoReject: 'Ablehnung aufheben',
    feedbackInfo1: 'Teilen Sie Ihre Meinung zu diesem Bewerber, z. B.: Vereinbaren Sie einen Termin mit mir am …',
  },
  candidateFilter: {
    candidateDateBetween: 'Bewerberdatum zwischen',
    selectStartDate: 'Anfangsdatum auswählen',
    selectEndDate: 'Enddatum auswählen',
    kived: 'Vorgemerkt',
    filterKeyworkds: 'Schlüsselwörter in Titel/Lebenslauf filtern',
    resetFilter: 'Filter zurücksetzen',
    applyFilter: 'Filter anwenden',
    keywordFilterInfo: 'Drücken Sie Enter oder Tab, um ein separates Schlüsselwort hinzuzufügen.',
  },
  insightsPayWall: {
    coachingHeading: 'Entfesseln Sie die Kraft der datengesteuerten Auswahl',
    coachingMessage: 'Erschließen Sie das verborgene Potenzial Ihres Bewerber-Screening-Prozesses, indem wir Ihnen einen Schatz an unschätzbar wertvollen Daten und intelligenten Analysen bieten. Tauchen Sie ein in eine Welt, in der jedes Vorstellungsgespräch zu einer Gelegenheit wird, tiefe Einblicke in die Bewerber zu gewinnen und so jedes Mal erstklassige Auswahlentscheidungen zu treffen.',
    heading: 'Entfesseln Sie die Kraft der datengetriebenen Einstellung',
    message: 'Entdecken Sie das verborgene Potenzial Ihres Bewerberauswahlprozesses, während wir Ihnen eine wahre Schatzkammer unverzichtbarer Daten und intelligenter Analysen präsentieren. Tauchen Sie ein in eine Welt, in der jeder Bewerber-Screening-Prozess die Möglichkeit bietet, tiefe Einblicke in Kandidaten zu gewinnen und Ihnen dabei zu helfen, bei jeder Einstellungsentscheidung Spitzenleistungen zu erzielen.',
    interested: 'Ich bin interessiert',
  },
  candidateFilters: {
    filterCandidates: 'Kandidaten filtern',
    applciationRecievedBetween: 'Eingegangene Bewerbungen zwischen',
    startDate: 'Startdatum',
    endDate: 'Endtermin',
    location: 'Standort',
    selectLocation: 'Ort auswählen',
    candidateStatus: 'Kandidatenstatus',
    selectStatus: 'Wählen Sie Status aus',
    recruiterStatus: 'Personalvermittlerstatus',
    introQuestions: 'Einführungsfragen',
    selectOptions: 'Optionen wählen',
    reset: 'Zurücksetzen',
    applyFilter: 'Filter anwenden',
  },
  newPlansPage: {
    expiryMessage: 'Das Guthaben Ihres Interviewer.AI-Kontos ist abgelaufen. Kaufen Sie Credits, um unsere Videointerviews weiterhin nutzen zu können.',
    selectCredits: 'wählen sie credits aus',
    egText: 'Beispiel: 500 Credits = 500 Videointerviews',
    pricingMainText: 'Sehen Sie sich Preisoptionen an, um Ihre Einstellung zu automatisieren',
    pricingMainSecondaryText: 'Nutzen Sie eine Reihe von Funktionen und Vorteilen, die speziell für Sie entwickelt wurden.',
    assessmentScoreIncHeading: 'Was beinhalten die Beurteilungsergebnisse der Kandidaten??',
    resumeScore: 'Lebenslaufpunktzahl',
    assessmentScorePrimHeading: 'Interviewer.AI bietet die umfassendste Top-of-Funnel-Rekrutierungs-Screening-Lösung, die drei Grundkomponenten umfasst',
    resumeSectionPara1: 'Resume Score nutzt optische Zeichenerkennung (OCR) und Deep Natural Language Processing (NLP)-Algorithmen, um den Text aus Lebensläufen zu extrahieren und ihn mit der Stellenbeschreibung abzugleichen.',
    resumeSectionPara2: 'Der KI-Score ersetzt die traditionelle Stichwortsuche (die Personalvermittler größtenteils einsetzen) und ersetzt sie durch Deep-Learning-Algorithmen, die mithilfe der Erkennung benannter Entitäten den Kontext hinter jedem Wort in einem Lebenslauf verstehen.',
    resumeSectionPara3: 'Es liefert Ihnen absolute KI-Werte, basierend auf einer Datenbank mit 120 Millionen Lebensläufen.',
    workmapScore: 'Workmap-Score',
    workmapSectionPara1: 'Die WorkMap bewertet die Fähigkeiten und Kompetenzen der Kandidaten auf der Grundlage spezifischer Rollen, für die sie sich beworben haben.',
    workmapSectionPara2: 'Die erforderlichen Kompetenzen zur Ausführung bestimmter Arbeitsaufgaben variieren je nach Branche, Funktion oder Rolle. Mit der WorkMap werden Kandidaten hinsichtlich kritischer Kompetenzen bewertet, die ihnen helfen werden, in ihren künftigen Rollen erfolgreich zu sein.',
    workmapSectionPara3: 'Die Bewertung basiert auf einer 5-Punkte-Likert-Skala zur Verträglichkeit mit 25–35 Fragen, bei denen jede Fähigkeit anhand von 5–7 Aussagen bewertet wird',
    aiVideoScore: 'KI-Video-Score',
    aiVideoSectionPara1: 'Das proprietäre Framework von Interviewer.Al bietet Bewertungen für die gesprochenen Worte, die Stimme und den Gesichtsausdruck der Kandidaten während des Videointerviews.',
    aiVideoSectionPara2: 'Für jeden Bewertungsparameter erhalten die Kandidaten eine Punktzahl von maximal 100. Diese Ergebnisse werden zu einer Gesamtpunktzahl für ihr Videointerview zusammengefasst.',
    aiVideoSectionPara3: 'Eine höhere Punktzahl auf der Leiste würde darauf hinweisen, dass der Kandidat basierend auf objektiven Bewertungen seines Verhaltens im Videointerview auf der Grundlage öffentlicher und Plattform-Datensätze besser abgeschnitten hat.',
    quickPreviewText: 'Hier ist eine kurze Vorschau',
    all: 'Planen',
    plans: 'Merkmale',
    yourAiCredits: 'Ihre Credits für AI-Interviews',
    myCurrentPlan: 'Mein aktueller Plan',
    active: 'Aktiv',
    expDate: 'Verfallsdatum',
    addYourCred: 'Fügen Sie Ihre Credits hinzu',
    addNewCred: 'Kaufguthaben',
    videoInt: 'Videointerviews',
    availCred: 'verfügbarer Kredit',
    addCred: 'Credits hinzufügen',
    newCred: 'Neue Credits',
    validOn: 'Gültig bis',
    price: 'Preis',
    congText: 'Glückwunsch!',
    unlockPlan: 'Sie haben die freigeschaltet {{activeCurrentPlan}} Planen.',
    unlockEnterprisePlan: 'Sie qualifizieren sich für unsere {{activeCurrentPlan}} Planen.',
    schedDemo: 'Vereinbaren Sie eine Demo',
    buyNow: 'Kaufe jetzt',
    unlocked: 'Entsperrt',
    starterPlanHeaderText: 'Zur begrenzten Einstellung',
    growthPlanHeaderText: 'Für eine beschleunigte Einstellung',
    premiumPlanHeaderText: 'Für skalierende Teams',
    benifits: 'Vorteile',
    userLicense: 'Benutzerlizenz',
    admin: 'Administrator',
    recuiter: 'Personalvermittler',
    accountAlloc: 'Kontozuordnungen',
    noOfJobsInterviews: 'Anzahl der Vorstellungsgespräche',
    noOfAIInterAssessments: 'Anzahl der AI-Interview-Bewertungen',
    generativeAiAssist: 'Generative KI-Unterstützung',
    autoGenJobDes: 'Automatisch generierte Stellenbeschreibung',
    candSummay: 'Zusammenfassung der Kandidaten',
    genAiQuesGeneration: 'Generation AI-Fragengenerierung',
    socialFeatures: 'Soziale Funktionen',
    careerPage: 'Karriereseite',
    linkWithJobPost: 'Link zu LinkedIn-Stellenanzeigen',
    stackRankAlgo: 'Stack-Ranking-Algorithmus',
    resumeScoring: 'Bewertung fortsetzen',
    workmapAssess: 'WorkMap-Bewertungen',
    scoreSettings: 'Score-Einstellungen',
    contentReleScore: 'Inhaltsrelevanz-Score',
    contentReleScoreBreakdown: 'Aufschlüsselung des Content-Relevanz-Scores',
    support: 'Unterstützung',
    emailSupport: 'Email Unterstützung',
    dedicatedAccountManger: 'Dedizierter Account Manager',
    expired: 'Abgelaufen',
    infoIconText: 'Credit = eine Videorezension',
  },
  expiryPlanModal: {
    firstMesssage: 'Ihr kostenloses Guthaben verfällt in',
    firstNonTrialMesssage: 'Ihr Guthaben verfällt in',
    day: 'Tag.',
    days: 'Tage.',
    secondMessage: 'Kaufen Sie jetzt Credits vor Ablauf und erhalten Sie 20 % Rabatt auf jedes Paket.',
    thirdMessage: 'Code verwenden',
    fourthMessage: 'an der Kasse, um dieses Angebot in Anspruch zu nehmen',
  },
  creditsAlertText: {
    message: 'Sie haben das zugewiesene Guthaben auf Ihrem Konto überschritten. Um auf die Antworten und Bewertungen der neuen Kandidaten zuzugreifen, kaufen Sie bitte sofort Credits.',
  },
};
