import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from '../css/GeneralModal.module.scss';


const GeneralModal = (props) => {
  const {
    isModalOpen,
    message,
    title,
    handleCloseGeneralModal,
  } = props;


  return (
    <Modal show={isModalOpen} onHide={() => handleCloseGeneralModal(!isModalOpen)} backdrop>
      <Modal.Header bsClass={styles.header}>
        <Modal.Title className="center_items">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className={styles.infoMessage}>
          {message}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={styles.cancelButton}
          onClick={() => { handleCloseGeneralModal(!isModalOpen); }}
        >
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

GeneralModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleCloseGeneralModal: PropTypes.func.isRequired,
};


export default GeneralModal;
