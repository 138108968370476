const config = {
  AUTH0: {
    domain: '',
    audience: '',
    namespace: '',
    redirectUri: '',
  },
  MIXPANEL_TOKEN: '',
  STRIPE_PUBLISHABLE_KEY: '',
  GTM: {
    id: '',
    auth: '',
    env: '',
  },
  SPECIAL_COACHING_SCORING_USER: [],
};

export default config;
