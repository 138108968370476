import React from 'react';
import {
  Col, Glyphicon, DropdownButton, MenuItem, Row,
} from 'react-bootstrap';
import { RingLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import classnames from 'classnames';
import ActivityRings, {
  ActivityRingsConfig,
  ActivityRingsData,
} from 'react-activity-rings';
import Switch from 'react-switch';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';
import i18n from '../../locales/i18n';

import CandidateAssessmentForm from '../../components/Candidates/CandidateAssessmentForm';
import 'react-vertical-timeline-component/style.min.css';
import OrganizationStore from '../../stores/OrganizationStore';
import { ReactComponent as LinkedIcon } from '../../assets/u_linkedin_alt.svg';
import PhoneIcon from '../../assets/PhoneIcon.svg';
import { ReactComponent as EmailIcon } from '../../assets/Envelope_alt.svg';
import ScoreCircle from '../../components/CircularScoreChart';
import { ReactComponent as MapPin } from '../../assets/MapPin.svg';
import {
  mxTrackEvent,
  getYYYYMMDD,
  getResponseStatusColorClass,
  convertKeyToTitle,
  generateSummaryFromParsedResume,
  getInitial,
  getAverage,
  getProgressColorClass,
} from '../../utils/utils';

import CandidateStore from '../../stores/CandidateStore';
import SettingStore from '../../stores/SettingStore';
import CandidateActions from '../../actions/CandidateActions';
import ShareCandidatesModal from '../../components/ShareCandidatesModal';
import InfoTooltip from '../../components/InfoTooltip';
import CandidateMetadataRow from '../../components/Candidates/CandidateMetadataRow';
import { alertToast, successToast } from '../../components/Toast';

import ScheduleInterviewEmailPreview from '../../components/Interviews/ScheduleInterviewEmailPreview';
import ScheduleCalendlyEmailPreview from '../../components/Interviews/ScheduleCalendlyEmailPreview';
import styles from '../../css/CandidateDetailsAIA.module.scss';
import '../../css/CandidatesDetail.css';
import RejectModalEmailPreview from '../../components/Candidates/RejectModalEmailPreview';

import infoIcon from '../../assets/icons-info.svg';
// import OrganizationStore from '../../stores/OrganizationStore';
import Auth from '../../auth/Auth';
import { ReactComponent as ResumeIconNew } from '../../assets/resume_icon_new.svg';
import LoadingComponent from '../../components/LoadingComponent';
import More from '../../assets/More.svg';
import AdditionalQuestion from './AdditionalQuestion';
import CandidateProfileButtonMobile from '../../components/CandidateProfileButtonMobile';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as OverallScoreIcon } from '../../assets/OverallIcon.svg';

import CandidateInformation from '../../components/CandidateInformation';
import AiScoreComponent from './AiScoreComponent';
import DomainQuestion from './DomainQuestion';
import clientConfig from '../../config/config';

class CandidatesDetailsAIA extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef(null);
    this.scoreRef = React.createRef(null);

    this.state = {
      ...CandidateStore.getState(),
      // subscriptionLimits: OrganizationStore.getState().subscriptionLimits,
      showShareShortlistModal: false,
      showScheduleInterviewModal: false,
      questionListMore: false,
      hasLoadedProfileData: false,
      candidateSearch: '',
      showAssessmentLimitModal: false,
      // specialScoringEnabled: isSpecialScoringEnabled(),
      removeBranding: OrganizationStore.getState().removeBranding,
      transcriptVisibility: true,
      videoStatus: 'pause',
      videoDuration: 0,
      isSearchBoxOpen: false,
      activeContentTabMobile: 'VideoContentMobile',
      workmapColorCodes: [],
      isFetchWorkmapColorCodes: true,
      organisationId: null,
      watchedVideosLocalData: [],
    };

    this.onChange = this.onChange.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.shareShortlist = this.shareShortlist.bind(this);
    this.onQuestionClick = this.onQuestionClick.bind(this);
    this.toggleShowShortlistedModal = this.toggleShowShortlistedModal.bind(this);
    this.handleCandidatesShareSubmit = this.handleCandidatesShareSubmit.bind(this);
    this.toggleQuestionListMore = this.toggleQuestionListMore.bind(this);
    this.toggleShowScheduleInterviewModal = this.toggleShowScheduleInterviewModal.bind(this);
    // this.renderScheduleInterviewButton = this.renderScheduleInterviewButton.bind(this);
    this.renderCandidateInfo = this.renderCandidateInfo.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.toggleAssessmentLimitModal = this.toggleAssessmentLimitModal.bind(this);
    this.disableTranscript = this.disableTranscript.bind(this);
    this.handlePlayVideo = this.handlePlayVideo.bind(this);
    this.handlePauseVideo = this.handlePauseVideo.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.onLoadVideo = this.onLoadVideo.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.openSearchBox = this.openSearchBox.bind(this);
    this.downloadCandidateReport = this.downloadCandidateReport.bind(this);
    this.sendCandidateReport = this.sendCandidateReport.bind(this);
    this.getRandomColor = this.getRandomColor.bind(this);
    this.overallWorkmapScore = this.overallWorkmapScore.bind(this);
    this.getWorkmapColors = this.getWorkmapColors.bind(this);
    this.handleScrollToScoring = this.handleScrollToScoring.bind(this);
    this.scrollToRef = this.scrollToRef.bind(this);
    this.handleSeenInterviewVideo = this.handleSeenInterviewVideo.bind(this);
  }

  async componentDidMount() {
    i18n.changeLanguage('id');
    sessionStorage.setItem('LANGUAGE', 'id');
    SettingStore.listen(this.onChange);
    CandidateStore.listen(this.onChange);
    OrganizationStore.listen(this.onChange);
    const { interviewId, location, candidateId } = this.props;
    const candidateEmailParam = new URLSearchParams(location.search).get(
      'email',
    );

    await CandidateActions.resetFilters.defer();
    // Get All Candidates info and Calendly eligibility workflow
    await CandidateActions.getSingleCandidate.defer(
      interviewId,
      true,
      candidateEmailParam,
      candidateId,
    );

    this.setState({
      hasLoadedProfileData: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    //eslint-disable-line
    const { location } = this.props;
    const candidateEmailParam = new URLSearchParams(location.search).get(
      'email',
    );
    const { selectedCandidatesIndex, selectedQuestionIndex } = this.state;
    if (prevState.displayData !== this.state.displayData) {        //eslint-disable-line
      if (!candidateEmailParam) {
        setTimeout(async () => {
          await this.getVideoUrl(
            selectedCandidatesIndex,
            selectedQuestionIndex,
          );
        }, 0);
      }
    }
  }

  componentWillUnmount() {
    CandidateStore.unlisten(this.onChange);
    OrganizationStore.unlisten(this.onChange);
    SettingStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);

    this.setState({
      organisationId: SettingStore.getState().profileData.orgId,
    });
    this.fetchRandomColor();
  }

  async onQuestionClick(index) {
    const { selectedCandidatesIndex } = this.state;

    await CandidateActions.updateIndex({
      selectedCandidatesIndex,
      selectedQuestionIndex: index,
    });

    await this.getVideoUrl(selectedCandidatesIndex, index);
  }

  async onVideoPlay(candidateData, candidateIndex) {
    const { interviewId } = this.props;
    await CandidateActions.updateCandidateViewed(
      candidateIndex,
      interviewId,
      candidateData,
    );
    this.setState({
      videoStatus: 'play',
    });
    CandidateActions.setAutoPlay();
  }

  async onVideoEnded() {
    const {
      selectedQuestionIndex,
      selectedCandidatesIndex,
      displayData,
      candidateQuestions,
    } = this.state;
    const candidateData = displayData[selectedCandidatesIndex];
    const { questions } = candidateQuestions.find(
      data => data.candidateId === candidateData.candidateid,
    );

    if (selectedQuestionIndex !== questions.length - 1) {
      await CandidateActions.updateIndex({
        selectedCandidatesIndex,
        selectedQuestionIndex: selectedQuestionIndex + 1,
      });

      await this.getVideoUrl(
        selectedCandidatesIndex,
        selectedQuestionIndex + 1,
      );
    }

    this.setState({
      videoStatus: 'pause',
    });
  }

  onLoadVideo(e) {
    this.setState({
      videoDuration: e.currentTarget.duration,
    });
  }

  getSummary = (data) => {
    if (data.metadata && data.metadata.profile_summary) {
      return data.metadata.profile_summary;
    }

    if (
      data.metadata
      && data.metadata.parsed_resume
      && data.metadata.parsed_resume.resume
    ) {
      if (data.metadata.parsed_resume.resume.iai_summary) {
        return data.metadata.parsed_resume.resume.iai_summary;
      }
      if (data.metadata.parsed_resume.resume.summary) {
        return data.metadata.parsed_resume.resume.summary;
      }
      // Legacy candidates who do not have summary field
      return generateSummaryFromParsedResume(
        data.name,
        data.metadata.parsed_resume.resume,
      );
    }

    return 'No summary';
  };

  async getVideoUrl(candidateIndex, questionIndex) {
    const { displayData, videoCache, candidateQuestions } = this.state;
    const { interviewId } = this.props;
    if (displayData.length !== 0) {
      const candidateData = displayData[candidateIndex];
      const { questions } = candidateQuestions.find(
        data => data.candidateId === candidateData.candidateid,
      );
      CandidateActions.getVideoUrlForAIA(
        interviewId,
        candidateIndex,
        questionIndex,
        displayData,
        questions,
        videoCache,
      );
    }
  }

  setActiveTabMoblie(activeTab) {
    if (!activeTab) {
      this.setState({
        activeContentTabMobile: 'VideoContentMobile',
      });
    }

    this.setState({
      activeContentTabMobile: activeTab,
    });
  }

  // eslint-disable-next-line

  getWorkmapColors(score) {     //eslint-disable-line
    if (getProgressColorClass(score) === 'success') {
      return '#5cb85c';
    } if (getProgressColorClass(score) === 'warning') {
      //eslint-disable-line
      return '#f0ad4e';
    }
    return '#fd6144';
  }

  getRandomColor() {      //eslint-disable-line
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    const color = `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},0.3)`;
    return color;
  }

  scrollToRef(ref) {      //eslint-disable-line
    window.scrollTo({
      top: ref.current.offsetTop - 330,
      behavior: 'smooth',
    }); //eslint-disable-line
  }

  shareShortlist() {
    const { hasShortlistedCandidates } = this.state;

    if (!hasShortlistedCandidates) {
      alertToast('No candidate has been shortlisted');
      return;
    }

    this.toggleShowShortlistedModal();
  }

  toggleShowScheduleInterviewModal() {
    const { showScheduleInterviewModal } = this.state;
    this.setState({
      showScheduleInterviewModal: !showScheduleInterviewModal,
    });
  }

  toggleQuestionListMore() {
    const { questionListMore } = this.state;
    this.setState({
      questionListMore: !questionListMore,
    });
  }

  handleSearchTextChange(e) {
    this.setState({
      candidateSearch: e.target.value,
    });
  }

  toggleAssessmentLimitModal() {
    const { showAssessmentLimitModal } = this.state;
    this.setState({
      showAssessmentLimitModal: !showAssessmentLimitModal,
    });
  }

  disableTranscript(checked) {
    this.setState({
      transcriptVisibility: checked,
    });
  }

  truncate(str, n) {      // eslint-disable-line
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  }

  async handleSeenInterviewVideo() {
    const {
      displayData,
      selectedCandidatesIndex,
      selectedQuestionIndex,
      candidateQuestions,
      watchedVideosLocalData,
    } = this.state;
    const { questions } = candidateQuestions[0];

    const candidateData = displayData[selectedCandidatesIndex];
    const {
      candidateid: candidateId,
      interviewid,
      watched_videos: watchedVideos,
    } = candidateData;

    const watched = watchedVideosLocalData;
    watched.push(questions[selectedQuestionIndex].questionid);
    this.setState({
      watchedVideosLocalData: watched,
    });
    if (watchedVideos.length < questions.length) {
      console.log('Updating the watched videos in db');
      CandidateActions.updateWatchVideo(
        interviewid,
        candidateId,
        questions[selectedQuestionIndex].questionid,
      );
    }

    if (watchedVideosLocalData.length === questions.length) {
      try {
        const response = await fetch(
          `${clientConfig.default.AIA_API_ENDPOINT}/recruit/v1/lead/updateVideoFullyPlayed`,
          {
            method: 'POST',
            headers: {
              'Src-Env': 'application.uat',
              'X-Gateway-Apikey': `${clientConfig.default.AIA_GATEWAY_KEY}`,
              'Content-Type': 'application/json',
              'channel-id': 'iRecruit Web',
              Cookie: 'cookiesession1=678ADACEUV0123456789890234AB9894',
            },
            body: JSON.stringify({
              candidateId,
              videoPlayed: 1,
              createDate: moment.utc().format(),
              updateDate: moment.utc().format(),
            }),
          },
        );

        const data = await response.json();
        const { code } = data;
        if (code !== 200) {
          console.log('full video played update failed');
        }
      } catch (error) {
        console.log('Err >>>', error);
      }
    }
  }

  async handleProgress(e) {
    const { videoDuration } = this.state;

    if (videoDuration && videoDuration === e.currentTarget.currentTime) {
      await this.handleSeenInterviewVideo();
    }
    this.setState({
      progressBarTime: e.currentTarget.currentTime,
    });
  }

  handlePlayVideo() {
    this.setState({
      videoStatus: 'play',
    });
    this.videoRef.current.play();
  }

  handlePauseVideo() {
    this.setState({
      videoStatus: 'pause',
    });
    this.videoRef.current.pause();
  }

  async handleCandidatesShareSubmit(
    emails,
    list,
    accessCode,
    emailSubject,
    removeBranding,
  ) {
    const { displayData, interviewId, interviewDetail } = this.state;
    const selectedCandidates = displayData.filter(
      candidate => candidate.shortlisted,
    );
    const listName = list;
    const language = interviewDetail.interview_language;
    await CandidateActions.shareCandidatesList(
      selectedCandidates,
      emails,
      listName,
      interviewId,
      interviewDetail.interviewname,
      language,
      accessCode,
      emailSubject,
      null,
      removeBranding,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  async downloadCandidateReport(
    candidateId,
    candidateName,
    candidateEmail,
    candidatePicUrl,
    candidateData,
    coachingCandidateId,
    interviewId,
    totalScore,
  ) {
    const downloadReport = true;
    const {
      energyLevelScore,
      totalCommunicationScore,
      professionalismScore,
      sociabilityScore,
      // eslint-disable-next-line camelcase
      coaching_candidate_id,
      arousalScore,
      emotionScore,
      sentimentScore,
      communicationScore,
      articulationScore,
      valenceScore,
      dressingScore,
      hairTidinessScore,
      bodyLanguageScore,
    } = candidateData;
    const ai_assessment = {        // eslint-disable-line
      pace: communicationScore,
      clarity: articulationScore,
      sentiment: sentimentScore,
      energy: arousalScore,
      positivity: valenceScore,
      positive_emotion: emotionScore,
    };
    let updatedScore = {};
    // let otherFeedbackText = '';
    let finalFeedbackString = '';
    // eslint-disable-next-line camelcase
    let has_unique_id = false;
    // eslint-disable-next-line camelcase
    let unique_id = '';
    // eslint-disable-next-line camelcase
    let unique_id_label = '';
    console.log('coaching id is....', coachingCandidateId);
    if (coachingCandidateId) {
      // eslint-disable-next-line camelcase
      has_unique_id = true;
      // eslint-disable-next-line camelcase
      unique_id = coaching_candidate_id;
      // eslint-disable-next-line camelcase
      unique_id_label = coachingCandidateId;
    }
    candidatePicUrl = candidatePicUrl || "https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png"; // eslint-disable-line
    try {
      const reqGet = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'get',
        },
      );
      if (reqGet.status >= 200 && reqGet.status < 300) {
        const { ratings } = reqGet;

        const { feedbackUsers } = await reqGet.json();
        const filteredFeedbackUsers = feedbackUsers.filter((x) => x.rating !== "{}"); // eslint-disable-line

        filteredFeedbackUsers.map((x) => {
          if (x.rating && JSON.parse(x.rating).otherFeedback) {
            finalFeedbackString = finalFeedbackString.concat(`
            <p>
             <b> Name </b>: ${x.user && x.user.firstName
                ? x.user.firstName.concat(x.user.lastName)   //eslint-disable-line
                : 'External user'      //eslint-disable-line
} <br/>
             <b> Email </b>: ${x.email || x.hiring_manager_email} <br/>
             <b> Feedback </b> : ${x.rating ? JSON.parse(x.rating).otherFeedback : ''
} <br/>
            </p>
            `);
          }
          return x;
        });

        const { otherFeedback: resOtherFeedback, ...manualAssessment } = ratings;
        const candidateScore = {};
        // otherFeedbackText = resOtherFeedback;

        updatedScore = Object.assign({}, candidateScore, manualAssessment);
      } else {
        throw new Error(reqGet.statusText);
      }
    } catch (error) {
      // eslint-disable-line
    }

    const req = await fetch(
      `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          candidateAssessment: updatedScore,
          otherFeedback: finalFeedbackString,
          candidateName,
          candidatePicUrl,
          downloadReport,
          sendReport: false,
          candidateEmail,
          energyLevelScore,
          totalCommunicationScore,
          professionalismScore,
          sociabilityScore,
          has_unique_id,
          unique_id,
          unique_id_label,
          totalScore: Math.round(totalScore, 2),
          ai_assessment,
          dressingScore,
          hairTidinessScore,
          bodyLanguageScore,
          arousalScore,
          communicationScore,
          articulationScore,
          sentimentScore,
          emotionScore,
        }),
      },
    );

    if (req.status >= 200 && req.status < 300) {
      const contentType = 'application/pdf';
      if (contentType) {
        const resp = await req.blob();

        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(resp);
        link.download = `${candidateName}.pdf`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        successToast('Report downloaded !');
      }
      mxTrackEvent('Manual candidate assessment updated successfully.');
      // CandidateActions.candidateAssessmentUpdated(
      //   candidateId,
      //   candidateScore
      // );
    } else {
      const res = await req.json();
      console.log(res.error);
      throw new Error(res.error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async sendCandidateReport(
    candidateId,
    candidateName,
    candidateEmail,
    candidatePicUrl,
    candidateData,
    coachingCandidateId,
    interviewId,
    totalScore,
  ) {
    const downloadReport = false;
    const {
      energyLevelScore,
      totalCommunicationScore,
      professionalismScore,
      sociabilityScore,
      // eslint-disable-next-line camelcase
      coaching_candidate_id,
      arousalScore,
      emotionScore,
      sentimentScore,
      communicationScore,
      articulationScore,
      valenceScore,
      dressingScore,
      hairTidinessScore,
      bodyLanguageScore,
    } = candidateData;
    const ai_assessment = {  // eslint-disable-line
      pace: communicationScore,
      clarity: articulationScore,
      sentiment: sentimentScore,
      energy: arousalScore,
      positivity: valenceScore,
      positive_emotion: emotionScore,
    };
    let updatedScore = {};
    // let otherFeedbackText = '';
    let finalFeedbackString = '';
    // eslint-disable-next-line camelcase
    let has_unique_id = false;
    // eslint-disable-next-line camelcase
    let unique_id = '';
    // eslint-disable-next-line camelcase
    let unique_id_label = '';
    console.log('coaching id is....', coachingCandidateId);
    if (coachingCandidateId) {
      // eslint-disable-next-line camelcase
      has_unique_id = true;
      // eslint-disable-next-line camelcase
      unique_id = coaching_candidate_id;
      // eslint-disable-next-line camelcase
      unique_id_label = coachingCandidateId;
    }
    candidatePicUrl = candidatePicUrl || "https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png"; // eslint-disable-line
    try {
      const reqGet = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'get',
        },
      );
      if (reqGet.status >= 200 && reqGet.status < 300) {
        const { ratings } = reqGet;

        const { feedbackUsers } = await reqGet.json();
        const filteredFeedbackUsers = feedbackUsers.filter((x) => x.rating !== "{}"); // eslint-disable-line

        filteredFeedbackUsers.map((x) => {
          if (x.rating && JSON.parse(x.rating).otherFeedback) {
            finalFeedbackString = finalFeedbackString.concat(`
            <p>
            <b> Name </b>: ${x.user && x.user.firstName
                ? x.user.firstName.concat(x.user.lastName)    //eslint-disable-line
                : 'External user'             //eslint-disable-line
} <br/>
            <b> Email </b>: ${x.email || x.hiring_manager_email} <br/>
            <b> Feedback </b> : ${x.rating ? JSON.parse(x.rating).otherFeedback : ''
} <br/>
           </p>
            `);
          }
          return x;
        });

        const { otherFeedback: resOtherFeedback, ...manualAssessment } = ratings;
        const candidateScore = {};
        // otherFeedbackText = resOtherFeedback;

        updatedScore = Object.assign({}, candidateScore, manualAssessment);
      } else {
        throw new Error(reqGet.statusText);
      }
    } catch (error) {
      // eslint-disable-line
    }

    const req = await fetch(
      `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          candidateAssessment: updatedScore,
          otherFeedback: finalFeedbackString,
          candidateName,
          candidatePicUrl,
          downloadReport,
          sendReport: true,
          candidateEmail,
          energyLevelScore,
          totalCommunicationScore,
          professionalismScore,
          sociabilityScore,
          has_unique_id,
          unique_id,
          unique_id_label,
          totalScore: Math.round(totalScore, 2),
          ai_assessment,
          dressingScore,
          hairTidinessScore,
          bodyLanguageScore,
          arousalScore,
          communicationScore,
          articulationScore,
          sentimentScore,
          emotionScore,
        }),
      },
    );

    if (req.status >= 200 && req.status < 300) {
      const contentType = 'application/pdf';
      if (contentType) {
        successToast('Report send successfully !');
      }
      mxTrackEvent('Manual candidate assessment updated successfully.');
      // CandidateActions.candidateAssessmentUpdated(
      //   candidateId,
      //   candidateScore
      // );
    } else {
      const res = await req.json();
      console.log(res.error);
      throw new Error(res.error);
    }
  }

  openSearchBox() {
    const searchcontainer = document.getElementById(
      'candidate_search_box_mobile',
    );
    if (searchcontainer) {
      searchcontainer.classList.toggle('open');
      this.setState({
        isSearchBoxOpen: true,
      });
    }
  }

  toggleShowShortlistedModal() {
    const { showShareShortlistModal } = this.state;
    this.setState({
      showShareShortlistModal: !showShareShortlistModal,
    });
  }

  overallWorkmapScore(arr) {   //eslint-disable-line
    const arrayToSend = [];
    arr.forEach(value => arrayToSend.push(+value.score));
    return getAverage(arrayToSend).toFixed(0);
  }

  fetchRandomColor() {
    const { displayData, selectedCandidatesIndex, isFetchWorkmapColorCodes } = this.state;
    const colorArray = [];
    if (displayData.length === 0 || selectedCandidatesIndex === undefined) {
      return;
    }

    if (!isFetchWorkmapColorCodes) {
      return;
    }

    const candidateData = displayData[selectedCandidatesIndex];
    const { metadata } = candidateData;
    const candidateWorkmapScores = metadata && metadata.workmap_score;
    const workmapScoreSkills = candidateWorkmapScores
      ? Object.keys(candidateWorkmapScores)
      : [];

    // eslint-disable-next-line
    workmapScoreSkills.map((x) => {
      colorArray.push(this.getRandomColor());
    });

    this.setState({
      workmapColorCodes: colorArray,
      isFetchWorkmapColorCodes: false,
    });
  }

  handleScrollToScoring() {
    //eslint-disable-line
    this.scrollToRef(this.scoreRef);
  }

  renderVideo() {
    const {
      displayData,
      selectedCandidatesIndex,
      fetchingS3Video,
      fetchS3VideoFail,
      fetchS3VideoFailMessage,
      videoUrl,
      videoCaption,
      candidateQuestions,
      autoplay,
      transcriptVisibility,
    } = this.state;
    if (fetchingS3Video) {
      return <RingLoader color="#fd6144" />;
    }

    if (fetchS3VideoFail) {
      return (
        <div className={styles.emptyStateQuestion}>
          <span className="f16-500-bold-grey">{fetchS3VideoFailMessage}</span>
        </div>
      );
    }

    const candidateData = displayData[selectedCandidatesIndex];
    let questions = [];
    if (candidateData) {
      ({ questions } = candidateQuestions.find(
        data => data.candidateId === candidateData.candidateid,
      ));
    }

    if (!questions || !questions.length) {
      return null;
    }

    return (
      // <div>
      <React.Fragment>
        <div className={styles.videoContainer}>
          <video // eslint-disable-line
            ref={this.videoRef}
            onDurationChange={this.onLoadVideo}
            controlsList="nodownload"
            crossOrigin="anonymous"
            autoPlay={autoplay}
            onTimeUpdate={this.handleProgress}
            onPlay={() => this.onVideoPlay(candidateData, selectedCandidatesIndex)
            }
            onEnded={this.onVideoEnded}
            // playsInline
            className={styles.videoContent}
          >
            <source src={videoUrl} />
            {transcriptVisibility && (
              <track
                label="English"
                kind="captions"
                srcLang="en"
                src={videoCaption}
                default
              />
            )}
          </video>
        </div>
        {/* </div> */}
        {/* <div className="App-content-video-div--screen-caption">
            <h4 className="App-content-video-div--screen-caption-question">
              {questions[selectedQuestionIndex].questiontext}
            </h4>
            <p className="App-content-video-div--screen-caption-text">
              {candidateData && candidateData.caption[selectedQuestionIndex]}
            </p>
          </div>
        </div>
      </div> */}
      </React.Fragment>
    );
  }

  // renderScheduleInterviewButton() {
  //   const { displayData, selectedCandidatesIndex, hasLoadedProfileData } = this.state;
  //   const candidateData = displayData[selectedCandidatesIndex];
  //   return !hasLoadedProfileData ? (
  //     <Button id="skel-scheduleInterview">
  //       <Skeleton width={118} height={20} />
  //     </Button>
  //   ) : (
  //     <React.Fragment>
  //       <Button
  //         className="btn-default"
  //         onClick={this.toggleShowScheduleInterviewModal}
  //         disabled={candidateData.rejected}
  //       >
  //         {candidateData.scheduled_interview_date
  //           ? 'Change Schedule'
  //           : 'Schedule Interview'}
  //       </Button>
  //     </React.Fragment>
  //   );
  // }

  renderCandidateInfo() {
    const { displayData, selectedCandidatesIndex } = this.state;
    const candidateData = displayData[selectedCandidatesIndex];
    const { dateresponded, datecreated } = candidateData;

    const resume = candidateData.metadata
      && candidateData.metadata.parsed_resume
      && candidateData.metadata.parsed_resume.resume;
    const candidateResponseStatusColorClass = getResponseStatusColorClass(
      candidateData.status,
    );
    return (
      <div>
        <CandidateMetadataRow title="Email" value={candidateData.email} />

        {candidateData.phone && (
          <CandidateMetadataRow title="Phone" value={candidateData.phone} />
        )}

        {dateresponded && (
          <CandidateMetadataRow
            title="Response date"
            value={getYYYYMMDD(dateresponded)}
          />
        )}

        {datecreated && (
          <CandidateMetadataRow
            title="Sent / Application date"
            value={getYYYYMMDD(datecreated)}
          />
        )}

        {candidateData.metadata && candidateData.metadata.location && (
          <CandidateMetadataRow
            title="Location"
            value={candidateData.metadata.location}
          />
        )}

        {resume && resume.currentCompany && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Current Company
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.currentCompany}
            </Col>
          </div>
        )}

        {resume && resume.currentTitle && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Current Title
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.currentTitle}
            </Col>
          </div>
        )}

        {resume && resume.skills && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Skills
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.skills.slice(0, 10).join(', ')}
            </Col>
          </div>
        )}

        {
          // insightPro related metadata
          candidateData.metadata
          && Object.keys(candidateData.metadata)
            .filter(k => k.startsWith('insightPro'))
            .map(k => (
              <CandidateMetadataRow
                key={k}
                title={convertKeyToTitle(k, 'insightPro')}
                value={candidateData.metadata[k]}
              />
            ))
        }

        {
          // hospitality related metadata
          candidateData.preferences
          && JSON.parse(candidateData.preferences).map((preference, index) => (
            <CandidateMetadataRow
              key={preference}
              title={`Preference ${index + 1}`}
              value={preference}
            />
          ))
        }

        {
          // additional form fields
          candidateData.metadata
          && candidateData.metadata.additional_fields
          && Object.keys(candidateData.metadata.additional_fields)
            .sort() // so that it's displayed consistenly
            .map(k => (
              <CandidateMetadataRow
                key={k}
                title={k}
                value={candidateData.metadata.additional_fields[k]}
              />
            ))
        }
        <div className="App-content-candidates-detail clearfix">
          <Col xs={3} className="App-content-candidates-detail--title">
            Status
          </Col>
          <Col xs={9} className="App-content-candidates-detail--info">
            <div
              className={`App-content-candidates-detail--info-status ${candidateResponseStatusColorClass}`}
            >
              {candidateData.status}
            </div>
          </Col>
        </div>
      </div>
    );
  }

  renderConditionalClassForWorkmap(value) {  //eslint-disable-line
    if (value >= 0 && value <= 50) {
      return styles.redFlag;
    } if (value >= 51 && value <= 99) {
      return styles.yellowFlag;
    }
    return styles.greenFlag;
  }

  render() {
    const activityConfig: ActivityRingsConfig = { width: 220, height: 220 }; // eslint-disable-line
    const {
      displayData,
      selectedCandidatesIndex,
      selectedQuestionIndex,
      isDataLoading,
      interviewDetail,
      loadDataFail,
      loadDataFailMsg,
      showShareShortlistModal,
      candidateQuestions,
      candidateSearch,
      transcriptVisibility,
      videoStatus,
      videoDuration,
      isSearchBoxOpen,
      activeContentTabMobile,
      removeBranding,
      showScheduleInterviewModal,
      eventTypeData,
      chosenEventTypeUrl,
      currentNameAttribute,
      hasShortlistedCandidates,
      hasLoadedProfileData,
      organisationId,
      workmapColorCodes,
      userNotAuthFromAIA,
      isRecommended,
    } = this.state;

    const {
      interviewname: interviewName,
      metadata: interviewMetadata,
      coaching_candidate_id_label: coachingCandidateId,
      overwrite_fields: overwriteFields,
      company_name: companyName,
      company_logo_url: companyLogoUrl,
      workmap_enabled: workmapEnabled,
      resume_mandatory: resumeMandatory,
    } = interviewDetail;
    const { interviewId } = this.props;
    const { t } = this.props; // eslint-disable-line
    // console.log("candidate questions are : ....", candidateQuestions);

    const overwrittenLogo = overwriteFields && overwriteFields.interview_logo;
    const overwrittenCompanyName = overwriteFields && overwriteFields.company_name;
    if (isDataLoading) {
      return (
        // <RingLoader
        //   color="#fd6144"
        //   className={css`
        //     margin: 20px auto;
        //   `}
        // />
        <div className={styles.loadContainer}>
          <LoadingComponent />
        </div>
      );
    }

    if (userNotAuthFromAIA) {
      return (
        <div className={styles.authDiv}>
          You are not Authorised to view this page...
        </div>
      );
    }

    if (loadDataFail) {
      return <p className="App-content-error">{loadDataFailMsg}</p>;
    }
    const candidateData = displayData[selectedCandidatesIndex];
    const { videoScore, totalScore } = candidateData;
    const { metadata } = candidateData;
    let workmapScoresData = [];
    let workmapScoresDataAIA = [];
    let totalSumOfWorkmapScore = 0;
    const candidateWorkmapScoreAIA = metadata && metadata.aia_workmap_score;
    const candidateWorkmapScores = metadata && metadata.workmap_score;
    if (candidateWorkmapScores) {
      workmapScoresData = Object.entries(candidateWorkmapScores).map(
        ([skill, score]) => ({ skill, score }),
      );
    }
    if (candidateWorkmapScoreAIA) {
      workmapScoresDataAIA = Object.entries(candidateWorkmapScoreAIA).map(
        ([skill, score]) => ({ skill, score }),
      );
      workmapScoresDataAIA.map(
        data => (totalSumOfWorkmapScore += data.score),       //eslint-disable-line
      );
    }

    const { parsed_resume } = metadata; // eslint-disable-line
    const resumeScore = parsed_resume && parsed_resume.score ? parsed_resume.score : {}; // eslint-disable-line
    const {
      score: candidateAIScore,
    } = candidateData;

    const activityData: ActivityRingsData = [   // eslint-disable-line
      {
        value:
          resumeScore && resumeScore.overall ? resumeScore.overall / 100 : 0.1,
        color: '#4FC1E9',
        backgroundColor: '#7989B1',
      },
      {
        label: 'ACTIVITY',
        value: resumeScore && resumeScore.skill ? resumeScore.skill / 100 : 0.1,
        color: '#AC92EC',
        backgroundColor: '#7989B1',
      },
      {
        label: 'ACTIVITY',
        value:
          resumeScore && resumeScore.education
            ? resumeScore.education / 100
            : 0.1,
        color: '#FC6E51',
        backgroundColor: '#7989B1',
      },
      {
        label: 'RINGS',
        value:
          resumeScore && resumeScore.experience
            ? resumeScore.experience / 100
            : 0.1,
        color: '#EC87C0',
        backgroundColor: '#7989B1',
      },
    ];

    let questions = [];
    if (candidateData) {
      ({ questions = [] } = candidateQuestions.find(
        //eslint-disable-line
        data => data.candidateId === candidateData.candidateid,
      ));
    }

    // const colWidth = collapseList ? 11 : 9;
    const ratingParameters = interviewMetadata && interviewMetadata.rating_parameters
      ? interviewMetadata.rating_parameters
      : [];

    const myRatings = (       // eslint-disable-line
      defaultExpanded // eslint-disable-line
    ) => (
      <div id="myRatings">
        {/* <Panel.Heading>
          <Panel.Toggle>
            <Panel.Title>Your Feedback</Panel.Title>
          </Panel.Toggle>
        </Panel.Heading> */}
        {/* <Panel.Collapse> */}
        {/* <Panel.Body> */}
        {candidateData && (
          <CandidateAssessmentForm
            interviewId={interviewId}
            candidateId={candidateData.candidateid}
            candidateName={candidateData.name}
            candidatePicUrl={
              candidateData.img
              || 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png'
            }
            candidateEmail={candidateData.email}
            key={candidateData.candidateid}
            workmapSkills={Object.keys(
              (candidateData.metadata
                && candidateData.metadata.workmap_score)
              || [],
            )}
            ratingParameters={ratingParameters}
          />
        )}
        {/* </Panel.Body> */}
        {/* </Panel.Collapse> */}
      </div>
    );

    const renderMobileContent = () => (
      <div className={styles.mobileContent}>
        {activeContentTabMobile === 'infoDetailsMobile' && (
          <div className={styles.tabsMobilePanel}>
            <div className={styles.candidateData}>
              <div className="space_between_items mb-15">
                <span className="center_items">
                  <span className="mr-5">
                    <MapPin height="16px" width="16px" stroke="#7989B1" />
                  </span>
                  {t('candidateDetails.location')}
                </span>
                <span className={styles.truncateTextMobile}>
                  {candidateData.metadata.location
                    ? candidateData.metadata.location
                    : '--'}
                </span>
              </div>
              <div className="space_between_items mb-15">
                <span className="center_items">
                  <span className="mr-5">
                    {/* <img src={EmailIcon} alt="email" /> */}
                    <EmailIcon width="22" height="22" fill="#7989B1" />
                  </span>
                  {t('candidateDetails.email')}
                </span>
                <span className={styles.truncateTextMobile}>
                  {candidateData.email}
                </span>
              </div>
              <div className="space_between_items mb-15">
                <span className="center_items">
                  <span className="mr-5">
                    <img src={PhoneIcon} alt="phone" />
                  </span>
                  {t('candidateDetails.phone')}
                </span>
                <span className={styles.truncateTextMobile}>
                  {candidateData.phone ? candidateData.phone : '--'}
                </span>
              </div>
              <div className="space_between_items mb-15">
                <span className="center_items">
                  <span className="mr-5">
                    {/* <img src={LinkedIcon} alt="linkedin" className="mb-10" /> */}
                    <LinkedIcon width="22" height="23" fill="#7989B1" />
                  </span>
                  {t('candidateDetails.linkedIn')}
                </span>
                <span className={styles.linkedinUrlSection}>
                  <span
                    title={candidateData.metadata.linkedinUrl}
                    className={`
      ${candidateData.metadata.linkedinUrl ? styles.linkedinUrlSection : ''}`}
                  >
                    {candidateData.metadata.linkedinUrl ? (
                      <a
                        href={candidateData.metadata.linkedinUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={
                          !candidateData.metadata.linkedinUrl
                            ? styles.nolinkedin
                            : ''
                        }
                      >
                        {candidateData.metadata.linkedinUrl}
                      </a>
                    ) : (
                      '--'
                    )}
                  </span>
                </span>
              </div>
            </div>
            {isRecommended && (
              <div>
                <div className={styles.recommendedBtn}>Potensi tinggi</div>
              </div>
            )}
          </div>
        )}
        {activeContentTabMobile === 'VideoContentMobile' && (
          <div className={styles.tabsMobilePanel}>
            <div className="space_between_items">
              <span className={styles.mobilePanelTitle}>
                {t('candidateDetails.videoTranscript')}
              </span>
              <Switch
                onColor="#6196FE"
                checkedIcon=""
                uncheckedIcon=""
                checked={transcriptVisibility}
                height={18}
                width={30}
                onChange={this.disableTranscript}
              />
            </div>

            <div className={styles.timelineContainerMobile}>
              {questions
                && questions.map((question, index) => (
                  <div
                    role="button"
                    onKeyDown={() => this.onQuestionClick(index)}
                    tabIndex={0}
                    className={
                      selectedQuestionIndex === index
                        ? styles.questionButtonTimeLineActiveMobile
                        : styles.questionButtonTimeLineMobile // eslint-disable-line
                    }
                    onClick={() => this.onQuestionClick(index)}
                  >
                    <span>{index + 1}</span>
                  </div>
                ))}
            </div>

            <div className={classnames(styles.videoContainerPanelMobile)}>
              <span className={styles.questionMobile}>
                {questions && questions[selectedQuestionIndex]
                  ? questions[selectedQuestionIndex].questiontext
                  : ''}
              </span>
              {this.renderVideo()}
            </div>

            <div className={classnames('center_items', 'mt-15', 'mb-10')}>
              <div>
                {questions.length > 0 && videoStatus === 'pause' && (
                  <button
                    type="button"
                    className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-play`}
                    onClick={this.handlePlayVideo}
                    disabled={questions.length === 0}
                  />
                )}

                {questions.length > 0 && videoStatus === 'play' && (
                  <button
                    type="button"
                    className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-pause`}
                    onClick={this.handlePauseVideo}
                  />
                )}
              </div>
              <div className={styles.progressBarContainer}>
                {Array.from({ length: questions.length }).map((x, index) => (
                  <progress // eslint-disable-line
                    value={
                      // eslint-disable-line
                      selectedQuestionIndex === index
                        ? this.state.progressBarTime // eslint-disable-line
                        : 0 // eslint-disable-line
                    }
                    onClick={() => this.onQuestionClick(index)}
                    className={classnames(
                      styles.progressBar,
                      selectedQuestionIndex === index
                        ? styles.progressBarActive
                        : '',
                    )}
                    max={videoDuration}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
        {activeContentTabMobile === 'VideoScore' && (
          <div className={styles.mobileScoreSectionI}>
            {resumeMandatory && Object.keys(resumeScore).length !== 0 && (
              <div className={styles.tabsMobilePanel}>
                <div className={styles.resumeScoreContainer}>
                  <div className={styles.mobileScoreHeading}>
                    <div className={styles.scorePercentage}>
                      {+resumeScore.overall > 0
                        ? +resumeScore.overall.toFixed(0) || 0
                        : 0}
                      %
                    </div>
                    <div className={styles.scoreHeadingName}>
                      {t('candidateDetails.resumeScore')}
                    </div>
                  </div>
                  <div className={styles.mobileResumeChartSection}>
                    {!resumeScore
                      || (Object.keys(resumeScore).length === 0 && (
                        <ScoreCircle score={0} />
                      ))}

                    {resumeScore && Object.keys(resumeScore).length > 0 && (
                      <div className={styles.activityRingContainer}>
                        <ActivityRings
                          data={activityData}
                          config={activityConfig}
                        />
                      </div>
                    )}
                    {/* </div> */}
                    <div className={styles.centerElement}>
                      <div className={styles.labelContainer}>
                        {/* We dont want to display overall score but we want
                          it to be there in the circle ring */}
                        <div
                          className={styles.label}
                          style={{ display: 'none' }}
                        >
                          <div className={styles.dot} />
                          <span className="f16-400-bold-dark ">
                            {+resumeScore.overall > 0
                              ? +resumeScore.overall.toFixed(0) || 0
                              : 0}
                            %
                          </span>
                          <span className="f16-500-normal-grey">
                            {t('candidateDetails.overall')}
                          </span>
                        </div>

                        <div className={styles.label}>
                          <div className={styles.dot} />
                          <span className="f16-400-bold-dark ">
                            {+resumeScore.skill > 0
                              ? +resumeScore.skill.toFixed(0) || 0
                              : 0}
                            %
                          </span>
                          <span className="f16-500-normal-grey">
                            {t('candidateDetails.skill')}
                          </span>
                        </div>

                        <div className={styles.label}>
                          <div className={styles.dot} />
                          <span className="f16-400-bold-dark ">
                            {+resumeScore.education > 0
                              ? +resumeScore.education.toFixed(0) || 0
                              : 0}
                            %
                          </span>
                          <span className="f16-500-normal-grey">
                            {t('candidateDetails.education')}
                          </span>
                        </div>

                        <div className={styles.label}>
                          <div className={styles.dot} />
                          <span className="f16-400-bold-dark ">
                            {+resumeScore.experience > 0
                              ? +resumeScore.experience.toFixed(0) || 0
                              : 0}
                            %
                          </span>
                          <span className="f16-500-normal-grey">
                            {t('candidateDetails.experience')}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {workmapEnabled && (
              <div className={styles.tabsMobilePanel}>
                <div className={styles.workmapScoreContainer}>
                  <div className={styles.scoreHeading}>
                    <div
                      className={this.renderConditionalClassForWorkmap(
                        totalSumOfWorkmapScore,
                      )}
                    >
                      {workmapScoresData.length !== 0
                        ? this.overallWorkmapScore(workmapScoresData)
                        : 0}
                      %
                    </div>
                    <div className={styles.scoreHeadingName}>
                      {t('candidateDetails.workmapScore')}
                    </div>
                  </div>
                  <Row className={styles.mobileWorkmapBorder}>
                    {workmapScoresData.map((workmap, index) => (
                      <Col
                        lg={6}
                        md={6}
                        sm={12}
                        className={styles.columnCenter}
                      >
                        <div
                          className={styles.workmapCircle}
                          style={{
                            border: `7px solid ${workmapColorCodes[index]}`,
                          }}
                        >
                          {+workmap.score.toFixed(0)}
                          %
                        </div>
                        <span className="f14-400-bold-dark">
                          {workmap.skill}
                        </span>
                      </Col>
                    ))}
                    {workmapScoresData && workmapScoresData.length === 0 && (
                      <ScoreCircle score={0} />
                    )}
                  </Row>
                </div>
              </div>
            )}
            <div className={styles.tabsMobilePanel}>
              <div className={styles.videopScoreContainer}>
                <AiScoreComponent
                  aiScores={candidateAIScore}
                  candidateData={candidateData}
                  orgId={organisationId}
                  videoScore={videoScore}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );

    // const hidden = candidateData ? candidateData.hidden : false;
    // const { assessment_per_interview: assessmentsPerInterview } = subscriptionLimits;
    return (
      // <Grid fluid>
      <React.Fragment>
        {/* Desktop section starts */}
        {!isMobile && (
          <div className={styles.candidate_detail_container}>
            <div className={styles.candidateLeftPanel}>
              <CandidateInformation
                candidateData={candidateData}
                displayData={displayData}
                selectedCandidatesIndex={selectedCandidatesIndex}
                interviewDetail={interviewDetail}
                eventTypeData={eventTypeData}
                chosenEventTypeUrl={chosenEventTypeUrl}
                currentNameAttribute={currentNameAttribute}
                removeBranding={removeBranding}
                interviewId={interviewId}
                coachingCandidateId={coachingCandidateId}
                hasShortlistedCandidates={hasShortlistedCandidates}
                handleCandidatesShareSubmit={this.handleCandidatesShareSubmit}
                downloadCandidateReport={this.downloadCandidateReport}
                sendCandidateReport={this.sendCandidateReport}
                hasLoadedProfileData={hasLoadedProfileData}
                isFromShareShortList={false}
                totalOverallScore={totalScore}
                handleScrollToScoring={this.handleScrollToScoring}
                parentComponent="CandidateDetailsAIA"
                isRecommended={isRecommended}
              />

              <div className={styles.videSection}>
                <div className={styles.video_questions}>
                  {questions && questions.length === 0 && (
                    <div className={styles.emptyStateQuestion}>
                      <span className="f16-500-bold-grey">
                        {t('candidateDetails.noQuestionsAttempted')}
                      </span>
                    </div>
                  )}

                  {questions
                    && questions.map((question, index) => (
                      <div // eslint-disable-line
                        title={question.questiontext} // eslint-disable-line
                        className={styles.questionContainer}
                        onClick={() => this.onQuestionClick(index)}
                      >
                        <div
                          className={classnames(
                            styles.circle,
                            styles.fullHeight,
                            selectedQuestionIndex === index
                              ? styles.circleActive
                              : '',
                          )}
                        >
                          <a>  {/* eslint-disable-line */}
                            {' '}
                            {/* eslint-disable-line */}{" "}
                            {/*  eslint-disable-line */}
                            {index + 1}
                          </a>
                        </div>
                        <span
                          className={classnames(
                            styles.question,
                            selectedQuestionIndex === index
                              ? styles.questionActive
                              : '',
                          )}
                        >
                          {this.truncate(question.questiontext, 83)}
                        </span>
                      </div>
                    ))}
                </div>
                <div>{this.renderVideo()}</div>
                <div className={styles.transcript_parent}>
                  <div>
                    <div className={styles.videoTranscriptHeading}>
                      {transcriptVisibility && (
                        <h4>{t('candidateDetails.videoTranscript')}</h4>
                      )}
                      {!transcriptVisibility && (
                        <h4>{t('candidateDetails.candidateSummary')}</h4>
                      )}
                      <Switch
                        onColor="#6196FE"
                        checkedIcon=""
                        uncheckedIcon=""
                        checked={transcriptVisibility}
                        height={18}
                        width={30}
                        onChange={this.disableTranscript}
                      />
                    </div>
                    <div className={styles.playerControlContainer}>
                      <div>
                        {transcriptVisibility && videoStatus === 'pause' && (
                          <button
                            type="button"
                            className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-play`}
                            onClick={this.handlePlayVideo}
                            disabled={questions.length === 0}
                          />
                        )}

                        {transcriptVisibility && videoStatus === 'play' && (
                          <button
                            type="button"
                            className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-pause`}
                            onClick={this.handlePauseVideo}
                          />
                        )}
                      </div>
                      <div className={styles.progressBarContainer}>
                        {transcriptVisibility
                          && Array.from({ length: questions.length }).map(
                            (x, index) => (
                              <progress // eslint-disable-line
                                value={
                                  // eslint-disable-line
                                  selectedQuestionIndex === index
                                    ? this.state.progressBarTime // eslint-disable-line
                                    : 0 // eslint-disable-line
                                }
                                onClick={() => this.onQuestionClick(index)}
                                className={classnames(
                                  styles.progressBar,
                                  selectedQuestionIndex === index
                                    ? styles.progressBarActive
                                    : '',
                                )}
                                max={videoDuration}
                              />
                            ),
                          )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.video_transcript}>
                    <p className="f16-normal-grey">
                      {transcriptVisibility
                        && questions
                        && questions[selectedQuestionIndex]
                        ? questions[selectedQuestionIndex].questiontext
                        : ''}
                    </p>
                    {transcriptVisibility && (
                      <span className="f16-400-bold-dark">
                        {candidateData.caption[selectedQuestionIndex]}
                      </span>
                    )}
                    {!transcriptVisibility && (
                      <span className="f16-400-bold-dark">
                        {this.getSummary(candidateData)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <p className="App-content-video-div--disclaimer">
                {t('candidateDetails.insightsDisclaimer')}
              </p>
              <div
                className={styles.miscActivity}
                id="scoring-section"
                ref={this.scoreRef}
              >
                <div className={styles.overallScoreSection}>
                  <div className={styles.ScoreContainer}>
                    <div>
                      <OverallScoreIcon />
                    </div>
                    <div className={styles.overallScore}>
                      {totalScore.toFixed(0)}
                      %
                    </div>
                  </div>
                  <div className={styles.overallScoreText}>
                    {t('candidateDetails.overallScore')}
                  </div>
                </div>
                <hr />
                <div className={classnames(styles.scoringSection)}>
                  {resumeMandatory && Object.keys(resumeScore).length !== 0 && (
                    <div className={styles.resumeScoreContainer}>
                      <div className={styles.scoreHeading}>
                        <div className={styles.scorePercentage}>
                          {+resumeScore.overall > 0
                            ? +resumeScore.overall.toFixed(0) || 0
                            : 0}
                          %
                        </div>
                        <div className={styles.scoreHeadingName}>
                          {t('candidateDetails.resumeScore')}
                        </div>
                      </div>
                      <div className={styles.resumeChartSection}>
                        {!resumeScore
                          || (Object.keys(resumeScore).length === 0 && (
                            <ScoreCircle score={0} />
                          ))}

                        {resumeScore && Object.keys(resumeScore).length > 0 && (
                          <div className={styles.activityRingContainer}>
                            <ActivityRings
                              data={activityData}
                              config={activityConfig}
                            />
                          </div>
                        )}
                        {/* </div> */}
                        <div className={styles.centerElement}>
                          <div className={styles.labelContainer}>
                            {/* We dont want to display overall score but we want
                              it to be there in the circle ring */}
                            <div
                              className={styles.label}
                              style={{ display: 'none' }}
                            >
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {+resumeScore.overall > 0
                                  ? +resumeScore.overall.toFixed(0) || 0
                                  : 0}
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.overall')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {+resumeScore.skill > 0
                                  ? +resumeScore.skill.toFixed(0) || 0
                                  : 0}
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.skill')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {+resumeScore.education > 0
                                  ? +resumeScore.education.toFixed(0) || 0
                                  : 0}
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.education')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {+resumeScore.experience > 0
                                  ? +resumeScore.experience.toFixed(0) || 0
                                  : 0}
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.experience')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {workmapEnabled && (
                    <div className={styles.workmapScoreContainer}>
                      <div className={styles.scoreHeading}>
                        <div
                          className={this.renderConditionalClassForWorkmap(
                            totalSumOfWorkmapScore,
                          )}
                        >
                          {workmapScoresData.length !== 0
                            ? this.overallWorkmapScore(workmapScoresData)
                            : 0}
                          %
                        </div>
                        <div className={styles.scoreHeadingName}>
                          {t('candidateDetails.workmapScore')}
                        </div>
                      </div>
                      <Row className={styles.workmapBorder}>
                        {workmapScoresData.map((workmap, index) => (
                          <Col
                            lg={6}
                            md={6}
                            sm={12}
                            className={styles.columnCenter}
                          >
                            <div
                              className={styles.workmapCircle}
                              style={{
                                border: `7px solid ${workmapColorCodes[index]}`,
                              }}
                            >
                              {+workmap.score.toFixed(0)}
                              %
                            </div>
                            <span className="f14-400-bold-dark">
                              {workmap.skill}
                            </span>
                          </Col>
                        ))}
                        {workmapScoresData
                          && workmapScoresData.length === 0 && (
                            <ScoreCircle score={0} />
                          )}
                      </Row>
                    </div>
                  )}
                  <div className={styles.videopScoreContainer}>
                    <AiScoreComponent
                      aiScores={candidateAIScore}
                      candidateData={candidateData}
                      orgId={organisationId}
                      videoScore={videoScore}
                    />
                  </div>
                </div>
              </div>
              {clientConfig.default.DOMAIN_QUESTION_SCORES.includes(
                organisationId,
              ) && (
                 <DomainQuestion         //eslint-disable-line
                   interviewId={interviewId}
                   candidateData={candidateData} />  //eslint-disable-line
                )}
              <React.Fragment>
                <AdditionalQuestion
                  interviewId={interviewId}
                  candidateEmail={candidateData.email}
                  parentComponent="candidatesDetails"
                />
              </React.Fragment>

              {false && (
                <div className={styles.feedbackSectionParent}>
                  <div className={styles.feedbackSection}>
                    {myRatings(true)}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {/* Desktop section ends */}

        {/* Mobile section starts */}
        {isMobile && (
          <div>
            <div className={styles.candidateInfoContainterMobile}>
              <div className={classnames(styles.candidateDetailHeadingMobile)}>
                <LinkContainer to="/interviews">
                  <button type="button" className="link d-inline-flex ">
                    <Glyphicon glyph="chevron-left" />
                    <span className="ml-2 f18-500-bold-dark">
                      {t('candidateDetails.candidates')}
                    </span>
                  </button>
                </LinkContainer>
                <div
                  className={styles.searchContainerMobile}
                  id="candidate_search_box_mobile"
                >
                  {isSearchBoxOpen && (
                    <input
                      type="search"
                      placeholder="Search Candidate"
                      className={styles.searchBoxMobile}
                      onChange={this.handleSearchTextChange}
                    />
                  )}
                  <button
                    type="button"
                    className={styles.searchButton}
                    onClick={this.openSearchBox}
                  >
                    <Glyphicon glyph="search" />
                  </button>
                </div>
              </div>
              <div className={styles.candidateTimelineMobile}>
                {displayData
                  .filter(
                    candidate => candidate.name
                      .toLowerCase()
                      .search(candidateSearch.toLowerCase()) >= 0,
                  )
                  .map((individualCandidate, index) => (
                    <CandidateProfileButtonMobile
                      individualCandidate={individualCandidate}
                      isCurrentlyViewedCandidate={
                        selectedCandidatesIndex === index
                      }
                      displayData={displayData}
                      interviewId={interviewId}
                    />
                  ))}
              </div>
              <div className={styles.candidateInfoPanelMobile}>
                <div className={classnames('space_between_items')}>
                  {candidateData.img ? (
                    <img
                      src={candidateData.img}
                      alt="candidate"
                      className="App-content-candidates-list-div--thumbnail"
                    />
                  ) : (
                    <span className={styles.ProfileInitials}>
                      {getInitial(candidateData.name)}
                    </span>
                  )}
                  <span
                    className={classnames(
                      styles.truncateTextMobile,
                      'f18-500-bold-dark',
                      styles.candidateNameFlexMobile,
                    )}
                  >
                    {candidateData.name}
                  </span>
                  <div className={classnames(styles.iconsContainerMobile)}>
                    {/* <li className={styles.detail_parent}> */}
                    <InfoTooltip placement="top" tooltipContent="View resume">
                      <a // eslint-disable-line
                        href={candidateData.resume} // eslint-disable-line
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classnames(
                          !candidateData.resume ? 'disableLink' : '',
                        )}
                      >
                        <ResumeIconNew
                          height="24"
                          width="24"
                          fill={`${candidateData.resume ? '#6196FE' : '#1C2640'
                            }`}
                        />
                      </a>
                    </InfoTooltip>

                    <InfoTooltip
                      placement="top"
                      tooltipContent="Schedule interview"
                    >
                      <button
                        type="button"
                        onClick={this.toggleShowScheduleInterviewModal}
                      >
                        <CalendarIcon
                          width="21"
                          height="22"
                          fill={`${candidateData.scheduled_interview_date
                            ? '#6196FE'
                            : '#1C2640'
                            }`}
                        />
                      </button>
                    </InfoTooltip>

                    <DropdownButton
                      noCaret
                      pullRight
                      bsStyle="default"
                      id="member-actions"
                      className={`pull-right dropdown ${styles.candidate_action_dropdown}`}
                      title={<img src={More} alt="More function" />}
                    >
                      <MenuItem
                        eventKey="change-background-image-item"
                        onClick={() => {
                          this.downloadCandidateReport(
                            candidateData.candidateid,
                            candidateData.name,
                            candidateData.email,
                            candidateData.img,
                            candidateData,
                            coachingCandidateId,
                            interviewId,
                          );
                        }}
                      >
                        {t('candidateDetails.downloadReport')}
                      </MenuItem>
                      <MenuItem
                        eventKey="change-background-image-item"
                        onClick={this.shareShortlist}
                      >
                        {t('candidateDetails.shareShortlist')}
                      </MenuItem>
                      <ShareCandidatesModal
                        shareableLink=""
                        onCandidatesShareSubmit={
                          this.handleCandidatesShareSubmit
                        }
                        show={showShareShortlistModal}
                        onHide={this.toggleShowShortlistedModal}
                        interviewName={interviewName}
                        sharer={
                          Auth.getProfileInfo('firstName')
                          || Auth.getProfileInfo('email')
                        }
                        isShareThisProfile={false}
                      />
                    </DropdownButton>
                  </div>
                </div>
                <div className={styles.dateContainerMobile}>
                  <div>
                    <p className="f14-normal-blue">
                      {t('candidateDetails.inviteSent')}
                      :
                      {' '}
                    </p>
                    <p className="14-400-bold-dark">
                      {candidateData.datesent
                        ? moment(getYYYYMMDD(candidateData.datesent)).format(
                          'll',
                        )
                        : '--'}
                    </p>
                  </div>
                  <div className={styles.dividerMobile} />
                  <div>
                    <p className="f14-normal-blue">
                      {t('candidateDetails.appliedDate')}
                      :
                    </p>
                    <p className="f14-400-bold-dark">
                      {candidateData.dateresponded
                        ? moment(
                          getYYYYMMDD(candidateData.dateresponded),
                        ).format('ll')
                        : '--'}
                    </p>
                  </div>
                </div>
              </div>

              {/** Mobile tabs section starts */}
              <hr />
              <div className={styles.tabsMobile}>
                <nav className={styles.tabsNavigationMobile}>
                  <ul className={styles.tabsListMobile}>
                    <li>
                      <div className={styles.activeTabsMobileSubDiv}>
                        <a
                          href="#infoDetailsMobile"
                          className={
                            activeContentTabMobile === 'infoDetailsMobile'
                              ? styles.selectedActiveTabListItemMobile
                              : styles.activeTabListItemMobile
                          }
                          onClick={() => {
                            this.setActiveTabMoblie('infoDetailsMobile');
                          }}
                        >
                          <span>Info Details</span>
                        </a>
                        <div
                          className={classnames(
                            activeContentTabMobile === 'infoDetailsMobile'
                              ? styles.activeTabsMobile
                              : styles.inActiveTabsMobile,
                          )}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={styles.activeTabsMobileSubDiv}>
                        <a
                          href="#VideoContentMobile"
                          className={
                            activeContentTabMobile === 'VideoContentMobile'
                              ? styles.selectedActiveTabListItemMobile
                              : styles.activeTabListItemMobile
                          }
                          onClick={() => {
                            this.setActiveTabMoblie('VideoContentMobile');
                          }}
                        >
                          <span>{t('candidateDetails.interviewVideo')}</span>
                        </a>

                        <div
                          className={classnames(
                            activeContentTabMobile === 'VideoContentMobile'
                              ? styles.activeTabsMobile
                              : styles.inActiveTabsMobile,
                          )}
                        />
                      </div>
                    </li>
                    <li>
                      <div className={styles.activeTabsMobileSubDiv}>
                        <a
                          href="#VideoScore"
                          className={
                            activeContentTabMobile === 'VideoScore'
                              ? styles.selectedActiveTabListItemMobile
                              : styles.activeTabListItemMobile
                          }
                          onClick={() => {
                            this.setActiveTabMoblie('VideoScore');
                          }}
                        >
                          <span>{t('candidateDetails.score')}</span>
                        </a>

                        <div
                          className={classnames(
                            activeContentTabMobile === 'VideoScore'
                              ? styles.activeTabsMobile
                              : styles.inActiveTabsMobile,
                          )}
                        />
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>
              {/** Mobile tabs section ends */}
            </div>
            {renderMobileContent(organisationId)}
            {/** video content mobile ends */}
            {/** modal section starts */}
            <RejectModalEmailPreview
              interviewId={interviewId}
              interviewName={interviewDetail.interviewname}
              companyName={interviewDetail.company_name}
              companyLogoUrl={interviewDetail.company_logo_url}
              candidatesData={[candidateData]}
              interviewLanguage={interviewDetail.interview_language}
              removeBranding={removeBranding}
            />
            {candidateData.eligibleForCalendly ? (
              <ScheduleCalendlyEmailPreview
                candidateData={candidateData}
                interviewId={interviewId}
                jobName={interviewDetail.interviewname || ''}
                companyName={overwrittenCompanyName || companyName}
                companyLogoUrl={overwrittenLogo || companyLogoUrl}
                showScheduleInterviewModal={showScheduleInterviewModal}
                eventTypeData={eventTypeData}
                chosenEventTypeUrl={chosenEventTypeUrl}
                currentNameAttribute={currentNameAttribute}
                onHide={this.toggleShowScheduleInterviewModal}
                interviewLanguage={interviewDetail.interview_language}
                removeBranding={removeBranding}
              />
            ) : (
              <ScheduleInterviewEmailPreview
                candidateData={candidateData}
                interviewId={interviewId}
                jobName={interviewDetail.interviewname || ''}
                companyName={overwrittenCompanyName || companyName}
                companyLogoUrl={overwrittenLogo || companyLogoUrl}
                showScheduleInterviewModal={showScheduleInterviewModal}
                onHide={this.toggleShowScheduleInterviewModal}
                interviewLanguage={interviewDetail.interview_language}
                removeBranding={removeBranding}
              />
            )}
            {/** modal section ends */}
          </div>
        )}
        {/* Mobile section ends  */}
      </React.Fragment>
    );
  }
}

CandidatesDetailsAIA.propTypes = {
  interviewId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(withTranslation()(CandidatesDetailsAIA));
