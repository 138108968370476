import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import styles from '../css/LiveInterviewLimitModal.module.scss';

const AssessmentLimitModal = ({
  numberOfAssessments, show,
  onHide,
}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header bsClass={styles.header}>
      <Modal.Title>CANDIDATE ASSESSMENTS LIMIT REACHED</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>
        Your plan have reached the limit of
        {' '}
        <b>
          {numberOfAssessments}
        </b>
        {' '}
        candidate assessments. Upgrade now or contact us for more details
        .
      </p>
      <div className={styles.buttons}>
        <Link to="/settings/billing/plan">
          <Button className={`btn btn-primary-custom ${styles.upgradeButton}`}>Upgrade</Button>
        </Link>
      </div>
    </Modal.Body>
  </Modal>
);


AssessmentLimitModal.propTypes = {
  numberOfAssessments: PropTypes.number.isRequired,
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default AssessmentLimitModal;
