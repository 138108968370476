import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import {
  getYYYYMMDD,
} from '../../utils/utils';
import OrganizationActions from '../../actions/OrganizationActions';
import OrganizationStore from '../../stores/OrganizationStore';
import SettingStore from '../../stores/SettingStore';
import styles from '../../css/Notifications.module.scss';
import Auth from '../../auth/Auth';
import { ReactComponent as UserIcon } from '../../assets/UserIcon.svg';
import LoadingComponent from '../../components/LoadingComponent';

const Notifications = (props) => {
  const [isLoadingNotifs, setIsLoadingNotifs] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [noNotifs, setNoNotifs] = useState(false);
  const [userid, setUserid] = useState('');
  const [processingMarkRead, setProcessingMarkRead] = useState(false);
  const [userPrefferedLanguage, setLanguage] = useState(''); // eslint-disable-line
  const { t } = useTranslation();

  const onOrgChange = () => {
    const {
      userNotifications,
      isUsersNotificationsLoading,
      noUserNotifications,
      markingAllAsRead,
      userLanguage,
    } = OrganizationStore.getState();
    setIsLoadingNotifs(isUsersNotificationsLoading);
    moment.locale(userLanguage === 'en-US' ? 'en' : userLanguage || 'en');
    // if (userLanguage === 'vi') {
    //   console.log('VI');
    //   moment.locale('vi');
    // } else {
    //   console.log('EN-US');
    //   moment.locale('en');
    // }
    setNotifications(userNotifications);
    setNoNotifs(noUserNotifications);
    setProcessingMarkRead(markingAllAsRead);
    setLanguage(userLanguage);
  };

  const onProfileStateChange = (profileStateData) => {
    const { profileData } = profileStateData;
    if (!profileData) {
      return;
    }
    setUserid(profileData.userId);
  };

  useEffect(() => {
    OrganizationStore.listen(onOrgChange);
    SettingStore.listen(onProfileStateChange);
    return () => {
      OrganizationStore.unlisten();
    };
  }, []);

  const updateNotifReadStatus = async (notifId) => {
    try {
      await fetch(`/api/notifications/${notifId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      });
    } catch (e) {
      console.log(e);
    }
  };

  const notificationDate = (value) => {
    const notiDate = new Date(value);
    const currentDate = new Date();
    if (notiDate.getFullYear() === currentDate.getFullYear()
      && notiDate.getMonth() === currentDate.getMonth()
      && notiDate.getDay() === currentDate.getDay()
      && notiDate.getDate() === currentDate.getDate()) {
      return 'Today';
    } else if (notiDate.getFullYear() === currentDate.getFullYear()      //eslint-disable-line
      && notiDate.getMonth() === currentDate.getMonth()
      && notiDate.getDay() + 1 === currentDate.getDay()
      && notiDate.getDate() + 1 === currentDate.getDate()) {
      return 'Yesterday';
    }
    return moment(
      getYYYYMMDD(value),
    ).format(
      'll',
    );
  };

  const notificationLogo = (userLogo, username) => {
    if (userLogo) {
      return <img src={userLogo} className={styles.notificationImg} alt="userLogo" />;
    }
    if (username) {
      const words = username.split(' ');
      const firstName = words[0];
      const lastName = words[words.length - 1];
      return (
        <div className={styles.navInitials}>
          <span>
            {firstName ? firstName.charAt(0).toUpperCase() : ''}
            {lastName ? lastName.charAt(0).toUpperCase() : ''}
          </span>
        </div>
      );
    }
    return (
      <div className={styles.userNotificationImg}>
        <UserIcon height="22" width="22" />
      </div>
    );
  };

  const { history } = props;
  return (
    <div className={styles.notificationParentContainer}>
      <NavigationBar history={history} />
      {isLoadingNotifs
        ? <LoadingComponent />
        : (
          <div className={styles.contentSection}>
            <div className={styles.notificationContainer}>
              <div className={styles.notificationHeading}>
                <div className={styles.notificationsText}>
                  {t('navbarSection.notifications')}
                </div>
                {
                  !noNotifs && (
                    <div
                      className={styles.markAllReadText}
                      onClick={() => OrganizationActions.markAllNotificationsAsRead(userid)}
                      onKeyDown={() => OrganizationActions.markAllNotificationsAsRead(userid)}
                      role="button"
                      tabIndex={0}
                      type="button"
                    >
                      {
                        processingMarkRead
                          ? (t('marking'))
                          : (t('markAllAsRead'))
                      }
                    </div>
                  )
                }
              </div>
              {noNotifs
                ? <div className={styles.noNotifs}>{t('navbarSection.notificationInfo1')}</div>
                : notifications.map((notif, index) => (
                  <div key={Math.random()}>
                    {
                      index !== 0 ? (
                        (moment(getYYYYMMDD(notifications[index].date_time)).format('ll') !== moment(getYYYYMMDD(notifications[index - 1].date_time)).format('ll')) && (
                          <div className={styles.notificationDaySection}>
                            <div className={styles.line} />
                            <div className={styles.notificationDayName}>
                              {notif.date_time ? notificationDate(notif.date_time) : '--'}
                            </div>
                            <div className={styles.line} />
                          </div>
                        )
                      ) : (
                        <div className={styles.notificationDaySection}>
                          <div className={styles.line} />
                          <div className={styles.notificationDayName}>
                            {notif.date_time ? notificationDate(notif.date_time) : '--'}
                          </div>
                          <div className={styles.line} />
                        </div>
                      )
                    }
                    <div
                      className={notif.has_read ? styles.read : styles.notifItem}
                      key={notif.notification_id}
                    >
                      <div className={styles.notifDescription}>
                        <div className={styles.testing}>
                          {notificationLogo(notif.user_logo_url, notif.name)}
                        </div>
                        <div className={styles.notificationDesc}>
                          <a
                            className={styles.notiLink}
                            onClick={() => updateNotifReadStatus(notif.notification_id)}
                            href={notif.redirect_url}
                          >
                            {notif.description}
                          </a>
                          <div className={styles.desktopHide}>
                            <div className={styles.date}>
                              {new Date(notif.date_time).toLocaleTimeString('en-US', {
                                hour: 'numeric', minute: 'numeric',
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.flexBox}>
                        <div className={styles.mobileHide}>
                          <div className={styles.date}>
                            {new Date(notif.date_time).toLocaleTimeString('en-US', {
                              hour: 'numeric', minute: 'numeric',
                            })}
                          </div>
                        </div>
                        <div className={styles.centerElement}>
                          {!notif.has_read && <div className={styles.dot} />}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )
      }
    </div>
  );
};

Notifications.propTypes = {
  history: PropTypes.func.isRequired,
};

export default Notifications;
