import React from 'react'; // importing react
import {
  Col,
  Glyphicon,
  DropdownButton,
  MenuItem,
  Row,
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import classnames from 'classnames';
import ActivityRings, {
  ActivityRingsConfig,
  ActivityRingsData,
} from 'react-activity-rings';
import Switch from 'react-switch';
import moment from 'moment';
import { isMobile } from 'react-device-detect';
import { withTranslation } from 'react-i18next';

import CandidateAssessmentForm from '../../components/Candidates/CandidateAssessmentForm';
import 'react-vertical-timeline-component/style.min.css';
import { ReactComponent as LinkedIcon } from '../../assets/u_linkedin_alt.svg';
import { ReactComponent as ResetIcon } from '../../assets/resetIcon.svg';
import PhoneIcon from '../../assets/PhoneIcon.svg';
import { ReactComponent as EmailIcon } from '../../assets/Envelope_alt.svg';
import ScoreCircle from '../../components/CircularScoreChart';
import { ReactComponent as MapPin } from '../../assets/MapPin.svg';
import {
  mxTrackEvent,
  getYYYYMMDD,
  getResponseStatusColorClass,
  convertKeyToTitle,
  generateSummaryFromParsedResume,
  getInitial,
  getAverage,
  getProgressColorClass,
  isCoachingUser,
} from '../../utils/utils';

import CandidateStore from '../../stores/CandidateStore';
import SettingStore from '../../stores/SettingStore';
import CandidateActions from '../../actions/CandidateActions';
import ThumbnailPlaceholder from '../../assets/thumbnail-placeholder.jpg';
import ShareCandidatesModal from '../../components/ShareCandidatesModal';
import InfoTooltip from '../../components/InfoTooltip';
import CandidateMetadataRow from '../../components/Candidates/CandidateMetadataRow';
import { alertToast, successToast } from '../../components/Toast';
import PaginationNewWidgit from '../../components/PaginationNewWidgit';

import ScheduleInterviewEmailPreview from '../../components/Interviews/ScheduleInterviewEmailPreview';
import ScheduleCalendlyEmailPreview from '../../components/Interviews/ScheduleCalendlyEmailPreview';
import styles from '../../css/CandidateDetail.module.scss';
import '../../css/CandidatesDetail.css';
import RejectModalEmailPreview from '../../components/Candidates/RejectModalEmailPreview';

import infoIcon from '../../assets/icons-info.svg';
import OrganizationStore from '../../stores/OrganizationStore';
import Auth from '../../auth/Auth';
import { ReactComponent as ResumeIconNew } from '../../assets/resume_icon_new.svg';
import LoadingComponent from '../../components/LoadingComponent';
import More from '../../assets/More.svg';
import AdditionalQuestion from './AdditionalQuestion';
import CandidateProfileButtonMobile from '../../components/CandidateProfileButtonMobile';
import { ReactComponent as CalendarIcon } from '../../assets/calendar.svg';
import { ReactComponent as Handshake } from '../../assets/Handshake.svg';
import { ReactComponent as OverallScoreIcon } from '../../assets/OverallIcon.svg';
import { ReactComponent as CloseIcon } from '../../assets/closeButton.svg';
import { ReactComponent as ThumbsupIcon } from '../../assets/ThumbsupIcon.svg';
import { ReactComponent as Verfiedicon } from '../../assets/VerfiedIcon.svg';
import { ReactComponent as MediumMatchIcon } from '../../assets/mediumMatchIcon.svg';
import { ReactComponent as LowMatchIcon } from '../../assets/lowMatchIcon.svg';
import CandidateInformation from '../../components/CandidateInformation';
import CandidateListWidget from '../../components/CandidateListWidget';
import AiScoreComponent from './AiScoreComponent';
import DomainQuestion from './DomainQuestion';
import clientConfig from '../../config/config';
import ClipLoadingComponent from '../../components/ClipLoadingComponent';

class CandidatesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef(null);
    this.scoreRef = React.createRef(null);
    this.start = new URLSearchParams(props.location.search).get('start');
    this.end = new URLSearchParams(props.location.search).get('end');

    this.state = {
      ...CandidateStore.getState(),
      // subscriptionLimits: OrganizationStore.getState().subscriptionLimits,
      showShareShortlistModal: false,
      showScheduleInterviewModal: false,
      questionListMore: false,
      hasLoadedProfileData: false,
      showAssessmentLimitModal: false,
      // specialScoringEnabled: isSpecialScoringEnabled(),
      removeBranding: OrganizationStore.getState().removeBranding,
      transcriptVisibility: true,
      videoStatus: isMobile ? 'play' : 'pause',
      videoDuration: 0,
      activeContentTabMobile: 'VideoContentMobile',
      workmapColorCodes: [],
      isFetchWorkmapColorCodes: true,
      organisationId: null,
      userId: null,
      showIdentityMatchModel: false,
      currentPage: this.end ? this.end / 10 : 1,
    };

    this.onChange = this.onChange.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.shareShortlist = this.shareShortlist.bind(this);
    this.onQuestionClick = this.onQuestionClick.bind(this);
    this.toggleShowShortlistedModal = this.toggleShowShortlistedModal.bind(this);
    this.handleCandidatesShareSubmit = this.handleCandidatesShareSubmit.bind(this);
    this.toggleQuestionListMore = this.toggleQuestionListMore.bind(this);
    this.toggleShowScheduleInterviewModal = this.toggleShowScheduleInterviewModal.bind(this);
    // this.renderScheduleInterviewButton = this.renderScheduleInterviewButton.bind(this);
    this.renderCandidateInfo = this.renderCandidateInfo.bind(this);
    this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
    this.toggleAssessmentLimitModal = this.toggleAssessmentLimitModal.bind(this);
    this.disableTranscript = this.disableTranscript.bind(this);
    this.handlePlayVideo = this.handlePlayVideo.bind(this);
    this.handlePauseVideo = this.handlePauseVideo.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.onLoadVideo = this.onLoadVideo.bind(this);
    this.getSummary = this.getSummary.bind(this);
    this.openSearchBox = this.openSearchBox.bind(this);
    this.closeSearchBox = this.closeSearchBox.bind(this);
    this.downloadCandidateReport = this.downloadCandidateReport.bind(this);
    this.sendCandidateReport = this.sendCandidateReport.bind(this);
    this.getRandomColor = this.getRandomColor.bind(this);
    this.overallWorkmapScore = this.overallWorkmapScore.bind(this);
    this.getWorkmapColors = this.getWorkmapColors.bind(this);
    this.handleScrollToScoring = this.handleScrollToScoring.bind(this);
    this.scrollToRef = this.scrollToRef.bind(this);
    this.displayIdentityModal = this.displayIdentityModal.bind(this);
    this.hideIdentityModal = this.hideIdentityModal.bind(this);
    this.handleIdentityRating = this.handleIdentityRating.bind(this);
    this.renderConditionalClassForWorkmap = this.renderConditionalClassForWorkmap.bind(this);
    this.handleGetAnswerRelevancy = this.handleGetAnswerRelevancy.bind(this);
    this.handlePaginationClick = this.handlePaginationClick.bind(this);
    this.changePagination = this.changePagination.bind(this);
    this.handleFilterButton = this.handleFilterButton.bind(this);
    this.handleFilterReset = this.handleFilterReset.bind(this);
    this.handleSearchCandidateClick = this.handleSearchCandidateClick.bind(this);
    this.handleResetCandidateClick = this.handleResetCandidateClick.bind(this);
  }

  async componentDidMount() {
    SettingStore.listen(this.onChange);
    CandidateStore.listen(this.onChange);
    OrganizationStore.listen(this.onChange);
    setTimeout(() => {
      CandidateActions.setCurrentPage(this.end ? this.end / 10 : 1);
    }, 0);
    const { selectedCandidatesIndex, selectedQuestionIndex } = this.state;
    const { interviewId, location, match } = this.props;
    const { params } = match;
    const { candidateId } = params;
    const candidateEmailParam = new URLSearchParams(location.search).get(
      'email',
    );
    await CandidateActions.resetFilters.defer();
    await CandidateActions.getAdditionalIntroQuestions(interviewId);
    // Get All Candidates info and Calendly eligibility workflow
    await CandidateActions.getCandidateDetail(
      interviewId,
      candidateId,
      true,
      candidateEmailParam,
      false,
    );

    if (this.start && this.end) {
      this.setState({
        currentPage: this.end / 10,
      });
      // await CandidateActions.getCandidates.defer(
      //   interviewId,
      //   true,
      //   candidateEmailParam,
      //   start,
      //   end,
      // );
      await CandidateActions.getCandidatesListing(
        interviewId,
        candidateId,
        this.start,
        this.end,
      );
    } else {
      // await CandidateActions.getCandidates.defer(
      //   interviewId,
      //   true,
      //   candidateEmailParam,
      //   1,
      //   10,
      // );
      await CandidateActions.getCandidatesListing(
        interviewId,
        candidateId,
        1,
        10,
      );
    }
    if (!candidateEmailParam) {
      await this.getVideoUrl(selectedCandidatesIndex, selectedQuestionIndex);
    }

    this.setState({
      hasLoadedProfileData: true,
    });
  }

  componentWillUnmount() {
    CandidateStore.unlisten(this.onChange);
    OrganizationStore.unlisten(this.onChange);
    SettingStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
    this.setState({
      organisationId: SettingStore.getState().profileData.orgId,
      userId: SettingStore.getState().profileData.userId,
    });
    this.fetchRandomColor();
  }


  async onQuestionClick(index) {
    const { selectedCandidatesIndex } = this.state;

    await CandidateActions.updateIndex({
      selectedCandidatesIndex,
      selectedQuestionIndex: index,
    });

    await this.getVideoUrl(selectedCandidatesIndex, index);
  }

  async onVideoPlay(candidateData, candidateIndex) {
    const { interviewId } = this.props;
    await CandidateActions.updateCandidateViewed(
      candidateIndex,
      interviewId,
      candidateData,
    );
    this.setState({
      videoStatus: 'play',
    });
    CandidateActions.setAutoPlay();
  }

  async onVideoEnded() {
    const {
      selectedQuestionIndex,
      selectedCandidatesIndex,
      displayData,
      candidateQuestions,
    } = this.state;
    const candidateData = displayData[selectedCandidatesIndex];
    const { questions } = candidateQuestions.find(
      data => data.candidateId === candidateData.candidateid,
    );

    if (selectedQuestionIndex !== questions.length - 1) {
      await CandidateActions.updateIndex({
        selectedCandidatesIndex,
        selectedQuestionIndex: selectedQuestionIndex + 1,
      });

      await this.getVideoUrl(
        selectedCandidatesIndex,
        selectedQuestionIndex + 1,
      );
    }

    this.setState({
      videoStatus: 'pause',
    });
  }

  onLoadVideo(e) {
    this.setState({
      videoDuration: e.currentTarget.duration,
    });
  }

  getSummary = (data) => {
    if (data.metadata && data.metadata.candidate_summary) {
      return data.metadata.candidate_summary;
    }

    if (data.metadata && data.metadata.parsed_resume
      && data.metadata.parsed_resume.resume) {
      if (data.metadata.parsed_resume.resume.iai_summary) {
        return data.metadata.parsed_resume.resume.iai_summary;
      }
      if (data.metadata.parsed_resume.resume.summary) {
        return data.metadata.parsed_resume.resume.summary;
      }
      // Legacy candidates who do not have summary field
      return generateSummaryFromParsedResume(
        data.name,
        data.metadata.parsed_resume.resume,
      );
    }

    return 'No summary';
  };

  async getVideoUrl(candidateIndex, questionIndex) {
    const { displayData, videoCache, candidateQuestions } = this.state;
    const { interviewId } = this.props;
    const candidateData = displayData[candidateIndex];
    const { questions } = candidateQuestions.find(
      data => data.candidateId === candidateData.candidateid,
    );
    CandidateActions.getVideoUrl(
      interviewId,
      candidateIndex,
      questionIndex,
      displayData,
      questions,
      videoCache,
    );
  }

  handleGetAnswerRelevancy = (candidateData, questionData) => {
    if (!candidateData.metadata || !candidateData.metadata.answer_relevancy) {
      return candidateData.metadata.answer_relevancy;
    }
    const {
      metadata,
    } = candidateData;

    const {
      answer_relevancy: answerRelevancy,
    } = metadata;

    let parsedRelevancyScore;
    if (typeof answerRelevancy === 'string') {
      parsedRelevancyScore = JSON.parse(answerRelevancy);
    } else {
      parsedRelevancyScore = answerRelevancy;
    }
    if (!parsedRelevancyScore) {
      return false;
    }
    const { questionid } = questionData;
    const question = parsedRelevancyScore.find(x => x.question_id === questionid);
    if (!question) {
      return false;
    }

    if (question.relevancy_score === 'SKIPPED') {
      return 'Skipped';
    }
    if (questionData.category) {
      return `${questionData.category} : ${question.relevancy_score} %`;
    }
    return `Relevancy : ${question.relevancy_score} %`;
  }

  setActiveTabMoblie(activeTab) {
    if (!activeTab) {
      this.setState({
        activeContentTabMobile: 'VideoContentMobile',
      });
    }

    this.setState({
      activeContentTabMobile: activeTab,
    });
  }

  scrollToRef(ref) {   //eslint-disable-line
    window.scrollTo({
      top: (ref.current.offsetTop - 330),
      behavior: 'smooth',
    })  //eslint-disable-line
  }

  // eslint-disable-next-line

  getWorkmapColors(score) {    //eslint-disable-line
    if (getProgressColorClass(score) === 'success') {
      return '#5cb85c';
    } else if (getProgressColorClass(score) === 'warning') {    //eslint-disable-line
      return '#f0ad4e';
    }
    return '#fd6144';
  }

  getRandomColor() {  //eslint-disable-line
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    const color = `rgba(${o(r() * s)},${o(r() * s)},${o(r() * s)},0.3)`;
    return color;
  }

  shareShortlist() {
    const { hasShortlistedCandidates } = this.state;

    if (!hasShortlistedCandidates) {
      alertToast('No candidate has been shortlisted');
      return;
    }

    this.toggleShowShortlistedModal();
  }

  toggleShowScheduleInterviewModal() {
    const { showScheduleInterviewModal } = this.state;
    this.setState({
      showScheduleInterviewModal: !showScheduleInterviewModal,
    });
  }

  toggleQuestionListMore() {
    const { questionListMore } = this.state;
    this.setState({
      questionListMore: !questionListMore,
    });
  }

  // handleSearchTextChange(e) {
  //   this.setState({
  //     candidateSearch: e.target.value,
  //   });
  // }

  toggleAssessmentLimitModal() {
    const { showAssessmentLimitModal } = this.state;
    this.setState({
      showAssessmentLimitModal: !showAssessmentLimitModal,
    });
  }

  disableTranscript(checked) {
    this.setState({
      transcriptVisibility: checked,
    });
  }

  truncate(str, n) { // eslint-disable-line
    return str.length > n ? `${str.substr(0, n - 1)}...` : str;
  }

  handleProgress(e) {
    this.setState({
      progressBarTime: e.currentTarget.currentTime,
    });
  }

  handlePlayVideo() {
    this.setState({
      videoStatus: 'play',
    });
    this.videoRef.current.play();
  }

  handlePauseVideo() {
    this.setState({
      videoStatus: 'pause',
    });
    this.videoRef.current.pause();
  }

  renderConditionalClassForWorkmap(value) {   //eslint-disable-line
    if (value >= 0 && value <= 50) {
      return styles.redFlag;
    } if (value >= 51 && value <= 99) {
      return styles.yellowFlag;
    }
    return styles.greenFlag;
  }

  changePagination() {
    this.setState({
      currentPage: 1,
    });
    CandidateActions.setCurrentPage(1);
  }

  async handleCandidatesShareSubmit(emails, list, accessCode, emailSubject, removeBranding) {
    const { displayData, interviewId, interviewDetail } = this.state;
    const selectedCandidates = displayData.filter(
      candidate => candidate.shortlisted,
    );
    const listName = list;
    const language = interviewDetail.interview_language;
    await CandidateActions.shareCandidatesList(
      selectedCandidates,
      emails,
      listName,
      interviewId,
      interviewDetail.interviewname,
      language,
      accessCode,
      emailSubject,
      null,
      removeBranding,
    );
  }

  async handleSearchTextChange(e, searchedIconClickedOnDetails) {    //eslint-disable-line
    if (!searchedIconClickedOnDetails) {
      CandidateActions.setSearchedCandidateListingText(e.target.value);
    }
    CandidateActions.setSearchedCandidateNameOnDetails(e.target.value);
  }

  async handleSearchCandidateClick(
    candidatesearch,
    filterIconClicked,
    interviewId,
    selectedCountries,
    selectCandidateStatusToFilter,
    selectRecruiterStatusToFilter,
    filterStartDate,
    filterEndDate,
    introQuestionDpObject,
    additionalQuestionsType,
  ) {
    CandidateActions.setSearchedIconClickedOnDetails(true);
    if (filterIconClicked) {
      await CandidateActions.getSearchedCandidatesListing(
        interviewId,
        1,
        10,
        candidatesearch,
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        false,
        additionalQuestionsType,
      );
    } else {
      await CandidateActions.getSearchedCandidatesListing(
        interviewId,
        1,
        10,
        candidatesearch,
        false,
        [],
        [],
        [],
        null,
        null,
        {},
        false,
        additionalQuestionsType,
      );
    }
    this.changePagination();
  }

  async handleResetCandidateClick(interviewId) {
    // handle the reset click including calling api
    CandidateActions.setSearchedIconClicked(false);
    CandidateActions.setSearchedCandidateNameOnDetails('');
    await CandidateActions.getSearchedCandidatesListing(
      interviewId,
      this.start,
      this.end,
      '',
      true,
    );
    CandidateActions.setCurrentPage(this.end / 10);
  }


  // eslint-disable-next-line class-methods-use-this
  async downloadCandidateReport(
    candidateId,
    candidateName,
    candidateEmail,
    candidatePicUrl,
    candidateData,
    coachingCandidateId,
    interviewId,
    totalScore,
  ) {
    const downloadReport = true;
    const {
      energyLevelScore,
      totalCommunicationScore,
      professionalismScore,
      sociabilityScore,
      // eslint-disable-next-line camelcase
      coaching_candidate_id,
      arousalScore,
      emotionScore,
      sentimentScore,
      communicationScore,
      articulationScore,
      valenceScore,
      dressingScore,
      hairTidinessScore,
      bodyLanguageScore,
      score,
      status,
    } = candidateData;
    const ai_assessment = { // eslint-disable-line
      pace: communicationScore,
      clarity: articulationScore,
      sentiment: sentimentScore,
      energy: arousalScore,
      positivity: valenceScore,
      positive_emotion: emotionScore,
    };
    let updatedScore = {};
    // let otherFeedbackText = '';
    let finalFeedbackString = '';
    // eslint-disable-next-line camelcase
    let has_unique_id = false;
    // eslint-disable-next-line camelcase
    let unique_id = '';
    // eslint-disable-next-line camelcase
    let unique_id_label = '';
    console.log('coaching id is....', coachingCandidateId);
    if (coachingCandidateId) {
      // eslint-disable-next-line camelcase
      has_unique_id = true;
      // eslint-disable-next-line camelcase
      unique_id = coaching_candidate_id;
      // eslint-disable-next-line camelcase
      unique_id_label = coachingCandidateId;
    }
    candidatePicUrl = candidatePicUrl || 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png'; // eslint-disable-line
    try {
      const reqGet = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'get',
        },
      );
      if (reqGet.status >= 200 && reqGet.status < 300) {
        const {
          ratings,
        } = reqGet;

        const { feedbackUsers } = await reqGet.json();
        const filteredFeedbackUsers = feedbackUsers.filter(x => { return x.rating !== '{}' }); // eslint-disable-line

        filteredFeedbackUsers.map((x) => {
          if (x.rating && JSON.parse(x.rating).otherFeedback) {
            finalFeedbackString = finalFeedbackString.concat(`
            <p>
             <b> Name </b>: ${x.user && x.user.firstName ? x.user.firstName.concat(x.user.lastName) : 'External user'} <br/>
             <b> Email </b>: ${x.email || x.hiring_manager_email} <br/>
             <b> Feedback </b> : ${x.rating ? JSON.parse(x.rating).otherFeedback : ''} <br/>
            </p>
            `);
          }
          return x;
        });

        const { otherFeedback: resOtherFeedback, ...manualAssessment } = ratings;
        const candidateScore = {};
        // otherFeedbackText = resOtherFeedback;

        updatedScore = Object.assign(
          {},
          candidateScore,
          manualAssessment,
        );
      } else {
        throw new Error(reqGet.statusText);
      }
    } catch (error) { // eslint-disable-line

    }

    let totalRelevancyScore;
    const parsedScore = score ? JSON.parse(score) : null;
    let relevancyScoreBreakdown;
    if (status === 'Evaluated' && parsedScore) {
      const {
        // eslint-disable-next-line
        relevancy_score_breakdown,
      } = parsedScore;
      // eslint-disable-next-line
      relevancyScoreBreakdown = relevancy_score_breakdown;
      // eslint-disable-next-line
      if (parsedScore && relevancy_score_breakdown) {
        // eslint-disable-next-line
        totalRelevancyScore = relevancy_score_breakdown.reduce((sum, value) => {
          return sum + value.relevancy_score;
        }, 0) / relevancy_score_breakdown.length;
      }
    }

    const req = await fetch(
      `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          candidateAssessment: updatedScore,
          otherFeedback: finalFeedbackString,
          candidateName,
          candidatePicUrl,
          downloadReport,
          sendReport: false,
          candidateEmail,
          energyLevelScore,
          totalCommunicationScore,
          professionalismScore,
          sociabilityScore,
          has_unique_id,
          unique_id,
          unique_id_label,
          totalScore: Math.round(totalScore, 2),
          ai_assessment,
          dressingScore,
          hairTidinessScore,
          bodyLanguageScore,
          arousalScore,
          communicationScore,
          articulationScore,
          sentimentScore,
          emotionScore,
          totalRelevancyScore,
          relevancyScoreBreakdown,
        }),
      },
    );

    if (req.status >= 200 && req.status < 300) {
      const contentType = 'application/pdf';
      if (contentType) {
        const resp = await req.blob();

        const link = document.createElement('a');
        // create a blobURI pointing to our Blob
        link.href = URL.createObjectURL(resp);
        link.download = `${candidateName}.pdf`;
        // some browser needs the anchor to be in the doc
        document.body.append(link);
        link.click();
        link.remove();
        successToast('Report downloaded !');
      }
      mxTrackEvent('Manual candidate assessment updated successfully.');
      // CandidateActions.candidateAssessmentUpdated(
      //   candidateId,
      //   candidateScore
      // );
    } else {
      const res = await req.json();
      console.log(res.error);
      throw new Error(res.error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async sendCandidateReport(
    candidateId,
    candidateName,
    candidateEmail,
    candidatePicUrl,
    candidateData,
    coachingCandidateId,
    interviewId,
    totalScore,
  ) {
    const downloadReport = false;
    const {
      energyLevelScore,
      totalCommunicationScore,
      professionalismScore,
      sociabilityScore,
      // eslint-disable-next-line camelcase
      coaching_candidate_id,
      arousalScore,
      emotionScore,
      sentimentScore,
      communicationScore,
      articulationScore,
      valenceScore,
      dressingScore,
      hairTidinessScore,
      bodyLanguageScore,
    } = candidateData;
    const ai_assessment = { // eslint-disable-line
      pace: communicationScore,
      clarity: articulationScore,
      sentiment: sentimentScore,
      energy: arousalScore,
      positivity: valenceScore,
      positive_emotion: emotionScore,
    };
    let updatedScore = {};
    // let otherFeedbackText = '';
    let finalFeedbackString = '';
    // eslint-disable-next-line camelcase
    let has_unique_id = false;
    // eslint-disable-next-line camelcase
    let unique_id = '';
    // eslint-disable-next-line camelcase
    let unique_id_label = '';
    console.log('coaching id is....', coachingCandidateId);
    if (coachingCandidateId) {
      // eslint-disable-next-line camelcase
      has_unique_id = true;
      // eslint-disable-next-line camelcase
      unique_id = coaching_candidate_id;
      // eslint-disable-next-line camelcase
      unique_id_label = coachingCandidateId;
    }
    candidatePicUrl = candidatePicUrl || 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png'; // eslint-disable-line
    try {
      const reqGet = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'get',
        },
      );
      if (reqGet.status >= 200 && reqGet.status < 300) {
        const {
          ratings,
        } = reqGet;

        const { feedbackUsers } = await reqGet.json();
        const filteredFeedbackUsers = feedbackUsers.filter(x => { return x.rating !== '{}' }); // eslint-disable-line

        filteredFeedbackUsers.map((x) => {
          if (x.rating && JSON.parse(x.rating).otherFeedback) {
            finalFeedbackString = finalFeedbackString.concat(`
            <p>
             <b> Name </b>: ${x.user && x.user.firstName ? x.user.firstName.concat(x.user.lastName) : 'External user'} <br/>
             <b> Email </b>: ${x.email || x.hiring_manager_email} <br/>
             <b> Feedback </b> : ${x.rating ? JSON.parse(x.rating).otherFeedback : ''} <br/>
            </p>
            `);
          }
          return x;
        });

        const { otherFeedback: resOtherFeedback, ...manualAssessment } = ratings;
        const candidateScore = {};
        // otherFeedbackText = resOtherFeedback;

        updatedScore = Object.assign(
          {},
          candidateScore,
          manualAssessment,
        );
      } else {
        throw new Error(reqGet.statusText);
      }
    } catch (error) { // eslint-disable-line

    }

    const req = await fetch(
      `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          candidateAssessment: updatedScore,
          otherFeedback: finalFeedbackString,
          candidateName,
          candidatePicUrl,
          downloadReport,
          sendReport: true,
          candidateEmail,
          energyLevelScore,
          totalCommunicationScore,
          professionalismScore,
          sociabilityScore,
          has_unique_id,
          unique_id,
          unique_id_label,
          totalScore: Math.round(totalScore, 2),
          ai_assessment,
          dressingScore,
          hairTidinessScore,
          bodyLanguageScore,
          arousalScore,
          communicationScore,
          articulationScore,
          sentimentScore,
          emotionScore,
        }),
      },
    );

    if (req.status >= 200 && req.status < 300) {
      const contentType = 'application/pdf';
      if (contentType) {
        successToast('Report send successfully !');
      }
      mxTrackEvent('Manual candidate assessment updated successfully.');
      // CandidateActions.candidateAssessmentUpdated(
      //   candidateId,
      //   candidateScore
      // );
    } else {
      const res = await req.json();
      console.log(res.error);
      throw new Error(res.error);
    }
  }

  openSearchBox() {   //eslint-disable-line
    const searchcontainer = document.getElementById('candidate_search_box_mobile');
    if (searchcontainer) {
      searchcontainer.classList.toggle('open');
    }
  }

  closeSearchBox() {    //eslint-disable-line
    const searchcontainer = document.getElementById('candidate_search_box_mobile');
    if (searchcontainer) {
      searchcontainer.classList.toggle('close');
    }
  }

  toggleShowShortlistedModal() {
    const { showShareShortlistModal } = this.state;
    this.setState({
      showShareShortlistModal: !showShareShortlistModal,
    });
  }

  overallWorkmapScore(arr) {          //eslint-disable-line
    const arrayToSend = [];
    arr.forEach(value => arrayToSend.push(+value.score));
    return getAverage(arrayToSend).toFixed(0);
  }

  fetchRandomColor() {
    const { displayData, selectedCandidatesIndex, isFetchWorkmapColorCodes } = this.state;
    const colorArray = [];
    if (displayData.length === 0 || selectedCandidatesIndex === undefined) {
      return;
    }

    if (!isFetchWorkmapColorCodes) {
      return;
    }

    const candidateData = displayData[selectedCandidatesIndex];
    const { metadata } = candidateData;
    const candidateWorkmapScores = metadata && metadata.workmap_score;
    const workmapScoreSkills = candidateWorkmapScores ? Object.keys(candidateWorkmapScores) : [];

    // eslint-disable-next-line
    workmapScoreSkills.map((x) => {
      colorArray.push(this.getRandomColor());
    });

    this.setState({
      workmapColorCodes: colorArray,
      isFetchWorkmapColorCodes: false,
    });
  }

  handleScrollToScoring() {          //eslint-disable-line
    this.scrollToRef(this.scoreRef);
  }

  async handlePaginationClick(key) {
    const {
      searchedIconClickedOnDetails,
      searchedCandidateListingText,
      selectedCountries,
      selectCandidateStatusToFilter,
      selectRecruiterStatusToFilter,
      introQuestionDpObject,
      additionalQuestionsType,
      filterStartDate,
      filterEndDate,
      filterIconClicked,
    } = this.state;
    const defaultPageItems = 10;
    const { match } = this.props;
    const newStartingIndex = key * defaultPageItems - 9;
    const newEndingIndex = key * defaultPageItems;
    CandidateActions.setCurrentPage(key);
    if (searchedIconClickedOnDetails && !filterIconClicked) {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        newStartingIndex,
        newEndingIndex,
        searchedCandidateListingText,
        false,
        [],
        [],
        [],
        null,
        null,
        {},
        false,
        additionalQuestionsType,
      );
    } else if (searchedIconClickedOnDetails && filterIconClicked) {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        newStartingIndex,
        newEndingIndex,
        searchedCandidateListingText,
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        false,
        additionalQuestionsType,
      );
    } else if (!searchedIconClickedOnDetails && filterIconClicked) {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        newStartingIndex,
        newEndingIndex,
        '',
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        false,
        additionalQuestionsType,
      );
    } else {
      await CandidateActions.getCandidatesListing(
        match.params.interviewId,
        match.params.candidateId,
        newStartingIndex,
        newEndingIndex,
        [],
        [],
        [],
        null,
        null,
        {},
        additionalQuestionsType,
      );
    }
  }

  async handleFilterButton() {
    const {
      selectedCountries,
      selectCandidateStatusToFilter,
      selectRecruiterStatusToFilter,
      introQuestionDpObject,
      searchedIconClickedOnDetails,
      additionalQuestionsType,
      filterStartDate,
      filterEndDate,
      searchedCandidateNameOnDetails,
      selectRelevancyScoreToFilter,
    } = this.state;
    const { match } = this.props;
    console.log('>>>>>>>> Relevancy score in handleFilterButton<<<<<', selectRelevancyScoreToFilter);
    console.log('>>>>>>>> searchedIconClickedOnDetails ', searchedIconClickedOnDetails);

    if (searchedIconClickedOnDetails) {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        1,
        10,
        searchedCandidateNameOnDetails,
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        true,
        additionalQuestionsType,
      );
    } else {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        1,
        10,
        '',
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        true,
        additionalQuestionsType,
        selectRelevancyScoreToFilter,
      );
    }
    this.changePagination();
  }

  async handleFilterReset() {
    const {
      searchedIconClickedOnDetails,
      additionalQuestionsType,
      searchedCandidateListingText,
    } = this.state;
    const { match } = this.props;
    CandidateActions.setResetFilters();
    if (searchedIconClickedOnDetails) {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        1,
        10,
        searchedCandidateListingText,
        false,
        [],
        [],
        [],
        null,
        null,
        {},
        false,
        additionalQuestionsType,
      );
    } else {
      await CandidateActions.getSearchedCandidatesListing(
        match.params.interviewId,
        1,
        10,
        '',
        false,
        [],
        [],
        [],
        null,
        null,
        {},
        false,
        additionalQuestionsType,
      );
    }
    this.changePagination();
  }

  renderVideo() {
    const {
      displayData,
      selectedCandidatesIndex,
      fetchingS3Video,
      fetchS3VideoFail,
      fetchS3VideoFailMessage,
      videoUrl,
      videoCaption,
      candidateQuestions,
      autoplay,
      transcriptVisibility,
    } = this.state;
    if (fetchingS3Video) {
      return (
        <div className={styles.videoRenderLoading}>
          <ClipLoadingComponent />
        </div>
      );
    }

    if (fetchS3VideoFail) {
      return (
        <div className={styles.emptyStateQuestion}>
          <span className="f16-500-bold-grey">{fetchS3VideoFailMessage}</span>
        </div>
      );
    }

    const candidateData = displayData[selectedCandidatesIndex];
    let questions = [];
    if (candidateData) {
      ({ questions } = candidateQuestions.find(
        data => data.candidateId === candidateData.candidateid,
      ));
    }

    if (!questions || !questions.length) {
      return null;
    }

    return (
      // <div>
      <React.Fragment>
        <div className={styles.videoContainer}>

          <video // eslint-disable-line
            ref={this.videoRef}
            onDurationChange={this.onLoadVideo}
            controlsList="nodownload"
            crossOrigin="anonymous"
            autoPlay={isMobile ? true : autoplay}
            onTimeUpdate={this.handleProgress}
            onPlay={() => this.onVideoPlay(candidateData, selectedCandidatesIndex)
            }
            onEnded={this.onVideoEnded}
            playsInline
            controls={isMobile}
            className={styles.videoContent}
            muted={isMobile}
          >
            <source src={videoUrl} />
            {
              transcriptVisibility && (
                <track
                  label="English"
                  kind="captions"
                  srcLang="en"
                  src={videoCaption}
                  default
                />

              )
            }
          </video>
        </div>
        {/* </div> */}
        {/* <div className="App-content-video-div--screen-caption">
            <h4 className="App-content-video-div--screen-caption-question">
              {questions[selectedQuestionIndex].questiontext}
            </h4>
            <p className="App-content-video-div--screen-caption-text">
              {candidateData && candidateData.caption[selectedQuestionIndex]}
            </p>
          </div>
        </div>
      </div> */}
      </React.Fragment>
    );
  }

  // renderScheduleInterviewButton() {
  //   const { displayData, selectedCandidatesIndex, hasLoadedProfileData } = this.state;
  //   const candidateData = displayData[selectedCandidatesIndex];
  //   return !hasLoadedProfileData ? (
  //     <Button id="skel-scheduleInterview">
  //       <Skeleton width={118} height={20} />
  //     </Button>
  //   ) : (
  //     <React.Fragment>
  //       <Button
  //         className="btn-default"
  //         onClick={this.toggleShowScheduleInterviewModal}
  //         disabled={candidateData.rejected}
  //       >
  //         {candidateData.scheduled_interview_date
  //           ? 'Change Schedule'
  //           : 'Schedule Interview'}
  //       </Button>
  //     </React.Fragment>
  //   );
  // }

  renderCandidateInfo() {
    const { displayData, selectedCandidatesIndex } = this.state;
    const candidateData = displayData[selectedCandidatesIndex];
    const { dateresponded, datecreated } = candidateData;

    const resume = candidateData.metadata
      && candidateData.metadata.parsed_resume
      && candidateData.metadata.parsed_resume.resume;
    const candidateResponseStatusColorClass = getResponseStatusColorClass(
      candidateData.status,
    );
    return (
      <div>
        <CandidateMetadataRow title="Email" value={candidateData.email} />

        {candidateData.phone && (
          <CandidateMetadataRow title="Phone" value={candidateData.phone} />
        )}

        {dateresponded && (
          <CandidateMetadataRow
            title="Response date"
            value={getYYYYMMDD(dateresponded)}
          />
        )}

        {datecreated && (
          <CandidateMetadataRow
            title="Sent / Application date"
            value={getYYYYMMDD(datecreated)}
          />
        )}

        {candidateData.metadata && candidateData.metadata.location && (
          <CandidateMetadataRow
            title="Location"
            value={candidateData.metadata.location}
          />
        )}

        {resume && resume.currentCompany && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Current Company
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.currentCompany}
            </Col>
          </div>
        )}

        {resume && resume.currentTitle && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Current Title
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.currentTitle}
            </Col>
          </div>
        )}

        {resume && resume.skills && (
          <div className="App-content-candidates-detail clearfix">
            <Col
              xs={3}
              sm={12}
              md={12}
              lg={4}
              className="App-content-candidates-detail--title"
            >
              Skills
              <InfoTooltip tooltipContent="Parsed from resume">
                <img src={infoIcon} alt="parsed from resume tooltip" />
              </InfoTooltip>
            </Col>
            <Col
              xs={9}
              sm={12}
              md={12}
              lg={8}
              className="App-content-candidates-detail--info"
            >
              {resume.skills.slice(0, 10).join(', ')}
            </Col>
          </div>
        )}

        {
          // insightPro related metadata
          candidateData.metadata
          && Object.keys(candidateData.metadata)
            .filter(k => k.startsWith('insightPro'))
            .map(k => (
              <CandidateMetadataRow
                key={k}
                title={convertKeyToTitle(k, 'insightPro')}
                value={candidateData.metadata[k]}
              />
            ))
        }

        {
          // hospitality related metadata
          candidateData.preferences
          && JSON.parse(candidateData.preferences).map((preference, index) => (
            <CandidateMetadataRow
              key={preference}
              title={`Preference ${index + 1}`}
              value={preference}
            />
          ))
        }

        {
          // additional form fields
          candidateData.metadata
          && candidateData.metadata.additional_fields
          && Object.keys(candidateData.metadata.additional_fields)
            .sort() // so that it's displayed consistenly
            .map(k => (
              <CandidateMetadataRow
                key={k}
                title={k}
                value={candidateData.metadata.additional_fields[k]}
              />
            ))
        }
        <div className="App-content-candidates-detail clearfix">
          <Col xs={3} className="App-content-candidates-detail--title">
            Status
          </Col>
          <Col xs={9} className="App-content-candidates-detail--info">
            <div
              className={`App-content-candidates-detail--info-status ${candidateResponseStatusColorClass}`}
            >
              {candidateData.status}
            </div>
          </Col>
        </div>
      </div>
    );
  }

  displayIdentityModal() {
    this.setState({
      showIdentityMatchModel: true,
    });
  }

  hideIdentityModal() {
    this.setState({
      showIdentityMatchModel: false,
    });
  }

  async handleIdentityRating(rating, candidateId) {     //eslint-disable-line
    await CandidateActions.identityFraudFeedback(rating, candidateId);
  }

  IdentityFraudModal(candidateData) {
    const { showIdentityMatchModel } = this.state;
    return (
      <div
        className={
          showIdentityMatchModel
            ? styles.displayIdentityModal
            : styles.hideIdentityModal
        }
      >
        <div className={styles.ifHeader}>
          <span className={styles.ifHeading}>
            Identity Match
          </span>
          <div
            className={styles.ifCloseModalButton}
            onClick={this.hideIdentityModal}
            onKeyDown={this.hideIdentityModal}
            role="button"
            type="button"
            tabIndex={0}
          >
            <CloseIcon />
          </div>
        </div>
        <div className={styles.ifIdentityMatchSection}>
          <div
            className={
              candidateData.identityFraudRange === 'Low'
                ? styles.ifLowMatch
                : styles.ifLowUnMatch
            }
          >
            {
              candidateData.identityFraudRange === 'Low'
                ? (
                  <LowMatchIcon
                    width="20"
                    height="19"
                    fill="#FF0000"
                  />
                )
                : (
                  <LowMatchIcon
                    width="20"
                    height="19"
                    fill="#bbbfc8"
                  />
                )
            }
            <span
              className={
                candidateData.identityFraudRange === 'Low'
                  ? styles.ifMatchedText
                  : styles.ifUnmatchedText
              }
            >
              Low Match
            </span>
          </div>
          <div className={
            candidateData.identityFraudRange === 'Medium'
              ? styles.ifMediumMatch
              : styles.ifMediumUnMatch
          }
          >
            {
              candidateData.identityFraudRange === 'Medium'
                ? (
                  <MediumMatchIcon
                    width="20"
                    height="19"
                    fill="#FFE500"
                  />
                )
                : (
                  <MediumMatchIcon
                    width="20"
                    height="19"
                    fill="#bbbfc8"
                  />
                )
            }
            <span
              className={
                candidateData.identityFraudRange === 'Medium'
                  ? styles.ifMatchedText
                  : styles.ifUnmatchedText
              }
            >
              Medium Match
            </span>
          </div>
          <div className={
            candidateData.identityFraudRange === 'High'
              ? styles.ifHighMatch
              : styles.ifHighUnMatch
          }
          >
            {
              candidateData.identityFraudRange === 'High'
                ? (
                  <Verfiedicon
                    width="20"
                    height="19"
                    fill="#37B24D"
                  />
                )
                : (
                  <Verfiedicon
                    width="20"
                    height="19"
                    fill="#bbbfc8"
                  />
                )
            }
            <span
              className={
                candidateData.identityFraudRange === 'High'
                  ? styles.ifMatchedText
                  : styles.ifUnmatchedText
              }
            >
              High Match
            </span>
          </div>
        </div>
        {
          candidateData.identityFraudRange === 'Low' && (
            <div className={styles.ifIdentityMatchPointerSection}>
              <div className={styles.ifLowPointer} />
              <div />
              <div />
            </div>
          )
        }
        {
          candidateData.identityFraudRange === 'Medium' && (
            <div className={styles.ifIdentityMatchPointerSection}>
              <div />
              <div className={styles.ifMediumPointer} />
              <div />
            </div>
          )
        }
        {
          candidateData.identityFraudRange === 'High' && (
            <div className={styles.ifIdentityMatchPointerSection}>
              <div />
              <div />
              <div className={styles.ifHighPointer} />
            </div>
          )
        }
        <div className={styles.ifCandidateThumbnailsSection}>
          <div className={styles.ifCandidateImgParentDiv}>
            <img
              src={candidateData.identificationURL}
              alt="identityThumbnail"
              className={styles.ifCandidateThumbnail}
            />
            <p className={styles.ifPrimaryIdentityText}>
              Government Identity
            </p>
          </div>
          <div className={styles.ifCandidateImgParentDiv}>
            <img src={candidateData.img} alt="identityThumbnail" className={styles.ifCandidateThumbnail} />
            <p className={styles.ifPrimaryIdentityText}>
              Video Capture
            </p>
          </div>
        </div>
        {
          candidateData.valid_identification_confidence_score === null && (
            <div className={styles.ifFeedbackSection}>
              <div className={styles.ifFeedbackText}>
                Do you Agree?
              </div>
              <div className={styles.ifFeedback}>
                <button
                  classNmae={styles.positiveFeedBack}
                  onClick={() => this.handleIdentityRating(true, candidateData.candidateid)}
                  type="button"
                >
                  <ThumbsupIcon className={styles.thumb} />
                </button>
                <button
                  className={styles.negativeFeedBack}
                  onClick={() => this.handleIdentityRating(false, candidateData.candidateid)}
                  type="button"
                >
                  <ThumbsupIcon className={styles.invertThumb} />
                </button>
              </div>
            </div>
          )
        }
      </div>
    );
  }

  render() {
    const activityConfig: ActivityRingsConfig = { width: 220, height: 220 }; // eslint-disable-line
    const {
      displayData,
      selectedCandidatesIndex,
      selectedQuestionIndex,
      isDataLoading,
      interviewDetail,
      loadDataFail,
      loadDataFailMsg,
      showShareShortlistModal,
      candidateQuestions,
      collapseList,
      transcriptVisibility,
      videoStatus,
      videoDuration,
      activeContentTabMobile,
      removeBranding,
      showScheduleInterviewModal,
      eventTypeData,
      chosenEventTypeUrl,
      currentNameAttribute,
      hasShortlistedCandidates,
      hasLoadedProfileData,
      organisationId,
      workmapColorCodes,
      candidateFilters,
      scoreSettingWeight,
      showScoreSettings,
      totalScoreSettings,
      isSavingScoreSettings,
      enabledScoreCategory,
      showIdentityMatchModel,
      totalCandidates,
      isDataLoadingForCandWidgit,
      currentPage,
      newCandidateData,
      fetchingNewCandidates,
      fetchingCandidateDetailsOnClick,
      searchedIconClickedOnDetails,
      searchedCandidateListingText,
      userId,
      countryList,
      countryId,
      countryName,
      selectedCountries,
      selectCandidateStatusToFilter,
      selectRecruiterStatusToFilter,
      additionalIntroQuestion,
      filterIntroDropdown,
      introQuestionDpObject,
      filterStartDate,
      filterEndDate,
      candidateLocations,
      candidateLocationsData,
      selectedCountriesDisplay,
      candidateStatusCount,
      recruiterStatusCount,
      searchedCandidateNameOnDetails,
      filterIconClicked,
      additionalQuestionsType,
      organizationType,
      candidateSharedToList,
      relevancyScoreCounts,
      enableScoreSettingUpdate,
    } = this.state;

    const {
      interviewname: interviewName,
      metadata: interviewMetadata,
      coaching_candidate_id_label: coachingCandidateId,
      overwrite_fields: overwriteFields,
      company_name: companyName,
      company_logo_url: companyLogoUrl,
      workmap_enabled: workmapEnabled,
      resume_mandatory: resumeMandatory,
      sop_mandatory: sopMandatory,
      ideal_answer_mandatory_flag: idealAnswerMandatoryFlag,
    } = interviewDetail;
    const { interviewId } = this.props;
    const { t, history } = this.props; // eslint-disable-line
    // console.log("candidate questions are : ....", candidateQuestions);

    const overwrittenLogo = overwriteFields && overwriteFields.interview_logo;
    const overwrittenCompanyName = overwriteFields && overwriteFields.company_name;

    if (isDataLoading) {
      return (
        // <RingLoader
        //   color="#fd6144"
        //   className={css`
        //     margin: 20px auto;
        //   `}
        // />
        <div className={styles.loadContainer}>
          <LoadingComponent />
        </div>
      );
    }

    if (loadDataFail) {
      return <p className="App-content-error">{loadDataFailMsg}</p>;
    }
    const candidateData = displayData[selectedCandidatesIndex];
    const {
      metadata,
    } = candidateData;

    const {
      answer_relevancy: answerRelevancy,
    } = metadata;

    let currentQuestionIdealAnswer;
    if (answerRelevancy) {
      if (typeof answerRelevancy === 'string') {
        currentQuestionIdealAnswer = JSON.parse(answerRelevancy)[selectedQuestionIndex];
      } else {
        currentQuestionIdealAnswer = answerRelevancy[selectedQuestionIndex];
      }
    }

    const { videoScore, totalScore } = candidateData;
    let workmapScoresData = [];
    let workmapScoresDataAIA = [];
    let totalSumOfWorkmapScore = 0;
    const candidateWorkmapScoreAIA = (metadata && metadata.aia_workmap_score)
      ? metadata.aia_workmap_score
      : metadata.workmap_score;
    const candidateWorkmapScores = metadata && metadata.workmap_score;
    if (candidateWorkmapScores) {
      workmapScoresData = Object.entries(candidateWorkmapScores).map(
        ([skill, score]) => ({ skill, score }),
      );
    }
    if (candidateWorkmapScoreAIA) {
      workmapScoresDataAIA = Object.entries(candidateWorkmapScoreAIA).map(
        ([skill, score]) => ({ skill, score }),
      );
      workmapScoresDataAIA.forEach((data) => {
        totalSumOfWorkmapScore += data.score;
      });
    }
    const { parsed_resume } = metadata; // eslint-disable-line
    const resumeScore = parsed_resume && parsed_resume.score ? parsed_resume.score : {}; // eslint-disable-line
    const {
      rejected,
      shortlisted,
      kived,
      hired,
      candidateid,
      score: candidateAIScore,
      submission_attempts: resubmissionAttempts,
    } = candidateData;

    const activityData: ActivityRingsData = [  // eslint-disable-line
      {
        value:
          resumeScore && resumeScore.overall
            ? (resumeScore.overall / 100)
            : 0.1, // ring will use color from theme
        color: '#4FC1E9',
        backgroundColor: '#7989B1',
      },
      {
        label: 'ACTIVITY',
        value:
          resumeScore && resumeScore.skill
            ? (resumeScore.skill / 100)
            : 0.1,
        color: '#AC92EC',
        backgroundColor: '#7989B1',
      },
      {
        label: 'ACTIVITY',
        value:
          resumeScore && resumeScore.education
            ? (resumeScore.education / 100)
            : 0.1,
        color: '#FC6E51',
        backgroundColor: '#7989B1',
      },
      {
        label: 'RINGS',
        value:
          resumeScore && resumeScore.experience
            ? (resumeScore.experience / 100)
            : 0.1,
        color: '#EC87C0',
        backgroundColor: '#7989B1',
      },
    ];

    let questions = [];
    if (candidateData) {
      ({ questions =[] } = candidateQuestions.find(           //eslint-disable-line
        data => data.candidateId === candidateData.candidateid,
      ));
    }

    // const colWidth = collapseList ? 11 : 9;
    const ratingParameters = interviewMetadata && interviewMetadata.rating_parameters
      ? interviewMetadata.rating_parameters
      : [];

    const myRatings = defaultExpanded => ( // eslint-disable-line
      <div id="myRatings">
        {/* <Panel.Heading>
          <Panel.Toggle>
            <Panel.Title>Your Feedback</Panel.Title>
          </Panel.Toggle>
        </Panel.Heading> */}
        {/* <Panel.Collapse> */}
        {/* <Panel.Body> */}
        {candidateData && (
          <CandidateAssessmentForm
            interviewId={interviewId}
            candidateId={candidateData.candidateid}
            candidateName={candidateData.name}
            candidatePicUrl={
              candidateData.img
              || 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png'
            }
            candidateEmail={candidateData.email}
            key={candidateData.candidateid}
            workmapSkills={Object.keys(
              (candidateData.metadata
                && candidateData.metadata.workmap_score)
              || [],
            )}
            ratingParameters={ratingParameters}
            currentUserId={userId}
            candidateSharedToList={candidateSharedToList}
          />
        )}
        {/* </Panel.Body> */}
        {/* </Panel.Collapse> */}
      </div>
    );
    const renderMobileContent = orgId => (
      <div className={styles.mobileContent}>
        {
          activeContentTabMobile === 'infoDetailsMobile' && (
            <div className={styles.tabsMobilePanel}>
              <div className={styles.candidateData}>
                <div className="space_between_items mb-15">
                  <span className="center_items">
                    <span className="mr-5">
                      <MapPin height="16px" width="16px" stroke="#7989B1" />
                    </span>
                    {t('candidateDetails.location')}
                  </span>
                  <span className={styles.truncateTextMobile}>
                    {candidateData.metadata.location
                      ? candidateData.metadata.location
                      : '--'}
                  </span>
                </div>
                <div className="space_between_items mb-15">
                  <span className="center_items">
                    <span className="mr-5">
                      {/* <img src={EmailIcon} alt="email" /> */}
                      <EmailIcon width="22" height="22" fill="#7989B1" />
                    </span>
                    {t('candidateDetails.email')}
                  </span>
                  <span className={styles.truncateTextMobile}>
                    {candidateData.email}
                  </span>
                </div>
                <div className="space_between_items mb-15">
                  <span className="center_items">
                    <span className="mr-5">
                      <img src={PhoneIcon} alt="phone" />
                    </span>
                    {t('candidateDetails.phone')}
                  </span>
                  <span className={styles.truncateTextMobile}>
                    {candidateData.phone ? candidateData.phone : '--'}
                  </span>
                </div>
                <div className="space_between_items mb-15">
                  <span className="center_items">
                    <span className="mr-5">
                      {/* <img src={LinkedIcon} alt="linkedin" className="mb-10" /> */}
                      <LinkedIcon width="22" height="23" fill="#7989B1" />
                    </span>
                    {t('candidateDetails.linkedIn')}
                  </span>
                  <span className={styles.linkedinUrlSection}>
                    <span
                      title={candidateData.metadata.linkedinUrl}
                      className={`
      ${candidateData.metadata.linkedinUrl ? styles.linkedinUrlSection : ''}`
                      }
                    >
                      {
                        candidateData.metadata.linkedinUrl ? (
                          <a
                            href={candidateData.metadata.linkedinUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={!candidateData.metadata.linkedinUrl ? styles.nolinkedin : ''}
                          >
                            {candidateData.metadata.linkedinUrl}
                          </a>
                        ) : (
                          '--'
                        )
                      }
                    </span>
                  </span>
                </div>

              </div>
              {/** button section starts */}
              <div className={styles.buttonSectionMobile}>
                <div className={styles.individualButtonContainer}>
                  <InfoTooltip placement="top" tooltipContent={shortlisted ? 'Candidate shortlisted' : 'Shortlist candidate'}>
                    <button
                      type="button"
                      className={`${styles.circularbutton} ${shortlisted ? styles.circularbuttonActive : ''}`}
                      onClick={() => CandidateActions.shortlistCandidate(
                        interviewId,
                        candidateData.candidateid,
                        shortlisted,
                      )}
                    >
                      <Glyphicon glyph="ok" />
                    </button>
                  </InfoTooltip>
                  <span className={` ${shortlisted ? styles.buttonTextActive : styles.buttonText}`}>{shortlisted ? 'Shortlisted' : t('shareProfile.shortlist')}</span>

                </div>

                <div className={styles.individualButtonContainer}>
                  <InfoTooltip placement="top" tooltipContent={kived ? 'Candidate kived' : 'KIV candidate'}>
                    <button
                      type="button"
                      className={`${styles.circularbutton} ${kived ? styles.circularbuttonActive : ''}`}
                      onClick={() => CandidateActions.kivCandidate(
                        interviewId,
                        candidateData.candidateid,
                        kived,
                      )}
                    >
                      <Glyphicon glyph="eye-open" />
                    </button>
                  </InfoTooltip>
                  <span className={` ${kived ? styles.buttonTextActive : styles.buttonText}`}>{kived ? 'Kept in view' : 'Keep in view'}</span>
                </div>

                <div
                  className={styles.individualButtonContainer}
                >
                  <InfoTooltip placement="top" tooltipContent={rejected ? 'Candidate rejected' : 'Reject candidate'}>
                    <button
                      type="button"
                      className={`${styles.circularbutton} ${rejected ? styles.circularbuttonActive : ''}`}
                      onClick={CandidateActions.openRejectCandidateModal}
                    >
                      <Glyphicon glyph="remove" />
                    </button>
                  </InfoTooltip>
                  <span className={` ${rejected ? styles.buttonTextActive : styles.buttonText}`}>{rejected ? 'Rejected' : 'Reject'}</span>
                </div>


                <div
                  className={styles.individualButtonContainer}
                >
                  <InfoTooltip placement="top" tooltipContent={hired ? 'Hire' : 'Hire me'}>
                    <button
                      type="button"
                      className={`${styles.circularbutton} ${hired ? styles.circularbuttonActive : ''}`}
                      onClick={() => CandidateActions.hireCandidate(
                        interviewId,
                        candidateid,
                        hired,
                      )}

                    >
                      <Handshake
                        width="26"
                        height="26"
                        fill={hired ? '#FE5819' : '#7989B1'}
                        stroke="2"
                      />
                    </button>
                  </InfoTooltip>
                  <span
                    className={classnames(`${hired ? styles.buttonTextActive : styles.buttonText}`)}
                  >
                    {hired ? (t('candidateDetails.hired')) : (t('candidateDetails.hire'))}
                  </span>
                </div>
              </div>
            </div>
          )
        }
        {
          activeContentTabMobile === 'VideoContentMobile' && (
            <div className={styles.tabsMobilePanel}>
              <div className="space_between_items">
                <span className={styles.mobilePanelTitle}>
                  {t('candidateDetails.videoTranscript')}
                </span>
                {
                  currentQuestionIdealAnswer && currentQuestionIdealAnswer.idealanswer && (
                    <Switch
                      onColor="#6196FE"
                      checkedIcon=""
                      uncheckedIcon=""
                      checked={transcriptVisibility}
                      height={18}
                      width={30}
                      onChange={this.disableTranscript}
                    />
                  )
                }
              </div>
              <div className={styles.timelineContainerMobile}>
                {questions && questions.map((question, index) => (
                  <div
                    role="button"
                    onKeyDown={() => this.onQuestionClick(index)}
                    tabIndex={0}
                    className={selectedQuestionIndex === index
                      ? styles.questionButtonTimeLineActiveMobile : styles.questionButtonTimeLineMobile // eslint-disable-line
                    }
                    onClick={
                      () => this.onQuestionClick(index)
                    }
                  >
                    <span>{index + 1}</span>
                  </div>
                ))
                }
              </div>

              <div className={classnames(styles.videoContainerPanelMobile)}>
                <span className={styles.questionMobile}>
                  {questions && questions[selectedQuestionIndex]
                    ? questions[selectedQuestionIndex].questiontext
                    : ''}
                </span>
                {transcriptVisibility && (
                  <span className={styles.mobileCandCaption}>
                    {candidateData.caption[selectedQuestionIndex]}
                  </span>
                )}
                {!transcriptVisibility && (
                  <span className={styles.mobileCandCaption}>
                    {(currentQuestionIdealAnswer && currentQuestionIdealAnswer.idealanswer)
                      || t('candidateDetails.idealAnsNotAvail')}
                  </span>
                )}
                {
                  this.handleGetAnswerRelevancy(
                    candidateData,
                    questions[selectedQuestionIndex],
                  ) ? (
                    <span className={styles.relevancyLabel}>
                      {
                        this.handleGetAnswerRelevancy(
                          candidateData,
                          questions[selectedQuestionIndex],
                        )
                      }
                    </span>
                  ) : (
                    <span />
                  )
                }
                {this.renderVideo()}
              </div>

              <div className={classnames('center_items', 'mt-15', 'mb-10')}>
                <div>

                  {
                    questions.length > 0 && videoStatus === 'pause' && (
                      <button
                        type="button"
                        className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-play`}
                        onClick={this.handlePlayVideo}
                        disabled={questions.length === 0}
                      />
                    )
                  }

                  {questions.length > 0 && videoStatus === 'play' && (
                    <button
                      type="button"
                      className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-pause`}
                      onClick={this.handlePauseVideo}
                    />
                  )}
                </div>
                <div className={styles.progressBarContainer}>
                  {Array.from({ length: questions.length }).map(
                    (x, index) => (
                      <progress  // eslint-disable-line
                        value={ // eslint-disable-line
                          selectedQuestionIndex === index
                            ? this.state.progressBarTime  // eslint-disable-line
                            : 0 // eslint-disable-line
                        }
                        onClick={() => this.onQuestionClick(index)}
                        className={classnames(
                          styles.progressBar,
                          selectedQuestionIndex === index
                            ? styles.progressBarActive
                            : '',
                        )}
                        max={videoDuration}
                      />
                    ),
                  )}
                </div>
              </div>
            </div>
          )
        }
        {
          activeContentTabMobile === 'VideoScore' && (
            <div className={styles.mobileScoreSectionI}>
              {
                resumeMandatory && Object.keys(resumeScore).length !== 0 && (
                  <div className={styles.tabsMobilePanel}>
                    <div className={styles.resumeScoreContainer}>
                      <div className={styles.mobileScoreHeading}>
                        <div className={styles.scorePercentage}>
                          {
                            +resumeScore.overall > 0
                              ? +resumeScore.overall.toFixed(0) || 0
                              : 0
                          }
                          %
                        </div>
                        <div className={styles.scoreHeadingName}>
                          {t('candidateDetails.resumeScore')}
                        </div>
                      </div>
                      <div className={styles.mobileResumeChartSection}>
                        {!resumeScore
                          || (Object.keys(resumeScore).length === 0 && (
                            <ScoreCircle score={0} />
                          ))}

                        {resumeScore && Object.keys(resumeScore).length > 0 && (
                          <div className={styles.activityRingContainer}>
                            <ActivityRings
                              data={activityData}
                              config={activityConfig}
                            />
                          </div>
                        )}
                        {/* </div> */}
                        <div className={styles.centerElement}>
                          <div className={styles.labelContainer}>
                            {/* We dont want to display overall score but we want
                          it to be there in the circle ring */}
                            <div
                              className={styles.label}
                              style={{ display: 'none' }}
                            >
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {
                                  +resumeScore.overall > 0
                                    ? +resumeScore.overall.toFixed(0) || 0
                                    : 0
                                }
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.overall')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {
                                  +resumeScore.skill > 0
                                    ? +resumeScore.skill.toFixed(0) || 0
                                    : 0
                                }
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.skill')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {
                                  +resumeScore.education > 0
                                    ? +resumeScore.education.toFixed(0) || 0
                                    : 0
                                }
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.education')}
                              </span>
                            </div>

                            <div className={styles.label}>
                              <div className={styles.dot} />
                              <span className="f16-400-bold-dark ">
                                {
                                  +resumeScore.experience > 0
                                    ? +resumeScore.experience.toFixed(0) || 0
                                    : 0
                                }
                                %
                              </span>
                              <span className="f16-500-normal-grey">
                                {t('candidateDetails.experience')}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              {
                workmapEnabled && (
                  <div className={styles.tabsMobilePanel}>
                    <div className={styles.workmapScoreContainer}>
                      <div className={styles.scoreHeading}>
                        {
                          clientConfig.default.AIA_ORG_ID.includes(orgId) ? (
                            <div
                              className={this
                                .renderConditionalClassForWorkmap(totalSumOfWorkmapScore)}
                            >
                              {
                                workmapScoresData.length !== 0
                                  ? this.overallWorkmapScore(workmapScoresData)
                                  : 0
                              }
                              %
                            </div>
                          ) : (
                            <div className={styles.scorePercentage}>
                              {
                                workmapScoresData.length !== 0
                                  ? this.overallWorkmapScore(workmapScoresData)
                                  : 0
                              }
                              %
                            </div>
                          )
                        }
                        <div className={styles.scoreHeadingName}>
                          {t('candidateDetails.workmapScore')}
                        </div>
                      </div>
                      {
                        clientConfig.default.AIA_ORG_ID.includes(organisationId) ? (
                          <Row className={styles.workmapBorder}>
                            {workmapScoresData.map((workmap, index) => (
                              <Col lg={6} md={6} sm={12} className={styles.columnCenter}>
                                {
                                  clientConfig.default.AIA_ORG_ID.includes(organisationId)
                                    ? (
                                      <div
                                        className={styles.workmapCircle}
                                        style={{
                                          border:
                                            `7px solid ${workmapColorCodes[index]}`,
                                        }}
                                      >
                                        {+(workmap.score).toFixed(0)}
                                        %
                                      </div>
                                    ) : (
                                      <div
                                        className={styles.workmapCircle}
                                        style={{
                                          border:
                                            `7px solid ${workmapColorCodes[index]}`,
                                        }}
                                      >
                                        {+(workmap.score).toFixed(0)}
                                        %
                                      </div>
                                    )
                                }
                                <span className="f14-400-bold-dark">{workmap.skill}</span>
                              </Col>
                            ))}
                            {workmapScoresData && workmapScoresData.length === 0 && (
                              <ScoreCircle score={0} />
                            )}
                          </Row>
                        ) : (
                          <Row className={styles.workmapBorder}>
                            {workmapScoresData.map((workmap, index) => (
                              <Col lg={6} md={6} sm={12} className={styles.columnCenter}>
                                {
                                  clientConfig.default.AIA_ORG_ID.includes(organisationId)
                                    ? (
                                      <div
                                        className={styles.workmapCircle}
                                        style={{
                                          border:
                                            `7px solid ${workmapColorCodes[index]}`,
                                        }}
                                      >
                                        {+(workmap.score).toFixed(0)}
                                        %
                                      </div>
                                    ) : (
                                      <div
                                        className={styles.workmapCircle}
                                        style={{
                                          border:
                                            `7px solid ${workmapColorCodes[index]}`,
                                        }}
                                      >
                                        {+(workmap.score).toFixed(0)}
                                        %
                                      </div>
                                    )
                                }
                                <span className="f14-400-bold-dark">{workmap.skill}</span>
                              </Col>
                            ))}
                            {workmapScoresData && workmapScoresData.length === 0 && (
                              <ScoreCircle score={0} />
                            )}
                          </Row>
                        )
                      }
                    </div>
                  </div>
                )
              }
              <div className={styles.tabsMobilePanel}>
                <div className={styles.videopScoreContainer}>
                  <AiScoreComponent
                    aiScores={candidateAIScore}
                    candidateData={candidateData}
                    orgId={organisationId}
                    videoScore={videoScore}
                    sopMandatory={sopMandatory}
                    idealAnswerMandatoryFlag={
                      idealAnswerMandatoryFlag
                    }
                  />
                </div>
              </div>
            </div>
          )
        }
        {
          activeContentTabMobile === 'VideoFeedback' && (
            <div className={styles.tabsMobilePanel}>
              {myRatings(true)}
            </div>
          )
        }
      </div>
    );


    // const hidden = candidateData ? candidateData.hidden : false;
    // const { assessment_per_interview: assessmentsPerInterview } = subscriptionLimits;
    return (
      // <Grid fluid>
      <React.Fragment>
        {/* Desktop section starts */}
        {
          !isMobile && (
            <>
              <div
                className={styles.candidate_detail_container}
                style={{
                  filter: showIdentityMatchModel ? 'blur(5px)' : 'none',
                  pointerEvents: showIdentityMatchModel ? 'none' : '',
                }}
              >
                {/* left div */}
                <CandidateListWidget
                  interviewId={interviewId}
                  interviewDetail={interviewDetail}
                  displayData={newCandidateData}
                  candidateData={candidateData}
                  collapseList={collapseList}
                  ThumbnailPlaceholder={ThumbnailPlaceholder}
                  parentComponent="candidateDetails"
                  totalOverallScore={totalScore}
                  candidateFilters={candidateFilters}
                  scoreSettingWeight={scoreSettingWeight}
                  showScoreSettings={showScoreSettings}
                  totalScoreSettings={totalScoreSettings}
                  isSavingScoreSettings={isSavingScoreSettings}
                  enabledScoreCategory={enabledScoreCategory}
                  orgId={organisationId}
                  totalCandidates={totalCandidates}
                  handlePaginationClick={this.handlePaginationClick}
                  isDataLoadingForCandWidgit={isDataLoadingForCandWidgit}
                  currentPage={currentPage}
                  fetchingNewCandidates={fetchingNewCandidates}
                  searchedIconClickedOnDetails={searchedIconClickedOnDetails}
                  searchedCandidateListingText={searchedCandidateListingText}
                  startIdx={this.start}
                  endIdx={this.end}
                  changePagination={this.changePagination}
                  countryList={countryList}
                  countryId={countryId}
                  countryName={countryName}
                  selectedCountries={selectedCountries}
                  selectCandidateStatusToFilter={selectCandidateStatusToFilter}
                  selectRecruiterStatusToFilter={selectRecruiterStatusToFilter}
                  additionalIntroQuestion={additionalIntroQuestion}
                  filterIntroDropdown={filterIntroDropdown}
                  introQuestionDpObject={introQuestionDpObject}
                  handleFilterButton={this.handleFilterButton}
                  handleFilterReset={this.handleFilterReset}
                  filterStartDate={filterStartDate}
                  filterEndDate={filterEndDate}
                  candidateLocations={candidateLocations}
                  candidateLocationsData={candidateLocationsData}
                  selectedCountriesDisplay={selectedCountriesDisplay}
                  candidateStatusCount={candidateStatusCount}
                  recruiterStatusCount={recruiterStatusCount}
                  searchedCandidateNameOnDetails={searchedCandidateNameOnDetails}
                  filterIconClicked={filterIconClicked}
                  additionalQuestionsType={additionalQuestionsType}
                  organizationType={organizationType}
                  relevancyScoreCounts={relevancyScoreCounts}
                  candidateid={candidateid}
                  enableScoreSettingUpdate={enableScoreSettingUpdate}
                />
                {
                  fetchingCandidateDetailsOnClick
                    ? (
                      <div className={styles.detailsLoader}>
                        <ClipLoadingComponent />
                      </div>
                    )
                    : (
                      <>
                        <div className={styles.candidateLeftPanel}>
                          <CandidateInformation
                            candidateData={candidateData}
                            displayData={displayData}
                            selectedCandidatesIndex={selectedCandidatesIndex}
                            interviewDetail={interviewDetail}
                            eventTypeData={eventTypeData}
                            chosenEventTypeUrl={chosenEventTypeUrl}
                            currentNameAttribute={currentNameAttribute}
                            removeBranding={removeBranding}
                            interviewId={interviewId}
                            coachingCandidateId={coachingCandidateId}
                            hasShortlistedCandidates={hasShortlistedCandidates}
                            handleCandidatesShareSubmit={this.handleCandidatesShareSubmit}
                            downloadCandidateReport={this.downloadCandidateReport}
                            sendCandidateReport={this.sendCandidateReport}
                            hasLoadedProfileData={hasLoadedProfileData}
                            isFromShareShortList={false}
                            totalOverallScore={totalScore}
                            handleScrollToScoring={this.handleScrollToScoring}
                            displayIdentityModal={this.displayIdentityModal}
                            orgId={organisationId}
                            history={history}
                          />

                          <div className={styles.videSection}>
                            <div>
                              <div className={styles.video_questions}>
                                {
                                  questions && questions.length === 0 && (
                                    <div className={styles.emptyStateQuestion}>
                                      <span className="f16-500-bold-grey">{t('candidateDetails.noQuestionsAttempted')}</span>
                                    </div>
                                  )
                                }

                                {questions
                                  && questions.map((question, index) => (
                                    <div // eslint-disable-line
                                      title={question.questiontext} // eslint-disable-line
                                      className={styles.questionContainer}
                                      onClick={() => this.onQuestionClick(index)}
                                    >
                                      <div
                                        className={classnames(
                                          styles.circle,
                                          styles.fullHeight,
                                          selectedQuestionIndex === index
                                            ? styles.circleActive
                                            : '',
                                        )}
                                      >
                                        <a>  {/* eslint-disable-line */}
                                          {' '}  {/*  eslint-disable-line */}
                                          {index + 1}
                                        </a>
                                      </div>
                                      <div>
                                        <div
                                          className={classnames(
                                            styles.question,
                                            selectedQuestionIndex === index
                                              ? styles.questionActive
                                              : '',
                                          )}
                                        >
                                          {this.truncate(question.questiontext, 83)}
                                        </div>
                                        {
                                          clientConfig.default.CATEGORY_HASTAG_FEATURE
                                            .includes(organisationId) && (
                                            <>
                                              {
                                                question.category ? (
                                                  <div
                                                    style={{
                                                      color: '#6196FE',
                                                      fontWeight: '500',
                                                      lineHeight: '18px',
                                                      fontSize: '0.95em',
                                                    }}
                                                  >
                                                    {`#${question.category}`}
                                                  </div>
                                                ) : (
                                                  <div
                                                    style={{
                                                      color: '#6196FE',
                                                      fontWeight: '500',
                                                      lineHeight: '18px',
                                                      fontSize: '0.95em',
                                                    }}
                                                  >
                                                    #General
                                                  </div>
                                                )
                                              }
                                            </>
                                          )
                                        }
                                      </div>
                                    </div>
                                  ))}
                              </div>
                              <div>{this.renderVideo()}</div>
                              <div className={styles.transcript_parent}>
                                <div>
                                  <div className={styles.videoTranscriptHeading}>
                                    {transcriptVisibility && <h4>{t('candidateDetails.videoTranscript')}</h4>}
                                    {!transcriptVisibility
                                      && (
                                        <h4>
                                          {t('candidateDetails.idealAnswer')}
                                        </h4>
                                      )}
                                    {
                                      questions.length !== 0
                                      && questions[selectedQuestionIndex].idealanswer && (
                                        <Switch
                                          onColor="#6196FE"
                                          checkedIcon=""
                                          uncheckedIcon=""
                                          checked={transcriptVisibility}
                                          height={18}
                                          width={30}
                                          onChange={this.disableTranscript}
                                        />
                                      )
                                    }
                                  </div>
                                  <div className={styles.playerControlContainer}>
                                    <div>

                                      {
                                        transcriptVisibility && videoStatus === 'pause' && (
                                          <button
                                            type="button"
                                            className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-play`}
                                            onClick={this.handlePlayVideo}
                                            disabled={questions.length === 0}
                                          />
                                        )
                                      }

                                      {transcriptVisibility && videoStatus === 'play' && (
                                        <button
                                          type="button"
                                          className={`${styles.candidateDetailPlayButton} glyphicon glyphicon-pause`}
                                          onClick={this.handlePauseVideo}
                                        />
                                      )}
                                    </div>
                                    <div className={styles.progressBarContainer}>
                                      {transcriptVisibility && Array
                                        .from({ length: questions.length }).map(
                                          (x, index) => (
                                            <progress  // eslint-disable-line
                                              value={ // eslint-disable-line
                                                selectedQuestionIndex === index
                                                  ? this.state.progressBarTime  // eslint-disable-line
                                                  : 0 // eslint-disable-line
                                              }
                                              onClick={() => this.onQuestionClick(index)}
                                              className={classnames(
                                                styles.progressBar,
                                                selectedQuestionIndex === index
                                                  ? styles.progressBarActive
                                                  : '',
                                              )}
                                              max={videoDuration}
                                            />
                                          ),
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.video_transcript}>
                                  <p className="f16-normal-grey">
                                    {transcriptVisibility
                                      && questions
                                      && questions[selectedQuestionIndex]
                                      ? questions[selectedQuestionIndex].questiontext
                                      : ''}
                                  </p>
                                  {
                                    this.handleGetAnswerRelevancy(
                                      candidateData,
                                      questions[selectedQuestionIndex],
                                    ) ? (
                                      <div
                                        className={styles.relevancyLabel}
                                        style={{
                                          marginBottom: '0.5rem',
                                        }}
                                      >
                                        {
                                          this.handleGetAnswerRelevancy(
                                            candidateData,
                                            questions[selectedQuestionIndex],
                                          )
                                        }
                                      </div>
                                    ) : (
                                      <span />
                                    )
                                  }
                                  {transcriptVisibility && (
                                    <div className="f16-400-bold-dark">
                                      {candidateData.caption[selectedQuestionIndex]}
                                    </div>

                                  )}
                                  {!transcriptVisibility && (
                                    <div className="f16-400-bold-dark">
                                      {(questions.length !== 0
                                        && questions[selectedQuestionIndex].idealanswer)
                                        || t('candidateDetails.idealAnsNotAvail')}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className={styles.candidateSummary}>
                              <b>
                                {
                                  isCoachingUser()
                                    ? t('candidateDetails.applicantSummary')
                                    : t('candidateDetails.candidateSummary')
                                }
                                <span className={styles.betaVersionLabel}>Beta</span>
                              </b>
                              <p className={styles.question}>
                                {this.getSummary(candidateData)}
                              </p>
                            </div>

                            {/* Resubmission count  start */}
                            {
                              clientConfig
                                .default
                                .SHOW_REATTEMPT_SUBMISSION
                                .includes(organisationId) && (
                                <div className={styles.reAttemptCount}>
                                  <span>
                                    {
                                      t('candidateDetails.retakeCount')
                                    }
                                  </span>
                                  :
                                  <span>
                                    {resubmissionAttempts}
                                  </span>
                                </div>
                              )
                            }
                            {/* Resubmission count stop */}

                          </div>
                          {
                            clientConfig.default.FEEDBACK_UP.includes(organisationId) && (
                              <div
                                className={styles.feedbackSectionParent}
                                style={{ marginTop: '25px' }}
                              >
                                <div className={styles.feedbackSection}>
                                  {myRatings(true)}
                                </div>
                              </div>
                            )
                          }
                          <p className="App-content-video-div--disclaimer">
                            {t('candidateDetails.insightsDisclaimer')}
                          </p>
                          <div className={styles.miscActivity} id="scoring-section" ref={this.scoreRef}>
                            <div className={styles.overallScoreSection}>
                              <div className={styles.ScoreContainer}>
                                <div>
                                  <OverallScoreIcon />
                                </div>
                                <div className={styles.overallScore}>
                                  {totalScore.toFixed(0)}
                                  %
                                </div>
                              </div>
                              <div className={styles.overallScoreText}>
                                {t('candidateDetails.overallScore')}
                              </div>
                            </div>
                            <hr />
                            <div className={classnames(styles.scoringSection)}>
                              {
                                resumeMandatory && Object.keys(resumeScore).length !== 0 && (

                                  <div className={styles.resumeScoreContainer}>
                                    <div className={styles.scoreHeading}>
                                      <div className={styles.scorePercentage}>
                                        {
                                          +resumeScore.overall > 0
                                            ? +resumeScore.overall.toFixed(0) || 0
                                            : 0
                                        }
                                        %
                                      </div>
                                      <div className={styles.scoreHeadingName}>
                                        {t('candidateDetails.resumeScore')}
                                      </div>
                                    </div>
                                    <div className={styles.resumeChartSection}>
                                      {!resumeScore
                                        || (Object.keys(resumeScore).length === 0 && (
                                          <ScoreCircle score={0} />
                                        ))}

                                      {resumeScore && Object.keys(resumeScore).length > 0 && (
                                        <div className={styles.activityRingContainer}>
                                          <ActivityRings
                                            data={activityData}
                                            config={activityConfig}
                                          />
                                        </div>
                                      )}
                                      {/* </div> */}
                                      <div className={styles.centerElement}>
                                        <div className={styles.labelContainer}>
                                          {/* We dont want to display overall score but we want
                              it to be there in the circle ring */}
                                          <div
                                            className={styles.label}
                                            style={{ display: 'none' }}
                                          >
                                            <div className={styles.dot} />
                                            <span className="f16-400-bold-dark ">
                                              {
                                                +resumeScore.overall > 0
                                                  ? +resumeScore.overall.toFixed(0) || 0
                                                  : 0
                                              }
                                              %
                                            </span>
                                            <span className="f16-500-normal-grey">
                                              {t('candidateDetails.overall')}
                                            </span>
                                          </div>

                                          <div className={styles.label}>
                                            <div className={styles.dot} />
                                            <span className="f16-400-bold-dark ">
                                              {
                                                +resumeScore.skill > 0
                                                  ? +resumeScore.skill.toFixed(0) || 0
                                                  : 0
                                              }
                                              %
                                            </span>
                                            <span className="f16-500-normal-grey">
                                              {t('candidateDetails.skill')}
                                            </span>
                                          </div>

                                          <div className={styles.label}>
                                            <div className={styles.dot} />
                                            <span className="f16-400-bold-dark ">
                                              {
                                                +resumeScore.education > 0
                                                  ? +resumeScore.education.toFixed(0) || 0
                                                  : 0
                                              }
                                              %
                                            </span>
                                            <span className="f16-500-normal-grey">
                                              {t('candidateDetails.education')}
                                            </span>
                                          </div>

                                          <div className={styles.label}>
                                            <div className={styles.dot} />
                                            <span className="f16-400-bold-dark ">
                                              {
                                                +resumeScore.experience > 0
                                                  ? +resumeScore.experience.toFixed(0) || 0
                                                  : 0
                                              }
                                              %
                                            </span>
                                            <span className="f16-500-normal-grey">
                                              {t('candidateDetails.experience')}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }

                              {
                                workmapEnabled && (
                                  <div className={styles.workmapScoreContainer}>
                                    <div className={styles.scoreHeading}>
                                      {
                                        clientConfig.default.AIA_ORG_ID.includes(organisationId) ? (
                                          <div
                                            className={this
                                              .renderConditionalClassForWorkmap(
                                                totalSumOfWorkmapScore,
                                              )}
                                          >
                                            {
                                              workmapScoresData.length !== 0
                                                ? this.overallWorkmapScore(workmapScoresData)
                                                : 0
                                            }
                                            %
                                          </div>
                                        ) : (
                                          <div className={styles.scorePercentage}>
                                            {
                                              workmapScoresData.length !== 0
                                                ? this.overallWorkmapScore(workmapScoresData)
                                                : 0
                                            }
                                            %
                                          </div>
                                        )
                                      }
                                      <div className={styles.scoreHeadingName}>
                                        {t('candidateDetails.workmapScore')}
                                      </div>
                                    </div>
                                    {
                                      clientConfig.default.AIA_ORG_ID.includes(organisationId) ? (
                                        <Row className={styles.workmapBorder}>
                                          {workmapScoresData.map((workmap, index) => (
                                            <Col
                                              lg={6}
                                              md={6}
                                              sm={12}
                                              className={styles.columnCenter}
                                            >
                                              {
                                                clientConfig
                                                  .default.AIA_ORG_ID.includes(organisationId)
                                                  ? (
                                                    <div
                                                      className={styles.workmapCircle}
                                                      style={{
                                                        border:
                                                          `7px solid ${workmapColorCodes[index]}`,
                                                      }}
                                                    >
                                                      {+(workmap.score).toFixed(0)}
                                                      %
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={styles.workmapCircle}
                                                      style={{
                                                        border:
                                                          `7px solid ${workmapColorCodes[index]}`,
                                                      }}
                                                    >
                                                      {+(workmap.score).toFixed(0)}
                                                      %
                                                    </div>
                                                  )
                                              }
                                              <span className="f14-400-bold-dark">
                                                {workmap.skill}
                                              </span>
                                            </Col>
                                          ))}
                                          {workmapScoresData
                                            && workmapScoresData.length === 0 && (
                                              <ScoreCircle score={0} />
                                            )}
                                        </Row>
                                      ) : (
                                        <Row className={styles.workmapBorder}>
                                          {workmapScoresData.map((workmap, index) => (
                                            <Col
                                              lg={6}
                                              md={6}
                                              sm={12}
                                              className={styles.columnCenter}
                                            >
                                              {
                                                clientConfig
                                                  .default.AIA_ORG_ID.includes(organisationId)
                                                  ? (
                                                    <div
                                                      className={styles.workmapCircle}
                                                      style={{
                                                        border:
                                                          `7px solid ${workmapColorCodes[index]}`,
                                                      }}
                                                    >
                                                      {+(workmap.score).toFixed(0)}
                                                      %
                                                    </div>
                                                  ) : (
                                                    <div
                                                      className={styles.workmapCircle}
                                                      style={{
                                                        border:
                                                          `7px solid ${workmapColorCodes[index]}`,
                                                      }}
                                                    >
                                                      {+(workmap.score).toFixed(0)}
                                                      %
                                                    </div>
                                                  )
                                              }
                                              <span className="f14-400-bold-dark">
                                                {workmap.skill}
                                              </span>
                                            </Col>
                                          ))}
                                          {workmapScoresData
                                            && workmapScoresData.length === 0 && (
                                              <ScoreCircle score={0} />
                                            )}
                                        </Row>
                                      )
                                    }
                                  </div>
                                )
                              }
                              <div className={styles.videopScoreContainer}>
                                <AiScoreComponent
                                  aiScores={candidateAIScore}
                                  candidateData={candidateData}
                                  orgId={organisationId}
                                  videoScore={videoScore}
                                  sopMandatory={sopMandatory}
                                  idealAnswerMandatoryFlag={
                                    idealAnswerMandatoryFlag
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          {
                            clientConfig.default.DOMAIN_QUESTION_SCORES.includes(organisationId)
                            && (
                              <DomainQuestion
                                interviewId={interviewId}
                                candidateData={candidateData}
                              />
                            )
                          }
                          <React.Fragment>
                            <AdditionalQuestion
                              interviewId={interviewId}
                              candidateEmail={candidateData.email}
                              parentComponent="candidatesDetails"
                            />

                          </React.Fragment>
                          {candidateSharedToList
                            && candidateSharedToList.length > 0
                            && (
                              <div className={styles.sharedListSection}>
                                <h3>{t('candidateDetails.shareWith')}</h3>
                                <div className={styles.flexWrap}>
                                  {candidateSharedToList
                                    && candidateSharedToList.length > 0
                                    ? candidateSharedToList.map(email => (
                                      <InfoTooltip
                                        placement="top"
                                        tooltipContent={
                                          email.viewed
                                            ? t('candidateDetails.candidateViewed')
                                            : t('candidateDetails.candidateNotViewed')
                                        }
                                      >
                                        <span
                                          className={
                                            email.viewed
                                              ? styles.viewedEmailItem
                                              : styles.emailItem
                                          }
                                        >
                                          {email.email}
                                        </span>
                                      </InfoTooltip>
                                    ))
                                    : '-'}
                                </div>
                              </div>
                            )
                          }
                          {
                            !clientConfig.default.FEEDBACK_UP.includes(organisationId) && (
                              <div className={styles.feedbackSectionParent}>
                                <div className={styles.feedbackSection}>
                                  {myRatings(true)}
                                </div>
                              </div>
                            )
                          }
                        </div>
                      </>
                    )
                }
              </div>
              {showIdentityMatchModel && this.IdentityFraudModal(candidateData)}
            </>
          )
        }
        {/* Desktop section ends */}

        {/* Mobile section starts */}
        {
          isMobile && (
            <div>
              <div className={classnames(styles.candidateDetailHeadingMobile)}>
                <LinkContainer to={`/interviews/edit/${interviewId}/candidates`}>
                  <button
                    type="button"
                    className="link d-inline-flex "
                  >
                    <Glyphicon glyph="chevron-left" />
                  </button>
                </LinkContainer>
                <input
                  type="search"
                  placeholder="Search Candidate"
                  className={styles.searchBoxMobile}
                  value={searchedCandidateNameOnDetails}
                  onChange={e => this.handleSearchTextChange(e, searchedIconClickedOnDetails)}
                />
                <span className={styles.flexbox}>
                  <button
                    onClick={() => {
                      this.handleSearchCandidateClick(
                        searchedCandidateNameOnDetails,
                        filterIconClicked,
                        interviewId,
                        selectedCountries,
                        selectCandidateStatusToFilter,
                        selectRecruiterStatusToFilter,
                        filterStartDate,
                        filterEndDate,
                        introQuestionDpObject,
                        additionalQuestionsType,
                      );
                    }}
                    type="button"
                    className={styles.mobileSearchbutton}
                  >
                    <Glyphicon glyph="search" />
                  </button>
                  <button
                    className={styles.mobileResetbutton}
                    onClick={() => this.handleResetCandidateClick(interviewId)}
                    type="button"
                  >
                    <ResetIcon stroke="#292D32" />
                  </button>
                </span>
              </div>
              {
                fetchingNewCandidates
                  ? (
                    <div className={styles.widgitLoader}>
                      <ClipLoadingComponent />
                    </div>
                  ) : (
                    <div className={styles.candidateTimelineMobile}>
                      {newCandidateData.map(individualCandidate => (
                        <CandidateProfileButtonMobile
                          individualCandidate={individualCandidate}
                          isCurrentlyViewedCandidate={individualCandidate.candidateid
                            === candidateData.candidateid}
                          displayData={newCandidateData}
                          candidateData={candidateData}
                          interviewId={interviewId}
                        />
                      ))
                      }
                    </div>
                  )
              }
              {
                fetchingCandidateDetailsOnClick
                  ? (
                    <div className={styles.detailsLoader}>
                      <ClipLoadingComponent />
                    </div>
                  )
                  : (
                    <>
                      <div className={styles.candidateInfoContainterMobile}>
                        <div className={styles.candidateInfoPanelMobile}>
                          <div className={classnames('space_between_items')}>
                            {
                              candidateData.img ? (
                                <img
                                  src={candidateData.img}
                                  alt="candidate"
                                  className="App-content-candidates-list-div--thumbnail"
                                />
                              ) : (
                                <span
                                  className={styles.ProfileInitials}
                                >
                                  {getInitial(candidateData.name)}
                                </span>
                              )
                            }
                            <span
                              className={classnames(styles.truncateTextMobile, 'f18-500-bold-dark', styles.candidateNameFlexMobile)}
                            >
                              {candidateData.name}
                            </span>
                            <div className={classnames(styles.iconsContainerMobile)}>
                              {/* <li className={styles.detail_parent}> */}
                              <InfoTooltip placement="top" tooltipContent="View resume">
                                <a // eslint-disable-line
                                  href={candidateData.resume} // eslint-disable-line
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={classnames(!candidateData.resume ? 'disableLink' : '')}
                                >
                                  <ResumeIconNew
                                    height="24"
                                    width="24"
                                    fill={`${candidateData.resume ? '#6196FE' : '#1C2640'}`}
                                  />
                                </a>
                              </InfoTooltip>

                              {
                                sopMandatory && (
                                  <InfoTooltip placement="top" tooltipContent="View SOP Document">
                                    <a // eslint-disable-line
                                      href={candidateData.sopDocument} // eslint-disable-line
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className={classnames(!candidateData.sopDocument ? 'disableLink' : '')}
                                    >
                                      <ResumeIconNew
                                        height="24"
                                        width="24"
                                        fill={`${candidateData.sopDocument ? '#6196FE' : '#1C2640'}`}
                                      />
                                    </a>
                                  </InfoTooltip>
                                )
                              }

                              <InfoTooltip placement="top" tooltipContent="Schedule interview">
                                <button
                                  type="button"
                                  onClick={this.toggleShowScheduleInterviewModal}
                                >

                                  <CalendarIcon
                                    width="21"
                                    height="22"
                                    fill={`${candidateData.scheduled_interview_date ? '#6196FE' : '#1C2640'}`}
                                  />
                                </button>
                              </InfoTooltip>

                              <DropdownButton
                                noCaret
                                pullRight
                                bsStyle="default"
                                id="member-actions"
                                className={`pull-right dropdown ${styles.candidate_action_dropdown}`}
                                title={<img src={More} alt="More function" />}
                              >
                                <MenuItem
                                  eventKey="change-background-image-item"
                                  onClick={() => {
                                    this.downloadCandidateReport(
                                      candidateData.candidateid,
                                      candidateData.name,
                                      candidateData.email,
                                      candidateData.img,
                                      candidateData,
                                      coachingCandidateId,
                                      interviewId,
                                    );
                                  }}
                                >
                                  {t('candidateDetails.downloadReport')}
                                </MenuItem>
                                <MenuItem eventKey="change-background-image-item" onClick={this.shareShortlist}>
                                  {t('candidateDetails.shareShortlist')}
                                </MenuItem>
                                <ShareCandidatesModal
                                  shareableLink=""
                                  onCandidatesShareSubmit={this.handleCandidatesShareSubmit}
                                  show={showShareShortlistModal}
                                  onHide={this.toggleShowShortlistedModal}
                                  interviewName={interviewName}
                                  sharer={Auth.getProfileInfo('firstName') || Auth.getProfileInfo('email')}
                                  isShareThisProfile={false}
                                />
                              </DropdownButton>
                            </div>

                          </div>
                          <div className={styles.dateContainerMobile}>
                            <div>
                              <p className="f14-normal-blue">
                                {t('candidateDetails.inviteSent')}
                                :
                                {' '}
                              </p>
                              <p className="14-400-bold-dark">
                                {candidateData.datesent ? moment(getYYYYMMDD(candidateData.datesent)).format('ll') : '--'}
                              </p>
                            </div>
                            <div className={styles.dividerMobile} />
                            <div>
                              <p className="f14-normal-blue">
                                {t('candidateDetails.appliedDate')}
                                :
                              </p>
                              <p className="f14-400-bold-dark">
                                {candidateData.dateresponded ? moment(
                                  getYYYYMMDD(candidateData.dateresponded),
                                ).format(
                                  'll',
                                ) : '--'}
                              </p>
                            </div>
                          </div>
                        </div>

                        {/** Mobile tabs section starts */}
                        <hr />
                        <div className={styles.tabsMobile}>
                          <nav className={styles.tabsNavigationMobile}>
                            <ul className={styles.tabsListMobile}>
                              <li>
                                <div className={styles.activeTabsMobileSubDiv}>
                                  <a
                                    href="#infoDetailsMobile"
                                    className={activeContentTabMobile === 'infoDetailsMobile'
                                      ? styles.selectedActiveTabListItemMobile
                                      : styles.activeTabListItemMobile}
                                    onClick={() => { this.setActiveTabMoblie('infoDetailsMobile'); }}
                                  >
                                    <span>
                                      Info Details
                                    </span>
                                  </a>
                                  <div className={classnames(activeContentTabMobile === 'infoDetailsMobile' ? styles.activeTabsMobile : styles.inActiveTabsMobile)} />

                                </div>
                              </li>
                              <li>
                                <div className={styles.activeTabsMobileSubDiv}>
                                  <a
                                    href="#VideoContentMobile"
                                    className={activeContentTabMobile === 'VideoContentMobile'
                                      ? styles.selectedActiveTabListItemMobile
                                      : styles.activeTabListItemMobile}
                                    onClick={() => { this.setActiveTabMoblie('VideoContentMobile'); }}
                                  >
                                    <span>
                                      {t('candidateDetails.interviewVideo')}
                                    </span>
                                  </a>

                                  <div className={classnames(activeContentTabMobile === 'VideoContentMobile' ? styles.activeTabsMobile : styles.inActiveTabsMobile)} />
                                </div>

                              </li>
                              <li>
                                <div className={styles.activeTabsMobileSubDiv}>
                                  <a
                                    href="#VideoScore"
                                    className={activeContentTabMobile === 'VideoScore'
                                      ? styles.selectedActiveTabListItemMobile
                                      : styles.activeTabListItemMobile}
                                    onClick={() => { this.setActiveTabMoblie('VideoScore'); }}
                                  >
                                    <span>
                                      {t('candidateDetails.score')}
                                    </span>
                                  </a>

                                  <div className={classnames(activeContentTabMobile === 'VideoScore' ? styles.activeTabsMobile : styles.inActiveTabsMobile)} />
                                </div>

                              </li>
                              <li>
                                <div className={styles.activeTabsMobileSubDiv}>
                                  <a
                                    href="#VideoFeedback"
                                    className={activeContentTabMobile === 'VideoFeedback'
                                      ? styles.selectedActiveTabListItemMobile
                                      : styles.activeTabListItemMobile}
                                    onClick={() => { this.setActiveTabMoblie('VideoFeedback'); }}
                                  >
                                    <span>
                                      {t('candidateDetails.feedback')}
                                    </span>
                                  </a>

                                  <div className={classnames(activeContentTabMobile === 'VideoFeedback' ? styles.activeTabsMobile : styles.inActiveTabsMobile)} />
                                </div>

                              </li>
                            </ul>
                          </nav>
                        </div>
                        {/** Mobile tabs section ends */}
                      </div>
                      {renderMobileContent(organisationId)}
                      <div className={styles.mobilePaginationSection}>
                        <PaginationNewWidgit
                          defaultPageItems={10}
                          totalItems={Number(totalCandidates)}
                          // onScrollOnTop={scrollOnTop}
                          sortOrder="asc"
                          handlePaginationClick={this.handlePaginationClick}
                          activePage={currentPage}
                          startIndex={!filterIconClicked ? this.start : 1}
                          endIndex={this.end}
                        />
                      </div>
                      {/** video content mobile ends */}
                      {/** modal section starts */}
                      <RejectModalEmailPreview
                        interviewId={interviewId}
                        interviewName={interviewDetail.interviewname}
                        companyName={interviewDetail.company_name}
                        companyLogoUrl={interviewDetail.company_logo_url}
                        candidatesData={[candidateData]}
                        interviewLanguage={interviewDetail.interview_language}
                        removeBranding={removeBranding}
                      />
                      {candidateData.eligibleForCalendly ? (
                        <ScheduleCalendlyEmailPreview
                          candidateData={candidateData}
                          interviewId={interviewId}
                          jobName={interviewDetail.interviewname || ''}
                          companyName={overwrittenCompanyName || companyName}
                          companyLogoUrl={overwrittenLogo || companyLogoUrl}
                          showScheduleInterviewModal={showScheduleInterviewModal}
                          eventTypeData={eventTypeData}
                          chosenEventTypeUrl={chosenEventTypeUrl}
                          currentNameAttribute={currentNameAttribute}
                          onHide={this.toggleShowScheduleInterviewModal}
                          interviewLanguage={interviewDetail.interview_language}
                          removeBranding={removeBranding}
                        />
                      ) : (
                        <ScheduleInterviewEmailPreview
                          candidateData={candidateData}
                          interviewId={interviewId}
                          jobName={interviewDetail.interviewname || ''}
                          companyName={overwrittenCompanyName || companyName}
                          companyLogoUrl={overwrittenLogo || companyLogoUrl}
                          showScheduleInterviewModal={showScheduleInterviewModal}
                          onHide={this.toggleShowScheduleInterviewModal}
                          interviewLanguage={interviewDetail.interview_language}
                          removeBranding={removeBranding}
                        />
                      )}
                    </>
                  )
              }
              {/** modal section ends */}
            </div>

          )
        }
        {/* Mobile section ends  */}
      </React.Fragment>

    );
  }
}

CandidatesDetail.propTypes = {
  interviewId: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      interviewId: PropTypes.string.isRequired,
      candidateId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(withTranslation()(CandidatesDetail));
