// @flow
import findIndex from 'lodash/findIndex';
import remove from 'lodash/remove';
import filter from 'lodash/filter';
import moment from 'moment';
import alt from '../alt';
import CandidateActions from '../actions/CandidateActions';
import {
  convertToDecimal,
  getAverageWorkmapScore,
  isSpecialScoringEnabled,
  removeDuplicateObjects,
} from '../utils/utils';
import {
  RESUME_SCORE_PARAMETERS,
  VIDEO_SCORE_PARAMETER,
  STATUS_FILTER_PARAMETER,
} from '../utils/constants';

// previous side effect: sort Candidates according to score in Desc order if orderCandidates = true
// scoreCandidates should just add score and not do anything else
const scoreCandidates = (
  candidates,
  scoreWeightage,
  enabledScoreCategory,
  scoreParams,
  scoreSettingWeight,
  isAiaorg,
  enableScoreSettingUpdate,
) => {
  if (isSpecialScoringEnabled()) {
    // for Coaching product, we emphasis on content
    const candidateWithScore = candidates.map((e) => {
      if (e.status === 'Evaluated') {
        const manualAssessment = e.manual_assessment || {};
        e.totalScore = (e.emotionScore * 10
          + e.arousalScore * 10
          + e.communicationScore * 15
          + 15 * 20 * (manualAssessment['Communication - Clearness'] || 0)
          + 20 * 20 * (manualAssessment['Communication - Conciseness'] || 0)
          + 15 * 20 * (manualAssessment['Content - Relevancy'] || 0)
          + 15 * 20 * (manualAssessment['Content - Logical Flow'] || 0)) / 100;
      } else {
        e.totalScore = 0;
      }
      return e;
    });

    return candidateWithScore;
  }

  let {
    resumeScoreWeight,
    videoScoreWeight, // eslint-disable-line prefer-const
    workmapScoreWeight,
  } = scoreWeightage;

  let {                                           //eslint-disable-line
    resumeScoreSettingWeight,
    workmapScoreSettingWeight,
    videoScoreSettingWeight,                     //eslint-disable-line
  } = scoreSettingWeight;

  const { workmap, video, resume } = scoreParams;
  const {
    professionalism,
    energyLevel,
    sociability,
    communication,
  } = video;

  const { skill, experience, education } = resume;

  const candidateWithScore = candidates.map((c) => {
    // add workmap score for display
    let resumeScore = 0;
    let workmapScore = 0;
    if (c.metadata && c.metadata.workmap_score && isAiaorg) {
      c.workmapScore = getAverageWorkmapScore( // eslint-disable-line
        c.metadata.workmap_score,
        workmap,
      ); // eslint-disable-line
    } else if (c.metadata && c.metadata.workmap_score && !isAiaorg) {
      c.workmapScore = getAverageWorkmapScore( // eslint-disable-line
        c.metadata.workmap_score,
        workmap,
      ); // eslint-disable-line
    } else {
      c.workmapScore = 0; // eslint-disable-line
    }
    if (enabledScoreCategory.resumeScoreEnabled) {
      // By default, skill is 50%, education is 20% and experience is 30% of total resume score
      // Unchecking skills, education and experience will decrease overall resume score
      // by 50%, 20% and 30% respectively
      resumeScore = (c.metadata
        && c.metadata.parsed_resume
        && c.metadata.parsed_resume.score
        && c.metadata.parsed_resume.score.overall
        - 0.5 * c.metadata.parsed_resume.score.overall * !skill
        - 0.2 * c.metadata.parsed_resume.score.overall * !education
        - 0.3 * c.metadata.parsed_resume.score.overall * !experience)
        || 0;
      if (resumeScore < 0) {
        resumeScore = 0;
      }
    } else {
      resumeScoreWeight = 0;
      resumeScoreSettingWeight = 0;
    }
    if (enabledScoreCategory.workmapScoreEnabled) {
      workmapScore = c.workmapScore;    // eslint-disable-line
    } else {
      workmapScoreWeight = 0;
      workmapScoreSettingWeight = 0;
    }

    let videoScore = 0;

    // If relevancy score available for candidate
    if (c.total_relevancy_score) {
      const relevancyScore = true;
      videoScore = (convertToDecimal(c.professionalismScore) * professionalism
        + convertToDecimal(c.sociabilityScore) * sociability
        + convertToDecimal(c.energyLevelScore) * energyLevel
        + convertToDecimal(c.totalCommunicationScore) * communication
        + convertToDecimal(c.total_relevancy_score) * relevancyScore)
        / (professionalism + sociability + energyLevel + communication + relevancyScore);
    } else {
      videoScore = (convertToDecimal(c.professionalismScore) * professionalism
        + convertToDecimal(c.sociabilityScore) * sociability
        + convertToDecimal(c.energyLevelScore) * energyLevel
        + convertToDecimal(c.totalCommunicationScore) * communication)
        / (professionalism + sociability + energyLevel + communication);
    }
    c.videoScore = videoScore; // eslint-disable-line no-param-reassign

    const totalWeightage = workmapScoreWeight + resumeScoreWeight + videoScoreWeight;  //eslint-disable-line
    const totalScoreSettingWeightage = workmapScoreSettingWeight
      + resumeScoreSettingWeight
      + videoScoreSettingWeight;

    const {
      workmapScoreEnabled,
      resumeScoreEnabled,
      videoScoreEnabled,
    } = enabledScoreCategory;
    if (workmapScoreEnabled && resumeScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+workmapScore + +videoScore + +resumeScore) / 3;  //eslint-disable-line
    } else if (workmapScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+workmapScore + +videoScore) / 2;  //eslint-disable-line
    } else if (resumeScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+resumeScore + +videoScore) / 2;  //eslint-disable-line
    } else {
      c.totalScore = videoScore;     //eslint-disable-line
    }
    // c.totalScore = totalWeightage === 0 ? 0 // eslint-disable-line no-param-reassign
    //   : (workmapScore * workmapScoreWeight
    //     + resumeScore * resumeScoreWeight
    //     + videoScore * videoScoreWeight) / totalWeightage;

    c.totalScoreSetting = totalScoreSettingWeightage === 0  // eslint-disable-line
      ? 0 // eslint-disable-line no-param-reassign
      : (workmapScore * workmapScoreSettingWeight
        + resumeScore * resumeScoreSettingWeight
        + videoScore * videoScoreSettingWeight) / totalScoreSettingWeightage;

    if (enableScoreSettingUpdate && c.score_settings_overall_score) {
      c.totalScore = Number(c.score_settings_overall_score);  //eslint-disable-line
    }
    return c;
  });

  return candidateWithScore;
  // =(10*E2 + 10*F2 + 15*H2 + 15*Q2*20 + 20*R2*20+15*S2*20+15*T2*20)/100
};

const findCandidatesIndex = (candidates, candidateId) => findIndex(candidates, ['candidateid', candidateId]);

class CandidateStore {
  /* eslint-disable lines-between-class-members */
  data: Array<Object>;
  displayData: Array<Object>;
  interviewId: string;
  interviewDetail: Object;
  candidateQuestions: Array<Object>;
  fetchingS3Video: boolean;
  fetchS3VideoFail: boolean;
  fetchS3VideoFailMessage: string;
  isDataLoading: boolean;
  loadDataFail: boolean;
  loadDataFailMsg: string;
  selectedCandidatesIndex: number;
  selectedQuestionIndex: number;
  currentCandidateId: string;
  videoUrl: string;
  videoCaption: string;
  videoCache: Object;
  autoplay: boolean;
  sortFilterShow: boolean;
  scoreSliderValue: Object;
  enabledScoreCategory: Object;
  scoreParams: Object;
  collapseList: boolean;
  hasShortlistedCandidates: boolean;
  filterStartEndDateFocus: ?string;
  filterLocationOptions: Array<string>;
  filterAdditionalFormFields: Array<Object>;
  filterKeywords: Array<string>;
  scheduleInterviewModalShow: boolean;
  scheduleInterviewDate: Array<Object>;
  scheduleInterviewDuration: number;
  sendingScheduleInterviewEmail: boolean;
  rejectingCandidate: boolean;
  rejectCandidateModalShow: boolean;
  newCandidateName: string;
  newCandidateEmail: string;
  newCandidatePhone: string;
  validCandidateEmail: boolean;
  validCandidatePhone: boolean;
  showAddCandidateBox: boolean;
  addingNewCandidate: boolean;
  removingMultipleCandidate: boolean;
  lastSelectedCandidateIndex: number;
  removingCandidate: boolean;
  removingCandidateIndex: number;
  sendingReminderEmail: boolean;
  filterData: Object;
  filterData2: Object;
  filterApplied: boolean;
  tabFilterApplied: boolean;
  isCandidateDetailScreen: boolean;
  candidatesView: string;
  bindListeners: (Object) => Object;
  eventTypeData: Array<string>; // [url, url, url]
  chosenEventTypeUrl: string;
  currentNameAttribute: string;
  sortOrder: string;
  hiredCandidates: Array<Object>;
  candidateFilters: Object;
  candidateDomainScore: Object;
  isAllCandidateSelectedFlag: boolean;
  allCandidateCountForInviteCount: number;
  /* eslint-enable */

  constructor() {
    this.data = [];
    this.displayData = [];
    this.interviewId = '';
    this.interviewDetail = {};
    this.candidateQuestions = [];
    this.fetchingS3Video = true;
    this.fetchS3VideoFail = false;
    this.fetchS3VideoFailMessage = '';
    this.isDataLoading = true;
    this.isDataLoadingForCandWidgit = true;
    this.loadDataFail = false;
    this
      .loadDataFailMsg = 'Error loading interview and candidates information. :(';
    this.selectedCandidatesIndex = 0;
    this.selectedQuestionIndex = 0;
    this.currentCandidateId = '';
    this.videoUrl = '';
    this.videoCaption = '';
    this.videoCache = {};
    this.autoplay = false;
    this.sortFilterShow = false;
    this.scoreSliderValue = {
      videoScoreWeight: 5,
      resumeScoreWeight: 5,
      workmapScoreWeight: 5,
    };
    this.scoreSettingWeight = {
      videoScoreSettingWeight: 33.3,
      resumeScoreSettingWeight: 33.3,
      workmapScoreSettingWeight: 33.3,
    };
    this.totalScoreWeightage = 0;
    this.enabledScoreCategory = {
      resumeScoreEnabled: true,
      workmapScoreEnabled: true,
      videoScoreEnabled: true,
    };
    this.scoreParams = {
      workmap: {},
      video: {
        professionalism: true,
        energyLevel: true,
        communication: true,
        sociability: true,
      },
      resume: {
        education: true,
        skill: true,
        experience: true,
      },
    };
    this.collapseList = false;
    this.hasShortlistedCandidates = false;
    this.hasHiredCandidates = false;
    this.filterLocationOptions = [];
    this.filterNameOptions = [];
    this.filterAdditionalFormFields = [];
    this.filterKeywords = [];
    this.isCandidateDetailScreen = false;
    this.totalScoreSettings = this.scoreSettingWeight.videoScoreSettingWeight
      + this.scoreSettingWeight.resumeScoreSettingWeight
      + this.scoreSettingWeight.workmapScoreSettingWeight;

    /* reject candidate modal */
    this.rejectingCandidate = false;
    this.rejectCandidateModalShow = false;

    /* add candidate screen */
    this.newCandidateName = '';
    this.newCandidateEmail = '';
    this.newCandidatePhone = '';
    this.validCandidateEmail = false;
    this.validCandidatePhone = true;
    this.showAddCandidateBox = false;
    this.addingNewCandidate = false;
    this.removingMultipleCandidate = false;
    this.lastSelectedCandidateIndex = -1;
    this.removingCandidate = false;
    this.removingCandidateIndex = -1;
    this.sendingReminderEmail = false;
    this.filterData = {
      showShortlisted: true,
      showRejected: true,
      showRespondedStatus: true,
      showNewStatus: true,
      showKIV: true,
      showHired: true,
      filterLocation: '',
      filterAdditionalFormValues: {},
      filterStartDate: null,
      filterEndDate: null,
    };
    this.showScoreSettings = false;

    this.filterApplied = false;
    this.candidatesView = 'listView';

    /* Calendly states */
    this.eventTypeData = [];
    this.chosenEventTypeUrl = '';
    this.currentNameAttribute = '';

    this.sortOrder = 'desc';
    this.sortKey = 'dateresponded';

    /* Editing candidate details */
    this.editingCandidateDetails = false;
    this.isSavingScoreSettings = false;

    /** Candidate listing slider */
    this.workmapScoreSliderValue = 0;
    this.resumeScoreSliderValue = 0;
    this.videoScoreSliderValue = 0;
    this.workmapScoreParameterList = [];
    this.resumeScoreFilterParameterList = RESUME_SCORE_PARAMETERS;
    this.videoScoreFilterParameterList = VIDEO_SCORE_PARAMETER;

    // ** Need to replace this object
    this.filterData2 = {
      filterStartDate: null,
      filterEndDate: null,
      showShortlisted: true,
      showYettoRespond: true,
      showRespondedStatus: true,
      showNewStatus: true,
      showHired: true,
      // showAllCandidates:true
    };
    this.userNotAuthFromAIA = false;
    this.isRecommended = false;
    this.isAIAOrg = false;

    this.filterStartDate = null;
    this.filterEndDate = null;
    this.candidateFilters = {
      workmapScoreSliderValue: 0,
      resumeScoreSliderValue: 0,
      videoScoreSliderValue: 0,
      videoContentRelevancyValue: 50,
      videoProfessionalismValue: 50,
      videoSociabilityValue: 50,
      videoCommunicationValue: 50,
      videoPositiveAttitudeValue: 50,
      statusFilterParameteras: STATUS_FILTER_PARAMETER.reduce(
        (acc, curr) => ((acc[curr] = false), acc), // eslint-disable-line
        {},
      ),
      workmapFilterParameters: {},
      resumeFilterParameters: RESUME_SCORE_PARAMETERS.reduce(
        (acc, curr) => ((acc[curr] = false), acc), // eslint-disable-line
        {},
      ),
      videoScoreFilterParameter: VIDEO_SCORE_PARAMETER.reduce(
        (acc, curr) => ((acc[curr] = false), acc), // eslint-disable-line
        {},
      ),
      filterAdditionalFormValues: {},
      communicationPaceAndClarity: 33.33,
      communicationArticulation: 33.33,
      communicationSentiments: 33.33, // For AIA and already processed candidate as of now
      communicationGrammarScore: 33.33, // Rest Candidates
      sociabilityEmotion: 33.33,
      sociabilityEnergyLevel: 33.33,
      sociabilitySentiments: 33.33,
      professionalismDressing: 33.33,
      professionalismGrooming: 33.33,
      professionalismBodyLanguage: 33.33,
    };
    this.candidateDomainScore = {};
    this.recentResponses = [];
    this.funnelData = [];
    this.weeklyCount = {};
    this.isFetchRecentResponsesProcessing = false;
    this.recentResponsesCount = 0;
    this.isInterviewCreated = false;
    this.isSampleInterviewViewed = false;
    this.totalCandidates = 0;
    this.clickedOnDownload = false;
    this.searchCandidate = false;
    this.searchedCandidateText = '';
    this.searchedCandidateListingText = '';
    this.searchedIconClicked = false;
    this.searchedIconClickedOnDetails = false;
    this.updatedCandidateResponse = [];
    this.candidateIndexes = [];
    this.newCandidateData = [];
    this.fetchingNewCandidates = true;
    this.fetchingCandidateDetailsOnClick = false;
    this.applicationstatus = 'TOTAL_CANDIDATES';
    this.fetchingCandidateIndexes = false;
    this.selectedCandIdFromRecentRes = '';
    this.currentPage = 1;
    this.countryList = [];
    this.selectedCountries = [];
    this.selectedCountriesDisplay = [];
    this.countryId = '';
    this.countryName = '';
    this.selectCandidateStatusToFilter = [];
    this.selectRecruiterStatusToFilter = [];
    this.selectRelevancyScoreToFilter = [];
    this.selectedFilterDropdown = '';
    this.filterIntroDropdown = {
      dropdownValue: '',
      dropdownOptions: [],
    };
    this.introQuestionDpObject = {};
    this.filterIconClicked = false;
    this.activePanel = 'TOTAL_CANDIDATES';
    this.additionalQuestionsType = {};
    this.candidateLocationsData = {};
    this.candidateLocations = [];
    this.candidateStatusCount = [];
    this.recruiterStatusCount = [];
    this.searchedCandidateNameOnDetails = '';
    this.overviewMessageObject = {};
    this.candidateSharedToList = [];
    this.relevancyScoreCounts = {};
    this.enableScoreSettingUpdate = false;
    this.candidateFiltersOpen = false;
    this.allCandidateCountForInviteCount = 0;
    this.startCandidateBulkDownload = false;
    // this.checkWorkmapFilterParameter = {};
    // this
    //  .checkResumeFilterParameter = RESUME_SCORE_PARAMETERS
    //  .reduce((acc,curr)=> (acc[curr]=false,acc),{});
    //  this.checkvideoFilterParameter = VIDEO_SCORE_PARAMETER
    //  .reduce((acc,curr)=> (acc[curr]=false,acc),{});

    this.bindListeners({
      handleSetStartedProfile: CandidateActions.SET_STARRED_PROFILE,
      handleIsSaveScoreSettings: CandidateActions.CHANGE_IS_SAVING_SCORE_SETTINGS,
      handleResumeWeightageChange: CandidateActions.CHANGE_RESUME_SCORE_WEIGHTAGE,
      handleResetScoreSettings: CandidateActions.RESET_SCORE_SETTINGS,
      handleWorkmapWeightageChange: CandidateActions.CHANGE_WORKMAP_SCORE_WEIGHTAGE,
      handleVideoWeightageChange: CandidateActions.CHANGE_VIDEO_SCORE_WEIGHTAGE,
      handleSaveScoreSettings: CandidateActions.HANDLE_SAVE_SCORE_SETTINGS,
      handleGettingCandidates: CandidateActions.GETTING_CANDIDATES,
      handleGettingCandidatesForWidgit: CandidateActions.GETTING_CANDIDATES_FOR_WIDGIT,
      handleGetCandidatesSuccess: CandidateActions.GET_CANDIDATES_SUCCESS,
      handleGetSingleCandidatesSuccess: CandidateActions.GET_SINGLE_CANDIDATES_SUCCESS,
      handleGetCandidatesFail: CandidateActions.GET_CANDIDATES_FAIL,
      handleGettingVideoUrl: CandidateActions.GETTING_VIDEO_URL,
      handleGetVideoUrlFail: CandidateActions.GET_VIDEO_URL_FAIL,
      handleGetVideoUrlSuccess: CandidateActions.GET_VIDEO_URL_SUCCESS,
      handleSetVideoCache: CandidateActions.SET_VIDEO_CACHE,
      handleSetAutoPlay: CandidateActions.SET_AUTO_PLAY,
      handleUpdateIndex: CandidateActions.UPDATE_INDEX,
      handleChangeSliderValue: CandidateActions.CHANGE_SLIDER_VALUE,
      handleToggleSortFilter: CandidateActions.TOGGLE_SORT_FILTER,
      handleChangeScoreParams: CandidateActions.CHANGE_SCORE_PARAMS,
      handleShortlistCandidateUpdate:
        CandidateActions.SHORTLIST_CANDIDATE_UPDATE,
      handleHiredCandidateUpdate: CandidateActions.HIRED_CANDIDATE_UPDATE,
      handleKivCandidateUpdate: CandidateActions.KIV_CANDIDATE_UPDATE,
      handleLikeCandidateUpdate: CandidateActions.LIKE_CANDIDATE_UPDATE,
      handleDislikeCandidateUpdate: CandidateActions.DISLIKE_CANDIDATE_UPDATE,
      handleChangeRejectedCheckbox: CandidateActions.CHANGE_REJECTED_CHECKBOX,
      handleChangeShowShortlistedCheckbox:
        CandidateActions.CHANGE_SHOW_SHORTLISTED_CHECKBOX, //
      handleSetShowShortlistedCandidate:
        CandidateActions.SET_SHOW_SHORTLISTED_CANDIDATE,
      handleChangeShowHiredSelected:
        CandidateActions.CHANGE_SHOW_HIRED_SELECTED,
      handleChangeLocationFilter: CandidateActions.CHANGE_LOCATION_FILTER,
      handleChangeStartDateFilter: CandidateActions.CHANGE_START_DATE_FILTER,
      handleChangeEndDateFilter: CandidateActions.CHANGE_END_DATE_FILTER,
      handleChangeAdditionalFormFieldFilter:
        CandidateActions.CHANGE_ADDITIONAL_FORM_FIELD_FILTER,
      handleChangeKeywordFilter: CandidateActions.CHANGE_KEYWORD_FILTER,
      handleResetFilters: CandidateActions.RESET_FILTERS,
      handleUpdateCandidateScheduleInterviewDate:
        CandidateActions.UPDATE_CANDIDATE_SCHEDULE_INTERVIEW_DATE,
      handleUpdateCandidateCalendlyScheduleInterviewDate:
        CandidateActions.UPDATE_CANDIDATE_CALENDLY_SCHEDULE_INTERVIEW_DATE,
      handleRejectingCandidate: CandidateActions.REJECTING_CANDIDATE,
      handleRejectCandidatesSuccess: CandidateActions.REJECT_CANDIDATES_SUCCESS,
      handleUnrejectCandidatesSuccess:
        CandidateActions.UNREJECT_CANDIDATES_SUCCESS,
      handleRejectCandidateFail: CandidateActions.REJECT_CANDIDATE_FAIL,
      handleOpenRejectCandidateModal:
        CandidateActions.OPEN_REJECT_CANDIDATE_MODAL,
      handleCloseRejectCandidateModal:
        CandidateActions.CLOSE_REJECT_CANDIDATE_MODAL,
      handleCandidateAssessmentUpdated:
        CandidateActions.CANDIDATE_ASSESSMENT_UPDATED,
      handleUpdateDisplayDataForCandidateViewed:
        CandidateActions.UPDATE_DISPLAY_DATA_FOR_CANDIDATE_VIEWED,
      handleCollapseList: CandidateActions.COLLAPSE_LIST,
      /* add candidate screen store functions */
      handleAddNewCandidateNameChange:
        CandidateActions.ADD_NEW_CANDIDATE_NAME_CHANGE,
      handleAddNewCandidateEmailChange:
        CandidateActions.ADD_NEW_CANDIDATE_EMAIL_CHANGE,
      handleAddNewCandidatePhoneChange:
        CandidateActions.ADD_NEW_CANDIDATE_PHONE_CHANGE,
      handleAddNewCandidate: CandidateActions.ADD_NEW_CANDIDATE,
      handleSubmittingNewCandidate: CandidateActions.SUBMITTING_NEW_CANDIDATE,
      handleSubmitNewCandidateSuccess:
        CandidateActions.SUBMIT_NEW_CANDIDATE_SUCCESS,
      handleSubmitNewCandidateFailure:
        CandidateActions.SUBMIT_NEW_CANDIDATE_FAILURE,
      handleCancelAddNewCandidate: CandidateActions.CANCEL_ADD_NEW_CANDIDATE,
      handleRemovingMultipleCandidates:
        CandidateActions.REMOVING_MULTIPLE_CANDIDATES,
      handleRemoveMultipleCandidatesSuccess:
        CandidateActions.REMOVE_MULTIPLE_CANDIDATES_SUCCESS,
      handleRemoveMultipleCandidatesFailure:
        CandidateActions.REMOVE_MULTIPLE_CANDIDATES_FAILURE,
      handleChangeAllCandidateCheckbox:
        CandidateActions.CHANGE_ALL_CANDIDATE_CHECKBOX,
      handleChangeCandidateCheckbox: CandidateActions.CHANGE_CANDIDATE_CHECKBOX,
      handleRemovingCandidateFromList:
        CandidateActions.REMOVING_CANDIDATE_FROM_LIST,
      handleRemoveCandidateFromListSuccess:
        CandidateActions.REMOVE_CANDIDATE_FROM_LIST_SUCCESS,
      handleRemoveCandidateFromListFailure:
        CandidateActions.REMOVE_CANDIDATE_FROM_LIST_FAILURE,
      handleSendingReminderEmail: CandidateActions.SENDING_REMINDER_EMAIL,
      handleSendReminderEmailSuccess:
        CandidateActions.SEND_REMINDER_EMAIL_SUCCESS,
      handleSendReminderEmailFailure:
        CandidateActions.SEND_REMINDER_EMAIL_FAILURE,
      handleUpdateCandidateToSent: CandidateActions.UPDATE_CANDIDATE_TO_SENT,
      handleApplyFilter: CandidateActions.APPLY_FILTER,
      handleChangeShowRespondedStatusCheckbox:
        CandidateActions.CHANGE_SHOW_RESPONDED_STATUS_CHECKBOX,
      handleChangeShowNewStatusCheckbox:
        CandidateActions.CHANGE_SHOW_NEW_STATUS_CHECKBOX,
      handleChangeShowKivCheckbox: CandidateActions.CHANGE_SHOW_KIV_CHECKBOX,
      handleChangeCandidatesView: CandidateActions.CHANGE_CANDIDATES_VIEW,
      handleEventTypeData: CandidateActions.EVENT_TYPE_DATA,
      handleChosenEventTypeUrl: CandidateActions.CHOSEN_EVENT_TYPE_URL,
      handleSetCurrentNameAttribute:
        CandidateActions.SET_CURRENT_NAME_ATTRIBUTE,
      handleSetDataLoading: CandidateActions.SET_DATA_LOADING,
      handleSortCandidates: CandidateActions.SORT_CANDIDATES,

      /* Editing candidate details */
      handleEditingCandidateDetails: CandidateActions.EDITING_CANDIDATE_DETAILS,
      handleEditCandidateDetailsSuccess:
        CandidateActions.EDIT_CANDIDATE_DETAILS_SUCCESS,
      handleEditCandidateDetailsFailure:
        CandidateActions.EDIT_CANDIDATE_DETAILS_FAILURE,

      /** Candidate listing Filter */
      handleWorkmapSlider: CandidateActions.CHANGE_WORKMAP_SCORE_SLIDER,
      handleResumeScoreSlider: CandidateActions.CHANGE_RESUME_SCORE_SLIDER,
      handleVideoScoreSlider: CandidateActions.CHANGE_VIDEO_SCORE_SLIDER,
      handleSetWorkmapScoreParameter:
        CandidateActions.SET_WORKMAP_SCORE_PARAMETER,
      handleCheckWorkmapFilterParameter:
        CandidateActions.CHECK_WORKMAP_FILTER_PARAMETER,
      handleCheckResumeScoreFilterParameter:
        CandidateActions.CHECK_RESUME_SCORE_FILTER_PARAMETER,
      handleCheckVideoScoreFilterParameter:
        CandidateActions.CHECK_VIDEO_SCORE_FILTER_PARAMETER,
      handleChangeVideoProfessionalismValue:
        CandidateActions.CHANGE_VIDEO_PROFESSIONALISM_VALUE,
      handleChangeVideoSociabilityValue:
        CandidateActions.CHANGE_VIDEO_SOCIABILITY_VALUE,
      handleChangeVideoCommunicationValue:
        CandidateActions.CHANGE_VIDEO_COMMUNICATION_VALUE,
      handleChangeVideoPositiveAttitudeValue:
        CandidateActions.CHANGE_VIDEO_POSITIVE_ATTITUDE_VALUE,
      handleSetCandidateDomainScoreSuccess:
        CandidateActions.SET_CANDIDATE_DOMAIN_SCORE_SUCCESS,
      handleSetCandidateDomainScoreFailure:
        CandidateActions.SET_CANDIDATE_DOMAIN_SCORE_FAILURE,
      handleSetUserNotAuthFromAIA:
        CandidateActions.SET_USER_NOT_AUTH_FROM_AIA,
      handleCandidateDetailsLoader:
        CandidateActions.SET_CANDIDATE_DETAILS_LOADER,
      handleUpdateWatchedVideoList:
        CandidateActions.UPDATE_WATCHED_VIDEO_LIST,

      handleSetRecentResponses: CandidateActions.SET_RECENT_RESPONSES,
      handleSetFunnelCount: CandidateActions.SET_FUNNEL_COUNT,
      handleSetWeeklyReportCounts: CandidateActions.SET_WEEKLY_REPORT_COUNTS,
      handleIsFetchRecentResponsesProcessing: CandidateActions
        .SET_FETCH_RECENT_RESPONSES_PROCESSING,
      handleSetRecentResponsesCount: CandidateActions.SET_RECENT_RESPONSES_COUNT,
      handleSetIsInterviewCreated: CandidateActions.SET_IS_INTERVIEW_CREATED,
      handleSetSampleCandidatesViewed: CandidateActions.SET_SAMPLE_CANDIDATES_VIEWED,
      handlesetClickedOnDownload:
        CandidateActions.SET_CLICKED_ON_DOWNLOAD,
      handleSetSearchCandidate:
        CandidateActions.SET_SEARCH_CANDIDATE,
      handleSetSearchedCandidateText:
        CandidateActions.SET_SEARCHED_CANDIDATE_TEXT,
      handlesetSearchedIconClicked:
        CandidateActions.SET_SEARCHED_ICON_CLICKED,
      handlesetUpdatedCandidateResponse:
        CandidateActions.SET_UPDATED_CANDIDATE_RESPONSE,
      handleSetCandidateIndexes:
        CandidateActions.SET_CANDIDATE_INDEXES,
      handleSetNewCandidateSuccess:
        CandidateActions.SET_NEW_CANDIDATE_SUCCESS,
      handleSetFetchingNewCandidate:
        CandidateActions.SET_FETCHING_NEW_CANDIDATE,
      handleSetFetchingCandidateDetailsOnClick:
        CandidateActions.SET_FETCHING_CANDIDATE_DETAILS_ON_CLICK,
      handleSetApplicationstatus:
        CandidateActions.SET_APPLICATIONSTATUS,
      handlesetFetchingCandidateIndexes:
        CandidateActions.SET_FETCHING_CANDIDATE_INDEXES,
      handleSetSearchedCandidateListingText:
        CandidateActions.SET_SEARCHED_CANDIDATE_LISTING_TEXT,
      handleSetSearchedIconClickedOnDetails:
        CandidateActions.SET_SEARCHED_ICON_CLICKED_ON_DETAILS,
      handleSetCurrentPage:
        CandidateActions.SET_CURRENT_PAGE,
      handlePaceAndClarityValue: CandidateActions.CHANGE_VIDEO_PACE_AND_CLARITY_VALUE,
      handleArticulationValue: CandidateActions.CHANGE_VIDEO_ARTICULATION_VALUE,
      handleSentimentsValue: CandidateActions.CHANGE_VIDEO_SENTIMENTS_VALUE,
      handleGrammarValue: CandidateActions.CHANGE_VIDEO_GRAMMAR_VALUE,
      handleChangeSociabilitySentimentValue: CandidateActions.CHANGE_SOCIABILITY_SENTIMENT_VALUE,
      handleChangeSociabilityEnergyValue: CandidateActions.CHANGE_SOCIABILITY_ENERGY_VALUE,
      handleChangeSociabilityEmotionValue: CandidateActions.CHANGE_SOCIABILITY_EMOTION_VALUE,
      handleChangeProfessionalismDressingyValue: CandidateActions
        .CHANGE_PROFESSIONALISM_DRESSING_VALUE,
      handleChangeProfessionalismGroomingValue: CandidateActions
        .CHANGE_PROFESSIONALISM_GROOMING_VALUE,
      handleChangeVideoBodyLanguageValue: CandidateActions
        .CHANGE_PROFESSIONALISM_BODY_LANGUAGE_VALUE,
      handleFetchCountrySuccess: CandidateActions
        .FETCH_COUNTRY_SUCCESS,
      handleSetCountryId: CandidateActions
        .SET_COUNTRY_ID,
      handleRemoveSelectedCountry: CandidateActions
        .REMOVE_SELECTED_COUNTRY,
      handleSelectFilterCandidateStatus: CandidateActions
        .SELECT_FILTER_CANDIDATE_STATUS,
      handleRemoveSelectedStatus: CandidateActions
        .REMOVE_SELECTED_STATUS,
      handleSelectFilterRecruiterStatus: CandidateActions
        .SELECT_FILTER_RECRUITER_STATUS,
      handleRemoveSelectedRecruiterStatus: CandidateActions
        .REMOVE_SELECTED_RECRUITER_STATUS,
      handleSetFilterIntroDropdown: CandidateActions
        .SET_FILTER_INTRO_DROPDOWN,
      handleSetSelectedIntroQuestionsOptions: CandidateActions
        .SET_SELECTED_INTRO_QUESTIONS_OPTIONS,
      handlesetResetFilters: CandidateActions
        .SET_RESET_FILTERS,
      handleSetAdditionalIntroQuestion: CandidateActions
        .SET_ADDITIONAL_INTRO_QUESTION,
      handleSetFilterIconClicked:
        CandidateActions.SET_FILTER_ICON_CLICKED,
      handleSetActivePanel:
        CandidateActions.SET_ACTIVE_PANEL,
      handleResetCandidateAndRecruiterStatus:
        CandidateActions.RESET_CANDIDATE_AND_RECRUITER_STATUS,
      handleRemoveSelectedIntroQuestionChoice:
        CandidateActions.REMOVE_SELECTED_INTRO_QUESTION_CHOICE,
      handleSetSearchedCandidateNameOnDetails:
        CandidateActions.SET_SEARCHED_CANDIDATE_NAME_ON_DETAILS,
      handleSetOverviewMessageObject:
        CandidateActions.SET_OVERVIEW_MESSAGE_OBJECT,
      handleSetCandidateSharedToList:
        CandidateActions.SET_CANDIDATE_SHARED_TO_LIST,
      handleSetCandidateFiltersOpen:
        CandidateActions.SET_CANDIDATE_FILTERS_OPEN,
      handleSelectFilterRelevancyScore: CandidateActions
        .SELECT_FILTER_RELEVANCY_SCORE,
      handleFetchCandidateEmailInviteCount: CandidateActions.FETCH_CANDIDATE_EMAIL_INVITE_COUNT,
      handleChangeVideoContentRelevancyValue: CandidateActions.CHANGE_VIDEO_CONTENT_RELEVANCY_VALUE,
      handleSetStartCandidateBulkDownload: CandidateActions.SET_START_CANDIDATE_BULK_DOWNLOAD,
      handleCloseAddCandidateModal: CandidateActions.CLOSE_ADD_CANDIDATE_MODAL,
    });
  }

  handleSetStartedProfile() {
    const {
      videoProfessionalismValue,
      videoSociabilityValue,
      videoCommunicationValue,
      videoPositiveAttitudeValue,
    } = this.candidateFilters;
    this.data = this.data.map((c) => {
      if (videoProfessionalismValue <= Number(c.professionalismScore.toFixed(0))
        && videoSociabilityValue <= Number(c.sociabilityScore.toFixed(0))
        && videoCommunicationValue <= Number(c.totalCommunicationScore.toFixed(0))
        && videoPositiveAttitudeValue <= Number(c.energyLevelScore.toFixed(0))) {
        c.isStartedProfile = true;   //eslint-disable-line
      } else {
        c.isStartedProfile = false;  //eslint-disable-line
      }
      return c;
    });
    this.displayData = this.data;
  }

  handleIsSaveScoreSettings(data) {
    this.isSavingScoreSettings = data;
  }

  handleSaveScoreSettings() {
    scoreCandidates(
      this.data,
      this.scoreSliderValue,
      this.enabledScoreCategory,
      this.scoreParams,
      this.scoreSettingWeight,
      this.isAIAOrg,
      this.enableScoreSettingUpdate,
    );
  }

  handlesetUpdatedCandidateResponse(data) {
    this.updatedCandidateResponse = scoreCandidates(
      data,
      this.scoreSliderValue,
      this.enabledScoreCategory,
      this.scoreParams,
      this.scoreSettingWeight,
      this.isAIAOrg,
      this.enableScoreSettingUpdate,
    );
  }

  handleResetScoreSettings() {
    const {
      workmapScoreEnabled,
      resumeScoreEnabled,
      videoScoreEnabled,
    } = this.enabledScoreCategory;
    if (!workmapScoreEnabled && resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 50;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 50;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (!workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 100;
    } else {
      this.scoreSettingWeight.resumeScoreSettingWeight = 33.3;
      this.scoreSettingWeight.workmapScoreSettingWeight = 33.3;
      this.scoreSettingWeight.videoScoreSettingWeight = 33.3;
    }
    this.totalScoreSettings = this.scoreSettingWeight.videoScoreSettingWeight
      + this.scoreSettingWeight.workmapScoreSettingWeight
      + this.scoreSettingWeight.resumeScoreSettingWeight;

    this.candidateFilters.videoProfessionalismValue = 50;
    this.candidateFilters.videoContentRelevancyValue = 50;
    this.candidateFilters.videoSociabilityValue = 50;
    this.candidateFilters.videoCommunicationValue = 50;
    this.candidateFilters.videoPositiveAttitudeValue = 50;

    this.candidateFilters
      .communicationPaceAndClarity = 33.33;
    this.candidateFilters
      .communicationArticulation = 33.33;

    this.candidateFilters
      .communicationSentiments = 33.33;

    // Grammar score reset
    this.candidateFilters
      .communicationGrammarScore = 33.33;

    this.candidateFilters
      .professionalismBodyLanguage = 33.33;
    this.candidateFilters
      .professionalismDressing = 33.33;
    this.candidateFilters
      .professionalismGrooming = 33.33;

    this.candidateFilters
      .sociabilityEmotion = 33.33;
    this.candidateFilters
      .sociabilityEnergyLevel = 33.33;
    this.candidateFilters
      .sociabilitySentiments = 33.33;
  }

  handleResumeWeightageChange(data) {
    this.scoreSettingWeight.resumeScoreSettingWeight = data;
    this.totalScoreSettings = data
      + this.scoreSettingWeight.videoScoreSettingWeight
      + this.scoreSettingWeight.workmapScoreSettingWeight;
  }

  handleWorkmapWeightageChange(data) {
    this.scoreSettingWeight.workmapScoreSettingWeight = data;
    this.totalScoreSettings = data
      + this.scoreSettingWeight.videoScoreSettingWeight
      + this.scoreSettingWeight.resumeScoreSettingWeight;
  }

  handleVideoWeightageChange(data) {
    this.scoreSettingWeight.videoScoreSettingWeight = data;
    this.totalScoreSettings = data
      + this.scoreSettingWeight.resumeScoreSettingWeight
      + this.scoreSettingWeight.workmapScoreSettingWeight;
  }

  handleChangeVideoProfessionalismValue(data) {
    this.candidateFilters.videoProfessionalismValue = data;
  }

  handleChangeVideoContentRelevancyValue(data) {
    this.candidateFilters.videoContentRelevancyValue = data;
  }

  handleChangeVideoSociabilityValue(data) {
    this.candidateFilters.videoSociabilityValue = data;
  }

  handleChangeVideoCommunicationValue(data) {
    this.candidateFilters.videoCommunicationValue = data;
  }

  handleChangeVideoPositiveAttitudeValue(data) {
    this.candidateFilters.videoPositiveAttitudeValue = data;
  }

  handleSortCandidates({ key, isSameSortOrder = false }) {
    this.sortKey = key;
    if (!isSameSortOrder) {
      // keeps toggling between desc and asc
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';// swap the order
    }
    if (isSameSortOrder) {
      this.sortOrder = 'desc';
    }
    this.displayData = this.displayData.sort((candidateA, candidateB) => {
      let attributeA;
      let attributeB;
      // hidden candidates are always arranged to the back
      if (candidateA.hidden) {
        return 1;
      }
      if (candidateB.hidden) {
        return -1;
      }

      if (key.search('date') >= 0) {
        attributeA = candidateA[key] ? new Date(candidateA[key]) : null;
        attributeB = candidateB[key] ? new Date(candidateB[key]) : null;
      } else if (key === 'resumeScore') {
        attributeA = candidateA.metadata
          && candidateA.metadata.parsed_resume
          && candidateA.metadata.parsed_resume.score
          && candidateA.metadata.parsed_resume.score.overall;
        attributeB = candidateB.metadata
          && candidateB.metadata.parsed_resume
          && candidateB.metadata.parsed_resume.score
          && candidateB.metadata.parsed_resume.score.overall;
      } else if (key === 'location') {
        attributeA = candidateA.metadata && candidateA.metadata.location;
        attributeB = candidateB.metadata && candidateB.metadata.location;
      } else if (key === 'name') {
        attributeA = candidateA.metadata && candidateA.name;
        attributeB = candidateB.metadata && candidateB.name;
      } else if (key === 'status') {
        attributeA = candidateA[key];
        attributeB = candidateB[key];
      } else if (key === 'totalScore') {
        attributeA = candidateA[key];
        attributeB = candidateB[key];
      } else if (key === 'totalScoreSetting') {
        attributeA = candidateA[key];
        attributeB = candidateB[key];
      } else if (key === 'videoScore') {
        attributeA = candidateA[key];
        attributeB = candidateB[key];
      } else {
        attributeA = typeof candidateA[key] === 'string'
          ? candidateA[key].toUpperCase()
          : candidateA[key];
        attributeB = typeof candidateB[key] === 'string'
          ? candidateB[key].toUpperCase()
          : candidateB[key];
      }

      attributeA = attributeA === undefined
        || Number.isNaN(attributeA) ? 0 : attributeA;
      attributeB = attributeB === undefined
        || Number.isNaN(attributeB) ? 0 : attributeB;

      let comparison = 0;
      if (attributeA === attributeB) {
        return 0;
      }
      if (attributeA === null) {
        return 1;
      }
      if (attributeB === null) {
        return -1;
      }
      if (attributeA > attributeB) {
        comparison = 1;
      } else if (attributeA < attributeB) {
        comparison = -1;
      }
      this.displayData = this.data;

      return this.sortOrder === 'desc' ? comparison * -1 : comparison;
    });
  }

  handleGettingCandidates() {
    this.isDataLoading = true;
  }

  handleGettingCandidatesForWidgit() {
    this.isDataLoadingForCandWidgit = true;
  }

  updateDisplayData(forceRefresh = false) { // eslint-disable-line
    this.displayData = this.data;

    const {
      workmapFilterParameters,
      resumeFilterParameters,
      videoScoreFilterParameter,
      statusFilterParameteras,
      filterStartDate,
      filterEndDate,
      workmapScoreSliderValue,
      resumeScoreSliderValue,
      videoScoreSliderValue,
      filterAdditionalFormValues,
      rated,
    } = this.candidateFilters;

    // const workmapScoreThreshold = (workmapScoreSliderValue * 10) / 100;
    const workmapScoreThreshold = workmapScoreSliderValue;
    const resumeScoreThreshold = resumeScoreSliderValue;
    const videoScoreThreshold = videoScoreSliderValue;
    const workmapFiltersToApplied = Object.keys(workmapFilterParameters).filter(
      x => workmapFilterParameters[x],
    );
    const resumeScoreFiltersToApplied = Object.keys(
      resumeFilterParameters,
    ).filter(x => resumeFilterParameters[x]);
    const videoScoreFiltersToApplied = Object.keys(
      videoScoreFilterParameter,
    ).filter(x => videoScoreFilterParameter[x]);
    const statusFiltersToApplied = Object.keys(statusFilterParameteras).filter(
      x => statusFilterParameteras[x],
    );
    if (this.filterApplied) { // eslint-disable-line
      workmapFiltersToApplied.map((x) => { // eslint-disable-line
        this.displayData = this.displayData.filter((candidate) => { // eslint-disable-line
          if (candidate.metadata && candidate.metadata.workmap_score) {
            if (candidate.metadata.workmap_score[x] >= workmapScoreThreshold) {
              return candidate;
            }
          }
        });
      });

      resumeScoreFiltersToApplied.map((x) => { // eslint-disable-line
        this.displayData = this.displayData.filter((candidate) => { // eslint-disable-line
          if (
            candidate.metadata
            && candidate.metadata.parsed_resume
            && candidate.metadata.parsed_resume.score
          ) {
            if (
              candidate.metadata.parsed_resume.score[x] >= resumeScoreThreshold
            ) {
              return candidate;
            }
          }
        });
      });

      videoScoreFiltersToApplied.map((x) => { // eslint-disable-line
        this.displayData = this.displayData.filter((candidate) => { // eslint-disable-line
          candidate['Professionalism'] = candidate.professionalismScore; // eslint-disable-line
          candidate['Energy level'] = candidate.energyLevelScore; // eslint-disable-line
          candidate['Communication'] = candidate.communicationScore; // eslint-disable-line
          candidate['Sociability'] = candidate.sociabilityScore; // eslint-disable-line
          if (candidate[x]) {
            if (candidate[x] >= videoScoreThreshold) {
              return candidate;
            }
          }
        });
      });

      statusFiltersToApplied.map(x => { // eslint-disable-line
        const loweredCaseStatus = x.toLowerCase();
        this.displayData = this.displayData.filter(candidate => { // eslint-disable-line
          // (candidate.status === 'New')
          // && candidate.viewed === false
          if (loweredCaseStatus === 'new') {
            if (
              candidate.status === 'New'
            ) {
              return candidate;
            }
          } else if (loweredCaseStatus === 'yet to respond') {
            if (candidate.status === 'New' || candidate.status === 'Sent') {
              return candidate;
            }
          } else if (loweredCaseStatus === 'responded') {
            if (
              candidate.status === 'Responded'
              || candidate.status === 'Evaluated'
            ) {
              return candidate;
            }
          } else if (loweredCaseStatus === 'rated') {
            if (
              candidate.userFeedback && candidate.userFeedback.length > 0
            ) {
              return candidate;
            }
          } else {
            if (candidate[loweredCaseStatus]) { // eslint-disable-line
              return candidate;
            }
          }
        });
      });
    }

    if (filterStartDate && filterEndDate) {
      this.displayData = this.displayData.filter(candidate => (
        candidate.dateCreatedObject >= moment(filterStartDate)
        && candidate.dateCreatedObject <= moment(filterEndDate)
      ));
    }

    if (rated) {
      this.displayData = this.displayData.filter((candidate) => { // eslint-disable-line
        return candidate.userFeedback && candidate.userFeedback.length > 0;
      });
    }

    // check if resume content or caption has the keywords filter matched
    if (this.filterKeywords.length > 0) {
      this.displayData = this.displayData.filter(
        c => (c.metadata.parsed_resume
          && this.filterKeywords.some(
            keyword => c.metadata.parsed_resume.resume
              && c.metadata.parsed_resume.resume.resumeConten
              && c.metadata.parsed_resume.resume.resumeContent
                .toLowerCase()
                .indexOf(keyword.toLowerCase()) !== -1,
          ))
          || c.caption.some(caption => this.filterKeywords.some(
            keyword => caption.toLowerCase().indexOf(keyword.toLowerCase()) !== -1,
          )),

      );
    }

    // additional form fields filter
    if (filterAdditionalFormValues) {
      Object.keys(filterAdditionalFormValues).map((key) => { // eslint-disable-line
        if (filterAdditionalFormValues[key] !== '') {
          this.displayData = this.displayData.filter((c) => { // eslint-disable-line
            if (c.metadata.additional_fields) {
              return filterAdditionalFormValues[key].includes(
                c.metadata.additional_fields[key],
              );
            }
          });
        }
      });
    }
  }

  handleGetSingleCandidatesSuccess(data) {
    this.showScoreSettings = data.data.showScoreSettings;
    this.isCandidateDetailScreen = data.isCandidateDetailScreen;
    this.interviewDetail = data.data.interview;

    if (data.data.candidates.length === 0 && this.isCandidateDetailScreen) {
      // no data
      this.loadDataFail = true;
      this
        .loadDataFailMsg = 'No candidates yet. Send interview to candidates now!';
      this.isDataLoading = false;
      this.isDataLoadingForCandWidgit = false;
      return;
    }
    this.isRecommended = data.data.isRecommended;
    this.loadDataFail = false;
    this.interviewId = data.interviewId;

    this.enabledScoreCategory = {
      ...this.enabledScoreCategory,
      workmapScoreEnabled: this.interviewDetail.workmap_enabled || false,
      resumeScoreEnabled:
        (this.interviewDetail.interview_description
          && this.interviewDetail.resume_mandatory)
        || false,
    }; // if skillate_reuisition is set, we are doing resume scoring
    const {
      workmapScoreEnabled,
      resumeScoreEnabled,
      videoScoreEnabled,
    } = this.enabledScoreCategory;

    if (!workmapScoreEnabled && resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 50;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 50;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (!workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 100;
    } else {
      this.scoreSettingWeight.resumeScoreSettingWeight = 33.3;
      this.scoreSettingWeight.workmapScoreSettingWeight = 33.3;
      this.scoreSettingWeight.videoScoreSettingWeight = 33.3;
    }

    if (this.interviewDetail.workmap_enabled) {
      this.scoreParams.workmap = this.interviewDetail.workmap_skills.reduce(
        (dict, skillName) => ({
          ...dict,
          [skillName]: true,
        }),
        {},
      );
    }

    if (data.data.candidates.length >= 1) {
      // true because this is for aia only
      this.data = scoreCandidates(
        data.data.candidates,
        this.scoreSliderValue,
        this.enabledScoreCategory,
        this.scoreParams,
        this.scoreSettingWeight,
        true,
        this.enableScoreSettingUpdate,
      );
      this.candidateQuestions = data.data.candidates.map(candidate => ({
        candidateId: candidate.candidateid,
        questions: candidate.questions,
      }));

      const locationOptions = new Set();
      this.data.map((c) => {
        // for date sorting and location filter purposes
        c.dateRespondedObject = moment(c.dateresponded); // eslint-disable-line no-param-reassign
        c.dateCreatedObject = moment(c.datecreated); // eslint-disable-line no-param-reassign
        if (c.metadata && c.metadata.location) {
          locationOptions.add(c.metadata.location);
        }
        return c;
      });
      this.filterLocationOptions = Array.from(locationOptions).sort();

      const nameOptions = new Set();
      this.data.map((c) => {
        // for name filter purposes
        c.dateRespondedObject = moment(c.dateresponded); // eslint-disable-line no-param-reassign
        c.dateCreatedObject = moment(c.datecreated); // eslint-disable-line no-param-reassign
        if (c.metadata && c.name) {
          nameOptions.add(c.name);
        }
        return c;
      });
      this.filterNameOptions = Array.from(nameOptions).sort();

      this.filterAdditionalFormFields = data.data.interview
        .additional_form_field
        ? JSON.parse(data.data.interview.additional_form_field)
        : [];

      // for text form fields, we generate the options from candidate's submission
      this.filterAdditionalFormFields.map((field) => { // eslint-disable-line array-callback-return
        if (field.type === 'text') {
          const fieldOptions = new Set();
          this.data.map((c) => { // eslint-disable-line array-callback-return
            if (
              c.metadata
              && c.metadata.additional_fields
              && c.metadata.additional_fields[field.name]
            ) {
              fieldOptions.add(c.metadata.additional_fields[field.name]);
            }
          });
          field.options = Array.from(fieldOptions).sort(); // eslint-disable-line no-param-reassign
        }
      });

      const selectedCandidate = this.data[this.selectedCandidatesIndex];
      this.currentCandidateId = selectedCandidate
        ? selectedCandidate.candidateid
        : this.currentCandidateId;
      this.checkHasShortlistedCandidate();
      // updateDisplayData will sort once based on current key and sortOrder
      this.updateDisplayData(false);
    } else {
      this.data = [];
      this.displayData = [];
    }
    if (data.candidateId && this.data.length > 0) {
      const index = this.displayData.findIndex(
        candidate => candidate.candidateid === data.candidateId,
      );
      if (index >= 0) {
        const updateIndexObj = {
          selectedCandidatesIndex: index,
          selectedQuestionIndex: 0,
        };
        this.handleUpdateIndex(updateIndexObj);
      }
    }

    this.hiredCandidates = data.hired;
    this.displayData = this.data;

    this.isDataLoading = false;
  }

  // coming from OverviewMain or CandidatesDetail or AddCandidateScreen
  handleGetCandidatesSuccess(data) {
    this.enableScoreSettingUpdate = data.data.enableScoreSettingUpdate;
    this.isCandidateDetailScreen = data.isCandidateDetailScreen;
    if (!data.isCandidateDetailScreen) {
      this.totalCandidates = data.totalCandidates;
    }
    this.isAIAOrg = data.data.isAIAOrg;

    if (data.data.candidates.length === 0 && this.isCandidateDetailScreen) {
      // no data
      this.loadDataFail = true;
      this
        .loadDataFailMsg = 'No candidates yet. Send interview to candidates now!';
      if (data.interviewId === '60d91419-73e4-4d8f-932c-8db68d5c830f') {
        this
          .loadDataFailMsg = 'Kandidat tidak direkomendasikan lebih dari 90 hari';
      }
      this.isDataLoading = false;
      return;
    }
    this.isRecommended = data.data.isRecommended;
    this.loadDataFail = false;
    this.interviewId = data.interviewId;
    this.interviewDetail = data.data.interview;

    this.enabledScoreCategory = {
      ...this.enabledScoreCategory,
      workmapScoreEnabled: this.interviewDetail.workmap_enabled || false,
      resumeScoreEnabled:
        (this.interviewDetail.interview_description
          && this.interviewDetail.resume_mandatory)
        || false,
    }; // if skillate_reuisition is set, we are doing resume scoring

    if (this.interviewDetail.workmap_enabled) {
      this.scoreParams.workmap = this.interviewDetail.workmap_skills.reduce(
        (dict, skillName) => ({
          ...dict,
          [skillName]: true,
        }),
        {},
      );
    }

    if (data.data.candidates.length >= 1) {
      this.data = scoreCandidates(
        data.data.candidates,
        this.scoreSliderValue,
        this.enabledScoreCategory,
        this.scoreParams,
        this.scoreSettingWeight,
        this.isAIAOrg,
        this.enableScoreSettingUpdate,
      );
      this.candidateQuestions = data.data.candidates.map(candidate => ({
        candidateId: candidate.candidateid,
        questions: candidate.questions,
      }));

      const locationOptions = new Set();
      this.data.map((c) => {
        // for date sorting and location filter purposes
        c.dateRespondedObject = moment(c.dateresponded); // eslint-disable-line no-param-reassign
        c.dateCreatedObject = moment(c.datecreated); // eslint-disable-line no-param-reassign
        if (c.metadata && c.metadata.location) {
          locationOptions.add(c.metadata.location);
        }
        return c;
      });
      this.filterLocationOptions = Array.from(locationOptions).sort();

      const nameOptions = new Set();
      this.data.map((c) => {
        // for name filter purposes
        c.dateRespondedObject = moment(c.dateresponded); // eslint-disable-line no-param-reassign
        c.dateCreatedObject = moment(c.datecreated); // eslint-disable-line no-param-reassign
        if (c.metadata && c.name) {
          nameOptions.add(c.name);
        }
        return c;
      });
      this.filterNameOptions = Array.from(nameOptions).sort();

      this.filterAdditionalFormFields = data.data.interview
        .additional_form_field
        ? JSON.parse(data.data.interview.additional_form_field)
        : [];

      // for text form fields, we generate the options from candidate's submission
      this.filterAdditionalFormFields.map((field) => { // eslint-disable-line array-callback-return
        if (field.type === 'text') {
          const fieldOptions = new Set();
          this.data.map((c) => { // eslint-disable-line array-callback-return
            if (
              c.metadata
              && c.metadata.additional_fields
              && c.metadata.additional_fields[field.name]
            ) {
              fieldOptions.add(c.metadata.additional_fields[field.name]);
            }
          });
          field.options = Array.from(fieldOptions).sort(); // eslint-disable-line no-param-reassign
        }
      });

      const selectedCandidate = this.data[this.selectedCandidatesIndex];
      this.currentCandidateId = selectedCandidate
        ? selectedCandidate.candidateid
        : this.currentCandidateId;
      this.checkHasShortlistedCandidate();
      // updateDisplayData will sort once based on current key and sortOrder
      this.updateDisplayData(false);
      // if orderCandidates is true, we will sort by totalScore in Desc order
    } else {
      this.data = [];
      this.displayData = [];
    }
    if (data.candidateId && this.data.length > 0) {
      const index = this.displayData.findIndex(
        candidate => candidate.candidateid === data.candidateId,
      );
      if (index >= 0) {
        const updateIndexObj = {
          selectedCandidatesIndex: index,
          selectedQuestionIndex: 0,
        };
        this.handleUpdateIndex(updateIndexObj);
      }
    }

    this.hiredCandidates = data.hired;
    this.displayData = this.data;

    this.isDataLoading = false;
    this.isDataLoadingForCandWidgit = false;
  }

  handleSetNewCandidateSuccess(data) {
    this.totalCandidates = data.totalCandidates;
    this.interviewDetail = data.data.interviewData;
    this.isAIAOrg = data.data.isAIAOrg;
    this.enabledScoreCategory = {
      ...this.enabledScoreCategory,
      workmapScoreEnabled: this.interviewDetail.workmap_enabled || false,
      resumeScoreEnabled:
        (this.interviewDetail.interview_description
          && this.interviewDetail.resume_mandatory)
        || false,
    }; // if skillate_reuisition is set, we are doing resume scoring
    const {
      workmapScoreEnabled,
      resumeScoreEnabled,
      videoScoreEnabled,
    } = this.enabledScoreCategory;

    if (!workmapScoreEnabled && resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 50;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 50;
      this.scoreSettingWeight.videoScoreSettingWeight = 50;
    } else if (!workmapScoreEnabled && !resumeScoreEnabled && videoScoreEnabled) {
      this.scoreSettingWeight.resumeScoreSettingWeight = 0;
      this.scoreSettingWeight.workmapScoreSettingWeight = 0;
      this.scoreSettingWeight.videoScoreSettingWeight = 100;
    } else {
      this.scoreSettingWeight.resumeScoreSettingWeight = 33.3;
      this.scoreSettingWeight.workmapScoreSettingWeight = 33.3;
      this.scoreSettingWeight.videoScoreSettingWeight = 33.3;
    }
    this.showScoreSettings = data.data.showScoreSettings;
    this.newCandidateData = scoreCandidates(
      data.data.candidates,
      this.scoreSliderValue,
      this.enabledScoreCategory,
      this.scoreParams,
      this.scoreSettingWeight,
      this.isAIAOrg,
      this.enableScoreSettingUpdate,
    );
    const { scoreSettings } = data.data;
    if (scoreSettings) {
      this.scoreSettingWeight.resumeScoreSettingWeight = Number(scoreSettings.resumeScore);
      this.scoreSettingWeight.workmapScoreSettingWeight = Number(scoreSettings.workmapScore);
      this.scoreSettingWeight.videoScoreSettingWeight = Number(scoreSettings.videoScore);
      this.totalScoreSettings = Number(scoreSettings.resumeScore)
        + Number(scoreSettings.workmapScore)
        + Number(scoreSettings.videoScore);
      if (scoreSettings) {
        const {
          videoScoreBreakdown,
          communicationBreakdown,
          professionalismBreakdown,
          sociabilityBreakdown,
        } = scoreSettings;

        if (videoScoreBreakdown) {
          this.candidateFilters.videoContentRelevancyValue = videoScoreBreakdown.contentRelevancy;
          this.candidateFilters.videoProfessionalismValue = videoScoreBreakdown.professionalism;
          this.candidateFilters.videoCommunicationValue = videoScoreBreakdown.communication;
          this.candidateFilters.videoSociabilityValue = videoScoreBreakdown.sociability;
          this.candidateFilters.videoPositiveAttitudeValue = videoScoreBreakdown['positive attitude'];
          this.newCandidateData = this.newCandidateData.map((c) => {
            if (videoScoreBreakdown.professionalism <= Number(c.professionalismScore.toFixed(0))
              && videoScoreBreakdown.sociability <= Number(c.sociabilityScore.toFixed(0))
              && videoScoreBreakdown.communication <= Number(c.totalCommunicationScore.toFixed(0))
              && videoScoreBreakdown['positive attitude'] <= Number(c.energyLevelScore.toFixed(0))) {
              c.isStartedProfile = true;   //eslint-disable-line
            } else {
              c.isStartedProfile = false;  //eslint-disable-line
            }
            return c;
          });
          // this.displayData = this.data;
        }

        if (communicationBreakdown) {
          this.candidateFilters
            .communicationPaceAndClarity = Number(communicationBreakdown.paceAndClarity);
          this.candidateFilters
            .communicationArticulation = Number(communicationBreakdown.articulation);
          this.candidateFilters
            .communicationSentiments = Number(communicationBreakdown.sentiments);
        }

        if (professionalismBreakdown) {
          this.candidateFilters
            .professionalismBodyLanguage = Number(professionalismBreakdown.bodyLanguage);
          this.candidateFilters
            .professionalismDressing = Number(professionalismBreakdown.dressing);
          this.candidateFilters
            .professionalismGrooming = Number(professionalismBreakdown.grooming);
        }

        if (sociabilityBreakdown) {
          this.candidateFilters
            .sociabilityEmotion = Number(sociabilityBreakdown.emotion);
          this.candidateFilters
            .sociabilityEnergyLevel = Number(sociabilityBreakdown.energyLevel);
          this.candidateFilters
            .sociabilitySentiments = Number(sociabilityBreakdown.sentiments);
        }
      }
    }
  }

  handleGetCandidatesFail(msg) {
    this.isDataLoading = false;
    this.isDataLoadingForCandWidgit = false;
    this.loadDataFail = true;
    this.loadDataFailMsg = msg;
  }

  handleGettingVideoUrl() {
    this.fetchingS3Video = true;
    this.fetchS3VideoFail = false;
    this.fetchS3VideoFailMessage = '';
  }

  handleGetVideoUrlFail(errMsg) {
    this.fetchingS3Video = false;
    this.fetchS3VideoFail = true;
    this.fetchS3VideoFailMessage = errMsg;
  }

  handleGetVideoUrlSuccess(data) {
    this.fetchingS3Video = false;
    this.videoUrl = data.videoUrl;
    this.videoCaption = data.videoCaption;
  }

  handleSetVideoCache(data) {
    this.videoCache[data.candidateId] = data.videoCache;
  }

  handleSetAutoPlay() {
    this.autoplay = true;
  }

  handleUpdateIndex(data) {
    this.selectedCandidatesIndex = data.selectedCandidatesIndex;
    this.selectedQuestionIndex = data.selectedQuestionIndex;
    this.currentCandidateId = this.displayData[this.selectedCandidatesIndex]
      ? this.displayData[this.selectedCandidatesIndex].candidateid
      : '';
  }

  async handleToggleSortFilter() {
    this.sortFilterShow = !this.sortFilterShow;
  }

  async sideEffectAfterScoring() {
    if (this.currentCandidateId !== this.displayData[this.selectedCandidatesIndex].candidateid) {
      const index = findCandidatesIndex(
        this.displayData,
        this.currentCandidateId,
      );
      if (index !== -1) {
        this.selectedCandidatesIndex = index;
      }
    }

    // pass through filter flags
    this.updateDisplayData();
  }

  async handleChangeSliderValue(data) {
    this.scoreSliderValue[data.key] = data.value;

    this.data = scoreCandidates(
      this.data,
      this.scoreSliderValue,
      this.enabledScoreCategory,
      this.scoreParams,
      this.scoreSettingWeight,
      this.isAIAOrg,
      this.enableScoreSettingUpdate,
    );

    this.sideEffectAfterScoring();
  }

  handleChangeScoreParams(data) {
    const { type, key, value } = data;
    this.scoreParams[type][key] = value;

    // we prevent all score params to be unchecked to prevent division by 0
    if (!value) {
      if (
        Object.keys(this.scoreParams[type])
          .map(k => this.scoreParams[type][k])
          .every(v => v === false)
      ) {
        this.scoreParams[type][key] = !value;
      }
    }

    this.data = scoreCandidates(
      this.data,
      this.scoreSliderValue,
      this.enabledScoreCategory,
      this.scoreParams,
      this.scoreSettingWeight,
      this.isAIAOrg,
      this.enableScoreSettingUpdate,
    );

    this.sideEffectAfterScoring();
  }

  checkHasShortlistedCandidate() {
    this
      .hasShortlistedCandidates = filter(this.data,
        { shortlisted: true }).length > 0;
  }

  checkHasHiredCandidate() {
    this.hasHiredCandidates = filter(this.data, { hired: true }).length > 0;
  }

  handleShortlistCandidateUpdate(data) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    this.data[updatedCandidateIndex].shortlisted = data.value;
    this.data[updatedCandidateIndex].viewed = true;
    this.checkHasShortlistedCandidate();
    this.updateDisplayData();
  }

  handleHiredCandidateUpdate(data) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    this.data[updatedCandidateIndex].hired = data.value;
    this.data[updatedCandidateIndex].viewed = true;
    this.checkHasHiredCandidate();
    this.updateDisplayData();
  }

  handleKivCandidateUpdate(data) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    this.data[updatedCandidateIndex].kived = data.value;
    this.data[updatedCandidateIndex].viewed = true;
    this.updateDisplayData();
  }

  handleLikeCandidateUpdate(data) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    if (data.value) {
      remove(
        this.data[updatedCandidateIndex].user_dislikes,
        userId => userId === data.userId,
      );
      this.data[updatedCandidateIndex].user_likes.push(data.userId);
    } else {
      remove(
        this.data[updatedCandidateIndex].user_likes,
        userId => userId === data.userId,
      );
    }

    this.updateDisplayData();
  }

  handleDislikeCandidateUpdate(data) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    if (data.value) {
      remove(
        this.data[updatedCandidateIndex].user_likes,
        (userId) => userId === data.userId, // eslint-disable-line
      );
      this.data[updatedCandidateIndex].user_dislikes.push(data.userId);
    } else {
      remove(
        this.data[updatedCandidateIndex].user_dislikes,
        (userId) => userId === data.userId, // eslint-disable-line
      );
    }
    this.updateDisplayData();
  }

  handleChangeShowShortlistedCheckbox(val: boolean) { // eslint-disable-line
    this.candidateFilters
      .statusFilterParameteras.Shortlisted = !this.candidateFilters
        .statusFilterParameteras.Shortlisted;
  }


  handleSetShowShortlistedCandidate(val: boolean) {
    this.candidateFilters.statusFilterParameteras = STATUS_FILTER_PARAMETER.reduce(
      (acc, curr) => ((acc[curr] = false), acc), // eslint-disable-line
      {},
    );
    if (val === 'YET_TO_RESPOND') {
      this.candidateFilters
        .statusFilterParameteras['Yet to Respond'] = !this.candidateFilters
          .statusFilterParameteras['Yet to Respond'];
    } else if (val === 'RESPONDED') {
      this.candidateFilters
        .statusFilterParameteras.Responded = !this.candidateFilters
          .statusFilterParameteras.Responded;

      this.filterData.showRespondedStatus = true;
    } else if (val === 'NEW_RESPONSES') {
      this.candidateFilters
        .statusFilterParameteras.New = !this.candidateFilters
          .statusFilterParameteras.New;
    } else if (val === 'SHORTLISTED') {
      this.candidateFilters
        .statusFilterParameteras.Shortlisted = !this.candidateFilters
          .statusFilterParameteras.Shortlisted;
    } else if (val === 'HIRED') {
      this.candidateFilters
        .statusFilterParameteras.Hired = !this.candidateFilters
          .statusFilterParameteras.Hired;
    } else if (val === 'RATED') {
      this.candidateFilters
        .statusFilterParameteras.Rated = !this.candidateFilters
          .statusFilterParameteras.Rated;
    } else {
      this.filterData.showAllCandidates = true;
    }
    this.filterApplied = true;

    this.updateDisplayData();
  }

  handleChangeShowHiredSelected(val: boolean) {
    Object.keys(this.filterData).map((key) => { // eslint-disable-line
      if (typeof this.filterData[key] === 'boolean') {
        this.filterData[key] = false;
      } else if (this.filterData[key] === 'object') {
        this.filterData[key] = {};
      } else if (this.filterData[key] === 'string') {
        this.filterData[key] = '';
      }
    });
    this.filterData.showHired = val;
    this.filterApplied = true;
    this.tabFilterApplied = false;
    this.updateDisplayData();
  }

  handleChangeRejectedCheckbox(val: boolean) { // eslint-disable-line
    this.candidateFilters
      .statusFilterParameteras.Rejected = !this.candidateFilters
        .statusFilterParameteras.Rejected;
  }

  handleChangeShowRespondedStatusCheckbox(val: boolean) { // eslint-disable-line
    this.candidateFilters
      .statusFilterParameteras.showRespondedStatus = !this.candidateFilters
        .statusFilterParameteras.showRespondedStatus;
  }

  handleChangeShowNewStatusCheckbox(val: boolean) { // eslint-disable-line
    this.candidateFilters
      .statusFilterParameteras.New = !this.candidateFilters
        .statusFilterParameteras.New;
  }

  handleChangeShowKivCheckbox(val: boolean) { // eslint-disable-line
    // this.filterData.showKIV = val;
    this.candidateFilters
      .statusFilterParameteras.showKIV = !this.candidateFilters
        .statusFilterParameteras.showKIV;
  }

  handleChangeLocationFilter(val: string) {
    this.filterData.filterLocation = val;
  }

  handleChangeStartDateFilter(dateObject: Object) {
    const { dateStr } = dateObject;
    if (dateStr) {
      this.filterStartDate = dateStr;
    } else if (dateStr === '' || !dateStr) {
      this.filterStartDate = null;
    }
  }

  handleChangeEndDateFilter(dateObject: Object) {
    const { dateStr } = dateObject;
    if (dateStr) {
      this.filterEndDate = dateStr;
    } else if (dateStr === '' || !dateStr) {
      this.filterEndDate = null;
    }
  }

  handleResetFilters() {
    this.filterData = {
      showAllCandidates: false,
      showShortlisted: true,
      showRejected: true,
      showRespondedStatus: true,
      showNewStatus: true,
      showKIV: true,
      showHired: false,
      filterLocation: '',
      filterAdditionalFormValues: {},
      filterStartDate: null,
      filterEndDate: null,
    };

    this.filterKeywords = [];

    if (this.data.length > 0) {
      this.currentCandidateId = this.data[0].candidateid;
    }

    this.filterApplied = false;
    this.updateDisplayData(true);
  }

  handleChangeAdditionalFormFieldFilter(data) {
    const { question, answer } = data;
    if (this.candidateFilters.filterAdditionalFormValues[question]) {
      if (
        this.candidateFilters.filterAdditionalFormValues[question].includes(
          answer,
        )
      ) {
        const removeOptionIndex = this.candidateFilters
          .filterAdditionalFormValues[question].indexOf(
            answer,
          );
        this.candidateFilters.filterAdditionalFormValues[question].splice(
          removeOptionIndex,
          1,
        );
        if (
          this.candidateFilters
            .filterAdditionalFormValues[question].length === 0
        ) {
          delete this.candidateFilters.filterAdditionalFormValues[question];
        }
      } else {
        this.candidateFilters.filterAdditionalFormValues[question].push(answer);
      }
    } else {
      this.candidateFilters.filterAdditionalFormValues[question] = [answer];
    }
  }

  handleChangeKeywordFilter(keywords) {
    this.filterKeywords = keywords;
  }

  handleOpenScheduleInterviewModal() {
    this.scheduleInterviewModalShow = true;
  }

  handleCloseScheduleInterviewModal() {
    this.scheduleInterviewModalShow = false;
  }

  handleChangeInterviewDate(interviewDate: Object) {
    this.scheduleInterviewDate = interviewDate;
  }

  handleChangeInterviewDuration(duration: number) {
    this.scheduleInterviewDuration = duration;
  }

  handleSchedulingInterview() {
    this.sendingScheduleInterviewEmail = true;
  }

  handleUpdateCandidateScheduleInterviewDate(data: {
    candidateId: string,
    interviewDateTime: string,
  }) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    this.data[updatedCandidateIndex]
      .scheduled_interview_date = data
        .interviewDateTime;
    this.updateDisplayData();
  }

  handleUpdateCandidateCalendlyScheduleInterviewDate(data: {
    candidateId: string,
    third_party_scheduled_type: string,
    contacted_date: string,
  }) {
    const updatedCandidateIndex = findCandidatesIndex(
      this.data,
      data.candidateId,
    );
    this.data[updatedCandidateIndex]
      .third_party_scheduled_type = data
        .third_party_scheduled_type;
    this.data[updatedCandidateIndex].contacted_date = data.contacted_date;
    this.updateDisplayData();
  }

  handleScheduleInterviewFail() {
    this.sendingScheduleInterviewEmail = false;
  }

  handleRejectingCandidate() {
    this.rejectingCandidate = true;
  }

  handleRejectCandidatesSuccess(candidateIds: Array<string>) {
    this.rejectingCandidate = false;
    this.rejectCandidateModalShow = false;
    candidateIds.forEach((cid) => {
      const updatedCandidateIndex = findCandidatesIndex(this.data, cid);
      this.data[updatedCandidateIndex].rejected = true;
      this.data[updatedCandidateIndex].viewed = true;
    });
    this.updateDisplayData();
  }

  handleUnrejectCandidatesSuccess(candidateIds) {
    this.rejectingCandidate = false;
    this.rejectCandidateModalShow = false;
    candidateIds.forEach((cid) => {
      const updatedCandidateIndex = findCandidatesIndex(this.data, cid);
      this.data[updatedCandidateIndex].rejected = false;
      this.data[updatedCandidateIndex].viewed = true;
    });
    this.updateDisplayData();
  }

  handleRejectCandidateFail() {
    this.rejectingCandidate = false;
  }

  handleOpenRejectCandidateModal() {
    this.rejectCandidateModalShow = true;
  }

  handleCloseRejectCandidateModal() {
    this.rejectCandidateModalShow = false;
  }

  handleCandidateAssessmentUpdated(data: {
    candidateId: number,
    assessmentScore: Object,
  }) {
    const candidateIndex = findCandidatesIndex(this.data, data.candidateId);
    this.data[candidateIndex].manual_assessment = data.assessmentScore;
    this.updateDisplayData(true);
  }

  handleUpdateDisplayDataForCandidateViewed(candidateIndex) {
    this.displayData[candidateIndex].viewed = true;
  }

  handleCollapseList() {
    this.collapseList = !this.collapseList;
  }

  handleAddNewCandidateNameChange(text: string) {
    this.newCandidateName = text;
  }

  handleAddNewCandidateEmailChange(text: string) {
    this.newCandidateEmail = text;
    this.validCandidateEmail = !!text.match(/\S+@\S+\.\S+/i);
  }

  handleAddNewCandidatePhoneChange(text: string) {
    this.newCandidatePhone = text;
    this.validCandidatePhone = !text || !!text.match(/^\+?\d+/i);
  }

  handleAddNewCandidate() {
    this.showAddCandidateBox = true;
    this.newCandidateEmail = '';
    this.newCandidateName = '';
    this.addingNewCandidate = false;
  }

  handleSubmittingNewCandidate() {
    this.addingNewCandidate = true;
  }

  handleSubmitNewCandidateSuccess(data: Object) {
    this.displayData = data.candidateDetails.concat(this.displayData);
    this.data = data.candidateDetails.concat(this.data);
    this.addingNewCandidate = false;
    this.showAddCandidateBox = false;
    this.newCandidateName = '';
    this.newCandidateEmail = '';
    this.newCandidatePhone = '';
  }

  handleSubmitNewCandidateFailure() {
    this.addingNewCandidate = false;
  }

  handleCancelAddNewCandidate() {
    this.showAddCandidateBox = false;
  }

  handleRemovingMultipleCandidates() {
    this.removingMultipleCandidate = true;
  }

  handleRemoveMultipleCandidatesSuccess(deletedCandidatesList: Array<Object>) {
    this.removingMultipleCandidate = false;
    const deletedCandidatesIds = deletedCandidatesList.map(
      (candidate) => candidate.candidateid, // eslint-disable-line arrow-parens
    );
    this.data = this.data.filter(
      candidate => deletedCandidatesIds
        .indexOf(candidate.candidateid) === -1, // eslint-disable-line arrow-parens
    );
    this.updateDisplayData();
  }

  handleRemoveMultipleCandidatesFailure() {
    this.removingMultipleCandidate = false;
  }

  handleChangeAllCandidateCheckbox(val: boolean) {
    this.isAllCandidateSelectedFlag = val;
    this.displayData = this.displayData.map((candidate) => {
      if (candidate.status === 'Deleted') {
        return candidate;
      }
      candidate.checked = val; // eslint-disable-line no-param-reassign
      return candidate;
    });
  }

  handleChangeCandidateCheckbox(data: Object) {
    const { candidateId, event } = data;
    const candidateIndex = findCandidatesIndex(this.data, candidateId);
    this.data[candidateIndex].checked = !this.data[candidateIndex].checked;
    if (this.lastSelectedCandidateIndex === -1) {
      this.lastSelectedCandidateIndex = candidateIndex;
      return;
    }

    if (event.shiftKey) {
      const startIndex = Math.min(
        candidateIndex,
        this.lastSelectedCandidateIndex,
      );
      const endIndex = Math.max(candidateIndex, this.lastSelectedCandidateIndex) + 1;
      const lastSelectedCandidateChecked = this
        .data[this.lastSelectedCandidateIndex].checked;
      this.data.slice(startIndex, endIndex).map((candidate) => {
        candidate.checked = lastSelectedCandidateChecked; // eslint-disable-line no-param-reassign
        return candidate;
      });
    }

    this.lastSelectedCandidateIndex = candidateIndex;
  }

  handleRemovingCandidateFromList(index: number) {
    this.removingCandidate = true;
    this.removingCandidateIndex = index;
  }

  handleRemoveCandidateFromListSuccess(candidateId: string) {
    const candidateIndexForRemovingCandidate = findCandidatesIndex(
      this.data,
      candidateId,
    );
    this.data.splice(candidateIndexForRemovingCandidate, 1);
    this.removingCandidate = false;
    this.updateDisplayData();
  }

  handleRemoveCandidateFromListFailure() {
    this.removingCandidate = false;
  }

  handleSendingReminderEmail() {
    this.sendingReminderEmail = true;
  }

  handleSendReminderEmailFailure() {
    this.sendingReminderEmail = false;
  }

  handleSendReminderEmailSuccess() {
    this.sendingReminderEmail = false;
  }

  handleUpdateCandidateToSent(canSendCandidates: Array<Object>) {
    const canSendCandidateIds = canSendCandidates.map(
      (candidate) => { candidate.candidateid; }, //eslint-disable-line
    );
    this.data.map((candidate) => {
      if (canSendCandidateIds.includes(candidate.candidateid)) {
        candidate.status = 'Sent'; // eslint-disable-line no-param-reassign
      }
      return candidate;
    });

    this.displayData = this.data;
  }

  handleApplyFilter() {
    this.filterApplied = true;
    this.tabFilterApplied = false;
    this.updateDisplayData();
  }

  handleChangeCandidatesView(val) {
    this.candidatesView = val;
  }

  handleEventTypeData(eventData) {
    this.eventTypeData = eventData;
  }

  handleChosenEventTypeUrl(url) {
    this.chosenEventTypeUrl = url;
  }

  handleSetCurrentNameAttribute(name) {
    this.currentNameAttribute = name;
  }

  handleSetDataLoading(bool) {
    this.isDataLoading = bool;
  }

  handleEditingCandidateDetails() {
    this.editingCandidateDetails = true;
  }

  handleEditCandidateDetailsFailure() {
    this.editingCandidateDetails = false;
  }

  handleEditCandidateDetailsSuccess(data: Array<Object>) {
    this.data = data;
    this.updateDisplayData();
    this.editingCandidateDetails = false;
  }

  handleWorkmapSlider(data: number) {
    this.candidateFilters.workmapScoreSliderValue = data;
  }

  handleResumeScoreSlider(data: number) {
    this.candidateFilters.resumeScoreSliderValue = data;
  }

  handleVideoScoreSlider(data: number) {
    this.candidateFilters.videoScoreSliderValue = data;
  }

  handleSetWorkmapScoreParameter(data: Array) {
    this.workmapScoreParameterList = data.map((x) => x.skill_name); //eslint-disable-line
    this.candidateFilters.workmapFilterParameters = data
      .map((value) => value.skill_name) //eslint-disable-line
      .reduce((acc, curr) => ((acc[curr] = false), acc), {}); //eslint-disable-line
  }

  handleCheckWorkmapFilterParameter(data: String) {
    this.candidateFilters
      .workmapFilterParameters[data] = !this
        .candidateFilters.workmapFilterParameters[data];
  }

  handleCheckResumeScoreFilterParameter(data: String) {
    this.candidateFilters
      .resumeFilterParameters[data] = !this.candidateFilters
        .resumeFilterParameters[data];
  }

  handleCheckVideoScoreFilterParameter(data: String) {
    this.candidateFilters
      .videoScoreFilterParameter[data] = !this.candidateFilters
        .videoScoreFilterParameter[data];
  }

  handleSetCandidateDomainScoreSuccess(data: any) {
    this.candidateDomainScore = data;
  }

  handleSetCandidateDomainScoreFailure() {
    this.candidateDomainScore = null;
  }

  handleSetUserNotAuthFromAIA(value) {
    this.userNotAuthFromAIA = value;
  }

  handleCandidateDetailsLoader() {
    this.isDataLoading = false;
  }

  handleUpdateWatchedVideoList(data) {
    this.displayData[0].watched_videos = data.watchedVideos;
  }

  handleSetRecentResponses(recentResponsesData: Array) {
    const previousData = this.recentResponses;
    this.recentResponses = [];
    previousData.push(...recentResponsesData);
    this.recentResponses = removeDuplicateObjects(previousData, obj => obj.candidateid);
  }

  handleSetFunnelCount(funnelData: Array) {
    this.funnelData = funnelData;
  }

  handleSetWeeklyReportCounts(weeklyCount: Object) {
    this.weeklyCount = weeklyCount;
  }

  handleIsFetchRecentResponsesProcessing(flag: Boolean) {
    this.isFetchRecentResponsesProcessing = flag;
  }

  handleSetRecentResponsesCount(counts: Number) {
    this.recentResponsesCount = counts;
  }

  handleSetIsInterviewCreated(flag: Boolean) {
    this.isInterviewCreated = flag;
  }

  handleSetSampleCandidatesViewed(flag: Boolean) {
    this.isSampleInterviewViewed = flag;
  }

  handlesetClickedOnDownload(value) {
    this.clickedOnDownload = value;
  }

  handleSetSearchCandidate(value) {
    this.searchCandidate = value;
  }

  handleSetSearchedCandidateText(value) {
    this.searchedCandidateText = value;
  }

  handlesetSearchedIconClicked(value) {
    this.searchedIconClicked = value;
  }

  handleSetCandidateIndexes(value) {
    this.candidateIndexes = value;
  }

  handleSetFetchingNewCandidate(value) {
    this.fetchingNewCandidates = value;
  }

  handleSetFetchingCandidateDetailsOnClick(value) {
    this.fetchingCandidateDetailsOnClick = value;
  }

  handleSetApplicationstatus(value) {
    this.applicationstatus = value;
  }

  handlesetFetchingCandidateIndexes(data) {
    this.fetchingCandidateIndexes = data.value;
    this.selectedCandIdFromRecentRes = data.candidateId;
  }

  handleSetSearchedCandidateListingText(value) {
    this.searchedCandidateListingText = value;
  }

  handleSetSearchedIconClickedOnDetails(value) {
    this.searchedIconClickedOnDetails = value;
  }

  handleSetCurrentPage(value) {
    this.currentPage = value;
  }

  handlePaceAndClarityValue(value) {
    this.candidateFilters.communicationPaceAndClarity = value;
  }

  handleArticulationValue(value) {
    this.candidateFilters.communicationArticulation = value;
  }

  handleSentimentsValue(value) {
    this.candidateFilters.communicationSentiments = value;
  }

  handleGrammarValue(value) {
    this.candidateFilters.communicationGrammarScore = value;
  }

  handleChangeSociabilitySentimentValue(value) {
    this.candidateFilters.sociabilitySentiments = value;
  }

  handleChangeSociabilityEnergyValue(value) {
    this.candidateFilters.sociabilityEnergyLevel = value;
  }

  handleChangeSociabilityEmotionValue(value) {
    this.candidateFilters.sociabilityEmotion = value;
  }

  handleChangeProfessionalismDressingyValue(value) {
    this.candidateFilters.professionalismDressing = value;
  }

  handleChangeProfessionalismGroomingValue(value) {
    this.candidateFilters.professionalismGrooming = value;
  }

  handleChangeVideoBodyLanguageValue(value) {
    this.candidateFilters.professionalismBodyLanguage = value;
  }

  handleFetchCountrySuccess(data) {
    this.countryList = data;
    if (data.length === 1) {
      const { countryid, name } = data[0];
      this.countryId = countryid;
      this.countryName = name;
    }
  }

  handleSetCountryId(data) {
    this.selectedCountriesDisplay = data;
    this.selectedCountries = data.map(str => str.split(' (')[0]);
  }

  handleRemoveSelectedCountry(obj) {
    const countryArray = this.selectedCountriesDisplay;
    const index = countryArray.indexOf(obj);
    countryArray.splice(index, 1);
    if (countryArray.length !== 0) {
      this.selectedCountries = countryArray.map(str => str.split(' (')[0]);
    } else {
      this.selectedCountries = countryArray;
    }
  }

  handleSelectFilterCandidateStatus(data) {
    this.selectCandidateStatusToFilter = data;
  }

  handleRemoveSelectedStatus(value) {
    const candidateStatusArray = this.selectCandidateStatusToFilter;
    const index = candidateStatusArray.indexOf(value);
    candidateStatusArray.splice(index, 1);
  }

  handleSelectFilterRecruiterStatus(value) {
    this.selectRecruiterStatusToFilter = value;
  }

  handleSelectFilterRelevancyScore(value) {
    this.selectRelevancyScoreToFilter = value;
  }

  handleRemoveSelectedRecruiterStatus(value) {
    const recruiterStatusArray = this.selectRecruiterStatusToFilter;
    const index = recruiterStatusArray.indexOf(value);
    recruiterStatusArray.splice(index, 1);
  }

  handleSetAdditionalIntroQuestion(data) {
    const {
      locationCounts,
      candidateStatusCounts,
      recruiterStatusCounts,
      relevancyScoreCounts: relevancyScoreNumbers,
    } = data;
    let {
      additionalQuestions: questions,
    } = data;

    this.candidateLocations = Object.keys(locationCounts)
      .map(value => `${value} (${locationCounts[value]})`);

    this.candidateStatusCount = Object.keys(candidateStatusCounts)
      .map(value => `${value} (${candidateStatusCounts[value]})`);

    this.recruiterStatusCount = Object.keys(recruiterStatusCounts)
      .map(value => `${value} (${recruiterStatusCounts[value]})`);

    this.relevancyScoreCounts = Object.keys(relevancyScoreNumbers)
      .map((value) => {
        const obj = {
          label: `${value} (${relevancyScoreNumbers[value]})`,
          key: value,
        };
        return obj;
      });
    questions = questions.filter(question => question.type === 'dropdown' || question.type === 'checkbox' || question.type === 'radio');
    questions.map(question => this.additionalQuestionsType[question.name] = question.type);   //eslint-disable-line
    questions.forEach(question => this.introQuestionDpObject[question.name] = []);   //eslint-disable-line
    this.filterIntroDropdown.dropdownValue = questions[0].name;
    this.filterIntroDropdown.dropdownOptions = questions[0].options;
    this.additionalIntroQuestion = questions;
  }

  handleSetFilterIntroDropdown(value) {
    let questionList = this.additionalIntroQuestion;
    questionList = questionList.filter(question => question.name === value);
    this.filterIntroDropdown.dropdownValue = questionList[0].name;
    this.filterIntroDropdown.dropdownOptions = questionList[0].options;
  }

  handleSetSelectedIntroQuestionsOptions(value) {
    const { dropdownValue, checkBoxValue } = value;
    this.introQuestionDpObject[dropdownValue] = checkBoxValue;
  }

  handlesetResetFilters() {
    this.filterIconClicked = false;
    if (Object.keys(this.introQuestionDpObject).length !== 0) {
      this.additionalIntroQuestion.forEach((question) => {
        this.introQuestionDpObject[question.name].splice(0, this.introQuestionDpObject[question.name].length); //eslint-disable-line
      });
    }
    this.filterIntroDropdown = {
      dropdownValue: '',
      dropdownOptions: [],
    };
    this.selectedCountries.splice(0, this.selectedCountries.length);
    this.selectedCountriesDisplay.splice(0, this.selectedCountriesDisplay.length);
    this.selectCandidateStatusToFilter.splice(0, this.selectCandidateStatusToFilter.length);
    this.selectRecruiterStatusToFilter.splice(0, this.selectRecruiterStatusToFilter.length);
    this.filterStartDate = null;
    this.filterEndDate = null;
  }

  handleSetFilterIconClicked() {
    this.filterIconClicked = true;
  }

  handleSetActivePanel(value) {
    this.activePanel = value;
  }

  handleResetCandidateAndRecruiterStatus() {
    this.selectCandidateStatusToFilter = [];
    this.selectRecruiterStatusToFilter = [];
  }

  handleRemoveSelectedIntroQuestionChoice(data) {
    const { question, choice } = data;
    const arrayDummy = this.introQuestionDpObject[question];
    const index = arrayDummy.indexOf(choice);
    arrayDummy.splice(index, 1);
  }

  handleSetSearchedCandidateNameOnDetails(value) {
    this.searchedCandidateNameOnDetails = value;
  }

  handleSetOverviewMessageObject(value) {
    this.overviewMessageObject = value;
  }

  handleSetCandidateSharedToList(data) {
    this.candidateSharedToList = data;
  }

  handleSetCandidateFiltersOpen(value) {
    this.candidateFiltersOpen = value;
  }

  handleFetchCandidateEmailInviteCount(value) {
    this.allCandidateCountForInviteCount = value;
  }

  handleSetStartCandidateBulkDownload(value) {
    console.log('coming ehre', value);
    this.startCandidateBulkDownload = value;
  }

  handleCloseAddCandidateModal() {
    this.addingNewCandidate = false;
    this.showAddCandidateBox = false;
    this.newCandidateName = '';
    this.newCandidateEmail = '';
    this.newCandidatePhone = '';
  }
}

export default alt.createStore(CandidateStore, 'CandidateStore');
