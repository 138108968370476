export default {
  cancel: 'Hủy bỏ',
  save: 'Cứu',
  back: 'Quay lại',
  edit: 'Biên tập',
  delete: 'Xóa bỏ',
  submit: 'Nộp',
  create: 'Tạo nên',
  submitting: 'đệ trình..',
  confirm: 'Xác nhận',
  share: 'Chia sẻ',
  marking: 'Đánh dấu...',
  markAllAsRead: 'đánh dấu tất cả như đã đọc',
  adding: 'Đang thêm...',
  error: 'Lỗi',
  clear: 'Xóa',
  all: 'Tất cả',
  navbarSection: {
    overviewTab: 'Tổng quan',
    interviewsTab: 'Phỏng vấn',
    insights: 'Thông tin chi tiết',
    talentpoolTab: 'Kho nguồn lực',
    applicantpoolTab: 'Nhóm ứng viên',
    usersTab: 'Người dùng',
    buyCredits: 'Mua Tín dụng',
    'Upgrade Plan': 'Nâng cấp dịch vụ',
    viewAll: 'Xem tất cả',
    notifications: 'Thông báo',
    help: 'Giúp đỡ',
    notificationInfo1: 'Bạn không có thông báo mới',
    today: 'Hôm nay',
    yesterday: 'Hôm qua',
    english: 'English',
    vietnamese: 'Vietnamese',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
  },
  overviewPage: {
    messageText1: 'Chào mừng! Chịu trách nhiệm về Trang nghề nghiệp và xây dựng sự hiện diện của bạn.',
    messageText2: 'Bạn có thể liên kết trang nghề nghiệp của bạn với trang web của bạn.',
    messageButton: 'Yêu cầu Trang nghề nghiệp của bạn',
    ratingError: 'Đánh giá sao là bắt buộc.',
    feedback: 'Nhận xét',
    feedbackQuestion: 'Hãy cho chúng tôi biết về trải nghiệm của bạn với Interviewer.AI. Bạn hài lòng đến mức nào?',
    veryUnsatisfied: 'Rất không hài lòng',
    NitherSatUsat: 'Không hài lòng cũng không hài lòng',
    verySatisfied: 'Rất hài lòng',
    feedbackTextLabel: 'Bạn có ý kiến ​​gì khác cho chúng tôi không? Vui lòng để lại chúng dưới đây:',
    surveyText: 'Chúng tôi có một cuộc khảo sát ngắn. Sẽ không mất hơn một phút',
    thankyouSurveyText: 'Chào mừng! Hãy giúp chúng tôi hiểu rõ hơn về nền tảng này. Cuộc khảo sát của chúng tôi sẽ mất ít hơn 30 giây. Chúng tôi rất mong nhận được phản hồi từ bạn!',
    whatDoYouThink: 'Hãy cho chúng tôi biết bạn nghĩ gì',
    secondsAgo: '{{seconds}} Cách đây vài giây',
    minuteAgo: '1 một phút trước đây',
    hourAgo: '1 giờ trước',
    monthAgo: '1 tháng trước',
    yearAgo: '1 năm trước',
    getStarted: 'Bắt đầu',
    weeklyOverall: 'Tổng hợp hàng tuần',
    totalInterviews: 'Tổng số cuộc phỏng vấn',
    totalApplicants: 'Tổng số ứng viên',
    totalVideoResponses: 'Tổng số phản hồi video',
    viewMoreInsights: 'Xem thêm thông tin chi tiết',
    jobInterview: 'Tin đăng tuyển',
    sourcingFunnel: 'Phễu nguồn cung ứng',
    applicantFunnel: 'Phễu ứng viên',
    totalCandidates: 'Ứng tuyển',
    createInterview: '+ Tạo cuộc phỏng vấn',
    text1: 'Tham gia Pulse of Hiring trên LinkedIn để biết thông tin chi tiết và chủ đề về thế giới công việc và công nghệ.',
    text2: 'đặt mua',
    text3: 'về gian lận danh tính',
    yetToRespond: 'Không phản hồi',
    responded: 'Đã trả lời',
    rated: 'Đánh giá nhà tuyển dụng',
    newResponses: 'Ứng viên mới',
    shortlisted: 'Tuyển dụng',
    hello: 'Xin chào',
    welcome: 'Chào mừng',
    recentResponses: 'Phản hồi gần đây',
    noRecentResponses: 'Không có phản hồi gần đây',
    applications: 'Hồ sơ ứng tuyển',
    videoResponses: 'Phản hồi',
    createFirstInterview: 'Tạo cuộc phỏng vấn đầu tiên của bạn',
    addCompanyLogo: 'Thêm logo Công ty của bạn',
    viewSampleProfile: 'Xem hồ sơ mẫu',
    addTeamMember: 'Thêm một thành viên trong nhóm',
    instruction1: 'Đừng bỏ lỡ mới nhất và cập nhật của chúng tôi',
    instruction2: 'Trải nghiệm thông báo',
  },
  interviewListing: {
    archieveInterview: 'Lưu Trữ Cuộc Phỏng Vấn',
    deleteInterview: 'Xóa cuộc phỏng vấn',
    duplicateInterview: 'phỏng vấn trùng lặp',
    editInterview: 'Chỉnh sửa cuộc phỏng vấn',
    manageApplicants: 'Quản lý ứng viên',
    archiveInterview: 'Lưu Trữ Cuộc Phỏng Vấn',
    interviewOwner: 'phỏng vấn chủ sở hữu',
    searchInterview: 'Tìm kiếm phỏng vấn',
    details: 'Mục',
    created: 'Ngày tạo',
    expiry: 'Ngày hết hạn',
    candidates: 'Ứng viên',
    applicants: 'ứng viên',
    responses: 'Phản hồi',
    analyzed: 'Phân tích',
    shortlisted: 'Tuyển dụng',
    createdBy: 'Người tạo',
    status: 'Trạng thái',
    share: 'Chia sẻ',
    filter: 'Lọc',
    newJobInterview: 'Phỏng vấn việc làm mới',
    newInterview: 'Cuộc phỏng vấn mới',
    manageCandidates: 'Quản lý ứng viên',
    assignTo: 'Giao cho',
    changeBaground: 'Thay đổi nền',
    editJobInterview: 'Chỉnh sửa phỏng vấn xin việc',
    duplicateJobInterview: 'Phỏng vấn việc làm trùng lặp',
    deleteJobInterview: 'Xóa phỏng vấn việc làm',
    archieveJobInterview: 'Lưu Trữ Phỏng Vấn Việc Làm',
    downloadQRCode: 'Tải xuống mã QR',
    results: 'Kết quả',
    jobOwner: 'Người tạo phỏng vấn',
    contributor: 'người đóng góp',
    randomiseInfo1: 'Chọn câu hỏi để ngẫu nhiên hóa',
    youHaveSelected: 'Bạn đã chọn',
    questions: 'câu hỏi',
    interviewStatus: 'Trạng thái',
    pleaseSelect: '-- Chọn --',
    filterForInterview: 'Lọc để phỏng vấn',
    expiryMessage: 'Cuộc phỏng vấn này sẽ hết hạn ngay lập tức và ứng viên sẽ không thể trả lời cuộc phỏng vấn nữa.',
    sureMessage: 'Bạn có chắc chắn để lưu trữ các cuộc phỏng vấn',
    archieving: 'lưu trữ...',
  },
  userList: {
    team: 'Đội',
    inviteNewUsers: 'Mời người dùng mới',
    emailAddresses: 'địa chỉ email',
    organizationMembers: 'thành viên tổ chức',
    addEmailAddress: 'Thêm địa chỉ email ở đây',
    addEmail: 'Nhấn Enter hoặc Tab để thêm địa chỉ email',
    cancel: 'Hủy bỏ',
    share: 'Chia sẻ',
    emails: 'email',
    sentInvitesTo: 'Đã gửi thành công lời mời đến',
    inviteError: 'Lỗi khi gửi lời mời, vui lòng thử lại sau',
    confirm: 'Xác nhận',
    adminUserMessage: 'Quản trị viên có thể quản lý người dùng và tất cả các cuộc phỏng vấn được tạo trong tổ chức',
    inOrganization: 'trong tổ chức của bạn?',
    areSureYouWantToAssignPermissionTo: 'Bạn có chắc chắn muốn gán quyền quản trị cho',
    toAdmin: 'để quản lý',
    upgrade: 'Nâng cấp',
    resendInvitation: 'Bạn có chắc chắn muốn gửi lại lời mời tới',
    fromOrganization: 'từ tổ chức của bạn?',
    resendInviteTo: 'Gửi lại lời mời đến',
    eraseAccessMessage: 'từ tổ chức của bạn? Thao tác này sẽ xóa tài khoản và quyền truy cập khỏi nền tảng của chúng tôi.',
    irreversibleMessage: 'Hành động này là không thể đảo ngược!',
    removeUserMessage: 'Bạn có chắc chắn muốn xóa không',
    remove: 'Di dời',
    noMemberFound: 'Không tìm thấy thành viên nào. Mời thành viên mới qua email tham gia tổ chức của bạn',
    joined: 'đã tham gia',
    status: 'Trạng thái',
    email: 'E-mail',
    role: 'Vai trò',
    members: 'Các thành viên',
    removeUser: 'Xóa người dùng',
    resendInvite: 'gửi lại lời mời',
    upgradeToAdmin: 'Nâng cấp lên quản trị viên',
    exceededUserAdditionMessage: 'Đã vượt quá số lượng quản trị viên trong tổ chức của bạn.',
    membersContactUsPageProps: {
      contactUsMessage: `Quản lý nhóm và mời thành viên là một tính năng mở rộng.
      Vui lòng liên hệ với chúng tôi để biết chi tiết.
      Hoặc nhấn vào nút bên dưới để chúng tôi liên hệ với bạn qua email!`,
      contactUsTitle: 'Team',
      contactUsFeature: 'Tính năng quản lý tổ chức',
    },
    searchMember: 'Tìm kiếm theo tên hoặc email',
    inviteMember: 'Mời người dùng mới',
    memberLimitExceededMessage: 'Đã vượt quá giới hạn số lượng thành viên trong tổ chức của bạn',
    incorrectEmailMessage: 'Nhập sai địa chỉ email',
    sameCompanyDomainMessage: 'Vui lòng nhập (các) địa chỉ email từ cùng một miền công ty',
    hasAlreadyInvited: 'đã được mời',
  },
  planDetails: {
    planEntitlements: 'Quyền lợi kế hoạch',
    superAdmin: 'Quản trị viên cấp cao',
    admin: 'Quản trị viên',
    user: 'Người dùng',
    contributors: 'người đóng góp',
    yourPlan: 'Kế hoạch của bạn',
  },
  newInterview: {
    createNewInterview: 'Tạo cuộc phỏng vấn mới',
    createInterview: 'Tạo nội dung tương tác',
    creatingInterview: 'Đang tạo cuộc phỏng vấn...',
    updateInterview: 'Cập nhật nội dung tương tác',
    updatingInterview: 'Đang cập nhật phỏng vấn...',
    newInterview: 'Cuộc phỏng vấn mới',
    basicInfo: 'Basic Info',
    instituteLogo: 'Logo Viện',
    addInstituteLogo: 'Thêm Logo Viện',
    instLogoRequired: 'Biểu trưng của Viện là bắt buộc',
    instituteName: 'Tên học viện',
    instituteNameRequired: 'Tên Viện là bắt buộc',
    departmentTitle: 'Chức vụ',
    departmentTitleRequired: 'Dtiêu đề khoa là bắt buộc',
    chooseFunction: 'Chọn một chức năng',
    createFunction: 'Tạo chức năng tùy chỉnh',
    functionName: 'Tên chức năng',
    interviewExpiryDate: 'Ngày hết hạn phỏng vấn',
    interviewDescription: 'Mô tả cuộc phỏng vấn',
    applicantRetakeText: 'Ứng viên có thể thực hiện lại toàn bộ cuộc phỏng vấn nhiều lần không?',
    applicantResumeText: 'Điểm sơ yếu lý lịch có cần thiết khi ứng viên nộp đơn không?',
    applicantResponseText: 'Ngôn ngữ phản hồi của ứng viên dự kiến',
    enableSocialMediaText: 'Chọn để hỏi hồ sơ truyền thông xã hội từ các ứng cử viên',
    workmapErrorMessage: '(Vui lòng chọn ít nhất một kỹ năng)',
    qualifierQuestion: 'vòng loạiCâu hỏi',
    qualifierQuestionErrorMessage: 'Câu hỏi vòng loại là bắt buộc nếu nó được bật.',
    selectQuestions: 'Chọn câu hỏi',
    selectTwoQuestions: 'Vui lòng chọn ít nhất 2 câu hỏi.',
    identityFraudToggleText: 'Có cần xác minh danh tính không?',
    selectAllQuestions: 'Chọn tất cả câu hỏi',
    updateJobInterview: 'Cập nhật phỏng vấn việc làm',
    createJobInterview: 'Tạo cuộc phỏng vấn việc làm',
    newJobInterview: 'Phỏng vấn việc làm mới',
    workmapAssessment: 'Đánh giá Workmap',
    addIntroQuestions: 'Additional Form Questions',
    addVideoQuestions: 'Thêm câu hỏi video',
    editYourQuestion: 'Chỉnh sửa câu hỏi dạng bổ sung',
    editingYourQuestion: 'Đang chỉnh sửa...',
    noIntroCategories: 'Chưa có danh mục nào được tạo...',
    interviewSettings: 'Cài đặt phỏng vấn',
    basicJobInfo: 'Thông tin công việc cơ bản',
    addLogo: 'Thêm biểu trưng',
    companyLogo: 'Logo công ty',
    addCompanyLogo: 'Thêm Logo công ty',
    logoChangeInstruction: 'Click vào logo để thay đổi hoặc thêm logo',
    logoChangeInstruction2: 'tối đa 500kb',
    companyLogoRequired: 'Logo công ty là bắt buộc',
    companyName: 'Tên công ty',
    companyNameRequired: 'Tên công ty là bắt buộc.',
    jobTitleInstruction1: 'Chức vụ',
    jobTitleInstruction2: 'Tên cuộc phỏng vấn là bắt buộc.',
    jobFunction: 'Chức năng công việc',
    chooseJobFunction: 'Chọn chức năng công việc',
    createCustomJobFunction: 'Tạo chức năng công việc tùy chỉnh',
    questions: 'câu hỏi',
    jobFunctionInstruction: {
      instruction1: 'Tên chức năng công việc*',
      instruction2: 'Tên chức năng công việc là bắt buộc.',
    },
    updatingJobInterview: 'Cập nhật phỏng vấn việc làm',
    workmapSkills: {
      instruction1: 'Chọn kỹ năng',
      instruction2: 'Đánh giá Workmap sử dụng Thang đo Likert. Thang đo Likert thường được sử dụng để đo lường thái độ, kiến ​​thức, nhận thức, giá trị và thay đổi hành vi. Thang đo kiểu Likert bao gồm một loạt các nhận định mà người trả lời có thể chọn để đánh giá câu trả lời của họ đối với các câu hỏi đánh giá (Vogt, 1999).',
      instruction3: 'Kỹ năng được yêu cầu.',
      instruction4: 'Chọn nhiều:',
      relevantSkills: 'Kỹ năng liên quan',
      instruction5: 'Danh sách các kỹ năng sẽ được kiểm tra trong Interviewer.AI Workmap Assessment. Đánh giá WorkMap là một công cụ đo lường tâm lý được thiết kế để đo lường các kỹ năng và năng lực có liên quan đến các yếu tố thành công của các công việc cụ thể.',
      instruction6: 'Chọn nhiều',
      workmapAssessmentEnabled: 'Đã bật đánh giá sơ đồ làm việc',
      instruction7: 'Thang đo Likert',

    },
    creating: 'Ngày tạo',
    create: 'Tạo nên',
    interviewStartDate: 'Ngày bắt đầu phỏng vấn',
    jobExpiry: 'Ngày hết hạn công việc',
    expirydateRequired: 'Ngày hết hạn là bắt buộc.',
    jobLocationInstruction: {
      jobLocation: 'Nơi làm việc',
      instruction1: 'Quốc gia là bắt buộc.',
      instruction2: 'Thành phố là bắt buộc.',
    },
    jobVisibility: {
      jobVisibilityInstruction: 'Khả năng hiển thị công việc',
      jobVisibilityInstruction1: 'Khả năng hiển thị công việc kiểm soát những công việc nào hiển thị trên Trang nghề nghiệp của bạn',
      jobVisibilityInstruction2: 'Công việc là',

    },
    private: 'Riêng tư',
    public: 'Công cộng',
    qrCodeInstructions: {
      qrCodeInstructions1: 'Khả năng hiển thị mã QR',
      qrCodeInstructions2: 'QR Code Visibility hiển thị mã Qr trên danh sách công việc',
      qrCodeInstructions3: 'Mã QR',
    },
    enabled: 'Đã bật',
    disabled: 'Tàn tật',
    roleType: 'Loại vai trò',
    roleTypeRequired: 'Loại vai trò là bắt buộc.',
    uniqueCandidatesLabel: 'Nhãn nhận dạng ứng viên duy nhất',
    uniqueCandidatesLabel2: 'Đây có thể là bất kỳ giá trị duy nhất nào mà bạn đã chỉ định cho ứng viên để xác định họ.',
    uniqueCandidatesLabel3: '1 - Giá trị được chỉ định sẽ hiển thị trong biểu mẫu ứng dụng dưới dạng nhãn có trường nhập liệu.',
    uniqueCandidatesLabel4: '2 - Chúng tôi khuyên bạn, Không chỉ định bất kỳ giá trị nào trong trường này nếu bạn không có id duy nhất như vậy hoặc chưa cung cấp các id này cho ứng viên vì trường này sẽ là bắt buộc đối với ứng viên.',
    candidateCoachingId: 'E.g. Số ghế / Số thí sinh / Số điểm danh',
    jobDescription: {
      jobDescription: 'Mô tả công việc',
      jobDescriptionInstruction1: 'Mô tả là bắt buộc.',
      jobDescriptionInstruction2: 'Gửi báo cáo cho ứng viên về việc hoàn thành đánh giá',
      jobDescriptionInstruction3: 'Bật tính năng này sẽ cho phép gửi báo cáo cho từng ứng viên sau khi họ hoàn thành cuộc phỏng vấn qua video này.',
    },
    moreOptions: 'Lựa chọn khác',
    resumeInstructions: {
      resumeInstructions1: 'Có yêu cầu sơ yếu lý lịch khi ứng viên nộp đơn?',
      resumeInstructions2: 'Đặt URL hồ sơ LinkedIn bắt buộc',
      resumeInstructions3: 'Ứng viên có thể thực hiện lại toàn bộ cuộc phỏng vấn nhiều lần không?',
      resumeInstructions4: 'Đặt URL hồ sơ Facebook bắt buộc',
      resumeInstructions5: 'Đặt URL hồ sơ Instagram bắt buộc',
      resumeInstructions6: 'Bắt buộc câu trả lời lý tưởng',
      resumeInstructions7: 'Chuyển Đổi Điểm Liên Quan Nội Dung',

    },
    showLinkedin: '{{option}} Không gian URL LinkedIn',
    showFacebook: '{{option}} Không gian URL Facebook',
    showInstagram: '{{option}} Không gian URL Instagram',
    videoScoreInstruction: 'Bạn có muốn đặt điểm video tối thiểu để thực hiện lại cuộc phỏng vấn không?',
    thresholdInstruction: 'Giá trị ngưỡng phải lớn hơn 20',
    thresholdInstruction1: 'Hạn chế các ứng viên tiếp tục ngoài mẫu đơn',
    thresholdInstruction2: 'Xin lưu ý rằng việc kích hoạt tùy chọn này sẽ hạn chế các ứng viên tiếp tục ngoài mẫu đơn đăng ký. Các ứng viên sẽ cần nhận được lời mời từ bạn để tiếp tục quá trình',
    overwriteInterviewCompanyName: 'Ghi đè tên công ty phỏng vấn',
    overwriteCompanyName: 'Ghi đè tên công ty',
    customiseInterviewLogo: 'Tùy chỉnh logo Phỏng vấn (JPG, JPEG, PNG)',
    redirectURL: 'chuyển hướng URL',
    redirectUrlInstructions: 'Điều này đặt URL chuyển hướng khi ứng viên kết thúc cuộc phỏng vấn video, ví dụ: Trang LinkedIn của công ty bạn. URL phải có tiền tố là giao thức http hoặc https.',
    manualRatingParameter: 'Thông số xếp hạng thủ công',
    instruction5: 'Nhấn Enter hoặc Tab để thêm tham số xếp hạng riêng',
    instruction6: 'Ngôn ngữ trả lời dự kiến ​​của ứng viên (Thử nghiệm)',
    instruction7: 'Cấu hình này đặt ngôn ngữ dự kiến ​​từ phản hồi của ứng viên và thực hiện lời nói thành văn bản tương ứng. Xin lưu ý rằng một số điểm (ví dụ: giao tiếp) sẽ bị vô hiệu hóa đối với các ngôn ngữ không phải tiếng Anh.',
    english: 'Tiếng Anh',
    mandarin: 'Quan thoại',
    polish: 'Đánh bóng',
    spanish: 'người Tây Ban Nha',
    bahasa: 'Bahasa (tiếng Indonesia)',
    french: 'Tiếng Pháp (nước Pháp)',
    german: 'Deutsch',
    country: 'Quốc gia',
    vietnamese: 'Tiếng Việt',
    city: 'Thành phố',
    cognitiveAssessment: 'Đánh giá nhận thức',
    selectTime: 'Chọn thời gian',
    categoryName: 'Bộ câu hỏi',
    categoryDescription: 'Danh mục Mô tả',
    enterCtaegoryDescription: 'Nhập mô tả danh mục',
    createCategory: 'Tạo danh mục',
    categoryNameInstruction: 'Đặt tên cho nhóm câu hỏi này',
    listMore: 'Liệt kê thêm +',
    listLess: 'Liệt kê ít hơn -',
    expired: 'Ngày hết hạn',
    copyJobLink: ' Sao chép liên kết công việc',
    required: 'Yêu cầu',
    'On-site': 'Trực tiếp', // Purposely kept key like this
    Remote: 'Làm việc từ xa', // Purposely kept key like this
    Hybrid: 'Kết hợp làm việc từ xa và trực tiếp', // Purposely kept key like this
    'Part Time': 'Bán thời gian', // Purposely kept key like this
    'Full Time': 'Toàn thời gian', // Purposely kept key like this
    Internship: 'Thực tập sinh', // Purposely kept key like this
    Contract: 'Thời vụ', // Purposely kept key like this
    Training: 'Đào tạo', // Purposely kept key like this
    'Mock Interview': 'Cuộc phỏng vấn giả', // Purposely kept key like this
  },
  introQuestions: {
    introQuestionsTitle: 'Câu hỏi mẫu bổ sung',
    addQuestions: 'Thêm câu hỏi',
    instruction1: 'Thí sinh có thể chọn nhiều phương án',
    instruction2: 'Thí sinh chỉ được chọn một phương án',
    edit: 'Biên tập',
    question: 'Câu hỏi',
    instruction3: 'Kích hoạt để chọn nhiều hơn 1 tùy chọn',
    notNow: 'Không phải bây giờ',
    addYourQuestion: 'Thêm câu hỏi của bạn',
    randomize: 'ngẫu nhiên hóa',
    selectTime: 'Chọn thời gian',
    selectCategory: 'Chọn danh mục',
    videoQuestionBank: 'Ngân hàng câu hỏi video',
    introQuestionBank: 'Ngân hàng câu hỏi mẫu bổ sung',

  },
  profileSlider: {
    logout: 'Đăng xuất',
    userProfile: 'Hồ sơ người dùng',
    companyProfile: 'Hồ sơ công ty',
    instituteProfile: 'hồ sơ viện',
    billing: 'Đơn hàng',
    integrations: 'Tích hợp',
    notificationSettings: 'Cài đặt thông báo',
    promoCode: 'Mã khuyến mãi',
    contactSupport: 'Liên hệ hỗ trợ',
    shareFeedback: 'Chia sẻ đánh giá',
    changePassword: 'Đổi mật khẩu',
    termsAndConditions: 'Điều khoản và Điều kiện',
    help: 'Giúp đỡ',
    videoTutorial: 'Video hướng dẫn',
    howToCreateAnInterview: 'Cách tạo một cuộc phỏng vấn',
    howToAddQuestionsToAnInterview: 'Cách thêm câu hỏi vào cuộc phỏng vấn',
    howToInviteCandidates: 'Cách mời ứng viên',
    howToShareCandidates: 'Cách chia sẻ ứng viên',
    aFeelOfTheDashboard: 'Cảm nhận về Bảng điều khiển',
    reCalibrateAIMatrics: 'Hiệu chỉnh lại các chỉ số AI của bạn',
    knowMoreAboutTalentPool: 'Biết thêm về Talent Pool',
    addTeamMember: 'Thêm thành viên nhóm',
  },
  userProfile: {
    back: 'Quay trở lại',
    editProfile: 'Chỉnh sửa hồ sơ',
    profileImage: 'Hình ảnh hồ sơ',
    upload: 'Tải lên',
    maxImgSize: 'Kích thước hình ảnh tối đa là 500 x 500*',
    personalInfo: 'Thông tin cá nhân',
    firstName: 'Tên đầu tiên',
    lastName: 'Họ',
    email: 'Thư điện tử',
    phoneNumber: 'Số điện thoạ',
    save: 'Lưu',
    saving: 'Đang lưu',
    placeHolderFirstName: 'Nhập tên đầu tiên',
    placeHolderLastName: 'Nhập họ',
  },
  companyProfile: {
    back: 'Quay trở lại',
    myCompany: 'Công ty của tôi',
    myInstitute: 'Viện của tôi',
    basicInformation: 'Thông tin cơ bản',
    orgIntroVideo: 'Video giới thiệu tổ chức',
    choose: 'Chọn',
    orgIntroVideoFormat: 'Tải lên video (<30 MB, .mp4)',
    remove: '(Di dời)',
    companyLogo: 'Logo của công ty',
    instituteLogo: 'Logo Viện',
    upload: 'Tải lên',
    businessInformation: 'Thông tin kinh doanh',
    companyName: 'Tên công ty',
    instituteName: 'Tên học viện',
    companyNamePlaceholder: 'Nhập tên công ty',
    yourCareerPage: 'Trang tuyển dụng của bạn',
    yourPageIs: 'Trang tuyển dụng của bạn là',
    public: 'Công cộng',
    private: 'Riêng tư',
    categories: 'Danh mục *',
    industryType: 'Vui lòng chọn loại ngành nghề.',
    description: 'Miêu tả"',
    maxCharacter: '(Tối đa {{count}} ký tự)',
    siteNamePlaceHolder: 'Nhập tên trang web',
    createNew: 'Tạo mới',
    subOrganisation: 'Các tổ chức phụ',
    urlCopyurl: 'Đã sao chép URL vào bộ nhớ tạm',
    saveProfile: 'Lưu trang cá nhân',
    saving: 'Đang lưu',
    hideEmploymentType: 'Ẩn thông tin về loại hình công việc trong biểu mẫu đăng ký ứng viên.',
    hideLocation: 'Ẩn thông tin về địa điểm làm việc trong biểu mẫu đăng ký ứng viên.',
  },
  billingPage: {
    overall: 'Tổng thể',
    byInterview: 'Bằng cách phỏng vấn',
    heading: 'Bây giờ bạn đang ở trên',
    changePlan: 'Thay đổi kế hoạch',
    particulars: 'Danh sách phỏng vấn',
    credits: 'Số lượng',
    totalCredits: 'Tổng số tín chỉ',
    to: 'ĐẾN',
    used: 'Đã sử dụng',
    balance: 'Số lượng còn lại',
    numberOfLiveInterview: 'Số cuộc phỏng vấn tích cực',
    numberOfUser: 'Số lượng người dùng',
    candidateResponse: 'Phản hồi của Ứng viên (Tín dụng)',
    billingInformation: 'Thông tin thanh toán',
    nextBillingDate: 'Ngày thanh toán tiếp theo',
    on: 'trên',
    freeCredit: 'Tín dụng miễn phí trị giá 100 đô la',
    continue: 'Tiếp tục',
    usePromoCode: 'Sử dụng mã khuyến mại của chúng tôi để được giảm giá 20% cho bất kỳ gói nào',
    copiedSuccess: 'Đã sao chép thành công',
    notApplicable: 'không áp dụng',
    hiringIsSeasonal: 'Việc tuyển dụng phù thuộc vào mùa và chúng tôi hiểu điều đó',
    hiringDesc1: 'Khám phá giá cả theo tín dụng hàng năm mới của chúng tôi. Truy cập không giới hạn vào các công việc.',
    hiringDesc2: 'Và số lượng người dùng không giới hạn khi bạn mua 100 tín dụng trở lên.',
  },
  integrationPage: {
    calendayApiKey: 'CALENDLY MÃ API',
    save: 'Lưu',
    zapierIntegration: 'ZAPIER Tích hợp',
    beta: 'Bản thử nghiệm',
    generate: 'Phát ra',
    whatZapier: 'BẠN CÓ THỂ LÀM GÌ VỚI ZAPIER?',
    placeHolderCalendly: 'Nhập Calendly API',
    placeholderGenerateZapierKey: 'Nhấp vào tạo để tạo khóa API zapier',
  },
  notificationPreference: {
    heading: 'Tóm tắt Ứng viên Tùy chọn Thông báo Email',
    daily: 'Hằng ngày',
    weekly: 'hàng tuần',
    monthly: 'hàng tháng',
    save: 'Cứu',
  },
  resetPassword: {
    heading: 'Đặt lại mật khẩu của bạn',
    enterEmailInstr: 'Nhập địa chỉ email của bạn bên dưới và chúng tôi sẽ gửi cho bạn một liên kết để đặt lại mật khẩu của bạn.',
    email: 'Email',
    sendResetLink: 'Gửi Email đặt lại mật khẩu',
    login: 'Đăng nhập',
    resetting: 'đặt lại...',
  },
  talentpool: {
    resume: 'Bản tóm tắt',
    viewResume: 'Xem sơ yếu lý lịch',
    'date applied': 'Ngày áp dụng',
    talentpoolInsights: 'Hiểu biết sắc về Bể Tài Năng',
    errorOccured: 'Đã xảy ra lỗi. Vui lòng liên hệ chúng tôi để biết thêm chi tiết.',
    reachOut: 'Chúng tôi sẽ liên lạc sớm!',
    hi: 'Xin chào!',
    contactUsMessage: `Bạn đã mệt mỏi với việc phải thực hiện nhiều cuộc phỏng vấn để truy cập vào các ứng viên của riêng mình chưa?

    Tính năng Bể tài năng (Talent Pool) sẽ giúp bạn giải quyết vấn đề đó một cách đơn giản! Truy cập tất cả các ứng viên của bạn trên một trang với các bộ lọc để thu hẹp tìm kiếm và tìm ra những ứng viên mà bạn quan tâm.
    
    Để có được quyền truy cập vào tính năng cao cấp này, vui lòng liên hệ chúng tôi để biết thêm chi tiết. Hoặc nhấp vào nút bên dưới để chúng tôi liên hệ với bạn qua email!
  `,
    contactUsTitle: 'Bể tài năng',
    demographicData: 'Dữ liệu nhân khẩu',
    applications: 'Ứng dụng',
    usage: 'Cách sử dụng',
    educationBackground: 'Nền giáo dục',
    emailPerformance: 'Hiệu suất email',
    dataWithPhoneNumber: 'Dữ liệu với số điện thoại',
    appliedToMultipleRoles: ' Áp dụng cho nhiều vai trò',
    timeToHire: 'Thời gian thuê',
    totalLiveInterviews: 'Tổng số cuộc phỏng vấn trực tiếp',
    totalUsers: 'Tổng số người dùng',
    allJobs: 'Tất cả công việc',
    contactUsToUpgrade: 'Liên hệ với chúng tôi để nâng cấp',
    contactUsFeature: 'Tính năng Nhận thức về Bể tài năng',
    interested: 'tôi quan tâm!',
    companyOverall: 'Tổng quan về công ty.',
    interviews: 'Phỏng vấn',
    applicants: 'Ứng viên',
    resumeScoring: 'Điểm số CV',
    workmapScoring: 'Điểm số bản đồ công việc',
    videoScoring: 'Phỏng vấn qua video',
    shortlisted: 'Danh sách rút gọn',
    scheduled: 'Đã lên lịch',
    rejected: 'Bị từ chối',
    kiv: 'Được giữ lại để xem xét',
    completedApplications: 'Hồ sơ hoàn thành',
    incompleteApplications: 'Hồ sơ chưa hoàn thành',
    searchCandidatePlaceholder: 'Tìm kiếm ứng viên',
    allJobTitle: 'Tất cả các chức danh công việc',
    allLocations: 'Tất cả các địa điểm',
    allStatus: 'Tất cả các trạng thái',
    allApplication: 'Đơn ứng tuyển',
    complete: 'Hoàn thành',
    inComplete: 'Chưa hoàn thành',
    name: 'Tên',
    'job title': 'Chức danh công việc',
    company: 'Công ty',
    location: 'Địa điểm',
    'interview name': 'Tên cuộc phỏng vấn',
    status: 'Trạng thái',
    application: 'Đơn xin việc',
    all: 'Tất cả',
    completed: 'Hoàn thành',
    incomplete: 'Không hoàn thành',
    videoInterview: 'Phỏng vấn qua video',
    'Action Pending': 'Hành động đang chờ xử lý',
    Shortlist: 'Tuyên dụng',
    Rejected: 'Không được chấp nhận',
    Kiv: 'Cân nhặc',
  },
  candidateDetails: {
    idealAnswer: 'Câu trả lời lý tưởng',
    idealAnsNotAvail: 'Câu trả lời lý tưởng không có sẵn',
    idVerifyTrueText: 'Xác minh ID đã được xác minh',
    idVerifyFalseText: 'Xác minh ID chưa được xác minh',
    audioDetectedTrue: 'Đã phát hiện thấy âm thanh',
    audioDetectedFalse: 'Không phát hiện thấy âm thanh',
    browserToggleTrue: 'Đã phát hiện chuyển đổi trình duyệt',
    browserToggleFalse: 'Không phát hiện thấy chuyển đổi trình duyệt',
    pastedAnsTrue: 'Đã phát hiện câu trả lời đã dán',
    pastedAnsFalse: 'Không phát hiện thấy câu trả lời đã dán nào',
    thirdPartyVoiceTrue: 'Đã phát hiện thấy giọng nói của bên thứ ba',
    thirdPartyVoiceFalse: 'Không phát hiện thấy giọng nói của bên thứ ba',
    eyeContactTrue: 'Đã phát hiện thấy giao tiếp bằng mắt',
    eyeContactFalse: 'Không phát hiện thấy giao tiếp bằng mắt',
    suspiciousObjectTrue: 'Đã phát hiện đối tượng khả nghi',
    suspiciousObjectFalse: 'Đã phát hiện đối tượng khả nghi',
    shareWith: 'Đã được chia sẻ với',
    sendReportCoaching: 'Gửi báo cáo cho người nộp đơn',
    applicantShortlisted: 'Ứng viên lọt vào danh sách rút gọn',
    shortlistApplicant: 'ứng viên trong danh sách rút gọn',
    candidateKived: 'Candidate kived',
    kivCandidate: 'ứng cử viên KIV',
    applicants: 'ứng viên',
    searchApplicants: 'tìm ứng viên',
    selectMe: 'Chọn tôi',
    selected: 'Đã chọn',
    applicantSummary: 'Tóm tắt người nộp đơn',
    all: 'Tất cả',
    candidates: 'Ứng viên',
    searchCandidates: 'Tìm kiếm ứng viên',
    scoreSettings: 'Cài đặt điểm',
    resumeScore: 'Điểm Sơ yếu lý lịch',
    workmapScore: 'Kết quả phân tích bài kiểm tra',
    aiVideoScore: 'Kết quả phân tích AI Video',
    recommended: 'pontensi tinggi',
    professionalism: 'Tính chuyên nghiệp',
    positiveAttitude: 'Thái độ tích cực',
    communication: 'Kỹ năng giao tiếp',
    sociability: 'Sự hòa đồng',
    contentRelevancy: 'ính liên quan của nội dung',
    reset: 'Cài lại',
    saving: 'Tiết kiệm...',
    save: 'Cứu',
    new: 'Đã mời',
    unlockCandidate: 'Mở khóa Ứng viên này',
    scoreSettingsInfo: 'Điều này cho phép bạn kiểm soát trọng số của Resume, WorkMap và AI Video Score. Chọn trọng số bạn muốn và nó sẽ cộng lại thành 100.',
    deleteCandidateInfo: 'Ứng viên đã xóa hồ sơ của họ, vì vậy bạn không thể truy cập thông tin của họ nữa.',
    overallScore: 'Tổng điểm',
    overall: 'Tổng thể',
    hired: 'Đã tuyển',
    hire: 'Hãy tuyển tôi',
    appliedDate: 'Ngày ứng tuyển',
    changeSchedule: 'Thay đổi lịch trình',
    scheduleInterview: 'Lên lịch phỏng vấn',
    downloadReport: 'Tải xuống báo cáo',
    shareProfile: 'Chia sẻ hồ sơ này',
    sendReport: 'Gửi báo cáo cho ứng viên',
    candidateShortlisted: 'ứng cử viên lọt vào danh sách rút gọn',
    shortlistCandidate: 'ứng cử viên danh sách rút gọn',
    keepInView: 'Cân nhặc',
    keptInView: 'Cân nhặc',
    candidateRejected: 'ứng viên bị từ chối',
    rejectCandidate: 'Từ chối ứng viên',
    rejected: 'Vật bị loại bỏ',
    reject: 'Từ chối',
    inviteActivity: 'Mời hoạt động',
    interviewScheduledOn: ' Lịch phỏng vấn vào',
    activity: 'Hoạt động',
    contactedUser: 'Người dùng đã liên hệ trên',
    noQuestionsAttempted: 'Không có câu hỏi cố gắng',
    videoTranscript: 'Bản ghi video',
    candidateSummary: 'Tóm tắt Ứng viên',
    insightsDisclaimer: 'Tuyên bố miễn trừ trách nhiệm: Vui lòng sử dụng thông tin chi tiết theo quyết định của bạn để đưa vào danh sách rút gọn các ứng viên cho các cuộc phỏng vấn hoặc thực hiện các cuộc gọi tuyển dụng.',
    skill: 'Kỹ năng',
    education: 'Bằng cấp',
    experience: 'Kinh nghiệm',
    shareShortlist: 'Chia sẻ danh sách rút gọn',
    inviteSent: 'Đã gửi lời mời',
    interviewVideo: 'Video phỏng vấn',
    location: 'Vị trí',
    email: 'E-mail',
    phone: 'Điện thoại',
    linkedIn: 'liên kết',
    includeEmail: 'Bao gồm một email',
    rejectCandidateInfo: 'Vui lòng xác nhận nếu bạn muốn từ chối (những) ứng viên sau:',
    emailSubject: 'Chủ đề email',
    dear: 'Kính thưa',
    rejectReason: 'Lý do từ chối',
    rejecting: 'từ chối',
    emailPreview: 'Xem trước email',
    selectEventType: 'Chọn loại sự kiện Calendly',
    calendlyEventCreated: 'Đảm bảo bạn đã tạo một sự kiện Calendly hoặc tạo một sự kiện tại đây',
    noCalendlyEventFound: 'Không tìm thấy sự kiện theo lịch nào',
    sender: 'Người gửi',
    editEmailSubject: 'Chỉnh sửa chủ đề email',
    checkEditEmailContent: 'Kiểm tra và chỉnh sửa Nội dung Email',
    lookForwardMeetingYou: 'Chúng tôi mong được gặp bạn sớm',
    sending: 'Gửi...',
    scheduleAndSend: ' Lên lịch và gửi',
    yourFeedback: 'Thông tin phản hồi của bạn',
    feedbackInstruction1: 'Ký tự tối đa: 5000',
    overallFeedback: 'Phản hồi tổng thể',
    hiringManager: 'Thuê quản lý',
    notes: 'ghi chú',
    status: 'Trạng thái',
    noSummary: 'Không Tóm tắt',
    videoScoreTooltip: 'Interviewer.AI Khung độc quyền của AI cung cấp xếp hạng cho lời nói, giọng nói cũng như nét mặt của ứng viên trong cuộc phỏng vấn video. Các thí sinh được cho điểm trên thang điểm một trăm cho mỗi thông số đánh giá. Những điểm này được tổng hợp thành điểm tổng thể cho cuộc phỏng vấn qua video của họ. Điểm cao hơn trên thanh sẽ chỉ ra rằng ứng viên đạt kết quả tốt hơn dựa trên xếp hạng khách quan về hành vi của họ trong cuộc phỏng vấn video so với các cuộc phỏng vấn video được thực hiện trên nền tảng cũng như các bộ dữ liệu công khai.',
    professionalismTooltip: 'Nó được đo lường từ hành vi của ứng viên (chính thức/không trang trọng) và xem xét cách ứng viên thể hiện bản thân trong video. Nó cũng đo xem giao tiếp bằng mắt được duy trì ở đâu trong quá trình phản hồi video, ghi lại nếu có bất kỳ sự cựa quậy nào. Vâng, ăn mặc lịch sự sẽ là một lợi thế.',
    positiveAttitudeTooltip: 'Thái độ, như tên cho thấy, tìm kiếm tình cảm cùng với sự kết hợp giữa năng lượng và cảm xúc. Mô hình này xem xét sự kết hợp của các số liệu từ giao tiếp và Tính xã hội (ví dụ: tự tin, khuyến khích, tích cực)',
    communicationTooltip: 'Điều này chủ yếu đo lường sự rõ ràng trong bài phát biểu của người nộp đơn. Nó cũng xem xét sự trôi chảy trong giao tiếp bằng lời nói và cách điều chỉnh lời nói. Nói rõ ràng và với tốc độ phù hợp sẽ là lợi thế ở đây. Sự thay đổi về cao độ giọng nói vào những thời điểm thích hợp trong suốt video sẽ có ích.',
    sociabilityTooltip: 'Mô hình AI này tập trung nhiều hơn vào việc ghi điểm tình cảm của ứng viên, sử dụng cách ứng viên giao tiếp và thể hiện các kỹ năng giao tiếp tích cực cũng như sự khéo léo. Nó thường tìm kiếm những từ tình cảm tích cực và mức năng lượng của người nộp đơn',
    contentRelevancyTooltip: 'Mức liên quan của câu trả lời được hiển thị dưới dạng phần trăm để chỉ mức độ mà nó giải quyết được câu hỏi. Phần trăm cao cho thấy mức độ liên quan cao hơn, trong khi phần trăm thấp gợi ý một câu trả lời có thể ít liên quan hơn. Thông tin này giúp bạn đánh giá tính chính xác và phù hợp của câu trả lời được cung cấp.',
  },
  scheduleInterview: {
    email: 'Xem trước email',
    selectDateTimeDuration: '1. Chọn ngày giờ và thời lượng',
    scheduleDescription: 'Bạn có thể lên lịch mời phỏng vấn với ứng viên tại đây. Chúng tôi sẽ gửi email cho bạn và ứng viên của bạn.',
    dateAndTime: 'Ngày và giờ',
    duration: 'Khoảng thời gian',
    sender: '2. Người gửi',
    editEmailSubject: '3. Chỉnh sửa tiêu đề email',
    checkEditEmailContent: '4. Kiểm tra và chỉnh sửa Nội dung Email',
  },
  candidateListing: {
    removeSelectedApplicants: 'Xóa ứng viên đã chọn',
    removeSelectedApplicantInfo: 'Bạn có chắc chắn muốn xóa những ứng viên này không?',
    editApplicantDetail: 'Edit applicant details',
    unlockApplicant: 'Mở khóa Ứng viên này',
    kivApplicant: 'Ứng viên KIV',
    deleteApplicant: 'Xóa ứng viên',
    copyApplicantInterviewLink: 'Sao chép liên kết phỏng vấn ứng viên',
    editApplicant: 'Chỉnh sửa ứng viên',
    applicantDateBetween: 'Ngày nộp đơn giữa',
    filterApplicants: 'Lọc ứng viên',
    selectToDeleteCoaching: 'Chọn ứng viên để xóa',
    deleteSelectedCandidatesCoaching: 'Xóa ứng viên đã chọn',
    downloadApplicantDetails: 'Tải thông tin ứng viên',
    selectToRejectCoaching: 'Chọn ứng viên để từ chối',
    rejectSelectedCoaching: 'Từ chối ứng viên đã chọn',
    selectToShareCoaching: 'Chọn ứng viên để chia sẻ',
    shareSelectedCoaching: 'Chia sẻ ứng viên được chọn',
    addApplicant: 'Thêm người nộp đơn mới',
    addApplicantToInterview: 'Thêm ứng viên sau vào cuộc phỏng vấn',
    enterCandidateName: 'Nhập tên ứng viên',
    enterApplicantName: 'Nhập tên người nộp đơn',
    enterCandidateEmail: 'Nhập địa chỉ email của ứng viên',
    enterApplicanteEmail: 'Nhập địa chỉ email của ứng viên',
    enterCandidateNumber: 'Nhập số điện thoại liên hệ của ứng viên',
    enterApplicantNumber: 'Nhập số điện thoại liên hệ của ứng viên',
    applicantsTo: 'Ứng viên vào',
    notifyApplicants: 'thông báo cho ứng viên',
    sendMessagesToMultipleApplicants: 'Gửi tin nhắn cho nhiều ứng viên từ đây.',
    searchApplicants: 'tìm ứng viên',
    newApplicant: 'Người nộp đơn mới',
    totalApplicants: 'Tổng số ứng viên',
    totalCandidates: 'Ứng tuyển',
    yetToRespond: 'Không phản hồi',
    editCandidate: 'Chỉnh sửa Ứng viên',
    copyInterviewLink: 'Sao chép liên kết phỏng vấn ứng viên',
    deleteCandidate: 'Xóa ứng viên',
    editCandidateDetail: 'Sửa thông tin ứng viên',
    name: 'Tên',
    email: 'E-mail',
    phoneNumber: 'Số điện thoại',
    selectToDelete: 'Chọn ứng viên để xóa',
    deleteSelectedCandidates: 'Xóa ứng viên đã chọn',
    selectToReject: 'Chọn ứng viên để từ chối',
    rejectSelected: 'Từ chối ứng viên được chọn',
    selectToShare: 'Chọn ứng viên để chia sẻ',
    shareSelected: 'Chia sẻ ứng viên được chọn',
    newCandidate: 'Ứng viên mới',
    videoScore: 'Điểm video',
    sendVideoInterviewLink: 'Gửi liên kết phỏng vấn video',
    select: 'Lựa chọn',
    selected: 'đã chọn',
    candidatesTo: 'ứng viên',
    candidate: 'Ứng cử viên',
    applicant: 'người nộp đơn',
    notify: 'Thông báo',
    importByCSV: 'Nhập bằng CSV',
    addCandidate: 'Thêm ứng viên mới',
    addCandidateToInterview: 'Thêm ứng viên sau vào cuộc phỏng vấn',
    enterContactNumber: 'Nhập số điện thoại liên hệ của ứng viên',
    removeSelectedCandidates: 'Xóa ứng viên đã chọn',
    removeSelectedCandidateInfo: 'Bạn có chắc chắn muốn xóa những ứng viên này không?',
    noCandidatesInfo: 'Chưa có ứng cử viên nào. Gửi phỏng vấn cho các ứng viên ngay bây giờ!',
    notifyCandidate: 'thông báo cho ứng viên',
    sendMessagesToMultipleCandidates: 'Gửi tin nhắn cho nhiều ứng viên từ đây.',
    sendEmailsTo: 'Gửi email đến',
    emailPreviewInfo: 'Dưới đây là bản xem trước của email sẽ được gửi cho ứng viên. Bạn có thể thay đổi khu vực màu vàng để phù hợp hơn với tin nhắn của mình.',
    emailPreviewInfo2: 'Vui lòng nhấp vào liên kết bên dưới và ghi lại câu trả lời của bạn',
    recordResponse: 'Ghi lại câu trả lời của bạn',
    lookForwardForResponse: 'Chúng tôi mong chờ phản hồi của bạn, chúc may mắn!',
    filterCandidates: 'Sàng lọc ứng viên',
    downloadCandidateDetails: 'Tải báo cáo',
    importCandidates: 'Ứng viên nhập khẩu',
    fileName: 'Tên tệp',
    bulkImportCandidateInfo1: 'Nội dung CSV phải bao gồm email, tên và điện thoại (tùy chọn). Có thể theo thứ tự khác nhau',
    actions: 'Hành động',
    chooseFile: 'Chọn tập tin',
    addCandidates: 'Thêm ứng viên',
    candidateImportInfo1: 'Không có dữ liệu ứng viên nào được nhập',
    duplicateEntry: 'Mục nhập trùng lặp cho email',
    duplicateEntryPhone: 'Mục nhập trùng lặp cho điện thoại',
    duplicateEntryInfo: 'Mục nhập trùng lặp cho',
    inCSVsheet: 'trong trang tính CSV',
    alreadyExist: 'đã có trong danh sách ứng viên',
    enterValidName: 'Vui lòng nhập tên hợp lệ',
    enterValidEmail: 'Vui lòng nhập email hợp lệ',
    enterValidPhone: 'Vui lòng nhập số điện thoại hợp lệ',
    enterValid: 'Vui lòng nhập hợp lệ',
    invalidEmail: 'Nội dung CSV chứa email không hợp lệ',
    invalidPhone: 'Nội dung CSV chứa số điện thoại không hợp lệ',
    isMandatoryForAllCandidates: 'là bắt buộc đối với tất cả các ứng cử viên',
    csvContailsInvalid: 'Nội dung CSV chứa không hợp lệ',
    csvImportInfo1: 'Tệp không phải là tệp CSV hoặc excel',
    csvImport2: 'Tệp CSV/Excel phải có tên hoặc email',
    columnInHeader: 'cột trong tiêu đề',
    reactivateCandidateLink: 'Réactiver le lien candidat',
    reactivateApplicantLink: 'Réactiver le lien du candidat',
  },
  insights: {
    candidateLocation: 'Vị trí ứng viên',
    genderDiversity: 'Giới tính',
    jobTitle: 'Chức danh công việc',
    colleges: 'Các trường đại học',
    educationGraph: 'Trình độ học vấn Education Graph',
    sourceOfApplication: 'Nguồn đăng ký ứng tuyển',
    resetMap: 'Đặt lại bản đồ',
    emailSentOpened: 'Email đã gửi, mở và phản hồi',
    showingLatest: 'Hiển thị 5 cuộc phỏng vấn mới nhất',
    emptyGenderInfo: 'Chưa có thông tin giới tính',
    insights: 'cái nhìn sâu sắc',
    interested: 'Tôi quan tâm!',
    contactUs: 'Liên hệ chúng tôi',
    noEmailEventInfo: 'No email events info yet',
    noSourceInfo: 'Chưa có thông tin nguồn',
    reactivateCandidateLink: 'Réactiver le lien candidat',
    reactivateApplicantLink: 'Réactiver le lien du candidat',
  },
  footer: {
    copyRightText: 'Bản quyền © {{currentYear}} Interviewer.AI. Tất cả các quyền được bảo lưu.',
  },
  shareProfile: {
    shareThisProfile: 'Chia sẻ hồ sơ này',
    shareCandidates: 'Chia sẻ Ứng viên',
    enterEmailSubject: 'Nhập chủ đề email',
    selectedCandidate: 'Ứng viên được chọn',
    listName: 'Danh sách tên',
    shareWith: 'Chia sẽ với',
    info1: 'Nhấn Enter để thêm các email riêng biệt',
    secureAccess: 'Truy cập an toàn',
    optional: 'không bắt buộc',
    enterAccessCodeHere: 'Nhập mã truy cập tại đây',
    shareLink: 'Chia sẻ đường link',
    copyLink: 'Sao chép đường dẫn',
    note: 'Ghi chú',
    enterNote: 'Nhập ghi chú',
    undoKIV: 'Hoàn tác KIV',
    shortlist: 'Tuyên dụng',
    undoShortlist: 'Hoàn tác danh sách rút gọn',
    undoReject: 'Hoàn tác từ chối',
    feedbackInfo1: 'Chia sẻ quan điểm của bạn về ứng cử viên này, ví dụ: sắp xếp một cuộc họp với tôi vào ngày...',
  },
  candidateFilter: {
    candidateDateBetween: 'Ngày ứng viên giữa',
    selectStartDate: 'Chọn ngày bắt đầu',
    selectEndDate: 'Chọn ngày kết thúc',
    kived: 'KIVed',
    filterKeyworkds: 'Lọc từ khóa trong Caption/Resume',
    resetFilter: 'Đặt lại bộ lọc',
    applyFilter: 'Áp dụng bộ lọc',
    keywordFilterInfo: 'Nhấn Enter hoặc Tab để thêm từ khóa riêng',
  },
  insightsPayWall: {
    coachingHeading: 'Giải phóng sức mạnh của lựa chọn theo hướng dữ liệu',
    coachingMessage: 'Mở khóa tiềm năng tiềm ẩn trong quy trình sàng lọc ứng viên của bạn khi chúng tôi mang đến cho bạn một kho tàng dữ liệu vô giá và các phân tích thông minh. Hòa mình vào một thế giới nơi mọi buổi sàng lọc phỏng vấn đều trở thành cơ hội để hiểu sâu hơn về ứng viên, giúp bạn đưa ra quyết định lựa chọn hàng đầu mỗi lần.',
    heading: 'Kích hoạt Sức mạnh của Tuyển dụng Dựa trên Dữ liệu',
    message: 'Mở khóa tiềm năng ẩn của quy trình lọc ứng viên của bạn khi chúng tôi mang đến cho bạn một kho tàng dữ liệu vô giá và phân tích thông minh. Lạc vào một thế giới mà mỗi cuộc phỏng vấn trở thành cơ hội để có cái nhìn sâu sắc về ứng viên, giúp bạn đưa ra quyết định tuyển dụng hàng đầu mỗi lần.',
    interested: 'Saya tertarik.',
  },
  candidateFilters: {
    filterCandidates: 'Lọc ứng viên',
    applciationRecievedBetween: 'Đơn đăng ký nhận được giữa',
    startDate: 'Ngày bắt đầu',
    endDate: 'Ngày cuối',
    location: 'Vị trí',
    selectLocation: 'Chọn địa điểm',
    candidateStatus: 'Tình trạng ứng viên',
    selectStatus: 'Chọn trạng thái',
    recruiterStatus: 'Tình trạng nhà tuyển dụng',
    introQuestions: 'Câu hỏi giới thiệu',
    selectOptions: 'Lựa chọn các phương án',
    reset: 'Cài lại',
    applyFilter: 'Áp dụng bộ lọc',
  },
  newPlansPage: {
    expiryMessage: 'Tín dụng tài khoản Interviewer.AI của bạn đã hết hạn. Mua tín dụng để tiếp tục sử dụng các cuộc phỏng vấn video của chúng tôi.',
    selectCredits: 'chọn tín dụng',
    egText: 'Ví dụ: 500 tín chỉ = 500 cuộc phỏng vấn video',
    pricingMainText: 'Xem các tùy chọn tính toán để tự động hóa việc tuyển dụng của bạn',
    pricingMainSecondaryText: 'Mở khóa một loạt các tính năng và lợi ích được thiết kế cho bạn.',
    assessmentScoreIncHeading: 'Điểm đánh giá ứng viên bao gồm những gì?',
    resumeScore: 'Điểm tiếp tục',
    assessmentScorePrimHeading: 'Interviewer.AI cung cấp giải pháp sàng lọc tuyển dụng hàng đầu, toàn diện nhất bao gồm ba thành phần cơ bản',
    resumeSectionPara1: 'Điểm tiếp tục sử dụng thuật toán Nhận dạng ký tự quang học (OCR) và xử lý ngôn ngữ tự nhiên sâu (NLP) để chính xác văn bản từ sơ yếu lý lịch và khớp nó với Mô tả công việc.',
    resumeSectionPara2: 'Điểm AI thay thế tìm kiếm từ khóa truyền thống (mà các nhà tuyển dụng chủ yếu sử dụng) và thay thế nó bằng thuật toán học sâu giúp hiểu ngữ cảnh đằng sau mỗi từ trong sơ yếu lý lịch bằng cách sử dụng nhận dạng thực thể được đặt tên.',
    resumeSectionPara3: 'Nó mang lại cho bạn điểm số AI tuyệt đối, dựa trên cơ sở dữ liệu gồm 120 triệu hồ sơ.',
    workmapScore: 'sơ đồ công việc Điểm',
    workmapSectionPara1: 'WorkMap đánh giá các kỹ năng và năng lực của ứng viên dựa trên các vai trò cụ thể mà họ ứng tuyển.',
    workmapSectionPara2: 'Năng lực cần thiết để thực hiện một số nhiệm vụ công việc nhất định sẽ khác nhau giữa các ngành, chức năng hoặc vai trò khác nhau. Với WorkMap, ứng viên được đánh giá dựa trên những năng lực quan trọng sẽ giúp họ thành công ở vai trò của mình trong tương lai.',
    workmapSectionPara3: 'Điểm số dựa trên thang đo Likert Mức độ đồng ý 5 điểm bao gồm 25-35 câu hỏi trong đó mỗi kỹ năng được đánh giá bằng cách sử dụng 5-7 câu',
    aiVideoScore: 'Điểm video AI',
    aiVideoSectionPara1: 'Khung độc quyền của Interviewer.Al cung cấp xếp hạng cho lời nói, giọng nói cũng như nét mặt của ứng viên trong cuộc phỏng vấn video.',
    aiVideoSectionPara2: 'Thí sinh được cho điểm theo thang điểm một trăm cho mỗi thông số đánh giá. Những điểm số này được tổng hợp thành điểm tổng thể cho cuộc phỏng vấn qua video của họ.',
    aiVideoSectionPara3: 'Điểm cao hơn trên thanh sẽ cho thấy rằng ứng viên có kết quả tốt hơn dựa trên xếp hạng khách quan về hành vi của họ trong cuộc phỏng vấn video dựa trên bộ dữ liệu công khai và trên nền tảng.',
    quickPreviewText: 'Đây là bản xem trước nhanh',
    all: 'Kế hoạch',
    plans: 'Đặc trưng',
    yourAiCredits: 'Tín dụng phỏng vấn AI của bạn',
    myCurrentPlan: 'Kế hoạch hiện tại của tôi',
    active: 'Tích cực',
    expDate: 'Ngày hết hạn',
    addYourCred: 'Thêm tín dụng của bạn',
    addNewCred: 'Mua tín dụng',
    videoInt: 'phỏng vấn video',
    availCred: 'Tín dụng có sẵn',
    addCred: 'Thêm tín dụng',
    newCred: 'Tín dụng mới',
    validOn: 'Có hiệu lực thông qua',
    price: 'Giá',
    congText: 'Chúc mừng!',
    unlockPlan: 'Bạn đã mở khóa {{activeCurrentPlan}} Kế hoạch.',
    unlockEnterprisePlan: 'Bạn đủ điều kiện cho chúng tôi {{activeCurrentPlan}} Kế hoạch.',
    schedDemo: 'Lên lịch demo',
    buyNow: 'Mua ngay',
    unlocked: 'Đã mở khóa',
    starterPlanHeaderText: 'Đối với tuyển dụng hạn chế',
    growthPlanHeaderText: 'Để tuyển dụng nhanh',
    premiumPlanHeaderText: 'Dành cho các nhóm mở rộng quy mô',
    benifits: 'Những lợi ích',
    userLicense: 'Giấy phép người dùng',
    admin: 'Quản trị viên',
    recuiter: 'Nhà tuyển dụng',
    accountAlloc: 'Phân bổ tài khoản',
    noOfJobsInterviews: 'Số lượng cuộc phỏng vấn việc làm',
    noOfAIInterAssessments: 'Số lượng đánh giá phỏng vấn AI',
    generativeAiAssist: 'Hỗ trợ AI sáng tạo',
    autoGenJobDes: 'Mô tả công việc được tạo tự động',
    candSummay: 'Tóm tắt ứng viên',
    genAiQuesGeneration: 'Tạo câu hỏi Gen AI',
    socialFeatures: 'Tính năng xã hội',
    careerPage: 'Trang tuyển dụng',
    linkWithJobPost: 'Liên kết với bài đăng việc làm trên LinkedIn',
    stackRankAlgo: 'Thuật toán xếp hạng ngăn xếp',
    resumeScoring: 'Tiếp tục chấm điểm',
    workmapAssess: 'Đánh giá sơ đồ công việc',
    scoreSettings: 'Cài đặt điểm',
    contentReleScore: 'Điểm liên quan đến nội dung',
    contentReleScoreBreakdown: 'Phân tích điểm liên quan của nội dung',
    support: 'Ủng hộ',
    emailSupport: 'Hỗ trợ email',
    dedicatedAccountManger: 'Người quản lý tài khoản chuyên dụng',
    expired: 'Hết hạn',
    infoIconText: 'Tín dụng = một video đánh giá',
  },
  expiryPlanModal: {
    firstMesssage: 'Tín dụng miễn phí của bạn sẽ hết hạn sau',
    firstNonTrialMesssage: 'Tín dụng của bạn sẽ hết hạn sau',
    day: 'ngày.',
    days: 'ngày.',
    secondMessage: 'Mua tín dụng ngay trước khi hết hạn và được giảm giá 20% cho bất kỳ gói nào.',
    thirdMessage: 'Sử dụng mã',
    fourthMessage: 'lúc thanh toán để tận dụng ưu đãi này',
  },
  creditsAlertText: {
    message: 'Bạn đã vượt quá số tín dụng được phân bổ trong tài khoản của mình. Để truy cập các phản hồi và đánh giá ứng viên mới, vui lòng mua tín dụng ngay lập tức.',
  },
};
