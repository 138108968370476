import React, { useState } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import { Grid, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Auth from '../auth/Auth';
import clientConfig from '../config/config';
import styles from '../css/ContactUs.module.scss';
import ClipLoadingComponent from '../components/ClipLoadingComponent';

const config = clientConfig.default;

const ContactUs = ({
  contactUsMessage,
  contactUsTitle,
  contactUsFeature,
  buttonText,
  redirectLink,
}) => {
  const { t } = useTranslation();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    setTimeout(() => {
      setButtonClicked(true);
      setLoading(false);
    }, 1000);
    const email = Auth.getProfileInfo('email');
    const content = `[Paid feature request] ${email} is interested to know more ${contactUsFeature}. source: ${window.location.href}`;
    const body = {
      text: content,
      channel: '#alert-user-signup',
    };
    await fetch(config.SLACK_NOTIFICATION, {
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (redirectLink) {
      // eslint-disable-next-line no-unused-expressions
      window.open(redirectLink, '_blank') || window.location.replace(redirectLink);
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <Grid fluid>
          <h3>{t(contactUsTitle)}</h3>
        </Grid>
      </div>
      <Grid>
        <div className={styles.content}>
          <b>{t('talentpool.hi')}</b>
          <br />
          <br />
          <p className={styles.message}>{t(contactUsMessage)}</p>
          <br />
          <Button
            className={`btn btn-primary-custom ${styles.mainButton}`}
            onClick={handleClick}
            bsClass="btn-primary-custom"
            disabled={buttonClicked || loading}
          >
            {/*  */}
            {buttonClicked
              ? <span>{t('talentpool.reachOut')}</span>
              : <span>{t(buttonText)}</span>
            }
            {
              loading && <ClipLoadingComponent inverse />
            }
          </Button>
        </div>
      </Grid>
    </div>
  );
};

ContactUs.propTypes = {
  contactUsMessage: PropTypes.string.isRequired,
  contactUsTitle: PropTypes.string.isRequired,
  contactUsFeature: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  redirectLink: PropTypes.string,
};

ContactUs.defaultProps = {
  buttonText: 'talentpool.interested',
  redirectLink: '',
};

export default ContactUs;
