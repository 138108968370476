import React from 'react';
import PropTypes from 'prop-types';
import TagsInput from 'react-tagsinput';
import { FormControl, FormGroup } from 'react-bootstrap';

import 'react-tagsinput/react-tagsinput.css';
import '../css/MultivalueInput.css';

const MultivalueInput = ({
  values,
  onValuesChange,
  placeholder,
  error,
  isPressEnterNoteShow,
}) => {
  /* eslint-disable react/no-unused-prop-types */
  const textarea = ({
    addTag,
    onChange,
    value,
    ...props
  }) => (
    <FormGroup validationState={error ? 'error' : null}>
      <FormControl
        componentClass="input"
        type="text"
        value={value}
        onChange={onChange}
        className="form-control-with-value"
        {...props}
      />
      <FormControl.Feedback />
      {error && <span className="errorMessage">{error}</span>}
    </FormGroup>
  );

  return (
    <TagsInput
      value={values}
      inputProps={{ placeholder }}
      onChange={onValuesChange}
      renderInput={textarea}
      className={isPressEnterNoteShow ? 'multiEmails' : ''}
    />
  );
};

MultivalueInput.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  onValuesChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  addTag: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.arrayOf(PropTypes.string),
  isPressEnterNoteShow: PropTypes.bool,
};

MultivalueInput.defaultProps = {
  placeholder: '',
  addTag: () => {},
  onChange: () => {},
  value: [],
  error: false,
  isPressEnterNoteShow: false,
};

export default MultivalueInput;
