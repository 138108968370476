import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import alt from '../alt';
import SettingActions from '../actions/SettingActions';
import { DEFAULT_API_DATA } from '../utils/constants';

class SettingStore {
  constructor() {
    this.isLoading = true;
    this.profileLoaded = false;
    this.savingProfile = false;
    this.companyUpdated = false;
    this.inputChanged = false;
    this.profileSaved = false;
    this.errorMessage = '';
    this.successMessage = '';
    this.profileData = {};
    this.companyLogoValid = true;
    this.companyLogo = null;
    this.userProfileImageValid = true;
    this.userProfileImage = null;
    this.profileInfoSaveCompleted = true;
    // this.calendlyInt = { api_key: '', api_secret: '', service: 'calendly' };
    this.apiData = DEFAULT_API_DATA;
    this.companyDescription = EditorState.createEmpty();
    this.previousCareerSiteName = '';
    this.orgIntroductionVideoUrl = null;
    this.companyIntroductionVideoObj = null;
    this.companyDummyIntroductionVideoObj = null;
    this.orgSettings = [];

    this.bindListeners({
      handleGetProfileBasicInfoSuccess: SettingActions.GET_PROFILE_BASIC_INFO_SUCCESS,
      handleGetProfileBasicInfoFail: SettingActions.GET_PROFILE_BASIC_INFO_FAIL,
      handleInputChange: SettingActions.INPUT_CHANGE,
      handleIsSavingProfile: SettingActions.IS_SAVING_PROFILE,
      handleSaveProfileSuccess: SettingActions.SAVE_PROFILE_SUCCESS,
      handleSaveProfileFail: SettingActions.SAVE_PROFILE_FAIL,
      handleUpdateCompanySuccess: SettingActions.UPDATE_COMPANY_SUCCESS,
      handleUpdateCompanyFail: SettingActions.UPDATE_COMPANY_FAIL,
      handleCompanyLogoInvalid: SettingActions.COMPANY_LOGO_INVALID,
      handleCompanyLogoIsValid: SettingActions.COMPANY_LOGO_IS_VALID,
      handleInputApiChange: SettingActions.INPUT_API_CHANGE,
      handleGetIntegrationInfo: SettingActions.GET_INTEGRATION_INFO,
      handleChangeCompanyDescription: SettingActions.CHANGE_COMPANY_DESCRIPTION,
      handleUserProfileImageValid: SettingActions.USER_PROFILE_IMAGE_VALID,
      handleUserProfileImageInvalid: SettingActions.USER_PROFILE_IMAGE_INVALID,
      handleProfileInfoSaveCompleted: SettingActions.PROFILE_INFO_SAVE_COMPLETED,
      handleSetOrgIntroductionVideoUrl: SettingActions.SET_ORG_INTRODUCTION_VIDEO_URL,
      handleSetCompanyIntroductionVideoObj: SettingActions.SET_COMPANY_INTRODUCTION_VIDEO_OBJ,
      handleSetCompanyDummyIntroductionVideoObj: SettingActions
        .SET_COMPANY_DUMMY_INTRODUCTION_VIDEO_OBJ,
      handleFetchOrganisationSettings: SettingActions.FETCH_ORG_SETTINGS_SUCCESS,
    });
  }

  handleChangeCompanyDescription(state) {
    this.inputChanged = true;
    this.companyDescription = state;
  }

  handleGetProfileBasicInfoSuccess(profileData) {
    this.profileData = profileData;
    if (this.profileData && this.profileData.industry_name) {
      this.profileData.industry = {
        [this.profileData.industry_name]: this.profileData.industry_name,
      };
    }
    this.isLoading = false;
    this.profileLoaded = true;
    this.companyLogoValid = true;
    this.companyLogo = null;
    this.inputChanged = false;
    this.previousCareerSiteName = this.profileData.career_site_company_name;

    const blocksFromHtml = htmlToDraft(profileData.description || '');
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    this.companyDescription = EditorState.createWithContent(contentState);
  }

  handleGetProfileBasicInfoFail() {
    this.isLoading = false;
    this.profileLoaded = false;
  }

  handleInputChange(profileData) {
    this.profileData[profileData.name] = profileData.value;
    if (profileData.name === 'companyName' && !this.previousCareerSiteName) {
      this.profileData['career_site_company_name'] = profileData.value.replace(/[^a-zA-Z0-9-]/g, '-'); //eslint-disable-line
    }
    this.inputChanged = true;
  }

  handleInputApiChange(formHtmlData) {
    this.apiData[formHtmlData.id][formHtmlData.name] = formHtmlData.value;
    this.inputChanged = true;
  }

  handleIsSavingProfile() {
    this.savingProfile = true;
  }

  handleSaveProfileSuccess(msg) {
    this.profileSaved = true;
    this.successMessage = msg;
    this.savingProfile = false;
    this.inputChanged = false;
  }

  handleSaveProfileFail(msg) {
    this.profileSaved = false;
    this.errorMessage = msg;
    this.savingProfile = false;
    this.inputChanged = false;
  }

  handleUpdateCompanySuccess() {
    this.companyUpdated = true;
  }

  handleUpdateCompanyFail() {
    this.companyUpdated = false;
  }

  handleCompanyLogoInvalid() {
    this.companyLogoValid = false;
    this.companyLogo = null;
    this.profileData.companyLogoPreviewUrl = null;
  }

  handleCompanyLogoIsValid(file) {
    this.companyLogoValid = true;
    this.companyLogo = file;
    this.profileData.companyLogoPreviewUrl = URL.createObjectURL(file);
  }

  handleGetIntegrationInfo(apiData) {
    this.apiData = apiData;
  }

  handleUserProfileImageInvalid() {
    this.userProfileImageValid = false;
    this.userProfileImage = null;
    this.profileData.userProfileImage = null;
  }

  handleUserProfileImageValid(file) {
    this.userProfileImageValid = true;
    this.userProfileImage = file;
    this.profileData.userProfileImage = URL.createObjectURL(file);
  }

  handleProfileInfoSaveCompleted(data) {
    this.profileInfoSaveCompleted = data;
  }

  handleSetOrgIntroductionVideoUrl(url) {
    this.orgIntroductionVideoUrl = url;
  }

  handleSetCompanyIntroductionVideoObj(urlObj) {
    this.companyIntroductionVideoObj = urlObj;
  }

  handleSetCompanyDummyIntroductionVideoObj(urlObj) {
    this.companyDummyIntroductionVideoObj = urlObj;
  }

  handleFetchOrganisationSettings(data) {
    this.orgSettings = data;
  }
}

export default alt.createStore(SettingStore, 'SettingStore');
