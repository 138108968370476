import React, { useEffect, useState } from 'react';
import BrandImg from '../../assets/Brand.svg';
import ClipLoadingComponent from '../../components/ClipLoadingComponent';
import { getUrlParams } from '../../utils/utils';

import styles from '../../css/CheckInvitation.module.scss';

const CheckInvitation = () => {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const redirectToSignUp = (redirect) => {
    window.setTimeout(() => {
      window.location = redirect;
    }, 5000);
  };

  const checkMemberInvitation = async () => {
    setIsLoading(true);
    const params = getUrlParams(window.location.search);
    if (!params.invite_token) {
      setError('Invitation token error encountered, please request for another invite');
      console.error('Invitation token not found');
      return;
    }
    const req = await fetch(`/signUpApi/member/${params.invite_token}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const res = await req.json();
    if (req.status === 500) {
      console.error(req.statusText);
      setError('An unexpected error has occured. Please contact the administrator');
      setIsLoading(false);
      return;
    }

    if (!res.success) {
      setError(res.message);
      setIsLoading(false);
      return;
    }

    // redirect to login instead
    if (res.userAlreadyExisted) {
      const redirect = '/logout';
      setLink(redirect);
      setMessage(`Invitation accepted! You have joined a new team.
      You will be redirected to sign in in 5 seconds or you may just click the`);
      redirectToSignUp(redirect);
      setIsLoading(false);
    } else {
      // valid token and redirect to sign up
      const redirect = `/signup?invite_token=${params.invite_token}&companyName=${res.companyName}&referredEmail=${res.email}&companyLogo=${res.companyLogo}`;
      setLink(redirect);
      setMessage(`Invitation accepted! Please sign up now to join your team. 
      You will be redirected to sign up in 5 seconds or you may just click the`);
      redirectToSignUp(redirect);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkMemberInvitation();
  }, []);

  return (
    <div className={styles.container}>
      <a href="https://interviewer.ai">
        <img src={BrandImg} alt="Interviewer.AI brand logo" className="login-screen__brand-img" />
      </a>
      <div className={styles.message}>
        {
          isLoading && (
            <React.Fragment>
              Please wait while we&apos;re checking your invitation
              <ClipLoadingComponent />
            </React.Fragment>
          )
        }
        {error && <p className={styles.error}>{error}</p>}
        {message && (
          <p>
            {message}
            {' '}
            <a href={link}>link</a>
          </p>
        )}
      </div>

    </div>
  );
};

export default CheckInvitation;
