import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getPackageName, isObjectEmpty } from '../utils/utils';
import styles from '../css/ExpiryActionButton.module.scss';
import LoadingComponent from './LoadingComponent';

const ExpiryActionButton = (props) => {
  const { subscription, accountBalance, appSumoUser } = props;
  const { t } = useTranslation();

  const [subscriptionButtonText, setSubscriptionButtonText] = useState('');
  const [isLockedAccount, setIsLockedAccount] = useState(false);

  const handleSetSubscription = () => {
    const subscriptionText = t(`navbarSection.${getPackageName(subscription)}`);
    if (appSumoUser) {
      setSubscriptionButtonText(subscriptionText);
    } else {
      setSubscriptionButtonText(`Balance: ${accountBalance || '-'}`);
    }
  };

  useEffect(() => {
    const subscriptionText = t(`navbarSection.${getPackageName(subscription)}`);
    if (appSumoUser) {
      setSubscriptionButtonText(subscriptionText);
    } else {
      setSubscriptionButtonText(`Balance: ${accountBalance || '-'}`);
    }
  }, [accountBalance, appSumoUser]);

  useEffect(() => {
    if (!isObjectEmpty(subscription)) {
      const { isLocked } = subscription;
      handleSetSubscription();
      setIsLockedAccount(isLocked);
    }
  }, [subscription]);

  const handleMouseEnter = () => {
    setSubscriptionButtonText((t('navbarSection.Upgrade Plan')));
  };


  const handleRedirectToUpgrade = () => {
    const { history } = props;
    history.push('/settings/billing/plan');
  };

  if (!subscription) {
    return (
      <div className={styles.planExpiryContainer}>
        {/* <RingLoader
          color="#fd6144"
          className={`
        margin: 20px auto;
      `}
          size={20}
        /> */}
        <LoadingComponent />
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.planExpiryContainer}>
        <button
          type="button"
          className={isLockedAccount ? styles.lockedButton : styles.subscriptionLabel}
          onMouseEnter={() => { handleMouseEnter(); }}
          onMouseLeave={() => { handleSetSubscription(); }}
          onClick={() => { handleRedirectToUpgrade(); }}
        >
          {
            subscriptionButtonText
          }
        </button>
      </div>
    </React.Fragment>
  );
};

ExpiryActionButton.propTypes = {
  subscription: PropTypes.object.isRequired, // eslint-disable-line
  accountBalance: PropTypes.string.isRequired,
  appSumoUser: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(ExpiryActionButton);
