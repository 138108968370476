import React, { useEffect, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Auth from '../auth/Auth';
import OrganizationStore from '../stores/OrganizationStore';
import OrganizationActions from '../actions/OrganizationActions';
import LoadingComponent from './LoadingComponent';


const PrivateRoute = ({
  component: Component, componentProps, isProtectedRoute = false, history, ...rest
}) => {
  const [subscription, setSubscription] = useState(OrganizationStore.getState().subscription);
  const [hasSubscription, setHasSubscription] = useState(
    OrganizationStore.getState().hasSubscription,
  );
  const [paymentPending, setPaymentPending] = useState(     //eslint-disable-line
    OrganizationStore.getState().paymentPending,
  );
  const [hasOrganizationData, setHasOrganizationData] = useState(
    OrganizationStore.getState().hasOrganization,
  );
  const [subscriptionFetched, setSubscriptionFetched] = useState(
    OrganizationStore.getState().subscriptionFetched,
  );

  const onChange = (storeData) => {
    setSubscription(storeData.subscription);
    setHasSubscription(storeData.hasSubscription);
    setHasOrganizationData(storeData.hasOrganization);
    setSubscriptionFetched(storeData.subscriptionFetched);
  };

  useEffect(() => { // eslint-disable-line
    if (!Auth.isAuthenticated()) {
      history.replace(`/login?requestedPage=${document.location.href}`);
    } else {
      // fetch subscription and organization
      OrganizationStore.listen(onChange);
      if (!hasSubscription && !subscriptionFetched) {
        OrganizationActions.fetchSubscription();
      }
      // settimeout for handling dispatch error
      setTimeout(() => {
        OrganizationActions.creditDetails();
      }, 0);
      if (!hasOrganizationData) {
        OrganizationActions.fetchOrganizationData();
      }

      return () => {
        OrganizationStore.unlisten(onChange);
      };
    }
  }, []);

  useEffect(() => {
    if (isProtectedRoute) {
      return;
    }
    if (!hasSubscription && subscriptionFetched) {
      OrganizationActions.fetchSubscription();
      history.push('/settings/billing/plan'); // eslint-disable-line
    }
    if (paymentPending && subscriptionFetched) {
      OrganizationActions.fetchSubscription();
      history.push('/settings/suspended');
    }
  }, [subscription, hasSubscription, subscriptionFetched, isProtectedRoute, paymentPending]);

  if ((!hasSubscription || !hasOrganizationData) && !isProtectedRoute) {
    return <LoadingComponent />;
  }

  return ( // eslint-disable-line
    <Route
      {...rest}
      render={props => <Component {...componentProps} {...props} />}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  componentProps: PropTypes.object, // eslint-disable-line
  isProtectedRoute: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

PrivateRoute.defaultProps = {
  isProtectedRoute: false,
  componentProps: {},
};

export default withRouter(PrivateRoute);
