import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import alt from '../alt';
import Auth from '../auth/Auth';
import 'react-toastify/dist/ReactToastify.min.css';
import i18n from '../locales/i18n';
import { successToast, alertToast } from '../components/Toast';
import clientConfig from '../config/config';

const config = clientConfig.default;
class OrganizationActions {
  constructor() {
    this.fetchTeamMembersStart = () => true;
    this.fetchTeamMembersSuccess = data => data;
    this.fetchTeamMembersFailed = () => true;
    this.fetchLimitSuccess = data => data;
    this.deleteMemberStart = () => true;
    this.deleteMemberSuccess = email => email;
    this.deleteMemberFailed = () => true;
    this.UpdateMemberRoleStart = () => true;
    this.UpdateMemberRoleFailed = () => true;
    this.UpdateMemberRoleSuccess = (email, role) => ({ email, role });
    this.resendInviteStart = () => true;
    this.resendInviteSuccess = () => true;
    this.resendInviteFailed = () => true;
    this.fetchOrganizationStart = () => true;
    this.fetchOrganizationSuccess = data => data;
    this.fetchOrganizationFailed = () => true;
    this.setUserRole = role => role;
    this.fetchSubscriptionStart = () => true;
    this.fetchSubscriptionSuccess = data => data;
    this.fetchSubscriptionFailed = err => err;
    this.setPlanSelectedOnWebsite = plan => plan;
    this.setPlanTypeSelectedOnWebsite = planType => planType;
    this.setUpgradeModalOpen = () => true;
    this.setUpgradeModalClose = () => true;
    this.setOnBoardingModal = value => value;
    this.setOrganizationDetails = data => data;
    this.setOverviewActionButtonsVisitedStatus = data => data;
    this.setCreateOrganisation = value => value;
    this.setRedirectionToOverview = value => value;
    this.changeCompanyname = value => value;
    this.setShowNotificationOnOverview = value => value;
    this.setNotifications = value => value;
    this.setIsUserNotificationsLoading = value => value;
    this.setMarkingAllAsRead = value => value;
    this.setUserLanguage = lang => lang;
    // this.setInsightAccess = flag => flag;
    this.setHasUserGivenFeedback = value => value;
    this.changeBillingPlanCreditsValue = value => value;
    // this.creatingNewOrganizationStart = () => true;
    this.toggleHideEmploymentType = e => e;
    this.toggleHideLocation = e => e;
    this.setCreditsAlertModalOpen = value => value;
    this.setCreditsDetailsLoading = value => value;
    this.setCreditsDetails = data => data;
    this.setLangDefaultModal = value => value;
    this.updateUserGeoLocation = data => data;
  }

  async changeBillingPlan(e) {
    this.changeBillingPlanCreditsValue(e.target.value);
  }

  async fetchNotifications(boolvalue) {
    try {
      const req = await fetch('/api/notifications', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });
      await this.getUserRole();
      const res = await req.json();
      this.setNotifications(res);
      if (boolvalue) {
        this.setMarkingAllAsRead(false);
      }
      this.setIsUserNotificationsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }

  async markAllNotificationsAsRead(userid) {  //eslint-disable-line
    try {
      this.setMarkingAllAsRead(true);
      const request = await fetch('/api/notifications/bulkupdate/markallread', {   //eslint-disable-line
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: userid,
        }),
        method: 'PATCH',
      });
      this.fetchNotifications(true);
    } catch (e) {    //eslint-disable-line
      console.log(e);
    }
  }

  async fetchSubscription() {
    this.fetchSubscriptionStart();
    try {
      const req = await fetch('/api/subscription', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        this.fetchSubscriptionSuccess(res.data);
      } else if (req.status === 401) {
        Auth.signOut();
      }
    } catch (e) {
      console.error(e);
      this.fetchSubscriptionFailed(e.message);
    }
  }

  async fetchTeamMembers() {
    this.fetchTeamMembersStart();
    try {
      const req = await fetch('/api/organization/members', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        this.fetchTeamMembersSuccess(res.members);
        this.fetchLimitSuccess(res.limit);
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
      this.fetchTeamMembersFailed();
    }
    return true;
  }

  async fetchOrganizationData() {
    this.fetchOrganizationStart();
    try {
      const req = await fetch('/api/organization/organization', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        this.fetchOrganizationSuccess(res.organization);
        this.setHasUserGivenFeedback(res.userGivenFeedback);
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
      this.fetchOrganizationFailed();
    }
    return true;
  }

  async deleteTeamMember(userObject) {
    this.deleteMemberStart();
    const { email, userId } = userObject;
    try {
      const req = await fetch('/api/organization/member', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          userId,
        }),
        method: 'DELETE',
      });

      if (req.status >= 200 && req.status < 300) {
        this.deleteMemberSuccess(email);
        successToast('Member removed');
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
      alertToast('Failed to remove member. Please try again later.');
      this.deleteMemberFailed();
    }
    return true;
  }

  async updateMemberRole(userObject, role) {
    this.UpdateMemberRoleStart();
    const { email, userId } = userObject;
    try {
      const req = await fetch('/api/organization/member-role', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          userId,
          role,
        }),
        method: 'PUT',
      });

      const resp = await req.json();
      if (req.status >= 200 && req.status < 300) {
        this.UpdateMemberRoleSuccess(email, role);
        successToast("Updated member's role");
      } else {
        throw new Error(resp.message);
      }
    } catch (e) {
      console.error(e);
      alertToast(`Failed to update member's role. ${e.message}. Please try again later.`);
      this.UpdateMemberRoleFailed();
    }
    return true;
  }

  async resendMemberInvite(userObject) {
    this.resendInviteStart();
    const { email } = userObject;
    try {
      const req = await fetch('/api/organization/members-invite', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
        method: 'PUT',
      });

      if (req.status >= 200 && req.status < 300) {
        this.resendInviteSuccess();
        successToast('Invitation resent to member');
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
      alertToast('Failed to resend email invite to member. Please try again later.');
      this.resendInviteFailed();
    }
    return true;
  }

  async createOrganization(data) {
    this.setCreateOrganisation(true);
    try {
      const req = await fetch('/api/organization/superadmin/neworganisation', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(
          data,
        ),
      });
      if (req.status >= 200 && req.status < 300) {
        successToast('Organization created successfully');
        this.setCreateOrganisation(false);
        window.location.reload();
      } else {
        alertToast('Failed to create the organization.');
      }
    } catch (e) {
      console.error(e);
      alertToast('Failed to create the organization.');
    } finally {
      this.setCreateOrganisation(false);
    }
  }

  async switchOrganisation(data, userid) {   //eslint-disable-line
    try {
      const req = await fetch(`/api/organization/superadmin/change-organization/${userid}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
        body: JSON.stringify(
          data,
        ),
      });
      if (req.status === 200) {
        Auth.refreshUserSession();
        window.location.reload();
        // this.setRedirectionToOverview(true);
        successToast('Organisation switched successfully');
      } else {
        alertToast('Failed to switch the organisation.');
      }
    } catch (e) {
      console.error(e);
      alertToast('Failed to switch the organisation.');
    }
  }

  // Class components will benefit from this.setUserRole.
  // Functional components will require return value to set it via useEffect on
  // initialisation else the state will be stale and be defaulted to member
  async getUserRole() {
    try {
      const req = await fetch('/api/organization/role', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      this.setUserRole(res.role);
      return res.role;
    } catch (e) {
      console.error(e);
      alertToast('Failed to get user\'s role.');
      this.setUserRole('user');
      return 'user';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async updateFirstRedirectionToCheckout(userData) {
    const { email, isUsed } = userData;
    try {
      const req = await fetch('/api/organization/updateAutoRedirection', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          isUsed,
        }),
        method: 'PUT',
      });

      if (req.status >= 200 && req.status < 300) {
        console.error('Successful redirection');
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }

  async getUserLanguage() {
    const request = await fetch('/api/organization/userLanguage', {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
    });
    const response = await request.json();
    if (response.success) {
      i18n.changeLanguage(response.language);
      this.setUserLanguage(response.language);
    }
  }

  async patchUserLanguage(lang) {      //eslint-disable-line
    const request = await fetch(`/api/organization/userLanguage/${lang}`, {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
    const response = await request.json();
    if (response.success) {
      i18n.changeLanguage(lang);
      this.setUserLanguage(lang);
    }
  }

  async isSuperAdmin() {      //eslint-disable-line
    try {
      const request = await fetch('/api/organization/superAdmin', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });
      const response = await request.json();
      return response.isSuperAdmin;
    } catch (e) {
      console.log(e);
      return 'superadmin';
    }
  }

  async updateToggleEmploymentTypeVisiblity(value) {
    const request = await fetch(`/api/organization/update/toggle_employment_type_visibility/${value}`, {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
    const response = await request.json();
    if (response.success) {
      this.toggleHideEmploymentType(value);
    }
  }

  async updateToggleJobLocationVisiblity(value) {
    const request = await fetch(`/api/organization/update/toggle_job_location_visibility/${value}`, {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
    });
    const response = await request.json();
    if (response.success) {
      this.toggleHideLocation(value);
    }
  }

  // this.toggleHideEmploymentType = e => e;
  // this.toggleHideLocation = e => e;

  // async checkInsightsAccess() {
  //   try {
  //     const req = await fetch('api/insight/check-insights-access', {
  //       headers: {
  //         Authorization: `Bearer ${Auth.getIdToken()}`,
  //       },
  //     });
  //     if (req.status === 403) {
  //       return false;
  //     }
  //     const res = await req.json();

  //     const {
  //       success,
  //     } = res;
  //     this.setInsightAccess(success);
  //   } catch (e) {
  //     this.setInsightAccess(false);
  //     console.error(e);
  //   }
  //   return true;
  // }

  async creditDetails() {
    this.setCreditsDetailsLoading(true);
    try {
      const req = await fetch('/api/subscription/limits/utilisation', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      if (req.status >= 200 && req.status <= 300) {
        const res = await req.json();
        this.setCreditsDetails(res.data);
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setTimeout(() => {
        this.setCreditsDetailsLoading(false);
      }, 1000);
    }
  }

  async geoLocation() {
    try {
      const req = await fetch(`${config.GEO_LOCATION_API.url}?apiKey=${config.GEO_LOCATION_API.apiKey}&fields=country_code2,country_name,country_code3`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const res = await req.json();
      const {
        country_code2: countryCode,
        country_name: countryName,
      } = res;
      let adjustedCountryCode = countryCode;

      // for vietnam we use vi for translating langugage
      if (countryCode === 'VN') {
        adjustedCountryCode = 'VI';
      }

      const userGeoLocation = {
        countryCode: adjustedCountryCode,
        countryName,
      };
      this.updateUserGeoLocation(userGeoLocation);
    } catch (e) {
      console.error(e);
    }
  }
}

export default alt.createActions(OrganizationActions);
