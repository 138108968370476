import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  NavDropdown,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
  Glyphicon,
} from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
// import { LinkContainer } from 'react-router-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import '../../css/NavigationBar/NavigationBar.scss';
import classnames from 'classnames';
import { ReactComponent as MenuIcon } from '../../assets/Menu.svg';
import { ReactComponent as Interviews } from '../../assets/Interviews.svg';
import { ReactComponent as Insights } from '../../assets/Insights.svg';
import { ReactComponent as Talentpool } from '../../assets/TalentPool.svg';
import { ReactComponent as Team } from '../../assets/Teams.svg';
import { ReactComponent as BellIcon } from '../../assets/BellIcon.svg';
import { ReactComponent as UserIcon } from '../../assets/UserIcon.svg';
import { ReactComponent as HamburgerIcon } from '../../assets/NewHamburgerIcon.svg';
import { ReactComponent as DownIcon } from '../../assets/ArrowDown.svg';
import Notification from '../../assets/Notification.png';
import englishFlag from '../../assets/englishFlag.png';
import vietnamFlag from '../../assets/vietnamFlag.png';
import frenchFlag from '../../assets/frenchFlag.png';
import germanyFlag from '../../assets/German_flag.png';
import spainFlag from '../../assets/spanishFlag.png';
import UpgradePlanModal from '../UpgradePlanModal';
import OnboardingModal from '../OnboardingModal';
import NegativeAlertModal from '../NegativeAlertModal';
import {
  getYYYYMMDD,
  isCoachingUser,
} from '../../utils/utils';

import { ReactComponent as IntervieweraiIcon } from '../../assets/InterviewerAIBrand.svg';
import OrganizationStore from '../../stores/OrganizationStore';
import Auth from '../../auth/Auth';
import OrganizationActions from '../../actions/OrganizationActions';
import ProfileSidebar from './ProfileSlider/ProfileSlider';
import BackDrop from '../BackDrop/BackDrop';
import ClipLoadingComponent from '../ClipLoadingComponent';
import SettingStore from '../../stores/SettingStore';
import CandidateActions from '../../actions/CandidateActions';
import { ReactComponent as HelpIcon } from '../../assets/HelpIcon.svg';
import InfoTooltip from '../InfoTooltip';
import clientConfig from '../../config/config';
// import { getPackageName, isObjectEmpty } from '../../utils/utils';
import ExpiryActionButton from '../ExpiryActionButton';
import NavigationSlider from './NavigationSlider/NavigationSlider';
import { profile } from 'winston';  //eslint-disable-line

const config = clientConfig.default;

// import SettingStore from '../../stores/SettingStore';


// const hamburgerToggle = () => {
//   const menu = document.querySelector('.mobile-menu');
//   const menuLinks = document.querySelector('.nav-menu');
//   menu.classList.toggle('is-active');
//   if (document.getElementsByClassName('activeNav').length === 0) {
//     menuLinks.style.display = 'grid';
//   } else {
//     menuLinks.style.display = 'none';
//   }
//   menuLinks.classList.toggle('active');
//   menuLinks.classList.toggle('activeNav');
// };

const NavigationBar = (props) => {
  const organizationStoreData = OrganizationStore.getState();
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isNavigationDrawerOpen, setIsNavigationDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [isLoadingNotifs, setIsLoadingNotifs] = useState(true);
  const [unreadNotifsCount, setUnreadNotifsCount] = useState(0);
  const [noNotifs, setNoNotifs] = useState(false);
  const [companyLogoUrl, setCompanyLogoUrl] = useState('');
  const { firstName, lastName, userId } = SettingStore.getState().profileData;
  const [subscription, setSubscription] = useState({});
  const [userProfileImage, setUserProfileImage] = useState(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);
  // const [subscriptionButtonText, setSubscriptionButtonText] = useState()
  // const [isLockedAccount, setIsLockedAccount] = useState(false);
  const [activeNavItem, setActiveNavItem] = useState('overview');
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [listOfOrganisations, setListOfOrganisations] = useState([]);
  const [userIdOfSuperAdmin, setUserIdOfSuperAdmin] = useState(null);
  const [currentOrganisationId, setCurrentOrganisationId] = useState(null);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showLanguages, setShowLanguages] = useState(false);
  const [userGeoLocation, setUserGeoLocation] = useState({});
  const [subscriptionLimits] = useState(
    OrganizationStore.getState().subscriptionLimits,
  );
  const [openTrialPopup] = useState(() => {  //eslint-disable-line
    const localStorageVal = localStorage.getItem('popup');
    return localStorageVal !== null
      ? localStorageVal
      : false;
  });
  const [openCreditsAlertPopup] = useState(() => {  //eslint-disable-line
    const localStorageVal = localStorage.getItem('creditsAlertPopup');
    return localStorageVal !== null
      ? localStorageVal
      : false;
  });
  const [creditsBalance, setCreditsBalance] = useState(null);
  const [isTrailAccount] = useState(subscriptionLimits.id
    === config.TRIAL_PLAN_ID);
  const [accountExpiryDate] = useState(OrganizationStore.getState().subscription.expiryDate);
  const expirationDate = accountExpiryDate && new Date(accountExpiryDate);
  const currentDate = new Date();
  const timeDifference = expirationDate && (expirationDate - currentDate);
  const daysDifference = timeDifference && Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  const popUpCondition = Boolean(!openTrialPopup)
    && daysDifference > 0
    && daysDifference <= 7
    && isTrailAccount;
  const { history } = props;
  const { t } = useTranslation();
  const isUserCoaching = isCoachingUser();
  const languageDropdownRef = useRef(null);
  const notificationRef = useRef(null);
  const [showDefaultLangPopup, setShowDefaultLangPopup] = useState(false);
  const [appSumoUser, setAppSumoUser] = useState(true);


  const openProfileDrawerHandler = () => {
    setIsProfileDrawerOpen(!isProfileDrawerOpen);
  };

  const openNavigationDrawerHandler = () => {
    setIsNavigationDrawerOpen(!isNavigationDrawerOpen);
  };

  const onChange = (storeData) => {
    const {
      subscription,  //eslint-disable-line
      // isLocked,
      isSuperadmin,
      superadminOrgs,
      superAdminuserId,
      companyLogoUrl: companyLogo,
      userNotifications,
      isUsersNotificationsLoading,
      userUnreadNotifications,
      noUserNotifications,
      userLanguage,
      creditsBalance: balance,
      defaultLangModal,
      userGeoLocation: location,
    } = storeData;
    setUserGeoLocation(location);
    setShowDefaultLangPopup(defaultLangModal);
    setCreditsBalance(balance);
    setIsLoadingNotifs(isUsersNotificationsLoading);
    setNotifications(userNotifications);
    setUnreadNotifsCount(userUnreadNotifications);
    setNoNotifs(noUserNotifications);
    setCompanyLogoUrl(companyLogo);
    setIsUserSuperAdmin(isSuperadmin);
    setListOfOrganisations(superadminOrgs);
    setSubscription(subscription);
    setUserIdOfSuperAdmin(superAdminuserId);
    setSelectedLanguage(userLanguage);
  };

  const onProfileStateChange = (profileStateData) => {
    const { profileData } = profileStateData;
    const { user_logo_url: userLogoUrl, orgId, isAppsumo } = profileData;
    setCurrentOrganisationId(orgId);
    setUserProfileImage(userLogoUrl);
    setAppSumoUser(isAppsumo);
  };

  const handleGlobalClick = () => {
    if (languageDropdownRef.current) {
      setShowLanguages(true);
    } else {
      setShowLanguages(false);
    }
    if (notificationRef.current) {
      setOpenNotifications(true);
    } else {
      setOpenNotifications(false);
    }
    languageDropdownRef.current = null;
    notificationRef.current = null;
  };

  useEffect(() => {
    const { pathname } = history.location;
    async function checkLoginStatus() {
      const loginCount = await Auth.loginCounts();
      const hasShownModal = localStorage.getItem('hasShownOnboardingModal');
      if (loginCount === 1 && !hasShownModal) {
        localStorage.setItem('hasShownOnboardingModal', 'true');
        setTimeout(() => {
          OrganizationActions.setOnBoardingModal.defer(true);
        }, 6000);
      }
    }

    checkLoginStatus();
    setActiveNavItem(pathname);
    OrganizationActions.fetchNotifications();
    OrganizationActions.getUserLanguage();
    OrganizationActions.geoLocation();
    OrganizationActions.creditDetails();
    OrganizationStore.listen(onChange);
    SettingStore.listen(onProfileStateChange);
    window.addEventListener('click', handleGlobalClick);
    return () => {
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  const handleLanguageChange = async (lang) => {
    setSelectedLanguage(lang);
    moment.locale(lang);
    await OrganizationActions.patchUserLanguage(lang);
    await CandidateActions.getOverviewMessage(lang);
  };

  useEffect(() => {
    const { countryCode } = userGeoLocation;
    if (!countryCode) return;
    const supportedLocation = config.SUPPORTED_NON_ENGLISH_COUNTRY_CODES
      .includes(countryCode.toLowerCase());
    const storedUserId = localStorage.getItem('languagePopup');
    if (userId && supportedLocation && storedUserId !== userId) {
      handleLanguageChange(countryCode.toLowerCase());
      OrganizationActions.setLangDefaultModal.defer(true);
    }
  }, [userGeoLocation, userId]);

  useEffect(() => {
    if (popUpCondition) {
      OrganizationActions.setUpgradeModalOpen.defer();
    }
  }, [subscription]);

  useEffect(() => {
    if (creditsBalance !== null) {
      if (+creditsBalance < 0 && Boolean(!openCreditsAlertPopup) && !appSumoUser) {
        OrganizationActions.setCreditsAlertModalOpen.defer(true);
      }
    }
  }, [creditsBalance, appSumoUser]);

  useEffect(() => {
    const currentCompanyName = listOfOrganisations.find(org => org.organizationid === currentOrganisationId);  //eslint-disable-line
    if (currentCompanyName) {
      setSelectedOrganisation(currentCompanyName.company_name);
    }
  }, [listOfOrganisations, currentOrganisationId]);

  const {
    redirectionToOverview,
  } = organizationStoreData;
  if (redirectionToOverview) {
    history.push('/overview');
  }

  const handleSelectedOrganisation = (org) => {
    setSelectedOrganisation(org.company_name);
    const dataObject = {};
    dataObject.email = org.email;
    dataObject.orgId = org.organizationid;
    dataObject.userId = org.userid;
    dataObject.companyName = org.company_name;
    dataObject.companyLogoUrl = org.company_logo_url;
    OrganizationActions.switchOrganisation(dataObject, userIdOfSuperAdmin);
  };
  const thumbnailGenerator = (companyLogoUrl, firstName, lastName) => { // eslint-disable-line
    return (
      <div className="profileButton">
        {/* Dropdown */}
        {
          isUserSuperAdmin && (
            <ButtonToolbar style={{ marginLeft: '.5rem', zIndex: '5' }}>
              <DropdownButton
                title={selectedOrganisation}
                id="dropdown-size-medium"
                style={{ border: 'none', padding: '1rem 0rem' }}
              >
                {
                  listOfOrganisations.length !== 0 && (
                    listOfOrganisations.map(org => (
                      <MenuItem
                        eventKey={org}
                        onSelect={() => { handleSelectedOrganisation(org); }}
                      >
                        {org.company_name}
                      </MenuItem>
                    ))
                  )
                }
              </DropdownButton>
            </ButtonToolbar>
          )
        }
        <button
          type="button"
          onClick={openProfileDrawerHandler}
        >
          <div
            className="clientCompanyLogo"
            style={{
              backgroundImage: `url(${companyLogoUrl})`,
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width: '70%',
              height: '40px',
              borderRadius: '600px',
            }}
          />
          {
            userProfileImage ? (
              <img
                src={userProfileImage}
                alt={firstName}
                className="navProfileImage"
              />
            ) : (
              <div className="navInitials">
                <span>
                  {firstName ? firstName.charAt(0).toUpperCase() : ''}
                  {lastName ? lastName.charAt(0).toUpperCase() : ''}
                </span>
              </div>
            )
          }

        </button>
      </div>
    );
  };

  const defaultToEnglish = () => {
    if (userId) {
      localStorage.setItem('languagePopup', userId);
    }
    handleLanguageChange('en-US');
    OrganizationActions.setLangDefaultModal(false);
  };


  const updateNotifReadStatus = async (notifId) => {
    try {
      await fetch(`/api/notifications/${notifId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'PATCH',
      });
    } catch (e) {
      console.log(e);
    }
  };

  const notificationIconClick = () => {
    const { pathname } = history.location;
    if (pathname === '/notifications') {
      setOpenNotifications(false);
      return;
    }
    setOpenNotifications(!openNotifications);
  };

  const notificationDate = (value) => {
    const notiDate = new Date(value);
    if (notiDate.getFullYear() === currentDate.getFullYear()
      && notiDate.getMonth() === currentDate.getMonth()
      && notiDate.getDay() === currentDate.getDay()
      && notiDate.getDate() === currentDate.getDate()) {
      return t('navbarSection.today');
    } else if (notiDate.getFullYear() === currentDate.getFullYear()    //eslint-disable-line
      && notiDate.getMonth() === currentDate.getMonth()
      && notiDate.getDay() + 1 === currentDate.getDay()
      && notiDate.getDate() + 1 === currentDate.getDate()) {
      return t('navbarSection.yesterday');
    }
    return moment(
      getYYYYMMDD(value),
    ).format(
      'll',
    );
  };

  const closeDefaultLangPopup = () => {
    if (userId) {
      localStorage.setItem('languagePopup', userId);
    }
    OrganizationActions.setLangDefaultModal(false);
  };

  const notificationLogo = (userLogo, username) => {
    if (userLogo) {
      return <img src={userLogo} className="notificationImg" alt="userLogo" />;
    }
    if (username) {
      const words = username.split(' ');
      const userFirstName = words[0];
      const userLastName = words[words.length - 1];
      return (
        <div className="navInitials">
          <span>
            {userFirstName ? userFirstName.charAt(0).toUpperCase() : ''}
            {userLastName ? userLastName.charAt(0).toUpperCase() : ''}
          </span>
        </div>
      );
    }
    return (
      <div className="userNotificationImg">
        <UserIcon height="24" width="24" />
      </div>
    );
  };

  const renderFlag = (selectedlang) => {  //eslint-disable-line
    return (
      <>
        {
          selectedlang === 'en-US' && (
            <img src={englishFlag} className="FlagImg" alt="englishFlag" />
          )
        }
        {
          selectedlang === 'vi' && (
            <img src={vietnamFlag} className="FlagImg" alt="VietnameseFlag" />
          )
        }
        {
          selectedlang === 'de' && (
            <img src={germanyFlag} className="FlagImg" alt="GermanyFlag" />
          )
        }
        {
          selectedlang === 'fr' && (
            <img src={frenchFlag} className="FlagImg" alt="frenchFlag" />
          )
        }
        {
          selectedlang === 'es-ES' && (
            <img src={spainFlag} className="FlagImg" alt="spanishFlag" />
          )
        }
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="desktopNavigation">
        <ProfileSidebar show={isProfileDrawerOpen} profileHandler={openProfileDrawerHandler} />
        {
          isProfileDrawerOpen ? <BackDrop profileHandler={openProfileDrawerHandler} /> : null
        }

        <nav className="nav-container">
          <a
            href="/overview"
            className="navbarBrandIconContainer"
          >
            {/* <a className="navbarBrandIconContainer"> */}
            <IntervieweraiIcon width="40" height="40" />
            {/* </a> */}
          </a>


          {/* <div> */}
          <Link
            to="/overview"
            className={`desktopNavLink ${activeNavItem === '/overview' && 'activeNav'}`}
            type="button"
            onClick={() => {
              setActiveNavItem('/overview');
            }}
          // href="/overview"
          >
            <MenuIcon width="18" height="18" fill="#7A91AB" />
            <span>{t('navbarSection.overviewTab')}</span>

          </Link>
          {/* </div> */}

          <Link
            to="/interviews"
            className={
              classnames(activeNavItem === '/interviews' ? 'activeNav' : '',
                'desktopNavLink')}
            onClick={() => { setActiveNavItem('/interviews'); }}
          // href="/interviews"
          >
            <Interviews width="20" height="20" fill="#7A91AB" />
            <span>
              {t('navbarSection.interviewsTab')}
            </span>
          </Link>

          <Link
            className={
              classnames(activeNavItem === '/insights' ? 'activeNav' : '',
                'desktopNavLink')}
            onClick={() => { setActiveNavItem('/insights'); }}
            to="/insights"
          >
            <Insights width="20" height="20" fill="#7A91AB" />
            <span>
              {t('navbarSection.insights')}
            </span>
          </Link>

          <Link
            className={
              classnames(activeNavItem === '/talent-pool' ? 'activeNav' : '',
                'desktopNavLink')}
            onClick={() => { setActiveNavItem('/talent-pool'); }}
            to="/talent-pool"
          >
            <Talentpool width="20" height="20" fill="#7A91AB" />
            <span>
              {
                isUserCoaching
                  ? t('navbarSection.applicantpoolTab')
                  : t('navbarSection.talentpoolTab')
              }
            </span>
          </Link>

          <Link
            className={
              classnames(activeNavItem === '/organization/members' ? 'activeNav' : '',
                'desktopNavLink')}
            onClick={() => { setActiveNavItem('/organization/members'); }}
            to="/organization/members"
          >
            <Team width="20" height="20" fill="#7A91AB" />
            <span>
              {t('navbarSection.usersTab')}
            </span>
          </Link>

          {
            !isMobile && (
              <InfoTooltip
                placement="bottom"
                tooltipContent={t('navbarSection.help')}
              >
                <a
                  className="supportLink"
                  target="_blank"
                  rel="noreferrer"
                  href="https://interviewer.ai/help-center"
                >
                  <HelpIcon height="25" width="25" stroke="#808080" />
                </a>
              </InfoTooltip>

            )
          }
          <button
            type="button"
            className={`nav-link navlinkButton ${activeNavItem === '/notifications'
              && 'activeNavNoti'}`}
            onClick={notificationIconClick}
          >
            <NavDropdown
              id="notification-dropdown"
              title={
                (
                  <div className="navBarIcon">
                    <img src={Notification} alt="notifications" />
                    {unreadNotifsCount > 0
                      && (
                        <div className="unreadCount">
                          <span>{unreadNotifsCount}</span>
                        </div>
                      )
                    }
                  </div>
                )}
              className="notificationsDropdown"
              noCaret
              open={openNotifications}
            >
              {
                openNotifications && (
                  <div ref={notificationRef}>
                    <div className="notificationHeading">
                      <div className="notificationsTitle">{t('navbarSection.notifications')}</div>
                      {
                        !noNotifs && (
                          <div
                            className="viewAllNotifications"
                            onClick={() => {
                              setOpenNotifications(!openNotifications);
                              history.push('/notifications');
                            }}
                            onKeyDown={() => {
                              setOpenNotifications(!openNotifications);
                              history.push('/notifications');
                            }}
                            tabIndex={0}
                            role="button"
                            type="button"
                          >
                            {t('navbarSection.viewAll')}
                          </div>
                        )
                      }
                    </div>
                    {isLoadingNotifs && <ClipLoadingComponent floatRight={false} />}
                    {noNotifs ? <div className="noNotifs">{t('navbarSection.notificationInfo1')}</div> : notifications.map((notif, index) => (
                      <div>
                        {
                          index !== 0 ? (
                            (moment(getYYYYMMDD(notifications[index].date_time)).format('ll') !== moment(getYYYYMMDD(notifications[index - 1].date_time)).format('ll')) && (
                              <div className="notificationDaySection">
                                <div className="line" />
                                <div className="notificationDayName">
                                  {notif.date_time ? notificationDate(notif.date_time) : '--'}
                                </div>
                                <div className="line" />
                              </div>
                            )
                          ) : (
                            <div className="notificationDaySection">
                              <div className="line" />
                              <div className="notificationDayName">
                                {notif.date_time ? notificationDate(notif.date_time) : '--'}
                              </div>
                              <div className="line" />
                            </div>
                          )
                        }
                        <div className={notif.has_read ? 'read' : 'notifItem'} key={notif.notification_id}>
                          <div className="notifDescription">
                            <div className="descriptionSection">
                              <div className="testing">
                                {notificationLogo(notif.user_logo_url, notif.name)}
                              </div>
                              <div className="descriptionAndTime">
                                <a className="notiLink" onClick={() => updateNotifReadStatus(notif.notification_id)} href={notif.redirect_url}>
                                  {notif.description}
                                </a>
                                <div className="date">
                                  {new Date(notif.date_time).toLocaleTimeString('en-US', {
                                    hour: 'numeric', minute: 'numeric',
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="centerElement">
                              {!notif.has_read && <div className="dot" />}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
            </NavDropdown>
          </button>
          <div className="expiryActionButtonContainer">
            <ExpiryActionButton subscription={subscription} className="expiryActionButtonContainer" accountBalance={creditsBalance} appSumoUser={appSumoUser} />
          </div>
          {thumbnailGenerator(
            companyLogoUrl || config.LOGO,
            firstName,
            lastName,
          )}
          {
            selectedLanguage && (
              <div
                className="languageChangeDropdown"
              >
                <button
                  onClick={() => setShowLanguages(!showLanguages)}
                  style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                  type="button"
                >
                  {renderFlag(selectedLanguage)}
                  <div style={{ display: 'flex' }}>
                    <DownIcon />
                  </div>
                </button>
                <div>
                  {
                    showLanguages && (
                      <ul className="languageDropdown" ref={languageDropdownRef}>
                        <li                             //eslint-disable-line
                          className="languageDropdownContent"
                          onClick={() => handleLanguageChange('en-US')}
                          onKeyDown={() => handleLanguageChange('en-US')}
                          type="button"
                        >
                          <img src={englishFlag} className="FlagImg" alt="englishFlag" />
                          <div>{t('navbarSection.english')}</div>
                        </li>
                        <li                            //eslint-disable-line
                          className="languageDropdownContent"
                          onClick={() => handleLanguageChange('vi')}
                          onKeyDown={() => handleLanguageChange('vi')}
                          type="button"
                        >
                          <img src={vietnamFlag} className="FlagImg" alt="englishFlag" />
                          <div>{t('navbarSection.vietnamese')}</div>
                        </li>
                        <li                            //eslint-disable-line
                          className="languageDropdownContent"
                          onClick={() => handleLanguageChange('de')}
                          onKeyDown={() => handleLanguageChange('de')}
                          type="button"
                        >
                          <img src={germanyFlag} className="FlagImg" alt="englishFlag" />
                          <div>{t('navbarSection.german')}</div>
                        </li>
                        <li                            //eslint-disable-line
                          className="languageDropdownContent"
                          onClick={() => handleLanguageChange('fr')}
                          onKeyDown={() => handleLanguageChange('fr')}
                          type="button"
                        >
                          <img src={frenchFlag} className="FlagImg" alt="frenchFlag" />
                          <div>{t('navbarSection.french')}</div>
                        </li>
                        <li                            //eslint-disable-line
                          className="languageDropdownContent"
                          onClick={() => handleLanguageChange('es-ES')}
                          onKeyDown={() => handleLanguageChange('es-ES')}
                          type="button"
                        >
                          <img src={spainFlag} className="FlagImg" alt="spanishFlag" />
                          <div>{t('navbarSection.spanish')}</div>
                        </li>
                      </ul>
                    )
                  }
                </div>
                {showDefaultLangPopup && (
                  <div className="toltip box-shadow">
                    <div className="toltip-content">
                      <button
                        type="button"
                        className="closeButton"
                        onClick={() => closeDefaultLangPopup()}
                      >
                        <Glyphicon glyph="remove" />
                      </button>
                      <p>
                        {'The selected language is based on your location in '}
                        {userGeoLocation.countryName || ''}
                        . Switch it to English?
                      </p>
                      <button
                        className="switchEng"
                        type="button"
                        onClick={defaultToEnglish}
                      >
                        Switch to English
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )
          }
        </nav>
      </div>


      <div className="MobileNavigation">
        <ProfileSidebar show={isProfileDrawerOpen} profileHandler={openProfileDrawerHandler} />
        <NavigationSlider
          show={isNavigationDrawerOpen}
          subscription={subscription}
          navigationHandler={openNavigationDrawerHandler}
        />


        <nav className="nav-container">
          <div className="leftSectionNavMobileview">
            <div
              className="hamburgerIconContainer"
              role="button"
              tabIndex={0}
              onClick={() => { openNavigationDrawerHandler(); }}
              onKeyDown={() => { openNavigationDrawerHandler(); }}
            >
              <HamburgerIcon />
            </div>

            <a
              href="/overview"
              className="navbarBrandIconContainer"
            >
              {/* <a className="navbarBrandIconContainer"> */}
              <IntervieweraiIcon width="35" height="35" />
              {/* </a> */}
            </a>
          </div>

          <div className="MiddleSection">
            {/* <span>Overview</span> */}
          </div>


          <div className="rightSectionNavMobileview">

            <NavDropdown
              id="notification-dropdown"
              title={
                (
                  <div
                    className="navBarIconMobile"
                    onClick={notificationIconClick}
                    onKeyDown={notificationIconClick}
                    tabIndex={0}
                    role="button"
                    type="button"
                  >
                    <BellIcon width="30" height="30" stroke="#292D32" stroke-width="1.5" />
                    {unreadNotifsCount > 0
                      && (
                        <div className="unreadCount">
                          <span>{unreadNotifsCount}</span>
                        </div>
                      )
                    }
                  </div>
                )}
              className="notificationsMobileDropdown"
              noCaret
              open={openNotifications}
            >
              {
                openNotifications && (
                  <div ref={notificationRef}>
                    <div className="notificationHeading">
                      <div className="notificationsTitle">{t('navbarSection.notifications')}</div>
                      {
                        !noNotifs && (
                          <div
                            className="viewAllNotifications"
                            onClick={() => {
                              setOpenNotifications(!openNotifications);
                              history.push('/notifications');
                            }}
                            onKeyDown={() => {
                              setOpenNotifications(!openNotifications);
                              history.push('/notifications');
                              setActiveNavItem('/notifications');
                            }}
                            tabIndex={0}
                            role="button"
                            type="button"
                          >
                            {t('navbarSection.viewAll')}
                          </div>
                        )
                      }
                    </div>
                    {isLoadingNotifs && <ClipLoadingComponent floatRight={false} />}
                    {noNotifs
                      ? <div className="noNotifs">{t('navbarSection.notificationInfo1')}</div>     //eslint-disable-line
                      : notifications.map((notif, index) => (
                        <div key={Math.random()}>
                          {
                            index !== 0 ? (
                              (moment(getYYYYMMDD(
                                notifications[index].date_time,
                              )).format('ll') !== moment(getYYYYMMDD(
                                notifications[index - 1].date_time,
                              )).format('ll'))
                              && (
                                <div className="notificationDaySection">
                                  <div className="line" />
                                  <div className="notificationDayName">
                                    {notif.date_time ? notificationDate(notif.date_time) : '--'}
                                  </div>
                                  <div className="line" />
                                </div>
                              )
                            ) : (
                              <div className="notificationDaySection">
                                <div className="line" />
                                <div className="notificationDayName">
                                  {notif.date_time ? notificationDate(notif.date_time) : '--'}
                                </div>
                                <div className="line" />
                              </div>
                            )
                          }
                          <div className={notif.has_read ? 'read' : 'notifItem'} key={notif.notification_id}>
                            <div className="notifDescription">
                              <div className="descriptionSection">
                                <div className="testing">
                                  {notificationLogo(notif.user_logo_url, notif.name)}
                                </div>
                                <div className="descriptionAndTime">
                                  <a className="notiLink" onClick={() => updateNotifReadStatus(notif.notification_id)} href={notif.redirect_url}>
                                    {notif.description}
                                  </a>
                                  <div className="date">
                                    {new Date(notif.date_time).toLocaleTimeString('en-US', {
                                      hour: 'numeric', minute: 'numeric',
                                    })}
                                  </div>
                                </div>
                              </div>
                              <div className="centerElement">
                                {!notif.has_read && <div className="dot" />}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                )
              }
            </NavDropdown>

            <button className="profileButtonMobile" type="button" onClick={openProfileDrawerHandler}>
              <div className="profile-icon">
                <img
                  src={companyLogoUrl || config.LOGO}
                  alt="settings icon"
                  style={{
                    height: 'auto',
                    width: '75px',
                  }}
                />
                {
                  userProfileImage ? (
                    <img
                      src={userProfileImage}
                      alt={`${firstName}`}
                    />
                  ) : (
                    <div className="userInitials">
                      {firstName ? firstName.charAt(0).toUpperCase() : ''}
                      {lastName ? lastName.charAt(0).toUpperCase() : ''}
                    </div>
                  )
                }

              </div>
            </button>
            {
              selectedLanguage && (
                <div
                  className="languageChangeDropdown"
                >
                  <button
                    onClick={() => setShowLanguages(!showLanguages)}
                    style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}
                    type="button"
                  >
                    {renderFlag(selectedLanguage)}
                    <div style={{ display: 'flex' }}>
                      <DownIcon />
                    </div>
                  </button>
                  <div>
                    {
                      showLanguages && (
                        <ul className="languageDropdown" ref={languageDropdownRef}>
                          <li                             //eslint-disable-line
                            className="languageDropdownContent"
                            onClick={() => handleLanguageChange('en-US')}
                            onKeyDown={() => handleLanguageChange('en-US')}
                            type="button"
                          >
                            <img src={englishFlag} className="FlagImg" alt="englishFlag" />
                            <div>{t('navbarSection.english')}</div>
                          </li>
                          <li                            //eslint-disable-line
                            className="languageDropdownContent"
                            onClick={() => handleLanguageChange('vi')}
                            onKeyDown={() => handleLanguageChange('vi')}
                            type="button"
                          >
                            <img src={vietnamFlag} className="FlagImg" alt="englishFlag" />
                            <div>{t('navbarSection.vietnamese')}</div>
                          </li>
                          <li                            //eslint-disable-line
                            className="languageDropdownContent"
                            onClick={() => handleLanguageChange('de')}
                            onKeyDown={() => handleLanguageChange('de')}
                            type="button"
                          >
                            <img src={germanyFlag} className="FlagImg" alt="englishFlag" />
                            <div>{t('navbarSection.german')}</div>
                          </li>
                          <li                            //eslint-disable-line
                            className="languageDropdownContent"
                            onClick={() => handleLanguageChange('fr')}
                            onKeyDown={() => handleLanguageChange('fr')}
                            type="button"
                          >
                            <img src={frenchFlag} className="FlagImg" alt="frenchFlag" />
                            <div>{t('navbarSection.french')}</div>
                          </li>
                          <li                            //eslint-disable-line
                            className="languageDropdownContent"
                            onClick={() => handleLanguageChange('es-ES')}
                            onKeyDown={() => handleLanguageChange('es-ES')}
                            type="button"
                          >
                            <img src={spainFlag} className="FlagImg" alt="spanishFlag" />
                            <div>{t('navbarSection.spanish')}</div>
                          </li>
                        </ul>
                      )
                    }
                  </div>
                  {showDefaultLangPopup && (
                    <div className="toltip box-shadow">
                      <div className="toltip-content">
                        <button
                          type="button"
                          className="closeButton"
                          onClick={() => closeDefaultLangPopup()}
                        >
                          <Glyphicon glyph="remove" />
                        </button>
                        <p>
                          {'The selected language is based on your location in '}
                          {userGeoLocation.countryName || ''}
                          . Switch it to English?
                        </p>
                        <button
                          className="switchEng"
                          type="button"
                          onClick={defaultToEnglish}
                        >
                          Switch to English
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )
            }
          </div>
          {
            !openTrialPopup && <UpgradePlanModal daysDiff={daysDifference} />
          }
          {
            !openCreditsAlertPopup
            && !appSumoUser
            && subscription.isCredit
            && <NegativeAlertModal />
          }
          <OnboardingModal history={history} />
        </nav>
      </div>
    </React.Fragment>
  );
};

NavigationBar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
  }).isRequired,
};

export default withRouter(NavigationBar);
