import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Button,
  ControlLabel,
  FormControl,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { isEmailValid, getInitial } from '../utils/utils';
import MultivalueInput from './MultivalueInput';
import { successToast } from './Toast';
import { ReactComponent as CrossIcon } from '../assets/CrossIcon.svg';

import '../css/ShareCandidatesModal.css';
import ClipLoadingComponent from './ClipLoadingComponent';
import CandidateActions from '../actions/CandidateActions';
import InfoTooltip from './InfoTooltip';

const ShareCandidatesModal = ({
  show, onHide, shareableLink, onCandidatesShareSubmit, requireListName,
  sharer, interviewName, candidateData, isShareThisProfile, interviewDetail, // eslint-disable-line
  interviewId, removeBranding, refinedData,
}) => {
  const [sharing, setSharing] = useState(false);
  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState(false);
  const inputRef = useRef(null);
  const [listName, setListName] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [note, setNote] = useState('');
  const [isPressEnterNoteShow, setIsPressEnterNoteShow] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    setEmailSubject(`${interviewName} - ${sharer} shared shortlisted candidates with you`);
  }, [interviewName]);
  useEffect(() => {
    if (!candidateData || isShareThisProfile) {
      return;
    }
    const filteredCandidate = candidateData.filter(x => x.checked); // eslint-disable-line
    setSelectedCandidate(filteredCandidate);
  }, [refinedData]);


  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
    successToast('Shareable link copied to clipboard');
  };

  const shareLinkToEmails = async () => {
    setSharing(true);
    const isListNameShow = true;

    await onCandidatesShareSubmit(
      emails,
      listName,
      accessCode.trim(),
      emailSubject,
      removeBranding,
      isListNameShow,
    );
    setSharing(false);
    onHide();
  };

  const onEmailsChange = (emailsList) => {
    if (emailsList.filter(email => !isEmailValid(email)).length > 0) {
      setEmailsError('Incorrect email address entered');
    } else {
      setIsPressEnterNoteShow(false);
      setEmails(emailsList);
      setEmailsError(false);
    }
  };

  const chipImageGenerator = (individualCandidate) => {
    if (!individualCandidate.img) {
      return (
        <div className="candidateChipInitial">
          {getInitial(individualCandidate.name)}
        </div>
      );
    }


    return (
      <div
        className="candidateImage"
        style={{
          backgroundImage: `url(${individualCandidate.img})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height: '36px',
          width: '36px',
          maxHeight: '36px',
          maxWidth: '36px',
          borderRadius: '50%',
        }}
      />
    );
  };

  const candidateNameFormatter = (candidateName) => {
    const candidateNameArray = candidateName.split(' ');
    const firstName = candidateNameArray[0];
    const lastName = candidateNameArray[1];
    if (!lastName) {
      return firstName;
    }

    return `${firstName} ${lastName.charAt(0).toUpperCase()}.`;
  };

  const handleShareThisProfile = async () => {
    // const { displayData, interviewId, interviewDetail } = this.state;
    const selectedCandidates = [candidateData];
    const listNameCaptured = `Share this profile-${interviewDetail.interviewname}`;
    const language = interviewDetail.interview_language;
    const defaultSubject = `${selectedCandidates.length}
    ${selectedCandidates.length > 1 ? 'candidates' : 'candidate'} shared for ${interviewDetail.interviewname}`;

    const isListNameShow = false;
    await CandidateActions.shareCandidatesList(
      selectedCandidates,
      emails,
      listNameCaptured,
      interviewId,
      interviewDetail.interviewname,
      language,
      accessCode,
      emailSubject || defaultSubject,
      note,
      removeBranding,
      isListNameShow,
    );
  };

  return (
    <Modal show={show} onHide={onHide} enforceFocus className="shareCandidateModalStructure" backdrop="static">

      <Modal.Header closeButton>
        {
          isShareThisProfile ? (
            <Modal.Title>{t('shareProfile.shareThisProfile')}</Modal.Title>
          ) : (
            <Modal.Title>{t('shareProfile.shareCandidates')}</Modal.Title>
          )
        }

      </Modal.Header>
      <Modal.Body>
        <div className="shareCandidateBody">
          {
            !isShareThisProfile && (
              <div className="formContainer">
                <ControlLabel>{t('candidateDetails.emailSubject')}</ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('shareProfile.enterEmailSubject')}
                  value={emailSubject}
                  className="form-control-with-value inputText"
                  onChange={e => setEmailSubject(e.target.value)}
                />
              </div>
            )
          }

          {
            !isShareThisProfile && (
              <div className="selectedCandidateSection">
                <p className="f18-500-bold-dark ">{t('shareProfile.selectedCandidate')}</p>
                <div className="selectedCandidateChipSection">
                  {
                    selectedCandidate && selectedCandidate.map(x => (
                      <div className="candidateChip" key={`key-${x}`}>
                        {chipImageGenerator(x)}
                        <span>{candidateNameFormatter(x.name)}</span>
                        <button
                          type="button"
                          className="unselectCandidate"
                          onClick={e => CandidateActions.changeCandidateCheckbox(
                            x.candidateid, e,
                          )}
                        >
                          <CrossIcon width="12" height="12" stroke="#7989B1" />
                        </button>
                      </div>
                    ))
                  }
                </div>
              </div>
            )
          }

          {requireListName && (
            <div className="formContainer">
              <ControlLabel>
                {t('shareProfile.listName')}
                {
                  requireListName && (
                    <span className="isRequired">*</span>
                  )
                }
              </ControlLabel>
              <FormControl
                type="text"
                placeholder="Enter a name for this list of candidates"
                value={listName}
                className="form-control-with-value inputText"
                onChange={e => setListName(e.target.value)}
              />
            </div>
          )}
          <div className="formContainer position-relative">
            <ControlLabel>
              {t('shareProfile.shareWith')}
            </ControlLabel>
            <MultivalueInput
              values={emails}
              onValuesChange={onEmailsChange}
              error={emailsError}
              isPressEnterNoteShow={isPressEnterNoteShow}
              placeholder={t('shareProfile.info1')}
            />
          </div>

          {
            !isShareThisProfile && (
              <div className="formContainer">
                <ControlLabel>
                  {t('shareProfile.secureAccess')}
                  <span className="optionalText">
                    (
                    {t('shareProfile.optional')}
                    )
                  </span>
                </ControlLabel>
                <FormControl
                  type="text"
                  placeholder={t('shareProfile.enterAccessCodeHere')}
                  value={accessCode}
                  className="form-control-with-value inputText"
                  onChange={e => setAccessCode(e.target.value)}
                />
              </div>
            )
          }
          {shareableLink && (
            <div className="formContainer">
              <ControlLabel>{t('shareProfile.shareLink')}</ControlLabel>
              <div className="shareLink">
                <FormControl
                  type="text"
                  placeholder=""
                  value={shareableLink}
                  className="form-control-with-value inputText"
                  inputRef={inputRef}
                />
                <Button className="btn btn-secondary-custom" onClick={copyToClipboard}>
                  {t('shareProfile.copyLink')}
                </Button>
              </div>
            </div>
          )}

          {
            isShareThisProfile && (
              <div className="formContainer">
                <ControlLabel>
                  {t('shareProfile.note')}
                </ControlLabel>
                <textarea
                  className="form-control inputTextArea"
                  maxLength="500"
                  rows="8"
                  name="categoryDescription"
                  placeholder={t('shareProfile.enterNote')}
                  onChange={e => setNote(e.target.value)}
                  value={note}

                />
              </div>
            )
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancelButton" onClick={onHide}>
          {t('cancel')}
        </Button>


        {
          isShareThisProfile ? (
            <Button
              className="shareButton"
              onClick={() => { handleShareThisProfile(); }}
              disabled={emails.length < 1 || (requireListName && !listName) || sharing}
            >
              {t('share')}
              {
                sharing && <ClipLoadingComponent inverse />
              }
            </Button>
          ) : (
            <InfoTooltip
              placement="top"
              tooltipContent="Select candidates to share"
            >

              <Button
                className="shareButton"
                onClick={shareLinkToEmails}
                disabled={emails.length < 1 || (requireListName && !listName) || sharing}
              >
                {t('share')}
                {
                  sharing && <ClipLoadingComponent inverse />
                }
              </Button>
            </InfoTooltip>
          )
        }
      </Modal.Footer>
    </Modal>
  );
};

ShareCandidatesModal.defaultProps = {
  shareableLink: '',
  requireListName: false,
};

ShareCandidatesModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  onCandidatesShareSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  shareableLink: PropTypes.string,
  requireListName: PropTypes.bool,
  sharer: PropTypes.string.isRequired,
  interviewName: PropTypes.string.isRequired,
  interviewDetail: PropTypes.shape({
    overwriteFields: PropTypes.object, // eslint-disable-line
    interviewname: PropTypes.string,
    interview_language: PropTypes.string,
  }).isRequired,
  // eslint-disable-next-line forbid-prop-types
  // candidateData: PropTypes.object.isRequired, // eslint-disable-line
  // candidateData: PropTypes.arrayOf(PropTypes.object),
  isShareThisProfile: PropTypes.bool.isRequired,
  interviewId: PropTypes.string.isRequired,
  removeBranding: PropTypes.bool.isRequired,
  refinedData: PropTypes.array.isRequired,  //eslint-disable-line
};

export default ShareCandidatesModal;
