import React, { Fragment, useState, useEffect } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  DropdownButton,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
  MenuItem,
  ButtonGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import 'flatpickr/dist/themes/material_green.css';
import '../../css/EmailPreview.css';
import '../../css/ReactDatesCalendar.css';
import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import { mxTrackEvent, getEmailTemplate, getLocalTime } from '../../utils/utils';
import CandidateActions from '../../actions/CandidateActions';
import ClipLoadingComponent from '../ClipLoadingComponent';
import {
  EMAIL_TYPE,
} from '../../utils/constants';
import Auth from '../../auth/Auth';
import { alertToast, successToast } from '../Toast';

const ScheduleCalendlyEmailPreview = ({
  jobName,
  companyName,
  companyLogoUrl,
  interviewId,
  candidateData,
  showScheduleInterviewModal,
  eventTypeData,
  chosenEventTypeUrl,
  currentNameAttribute,
  onHide,
  interviewLanguage,
  removeBranding,
}) => {
  const { t } = useTranslation();
  // convert template to state so the draft can be saved accordingly
  const emailData = getEmailTemplate(true);
  const calendlyTemplate = EMAIL_TYPE.CALENDLY_INTERVIEW_EMAIL;
  const firstName = Auth.getProfileInfo('firstName');
  const interviewerEmail = Auth.getProfileInfo('email');
  const [schedulingInterview, setSchedulingInterview] = useState(false);
  const [emailSubject, setEmailSubject] = useState(emailData.subject({ jobName, companyName }));
  // To put it to CandidateStore
  const [dropDownName, setDropDownName] = useState(() => (currentNameAttribute === '' ? 'No events found' : currentNameAttribute));

  const [footer, setFooter] = useState(`Best regards,\n${firstName}\n${companyName}`);
  const [content, setContent] = useState(emailData.body({
    jobName,
    companyName,
    chosenEventTypeUrl,
    interviewerEmail,
  }));
  const [senderEmail, setSenderEmail] = useState('no-reply@interviewerai.com');

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const getTemplateApi = `/api/email/template/${interviewId}?type=${calendlyTemplate}`;
        const req = await fetch(getTemplateApi, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
        });

        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          setEmailSubject(res.subject || emailSubject);
          setFooter(res.footer || footer);
          setContent(res.message || emailData.body({
            jobName,
            companyName,
            calendlyUrlLink: chosenEventTypeUrl,
            interviewerEmail,
          }));
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchTemplate();
  }, [showScheduleInterviewModal]);

  useEffect(() => {
    setContent(emailData.body({
      jobName,
      companyName,
      calendlyUrlLink: chosenEventTypeUrl,
      interviewerEmail,
    }));
  }, [chosenEventTypeUrl]);

  useEffect(() => {
    setDropDownName(currentNameAttribute);
  }, [currentNameAttribute]);

  const scheduleInterview = async () => {
    setSchedulingInterview(true);
    const candidateId = candidateData.candidateid;
    const candidateEmail = candidateData.email;
    const candidateName = candidateData.name;
    try {
      const postScheduleApi = `/api/email/scheduleInterview?type=${EMAIL_TYPE.CALENDLY_INTERVIEW_EMAIL}&calendly=${true}`;
      const req = await fetch(postScheduleApi, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidateId,
          candidateEmail,
          candidateName,
          interviewId,
          interviewName: jobName,
          interviewerEmail,
          companyName,
          companyLogoUrl,
          emailMessage: content,
          emailSubject,
          footerMessage: footer,
          language: interviewLanguage,
          removeBranding,
          senderEmail,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        successToast(`Successfully send interview invitation email to ${candidateName}`);
        mxTrackEvent('Interview scheduling email sent success', {
          jobName,
          interviewId,
          candidateEmail,
          candidateName,
        });
        CandidateActions.updateCandidateCalendlyScheduleInterviewDate({
          candidateId,
          third_party_scheduled_type: 'calendly',
          contacted_date: getLocalTime(),
        });
        setSchedulingInterview(false);
        setContent(emailData.body({
          jobName,
          companyName,
          chosenEventTypeUrl,
          interviewerEmail,
        }));
        onHide();
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      alertToast('Error in sending interview invitation email to candidate. Please try again later');
      mxTrackEvent('Interview scheduling email sent failure', {
        jobName,
        interviewId,
        candidateEmail,
        candidateName,
      });
      setSchedulingInterview(false);
    }
  };

  return (
    <div className="email-preview">
      <Modal
        show={showScheduleInterviewModal}
        onHide={() => {
          onHide();
        }}
        bsSize="large"
        className="scheduleInterviewModule"
      >
        <Modal.Header>
          <Modal.Title>{t('candidateDetails.emailPreview')}</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>
            <div className="calendlyEventTypes">
              <ControlLabel>
                1.
                {' '}
                {t('candidateDetails.selectEventType')}
              </ControlLabel>
              <p>
                {t('candidateDetails.calendlyEventCreated')}
                :
                {' '}
                <a href="https://calendly.com/event_types/user/me" target="_blank" rel="noopener noreferrer">
                  https://calendly.com/event_types/user/me
                </a>
              </p>
              <Row>
                <Col sm={9} md={4}>
                  <DropdownButton
                    id="calendly-dropdown"
                    title={eventTypeData.length === 0 ? (t('candidateDetails.noCalendlyEventFound')) : dropDownName}
                    as={ButtonGroup}
                    disabled={candidateData.rejected}
                  >
                    {
                      eventTypeData.map(e => (
                        <MenuItem
                          key={`${e.attributes.name}`}
                          onClick={() => {
                            setDropDownName(e.attributes.name);
                            CandidateActions.setCurrentNameAttribute(e.attributes.name);
                            CandidateActions.chosenEventTypeUrl(e.attributes.url);
                          }}
                        >
                          {e.attributes.name}
                        </MenuItem>
                      ))
                    }
                  </DropdownButton>
                </Col>
              </Row>
            </div>
            {
              eventTypeData.length !== 0 && (
                <Fragment>
                  <hr className="no-margin-top" />
                  <ControlLabel>
                    2.
                    {' '}
                    {t('candidateDetails.sender')}
                  </ControlLabel>
                  <Form horizontal>
                    <FormGroup controlId="senderEmail">
                      <Col sm={10}>
                        <FormControl
                          componentClass="select"
                          name="senderEmail"
                          value={senderEmail}
                          onChange={e => setSenderEmail(e.target.value)}
                        >
                          <option value="no-reply@interviewerai.com">no-reply@interviewerai.com</option>
                          <option value={interviewerEmail}>{interviewerEmail}</option>
                        </FormControl>
                      </Col>
                    </FormGroup>
                  </Form>
                  <hr className="no-margin-top" />
                  <ControlLabel>
                    3.
                    {' '}
                    {t('candidateDetails.editEmailSubject')}
                  </ControlLabel>
                  <Form horizontal>
                    <FormGroup controlId="emailSubject">
                      <Col sm={10}>
                        <FormControl
                          type="text"
                          name="emailSubject"
                          className="email-preview__content--editable email-preview__content--editable-small"
                          value={emailSubject}
                          onChange={e => setEmailSubject(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                  <hr />
                  <ControlLabel>
                    4.
                    {' '}
                    {t('candidateDetails.checkEditEmailContent')}
                  </ControlLabel>
                  <div className="email-preview__content">
                    <img
                      src={companyLogoUrl}
                      alt="Company logo"
                      className="email-preview__content--logo img-responsive"
                    />
                    <h4>
                      {t('candidateDetails.dear')}
                      {' '}
                      [candidate-name],
                    </h4>
                    <textarea
                      className="email-preview__content--editable"
                      value={content}
                      onChange={e => setContent(e.target.value)}
                      onBlur={() => mxTrackEvent('Email content area changed')}
                      maxLength={1500}
                    />
                    <br />
                    <p>
                      [candidate-name],
                      {t('candidateDetails.lookForwardMeetingYou')}
                      .
                    </p>
                    <br />
                    <textarea
                      className="email-preview__content--editable email-preview__content--editable-medium"
                      value={footer}
                      onChange={e => setFooter(e.target.value)}
                      onBlur={() => mxTrackEvent('Email footer area change')}
                      maxLength={300}
                    />
                  </div>
                </Fragment>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="schedule-button"
              className="btn-primary-custom"
              onClick={scheduleInterview}
              disabled={schedulingInterview || eventTypeData.length === 0}
            >
              {
                schedulingInterview ? (
                  <span>
                    {t('candidateDetails.sending')}
                    <ClipLoadingComponent inverse />
                  </span>
                ) : (t('candidateDetails.scheduleAndSend'))
              }
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

ScheduleCalendlyEmailPreview.defaultProps = {
  interviewLanguage: 'en-US',
};


ScheduleCalendlyEmailPreview.propTypes = {
  jobName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyLogoUrl: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  candidateData: PropTypes.shape({
    candidateid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    status: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    score: PropTypes.string,
    checked: PropTypes.bool,
    interviewLink: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    rejected: PropTypes.bool.isRequired,
  }).isRequired,
  showScheduleInterviewModal: PropTypes.bool.isRequired,
  eventTypeData: PropTypes.arrayOf(PropTypes.object).isRequired,
  chosenEventTypeUrl: PropTypes.string.isRequired,
  currentNameAttribute: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
  interviewLanguage: PropTypes.string,
  removeBranding: PropTypes.bool.isRequired,
};

export default ScheduleCalendlyEmailPreview;
