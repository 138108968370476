import 'es6-promise/auto';
import auth0 from 'auth0-js';
import clientConfig from '../config/config';

const config = clientConfig.default;

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: config.AUTH0.domain,
      clientID: config.AUTH0.audience,
      redirectUri: config.AUTH0.redirectUri,
      audience: `https://${config.AUTH0.managementDomain}/api/v2/`,
      responseType: 'token id_token',
      scope: 'openid email read:current_user',
    });

    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.getIdToken = this.getIdToken.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.setSession = this.setSession.bind(this);
    this.scheduleRenewal = this.scheduleRenewal.bind(this);
    this.clearRenewalTimeout = this.clearRenewalTimeout.bind(this);
    this.signupRedirection = this.signupRedirection.bind(this);

    this.tokenRenewalTimeout = null;
    window.addEventListener('load', () => {
      this.scheduleRenewal();
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);

        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  signIn(email, password) {
    // this.auth0.authorize();
    return new Promise((resolve, reject) => {
      this.auth0.login({
        realm: 'Username-Password-Authentication',
        email,
        password,
      }, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        this.setSession(authResult);

        return resolve();
      });
    });
  }

  login = (redirectParams = {}) => {
    if (redirectParams && Object.keys(redirectParams).length > 0) {
      this.auth0.authorize(redirectParams);
    } else {
      this.auth0.authorize();
    }
  }

  signOut() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('discount_data');
    localStorage.removeItem('popup');
    localStorage.removeItem('hasShownOnboardingModal');
    this.clearRenewalTimeout();
    window.location.href = '/login';
    this.auth0.logout({
      clientID: config.AUTH0.audience,
    });
  }

  resetPassword(email) {
    return new Promise((resolve, reject) => {
      this.auth0.changePassword({
        connection: 'Username-Password-Authentication',
        email,
      }, (err, authResult) => {
        if (err) {
          return reject(err);
        }
        return resolve(authResult);
      });
    });
  }

  /* eslint-disable */
  getIdToken() {
    return localStorage.getItem('id_token');
  }

  /* eslint-disable */
  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  /* eslint-disable */
  getProfileInfo(key) {
    const profile = localStorage.getItem('profile');
    if (!profile) {
      console.log('No profile info found');
      return;
    }

    const profileJson = JSON.parse(profile);
    if (key in profileJson) {
      return profileJson[key];
    }
    return profileJson[`${config.AUTH0.namespace}${key}`];
  }


  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
  /* eslint-enable */

  setSession(authResult) {
    const tokenPayload = authResult.idTokenPayload;
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('profile', JSON.stringify(tokenPayload));
    localStorage.setItem('expires_at', tokenPayload.exp * 1000 - 60 * 1000); // do silentAuth 1 min earlier
    this.clearRenewalTimeout();
    this.scheduleRenewal();
  }

  scheduleRenewal() {
    if (!this.tokenRenewalTimeout) {
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      const delay = expiresAt - Date.now();
      if (delay > 0) {
        this.tokenRenewalTimeout = setTimeout(() => this.silentAuth(), delay);
      }
    }
  }

  silentAuth() {
    console.log('silent authing');
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        console.log('Auth result before setting is ************', authResult);
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  silentIdToken() {
    return new Promise((resolve, reject) => {
      this.auth0.get({}, (err, authResult) => {
        if (err) return reject(err);
        console.log('Auth result before setting is ************', authResult);
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  clearRenewalTimeout() {
    clearTimeout(this.tokenRenewalTimeout);
    this.tokenRenewalTimeout = null;
  }

  refreshUserSession() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('discount_data');
    console.log('silent authing');
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
        return resolve();
      });
    });
  }

  signupRedirection() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('discount_data');
    localStorage.removeItem('popup');
    localStorage.removeItem('hasShownOnboardingModal');
    this.clearRenewalTimeout();
    this.signOut();
    // window.location.href = '/signup';
  }

  loginCounts() {    //eslint-disable-line
    const profile = JSON.parse(localStorage.getItem('profile'));
    const nameSpace = config.AUTH0.namespace;
    return profile[`${nameSpace}login_count`];
  }
}

const auth0Client = new Auth();

export default auth0Client;
