// @flow
import React from 'react';
import { Col } from 'react-bootstrap';

const CandidateMetadataRow = ({ title, value }: {
  title: string,
  value: string,
}) => (
  <div className="App-content-candidates-detail clearfix">
    <Col xs={3} sm={12} md={12} lg={4} className="App-content-candidates-detail--title">
      { title }
    </Col>
    <Col xs={9} sm={12} md={12} lg={8} className="App-content-candidates-detail--info">
      { value }
    </Col>
  </div>
);

export default CandidateMetadataRow;
