import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en';
import vi from './vi';
import de from './de';
import fr from './fr';
import es from './es';
import id from './id';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    resources: {
      'en-US': {
        translation: en,
      },
      vi: {
        translation: vi,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      'es-ES': {
        translation: es,
      },
      id: {
        translation: id,
      },
    },
  });

export default i18n;
