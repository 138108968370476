// @flow
import React, { useEffect, useState } from 'react';
import { Modal, Glyphicon } from 'react-bootstrap';
import OrganizationStore from '../stores/OrganizationStore';
import OrganizationActions from '../actions/OrganizationActions';
import firstStepImg1 from '../assets/step1.png';
import firstStepBottomImg1 from '../assets/step1bottom.png';
import secondStepImg1 from '../assets/step2.png';
import secondStepBottomImg1 from '../assets/step2bottom.png';
import thirdStepImg1 from '../assets/step3.png';
import thirdStepBottomImg1 from '../assets/step3bottom.png';
import forthStepImg1 from '../assets/step4.png';
import forthStepBottomImg1 from '../assets/step4bottom.png';
import fifthStepImg1 from '../assets/step5.png';
import fifthStepBottomImg1 from '../assets/step5bottom.png';
import styles from '../css/OnboardingModal.module.scss';
import {
  mxTrackEvent,
} from '../utils/utils';

const OnboardingModal = ({ history }: { history: Object }) => {
  const stepsData = [
    {
      text1: 'Wondering where to start? Here is a guide:',
      text2: [
        'Make sure your ',
        <a href="/settings/company-profile" className={styles.hyperLink} target="_blank">
          Company Logo
        </a>,
        ' is updated before you begin and complete your profile.',
      ],
      img1: firstStepImg1,
      img2: firstStepBottomImg1,
    },
    {
      text1: 'Your Career Page on Interviewer.AI is free.',
      text2: [
        'It has become so much easier (and automated) to have all the jobs that you are hiring for.',
        <br />,
        <br />,
        'Go to ',
        <a href="/settings/company-profile" target="_blank" className={styles.hyperLink}>
          company profile page
        </a>,
      ],
      img1: secondStepImg1,
      img2: secondStepBottomImg1,
    },
    {
      text1: 'Collaborate with your team. You can invite them to join.',
      text2: [
        <a href="/organization/members" className={styles.hyperLink} target="_blank">Invite</a>,
        ' new users.',
      ],
      img1: thirdStepImg1,
      img2: thirdStepBottomImg1,
    },
    {
      text1: 'Choose what you want to measure and assess.',
      text2: [
        'Use Gen AI to generate questions, content relevancy and more.',
        <br />,
        <a href="/interviews/new" className={styles.hyperLink} target="_blank">Go to Interview</a>,
      ],
      img1: forthStepImg1,
      img2: forthStepBottomImg1,
    },
    {
      text1: 'You are at the end of the step!',
      text2: [
        'Start by creating your ',
        <a href="/interviews/new" className={styles.hyperLink} target="_blank">
          first job interview!
        </a>,
      ],
      img1: fifthStepImg1,
      img2: fifthStepBottomImg1,
    },
  ];
  const [isModalShow, setIsModalShow] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  const closeUpgradePlanModal = () => {
    OrganizationActions.setOnBoardingModal(false);
  };

  const onChange = (storeData) => {
    const { isOnBoardingModal } = storeData;
    if (isOnBoardingModal) {
      // mxTrackEvent('Offer popup opened');
    }
    setIsModalShow(isOnBoardingModal);
  };

  const handleClosePopup = () => {
    OrganizationActions.setOnBoardingModal(false);
  };

  useEffect(() => {
    OrganizationStore.listen(onChange);
    mxTrackEvent('Onboarding modal opened');
  }, []);

  const handleNextLogic = (currentIdx) => {
    if (currentIdx + 1 === stepsData.length) {
      OrganizationActions.setOnBoardingModal(false);
      mxTrackEvent('Finished button clicked on onboarding modal');
      history.push('/interviews/new');
      return;
    }
    setStepIndex(currentIdx + 1);
  };

  const handlePreviousLogic = (currentIdx) => {
    setStepIndex(currentIdx - 1);
  };

  const contentSection = () => (
    <div className={styles.mainGridContainer}>
      <section
        className={styles.firstSection}
        style={{
          position: 'relative',
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
            url(${stepsData[stepIndex].img1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <>
          <div className={styles.text1}>
            {stepsData[stepIndex].text1}
          </div>
          <div className={styles.text2}>
            {stepsData[stepIndex].text2.map((part, index) => (
              <React.Fragment key={index}>{part}</React.Fragment>  //eslint-disable-line
            ))}
          </div>
          <div className={styles.stepsButonSection}>
            <div className={styles.stepsSection}>
              <span>
                Step
              </span>
              <span className={styles.steps}>
                {`${stepIndex + 1}/5`}
              </span>
            </div>
            <div className={styles.buttonSection}>
              {
                stepIndex !== 0 && (
                  <button
                    className={styles.previousButton}
                    onClick={() => handlePreviousLogic(stepIndex)}
                    type="button"
                  >
                    Previous
                  </button>
                )
              }
              <button
                className={styles.nextButton}
                onClick={() => handleNextLogic(stepIndex)}
                type="button"
              >
                {
                  stepsData.length === stepIndex + 1
                    ? 'Finish' : 'Next'
                }
              </button>
            </div>
          </div>
        </>
      </section>
      <section
        className={styles.secondSection}
        style={{
          position: 'relative',
          backgroundImage: `url(${stepsData[stepIndex].img2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      />
    </div>
  );

  return (
    <React.Fragment>
      <Modal show={isModalShow} className={styles.parentContainer} onExit={handleClosePopup}>
        <Modal.Body className={styles.paymentBody}>
          <div className={styles.imaageAndCancelContainer}>
            <button
              type="button"
              onClick={closeUpgradePlanModal}
              className={styles.closepopupButton}
            >
              <Glyphicon glyph="remove" />
            </button>
            {contentSection()}
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default OnboardingModal;
