const config = {
  DOMAIN: 'http://localhost:3000',
  RECORDING_DOMAIN: 'http://localhost:3000',
  AUTH0: {
    domain: 'interviewerai-dev.auth0.com',
    managementDomain: 'interviewerai-dev.auth0.com',
    audience: 'GFQIYfuUQ13glqCA4I4K1E666AnfrgGb', // client id,
    namespace: 'http://interviewer-dev.ai/',
    // redirectUri: 'http://localhost:3000/callback',
    redirectUri: 'https://dev-dashboard-33wr3b4yua-uc.a.run.app/callback',
  },
  MIXPANEL_TOKEN: '6f3fde42be9e86358aae037c7b38f28d',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_1VjOFXlCnhCslGingEm1e5Cz',
  SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  INSIGHTS_SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  GTM: {
    id: 'GTM-P8NRK92',
    auth: 'Dnxs8hmQiTekY2EHEfQn9A',
    env: 'env-7',
  },
  CAN_DOWNLOAD_REPORT_USER: [
    '8f561561-2e72-4133-b8e0-a092e43e9b0d',
    'ce4c9d63-c80d-4576-b15f-fa66536622d1',
  ],
  SPECIAL_COACHING_SCORING_USER: [
    // '8f561561-2e72-4133-b8e0-a092e43e9b0d',
    // 'ce4c9d63-c80d-4576-b15f-fa66536622d1', // Eric
  ],
  HIGH_STANDARD_INTERVIEW_ID: [

  ],
  LOGO: 'https://dev-interviewer-ai-assets.s3.amazonaws.com/Interviewer-ai-logo-transparent-128x128.png',
  GREY_SCALE_LOGO: 'https://dev-interviewer-ai-assets.s3.amazonaws.com/grayscale-InterviewerAI-logo_ccexpress.png',
  BRONZE_TRIAL: 15,
  SAMPLE_CANDIDATE_EMAIL: ['austin_00@domain.tld', 'melissa_89@domain.tld'],
  CUSTOM_JOBFUNCTION_USER: ['eba123b2-0d2f-4acb-b87d-5f74ddbf293b', 'd3b4de89-07ac-44df-af1a-0235b3cc8470', 'f37aa9b7-2694-4650-8137-93fb40e69704'],
  CANDIDATE_RATED_FLAG_ORGID: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  ENTERPRISE_CLIENTS: ['d3b4de89-07ac-44df-af1a-0235b3cc8470', 'eba123b2-0d2f-4acb-b87d-5f74ddbf293b', 'f37aa9b7-2694-4650-8137-93fb40e69704', '1922015b-cec6-4d22-82d7-f98086557727', '1922015b-cec6-4d22-82d7-f98086557727', '4b28e81b-e88e-4521-bb44-a823037f0623', '095178e8-b147-4ebf-baac-aefc16db3628', '37651a12-a2d9-4ff7-8845-7670be5bad99', 'a79d464a-913f-469e-a425-b1f42af6cc06'],
  PROMO_CODE: 'OXbq4apS',
  IAI_INTERNAL: ['d3b4de89-07ac-44df-af1a-0235b3cc8470'],
  VIDEO_INTERVIEW_GATE_PROMPT_ORGS: ['f37aa9b7-2694-4650-8137-93fb40e69704'],
  DELAY_INTERVIEW_ORGANIZATIONS: ['b5a18517-699f-42f9-9798-e1fb8308f42d', '4b28e81b-e88e-4521-bb44-a823037f0623'],
  EBANLE_VIETNAMESE_LANGUAGE: ['8f561561-2e72-4133-b8e0-a092e43e9b0d', 'd464c622-559d-4a7e-84ae-75e3ec80a71c'],
  DOMAIN_QUESTION_SCORES: ['58a4fd8a-7dc0-4e4e-9909-6e779fb05a6e'],
  ENABLE_VIETNAMESE_FOR_HIRING_MANAGER: ['ketki@interviewer.ai'],
  ENABLE_FRAUD_DETECTION: ['1922015b-cec6-4d22-82d7-f98086557727', '4b28e81b-e88e-4521-bb44-a823037f0623', '095178e8-b147-4ebf-baac-aefc16db3628', '37651a12-a2d9-4ff7-8845-7670be5bad99', 'a79d464a-913f-469e-a425-b1f42af6cc06', '4b28e81b-e88e-4521-bb44-a823037f0623'],
  INSIGHTS_PAYMENT_LINK: 'https://buy.stripe.com/test_cN23e7f3mf2e7NmcMO',
  DEEP_STACK_RANKING: ['4b28e81b-e88e-4521-bb44-a823037f0623', '4838ec0d-d785-4250-a64d-bc9612a8c36f'],
  AIA_API_ENDPOINT: 'https://imo2.aia.id/app/gateway/iRecruite/2.0',
  AIA_ORG_ID: ['1922015b-cec6-4d22-82d7-f98086557727', 'adbd3694-8d88-415d-966c-909ec6045b7c'],
  AIA_GATEWAY_KEY: '15c0f6b5-8ea2-454c-a50e-c10fab7eb0da',
  SHOW_REATTEMPT_SUBMISSION: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  GENERATE_VIDEO_INTERVIEW_QUESTION: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  AIA_ORG_IRECRUIT_LOGIN: 'adbd3694-8d88-415d-966c-909ec6045b7c',
  SOP_FEATURE: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  HIDE_RESUME_TOGGLE: [],
  INTERVIEW_IDEAL_ANSWER: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  CATEGORY_HASTAG_FEATURE: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  TOGGLE_CONTENT_RELEVANCY: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  CANDIDATE_EXTRA_INFORMATION: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  CANDIDATE_EVENT_ID: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  TRIAL_PLAN_ID: 15,
  CONTENT_RELEVANCY: [ // Primarily for CSV Download,
    '4b28e81b-e88e-4521-bb44-a823037f0623',
  ],
  EDIT_DELETE_VIDEO_CATEGORY: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  CANDIDATE_REACTIVATION_LINK: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  HIDE_QUESTIONS: ['4b28e81b-e88e-4521-bb44-a823037f0623'],
  GENERAL_QUESTION_ID: 2,
  NEW_RELIC_APP_NAME: 'Interviewer AI Dashboard - Backend',
  NEW_RELIC_LICENSE_KEY: '4f1662ebcf021f0630c56b29b877ef93FFFFNRAL',
};

export default config;
