import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Slider from 'rc-slider';
import {
  Col,
  ListGroupItem,
  ProgressBar,
  Glyphicon,
  InputGroup,
  FormControl,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useTranslation } from 'react-i18next';
import Auth from '../auth/Auth';
import {
  getProgressColorClass,
  getHighStandardProgressColorClass,
  mxTrackEvent,
  isCoachingUser,
} from '../utils/utils';
import styles from '../css/CandidateListWidget.module.scss';
// disabled eslint for scoreFilter because it is affecting the css for score settings sliders
import ScoreFilter from './ScoreFilter';  //eslint-disable-line
import clientConfig from '../config/config';
import PaginationNewWidgit from './PaginationNewWidgit';
import FilterCandidate from './FilterCandidate';
import { ReactComponent as ScoreSettingImg } from '../assets/ScoreSettingIcon.svg';
import { ReactComponent as ArrowDown } from '../assets/ArrowDown.svg';
import { ReactComponent as ArrowUp } from '../assets/ArrowUp.svg';
import { ReactComponent as ClipboardIcon } from '../assets/ClipboardIcon.svg';
import { ReactComponent as StarredIcon } from '../assets/StaredProfile.svg';
import { ReactComponent as ResetIcon } from '../assets/resetIcon.svg';
import { ReactComponent as FilterButton } from '../assets/newFilterButton.svg';
import CandidateActions from '../actions/CandidateActions';
import CandidateStore from '../stores/CandidateStore';
import ShareActions from '../actions/ShareActions';
import ShareStore from '../stores/ShareStore';
// import { alertToast } from './Toast';
import OrganizationStore from '../stores/OrganizationStore';
import AssessmentLimitModal from './AssessmentsLimitModal';
import searchIcon from '../assets/fi_search.svg';
import backArrow from '../assets/backArrowNew.svg';
// import closeIcon from '../assets/closeIcon.svg';
import infoIcon from '../assets/icons-info.svg';
import GeneralModal from './GeneralModal';
import ClipLoadingComponent from './ClipLoadingComponent';

const CandidateListWidget = (props) => {
  const {
    interviewId,
    interviewDetail,
    displayData,
    candidateData,
    collapseList,
    ThumbnailPlaceholder,
    parentComponent,
    scoreSettingWeight,
    showScoreSettings,
    totalScoreSettings,
    isSavingScoreSettings,
    enabledScoreCategory,
    orgId,
    totalCandidates,
    handlePaginationClick,
    isDataLoadingForCandWidgit,
    currentPage,
    // onCandidateClick
    fetchingNewCandidates,
    searchedIconClickedOnDetails,
    startIdx,
    endIdx,
    changePagination,
    countryList,
    selectedCountries,
    selectCandidateStatusToFilter,
    selectRecruiterStatusToFilter,
    candidateFilters,
    additionalIntroQuestion,
    filterIntroDropdown,
    introQuestionDpObject,
    handleFilterButton,
    handleFilterReset,
    filterStartDate,
    filterEndDate,
    candidateLocations,
    candidateLocationsData,
    selectedCountriesDisplay,
    candidateStatusCount,
    recruiterStatusCount,
    searchedCandidateNameOnDetails,
    filterIconClicked,
    additionalQuestionsType,
    organizationType,
    relevancyScoreCounts,
    candidateid,
    enableScoreSettingUpdate,
    changeCandidateIndex = 0,
  } = props;
  const { t } = useTranslation();
  const [videoCache, setVideoCache] = useState(null);  //eslint-disable-line
  const [candidateQuestions, setCandidateQuestions] = useState(null);   //eslint-disable-line
  const [showAssessmentLimitModal, setShowAssessmentLimitModal] = useState(false);
  const [openScoreSettings, setOpenScoreSettings] = useState(false);
  const [candidateSearchNameForShare, setCandidateSearchNameForShare] = useState('');
  const [openVideoScoringFilter, setOpenVideoScoringFilter] = useState(false);
  const [subscriptionLimit] = useState(OrganizationStore.getState().subscriptionLimits);
  const [iconView, setIconView] = useState(searchIcon);  //eslint-disable-line
  const { assessment_per_interview: assessmentsPerInterview } = subscriptionLimit;
  const { workmapScoreEnabled, resumeScoreEnabled, videoScoreEnabled } = enabledScoreCategory;
  const [showDeletedCandidateModal, setDeletedCandidateModal] = useState(false);
  const [openCommunicationScoringFilter, setOpenCommunicationScoringFilter] = useState(false);
  const [openSociabilityScoringFilter, setOpenSociabilityScoringFilter] = useState(false);
  const [openProfessionalismScoringFilter, setProfessionalismScoringFilter] = useState(false);
  const [openPositiveAttitudeScoringFilter, setOpenPositiveAttitudeScoringFilter] = useState(false);
  const [openFiltersSection, setOpenFilterSection] = useState(false);

  const handleSearchTextChange = (e) => {
    if (!searchedIconClickedOnDetails) {
      CandidateActions.setSearchedCandidateListingText(e.target.value);
    }
    CandidateActions.setSearchedCandidateNameOnDetails(e.target.value);
  };

  const handleShareCandidateSearch = (e) => {
    setCandidateSearchNameForShare(e.target.value);
  };

  useEffect(() => {
    if (parentComponent === 'shareCandidatesScreenView') {
      // Need to refactor the code
      const receivedVideoCache = ShareStore.getState().videoCache;
      const receivedCandidateQuestions = ShareStore.getState().candidateQuestions;
      setVideoCache(receivedVideoCache);
      setCandidateQuestions(receivedCandidateQuestions);
    } else {
      const receivedVideoCache = CandidateStore.getState().videoCache;
      const receivedCandidateQuestions = CandidateStore.getState().candidateQuestions;
      setVideoCache(receivedVideoCache);
      setCandidateQuestions(receivedCandidateQuestions);
    }
  }, []);

  if (parentComponent !== 'shareCandidatesScreenView') {
    useEffect(() => {
      setCandidateQuestions(CandidateStore.getState().candidateQuestions);
    }, [CandidateStore.getState().candidateQuestions]);
  }

  const handleClickOnScoreSetting = () => {
    setOpenScoreSettings(!openScoreSettings);
  };

  const handleOpenVideoScoringfilter = () => {
    setOpenVideoScoringFilter(!openVideoScoringFilter);
  };

  const handleOpenFilterSection = () => {
    setOpenFilterSection(!openFiltersSection);
  };


  // const getVideoUrl = async (candidateIndex, questionIndex) => {
  //   const { questions } = candidateQuestions && candidateQuestions.find(
  //     data => data.candidateId === displayData[candidateIndex].candidateid,
  //   );

  //   console.log('questions for this selected candidate is....', questions);

  //   if (parentComponent === 'shareCandidatesScreenView') {
  //     try {
  //       await ShareActions.getVideoUrls(
  //         interviewId,
  //         candidateData,
  //         questionIndex,
  //         questions,
  //         videoCache,
  //       );
  //     } catch (e) {
  //       console.log('Error :', e);
  //       alertToast(e.message);
  //     }
  //   } else {
  //     await CandidateActions.getVideoUrl(
  //       interviewId,
  //       candidateIndex,
  //       questionIndex,
  //       displayData,
  //       questions,
  //       videoCache,
  //     );
  //   }
  // };

  const handleCloseGeneralModal = (flag) => {
    setDeletedCandidateModal(flag);
  };

  const onCandidateClick = async (candidateId) => {
    const index = displayData.findIndex(
      candidate => candidate.candidateid === candidateId,
    );

    if (parentComponent === 'shareCandidatesScreenView') {
      console.log('Share shortlist clicked');
      changeCandidateIndex(candidateId);
      await ShareActions.updateIndex({
        selectedCandidatesIndex: index,
        selectedQuestionIndex: 0, // reset question index to 0
      });
    } else {
      if (displayData[index].status === 'Deleted') {
        setDeletedCandidateModal(true);
        return;
      }
      // Update index & get Calendly eligibility
      await CandidateActions.getCandidateDetail(
        interviewId,
        candidateId,
        true,
        '',
        true,
      );
    }
    // await getVideoUrl(index, 0);
  };

  const handleSearchClick = async (candidatesearch) => {
    // handle the search click including calling api
    CandidateActions.setSearchedIconClickedOnDetails(true);
    if (filterIconClicked) {
      await CandidateActions.getSearchedCandidatesListing(
        interviewId,
        1,
        10,
        candidatesearch,
        false,
        selectedCountries,
        selectCandidateStatusToFilter,
        selectRecruiterStatusToFilter,
        filterStartDate,
        filterEndDate,
        introQuestionDpObject,
        false,
        additionalQuestionsType,
      );
    } else {
      await CandidateActions.getSearchedCandidatesListing(
        interviewId,
        1,
        10,
        candidatesearch,
        false,
        [],
        [],
        [],
        null,
        null,
        {},
        false,
        additionalQuestionsType,
      );
    }
    changePagination();
  };

  const handleKeyDown = async (event, candidatesearch) => {
    if (event.key === 'Enter') {
      CandidateActions.setSearchedIconClickedOnDetails(true);
      if (filterIconClicked) {
        await CandidateActions.getSearchedCandidatesListing(
          interviewId,
          1,
          10,
          candidatesearch,
          false,
          selectedCountries,
          selectCandidateStatusToFilter,
          selectRecruiterStatusToFilter,
          filterStartDate,
          filterEndDate,
          introQuestionDpObject,
          false,
          additionalQuestionsType,
        );
      } else {
        await CandidateActions.getSearchedCandidatesListing(
          interviewId,
          1,
          10,
          candidatesearch,
          false,
          [],
          [],
          [],
          null,
          null,
          {},
          false,
          additionalQuestionsType,
        );
      }
      changePagination();
    }
  };

  const handleResetClick = async () => {
    // handle the reset click including calling api
    CandidateActions.setSearchedIconClicked(false);
    CandidateActions.setSearchedCandidateNameOnDetails('');
    await CandidateActions.getSearchedCandidatesListing(
      interviewId,
      startIdx,
      endIdx,
      '',
      true,
    );
    CandidateActions.setCurrentPage(endIdx / 10);
  };

  const scrollOnTop = () => {
    // if (this.topRef && this.topRef.current) {
    //   this.topRef.current.scrollIntoView();
    // }
  };

  const resetScoreSettings = async () => {
    CandidateActions.resetScoreSettings();
  };

  const handleSaveScore = async () => {
    const scoreParamters = {};
    scoreParamters.resumeScore = scoreSettingWeight.resumeScoreSettingWeight;
    scoreParamters.videoScore = scoreSettingWeight.videoScoreSettingWeight;
    scoreParamters.workmapScore = scoreSettingWeight.workmapScoreSettingWeight;
    scoreParamters.videoScoreBreakdown = {
      contentRelevancy: Number(candidateFilters.videoContentRelevancyValue),
      professionalism: Number(candidateFilters.videoProfessionalismValue),
      'positive attitude': candidateFilters.videoPositiveAttitudeValue,
      communication: candidateFilters.videoCommunicationValue,
      sociability: candidateFilters.videoSociabilityValue,
    };

    scoreParamters.communicationBreakdown = {
      paceAndClarity: candidateFilters.communicationPaceAndClarity,
      articulation: candidateFilters.communicationArticulation,
      sentiments: candidateFilters.communicationSentiments,
    };

    if (clientConfig
      .default.AIA_ORG_ID.includes(orgId)) {
      scoreParamters.communicationBreakdown.sentiments = candidateFilters.communicationSentiments;
    } else {
      scoreParamters
        .communicationBreakdown
        .grammarScore = candidateFilters.communicationGrammarScore;
    }

    scoreParamters.sociabilityBreakdown = {
      sentiments: candidateFilters.sociabilitySentiments,
      energyLevel: candidateFilters.sociabilityEnergyLevel,
      emotion: candidateFilters.sociabilityEmotion,
    };

    scoreParamters.professionalismBreakdown = {
      dressing: candidateFilters.professionalismDressing,
      grooming: candidateFilters.professionalismGrooming,
      bodyLanguage: candidateFilters.professionalismBodyLanguage,
    };

    CandidateActions.saveScoreWeightage(scoreParamters, interviewId, false);
    CandidateActions.handleSaveScoreSettings();
    CandidateActions.sortCandidates({ key: 'totalScoreSetting', isSameSortOrder: true });
    await CandidateActions.updateIndex({
      selectedCandidatesIndex: 0,
      selectedQuestionIndex: 0, // reset question index to 0
    });
    CandidateActions.setStarredProfile();

    const {
      interviewname,
    } = interviewDetail;
    const userEmail = Auth.getProfileInfo('email');
    const userName = `${Auth.getProfileInfo('firstName')} ${Auth.getProfileInfo('lastName')}`;
    mxTrackEvent('Stack ranking parameters', {
      userName,
      userEmail,
      interviewName: interviewname,
      resumeScore: scoreParamters.resumeScore,
      videoScore: scoreParamters.videoScore,
      workmapScore: scoreParamters.workmapScore,
    });
    // close AI video score dropdown
    setOpenVideoScoringFilter(false);
    // close score settings component
    setOpenScoreSettings(false);
    // api to call overall score
    if (enableScoreSettingUpdate) {
      await CandidateActions.updateOverviewScore(scoreParamters, interviewId, false);
      // load the new candidate listing
      await CandidateActions.getCandidateDetail(
        interviewId,
        candidateid,
        false,
        '',
        false,
      );
    }
    await CandidateActions.getCandidatesListing(
      interviewId,
      '',
      1,
      10,
    );
  };

  const scoreSettingsPopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.scoreSettingsInfo')}
    </Popover>
  );

  const deletedCandidatePopoverClick = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.deleteCandidateInfo')}
    </Popover>
  );

  const handleScoreSettingColor = () => {
    const totalScore = Number(totalScoreSettings.toFixed(0));
    if (totalScore === 100) {
      return '#40C057';
    } else if (totalScore < 100) {      //eslint-disable-line
      return '#d1d100';
    } else {
      return 'red';
    }
  };

  const handleFilterButtonOnDetails = () => {
    setOpenFilterSection(false);
    handleFilterButton();
  };

  const handleFilterResetOnDetails = () => {
    setOpenFilterSection(false);
    handleFilterReset();
  };

  const renderCandidates = (candidate, progressClass) => (
    <div className="position-relative">
      <ListGroupItem
        key={candidate.candidateid}
        className={classnames(`
${candidate.viewed
            ? 'clearfix list-group-item-white-bg'
            : 'clearfix'
          }
${candidate.hidden && styles.itemHidden},
${(candidateData.candidateid === candidate.candidateid)
          && styles.activeTab}
${candidate.status === 'Deleted' ? 'deletedContainer' : ''}
`)}
        onClick={async () => {
          await onCandidateClick(candidate.candidateid);
        }}
        style={{
          background: candidate.viewed && '#F8F9FA',
          margin: '0.7rem 0rem',
          border: candidate.viewed ? '1px solid #F1F3F5' : '0',
          borderRadius: '12px',
        }}
      >
        <Col
          xs={collapseList ? 12 : 2}
          className="no-padding text-center blurred"
        >
          {candidate.img ? (
            <div className={styles.staredProfile}>
              {
                candidate.isStartedProfile
                && organizationType
                && organizationType === 'Enterprise'
                && (
                  <div className={styles.staredIconSection}>
                    <StarredIcon />
                  </div>
                )
              }
              <img
                src={candidate.img}
                alt={candidate.name}
                className={`${'App-content-candidates-list-div--thumbnail'} ${styles.candidate_thumbnail
                  } ${candidateData.candidateid === candidate.candidateid ? styles.active_thumbnail : ''}`}
              />
            </div>
          ) : (
            <div className={styles.staredProfile}>
              {
                candidate.isStartedProfile
                && organizationType
                && organizationType === 'Enterprise' && (
                  <div className={styles.staredIconSection}>
                    <StarredIcon />
                  </div>
                )
              }
              <img
                // className={styles.candidate_thumbnail}
                src={ThumbnailPlaceholder}
                alt="thumbnail placeholder"
                className={`${'App-content-candidates-list-div--thumbnail'} ${styles.candidate_thumbnail
                  } ${candidateData.candidateid === candidate.candidateid ? styles.active_thumbnail : ''}`}
              />
            </div>
          )}
        </Col>
        {!collapseList && (
          <Col
            className={classnames(styles.nonDisplay, candidate.status === 'Deleted' ? 'deletedContainer' : '')}
            xs={10}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div className="App-content-candidates-list-div--name">
                  <span className="blurred">
                    {candidate.name}
                  </span>

                  {
                    candidate.status === 'Deleted' && (
                      <OverlayTrigger
                        trigger={['hover', 'focus']}
                        rootClose
                        placement="right"
                        overlay={deletedCandidatePopoverClick}
                      >
                        <button type="button" id="test"><img src={infoIcon} alt="video score tooltip" /></button>
                      </OverlayTrigger>

                    )
                  }
                </div>
                {
                  parentComponent !== 'shareCandidatesScreenView' && !candidate.viewed && (
                    <div className={styles.newCandidate}>
                      {t('candidateDetails.new')}
                    </div>
                  )
                }
              </div>
              <span>
                {`${Math.round(candidate.totalScore, 2)}%`}
              </span>
            </div>
            <div className="App-content-candidates-list-div--progress">
              <ProgressBar
                bsStyle={progressClass}
                now={candidate.totalScore}
              />
            </div>
          </Col>
        )}
      </ListGroupItem>

      {candidate.hidden && (
        <div className={styles.itemHiddenContainer}>
          <button
            type="button"
            className={styles.warningButton}
            onClick={() => setShowAssessmentLimitModal(true)}
          >
            {t('candidateDetails.unlockCandidate')}
          </button>
        </div>
      )}

    </div>
  );

  return (
    <div className={styles.CandidateListWidgetContainer}>
      <div>
        <div className={styles.stickyHeader}>
          {
            parentComponent !== 'shareCandidatesScreenView' && (
              <LinkContainer to={`/interviews/edit/${interviewId}/candidates`}>
                <button
                  type="button"
                  className="link"
                >
                  <Glyphicon glyph="chevron-left" />
                  <span className="ml-2">{t('back')}</span>
                </button>
              </LinkContainer>
            )
          }
          <div className={styles.filterButtonSection}>
            <h4>
              <span className={styles.nonDisplay}>
                {t('candidateDetails.all')}
                &nbsp;
              </span>
              <span>
                <button
                  type="button"
                  onClick={() => window.history.back()}
                >
                  <img
                    alt="Go back"
                    className={styles.backArrow}
                    src={backArrow}
                  />
                </button>
              </span>
              {
                isCoachingUser()
                  ? t('candidateDetails.applicants')
                  : t('candidateDetails.candidates')
              }
            </h4>
            {
              parentComponent !== 'shareCandidatesScreenView' && (
                <button
                  className={
                    openFiltersSection
                      ? styles.activeCandidateFilterButton
                      : styles.candidateFilterButton
                  }
                  onClick={handleOpenFilterSection}
                  type="button"
                >
                  <FilterButton stroke={openFiltersSection ? '#6196FE' : 'white'} />
                  <span>
                    Filter
                  </span>
                </button>
              )
            }
          </div>
          {
            (parentComponent === 'shareCandidatesScreenView')
              ? (
                <div className={styles.candidate_searchbox}>
                  <input
                    type="text"
                    placeholder="Search candidates"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={iconView === searchIcon
                      ? styles.nonDisplayMob
                      : styles.search_input_mobile}
                    value={candidateSearchNameForShare}
                    onChange={handleShareCandidateSearch}
                  />
                  <button
                    type="button"
                    onClick={() => handleSearchClick()}
                  >
                    <img
                      alt="search candidate"
                      className={styles.searchIcon}
                      src={iconView}
                    />
                  </button>
                  <InputGroup
                    className={`${styles.mb - 3} ${styles.candidate_search}`}
                  >
                    <FormControl
                      placeholder="Search candidates"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className={styles.search_input}
                      value={candidateSearchNameForShare}
                      onChange={handleShareCandidateSearch}
                    />
                  </InputGroup>
                  {
                    parentComponent !== 'shareCandidatesScreenView' && (
                      <ScoreFilter
                        interviewId={interviewId}
                        interviewDetail={interviewDetail}
                        className={styles.filterButton}
                        importedIn="candidateDetail"
                      />
                    )
                  }
                </div>
              ) : (
                <div className={styles.candidate_searchbox}>
                  <input
                    type="text"
                    placeholder={
                      isCoachingUser()
                        ? t('candidateDetails.searchApplicants')
                        : t('candidateDetails.searchCandidates')
                    }
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className={iconView === searchIcon
                      ? styles.nonDisplayMob
                      : styles.search_input_mobile}
                    value={searchedCandidateNameOnDetails}
                    onChange={handleSearchTextChange}
                  />
                  <InputGroup
                    className={`${styles.mb - 3} ${styles.candidate_search}`}
                  >
                    <FormControl
                      placeholder={
                        isCoachingUser()
                          ? t('candidateDetails.searchApplicants')
                          : t('candidateDetails.searchCandidates')
                      }
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className={styles.search_input}
                      value={searchedCandidateNameOnDetails}
                      onChange={handleSearchTextChange}
                      onKeyDown={e => handleKeyDown(e, searchedCandidateNameOnDetails)}
                    />
                  </InputGroup>
                  <button
                    type="button"
                    onClick={() => handleSearchClick(searchedCandidateNameOnDetails)}
                  >
                    <img
                      alt="search candidate"
                      className={styles.searchIcon}
                      src={iconView}
                    />
                  </button>
                  <button
                    type="button"
                    onClick={e => handleResetClick(e)}
                    style={{
                      marginLeft: '0.5rem',
                      display: 'flex',
                    }}
                  >
                    <ResetIcon stroke="#a5aac2" />
                  </button>
                </div>
              )
          }
          {
            (parentComponent !== 'shareCandidatesScreenView')
            && !isCoachingUser()
            && showScoreSettings
            && (
              <div className={styles.scoreSettingParent}>
                <div         //eslint-disable-line
                  className={styles.scoreSettingContainer}
                  onClick={handleClickOnScoreSetting}
                  tabIndex={0}
                  role="button"
                  type="button"
                >
                  <div className={styles.scoreSettingSection}>
                    <div className={styles.scoreSettingIcon}>
                      <ScoreSettingImg />
                    </div>
                    <div className={styles.settingsTextSection}>
                      <div className={styles.scoreSettingText}>
                        {t('candidateDetails.scoreSettings')}
                      </div>
                      <div className={styles.scoreSettingInfoIcon}>
                        <OverlayTrigger
                          trigger={['hover', 'focus']}
                          rootClose
                          placement="right"
                          overlay={scoreSettingsPopover}
                        >
                          <span
                            className="glyphicon glyphicon-info-sign"
                            style={{
                              marginLeft: '5px',
                            }}
                          />
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                  <div className={styles.totalScoreSettingsContainer}>
                    <div className={styles.totalScoreSettingsSection}>
                      <span
                        style={{
                          color: handleScoreSettingColor(),
                          fontSize: '1.17em',

                        }}
                      >
                        {`${totalScoreSettings.toFixed(0)}`}
                      </span>
                      /100
                    </div>
                    <div className={styles.dropdownIcon}>
                      {
                        openScoreSettings
                          ? <ArrowUp />
                          : <ArrowDown />
                      }
                    </div>
                  </div>
                </div>
                {
                  openScoreSettings && (
                    <div className={styles.filterSection}>
                      {
                        resumeScoreEnabled && (
                          <div className={styles.resumeScoringSection}>
                            <div className={styles.scoringHeading}>
                              <div className={styles.scoringText}>
                                {t('candidateDetails.resumeScore')}
                              </div>
                              <div className={styles.scoringPercentageSection}>
                                <div className={styles.scoringIcon}>
                                  <ClipboardIcon />
                                </div>
                                <div className={styles.scoringValue}>
                                  {scoreSettingWeight.resumeScoreSettingWeight.toFixed(0)}
                                  %
                                </div>
                              </div>
                            </div>
                            <div>
                              <Slider
                                min={0}
                                max={100}
                                value={scoreSettingWeight.resumeScoreSettingWeight}
                                onChange={CandidateActions.changeResumeScoreWeightage}
                                trackStyle={{
                                  backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0),rgba(97, 150, 254, 1)',
                                }}
                                handleStyle={{
                                  backgroundColor: '#FFFFFF',
                                  border: '4px solid #6196FE',
                                }}
                                step={10}
                              />
                            </div>
                          </div>
                        )
                      }
                      {
                        workmapScoreEnabled && (
                          <div className={styles.resumeScoringSection}>
                            <div className={styles.scoringHeading}>
                              <div className={styles.scoringText}>
                                {t('candidateDetails.workmapScore')}
                              </div>
                              <div className={styles.scoringPercentageSection}>
                                <div className={styles.scoringIcon}>
                                  <ClipboardIcon />
                                </div>
                                <div className={styles.scoringValue}>
                                  {scoreSettingWeight.workmapScoreSettingWeight.toFixed(0)}
                                  %
                                </div>
                              </div>
                            </div>
                            <div>
                              <Slider
                                min={0}
                                max={100}
                                step={10}
                                value={scoreSettingWeight.workmapScoreSettingWeight}
                                onChange={CandidateActions.changeWorkmapScoreWeightage}
                                trackStyle={{
                                  backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0),rgba(97, 150, 254, 1)',
                                }}
                                handleStyle={{
                                  backgroundColor: '#FFFFFF',
                                  border: '4px solid #6196FE',
                                }}
                              />
                            </div>
                          </div>
                        )
                      }
                      {
                        videoScoreEnabled && (
                          <div className={styles.resumeScoringSection}>
                            <div
                              className={styles.scoringHeading}
                              onClick={handleOpenVideoScoringfilter}
                              onKeyDown={handleOpenVideoScoringfilter}
                              role="button"
                              type="button"
                              tabIndex={0}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  gap: '0.8rem',
                                }}
                              >
                                <div className={styles.scoringText}>
                                  {t('candidateDetails.aiVideoScore')}
                                </div>
                                {
                                  organizationType && organizationType === 'Enterprise' && (
                                    <div className={styles.dropdownIcon}>
                                      {
                                        openVideoScoringFilter
                                          ? <ArrowUp />
                                          : <ArrowDown />
                                      }
                                    </div>
                                  )
                                }
                              </div>
                              <div className={styles.scoringPercentageSection}>
                                <div className={styles.scoringIcon}>
                                  <ClipboardIcon />
                                </div>
                                <div className={styles.scoringValue}>
                                  {scoreSettingWeight.videoScoreSettingWeight.toFixed(0)}
                                  %
                                </div>
                              </div>
                            </div>
                            <div>
                              <Slider
                                min={0}
                                max={100}
                                value={scoreSettingWeight.videoScoreSettingWeight}
                                onChange={CandidateActions.changeVideoScoreWeightage}
                                trackStyle={{
                                  backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0),rgba(97, 150, 254, 1)',
                                }}
                                handleStyle={{
                                  backgroundColor: '#FFFFFF',
                                  border: '4px solid #6196FE',
                                }}
                                step={10}
                              />
                              {
                                openVideoScoringFilter
                                && organizationType && organizationType === 'Enterprise' && (
                                  <div className={styles.videoScoringFilterSection}>
                                    <div className={styles.videoContentRelevancy}>
                                      <div className={styles.videoBreakdowntext}>
                                        <div className={styles.deepBreakdownParentLabel}>
                                          Content Relevancy
                                        </div>
                                        <div>
                                          {candidateFilters.videoContentRelevancyValue}
                                          %
                                        </div>
                                      </div>
                                      <div className={styles.videoBreakdownSlider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          value={candidateFilters.videoContentRelevancyValue}
                                          onChange={CandidateActions
                                            .changeVideoContentRelevancyValue}
                                          trackStyle={{
                                            backgroundImage:
                                              'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                          }}
                                          handleStyle={{
                                            backgroundColor: '#FFFFFF',
                                            border: '4px solid #6196FE',
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className={styles.videoProfessionalismSection}>
                                      <div className={styles.videoBreakdowntext}>
                                        <div className={styles.deepBreakdownParentLabel}>
                                          {t('candidateDetails.professionalism')}
                                          {
                                            clientConfig
                                              .default
                                              .DEEP_STACK_RANKING
                                              .includes(orgId) && (
                                              <div
                                                className={styles.dropdownIcon}
                                                tabIndex={0}
                                                role="button"
                                                onKeyDown={
                                                  () => setProfessionalismScoringFilter(
                                                    !openProfessionalismScoringFilter,
                                                  )
                                                }
                                                onClick={
                                                  () => setProfessionalismScoringFilter(
                                                    !openProfessionalismScoringFilter,
                                                  )
                                                }
                                              >
                                                {
                                                  openProfessionalismScoringFilter
                                                    ? <ArrowUp />
                                                    : <ArrowDown />
                                                }
                                              </div>
                                            )
                                          }
                                        </div>
                                        <div>
                                          {candidateFilters.videoProfessionalismValue}
                                          %
                                        </div>
                                      </div>
                                      <div className={styles.videoBreakdownSlider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          value={candidateFilters.videoProfessionalismValue}
                                          onChange={CandidateActions
                                            .changeVideoProfessionalismValue}
                                          trackStyle={{
                                            backgroundImage:
                                              'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                          }}
                                          handleStyle={{
                                            backgroundColor: '#FFFFFF',
                                            border: '4px solid #6196FE',
                                          }}
                                        />


                                        {/* Professionalism Deep Filter starts */}
                                        {
                                          openProfessionalismScoringFilter && (
                                            <div className={styles.aiSubBreakdownContainer}>
                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.dressing')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.professionalismDressing}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .professionalismDressing
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeProfessionalismDressingValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.grooming')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.professionalismGrooming}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .professionalismGrooming
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeProfessionalismGroomingValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.bodyLanguage')}
                                                    </p>
                                                    <p>
                                                      {
                                                        candidateFilters
                                                          .professionalismBodyLanguage
                                                      }
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .professionalismBodyLanguage
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeProfessionalismBodyLanguageValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                            </div>
                                          )
                                        }
                                        {/* Professionalism Deep Filter ends */}
                                      </div>

                                    </div>
                                    <div className={styles.videoPositiveAttitudeSection}>
                                      <div className={styles.videoBreakdowntext}>
                                        <div className={styles.deepBreakdownParentLabel}>
                                          {t('candidateDetails.positiveAttitude')}
                                          {
                                            clientConfig
                                              .default
                                              .DEEP_STACK_RANKING
                                              .includes(orgId) && (
                                              <div
                                                className={styles.dropdownIcon}
                                                role="button"
                                                tabIndex={0}
                                                onKeyDown={
                                                  () => setOpenPositiveAttitudeScoringFilter(
                                                    !openPositiveAttitudeScoringFilter,
                                                  )
                                                }
                                                onClick={
                                                  () => setOpenPositiveAttitudeScoringFilter(
                                                    !openPositiveAttitudeScoringFilter,
                                                  )
                                                }
                                              >
                                                {
                                                  openPositiveAttitudeScoringFilter
                                                    ? <ArrowUp />
                                                    : <ArrowDown />
                                                }
                                              </div>
                                            )
                                          }
                                        </div>
                                        <div>
                                          {candidateFilters.videoPositiveAttitudeValue}
                                          %
                                        </div>
                                      </div>
                                      <div className={styles.videoBreakdownSlider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          value={candidateFilters.videoPositiveAttitudeValue}
                                          onChange={CandidateActions
                                            .changeVideoPositiveAttitudeValue}
                                          trackStyle={{
                                            backgroundImage:
                                              'linear-gradient(to right,rgba(97, 150, 254, 0), rgba(97, 150, 254, 1)',
                                          }}
                                          handleStyle={{
                                            backgroundColor: '#FFFFFF',
                                            border: '4px solid #6196FE',
                                          }}
                                        />

                                        {/* Positive attitude Deep Filter starts */}
                                        {
                                          openPositiveAttitudeScoringFilter && (
                                            <div className={styles.aiSubBreakdownContainer}>
                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.energyLevel')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.videoPositiveAttitudeValue}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .videoPositiveAttitudeValue
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeVideoPositiveAttitudeValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          )
                                        }
                                        {/* Positive attitude Deep Filter ends */}
                                      </div>
                                    </div>
                                    <div className={styles.videoCommunicationSection}>
                                      <div className={styles.videoBreakdowntext}>
                                        <div className={styles.deepBreakdownParentLabel}>
                                          {t('candidateDetails.communication')}
                                          {
                                            clientConfig
                                              .default
                                              .DEEP_STACK_RANKING
                                              .includes(orgId) && (
                                              <div
                                                className={styles.dropdownIcon}
                                                role="button"
                                                tabIndex={0}
                                                onKeyDown={
                                                  () => setOpenCommunicationScoringFilter(
                                                    !openCommunicationScoringFilter,
                                                  )
                                                }
                                                onClick={
                                                  () => setOpenCommunicationScoringFilter(
                                                    !openCommunicationScoringFilter,
                                                  )
                                                }
                                              >
                                                {
                                                  openCommunicationScoringFilter
                                                    ? <ArrowUp />
                                                    : <ArrowDown />
                                                }
                                              </div>
                                            )
                                          }
                                        </div>
                                        <div>
                                          {candidateFilters.videoCommunicationValue}
                                          %
                                        </div>
                                      </div>
                                      <div className={styles.videoBreakdownSlider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          value={candidateFilters.videoCommunicationValue}
                                          onChange={CandidateActions.changeVideoCommunicationValue}
                                          trackStyle={{
                                            backgroundImage:
                                              'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                          }}
                                          handleStyle={{
                                            backgroundColor: '#FFFFFF',
                                            border: '4px solid #6196FE',
                                          }}
                                        />
                                        {/* Communication Deep Filter starts */}
                                        {
                                          openCommunicationScoringFilter && (
                                            <div className={styles.aiSubBreakdownContainer}>
                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.paceAndClarityOfSpeech')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.communicationPaceAndClarity}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .communicationPaceAndClarity
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeVideoPaceAndClarityValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.articulation')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.communicationArticulation}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .communicationArticulation
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeVideoArticulationValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>

                                                    {
                                                      clientConfig
                                                        .default.AIA_ORG_ID.includes(orgId) ? (
                                                          <>
                                                            <p>
                                                              {t('candidateDetails.sentiments')}
                                                            </p>
                                                            <p>
                                                              {
                                                              candidateFilters
                                                                .communicationSentiments
                                                            }
                                                              %
                                                            </p>
                                                          </>
                                                      ) : (
                                                        <>
                                                          <p>
                                                            {t('candidateDetails.grammarScore')}
                                                          </p>
                                                          <p>
                                                            {
                                                              candidateFilters
                                                                .communicationGrammarScore
                                                            }
                                                            %
                                                          </p>
                                                        </>
                                                      )
                                                    }

                                                  </div>
                                                </div>
                                                {
                                                  clientConfig
                                                    .default.AIA_ORG_ID.includes(orgId) ? (
                                                      <Slider
                                                        min={0}
                                                        max={100}
                                                        value={
                                                        candidateFilters
                                                          .communicationSentimentsScore
                                                      }
                                                        onChange={
                                                        CandidateActions
                                                          .changeVideoSentimentsValue
                                                      }
                                                        trackStyle={{
                                                        backgroundImage:
                                                          'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                      }}
                                                        handleStyle={{
                                                        backgroundColor: '#FFFFFF',
                                                        border: '4px solid #6196FE',
                                                      }}
                                                      />
                                                  ) : (
                                                    <Slider
                                                      min={0}
                                                      max={100}
                                                      value={
                                                        candidateFilters
                                                          .communicationGrammarScore
                                                      }
                                                      onChange={
                                                        CandidateActions
                                                          .changeVideoGrammarValue
                                                      }
                                                      trackStyle={{
                                                        backgroundImage:
                                                          'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                      }}
                                                      handleStyle={{
                                                        backgroundColor: '#FFFFFF',
                                                        border: '4px solid #6196FE',
                                                      }}
                                                    />


                                                  )
                                                }
                                              </div>

                                            </div>
                                          )
                                        }
                                        {/* Communication Deep Filter ends */}

                                      </div>
                                    </div>
                                    <div className={styles.videoSociabilitySection}>
                                      <div className={styles.videoBreakdowntext}>
                                        <div className={styles.deepBreakdownParentLabel}>
                                          {t('candidateDetails.sociability')}
                                          {
                                            clientConfig
                                              .default
                                              .DEEP_STACK_RANKING
                                              .includes(orgId) && (
                                              <div
                                                role="button"
                                                tabIndex={0}
                                                onKeyDown={
                                                  () => setOpenSociabilityScoringFilter(
                                                    !openSociabilityScoringFilter,
                                                  )
                                                }
                                                className={styles.dropdownIcon}
                                                onClick={
                                                  () => setOpenSociabilityScoringFilter(
                                                    !openSociabilityScoringFilter,
                                                  )
                                                }
                                              >
                                                {
                                                  openSociabilityScoringFilter
                                                    ? <ArrowUp />
                                                    : <ArrowDown />
                                                }
                                              </div>
                                            )
                                          }
                                        </div>
                                        {
                                          console.log('candidateFilters for socialbility >>>>', candidateFilters)
                                        }
                                        <div>
                                          {candidateFilters.videoSociabilityValue}
                                          %
                                        </div>
                                      </div>
                                      <div className={styles.videoBreakdownSlider}>
                                        <Slider
                                          min={0}
                                          max={100}
                                          value={candidateFilters.videoSociabilityValue}
                                          onChange={CandidateActions.changeVideoSociabilityValue}
                                          trackStyle={{
                                            backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254, 0),rgba(97, 150, 254,1)',
                                          }}
                                          handleStyle={{
                                            backgroundColor: '#FFFFFF',
                                            border: '4px solid #6196FE',
                                          }}
                                        />
                                      </div>

                                      <div>
                                        {/* Sociablity Deep Filter starts */}
                                        {
                                          openSociabilityScoringFilter && (
                                            <div className={styles.aiSubBreakdownContainer}>
                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.emotion')}
                                                    </p>
                                                    <p>
                                                      {
                                                        candidateFilters
                                                          .sociabilityEmotion
                                                      }
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .sociabilityEmotion
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeSociabilityEmotionValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.energyLevel')}
                                                    </p>
                                                    <p>
                                                      {candidateFilters.sociabilityEnergyLevel}
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={candidateFilters.sociabilityEnergyLevel}
                                                  onChange={
                                                    CandidateActions
                                                      .changeSociabilityEnergyValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                              <div className={styles.subBreakDown}>
                                                <div className={styles.scoringText}>
                                                  <div className={styles.deepBreakDownScoringText}>
                                                    <p>
                                                      {t('candidateDetails.sentiments')}
                                                    </p>
                                                    <p>
                                                      {
                                                        candidateFilters
                                                          .sociabilitySentiments
                                                      }
                                                      %
                                                    </p>
                                                  </div>
                                                </div>
                                                <Slider
                                                  min={0}
                                                  max={100}
                                                  value={
                                                    candidateFilters
                                                      .sociabilitySentiments
                                                  }
                                                  onChange={
                                                    CandidateActions
                                                      .changeSociabilitySentimentValue
                                                  }
                                                  trackStyle={{
                                                    backgroundImage:
                                                      'linear-gradient(to right,rgba(97, 150, 254, 0),rgba(97, 150, 254, 1)',
                                                  }}
                                                  handleStyle={{
                                                    backgroundColor: '#FFFFFF',
                                                    border: '4px solid #6196FE',
                                                  }}
                                                />
                                              </div>

                                            </div>
                                          )
                                        }
                                        {/* Sociablity Deep Filter ends */}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                      <div className={styles.scoreSettingsButtons}>
                        <button
                          className={styles.resetScoreSettingButton}
                          type="button"
                          onClick={() => resetScoreSettings()}
                        >
                          {t('candidateDetails.reset')}
                        </button>
                        <button
                          className={styles.saveScoreSettingButton}
                          type="button"
                          onClick={handleSaveScore}
                          disabled={Number(totalScoreSettings.toFixed(0)) !== 100}
                          style={{
                            background: Number(totalScoreSettings.toFixed(0)) !== 100
                              ? 'rgb(236 236 236)'
                              : '#6196FE',
                            color:
                              Number(totalScoreSettings.toFixed(0)) !== 100
                                ? '#a4a4a4'
                                : '#FFFFFF',
                          }}
                        >
                          {
                            isSavingScoreSettings
                              ? t('candidateDetails.saving')
                              : t('candidateDetails.save')
                          }
                        </button>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
        <>
          {
            openFiltersSection && parentComponent !== 'shareCandidatesScreenView'
              ? (
                <div style={{ marginTop: '7.5rem' }}>
                  <FilterCandidate
                    candidateFilters={candidateFilters}
                    countryList={countryList}
                    selectedCountries={selectedCountries}
                    selectCandidateStatusToFilter={selectCandidateStatusToFilter}
                    selectRecruiterStatusToFilter={selectRecruiterStatusToFilter}
                    additionalIntroQuestion={additionalIntroQuestion}
                    filterIntroDropdown={filterIntroDropdown}
                    introQuestionDpObject={introQuestionDpObject}
                    filterStartDate={filterStartDate}
                    filterEndDate={filterEndDate}
                    handleFilterButton={handleFilterButtonOnDetails}
                    handleFilterReset={handleFilterResetOnDetails}
                    candidateLocations={candidateLocations}
                    candidateLocationsData={candidateLocationsData}
                    selectedCountriesDisplay={selectedCountriesDisplay}
                    candidateStatusCount={candidateStatusCount}
                    recruiterStatusCount={recruiterStatusCount}
                    relevancyScoreCounts={relevancyScoreCounts}
                  />
                </div>
              )
              : (
                <>
                  {
                    fetchingNewCandidates
                      ? (
                        <div
                          className={styles.candidateListingSection}
                        >
                          <ClipLoadingComponent />
                        </div>
                      )
                      : (
                        <>
                          <div className={styles.candidate_list}>
                            {
                              isDataLoadingForCandWidgit
                                ? (
                                  <div className={styles.widgitLoader}>
                                    <ClipLoadingComponent />
                                  </div>
                                )
                                : (
                                  <>
                                    {
                                      parentComponent === 'shareCandidatesScreenView'
                                        ? (
                                          displayData
                                            .filter(
                                              candidate => candidate.name
                                                .toLowerCase()
                                                .search(candidateSearchNameForShare
                                                  .toLowerCase()) >= 0,
                                            ).map((candidate) => {
                                              const progressClass = clientConfig.default
                                                .HIGH_STANDARD_INTERVIEW_ID
                                                .includes(
                                                  interviewId,
                                                )
                                                ? getHighStandardProgressColorClass(candidate
                                                  .totalScore)
                                                : getProgressColorClass(candidate.totalScore);
                                              return (
                                                renderCandidates(candidate, progressClass)
                                              );
                                            })
                                        )
                                        : (
                                          displayData
                                            .map((candidate) => {
                                              const progressClass = clientConfig.default
                                                .HIGH_STANDARD_INTERVIEW_ID
                                                .includes(
                                                  interviewId,
                                                )
                                                ? getHighStandardProgressColorClass(candidate
                                                  .totalScore)
                                                : getProgressColorClass(candidate.totalScore);
                                              return (
                                                renderCandidates(candidate, progressClass)
                                              );
                                            })
                                        )
                                    }
                                  </>
                                )
                            }
                          </div>
                        </>
                      )
                  }
                </>
              )
          }
        </>
      </div>
      {
        parentComponent !== 'shareCandidatesScreenView'
        && totalCandidates
        && !openFiltersSection
        && (
          <PaginationNewWidgit
            defaultPageItems={10}
            totalItems={Number(totalCandidates)}
            onScrollOnTop={scrollOnTop}
            sortOrder="asc"
            handlePaginationClick={handlePaginationClick}
            activePage={currentPage}
            startIndex={!filterIconClicked ? startIdx : 1}
            endIndex={endIdx}
          />
        )
      }
      <AssessmentLimitModal
        numberOfAssessments={assessmentsPerInterview}
        show={showAssessmentLimitModal}
        onHide={() => setShowAssessmentLimitModal(false)}
      />
      <GeneralModal
        isModalOpen={showDeletedCandidateModal}
        message="The candidate has deleted their profile, so you can no longer access their information."
        title="Candidate Profile Deleted"
        type="info"
        handleCloseGeneralModal={handleCloseGeneralModal}
      />
    </div>
  );
};

CandidateListWidget.propTypes = {
  interviewId: PropTypes.string.isRequired,
  displayData: PropTypes.arrayOf(PropTypes.shape({
    candidateid: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired,
  parentComponent: PropTypes.string.isRequired,
  ThumbnailPlaceholder: PropTypes.string,
  interviewDetail: PropTypes.object.isRequired, // eslint-disable-line
  candidateData: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })).isRequired,
  collapseList: PropTypes.arrayOf(PropTypes.shape({
  })),
  candidateFilters: PropTypes.shape({
    videoProfessionalismValue: PropTypes.number.isRequired,
    videoContentRelevancyValue: PropTypes.number.isRequired,
    videoSociabilityValue: PropTypes.number.isRequired,
    videoCommunicationValue: PropTypes.number.isRequired,
    videoPositiveAttitudeValue: PropTypes.number.isRequired,
    sociabilitySentiments: PropTypes.number.isRequired,
    sociabilityEnergyLevel: PropTypes.number.isRequired,
    sociabilityEmotion: PropTypes.number.isRequired,
    communicationSentiments: PropTypes.number.isRequired,
    communicationArticulation: PropTypes.number.isRequired,
    communicationPaceAndClarity: PropTypes.number.isRequired,
    professionalismGrooming: PropTypes.number.isRequired,
    professionalismBodyLanguage: PropTypes.number.isRequired,
    professionalismDressing: PropTypes.number.isRequired,
    communicationGrammarScore: PropTypes.number.isRequired,
    communicationSentimentsScore: PropTypes.number.isRequired,
  }).isRequired,
  scoreSettingWeight: PropTypes.number.isRequired,
  showScoreSettings: PropTypes.bool.isRequired,
  totalScoreSettings: PropTypes.shape({
    toFixed: PropTypes.number,
  }).isRequired,
  enabledScoreCategory: PropTypes.shape({
    workmapScoreEnabled: PropTypes.bool,
    resumeScoreEnabled: PropTypes.bool,
    videoScoreEnabled: PropTypes.bool,
  }).isRequired,
  isSavingScoreSettings: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
  totalCandidates: PropTypes.number.isRequired,
  handlePaginationClick: PropTypes.func.isRequired,
  isDataLoadingForCandWidgit: PropTypes.bool.isRequired,
  currentPage: PropTypes.number.isRequired,
  fetchingNewCandidates: PropTypes.bool.isRequired,
  searchedIconClickedOnDetails: PropTypes.bool.isRequired,
  startIdx: PropTypes.number.isRequired,
  endIdx: PropTypes.number.isRequired,
  changePagination: PropTypes.func.isRequired,
  countryList: PropTypes.shape({}).isRequired,
  selectedCountries: PropTypes.shape({}).isRequired,
  selectCandidateStatusToFilter: PropTypes.shape({}).isRequired,
  selectRecruiterStatusToFilter: PropTypes.shape({}).isRequired,
  additionalIntroQuestion: PropTypes.shape({}).isRequired,
  filterIntroDropdown: PropTypes.shape({}).isRequired,
  introQuestionDpObject: PropTypes.shape({}).isRequired,
  handleFilterButton: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  filterStartDate: PropTypes.string.isRequired,
  filterEndDate: PropTypes.string.isRequired,
  candidateLocations: PropTypes.object.isRequired, // eslint-disable-line
  candidateLocationsData: PropTypes.object.isRequired, // eslint-disable-line
  selectedCountriesDisplay: PropTypes.shape({}).isRequired,
  candidateStatusCount: PropTypes.shape({}).isRequired,
  recruiterStatusCount: PropTypes.shape({}).isRequired,
  searchedCandidateNameOnDetails: PropTypes.string.isRequired,
  filterIconClicked: PropTypes.bool.isRequired,
  additionalQuestionsType: PropTypes.object.isRequired, //eslint-disable-line
  organizationType: PropTypes.string.isRequired,
  relevancyScoreCounts: PropTypes.shape({}).isRequired,
  candidateid: PropTypes.string.isRequired,
  enableScoreSettingUpdate: PropTypes.bool.isRequired,
  changeCandidateIndex: PropTypes.func.isRequired,
};

CandidateListWidget.defaultProps = {
  collapseList: {},
  ThumbnailPlaceholder: '',
};

export default CandidateListWidget;
