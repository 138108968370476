/* eslint no-use-before-define: 2 */
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import styles from '../assets/termsAndConditions.module.scss';
import { getUrlParams } from '../utils/utils';

const TermsAndConditions = () => {
  // eslint-disable-next-line
  const [termsChecked, setTermsChecked] = useState(false);
  const params = getUrlParams(window.location.search);

  return (
    <div
      className={
    isMobile
     ? styles.termsAndCondtionSectionForMobile
     : styles.termsAndCondtionSection
   }
    >
      <div
        className={isMobile ? styles.mainSectionForMobile : styles.mainSection}
      >
        <p
          className={
      isMobile ? styles.companyNameForMobile : styles.companyName
     }
        >
          Interviewer.AI
        </p>
        <p
          className={
      isMobile
       ? styles.termsConditionHeadingForMobile
       : styles.termsConditionHeading
     }
        >
          Terms and Conditions
        </p>
        <div className={isMobile ? styles.termsForMobile : styles.terms}>
          <ol>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Binding Agreement</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>1.1</span>
                {' '}
                &nbsp;Interviewer.AI Pte. Ltd. (Singapore UEN 201837827Z)
                (“Interviewer.AI”, “we”, “us”, and “our”) provides a platform
                that facilitates interactions between potential employers and
                job candidates. The terms of this Agreement are between you and
                Interviewer.AI, and your access and use of the Platform
                constitutes your consent to this Agreement and its terms.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>1.2</span>
                &nbsp;
                We may update the terms of this Agreement from time to time. We
                will notify you of such changes either via the email address
                provided by you on registration or through an announcement on
                the Platform. The changes will apply to the access and use of
                the Platform upon such notice. Your continued access and use of
                the Platform after such notice has been given indicates your
                agreement to be bound by the new terms.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Definitions</p>
              <p className={styles.termsDescription}>
                In this Agreement, the following definitions apply:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Candidate”</span>
                  &nbsp; means a user who creates a Candidate Profile for
                  accessing and using the Platform to search for employment
                  opportunities or to participate in an application for
                  employment with an Employer.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Candidate Profile”
                  </span>
                  &nbsp; means a profile created by a Candidate that may include
                  personal details, resume, photo, video, skills, and
                  professional interests.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Employer”</span>
                  &nbsp; means a user who is accessing or using the Platform for
                  any reason related to the purpose of seeking, screening or
                  assessing candidates for employment.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Intellectual Property Rights”
                  </span>
                  &nbsp; means all patents, trade names, trade marks, service
                  marks, logos, design rights, copyrights, trade secrets,
                  database rights, rights in technology, knowhow, or other
                  intellectual property rights (whether registered or
                  unregistered) that are protected under any applicable law, and
                  all applications, renewals and extensions of the same.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>
                    “Personal Data”
                  </span>
                  &nbsp; means data, whether true or not, about an individual
                  who can be identified (a) from that data; or (b) from that
                  data and other information to which we have or are likely to
                  have access.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“Platform”</span>
                  &nbsp; means the Interviewer.AI platform and any service or
                  feature provided thereon by Interviewer.AI or its affiliates
                  or agents, whether directly or indirectly, including through
                  application programming interface (API) integration and/or
                  hyperlinks.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“SIAC”</span>
         &nbsp; has the meaning ascribed to it in Clause 16.1.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“SIMC”</span>
         &nbsp; has the meaning ascribed to it in Clause 16.5.
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>“User Content”</span>
                  &nbsp; means all information, including but not limited to
                  data, text, videos, photographs, graphics, advertisements,
                  messages, music, or other materials submitted, posted,
                  displayed or otherwise provided by users on or through the
                  Platform, and includes job postings by Employers and Candidate
                  Profiles.
                </p>
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Registration</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.1</span>
                &nbsp; You must be at least 18 years of age to register an account on the
                Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.2</span>
                &nbsp; On registration, you will be asked to create a Candidate Profile.
                You must ensure that the details provided by you on registration
                or at any time thereafter are accurate, up to date, and
                complete.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.3</span>
                &nbsp;We reserve the right to delete your account and all of your
                information after a period of inactivity, but in any case only
                after we have provided you with notice accordingly.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>3.4</span>
                &nbsp; You may cancel your registration with the Platform at any time by
                informing us in writing. Upon cancellation of your registration,
                you agree to stop accessing and using the Platform.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Password and Security</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.1</span>
                &nbsp; You agree to keep your login details confidential and not to
                disclose it to any third party.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.2</span>
                &nbsp; You are strictly liable and responsible for all uses of your account
                and password, whether or not authorised by you.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.3</span>
                &nbsp; You shall notify us immediately if you know or suspect that your
                account has been compromised.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.4</span>
                &nbsp; If we have reason to believe that there is likely a breach of your
                account or misuse of the Platform using your account, we may
                require you to reset your password or may suspend your account.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>4.5</span>
                &nbsp; As a result of your loss of your login details or the misuse of the
                Platform, you shall:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
         &nbsp; bear all losses or damage incurred thereby; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp;
                  fully indemnify Interviewer.AI should Interviewer.AI suffer
                  any loss or damage.
                </p>
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Use of Platform</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.1</span>
                &nbsp;
                Interviewer.AI hereby grants you a limited, terminable,
                non-exclusive right to access and use the Platform for your
                personal, non-commercial purposes as follows:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
                  &nbsp;
                  seeking employment opportunities;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp;
                  applying for jobs; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
                  &nbsp; any other purposes reasonably related to the purposes specified
                  above.
                </p>
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.2</span>
                &nbsp; Your permission to access and use the Platform is personal to you and
                is non-transferrable and non-assignable.As part of the
                application process for employment with an Employer, you may be
                required to participate in activities such as video and audio
                interviews, psychometric assessments, and gaming tests. You
                acknowledge and agree that your participation in such activities
                on the Platform may be recorded, and you hereby consent to such
                recording, including the use of your name, likeness, voice and
                persona in connection with your use of the Platform, and to
                Interviewer.AI and the relevant Employer’s access to and use of
                any such recordings and your Candidate Profile in accordance
                with Clause 9 (Personal Data).
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.3</span>
        &nbsp; You may not access or use the Platform:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
         &nbsp; for any unlawful or fraudulent purpose;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp; to disseminate or publish any content that is false, inaccurate,
                  unlawful, harassing, defamatory, misleading, abusive,
                  threatening, harmful, violent, obscene, vulgar, or otherwise
                  objectionable;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
                  &nbsp; to disseminate or publish any content that is false, inaccurate,
                  unlawful, harassing, defamatory, misleading, abusive,
                  threatening, harmful, violent, obscene, vulgar, or otherwise
                  objectionable;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>d)</span>
                  &nbsp; to modify, adapt, translate, reverse engineer, decompile, or
                  disassemble any part of the Platform without our prior
                  consent;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>e)</span>
                  &nbsp; to interfere with any other person’s access to or use of the
                  Platform;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>f)</span>
                  &nbsp; to post any content or apply for any job on behalf of another
                  party;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>g)</span>
                  &nbsp; to access information not intended for you, or to access a server
                  or account which you are not authorised to access;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>h)</span>
                  &nbsp; to interfere with or disrupt the operation of the Platform, or
                  the servers or networks used to make the Platform available or
                  violate any requirements, procedures, policies or regulations
                  of such networks;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>i)</span>
                  &nbsp; to create a database by systematically downloading and storing
                  content available on the Platform;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>j)</span>
         &nbsp; to sell, promote or advertise products or services; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>k)</span>
         &nbsp; in any manner in breach of this Agreement.
                </p>
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.4</span>
                &nbsp;We reserve the right to waive any of the conditions in Clause 5.4
                either generally or in specific instances.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>5.5</span>
                &nbsp;We may, but are not obliged to, remove, edit, suspend, block,
                and/or monitor user accounts that we determine, in our sole
                discretion, violates the terms of this Agreement or any
                applicable law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Candidate Data</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>6.1</span>
                {/* eslint-disable-next-line */}
                &nbsp;As part of the application process for employment with you, you may ask Candidates to
                {/* eslint-disable-next-line */}
                participate in activities which you may customise such as video and audio interviews,
                {/* eslint-disable-next-line */}
                psychometric assessments, and gaming tests, on the Platform. Candidates participation in
                these activities will be recorded, processed and analysed as Candidate Data by
                {/* eslint-disable-next-line */}
                Interviewer.AI. Subject to Clause 6.2, you will have access to the relevant Candidate Data
                for the duration of your Subscription.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>6.2</span>
                {/* eslint-disable-next-line */}
                &nbsp;Notwithstanding that your Subscription may be ongoing, you will no longer have access
                to the relevant Candidate Data if:
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
         &nbsp; the Candidate Profile is deleted or otherwise removed;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
         &nbsp; the Candidate’s account is deleted or otherwise removed; or
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
         &nbsp; the Candidate withdraws from the application process with you.
                </p>
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Availability of Services</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>7.1</span>
                &nbsp;We will apply reasonable efforts to ensure that the Platform is
                available at all times. However, we cannot guarantee that the
                Platform or any part or feature thereof will always be available
                or error-free although we will attempt to correct the problem as
                soon as reasonably possible.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>7.2</span>
                &nbsp;Access to the Platform may be restricted from time to time to allow for
                the repair, maintenance, or update of the Platform. We will
                attempt to restore access to the Platform as soon as we
                reasonably can.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Intellectual Property</p>
              <p className={styles.termsDescription}>
                Subject to Clause 8 (User Content), all Intellectual Property
                Rights in the Platform and all of the text, pictures, videos,
                graphics, user interfaces, visual interfaces, trademarks, logos,
                applications, programs, computer code and other content made
                available on it are owned by Interviewer.AI. You may not
                reproduce, modify, distribute or otherwise use for commercial
                purposes any such content without our written consent, except as
                provided in this Agreement or permitted by law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>User Content</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.1</span>
                &nbsp; We do not claim ownership of any User Content that you provide on or
                through our Platform. You represent and warrant that you own or
                are otherwise authorised to use in any manner such User Content
                provided by you.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.2</span>
                &nbsp; We do not represent, endorse, or guarantee the accuracy, veracity, or
                reliability of User Content (whether provided by you or other
                persons) or derivative works therefrom. Any reliance on User
                Content will be at your own risk.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.3</span>
                &nbsp; You acknowledge and understand that all User Content is the sole
                responsibility of the person from whom the User Content
                originated.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.4</span>
                &nbsp; Any User Content that you upload or otherwise provide on or to the
                Platform may not contain:.
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>a)</span>
                  &nbsp;
                  keywords that are misleading, unreadable, hidden, repeated, or irrelevant to
                  your job listing;
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>b)</span>
                  &nbsp;
                  names, logos, or trademarks of unaffiliated persons, unless the necessary consent
                  has been obtained from them; and
                </p>
                <p className={styles.termsDescription}>
                  <span className={styles.rightsUnderDdpr}>c)</span>
                  &nbsp;
                  information that is false, misleading, or inaccurate.
                </p>
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.5</span>
                &nbsp; By submitting, posting, displaying or otherwise providing User
                Content on or through the Platform, you grant Interviewer.AI a
                worldwide, non-exclusive, royalty-free licence to reproduce,
                adapt, distribute, and publish the User Content in its sole
                discretion and in accordance with Clause 9 (Personal Data).
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>9.6</span>
                &nbsp; We may review and remove any User Content that we determine, in our
                sole discretion, violates the terms of this Agreement or any
                applicable law.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Payment</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>10.1</span>
        &nbsp; You will be invoiced according to your Subscription.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>10.2</span>
                {/* eslint-disable-next-line */}
                &nbsp; All amounts owed to us must be paid within the specified payment terms from the date
                {/* eslint-disable-next-line */}
                of the invoice. All payments payable are exclusive of all applicable taxes, and all such
                applicable taxes are payable by you.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>10.3</span>
        &nbsp; We reserve the right to suspend your account and job listings on any late payment.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Personal Data</p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.1</span>
                {' '}
        &nbsp;Collection of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.1.1</span>
                &nbsp; We may collect your Personal Data in the following instance for
                which you hereby consent to:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; when you provide it to us (e.g. by registering an account with the
                Platform or updating your Candidate Profile);
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                automatically when you access or use the Platform; and from
                third parties (including an Employer with whom you have applied
                to for employment, whether or not through the Platform) but only
                where we have made reasonable efforts to verify that the third
                parties either have your consent or are otherwise legally
                permitted or required to disclose your Personal Data to us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.1.2</span>
                &nbsp; The Personal Data that we may collect includes, but is not limited
                to:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
        &nbsp; name, gender, date of birth, nationality, country and city of birth;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                mailing address, telephone numbers, email address, and other
                contact details;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp;
                resume, educational and professional qualifications and
                certifications, and employment references;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp;
                employment and training history;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp;
                professional interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp;
                work-related health issues and disabilities;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>g)</span>
                &nbsp;
                photographs and videos;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>h)</span>
                &nbsp;
                usage data from your access and use of the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp;
                interview recordings and information you provide in online
                assessments conducted using the Platform.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.2</span>
                {' '}
        &nbsp;Purpose of collecting and using Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.2.1</span>
                &nbsp;
                Your Personal Data may be collected and used by us for the
                following purposes:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp;
                to operate, maintain, enhance, develop, test, provide, and monitor
                the effectiveness of the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; to
                verify your identity and manage your registration with the
                Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp; to
                facilitate any job application process between you and a
                Employer;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp; to
                personalise the Platform for your preferences and interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp; to
                respond to, handle, and process queries, requests, applications,
                complaints, and feedback from you;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp; to
                contact you for administrative purposes;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>g)</span>
                &nbsp; where
                in accordance with your preferences, to notify you of, or to
                enable potential Employers to contact you regarding employment
                opportunities we think you may be interested in;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>h)</span>
                &nbsp; to
                understand and analyse the usage trends, user preferences and
                interests;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp; to
                diagnose and troubleshoot errors on the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>j)</span>
                &nbsp; to
                detect and monitor illegal activity;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>k)</span>
                &nbsp; any
                other incidental business purposes related to or in connection
                with the above;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>i)</span>
                &nbsp; where
                we are required to do so by law.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.2.2</span>
                &nbsp; We
                may also disclose your Personal Data for the purposes specified
                in Clause 9.2.1 to the following persons:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp;
                Employers to whom you are applying for a job, and any of their
                affiliates or agents who are involved in the job application
                process;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp;
                potential Employers who may have employment opportunities which
                match your preferences;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp;
                employees of Interviewer.AI, but only where necessary for the
                performance of their duties;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>d)</span>
                &nbsp; any
                competent law enforcement or other government authorities
                (whether within or outside Singapore) as may be permitted or
                required by any applicable law or in relation to any court order
                or judgment;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>e)</span>
                &nbsp; to
                third-party service providers, agents, and other organisations
                that perform services on our behalf, including web-hosts,
                mailing services, market research and web usage analysis
                services, and maintenance and repair services;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>f)</span>
                &nbsp;
                otherwise as permitted under any data protection legislation.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.2.3</span>
                &nbsp; We
                will seek your consent where the collection, use, and disclosure
                of your Personal Data falls outside the scope of Clause 9.2.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.2.4 </span>
                &nbsp;
                The purposes listed in Clause 9.2 may continue to apply even in
                situations where your relationship with us has been terminated
                or altered in any way, for a reasonable period thereafter
                (including, where applicable, a period to enable us to enforce
                our rights under or arising from this Agreement with you).
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.3</span>
                {' '}
        &nbsp;Withdrawal of consent
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.3.1 </span>
                &nbsp;
                The consent that you provide for the collection, use, and
                disclosure of your Personal Data in accordance with Clause 9.2
                will remain valid until such time as it is withdrawn by you in
                writing.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.3.2 </span>
                &nbsp;
                Upon receipt of your written request to withdraw your consent,
                we may require reasonable time for your request to be processed
                and for us to notify you of the consequences of us acceding to
                the same, including any legal consequences which may affect your
                rights and liabilities to us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.3.3 </span>
                &nbsp;
                Whilst we respect your decision to withdraw your consent, please
                note that such withdrawal does not affect disclosures of your
                Personal Data that we had already disclosed or otherwise
                provided access to the persons listed in Clause 11.2.2.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.3.4 </span>
                &nbsp;
                The withdrawal of your consent does not affect our right to
                continue to collect, use, and disclose Personal Data where such
                collection, use, and disclosure without consent is permitted or
                required under the applicable laws.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.4</span>
                &nbsp;Access
                to and correction of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.4.1 </span>
                &nbsp;
                Should you wish to access a copy of the Personal Data which we
                hold about you or correct any of your Personal Data which we
                hold about you, you may submit a written request to our Data
                Protection Officer at the contact details provided in Clause 9.8
                below.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.4.2 </span>
                &nbsp;
                Please note that a reasonable fee may be charged for such
                request. We will inform you of the fee before processing your
                request.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.4.3 </span>
                &nbsp; We
                will aim to respond to your access request as soon as reasonably
                possible. If we are unable to respond to your access request
                within 30 days after receiving the request, we will inform you
                in writing within 30 days of the time by which we will be able
                to respond to your request. If we are unable to provide you with
                any Personal Data requested by you, we will generally inform you
                of the reasons why we are unable to do so (except where we are
                not required to do so under the applicable laws).
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.5</span>
                {' '}
        &nbsp;Retention of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.5.1 </span>
                &nbsp; We
                will retain your Personal Data for the period necessary to
                fulfil the purposes for which they were collected, or as
                required or permitted by applicable laws.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.5.2 </span>
                &nbsp; We
                will cease to retain your Personal Data, or remove the means by
                which the Personal Data can be associated with you, as soon as
                it is reasonable to assume that such retention no longer serves
                the purposes for which the Personal Data was collected, and is
                no longer necessary for legal or business purposes.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.6</span>
                &nbsp;
                Security of Personal Data
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.6.1 </span>
                &nbsp; To
                safeguard your Personal Data from unauthorised access,
                collection, use, disclosure, copying, modification, disposal, or
                similar risks, we will use reasonably appropriate
                administrative, physical, and technical measures to secure all
                storage and transmission of Personal Data by us.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>11.6.2 </span>
                &nbsp; No
                method of transmission over the Internet or method of electronic
                storage is completely secure. Although security cannot be
                guaranteed, we strive to protect the security of your Personal
                Data and will periodically review and enhance our information
                security measures.
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.7</span>
                &nbsp;
                Transfer of Personal Data outside Singapore
              </p>
              <p className={styles.termsDescription}>
                It may be necessary for us to transfer your Personal Data
                outside of Singapore, such as for the purposes of storing and/or
                processing. If we do so, we will take steps to ensure that your
                Personal Data continues to receive a standard of protection that
                is at least comparable to that provided under the Personal Data
                Protection Act (No. 26 of 2012).
              </p>
              <p className={styles.rightsUnderDdpr}>
                <span className={styles.rightsUnderDdpr}>11.8</span>
                &nbsp;
                Data Protection Inquiry
              </p>
              <p className={styles.termsDescription}>
                {/* eslint-disable-next-line */}
                You may contact our Data Protection team if you have any inquiries or feedback on our
                {/* eslint-disable-next-line */}
                Personal Data protection policies and procedures, or if you wish to make any request,
                in the following manner:
              </p>
              <div className={styles.flexbox}>
                <span>Email</span>
                <span>tech@interviewer.ai</span>
              </div>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Third Party Websites</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.1</span>
                &nbsp; The
                Platform may contain links to other online resources and
                locations. We are not responsible for, and do not endorse, the
                content of these external resources or locations.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>12.2</span>
                &nbsp; You
                may create a link to the Platform subject to the following
                conditions:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; the
                link is not presented in a way that is misleading or that may
                suggest any form of endorsement, approval, or association by us
                that does not exist, or is harmful to our reputation or the
                reputation of any of our affiliates;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; you
                shall immediately comply with any request by us to remove the
                link.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Term and Termination</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.1</span>
                &nbsp; The
                terms of this Agreement remain in full force and effect so long
                as you access or use the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.2</span>
                &nbsp; We
                reserve the right at our sole discretion to pursue all legal
                action, including but not limited to removing your content from
                the Platform and terminating with immediate effect your access
                to the Platform, upon any breach by you of this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>13.3</span>
                &nbsp;
                Clauses 7 (Intellectual Property), 11 (Term and Termination), 12
                (Limitation of Liability), 13 (Disclaimers), and 14 (Indemnity)
                inclusive remain in full force and effect even after you are no
                longer a user of the Platform.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Limitation of Liability</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.1</span>
                &nbsp; The
                Platform is provided on an “as is” basis, and we make no
                representations or warranty as to the quality, completeness, or
                accuracy of any content made available on the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.2</span>
                &nbsp; To
                the maximum extent permitted by law, we expressly exclude:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; all
                conditions, warranties, and other terms that might otherwise be
                implied by law into this Agreement;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; any
                and all liability to you, whether arising from this Agreement or
                otherwise in connection with your access to and use of the
                Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.3)</span>
                &nbsp; We
                shall not be liable for any consequential, special, incidental,
                or indirect damage or loss of any kind arising from our
                provision of the Platform or any access or use of the Platform
                by any person, even if we have been advised of the possibility
                of such damage or loss. For the avoidance of doubt, we shall not
                in any event be liable for loss of employment opportunity.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>14.4)</span>
                &nbsp; To
                the maximum extent permitted by law, our aggregate liability to
                you for all claims arising from your use of the Platform shall
                be limited to S$[110].
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Disclaimer</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>15.1)</span>
                &nbsp; You
                acknowledge and agree that Interviewer.AI is solely a platform
                provider and does not participate in the interview, selection,
                or hiring of Candidates, which are the sole responsibility of
                the Employer, notwithstanding the use of the Platform for and in
                connection with such activities. It is the Employer’s sole
                responsibility to comply with all applicable laws regarding its
                use of the Platform for such activities, including but not
                limited to all applicable employment and hiring laws and
                regulations.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>15.2)</span>
                &nbsp; We
                shall not be liable for any damage or loss suffered by you as a
                result of any employment decision or breach of applicable laws
                and regulations by the Employer.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>15.3)</span>
                &nbsp; We
                shall not be liable for any loss of information howsoever caused
                as a result of any interruption, suspension, or termination of
                the Platform or its services, or for the content, accuracy, or
                quality of content on or transmitted through the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>15.4)</span>
                &nbsp; We
                shall not be liable for any risks associated with dealing with
                other users with whom you come into contact with through the
                Platform, including but not limited to, the risk of physical
                harm, and you agree to assume all such risks if you decide to
                initiate such contact with other users of the Platform.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>15.5)</span>
                &nbsp;
                Nothing on the Platform is considered our endorsement,
                representation, or warranty with respect to any user or third
                party, whether of its reputation, websites, products, services,
                hiring, experience, employment or recruiting practices, or
                otherwise.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Indemnity</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.1)</span>
                &nbsp; To
                the maximum extent permitted by law, you agree to defend,
                indemnify, and hold harmless Interviewer.AI, our affiliates, and
                our or their respective directors, officers, employees, and
                agents, from and against any claims, actions, or demands,
                including reasonable legal and accounting fees, alleging or
                resulting from:
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>a)</span>
                &nbsp; any
                content you provided on or through the Platform;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>b)</span>
                &nbsp; your
                use of the Platform or any content thereon;
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>c)</span>
                &nbsp; your
                breach of any term of this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>16.2</span>
                &nbsp; We
                will provide you with notice of any such claim, action, or
                demand as soon as reasonably possible.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Applicable Law</p>
              <p className={styles.termsDescription}>
                The terms of this Agreement and any non-contractual obligation
                arising out of or in connection with this Agreement shall be
                governed by, and construed in accordance with, the laws of
                Singapore.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Dispute Resolution</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>18.1</span>
                &nbsp; Any
                dispute arising out of or in connection with this Agreement,
                including any question regarding its existence, validity or
                termination, shall be referred to and finally resolved by
                arbitration administered by the Singapore International
                Arbitration Centre (“SIAC”) in accordance with the Arbitration
                Rules of the SIAC for the time being in force, which rules are
                deemed to be incorporated by reference in this clause.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>18.2</span>
                &nbsp; The
                seat of the arbitration shall be Singapore.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>18.3</span>
                &nbsp; The
                Tribunal shall consist of one (1) arbitrator to be appointed by
                the chairman for the time being of the SIAC.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>18.4</span>
                &nbsp; The
                language of the arbitration shall be English.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>18.5</span>
                &nbsp; You
                further agree that following the commencement of arbitration,
                you will attempt in good faith to resolve the dispute through
                mediation at the Singapore International Mediation Centre
                (“SIMC”), in accordance with the SIAC-SIMC Arb-Med-Arb Protocol
                for the time being in force. Any settlement reached in the
                course of the mediation shall be referred to the arbitral
                tribunal appointed by SIAC and may be made a consent award on
                agreed terms.
              </p>
            </li>
            <li className={styles.termsPoints}>
              <p className={styles.termsHeading}>Miscellaneous</p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>19.1</span>
                &nbsp; You
                may not assign or transfer any of your rights under this
                Agreement to any other person. You agree that we may assign or
                transfer our rights under this Agreement at our sole discretion,
                where we reasonably believe that your rights will not be
                affected.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>19.2</span>
                &nbsp; No
                term of this Agreement shall be enforceable solely by virtue of
                the Contracts (Rights of Third Parties) Act (Chapter 53B of
                Singapore) by any person who is not a party to this Agreement.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>19.3</span>
                &nbsp; If
                we choose not to enforce any rights or remedies against you
                under this Agreement, we remain entitled to enforce those rights
                or remedies at a later date, or in any other situation where you
                breach any term of this Agreement. The rights provided in this
                Agreement are cumulative and not exclusive of any rights or
                remedies provided by law.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>19.4</span>
                &nbsp; If
                any provision of this Agreement shall be invalid, illegal, or
                unenforceable in any respect by operation of law or otherwise is
                or becomes ineffective for reasons beyond our control, the
                validity, legality and enforceability of the remaining
                provisions of this Agreement shall not in any way be affected or
                impaired.
              </p>
              <p className={styles.termsDescription}>
                <span className={styles.rightsUnderDdpr}>19.5</span>
                &nbsp; If
                you have any questions, please contact us at
                hello@interviewer.ai.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <label htmlFor="terms" className={styles.terms}>
          <input
            type="checkbox"
            // eslint-disable-next-line
            onChange={() => params.redirectTo ? window.location.href = params.redirectTo : window.location.href = '/overview'}
            checked={termsChecked}
            id="terms"
            className={styles.checkboxNoMarginTop}
          />
          &nbsp;
          I accept the Terms and Conditions.
        </label>
      </div>
    </div>
  );
};

export default TermsAndConditions;
