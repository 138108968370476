import React, { Suspense } from 'react';
import LoadingComponent from './LoadingComponent';

const WaitingComponent = Component => (
  props => (
    <Suspense fallback={<LoadingComponent />}>
      <Component {...props} />
    </Suspense>
  )
);

export default WaitingComponent;
