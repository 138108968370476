// @flow
import Mixpanel from 'mixpanel-browser';
import moment from 'moment';
import { find } from 'lodash';
import {
  CANDIDATES_STATUS, COMMON_EMAIL, EMAIL_TEMPLATE_DATA, EMAIL_TYPE,
  COMMON_EMAIL_SIGNUP, ROLE_TYPES, CREDITS_PLANS_PRICE, ASSESSMENT_REPORT_PARAMETERS,
} from './constants';
import Auth from '../auth/Auth';
import { successToast, alertToast } from '../components/Toast';
import OrganizationStore from '../stores/OrganizationStore';
import clientConfig from '../config/config';

const config = clientConfig.default;

const getUrlParams = (search) => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  const params = {};

  hashes.map((hash) => {
    const [key, val] = hash.split('=');
    if (key && val) {
      const hashVal = decodeURIComponent(val);
      if (key in params) {
        if (Array.isArray(params[key])) {
          params[key].push(hashVal);
        } else {
          params[key] = [params[key], hashVal];
        }
      } else {
        params[key] = hashVal;
      }
    }
    return params;
  });

  return params;
};

const isEmailValid = email => email.match(/\S+@\S+\.\S+/i);

const isCommonEmail = (email) => {
  const emailDomain = email.toLowerCase().split('@')[1];
  for (let i = 0; i < COMMON_EMAIL.length; i += 1) {
    if (emailDomain === COMMON_EMAIL[i]) {
      return true;
    }
  }

  return false;
};

const isCommonEmailSignup = (email) => {
  const emailDomain = email.toLowerCase().split('@')[1];
  for (let i = 0; i < COMMON_EMAIL_SIGNUP.length; i += 1) {
    if (emailDomain === COMMON_EMAIL_SIGNUP[i]) {
      return true;
    }
  }

  return false;
};

const copyTextToClipboard = (content, msg) => {
  try {
    const body = document.getElementsByTagName('body')[0];
    const tempInput = document.createElement('INPUT');
    body.appendChild(tempInput);
    tempInput.setAttribute('value', content);
    tempInput.select();
    document.execCommand('copy');
    body.removeChild(tempInput);
    successToast(msg);
  } catch (e) {
    console.log(e);
    alertToast('Unable to copy to clipboard');
  }
};

const getInterviewDeadline = (date, currentDate) => {
  const difference = date - currentDate;
  if (difference < 0) {
    return 0;
  }

  return Math.floor(difference / (1000 * 24 * 3600));
};

const getYYYYMMDD = (dateStr) => {
  try {
    const date = new Date(dateStr);
    return date.toISOString().split('T')[0];
  } catch (e) {
    return '2100-01-01';
  }
};

const getOneDayLater = (date) => {
  const oneDayLater = new Date(date.setDate(date.getDate() + 1));

  return getYYYYMMDD(oneDayLater.toISOString());
};

const getOneMonthLater = (date) => {
  const oneMonthLater = new Date(date.setDate(date.getDate() + 30));

  return getYYYYMMDD(oneMonthLater.toISOString());
};

const secondsToFormattedString = (seconds) => {
  const second = seconds % 60;
  const min = Math.floor(seconds / 60);
  const secondString = second.toString();
  const minString = min.toString();

  const formattedSecondString = secondString.length > 1 ? secondString : `0${secondString}`;
  const formattedMinString = minString.length > 1 ? minString : `0${minString}`;

  return `00:${formattedMinString}:${formattedSecondString}`;
};

const parseFormattedStringToSeconds = (string) => {
  const splittedString = string.split(':');

  return parseFloat(splittedString[0]) * 3600
    + parseFloat(splittedString[1]) * 60 + parseFloat(splittedString[2]);
};

const appendCharS = (number, word) => {
  switch (number) {
    case 0:
    case 1:
      return `${number} ${word}`;
    default:
      break;
  }

  return `${number} ${word}s`;
};

const slugify = wholeString => wholeString.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-');

const generateRandomCharacters = () => Math.random().toString(36).substr(2, 6);

const getFileExtension = filename => (
  (filename.lastIndexOf('.') !== -1) ? filename.substring(filename.lastIndexOf('.') + 1) : ''
);

const removeWhiteSpaceForArrayElements = arrayOfString => (
  arrayOfString.map(str => str.trim())
);

const removeWhiteSpaceForStringElement = str => (
  str.trim()
);

const replaceContentTag = (content, replacements) => {
  let replacedContent = content;
  for (let i = 0; i < replacements.length; i += 1) {
    replacedContent = replacedContent.replace(replacements[i].regex, replacements[i].replacement);
  }

  return replacedContent;
};

const getPlanIndex = (plan) => {
  if (CREDITS_PLANS_PRICE && CREDITS_PLANS_PRICE.length > 0) {
    return CREDITS_PLANS_PRICE.findIndex(x => x.numberOfCandidate === parseInt(plan, 10));
  }
  return -1;
};

const getAllBillingCycles = () => ['monthly', 'quarterly', 'annual'];

const getBillingCycleTotalMonth = (text) => {
  if (text === 'quarterly') {
    return 3;
  }

  if (text === 'annual') {
    return 12;
  }

  return 1;
};

const getBillingCycleText = (text) => {
  if (text === 'quarterly') {
    return 'every 3 months';
  }

  if (text === 'annual') {
    return 'every 12 months';
  }

  return 'every month';
};

const getTotalBill = (pricing, billingCycle, percentOff = null, amountOff = null) => {
  const totalPrice = pricing * getBillingCycleTotalMonth(billingCycle);
  if (percentOff) {
    return Math.round(totalPrice * (1 - percentOff / 100));
  }

  if (amountOff) {
    return Math.round(totalPrice - amountOff / 100);
  }

  return Math.round(totalPrice);
};

const getTotalBillWithDiscount = (totalPrice, percentOff = null, amountOff = null) => {
  if (percentOff) {
    return Math.round(totalPrice * (1 - percentOff / 100));
  }

  if (amountOff) {
    return Math.round(totalPrice - amountOff / 100);
  }

  return Math.round(totalPrice);
};

const mxTrackEvent = (eventName, eventProperty) => {
  const property = { ...eventProperty };
  if (Auth.isAuthenticated()) {
    property.name = Auth.getProfileInfo('firstName');
    // property.company = Auth.getProfileInfo('companyName'); need another way to track this
    property.userId = Auth.getProfileInfo('userId');
  }
  Mixpanel.track(eventName, property);
};

const mxUserProfile = (
  email,
  signup_source = null,  //eslint-disable-line
) => { // eslint-disable-line
  console.log('created user profile with email....', email);
  Mixpanel.identify(email);
  Mixpanel.people.set({
    '$email': email, // eslint-disable-line
    'source': Auth.getProfileInfo('source'), // eslint-disable-line
    'company': Auth.getProfileInfo('companyName'), // eslint-disable-line
    'signup_source': signup_source, // eslint-disable-line
  });
};

const getProgressColorClass = (score, max = 100) => {
  let scoreNum = score;
  scoreNum = Math.round((scoreNum * 100) / max);

  if (scoreNum >= 71) {
    return 'success';
  }

  if (scoreNum >= 41 && scoreNum <= 70) {
    return 'warning';
  }

  return 'danger';
};

const getHighStandardProgressColorClass = (score) => {
  if (score >= 75) {
    return 'success';
  }

  if (score >= 50 && score < 74) {
    return 'warning';
  }

  return 'danger';
};

const getResponseStatusColorClass = (status) => {
  if (status.toUpperCase() === CANDIDATES_STATUS.RESPONDED) {
    return 'responded';
  }

  if (status.toUpperCase() === CANDIDATES_STATUS.EVALUATED) {
    return 'evaluated';
  }

  if (status.toUpperCase() === CANDIDATES_STATUS.SENT) {
    return 'sent';
  }

  if (status.toUpperCase() === CANDIDATES_STATUS.NEW) {
    return 'new';
  }


  return 'normal';
};

const isCandidateEvaluated = status => status.toUpperCase() === CANDIDATES_STATUS.EVALUATED;

const dateToHumanReadableFormat = (d) => {
  const date = moment(d);
  return date.format('ddd, DD MMM YYYY @ h:mm A');
};

const dateToMonthAndDay = (d) => {
  const month = moment(d).format('MMMM');
  const day = moment(d).format('Do');
  return `${month} ${day}`;
};

const convertKeyToTitle = (k, removeString = '') => {
  const s = k.replace(removeString, '');
  return s.split(/(?=[A-Z])/).join(' ');
};

const imageFileValidation = (file, maximumFileSize, maximumFileSizeStr) => {
  if (file.type.match(/image/) && file.size > maximumFileSize) {
    alertToast(`Please only upload image file less than ${maximumFileSizeStr}`);
    return false;
  }

  return true;
};

const videoFileValidation = (file, maximumFileSize, maximumFileSizeStr) => {
  if (file.type.match(/video/) && file.size > maximumFileSize) {
    alertToast(`Please only upload video file less than ${maximumFileSizeStr}`);
    return false;
  }

  return true;
};

const getCurrentInterviewQuestions = (
  quesRes: {
    myQuestions: Array<Object>,
    commonQuestions: Array<Object>,
  }, questionIds: Array<string>,
) => {
  const allQuestions = quesRes.commonQuestions.concat(quesRes.myQuestions);
  const result = [];
  questionIds.forEach((questionId) => {
    result.push(find(allQuestions, { question_id: questionId }));
  });
  return result;
};

const generateInterviewBackgroundFilePath = (
  companyName: string,
  interviewBackground: Object,
) => {
  const toBeSlugified = `${companyName} ${generateRandomCharacters()}`;
  const filePath = `${companyName}/interviewBackground/${slugify(toBeSlugified)}`;
  const fileExt = getFileExtension(interviewBackground.name);
  return `${filePath}.${fileExt}`;
};

const getLevelAndClassFromYearsOfExperience = (years: number) => {
  if (years <= 3) {
    return ['Junior Level', 'tag-summary--green'];
  }

  if (years > 3 && years <= 10) {
    return ['Mid Level', 'tag-summary--red'];
  }

  return ['Senior Level', 'tag-summary--blue'];
};

const generateSummaryFromParsedResume = (name: string, resume: Object) => {
  /*
  generate a summary based on parsed info from resume
  [Full Name] has worked at [current company] as [current title]. [First Name]
  is based in [Country] with [xx] years of experience, studied at [University Name].
  */
  let experienceSentence = '';
  let educationSentence = '';

  if (resume.currentCompany && resume.currentTitle) {
    experienceSentence = `${name} has worked at ${resume.currentCompany} as ${resume.currentTitle}.`;
  } else if (resume.currentCompany) {
    experienceSentence = `${name} has worked at ${resume.currentCompany}.`;
  } else if (resume.currentTitle) {
    experienceSentence = `${name} has worked as ${resume.currentTitle}.`;
  }

  // let years = '';
  // if (resume.yearsOfExperience) {
  //   years = Math.round(resume.yearsOfExperience);
  // }

  // if (resume.yearsOfExperience && resume.salientEducationOrganization) {
  //   educationSentence = `${name} has around ${years}
  // years of working experience and studied at ${resume.salientEducationOrganization}.`;
  // } else if (resume.yearsOfExperience) {
  //   educationSentence = `${name} has around ${years} years of working experience.`;
  // } else
  if (resume.salientEducationOrganization) {
    educationSentence = `${name} has studied at ${resume.salientEducationOrganization}.`;
  }

  if (!!experienceSentence && !!educationSentence) {
    return `${experienceSentence} ${educationSentence}`;
  }

  return 'No Summary';
};

const removeEmptyValueFromObject = obj => (
  Object.keys(obj)
    .filter(k => obj[k]) // filter empty string
    .reduce(
      (newObj, k) => {
        newObj[k] = obj[k]; // eslint-disable-line no-param-reassign
        return newObj;
      }, {},
    )
);

const checkImageExists = (url: string) => new Promise((resolve, reject) => {
  const img = new Image();
  img.onload = () => resolve(true);
  img.onerror = () => reject(new Error('Image failed to load'));
  img.src = url;
});

const getEmailTemplate = (isEligibleForCalendly: boolean): string => {
  const emailTemplate = EMAIL_TYPE.SCHEDULE_INTERVIEW_EMAIL;
  const calendlyTemplate = EMAIL_TYPE.CALENDLY_INTERVIEW_EMAIL;

  if (isEligibleForCalendly) {
    return EMAIL_TEMPLATE_DATA[calendlyTemplate];
  }
  return EMAIL_TEMPLATE_DATA[emailTemplate];
};

const capitaliseFirstLetter = (word: string): string => (
  word.charAt(0).toUpperCase() + word.slice(1)
);

const capitaliseWord = (word: string): string => (
  word.charAt(0).toUpperCase() + word.toLowerCase().slice(1)
);

// https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
const getLocalTime = () => {
  const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
  return localISOTime;
};

const getAverageWorkmapScore = (workmapScores, workmap) => {
  const scores = Object.keys(workmap).map(skill => workmap[skill] && workmapScores[skill]);
  const allScores = scores.filter(Number);
  const totalScore = allScores.reduce((total, value) => total + value, 0);
  return totalScore === 0 ? 0 : parseFloat(totalScore / allScores.length).toFixed(2);
};

const isCoachingUser = () => {
  const store = OrganizationStore.getState();
  const { subscription } = store;
  const { isCoach } = subscription;
  return Boolean(isCoach);
};

const isSpecialScoringEnabled = () => {
  const store = OrganizationStore.getState();
  const { subscription } = store;
  const { isSpecialScoringEnabled: specialScore } = subscription;
  return Boolean(specialScore);
};

const getAverageRating = (ratings: Array<number>) => {
  if (ratings.length === 0) {
    return '-';
  }
  const average = ratings.reduce((val, item) => val + item, 0) / ratings.length;
  return Math.round((average + Number.EPSILON) * 100) / 100;
};

/*
  Parameters
  ----------
  @element1: any
  @element2: any
  @sortType : string
      A string defining descending or ascending. (default 'asc').
      'asc' | 'desc'
  @elementType : string
      A string defining variable type of element. (default 'string').
      'string' | 'integer'
*/
const comparatorOrder = (element1, element2, elementType = 'string', sortType = 'asc') => {
  // places null and '-' elements last regardless of order
  if (element1 === null || element1 === '-') {
    return 1;
  }
  if (element2 === null || element2 === '-') {
    return -1;
  }

  let order = 0;
  let elementA = element1;
  let elementB = element2;

  if (elementType === 'string') {
    elementA = element1 ? element1.toLowerCase() : element1;
    elementB = element2 ? element2.toLowerCase() : element1;
  }

  if (elementA > elementB) {
    order = 1;
  } else if (elementA < elementB) {
    order = -1;
  }

  return sortType === 'asc' ? order : -order;
};

const DDMMMYYYY = (d) => {
  const date = moment(d);
  return date.format(' DD MMM, YYYY ');
};

const clearLocalDiscountData = () => {
  localStorage.removeItem('discount_data');
};

const hideNavigationBar = (currentPath) => {
  if (!currentPath) {
    return false;
  }
  const navRestrictedPaths = ['/interview/:interviewId/candidates', '/interviews/edit/:interviewId/candidates/:filter?'];
  const isRestricted = navRestrictedPaths.includes(currentPath);
  return isRestricted;
};

const getInitial = (stringValue) => {
  if (stringValue && stringValue !== '') {
    const words = stringValue.trim().split(/\s+/);
    const fname = words[0].charAt(0).toUpperCase();
    const lname = words[words.length - 1].charAt(0).toUpperCase();
    return fname.concat(lname);
  }
  return '';
};


const generateOverviewMessage = (totalInterviews, interviews = []) => { // eslint-disable-line
  // temporary message
  // const customMessage = {
  //   label: ' We have \
  // launched a new, upgraded recording flow for candidates. Start by creating a new interview.',
  //   actionText: ' Create Job Interview',
  //   redirect: '/interviews/new',
  // };
  const customMessage = {
    primaryMessage: 'overviewPage.instruction1',   //eslint-disable-line
    secondaryMessage: 'overviewPage.instruction2',
  };  //eslint-disable-line
  // const interview = interviews[0]; // eslint-disable-line
  // const store = OrganizationStore.getState();
  // const { overviewPageActionButtonsVisitedStatus } = store; // eslint-disable-line
  // let customMessage = {
  //   label: ' Checkout resources on our help centre.',
  //   actionText: ' Help Center',
  //   redirect: 'https://interviewer.ai/help-center',
  // };
  // const loginCount = Auth.getProfileInfo('login_count');
  // // if (!overviewPageActionButtonsVisitedStatus.hire && interviews.length > 0) {
  // //   customMessage = {
  // //     label: ' Help us help you calculate your time to hire, tag your hires now!',
  // //     actionText: ' Hire',
  // //     redirect: `/interview/${interview.interviewid}/candidates?email=${interview.email}`,
  // //   };
  // //   return customMessage;
  // // }

  // if (store.companyLogoUrl === config.LOGO && loginCount % 2 === 1) {
  //   customMessage = {
  //     label: ' Customise your brand and add company details.',
  //     actionText: ' Add Logo',
  //     redirect: '/settings/profile',
  //   };
  // } else if (store
  //   .subscriptionLimits && !(store
  //   .subscriptionLimits.id === config.BRONZE_TRIAL) && store
  //   .companyLogoUrl !== config.LOGO && totalInterviews === 0) {
  //   customMessage = {
  //     label: ' Start by creating a new interview.',
  //     actionText: ' create interview',
  //     redirect: '/interviews/new',
  //   };
  // } else if (store
  //   .subscriptionLimits && !(store
  //   .subscriptionLimits.id === config.BRONZE_TRIAL) && store
  //   .companyLogoUrl !== config.LOGO && totalInterviews !== 0 && store
  //   .teamMembers.length === 0) {
  //   customMessage = {
  //     label: ' We made collaboration easy, invite your team members.',
  //     actionText: ' Add Team',
  //     redirect: '/organization/members',
  //   };
  // } else if (store
  //   .subscriptionLimits && !(store.subscriptionLimits.id === config.BRONZE_TRIAL) && store
  //   .companyLogoUrl !== config.LOGO && totalInterviews !== 0 && store
  //   .teamMembers.length > 0) {
  //   customMessage = {
  //     label: ' Checkout resources on our help centre.',
  //     actionText: ' Help Center',
  //     redirect: 'https://interviewer.ai/help-center',
  //   };
  // }
  return customMessage;
};

const checkUserAccountDetails = (jobs, candidates) => { // eslint-disable-line
  // temporary message
  // const customMessage = {
  //   label: ' We have \
  // launched a new, upgraded recording flow for candidates. Start by creating a new interview.',
  //   actionText: ' Create Job Interview',
  //   redirect: '/interviews/new',
  // };
  const store = OrganizationStore.getState();
  const userObject = {
    hasCompanyLogo: true,
    hasTeamMembers: true,
  };

  if (store.companyLogoUrl === config.GREY_SCALE_LOGO) {
    userObject.hasCompanyLogo = false;
  }

  if (store.teamMembers.length === 0) {
    userObject.hasTeamMembers = false;
  }

  return userObject;
};

const getPackageName = () => {   //eslint-disable-line
  // const { name, daysRemaining, isLocked } = plan;
  // if (isLocked) {
  //   return 'Trial Expired';
  // }

  // if (!name) {
  //   return 'Trial';
  // }
  // if (name && name.includes('-pro-')) {
  //   return 'Pro';
  // }
  // if (name && name.includes('-premium-')) {
  //   return 'Premium';
  // }
  // if (name && name.includes('-advanced-')) {
  //   return 'Advance';
  // }
  // if (name && name.includes('Bronze')) {
  //   return `Trial - ${daysRemaining} ${daysRemaining > 1 ? 'Days' : 'Day'} Left`;
  // }

  // return `${name.substring(0, 12)}...`;
  return 'buyCredits';
};

const getPackageEndTime = (expiryTimeStamp) => {
  const expiryDate = moment.unix(expiryTimeStamp).format('MM/DD/YYYY');
  const diffDays = Math.ceil(moment(expiryDate).diff(moment(), 'days', true));
  const diffMonth = Math.ceil(moment(expiryDate).diff(moment(), 'months', true));
  const diffYear = Math.ceil(moment(expiryDate).diff(moment(), 'year', true));

  if (diffDays === 365) {
    return {
      duration: diffYear,
      label: diffYear > 1 ? 'Years' : 'Year',
    };
  }

  if (diffDays >= 28 && diffDays < 365) { // Considering feb we compare it to 28 for timebeing
    return {
      duration: diffMonth,
      label: diffMonth > 1 ? 'Months' : 'Month',
    };
  }

  return {
    duration: diffDays,
    label: diffDays > 1 ? 'Days' : 'Day',
  };
};

const isObjectEmpty = (data) => {
  if (!data) {
    return true;
  }

  return data
    && Object.keys(data).length === 0
    && Object.getPrototypeOf(data) === Object.prototype;
};

const addRoleType = () => {
  const isCoachingAccount = isCoachingUser();
  if (isCoachingAccount) {
    const obj = [
      {
        label: 'Training',
        value: 'training',
      },
      {
        label: 'Mock Interview',
        value: 'mock interview',
      },
    ];
    ROLE_TYPES.push(...obj);
  }
  return ROLE_TYPES;
};

const findDuplicatedata = (data, param) => {
  const duplicateParameters = data
    .map(v => v[param])
    .filter((v, i, vDuplicate) => {
      if (!v) {
        return false;
      }
      return vDuplicate.indexOf(v) !== i;
    });

  const duplicateObjects = data
    .filter(obj => duplicateParameters.includes(obj[param]));
  return duplicateObjects;
};

const checkIfDataExits = (data, parameter, value) => {
  const status = data.some(el => (el[parameter] === value));
  return status;
};

// This logic can be merged into findDuplicatedata - For future changes
const getIndexOfAllOccurance = (data, parameter, value) => {
  const indexNumbers = data.reduce((ind, el, i) => {
    if (el[parameter] === value) ind.push(i);
    return ind;
  }, []);

  return indexNumbers;
};

const fetchFeedBackParamaters = () => {
  const { feedbackParametersType } = OrganizationStore.getState();
  if (feedbackParametersType === 'aiFeedback') {
    return ASSESSMENT_REPORT_PARAMETERS.ENTERPRISE_CLIENTS;
  }
  return ASSESSMENT_REPORT_PARAMETERS.COACHES;
};

const fetchScoreBreakDowns = (score, scoreParameter, orgId = null) => {
  const professionalismScoreBreakDown = ['dressingScore', 'hairTidinessScore', 'bodyLanguageScore'];
  const sociabilityScoreBreakDown = ['emotionScore', 'arousalScore', 'sentimentScore'];
  const communicationBreakDown = ['communicationScore', 'articulationScore'];
  const positiveAttitudeBreakDown = ['arousalScore'];

  if (config.AIA_ORG_ID.includes(orgId) || !(Object.prototype.hasOwnProperty.call(score, 'grammarScore'))) {
    communicationBreakDown.push('sentimentScore');
  } else {
    communicationBreakDown.push('grammarScore');
  }

  const obj = [];
  if (scoreParameter === 'professionalism') {
    professionalismScoreBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'sociability') {
    sociabilityScoreBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'communication') {
    communicationBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  } else if (scoreParameter === 'positiveAttitude') {
    positiveAttitudeBreakDown.forEach((x) => {
      obj.push({
        [x]: score[x],
      });
    });
  }
  return obj;
};

const getAverage = (values) => {
  let sum = 0;
  values.forEach((value) => {
    sum += value;
  });
  const avg = sum / values.length;
  return avg;
};

const convertWorkmapScoreToPercentage = (value) => {
  console.log('coming here', value);
  return (value / 7) * 100;
};

const convertToDecimal = (num, decimalPlaces = 0) => {
  const factor = 10 ** decimalPlaces;
  return Math.round(num * factor) / factor;
};

const removeSpacesAndCamelCase = (str) => {
  const words = str.split(/[-_\s]/);
  const camelCaseWords = words.map((word, index) => {
    if (index === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });
  return camelCaseWords.join('');
};

const removeEscapeCharacter = (str) => {
  if (!str) {
    return '';
  }
  const removedEscapeCharacterString = str.replace(/"/g, '""');
  return removedEscapeCharacterString;
};

const removeDuplicateObjects = (array, keyFn) => {
  const uniqueSet = new Set();
  const uniqueArray = [];

  array.forEach((item) => {
    const key = keyFn(item);
    if (!uniqueSet.has(key)) {
      uniqueSet.add(key);
      uniqueArray.push(item);
    }
  });

  return uniqueArray;
};

const addCommasToNumber = (number) => {
  // Split the number into parts before and after the decimal point
  const parts = number.toString().split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1] ? '.' + parts[1] : '';  //eslint-disable-line

  // Add commas to the integer part
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  // Combine the integer part with commas and the decimal part
  return integerWithCommas + decimalPart;
};

const findDataFromArrayOfObject = (data, key, value) => {
  if (!Array.isArray(data) || !key) {
    return false;
  }
  const resultObject = data.find(obj => obj[key] === value);
  return resultObject;
};


export {
  isEmailValid,
  getUrlParams,
  isCommonEmail,
  copyTextToClipboard,
  getInterviewDeadline,
  getYYYYMMDD,
  getOneDayLater,
  getOneMonthLater,
  secondsToFormattedString,
  parseFormattedStringToSeconds,
  appendCharS,
  slugify,
  generateRandomCharacters,
  getFileExtension,
  removeWhiteSpaceForArrayElements,
  removeWhiteSpaceForStringElement,
  replaceContentTag,
  mxTrackEvent,
  getBillingCycleTotalMonth,
  getPlanIndex,
  getAllBillingCycles,
  getBillingCycleText,
  getTotalBill,
  getTotalBillWithDiscount,
  getProgressColorClass,
  getHighStandardProgressColorClass,
  getResponseStatusColorClass,
  isCandidateEvaluated,
  dateToHumanReadableFormat,
  convertKeyToTitle,
  imageFileValidation,
  videoFileValidation,
  getCurrentInterviewQuestions,
  generateInterviewBackgroundFilePath,
  getLevelAndClassFromYearsOfExperience,
  generateSummaryFromParsedResume,
  checkImageExists,
  removeEmptyValueFromObject,
  getEmailTemplate,
  capitaliseFirstLetter,
  getLocalTime,
  getAverageWorkmapScore,
  isCoachingUser,
  getAverageRating,
  capitaliseWord,
  comparatorOrder,
  isSpecialScoringEnabled,
  isCommonEmailSignup,
  DDMMMYYYY,
  clearLocalDiscountData,
  hideNavigationBar,
  getInitial,
  generateOverviewMessage,
  mxUserProfile,
  getPackageName,
  getPackageEndTime,
  dateToMonthAndDay,
  isObjectEmpty,
  addRoleType,
  findDuplicatedata,
  checkIfDataExits,
  getIndexOfAllOccurance,
  fetchFeedBackParamaters,
  fetchScoreBreakDowns,
  getAverage,
  convertWorkmapScoreToPercentage,
  checkUserAccountDetails,
  convertToDecimal,
  removeSpacesAndCamelCase,
  removeEscapeCharacter,
  removeDuplicateObjects,
  addCommasToNumber,
  findDataFromArrayOfObject,
};
