import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';     //eslint-disable-line
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Button,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';

import {
  getInitial,
  getYYYYMMDD,
  dateToHumanReadableFormat,
  capitaliseFirstLetter,
  isCoachingUser,
} from '../utils/utils';
import Auth from '../auth/Auth';
import 'moment/locale/vi';
import 'moment/locale/de';
import OrganizationStore from '../stores/OrganizationStore';

import styles from '../css/CandidateInformation.module.scss';
import clientConfig from '../config/config';
import More from '../assets/More.svg';
import { ReactComponent as MapPin } from '../assets/MapPin.svg';
import { ReactComponent as EmailIcon } from '../assets/Envelope_alt.svg';
import { ReactComponent as PhoneIcon } from '../assets/PhoneIcon.svg';
import { ReactComponent as OverallScoreIcon } from '../assets/OverallIcon.svg';
import { ReactComponent as LikedinIcon } from '../assets/LinkedinIcon.svg';
import { ReactComponent as ResumeIcon } from '../assets/ResumeCDIcon.svg';
import { ReactComponent as SopIcon } from '../assets/SopIcon.svg';
import { ReactComponent as TickIcon } from '../assets/TickIcon.svg';
import { ReactComponent as EyeIcon } from '../assets/EyeIcon.svg';
import { ReactComponent as CrossIcon } from '../assets/CrossIcon.svg';
import { ReactComponent as CopyIcon } from '../assets/CopyIcon.svg';
import { ReactComponent as FacebookIcon } from '../assets/FacebookIcon.svg';
import { ReactComponent as InstagramIcon } from '../assets/InstagramIcon.svg';
import { ReactComponent as Verfiedicon } from '../assets/VerfiedIcon.svg';
import { ReactComponent as MediumMatchIcon } from '../assets/mediumMatchIcon.svg';
import { ReactComponent as LowMatchIcon } from '../assets/lowMatchIcon.svg';
import { ReactComponent as IdVerfTrue } from '../assets/idVerificationTrue.svg';
import { ReactComponent as AudioVerification } from '../assets/audioVerfication.svg';
import { ReactComponent as BrowserToggle } from '../assets/browserChange.svg';
import { ReactComponent as CopyPaste } from '../assets/copyPaste.svg';
import { ReactComponent as ThirdPartyVoice } from '../assets/thirdPartyVoice.svg';
import { ReactComponent as EyeContact } from '../assets/eyeContact.svg';
// import { ReactComponent as SuspiciousObject } from '../assets/suspiciousObject.svg';


import InfoTooltip from './InfoTooltip';
import CandidateActions from '../actions/CandidateActions';
import ScheduleCalendlyEmailPreview from './Interviews/ScheduleCalendlyEmailPreview';
import ScheduleInterviewEmailPreview from './Interviews/ScheduleInterviewEmailPreview';
import ShareCandidatesModal from './ShareCandidatesModal';
import { successToast } from './Toast';
import RejectModalEmailPreview from './Candidates/RejectModalEmailPreview';
import HiringManagerActionsComponent from '../pages/Candidates/ShareCandidatesScreen/HiringManagerActionsComponent';

const CandidateInformation = (props) => {
  const {
    downloadCandidateReport,
    sendCandidateReport,
    candidateData,
    interviewDetail,
    eventTypeData,
    chosenEventTypeUrl,
    currentNameAttribute,
    removeBranding,
    interviewId,
    coachingCandidateId,
    handleCandidatesShareSubmit,
    isFromShareShortList,
    hiringManagerEmail,
    activeTab,
    handleTabSelection,
    totalOverallScore,
    handleScrollToScoring,
    parentComponent,
    displayIdentityModal,
    orgId,
    isRecommended,
    history,
  } = props;

  const {
    valid_identification_confidence_score: idVerify,
    audio_exists: audioExists,
    browser_toggle: browserToggle,
    pasted_answer: pastedAnswer,
    third_party_voice_exists: thirdpartyVoice,
    eye_contact_exists: eyeContact,
    // suspicious_object_exists: suspiciousObjectExists,
  } = candidateData;

  const [showScheduleInterviewModal, setShowScheduleInterviewModal] = useState(false);
  const [overwrittenLogo, setOverwrittenLogo] = useState(null);
  const [overwrittenCompanyName, setOverwrittenCompanyName] = useState(null);
  const [showShareShortlistModal, setShowShareShortlistModal] = useState(false);
  const [userLanguage, setLanguage] = useState(OrganizationStore.getState().userLanguage); // eslint-disable-line
  const inputRef = useRef();
  const { t } = useTranslation();
  const config = clientConfig.default;

  const onChange = (storeData) => {
    setLanguage(storeData.userLanguage);
  };

  useEffect(() => {
    moment.locale(userLanguage === 'en-US' ? 'en' : userLanguage || 'en');
    // if (userLanguage === 'vi') {
    //   console.log('VI');
    //   moment.locale('vi');
    // } else {
    //   console.log('EN-US');
    //   moment.locale('en');
    // }
    const { overwriteFields } = props.interviewDetail; // eslint-disable-line
    if (overwriteFields) {
      setOverwrittenLogo(overwriteFields.interview_logo);
      setOverwrittenCompanyName(overwriteFields.company_name);
    }
    // return () => {
    //   OrganizationStore.unlisten(onChange);
    // };
  }, [props, userLanguage]);

  useEffect(() => {
    OrganizationStore.listen(onChange);
  }, []);

  const renderLinkedinUrl = (linkedinUrl) => { // eslint-disable-line
    if (linkedinUrl) {
      const linkedinUrlWithoutLastSlash = linkedinUrl.replace(/\/$/, '');
      const linkedinUsername = linkedinUrlWithoutLastSlash.split('/')[linkedinUrlWithoutLastSlash.split('/').length - 1];
      return ` Linkedin.com/${linkedinUsername}`;
    }
    return '--';
  };

  const toggleShowScheduleInterviewModal = () => {
    setShowScheduleInterviewModal(!showScheduleInterviewModal);
  };

  const toggleShowShortlistedModal = () => {
    setShowShareShortlistModal(!showShareShortlistModal);
  };

  const shareShortlist = () => {
    toggleShowShortlistedModal();
  };


  const copyToClipboard = () => {
    const text = inputRef.current.innerText;
    navigator.clipboard.writeText(text);
    successToast('Email copied to clipboard');
  };

  const MoreCandidateInformation = () => (
    <div className={styles.candidateExtraInformationSection}>
      {
        (idVerify !== null && idVerify !== true)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              !idVerify
              && t('candidateDetails.idVerifyFalseText')
            }
          >
            {
              !idVerify && <IdVerfTrue fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {
        (audioExists !== null && audioExists !== true)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              !audioExists
              && t('candidateDetails.audioDetectedFalse')
            }
          >
            {
              !audioExists && <AudioVerification fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {
        (browserToggle !== null && browserToggle !== false)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              browserToggle
              && t('candidateDetails.browserToggleTrue')
            }
          >
            {
              browserToggle && <BrowserToggle fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {
        (pastedAnswer !== null && pastedAnswer !== false)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              pastedAnswer
              && t('candidateDetails.pastedAnsTrue')
            }
          >
            {
              pastedAnswer && <CopyPaste fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {
        (thirdpartyVoice !== null && thirdpartyVoice !== false)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              thirdpartyVoice
              && t('candidateDetails.thirdPartyVoiceTrue')
            }
          >
            {
              thirdpartyVoice
              && <ThirdPartyVoice fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {
        (eyeContact !== null && eyeContact !== true)
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              !eyeContact
              && t('candidateDetails.eyeContactFalse')
            }
          >
            {
              !eyeContact
              && <EyeContact fill="#C92A2A" />
            }
          </InfoTooltip>
        )
      }
      {/* {
        suspiciousObjectExists !== null
        && (
          <InfoTooltip
            placement="top"
            tooltipContent={
              suspiciousObjectExists
                ? ('candidateDetails.suspiciousObjectTrue')
                : ('candidateDetails.suspiciousObjectFalse')
            }
          >
            {
              !suspiciousObjectExists
                ? <SuspiciousObject stroke="#2B8A3E" />
                : <SuspiciousObject stroke="#C92A2A" />
            }
          </InfoTooltip>
        )
      } */}
    </div>
  );

  return (
    <React.Fragment>
      <div
        className={classnames(parentComponent !== 'CandidateDetailsAIA'
          ? styles.candidateInfoParentContainer
          : styles.candidateInfoParentContainerAIA)}
      >
        <div className={classnames(styles.candidateInformationContainer)}>
          <div className={styles.CandidateInformationGrid}>
            <div
              className={styles.candidateInfoOverallScoreSection}
              onClick={handleScrollToScoring}
              onKeyDown={handleScrollToScoring}
              tabIndex={0}
              role="button"
              type="button"
            >
              <div style={{ textAlign: 'center' }}>
                <OverallScoreIcon />
              </div>
              <div className={styles.candidateInfoOverallScore}>
                {
                  !isFromShareShortList
                    ? totalOverallScore.toFixed(0)
                    : candidateData.totalScore.toFixed(0)
                }
                %
              </div>
              <div className={styles.candidateInfoOverallText}>
                {t('candidateDetails.overallScore')}
              </div>
            </div>
            <div style={{ marginBottom: '0.5rem' }}>
              <div className={styles.candidateInfoRightSection1}>
                <div className={styles.candidateImageAndDetailsSection}>
                  <div>
                    {
                      candidateData.img ? (
                        <>
                          {
                            config.ENABLE_FRAUD_DETECTION.includes(orgId)
                              && candidateData.identity_proof_exists
                              ? (
                                <div
                                  className={styles.candidateThumbnailSection}
                                  onClick={displayIdentityModal}
                                  onKeyDown={displayIdentityModal}
                                  role="button"
                                  type="button"
                                  tabIndex={0}
                                >
                                  <img
                                    alt="candidate"
                                    src={candidateData.img}
                                    className={classnames(styles.candidateImg)}
                                  />
                                  {
                                    candidateData.identityFraudRange === 'Low'
                                    && (
                                      <LowMatchIcon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#FF0000"
                                      />
                                    )
                                  }
                                  {
                                    candidateData.identityFraudRange === 'Medium'
                                    && (
                                      <MediumMatchIcon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#FFE500"
                                      />
                                    )
                                  }
                                  {
                                    candidateData.identityFraudRange === 'High'
                                    && (
                                      <Verfiedicon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#37B24D"
                                      />
                                    )
                                  }
                                </div>
                              )
                              : (
                                <img
                                  alt="candidate"
                                  src={candidateData.img}
                                  className={classnames(styles.candidateImg)}
                                />
                              )
                          }
                        </>
                      ) : (
                        <>
                          {
                            config.ENABLE_FRAUD_DETECTION.includes(orgId)
                              && candidateData.identity_proof_exists
                              ? (
                                <div className={styles.candidateThumbnailSection}>
                                  <div
                                    className={classnames(styles.candidateInitial)}
                                    onClick={displayIdentityModal}
                                    onKeyDown={displayIdentityModal}
                                    type="button"
                                    role="button"
                                    tabIndex={0}
                                  >
                                    {candidateData.name && getInitial(candidateData.name)}
                                  </div>
                                  {
                                    candidateData.identityFraudRange === 'Low'
                                    && (
                                      <LowMatchIcon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#FF0000"
                                      />
                                    )
                                  }
                                  {
                                    candidateData.identityFraudRange === 'Medium'
                                    && (
                                      <MediumMatchIcon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#FFE500"
                                      />
                                    )
                                  }
                                  {
                                    candidateData.identityFraudRange === 'High'
                                    && (
                                      <Verfiedicon
                                        className={styles.verfiedIcon}
                                        width="22"
                                        height="21"
                                        fill="#37B24D"
                                      />
                                    )
                                  }
                                </div>
                              )
                              : (
                                <div className={classnames(styles.candidateInitial)}>
                                  {candidateData.name && getInitial(candidateData.name)}
                                </div>
                              )
                          }
                        </>
                      )
                    }
                  </div>
                  <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    gap: '0.5rem',
                  }}
                  >
                    <div className={styles.interviewName}>
                      <InfoTooltip
                        placement="top"
                        tooltipContent={interviewDetail.interviewname}
                      >
                        <button
                          className={styles.candidateInterviewName}
                          onClick={() => history.push(`/interviews/edit/${interviewId}/candidates`)}
                          type="button"
                        >
                          {interviewDetail.interviewname}
                        </button>
                      </InfoTooltip>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <InfoTooltip placement="top" tooltipContent={candidateData.name}>
                        <div
                          className={styles.candidateNameInfo}
                        >
                          {candidateData.name}
                        </div>
                      </InfoTooltip>
                    </div>
                  </div>
                </div>
                <div className={styles.candidateNamelinkedinResumeSection}>
                  {/* Resume Icon */}
                  {
                    candidateData.resume && !isFromShareShortList && (
                      <div className={styles.centerElement}>
                        <a
                          disabled={candidateData.resume}
                          href={candidateData.resume}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ResumeIcon fill={`${candidateData.resume ? '#6196FE' : '#CDD3E1'}`} />
                        </a>
                      </div>
                    )
                  }
                  {
                    candidateData.sopDocument && interviewDetail.sop_mandatory && (
                      <InfoTooltip placement="top" tooltipContent="View SOP Document">
                        <div className={styles.centerElement}>
                          <a
                            disabled={candidateData.sopDocument}
                            href={candidateData.sopDocument}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className={styles.sopIconContainer}>
                              <SopIcon
                                fill={`${candidateData.sopDocument ? '#6196FE' : '#CDD3E1'}`}
                                stroke="#ffffff"
                              />
                            </div>
                          </a>
                        </div>
                      </InfoTooltip>
                    )
                  }

                  {
                    candidateData.resume && isFromShareShortList && true && (
                      <div className={styles.centerElement}>
                        <a
                          disabled={candidateData.resume}
                          href={candidateData.resume}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ResumeIcon fill={`${candidateData.resume ? '#6196FE' : '#CDD3E1'}`} />
                        </a>
                      </div>
                    )
                  }
                  {/* Linkedin Icon */}
                  {
                    candidateData.metadata.linkedinUrl && (
                      <div className={styles.centerElement}>
                        <InfoTooltip
                          placement="top"
                          tooltipContent={candidateData.metadata.linkedinUrl || '--'}
                        >

                          <div
                            className={classnames('f16-500-bold-dark')}
                          >
                            <a
                              href={candidateData.metadata.linkedinUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={!candidateData.metadata.linkedinUrl ? styles.nolinkedin : ''}
                            >
                              {candidateData.metadata.linkedinUrl
                                ? <LikedinIcon fill="#007BA7" />
                                : <LikedinIcon fill="#CDD3E1" />}
                            </a>
                          </div>
                        </InfoTooltip>
                      </div>
                    )
                  }
                  {/* Facebook Icon */}
                  {
                    candidateData.metadata.facebookUrl && (
                      <div className={styles.centerElement}>
                        <InfoTooltip
                          placement="top"
                          tooltipContent={candidateData.metadata.facebookUrl || '--'}
                        >

                          <div
                            className={classnames('f16-500-bold-dark')}
                          >
                            <a
                              href={candidateData.metadata.facebookUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={!candidateData.metadata.facebookUrl ? styles.nolinkedin : ''}
                            >
                              {candidateData.metadata.facebookUrl
                                ? <FacebookIcon fill="#0000ff" />
                                : <FacebookIcon fill="#bcbcbc" />}
                            </a>
                          </div>
                        </InfoTooltip>
                      </div>
                    )
                  }
                  {/* Instagram Icon */}
                  {
                    candidateData.metadata.instagramUrl && (
                      <div className={styles.centerElement}>
                        <InfoTooltip
                          placement="top"
                          tooltipContent={candidateData.metadata.instagramUrl || '--'}
                        >

                          <div
                            className={classnames('f16-500-bold-dark')}
                          >
                            <a
                              href={candidateData.metadata.instagramUrl}
                              rel="noopener noreferrer"
                              target="_blank"
                              className={!candidateData.metadata.instagramUrl ? styles.nolinkedin : ''}
                              style={{ marginTop: '0.5rem' }}
                            >
                              {candidateData.metadata.instagramUrl
                                ? <InstagramIcon fill="#ff0000" stroke="#ff0000" />
                                : <InstagramIcon fill="#bcbcbc" stroke="#ff0000" />}
                            </a>
                          </div>
                        </InfoTooltip>
                      </div>
                    )
                  }
                </div>
                <div className={styles.dateSection}>
                  {t('candidateDetails.appliedDate')}
                  :
                  {' '}
                  <br className={styles.nonDisplayBreak} />
                  <span>
                    {candidateData.dateresponded ? moment(
                      getYYYYMMDD(candidateData.dateresponded),
                    ).locale(userLanguage).format(
                      'll',
                    ) : '--'}
                  </span>
                </div>
                <div className={styles.centerElement}>
                  {
                    !isFromShareShortList
                    && parentComponent !== 'CandidateDetailsAIA'
                    && (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            gap: '0.8rem',
                          }}
                        >
                          <button
                            type="button"
                            className={styles.scheduleInterviewButton}
                            onClick={toggleShowScheduleInterviewModal}
                            disabled={candidateData.rejected}
                          >
                            {candidateData.scheduled_interview_date
                              ? (t('candidateDetails.changeSchedule'))
                              : (t('candidateDetails.scheduleInterview'))}
                          </button>
                          {
                            config.CANDIDATE_EXTRA_INFORMATION.includes(orgId) && (
                              <div className={styles.hireAndLocationSection}>
                                <div>
                                  {
                                    (!isFromShareShortList && parentComponent !== 'CandidateDetailsAIA') && (
                                      <Button
                                        className={styles.hireButton}
                                        disabled={candidateData.rejected || candidateData.hired}
                                        onClick={() => CandidateActions.hireCandidate(
                                          interviewId,
                                          candidateData.candidateid,
                                          candidateData.hired,
                                        )
                                        }
                                      >
                                        {candidateData.hired                //eslint-disable-line
                                          ? (
                                            isCoachingUser()
                                              ? t('candidateDetails.selected')
                                              : t('candidateDetails.hired')
                                          )
                                          : (
                                            isCoachingUser()
                                              ? t('candidateDetails.selectMe')
                                              : t('candidateDetails.hire')
                                          )}
                                      </Button>
                                    )
                                  }
                                </div>
                              </div>
                            )
                          }
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <DropdownButton
                              noCaret
                              pullRight
                              bsStyle="default"
                              id="member-actions"
                              className={styles.noborderDrpdwn}
                              title={<img src={More} alt="More function" />}
                            >
                              <MenuItem
                                eventKey="change-background-image-item"
                                onClick={() => {
                                  downloadCandidateReport(
                                    candidateData.candidateid,
                                    candidateData.name,
                                    candidateData.email,
                                    candidateData.img,
                                    candidateData,
                                    coachingCandidateId,
                                    interviewId,
                                    candidateData.videoScore,
                                  );
                                }}
                              >
                                {t('candidateDetails.downloadReport')}
                              </MenuItem>
                              <MenuItem eventKey="change-background-image-item" onClick={shareShortlist}>
                                {t('candidateDetails.shareProfile')}
                              </MenuItem>
                              <ShareCandidatesModal
                                shareableLink=""
                                onCandidatesShareSubmit={handleCandidatesShareSubmit}
                                show={showShareShortlistModal}
                                onHide={toggleShowShortlistedModal}
                                interviewName={interviewDetail.interviewname}
                                sharer={Auth.getProfileInfo('firstName') || Auth.getProfileInfo('email')}
                                isShareThisProfile
                                candidateData={candidateData}
                                interviewDetail={interviewDetail}
                                interviewId={interviewId}
                                removeBranding={removeBranding}
                              />
                              <MenuItem
                                eventKey="change-background-image-item"
                                onClick={() => {
                                  sendCandidateReport(
                                    candidateData.candidateid,
                                    candidateData.name,
                                    candidateData.email,
                                    candidateData.img,
                                    candidateData,
                                    coachingCandidateId,
                                    interviewId,
                                    candidateData.totalScore,
                                  );
                                }}
                              >
                                {
                                  isCoachingUser()
                                    ? t('candidateDetails.sendReportCoaching')
                                    : t('candidateDetails.sendReport')
                                }
                              </MenuItem>
                            </DropdownButton>
                          </div>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
              <div className={styles.candidateInfoRightSection2}>
                {
                  config.CANDIDATE_EXTRA_INFORMATION.includes(orgId) && (
                    <MoreCandidateInformation />
                  )
                }
                {
                  !config.CANDIDATE_EXTRA_INFORMATION.includes(orgId) && (
                    <div className={styles.hireAndLocationSection}>
                      <div>
                        {
                          (!isFromShareShortList && parentComponent !== 'CandidateDetailsAIA') && (
                            <Button
                              className={styles.hireButton}
                              disabled={candidateData.rejected || candidateData.hired}
                              onClick={() => CandidateActions.hireCandidate(
                                interviewId,
                                candidateData.candidateid,
                                candidateData.hired,
                              )
                              }
                            >
                              {candidateData.hired                //eslint-disable-line
                                ? (
                                  isCoachingUser()
                                    ? t('candidateDetails.selected')
                                    : t('candidateDetails.hired')
                                )
                                : (
                                  isCoachingUser()
                                    ? t('candidateDetails.selectMe')
                                    : t('candidateDetails.hire')
                                )}
                            </Button>
                          )
                        }
                      </div>
                    </div>
                  )
                }
                <div
                  style={{
                    display: 'flex',
                    gap: '0.5rem',
                  }}
                >
                  <div className={styles.centerElement}>
                    <MapPin height="16" width="15" stroke="#7989B1" />
                  </div>
                  <div className={styles.centerElement}>
                    <InfoTooltip
                      placement="top"
                      tooltipContent={candidateData.metadata.location ? candidateData.metadata.location : '-'}
                    >
                      <div
                        className={classnames('f16-500-bold-dark')}
                      >
                        {candidateData.metadata.location
                          ? candidateData.metadata.location
                          : '--'}
                      </div>
                    </InfoTooltip>
                  </div>
                </div>
                <div className={styles.phoneNumberSection}>
                  <div className={styles.centerElement}>
                    <PhoneIcon height="18" width="18" fill="#7989B1" />
                  </div>
                  <InfoTooltip placement="top" tooltipContent={candidateData.phone ? candidateData.phone : '--'}>

                    <div
                      className={classnames(styles.centerElement, 'f16-500-bold-dark')}
                    >
                      {candidateData.phone ? candidateData.phone : '--'}
                    </div>
                  </InfoTooltip>
                </div>
                <div style={{
                  display: ' flex',
                  flexDirection: ' column',
                  justifyContent: 'center',
                }}
                >
                  <div className={styles.emailContainer}>
                    <div className={styles.centerElement}>
                      <EmailIcon height="18" width="18" fill="#7989B1" />
                    </div>
                    <div className={styles.centerElement}>
                      <InfoTooltip placement="top" tooltipContent={candidateData.email}>
                        <div className={styles.copyContainer}>
                          <span
                            className={classnames(styles.candidateEmail)}
                            ref={inputRef}
                          >
                            {candidateData.email}
                          </span>
                          <button
                            type="button"
                            onClick={copyToClipboard}
                          >
                            <CopyIcon width="18" height="18" stroke="#16182F" strokeWidth="1.5" />
                          </button>
                        </div>
                      </InfoTooltip>
                    </div>
                  </div>

                  <div className={styles.tabRootContainer}>
                    <div className={styles.borderDiv} />
                    <div className={styles.tabContainer}>
                      <div className={styles.centerElement}>
                        <button
                          type="button"
                          className={styles.tabButton}
                          onClick={() => handleTabSelection('info')}
                        >
                          <span className={activeTab === 'info' ? styles.activeTabText : styles.tabText}>Info Details</span>
                        </button>
                        <div className={activeTab === 'info' ? styles.tabBar : styles.nonDisplay} />
                      </div>
                      <div className={styles.centerElement}>
                        <button
                          type="button"
                          className={styles.tabButton}
                          onClick={() => handleTabSelection('videoSection')}
                        >
                          <span className={activeTab === 'videoSection' ? styles.activeTabText : styles.tabText}>Video Transcript</span>
                        </button>
                        <div className={activeTab === 'videoSection' ? styles.tabBar : styles.nonDisplay} />
                      </div>
                      <div>
                        <button
                          type="button"
                          className={styles.tabButton}
                          onClick={() => handleTabSelection('scoreSection')}
                        >
                          <span className={activeTab === 'scoreSection' ? styles.activeTabText : styles.tabText}>Score</span>
                        </button>
                        <div className={activeTab === 'scoreSection' ? styles.tabBar : styles.nonDisplay} />
                      </div>
                      <div>
                        <button
                          type="button"
                          className={styles.tabButton}
                          onClick={() => handleTabSelection('feedback')}
                        >
                          <span className={activeTab === 'feedback' ? styles.activeTabText : styles.tabText}>Feedback</span>
                        </button>
                        <div className={activeTab === 'feedback' ? styles.tabBar : styles.nonDisplay} />
                      </div>
                    </div>
                    <div className={styles.borderDiv} />
                  </div>
                </div>
              </div>
              <div className={styles.candidateInfoRightSection3}>
                {
                  candidateData
                    .coaching_candidate_id && interviewDetail
                    .coaching_candidate_id_label && (
                    <div title={candidateData.coaching_candidate_id}>
                      <span>
                        {interviewDetail.coaching_candidate_id_label}
                        :
                      </span>
                      <span>
                        {candidateData.coaching_candidate_id}
                      </span>
                    </div>
                  )
                }
              </div>
              {
                (parentComponent === 'CandidateDetailsAIA' && isRecommended) && (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      marginTop: '1rem',
                    }}
                  >
                    <div
                      className={styles.recommendedBtn}
                    >
                      Potensi tinggi
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        {
          !isCoachingUser() && (
            <div
              className={parentComponent === 'CandidateDetailsAIA'
                ? styles.buttonSectionContainerAIA
                : styles.buttonSectionContainer}
            >
              {
                !isFromShareShortList ? (
                  <React.Fragment>
                    <InfoTooltip
                      placement="top"
                      tooltipContent={candidateData.shortlisted    //eslint-disable-line
                        ? (
                          isCoachingUser()
                            ? t('candidateDetails.applicantShortlisted')
                            : t('candidateDetails.candidateShortlisted')
                        )
                        : (
                          isCoachingUser()
                            ? t('candidateDetails.shortlistApplicant')
                            : t('candidateDetails.shortlistCandidate')
                        )}
                    >
                      <div>
                        <button
                          type="button"
                          className={`${styles.candidateSelectionButtons} ${candidateData.shortlisted ? styles.activeCandidateSelectionButtons : ''}`}
                          onClick={() => CandidateActions.shortlistCandidate(
                            interviewId,
                            candidateData.candidateid,
                            candidateData.shortlisted,
                          )}
                        >
                          <TickIcon width="16" height="12" stroke={candidateData.shortlisted ? '#2B8A3E' : '#1C2640'} />
                          <span className={` ${candidateData.shortlisted ? styles.buttonTextActive : styles.buttonText}`}>{candidateData.shortlisted ? t('overviewPage.shortlisted') : t('shareProfile.shortlist')}</span>
                        </button>
                      </div>
                    </InfoTooltip>


                    <InfoTooltip placement="top" tooltipContent={candidateData.kived ? (t('candidateDetails.candidateKived')) : (t('candidateDetails.kivCandidate'))}>
                      <div>
                        <button
                          type="button"
                          className={`${styles.candidateSelectionButtons} ${candidateData.kived ? styles.activeCandidateSelectionButtons : ''}`}
                          onClick={() => CandidateActions.kivCandidate(
                            interviewId,
                            candidateData.candidateid,
                            candidateData.kived,
                          )}
                        >
                          <EyeIcon width="20" height="21" stroke={candidateData.kived ? '#2B8A3E' : '#1C2640'} />
                          <span className={` ${candidateData.kived ? styles.buttonTextActive : styles.buttonText}`}>{candidateData.kived ? (t('candidateDetails.keptInView')) : (t('candidateDetails.keepInView'))}</span>
                        </button>
                      </div>
                    </InfoTooltip>

                    <InfoTooltip placement="top" tooltipContent={candidateData.rejected ? (t('candidateDetails.candidateRejected')) : (t('candidateDetails.rejectCandidate'))}>
                      <div>
                        <button
                          type="button"
                          className={`${styles.candidateSelectionButtons} ${candidateData.rejected ? styles.activeCandidateSelectionButtonsdanger : ''}`}
                          onClick={CandidateActions.openRejectCandidateModal}
                        >
                          <CrossIcon width="20" height="21" stroke={candidateData.rejected ? '#FE5819' : '#1C2640'} />
                          <span className={` ${candidateData.rejected ? styles.buttonTextActive : styles.buttonText}`}>{candidateData.rejected ? (t('candidateDetails.rejected')) : (t('candidateDetails.reject'))}</span>
                        </button>
                      </div>
                    </InfoTooltip>
                  </React.Fragment>

                ) : (
                  <HiringManagerActionsComponent
                    candidateID={candidateData.candidateid}
                    hiringManagerEmail={hiringManagerEmail}
                  />
                )
              }
            </div>
          )
        }

        {
          candidateData.eligibleForCalendly ? (
            <ScheduleCalendlyEmailPreview
              candidateData={candidateData}
              interviewId={interviewId}
              jobName={interviewDetail.interviewname || ''}
              companyName={overwrittenCompanyName || interviewDetail.company_name}
              companyLogoUrl={overwrittenLogo || interviewDetail.company_logo_url}
              showScheduleInterviewModal={showScheduleInterviewModal}
              eventTypeData={eventTypeData}
              chosenEventTypeUrl={chosenEventTypeUrl}
              currentNameAttribute={currentNameAttribute}
              onHide={toggleShowScheduleInterviewModal}
              interviewLanguage={interviewDetail.interview_language}
              removeBranding={removeBranding}
            />
          ) : (
            <ScheduleInterviewEmailPreview
              candidateData={candidateData}
              interviewId={interviewId}
              jobName={interviewDetail.interviewname || ''}
              companyName={overwrittenCompanyName || interviewDetail.company_name}
              companyLogoUrl={overwrittenLogo || interviewDetail.company_logo_url}
              showScheduleInterviewModal={showScheduleInterviewModal}
              onHide={toggleShowScheduleInterviewModal}
              interviewLanguage={interviewDetail.interview_language}
              removeBranding={removeBranding}
            />
          )
        }

        <RejectModalEmailPreview
          interviewId={interviewId}
          interviewName={interviewDetail.interviewname}
          companyName={interviewDetail.company_name}
          companyLogoUrl={interviewDetail.company_logo_url}
          candidatesData={[candidateData]}
          interviewLanguage={interviewDetail.interview_language}
          removeBranding={removeBranding}
        />
      </div>
      {
        !isFromShareShortList
        && (candidateData.scheduled_interview_date
          || candidateData.third_party_scheduled_type) && (
          <div className={styles.interviewScheduleDate}>
            {candidateData.scheduled_interview_date && (
              <div>
                <div className={styles.scheduleInterviewHeading}>
                  {t('candidateDetails.inviteActivity')}
                </div>
                <div className={styles.scheduleInterviewText}>
                  {t('candidateDetails.interviewScheduledOn')}
                  &nbsp;
                  {dateToHumanReadableFormat(
                    candidateData.scheduled_interview_date,
                  )}
                </div>
              </div>
            )}
            {candidateData.third_party_scheduled_type && (
              <div>
                <div className={styles.scheduleInterviewHeading}>
                  {t('candidateDetails.activity')}
                </div>
                <div className={styles.scheduleInterviewText}>
                  {t('candidateDetails.contactedUser')}
                  &nbsp;
                  {dateToHumanReadableFormat(candidateData.contacted_date)}
                  {` with ${capitaliseFirstLetter(
                    candidateData.third_party_scheduled_type,
                  )}`}
                </div>
              </div>
            )}
          </div>
        )
      }
    </React.Fragment>
  );
};


CandidateInformation.propTypes = {
  candidateData: PropTypes.shape({
    img: PropTypes.string,
    name: PropTypes.string,
    metadata: PropTypes.object, // eslint-disable-line
    datesent: PropTypes.string,
    dateresponded: PropTypes.string,
    rejected: PropTypes.bool,
    scheduled_interview_date: PropTypes.string,
    candidateid: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    coaching_candidate_id: PropTypes.string,
    contacted_date: PropTypes.string,
    third_party_scheduled_type: PropTypes.string,
    shortlisted: PropTypes.bool,
    kived: PropTypes.bool,
    hired: PropTypes.bool,
    resume: PropTypes.string,
    eligibleForCalendly: PropTypes.bool,
    totalScore: PropTypes.number.isRequired,
    videoScore: PropTypes.number.isRequired,
    identity_proof_exists: PropTypes.bool.isRequired,
    identityFraudRange: PropTypes.string,
    sopDocument: PropTypes.string,
    valid_identification_confidence_score: PropTypes.bool.isRequired,
    audio_exists: PropTypes.bool.isRequired,
    browser_toggle: PropTypes.bool.isRequired,
    pasted_answer: PropTypes.bool.isRequired,
    third_party_voice_exists: PropTypes.bool.isRequired,
    eye_contact_exists: PropTypes.bool.isRequired,
    // suspicious_object_exists: PropTypes.bool.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  interviewDetail: PropTypes.shape({
    overwriteFields: PropTypes.object, // eslint-disable-line
    interview_language: PropTypes.string.isRequired,
    company_logo_url: PropTypes.string.isRequired,
    company_name: PropTypes.string.isRequired,
    interviewname: PropTypes.string.isRequired,
    sop_mandatory: PropTypes.bool.isRequired,
    coaching_candidate_id_label: PropTypes.bool.isRequired,
  }).isRequired,
  coachingCandidateId: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  removeBranding: PropTypes.string.isRequired,
  eventTypeData: PropTypes.arrayOf(PropTypes.object).isRequired, // eslint-disable-line
  currentNameAttribute: PropTypes.string.isRequired,
  downloadCandidateReport: PropTypes.func.isRequired,
  sendCandidateReport: PropTypes.func.isRequired,
  chosenEventTypeUrl: PropTypes.string.isRequired,
  handleCandidatesShareSubmit: PropTypes.func.isRequired,
  // selectedCandidatesIndex: PropTypes.number.isRequired,
  isFromShareShortList: PropTypes.bool.isRequired,
  hiringManagerEmail: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  handleTabSelection: PropTypes.func.isRequired,
  totalOverallScore: PropTypes.number.isRequired,
  handleScrollToScoring: PropTypes.func.isRequired,
  parentComponent: PropTypes.string.isRequired,
  displayIdentityModal: PropTypes.func.isRequired,
  orgId: PropTypes.string.isRequired,
  isRecommended: PropTypes.bool.isRequired,
};

export default CandidateInformation;
