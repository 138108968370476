// @flow
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as React from 'react';


const InfoTooltip = ({
  tooltipContent, children, placement = 'right', ...props
}: {
  tooltipContent: React.Node,
  children: React.Node,
  placement: string,
}) => (
  <OverlayTrigger
    placement={placement}
    overlay={(
      <Tooltip id="info-level-desc" {...props}>
        {
          tooltipContent
        }
      </Tooltip>
    )}
    trigger={['hover', 'focus']}
  >
    {children || (
      <span
        className="glyphicon glyphicon-info-sign"
        style={{
          marginLeft: '5px',
        }}
      />
    )}
  </OverlayTrigger>
);

export default InfoTooltip;
