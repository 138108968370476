import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import { isMobile } from 'react-device-detect';
import NavigationBar from './components/NavigationBar/NavigationBar';
import CandidatesDetail from './pages/Candidates/CandidatesDetail';
import CopyrightScreen from './pages/CopyrightScreen';

import './css/App.css';
import 'react-toastify/dist/ReactToastify.min.css';
import { hideNavigationBar } from './utils/utils';


const App = ({ match }) => (
  (
    <div className="App">
      {
        (!hideNavigationBar(match.path) || !isMobile) && (
          <header className="App-header">
            <NavigationBar />
          </header>
        )
      }
      <CandidatesDetail interviewId={match.params.interviewId} />
      <ToastContainer autoClose={2000} />
      {/* <footer> */}
      <CopyrightScreen />
      {/* </footer> */}

    </div>
  )
);

App.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      interviewId: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};


export default App;
