const redirectUri = `${window.location.protocol}//${window.location.host}`;
const config = {
  DOMAIN: 'https://dashboard.interviewer.ai',
  RECORDING_DOMAIN: 'https://careers.interviewer.ai',
  AUTH0: {
    domain: 'id.interviewer.ai',
    managementDomain: 'interviewer-ai-prod.auth0.com',
    audience: 'td7EWu6QvS9CRFruHRXZODsW2EzC6U1w', // client id
    namespace: 'https://interviewer.ai/',
    redirectUri: `${redirectUri}/callback`,
  },
  GEO_LOCATION_API: {
    url: 'https://api.ipgeolocation.io/ipgeo',
    apiKey: 'c8b1493ae38349b5b7d290921fe20d91',
  },
  MIXPANEL_TOKEN: 'cea29de76d2bb466f7951a2003a7a938',
  STRIPE_PUBLISHABLE_KEY: 'pk_live_3CU6rb1tBPy1LPIxAKmHx5GJ',
  SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  INSIGHTS_SLACK_NOTIFICATION: 'https://hooks.slack.com/services/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',
  GTM: {
    id: 'GTM-P8NRK92',
    auth: 'PWX9TuvVsQVc-bYOFxOm-g',
    env: 'env-2',
  },
  // user that can download or under special coaching scoring user are considered career coaches
  CAN_DOWNLOAD_REPORT_USER: [
    '8f561561-2e72-4133-b8e0-a092e43e9b0d',
    '79d9ec0f-b3fc-42f6-923b-8236ce01aa55', // Sunny's account
    'df0e0f98-1ebc-4ba3-b9e1-c539b10f61a0', // Sasha
    'c8899162-7234-4e0a-9080-fddb12751c23', // NTU career service
    '74fb6ee2-167e-413d-97f5-f347c0d82ce2', // SIM career connect
    'b723d15e-a19d-4f58-8e64-f1a9cbffad19', // SIM Karen
    'bed1eb0d-8266-468e-a8d1-bfbbf6370415', // RP Joanna
    '25b2ed0c-633e-41dd-9146-9e457255d57c', // SP Mun Wai
    'a31aaa74-8d32-4b0d-a211-6c52ca813e30', // Career Education SG
    '2546046b-fbb1-4f62-82a1-c9e41a3394eb', // ITE
    'f249abb1-9231-4cf6-88d9-c1c97946ac7e', // ITE
    '4cea5bad-1e42-4447-9328-0b387f3fa40b', // ITE
    '5d920eda-77af-4d5d-9da3-7a5a12090171', // ITE
    '94381db8-23e6-4bbf-a2c0-e66adace78c7', // ITE
    'e05ef762-7b0f-45b8-9673-1c1255b5d0d1', // ITE
    '97dc3c59-d56a-457f-8a2a-d1f0f5e3c718', // Wan Lee Search4Maid HK
    '84295ac0-508e-4cf9-88b5-c19fb7c6a604', // MDIS
    '70dd8d4e-4016-4768-b47c-3b02269fbeac', // PSB Academy
    '0f1ffe2c-59cc-4a74-86a1-80000af2e0e1', // NP
    '46b22751-2442-4c42-bdfe-4a5673898531', // Sdaocconi
    '03d4600e-6724-40e1-9e7d-86f85c273aa8', // HKUST,
    '57ed137d-2bf3-446f-917a-a571ff75b6aa', // Solomon Sisolprojects
    '0bb4940d-0476-4388-88c6-98718db01f82', // NTU Business School
    '53f7a7e1-fbe2-49ef-8405-523b1d2daa4f', // ITE
    '7ff43c35-0e92-4368-b8f7-94e8d3c0f8c5', // ITE
    '4e1075bb-a6f6-4554-9e45-d45151f37dae', // ITE
    '08bb5d25-3e01-425f-9b60-33cd77682855', // ITE
    '26495650-5b25-4d1b-aa1c-1db8e44e4493', // ITE
    'eccef7e0-a7b2-4fbb-95e0-a748a243845b', // ITE
    '4f0b7873-d1ad-419d-b99a-fb084c8e34cb', // sales@interviewer.ai
    '99347c50-7049-4a3e-8272-60f5efc12882', // ITE Central
    '0643c31b-c410-458c-b87b-8d597ccf23e2', // ilovetobe
    'd7b91caa-7f5e-46ae-ac79-ba7bfdab88ec', // AA Zuzana
    '483c6bcf-65aa-4b9d-acd5-4049e67eb6ed', // Temasek Poly Sathish
    '3abb4210-bd27-4492-a836-273574533df2', // antony.09a655cb@inboxeen.com
    'd9b0dbb7-4f05-4291-8d30-25d33e1cf752', // anna@eliteadvantageprep.com
    'e6f37286-8ad9-4d6e-853d-a080a2ba0cea', // Stephanie TP
    'a31aaa74-8d32-4b0d-a211-6c52ca813e30', // Career Education SG
  ],
  SPECIAL_COACHING_SCORING_USER: [
    '5018a213-debc-4124-b688-afc92356d194', // NTU Meiyi
    'a769657f-fecc-4d78-b2bc-0019594c7dfb', // NTU Yang Fan
    'b34450f7-c0f8-45db-ad87-e304cb4471bf', // SUSS
    '6e43e12b-fc37-4caa-9ef5-b9b49cae3b45', // Ngee Ann Poly
    '9b10ce1d-d044-4d61-a26c-16ce34a071f5', // Republic Poly
    '686cb8dd-c04b-49a1-abf2-562354b0245a', // Nanyang Institute of Management,
    'd93e8ba0-2f95-4fb5-a5bc-470d3fda490e', // TAR College,
    'add31301-5591-4194-989d-6316dbab336f', // NUS Jasbir
    'a520bd3e-1211-4768-889a-036b39f35ca2', // NUS Ryan
    'b7ed027a-1797-4719-a89f-9671fe976afb', // SIT Jeffrey
  ],
  HIGH_STANDARD_INTERVIEW_ID: [
    '42100b41-6cec-412d-b337-dd967b79a512',
    'fd542661-1589-4bda-ade9-82ae4e648461', // NTU MBA GS Challenge
  ],
  LOGO: 'https://interviewer-ai-assets.s3-ap-southeast-1.amazonaws.com/site/Interviewer-ai-logo-transparent-128x128.png',
  BRONZE_TRIAL: 45,
  GREY_SCALE_LOGO: 'https://interviewer-ai-assets.s3.amazonaws.com/grayscale-InterviewerAI-logo_ccexpress.png',
  CUSTOM_JOBFUNCTION_USER: ['d91c3deb-b9fc-4025-a543-fbe86988af1d', '4409de1a-7246-4c44-8782-93a32301f4ae', '3e8fdf83-1c6d-4942-8b82-38a06743d504', '7b5fe92a-1327-4450-b120-eb786950a992', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', 'eb5f24dd-9c19-4653-a6af-35cc2e4a3ecc', '43a984ad-4f1f-4de5-9822-114d7233636e'], // solve cube and Interviewer.ai
  CANDIDATE_RATED_FLAG_ORGID: ['4838ec0d-d785-4250-a64d-bc9612a8c36f', '181c12f1-e0da-4e3d-a691-b003740e5cce'],
  ENTERPRISE_CLIENTS: ['be36a9f7-a0ef-46b3-9cea-a24325aeee8d', 'f354feef-a22f-4519-a867-4d157a915aed', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b', '4409de1a-7246-4c44-8782-93a32301f4ae', '4be7427b-4428-435a-bf61-43b03934f0db', 'ed87bf1a-9c4c-4e00-8eeb-a7d9c4cf1fc8', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '05c9428a-5ff6-4f0e-beac-987879aae905', '68460fcc-7f40-4381-acfa-7280cdc15e4e', '4409de1a-7246-4c44-8782-93a32301f4ae'],
  PROMO_CODE: 'OXbq4apS',
  IAI_INTERNAL: ['4409de1a-7246-4c44-8782-93a32301f4ae'],
  VIDEO_INTERVIEW_GATE_PROMPT_ORGS: ['eba123b2-0d2f-4acb-b87d-5f74ddbf293b', '26d9ac49-db17-4414-887c-84e3c039d7c3', '4409de1a-7246-4c44-8782-93a32301f4ae', '3e8fdf83-1c6d-4942-8b82-38a06743d504', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c'],
  DELAY_INTERVIEW_ORGANIZATIONS: ['efc6b625-0bc4-459f-889e-7da39d166989', 'efc6b625-0bc4-459f-889e-7da39d166989', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b', '2d4bab6a-970a-4629-8b2b-7c206396e021', 'cdaad4d3-eb3b-4375-b01d-7f8914ca3f32'],
  EBANLE_VIETNAMESE_LANGUAGE: ['5afc333e-f543-4a7c-8007-8b3825666712', 'f900956c-1682-4ad4-beef-3e9f7347b97c', '5afc333e-f543-4a7c-8007-8b3825666712', 'c5f9d940-d583-477f-b56d-8c166c4ce106'],
  DOMAIN_QUESTION_SCORES: ['2d4bab6a-970a-4629-8b2b-7c206396e021', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b'],
  ENABLE_VIETNAMESE_FOR_HIRING_MANAGER: ['ketki@interviewer.ai', 'van.phanquoc@aia.com', 'thuy-l.vu@aia.com', 'thanh.truongminh@aia.com'],
  SENTRY_DSN: 'https://acbd793ce0fb4a45a2624b7755a86d6d@o384120.ingest.sentry.io/4504876736249856',
  ENABLE_FRAUD_DETECTION: ['4409de1a-7246-4c44-8782-93a32301f4ae', 'a6e9a828-edaf-4d54-85c0-357ecbeed44b', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '4409de1a-7246-4c44-8782-93a32301f4ae', '05c9428a-5ff6-4f0e-beac-987879aae905', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '24f997c9-5be1-4c8b-8bf5-bec90a3e9fd2', '725d45e7-d4e0-4db0-b9d2-5143a6b3fc94', 'fa32f7e9-f5d0-45a2-a245-28471e2ab042', '039897e0-61ab-4a18-9cca-eb7be6b0ea07', '19cf7ace-47c8-4d42-a7a2-874b96412945', 'd8da23c1-c2b3-4bc4-839f-2fed59eb41d9', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', '142c5be4-64bc-4d98-be5c-8bbca0e9da61', '9c6cb6a9-188b-4e3b-a09c-f63a3dc4196c', '40dbd00a-c3e2-4a05-879a-87d79c85dc6f', '86199824-03a0-4710-a118-f99750b8b200', '81bb4154-22d2-441e-a063-1d107ff3cd04', '086fd76b-4c2c-4f7d-8760-ab066d64de47', '2718a967-8fde-4e4a-84b6-4459a2ad001c'],
  INSIGHTS_PAYMENT_LINK: 'https://buy.stripe.com/fZeeYRgfjdQw2KkbJh',
  DEEP_STACK_RANKING: ['05c9428a-5ff6-4f0e-beac-987879aae905', '68460fcc-7f40-4381-acfa-7280cdc15e4e', '4409de1a-7246-4c44-8782-93a32301f4ae', '2d4bab6a-970a-4629-8b2b-7c206396e021'],
  AIA_API_ENDPOINT: 'https://dc2.aia-financial.co.id/app/gateway/iRecruite/2.0',
  AIA_ORG_ID: ['1922015b-cec6-4d22-82d7-f98086557727', 'adbd3694-8d88-415d-966c-909ec6045b7c', '2d4bab6a-970a-4629-8b2b-7c206396e021', 'b272807f-79a4-4b22-8963-82bf2d1998b5'],
  AIA_GATEWAY_KEY: '6cac878d-f7f8-4162-9431-42584f545034',
  SHOW_REATTEMPT_SUBMISSION: ['5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '4409de1a-7246-4c44-8782-93a32301f4ae', '142c5be4-64bc-4d98-be5c-8bbca0e9da61', '9c6cb6a9-188b-4e3b-a09c-f63a3dc4196c', '40dbd00a-c3e2-4a05-879a-87d79c85dc6f', '86199824-03a0-4710-a118-f99750b8b200', '81bb4154-22d2-441e-a063-1d107ff3cd04', '086fd76b-4c2c-4f7d-8760-ab066d64de47', '2718a967-8fde-4e4a-84b6-4459a2ad001c'],
  SOP_FEATURE: ['caa487ad-1fd9-4198-bd67-adcbbe4bdc4d'],
  HIDE_RESUME_TOGGLE: [],
  GENERATE_VIDEO_INTERVIEW_QUESTION: ['4409de1a-7246-4c44-8782-93a32301f4ae', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '0a2aaeb0-0755-487d-8c6f-39d13e4be848', '95b7037f-d196-4588-90af-975882ebbc48', '1eb1a556-d19c-41e2-a5a3-ac1e82f168e2', '7bec8e2f-e2f8-44b8-a0b0-53cdc1aacf40', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '24f997c9-5be1-4c8b-8bf5-bec90a3e9fd2', '05c9428a-5ff6-4f0e-beac-987879aae905', '039897e0-61ab-4a18-9cca-eb7be6b0ea07', '19cf7ace-47c8-4d42-a7a2-874b96412945', '3bbac42f-0a8a-459b-9567-fc068c34441d', '43a984ad-4f1f-4de5-9822-114d7233636e', '993c14d0-0b22-43e5-8e36-075a46ed5aef'],
  INTERVIEW_REFERENCE_DOCUMENT: ['145f3b13-23c5-49cf-890f-284f08171046', 'cdaad4d3-eb3b-4375-b01d-7f8914ca3f32', '7bec8e2f-e2f8-44b8-a0b0-53cdc1aacf40', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '05c9428a-5ff6-4f0e-beac-987879aae905', '4409de1a-7246-4c44-8782-93a32301f4ae', '5cf6d807-3f14-49d6-96fc-780d1d47a835', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '5bbdc9b3-bf84-43a4-983a-6a27145599a2', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', '993c14d0-0b22-43e5-8e36-075a46ed5aef', '510112a8-a4bf-4c36-bb57-3ea20cdf9296'],
  INTERVIEW_IDEAL_ANSWER: ['145f3b13-23c5-49cf-890f-284f08171046', 'cdaad4d3-eb3b-4375-b01d-7f8914ca3f32', '7bec8e2f-e2f8-44b8-a0b0-53cdc1aacf40', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '05c9428a-5ff6-4f0e-beac-987879aae905', '4409de1a-7246-4c44-8782-93a32301f4ae', '5cf6d807-3f14-49d6-96fc-780d1d47a835', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '5bbdc9b3-bf84-43a4-983a-6a27145599a2', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', '993c14d0-0b22-43e5-8e36-075a46ed5aef', '510112a8-a4bf-4c36-bb57-3ea20cdf9296', 'edea5709-caf6-42b9-988c-9dd5d41bef4c', '993c14d0-0b22-43e5-8e36-075a46ed5aef', '142c5be4-64bc-4d98-be5c-8bbca0e9da61', '9c6cb6a9-188b-4e3b-a09c-f63a3dc4196c', '40dbd00a-c3e2-4a05-879a-87d79c85dc6f', '86199824-03a0-4710-a118-f99750b8b200', '81bb4154-22d2-441e-a063-1d107ff3cd04', '086fd76b-4c2c-4f7d-8760-ab066d64de47', '2718a967-8fde-4e4a-84b6-4459a2ad001c'],
  CANDIDATE_EXTRA_INFORMATION: ['05c9428a-5ff6-4f0e-beac-987879aae905', '4409de1a-7246-4c44-8782-93a32301f4ae', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', '91c01ecb-3b11-4b77-b42c-01c428af53bc', '24f997c9-5be1-4c8b-8bf5-bec90a3e9fd2', '725d45e7-d4e0-4db0-b9d2-5143a6b3fc94', '19a3ced9-329f-4522-9687-51886a0a1e55', 'fa32f7e9-f5d0-45a2-a245-28471e2ab042', '039897e0-61ab-4a18-9cca-eb7be6b0ea07', '19cf7ace-47c8-4d42-a7a2-874b96412945', 'd8da23c1-c2b3-4bc4-839f-2fed59eb41d9', 'ea87be7f-ed92-43b6-8e25-11a4d7a58944', '142c5be4-64bc-4d98-be5c-8bbca0e9da61', '9c6cb6a9-188b-4e3b-a09c-f63a3dc4196c', '40dbd00a-c3e2-4a05-879a-87d79c85dc6f', '86199824-03a0-4710-a118-f99750b8b200', '81bb4154-22d2-441e-a063-1d107ff3cd04', '086fd76b-4c2c-4f7d-8760-ab066d64de47', '2718a967-8fde-4e4a-84b6-4459a2ad001c'],
  CATEGORY_HASTAG_FEATURE: ['145f3b13-23c5-49cf-890f-284f08171046', '4409de1a-7246-4c44-8782-93a32301f4ae', '05c9428a-5ff6-4f0e-beac-987879aae905', '510112a8-a4bf-4c36-bb57-3ea20cdf9296'],
  TOGGLE_CONTENT_RELEVANCY: ['4409de1a-7246-4c44-8782-93a32301f4ae', '5cf6d807-3f14-49d6-96fc-780d1d47a835', 'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d', '5bbdc9b3-bf84-43a4-983a-6a27145599a2', '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', '993c14d0-0b22-43e5-8e36-075a46ed5aef', 'edea5709-caf6-42b9-988c-9dd5d41bef4c', '05c9428a-5ff6-4f0e-beac-987879aae905', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', 'cd9dc728-30c0-4568-8d77-9e8db9e2c952'],
  AIA_ORG_IRECRUIT_LOGIN: 'b272807f-79a4-4b22-8963-82bf2d1998b5',
  CANDIDATE_EVENT_ID: ['05c9428a-5ff6-4f0e-beac-987879aae905'],
  TRIAL_PLAN_ID: 45,
  CONTENT_RELEVANCY: [ // Primarily for CSV Download
    'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d',
    '4409de1a-7246-4c44-8782-93a32301f4ae',
    '5c8b8993-fe55-4a4b-9e73-0ab71b4c9161', // Ganjendra
    '95b7037f-d196-4588-90af-975882ebbc48',
    '1eb1a556-d19c-41e2-a5a3-ac1e82f168e2',
    '145f3b13-23c5-49cf-890f-284f08171046', // tien-hoang.tran@aia.com
    'cdaad4d3-eb3b-4375-b01d-7f8914ca3f32', // Emaar
    '0a2aaeb0-0755-487d-8c6f-39d13e4be848', // adaniaitools@adani.com
    '7bec8e2f-e2f8-44b8-a0b0-53cdc1aacf40', // NBS Academy for Career Excellence (ACE)
    '91c01ecb-3b11-4b77-b42c-01c428af53bc', // wsg.gov.sg
    '05c9428a-5ff6-4f0e-beac-987879aae905', // Merittrac
    '4c6cbdd2-e515-41cd-a597-f24f6e06e66f', // mhymatch
    '91c01ecb-3b11-4b77-b42c-01c428af53bc', // wsg
    '24f997c9-5be1-4c8b-8bf5-bec90a3e9fd2', // AIA Vietnam
    '725d45e7-d4e0-4db0-b9d2-5143a6b3fc94', // nomiso
    '19a3ced9-329f-4522-9687-51886a0a1e55', // eighty2i
    '19cf7ace-47c8-4d42-a7a2-874b96412945', // ite.edu.sg
    '039897e0-61ab-4a18-9cca-eb7be6b0ea07', // edfora.com
    'd8da23c1-c2b3-4bc4-839f-2fed59eb41d9', // snsgroups.com,
    '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', // optimumcare4u.com
    '993c14d0-0b22-43e5-8e36-075a46ed5aef', // Nykaa
    '3bbac42f-0a8a-459b-9567-fc068c34441d', // dstechnologiesinc.com
    'edea5709-caf6-42b9-988c-9dd5d41bef4c', // lntecc
    '7aab516c-ce74-4ddb-8e9e-2c791771f171', // perfettivanmelle.com
    'cd9dc728-30c0-4568-8d77-9e8db9e2c952', // fonlychu@ust.hk
  ],
  EDIT_DELETE_VIDEO_CATEGORY: ['4409de1a-7246-4c44-8782-93a32301f4ae', '510112a8-a4bf-4c36-bb57-3ea20cdf9296', '5d2b9f2c-27e8-4924-afaa-01f6c8da413c', '4409de1a-7246-4c44-8782-93a32301f4ae'],
  CANDIDATE_REACTIVATION_LINK: [
    'b272807f-79a4-4b22-8963-82bf2d1998b5',
    '5cf6d807-3f14-49d6-96fc-780d1d47a835',
    'caa487ad-1fd9-4198-bd67-adcbbe4bdc4d',
    '5bbdc9b3-bf84-43a4-983a-6a27145599a2',
    'e0fa8be1-95e1-4cda-a52c-f28df0f461d8',
    '2d4bab6a-970a-4629-8b2b-7c206396e021',
  ],
  HIDE_QUESTIONS: ['145f3b13-23c5-49cf-890f-284f08171046'],
  GENERAL_QUESTION_ID: 1,
  NEW_RELIC_APP_NAME: 'Interviewer AI Dashboard - Prod',
  NEW_RELIC_LICENSE_KEY: '0bcb4ec088a51760820a622ba38536c6FFFFNRAL',
  FEEDBACK_UP: ['2d4bab6a-970a-4629-8b2b-7c206396e021'],
  CANDIDATE_BULK_DOWNLOAD_TALENT_POOL: ['2d4bab6a-970a-4629-8b2b-7c206396e021'],
  SUPPORTED_NON_ENGLISH_COUNTRY_CODES: ['de', 'vi', 'es', 'fr'],
};

export default config;
