import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LoadingComponent from './LoadingComponent';
import { ReactComponent as ErrorImg } from '../assets/CallbackErrorIcon.svg';
import auth0Client from '../auth/Auth';
import {
  mxTrackEvent, mxUserProfile, getUrlParams,
} from '../utils/utils';
import { alertToast } from './Toast';

const {
  AIA_ACCOUNT_EMAIL_DOMAIN,
} = require('../utils/constants');

class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMsg: '',
    };
    this.updateUserMetadata = this.updateUserMetadata.bind(this);
    this.handleSocialSignup = this.handleSocialSignup.bind(this);
  }

  async componentDidMount() {
    const currentUrl = window.location.href.replace('#', '?');
    const params = getUrlParams(currentUrl);
    const { history } = this.props;
    try {
      await auth0Client.handleAuthentication();
      const email = auth0Client.getProfileInfo('email');
      if (auth0Client.getProfileInfo('is_new')) {
        // check if user exists with this account.
        let details = await fetch(`/signUpApi/checkUserExists/${email}`);
        details = await details.json();
        // userDetails = details;
        const { userDetails } = details;
        if (userDetails && userDetails.length) {
          await auth0Client.silentAuth();
          history.push({
            pathname: '/overview',
          });
        } else {
          let sosialSignupUser = { // eslint-disable-line
            lastName: auth0Client.getProfileInfo('lastName'),
            firstName: auth0Client.getProfileInfo('firstName'),
            email: auth0Client.getProfileInfo('email'),
            role: auth0Client.getProfileInfo('role'),
            source: 'ORGANIC',
            inviteToken: auth0Client.getProfileInfo('invite_token'),
          };
          const identity = auth0Client.getProfileInfo('is_social_signup');
          if (identity) {
            const identityProvider = auth0Client.getProfileInfo('source');
            if (identityProvider === 'ORGANIC-MICROSOFT') {
              sosialSignupUser.signup_source = 'microsoft';
            } else {
              sosialSignupUser.signup_source = 'google';
            }
            await this.handleSocialSignup(sosialSignupUser, identity);
          } else {
            sosialSignupUser.signup_source = 'manual';
            await this.handleSocialSignup(sosialSignupUser, identity);
            // history.replace('/settings/profile');
          }
          // await this.slackNotification(sosialSignupUser.email, sosialSignupUser.source);
        }
        // history.replace('/settings/profile');
      } else {
        // check if details exists in auth0
        const userId = await auth0Client.getProfileInfo('userId');
        const orgId = await auth0Client.getProfileInfo('orgId');
        if (!userId || !orgId) {
          await this.updateUserMetadata(auth0Client.getProfileInfo('email'));
        }
        await auth0Client.silentAuth();
        mxUserProfile(auth0Client.getProfileInfo('email'));
        mxTrackEvent('Login successfull', {
          email: auth0Client.getProfileInfo('email'),
        });
        const isEmailVerified = auth0Client.getProfileInfo('email_verified');
        const isInvitedMember = auth0Client.getProfileInfo('invite_token');
        // check if email is verified
        if (!isEmailVerified && !isInvitedMember) {
          auth0Client.signOut();
          window.location.href = '/signup/successful';
        } else if ((params.state.includes('http') || params.state.includes('https'))) {
          window.location = params.state;
        } else {
          history.replace('/overview');
        }
      }
    } catch (e) {
      console.log(e);
      this.setState({
        error: true,
        errorMsg: e.errorDescription,
      });
      // history.replace('/login');
      // setTimeout(() => history.replace('/logout'), 3000);
    }
  }

  async updateUserMetadata(email) { // eslint-disable-line
    try {
      const req = await fetch(`/signUpApi/user/metadata/${email}`, {
        method: 'PATCH',
      });
      const res = await req.json();
      return res;
    } catch (e) {
      console.error(e);
    }
  }

  async handleSocialSignup(userDetails, identity) {
    const { history } = this.props;
    try {
      if (!userDetails.email) {
        throw new Error('Missing details');
      }
      // await auth0Client.handleAuthentication();
      const companyName = '';
      // mxTrackEvent('Social Signing up', {
      //   email: userDetails.email,
      // });

      const updateDbReq = await fetch('/signUpApi/company', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          source: 'ORGANIC', // this is for notification for other channel partners, eg: Capterra
          email: userDetails.email,
          companyName,
          signup_source: userDetails.signup_source,
          inviteToken: userDetails.inviteToken,
          // isSocialSignup: true
        }),
      });
      const updateDbRes = await updateDbReq.json();
      console.log('updated response is.......', updateDbRes);
      if (updateDbReq.status !== 200) {
        throw new Error(updateDbRes.message);
      }
      // mxTrackEvent('Sign up successful', {
      //   email: userDetails.email,
      // });
      mxUserProfile(userDetails.email, userDetails.signup_source);
      mxTrackEvent('Sign up successful', {
        email: userDetails.email,
        signup_source: userDetails.signup_source,
      });

      // check if users email is verified and the
      // email should not be a team member email trying to signup for first time
      const isEmailVerified = auth0Client.getProfileInfo('email_verified');
      const isAIAIRecruitLogin = AIA_ACCOUNT_EMAIL_DOMAIN.includes(userDetails.email.split('@')[1]);
      const isInvitedMember = userDetails.inviteToken;
      if (!isInvitedMember && !isEmailVerified && auth0Client.getProfileInfo('is_new')) {
        await auth0Client.signOut();
        window.location.href = '/termsAndCondition?redirectTo=/signup/successful';
        // window.location.href = '/signup/successful';
      } else if (!isEmailVerified && !(auth0Client.getProfileInfo('is_new'))) {
        window.location.href = '/signup/successful';
      } else {
        await auth0Client.silentAuth();
        // history.replace('/termsandconditions');
        if (!(userDetails.inviteToken)) {
          window.location.href = isAIAIRecruitLogin ? '/overview' : '/termsAndCondition';
          // history.push({
          //   pathname: '/settings/company-profile?redirectURL=settings/billing',
          //   state: { identity },
          // });
        } else {
          await auth0Client.silentAuth();
          // await auth0Client.silentAuth();
          // let auth0User = await auth0.getUsersByEmail(email);
          // auth0User = auth0User.filter((user) => { //eslint-disable-line
          //   return (user.identities && user.identities.length >
          // 0 && user.identities[0].provider === 'auth0');
          // });
          mxUserProfile(userDetails.email, userDetails.signup_source);
          mxTrackEvent('Team member signup successfull', {
            email: userDetails.email,
            signup_source: userDetails.signup_source,
            companyName,
          });
          // if (!isEmailVerified) {
          // auth0Client.signOut();
          // } else {
          // await auth0Client.silentAuth();
          // TODO: add one key to localstorage to check if it is redirected in first time
          localStorage.setItem('onboardingFlow', true);
          history.push({
            pathname: '/overview',
            state: { identity },
          });
          // }
        }
      }
    } catch (e) {
      console.log(e);
      mxTrackEvent('Sign up error', {
        email: userDetails.email,
        err: e,
      });
      alertToast(e.message);
      this.setState({ error: true });
      this.setState({ errorMsg: e.message });
      setTimeout(() => history.replace('/logout'), 3000);
    }
  }

  async slackNotification(email, source) { // eslint-disable-line
    await fetch('/signUpApi/notifySlackNewSignup', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        email,
        source,
      }),
    });
  }

  render() {
    const { error, errorMsg } = this.state;

    if (error === true) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            fontWeight: '400',
          }}
        >
          <div>
            <ErrorImg width="260" height="258" />
          </div>
          <span>
            {errorMsg}
          </span>
          <div>
            <button
              style={{
                background: '#6196fe',
                color: '#fff',
                padding: '15px 25px',
                borderRadius: '12px',
                fontWeight: '400',
                marginTop: '5px',
              }}
              type="button"
              onClick={auth0Client.signupRedirection}
            >
              <span>Sign up</span>
            </button>
          </div>
          <div style={{ marginTop: '1rem' }}>
            <span>
              {'Are you a candidate? '}
            </span>
            <span>
              <a
                href="https://interviewer.ai/candidates/"
                target="_blank"
                style={{
                  fontWeight: '500',
                }}
                rel="noreferrer"
              >
                Signup here
              </a>
            </span>
          </div>
        </div>
      );
    }

    return (
      <LoadingComponent />
    );
  }
}

Callback.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(Callback);
