import React, { useState, useEffect } from 'react';
import {
  Button,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import '../../css/EmailPreview.css';
import '../../css/ReactDatesCalendar.css';
import moment from 'moment';
import fetch from 'isomorphic-fetch';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { mxTrackEvent, getEmailTemplate } from '../../utils/utils';
import CandidateActions from '../../actions/CandidateActions';
import ClipLoadingComponent from '../ClipLoadingComponent';
import {
  DEFAULT_INTERVIEW_DURATION,
  DEFAULT_INTERVIEW_DATETIME,
  DEFAULT_MOMENT,
  EMAIL_TYPE,
} from '../../utils/constants';
import Auth from '../../auth/Auth';
import { alertToast, successToast } from '../Toast';

const ScheduleInterviewEmailPreview = ({
  jobName,
  companyName,
  companyLogoUrl,
  interviewId,
  candidateData,
  showScheduleInterviewModal,
  onHide,
  interviewLanguage,
  removeBranding,
}) => {
  // persist scheduled_interview for calendly
  // convert template to state so the draft can be saved accordingly
  const { t } = useTranslation();
  const emailData = getEmailTemplate(false);
  const emailTemplate = EMAIL_TYPE.SCHEDULE_INTERVIEW_EMAIL;
  const firstName = Auth.getProfileInfo('firstName');
  const interviewerEmail = Auth.getProfileInfo('email');
  const [schedulingInterview, setSchedulingInterview] = useState(false);
  const [interviewDatetime, setInterviewDatetime] = useState(DEFAULT_INTERVIEW_DATETIME);
  const [interviewMoment, setInterviewMoment] = useState(DEFAULT_MOMENT);
  const [interviewDuration, setInterviewDuration] = useState(DEFAULT_INTERVIEW_DURATION);
  const [emailSubject, setEmailSubject] = useState(emailData.subject({ jobName, companyName }));

  const [footer, setFooter] = useState(`Best regards,\n${firstName}\n${companyName}`);
  const [content, setContent] = useState(emailData.body({
    jobName,
    companyName,
    interviewerEmail,
    interviewDuration,
    interviewDate: interviewMoment.format('dddd, DD MMM YYYY').toString(),
    interviewTime: interviewMoment.format('h:mm A').toString(),
  }));
  const [senderEmail, setSenderEmail] = useState('no-reply@interviewerai.com');

  const changeInterviewDatetime = (newInterviewDatetimeArr) => {
    const newInterviewMoment = moment(newInterviewDatetimeArr[0]);
    setInterviewMoment(newInterviewMoment);
  };

  const changeInterviewDuration = (e) => {
    const newInterviewDuration = e.target.value;
    setInterviewDuration(newInterviewDuration);
  };

  useEffect(() => {
    const newInterviewTime = interviewMoment.format('h:mm A').toString();
    const newInterviewDate = interviewMoment.format('dddd, DD MMM YYYY').toString();
    const emailDataContent = emailData.body({
      jobName,
      companyName,
      interviewerEmail,
      interviewDuration,
      interviewDate: newInterviewDate,
      interviewTime: newInterviewTime,
    });
    setContent(emailDataContent);
  }, [interviewMoment, interviewDuration]);


  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const getTemplateApi = `/api/email/template/${interviewId}?type=${emailTemplate}`;
        const req = await fetch(getTemplateApi, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
        });

        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          setEmailSubject(res.subject || emailSubject);
          setContent(res.message || content);
          setFooter(res.footer || footer);
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchTemplate();
  }, [showScheduleInterviewModal]);

  const scheduleInterview = async () => {
    setSchedulingInterview(true);
    const candidateId = candidateData.candidateid;
    const candidateEmail = candidateData.email;
    const candidateName = candidateData.name;
    const interviewDateTime = interviewMoment.format('YYYY-MM-DD HH:mm:ss ZZ');
    const interviewDate = interviewMoment.format('dddd, DD MMM YYYY');
    const interviewTime = interviewMoment.format('h:mm A');

    try {
      const postScheduleApi = `/api/email/scheduleInterview?type=${EMAIL_TYPE.SCHEDULE_INTERVIEW_EMAIL}&calendly=${false}`;
      const req = await fetch(postScheduleApi, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          candidateId,
          candidateEmail,
          candidateName,
          interviewId,
          interviewName: jobName,
          interviewerEmail,
          interviewDateTime,
          interviewDate,
          interviewTime,
          interviewDuration,
          companyName,
          companyLogoUrl,
          emailMessage: content,
          emailSubject,
          footerMessage: footer,
          language: interviewLanguage,
          removeBranding,
          senderEmail,
        }),
      });
      if (req.status >= 200 && req.status < 300) {
        successToast(`Successfully send interview invitation email to ${candidateName}`);
        mxTrackEvent('Interview scheduling email sent success', {
          jobName,
          interviewId,
          candidateEmail,
          candidateName,
        });
        CandidateActions.updateCandidateScheduleInterviewDate({
          candidateId,
          interviewDateTime,
        });
        setSchedulingInterview(false);
        setInterviewDatetime(DEFAULT_INTERVIEW_DATETIME);
        setInterviewDuration(DEFAULT_INTERVIEW_DURATION);
        setInterviewMoment(DEFAULT_MOMENT);
        onHide();
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      alertToast('Error in sending interview invitation email to candidate. Please try again later');
      mxTrackEvent('Interview scheduling email sent failure', {
        jobName,
        interviewId,
        candidateEmail,
        candidateName,
      });
      setSchedulingInterview(false);
    }
  };

  return (
    <div className="email-preview">
      <Modal
        show={showScheduleInterviewModal}
        onHide={() => {
          setInterviewMoment(DEFAULT_MOMENT);
          onHide();
        }}
        className="scheduleInterviewModule"
        bsSize="large"
      >
        <Modal.Header>
          <Modal.Title>{t('scheduleInterview.email')}</Modal.Title>
        </Modal.Header>
        <div>
          <Modal.Body>
            <div className="datetimeAndDurationContainer">
              <ControlLabel>
                {t('scheduleInterview.selectDateTimeDuration')}
              </ControlLabel>
              <p>
                {t('scheduleInterview.scheduleDescription')}
              </p>
              <Row>
                <Col sm={3} md={2}>
                  <p>{t('scheduleInterview.dateAndTime')}</p>
                </Col>
                <Col sm={9} md={4}>
                  <Flatpickr
                    data-enable-time
                    options={{
                      static: true,
                      minDate: 'today',
                    }}
                    value={interviewDatetime}
                    onChange={changeInterviewDatetime}
                    className="form-control"
                  />
                </Col>
                <Col sm={3} md={2}>
                  <p>{t('scheduleInterview.duration')}</p>
                </Col>
                <Col sm={9} md={4}>
                  <FormGroup controlId="formControlsSelect">
                    <FormControl
                      componentClass="select"
                      placeholder="select"
                      className=""
                      onChange={changeInterviewDuration}
                    >
                      <option value={30}>30 mins</option>
                      <option value={45}>45 mins</option>
                      <option value={60}>1 hour</option>
                      <option value={75}>1 hour 15 mins</option>
                      <option value={90}>1 hour 30 mins</option>
                    </FormControl>
                  </FormGroup>
                </Col>
              </Row>
              <hr className="no-margin-top" />
            </div>
            <ControlLabel>
              {t('scheduleInterview.sender')}
            </ControlLabel>
            <Form horizontal>
              <FormGroup controlId="senderEmail">
                <Col sm={10}>
                  <FormControl
                    componentClass="select"
                    name="senderEmail"
                    value={senderEmail}
                    onChange={e => setSenderEmail(e.target.value)}
                  >
                    <option value="no-reply@interviewerai.com">no-reply@interviewerai.com</option>
                    <option value={interviewerEmail}>{interviewerEmail}</option>
                  </FormControl>
                </Col>
              </FormGroup>
            </Form>
            <hr />
            <ControlLabel>
              {t('scheduleInterview.editEmailSubject')}
            </ControlLabel>
            <Form horizontal>
              <FormGroup controlId="emailSubject">
                <Col sm={10}>
                  <FormControl
                    type="text"
                    name="emailSubject"
                    className="email-preview__content--editable email-preview__content--editable-small"
                    value={emailSubject}
                    onChange={e => setEmailSubject(e.target.value)}
                  />
                </Col>
              </FormGroup>
            </Form>
            <hr />
            <ControlLabel>
              {t('scheduleInterview.checkEditEmailContent')}
            </ControlLabel>
            <div className="email-preview__content">
              <img
                src={companyLogoUrl}
                alt="Company logo"
                className="email-preview__content--logo img-responsive"
              />
              <h4>Dear [candidate-name],</h4>
              <textarea
                className="email-preview__content--editable"
                value={content}
                onChange={e => setContent(e.target.value)}
                onBlur={() => mxTrackEvent('Email content area changed')}
                maxLength={1500}
              />
              <br />
              <p>[candidate-name], we look forward to meeting you soon.</p>
              <br />
              <textarea
                className="email-preview__content--editable email-preview__content--editable-medium"
                value={footer}
                onChange={e => setFooter(e.target.value)}
                onBlur={() => mxTrackEvent('Email footer area change')}
                maxLength={300}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              id="schedule-button"
              className="btn-primary-custom"
              onClick={scheduleInterview}
              disabled={schedulingInterview}
            >
              {
                schedulingInterview ? (
                  <span>
                    Sending...
                    <ClipLoadingComponent inverse />
                  </span>
                ) : 'Schedule and send'
              }
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};

ScheduleInterviewEmailPreview.defaultProps = {
  interviewLanguage: 'en-US',
};


ScheduleInterviewEmailPreview.propTypes = {
  jobName: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  companyLogoUrl: PropTypes.string.isRequired,
  interviewId: PropTypes.string.isRequired,
  candidateData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    candidateid: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string,
    status: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    score: PropTypes.string,
    checked: PropTypes.bool,
    interviewLink: PropTypes.string.isRequired,
  }).isRequired,
  showScheduleInterviewModal: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  interviewLanguage: PropTypes.string,
  removeBranding: PropTypes.bool.isRequired,
};

export default ScheduleInterviewEmailPreview;
