import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import orderBy from 'lodash/orderBy';
import alt from '../alt';
import { mxTrackEvent } from '../utils/utils';
import 'react-toastify/dist/ReactToastify.min.css';
import { CANDIDATES_STATUS } from '../utils/constants';

class ShareActions {
  constructor() {
    this.getInterviewInfoFail = msg => msg;
    this.getInterviewInfoPass = data => data;
    this.getVideoUrlPass = data => data;
    this.getVideoUrlFail = msg => msg;
    this.setVideoCache = data => data;
    this.submitConsentSuccessful = data => data;
    this.updatePlanUserCount = (userType, count) => ({ userType, count });
    this.setAutoPlay = () => true;
    this.updateIndex = data => data;
  }

  async getInterviewInfo(interviewId, params) {
    try {
      mxTrackEvent('Retrieving interview information');
      const { friendliness, positivity, communication } = params;

      const friendlinessWeight = parseInt(friendliness || 5, 10);
      const positivityWeight = parseInt(positivity || 5, 10);
      const communicationWeight = parseInt(communication || 5, 10);

      const req = await fetch(`/shareApi/interview/${interviewId}`);
      if (req.status === 404) {
        this.getInterviewInfoFail('Interview not found');
        return false;
      }

      if (req.status < 200 || req.status >= 300) {
        throw new Error('Error loading interview and candidates information. :(');
      }

      const res = await req.json();
      const {
        interview,
        candidates,
      } = res;

      if (!candidates || candidates.length === 0) { // no data
        this.getInterviewInfoFail('No shortlisted candidates for this interview');
        return false;
      }

      const candidatesData = orderBy(candidates, (e) => {
        const newScore = (
          e.emotionScore * friendlinessWeight
          + e.arousalScore * positivityWeight
          + e.communicationScore * communicationWeight
        ) / (friendlinessWeight + positivityWeight + communicationWeight);
        e.totalScore = newScore;
        return newScore;
      }, ['desc']);

      this.getInterviewInfoPass({
        interviewId,
        interview,
        candidatesData,
      });

      mxTrackEvent('Retrieve interview information success');
    } catch (e) {
      console.log(e);
      mxTrackEvent('Retrieve interview information fail');
      this.getInterviewInfoFail(e.message);
    }
    return true;
  }

  async submitConsent(listId, email, accessCode) {
    const req = await fetch(`/shareApi/list/${listId}/consent`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        email,
        accessCode,
      }),
    });

    const res = await req.json();
    if (req.status >= 200 && req.status < 300) {
      const { candidates, feedbackParametersType, interviewData } = res;
      const candidatesData = orderBy(candidates, (e) => {
        const newScore = (
          e.emotionScore * 5
          + e.arousalScore * 5
          + e.communicationScore * 5
        ) / (15);
        e.totalScore = newScore;
        return newScore;
      }, ['desc']);

      this.submitConsentSuccessful({
        candidatesData, email, feedbackParametersType, interviewData,
      });
    } else {
      throw new Error(res.error);
    }
  }

  async getVideoUrls(
    interviewId,
    candidateData,
    questionIndex,
    questions,
    videoCache,
  ) {
    mxTrackEvent('Retrieving candidate interview videos');
    // if candidate status not responded yet, just set status to fail and return
    const candidateStatus = candidateData.status.toUpperCase();
    if ((candidateStatus !== CANDIDATES_STATUS.EVALUATED
      && candidateStatus !== CANDIDATES_STATUS.RESPONDED) || !questions || !questions.length) {
      throw new Error('Candidate has not responded or evaluated yet');
    }

    const candidateId = candidateData.candidateid;
    const candidateSlug = candidateData.slug;
    const questionId = questions[questionIndex].questionid;
    const candidateEmail = candidateData.email;

    // get from cache if being set and still within cache time (30 mins)
    if (videoCache
      && videoCache[candidateId]
      && videoCache[candidateId][questionId]
      && (new Date() - new Date(videoCache[candidateId][questionId].cacheTime)) / 1000 <= 1800) {
      console.log('Cache already exists');
      mxTrackEvent('Retrieve candidate interview videos success');
      return true;
    }

    const questionIds = questions.map(question => question.questionid);
    const queryParamsForQuestionIds = questionIds.join('&questionIds=');
    const req = await fetch(`/shareApi/getS3VideoPresignedUrl?interviewId=${interviewId}&candidateId=${candidateId}&candidateSlug=${candidateSlug}&candidateStatus=${candidateStatus}&questionIds=${queryParamsForQuestionIds}&email=${candidateEmail}`);

    if (req.status < 200 || req.status >= 300) {
      throw new Error(req.statusText);
    }

    const res = await req.json();

    this.setVideoCache({
      candidateId,
      videoCache: res,
    });

    mxTrackEvent('Retrieve candidate interview videos success');
    return true;
  }
}

export default alt.createActions(ShareActions);
