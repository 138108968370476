export default {
  cancel: 'Annuler',
  save: 'Sauvegarder',
  back: 'Retour',
  create: 'Créer',
  edit: 'Modifier',
  delete: 'Supprimer',
  submit: 'Envoyer',
  submitting: 'Envoi en cours...',
  confirm: 'Confirmer',
  share: 'Partager',
  marking: 'Marquer...',
  markAllAsRead: 'Marquer tout comme lu',
  adding: 'Ajouter…',
  error: 'Erreur',
  clear: 'Effacer',
  all: 'Tout',
  navbarSection: {
    overviewTab: 'Vue d\'ensemble',
    interviewsTab: 'Interviews',
    insights: 'Perspectives',
    talentpoolTab: 'Bassin de talent',
    applicantpoolTab: 'Bassin de candidats',
    usersTab: 'Utilisateurs',
    buyCredits: 'Acheter des crédits',
    'Upgrade Plan': 'Passer au forfait supérieur',
    viewAll: 'Tout voir',
    notifications: 'Notifications',
    help: 'Aide',
    notificationInfo1: 'Vous n\'avez pas de nouvelles notifications',
    today: 'Aujourd\'hui',
    yesterday: 'Hier',
    english: 'English',
    vietnamese: 'Vietnamese',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
  },
  overviewPage: {
    messageText1: 'Accueillir! Prenez en charge votre page carrière et renforcez votre présence.',
    messageText2: 'Vous pouvez lier votre page carrière à votre site Web.',
    messageButton: 'Réclamez votre page carrière',
    surveyText: 'Accueillir! Aidez-nous à mieux comprendre la plateforme. Notre enquête devrait vous prendre moins de 30 secondes. Nous serions ravis d’avoir de vos nouvelles !',
    thankyouSurveyText: 'Merci pour votre avis.',
    getStarted: 'Commencer',
    totalInterviews: 'Total des entretiens',
    totalApplicants: 'Nombre total de candidats',
    totalVideoResponses: 'Nombre total de réponses vidéo',
    viewMoreInsights: 'Afficher plus d\'informations',
    weeklyOverall: 'Aperçu global de la semaine',
    createInterview: '+ Créer un entretien',
    text1: 'Rejoignez Pulse of Hiring sur LinkedIn pour obtenir des informations et des sujets sur le monde du travail et de la technologie.',
    text2: 'S\'abonner',
    text3: 'gratuit pendant 15 jours.',
    jobInterview: 'Entretien d\'embauche',
    sourcingFunnel: 'Entonnoir de sourcing',
    applicantFunnel: 'Entonnoir de candidature',
    totalCandidates: 'Total des candidats',
    yetToRespond: 'Pas encore répondu',
    responded: 'Répondu',
    rated: 'Évalué',
    newResponses: 'Nouvelles réponses',
    shortlisted: 'Présélectionné(e)',
    hello: 'Bonjour',
    welcome: 'Bienvenue',
    recentResponses: 'Réponses récentes',
    noRecentResponses: 'Pas de réponses récentes',
    applications: 'Applications',
    videoResponses: 'Réponses vidéo',
    createFirstInterview: 'Créez votre premier entretien',
    addCompanyLogo: 'Ajouter le logo de l\'entreprise',
    viewSampleProfile: 'Voir un exemple de profil',
    addTeamMember: 'Ajouter des membres de l\'équipe',
    instruction1: 'Ne manquez pas nos dernières et mises à jour',
    instruction2: 'Expérience de notification',
  },
  interviewListing: {
    archieveInterview: 'Entretien d\'archives',
    deleteInterview: 'Supprimer l\'entretien',
    duplicateInterview: 'Entretien en double',
    editInterview: 'Modifier l\'entretien',
    manageApplicants: 'Gérer les candidats',
    archiveInterview: 'Entretien d\'archives',
    interviewOwner: 'Propriétaire de l\'entretien',
    searchInterview: 'Rechercher un entretien',
    details: 'Détails',
    created: 'Créé(e)',
    expiry: 'Expiration',
    candidates: 'Candidats',
    applicants: 'Candidats',
    responses: 'Réponses',
    analyzed: 'Analysé(e)',
    shortlisted: 'Présélectionné(e)',
    createdBy: 'Créé(e) par',
    status: 'Statut',
    share: 'Partager',
    filter: 'Filtre',
    newJobInterview: 'Nouvel entretien d\'embauche',
    newInterview: 'Nouvelle entrevue',
    manageCandidates: 'Gérer les candidats',
    assignTo: 'Affecter à',
    changeBaground: 'Modifier l\'arrière-plan',
    editJobInterview: 'Modifier l\'entretien d\'embauche',
    duplicateJobInterview: 'Dupliquer l\'entretien d\'embauche',
    deleteJobInterview: 'Supprimer l\'entretien d\'embauche',
    archieveJobInterview: 'Télécharger le code QR',
    downloadQRCode: 'Télécharger le code QR',
    results: 'Résultats',
    jobOwner: 'Créateur d\'emploi',
    contributor: 'Contributeur',
    randomiseInfo1: 'Sélectionner les questions à randomiser',
    youHaveSelected: 'Vous avez sélectionné',
    questions: 'questions',
    interviewStatus: 'Statut de l\'entretien',
    filterForInterview: 'Filtre pour l\'entretien',
    pleaseSelect: '-- Veuillez sélectionner --',
    expiryMessage: 'Cet entretien expirera immédiatement et les candidats ne pourront plus y répondre.',
    sureMessage: 'Êtes-vous sûr de vouloir archiver l\'entretien ?',
    archieving: 'Archivage...',
  },
  userList: {
    team: 'Équipe',
    inviteNewUsers: 'Invitaer de nouveaux utilisateurs',
    emailAddresses: 'Adresses e-mail',
    organizationMembers: 'Membres de l\'organisation',
    addEmailAddress: 'Ajouter les adresses e-mail ici',
    addEmail: 'Appuyez sur « Entrer » ou « Onglet » pour ajouter des adresses e-mail',
    cancel: 'Annuler',
    share: 'Partager',
    emails: 'adresses e-mail',
    sentInvitesTo: 'Invitations envoyées avec succès à',
    inviteError: 'Les administrateurs peuvent gérer les utilisateurs et tous les entretiens créés au sein de l\'organisation.',
    confirm: 'Confirmer',
    adminUserMessage: 'Les administrateurs peuvent gérer les utilisateurs et toutes les interviews créées au sein de l\'organisation',
    inOrganization: 'dans votre organisation ?',
    areSureYouWantToAssignPermissionTo: 'Êtes-vous sûr de vouloir attribuer des droits d\'administration à',
    toAdmin: 'à l\'administrateur',
    upgrade: 'Mise à jour',
    resendInvitation: 'Êtes-vous sûr de vouloir renvoyer une invitation à',
    fromOrganization: 'de la part de votre organisation ?',
    resendInviteTo: 'Renvoyer l\'invitation à',
    eraseAccessMessage: 'de la part de votre organisation ? Cette action effacera le compte et l\'accès à notre plateforme.',
    irreversibleMessage: 'Cette action est irréversible !',
    removeUserMessage: 'Êtes-vous sûr de vouloir supprimer',
    remove: 'Supprimer',
    noMemberFound: 'Aucun membre trouvé. Invitez de nouveaux membres à rejoindre votre organisation par e-mail',
    joined: 'Rejoint',
    status: 'Statut',
    email: 'E-mail',
    role: 'Fonction',
    members: 'Membres',
    removeUser: 'Supprimer l\'utilisateur',
    resendInvite: 'Renvoyer l\'invitation',
    upgradeToAdmin: 'Passer au statut d\'administrateur',
    exceededUserAdditionMessage: 'Vous avez dépassé le nombre d\'administrateurs au sein de votre organisation.',
    membersContactUsPageProps: {
      contactUsMessage: 'La gestion d\'équipe et l\'invitation des membres sont une fonctionnalité étendue. Veuillez nous contacter pour plus de détails. Ou cliquez sur le bouton ci-dessous pour que nous vous contactions par e-mail !',
      contactUsTitle: 'Équipe',
      contactUsFeature: 'Fonctionnalité de gestion de l\'organisation',
    },
    searchMember: 'Rechercher par nom ou par email',
    inviteMember: 'Inviter de nouveaux utilisateurs',
    memberLimitExceededMessage: 'Vous avez dépassé la limite du nombre de membres dans votre organisation',
    incorrectEmailMessage: 'Adresse e-mail incorrecte saisie',
    sameCompanyDomainMessage: 'Veuillez saisir une ou plusieurs adresses e-mail du même domaine d\'entreprise',
    hasAlreadyInvited: 'a déjà été invité',
  },
  planDetails: {
    planEntitlements: 'Prestations du forfait',
    superAdmin: 'Super administrateur',
    admin: 'Administrateur',
    user: 'Utilisateur',
    contributors: 'Contributeurs',
    yourPlan: 'Votre forfait',

  },
  newInterview: {
    reportSend: 'envoie des rapports aux candidats après avoir terminé l\'évaluation',
    cancel: 'Annuler',
    createNewInterview: 'Créer un nouvel entretien',
    createInterview: 'Créer un entretien',
    creatingInterview: 'Création d\'un entretien...',
    updateInterview: 'Entretien de mise à jour',
    updatingInterview: 'Mise à jour de l\'entretien...',
    newInterview: 'Nouvelle entrevue',
    basicInfo: 'Informations de base',
    instituteLogo: 'Logo de l\'Institut',
    addInstituteLogo: 'Ajouter le logo de l\'institut',
    instLogoRequired: 'Le logo de l\'institut est requis',
    instituteName: 'Nom de l\'Institut',
    instituteNameRequired: 'Le nom de \'institut est requis',
    departmentTitle: 'Titre du département',
    departmentTitleRequired: 'Le titre du département est requis',
    chooseFunction: 'Choisissez une fonction',
    createFunction: 'Create custom Function',
    functionName: 'Nom de la fonction',
    interviewExpiryDate: 'Date d\'expiration de l\'entretien',
    interviewDescription: 'Description de l\'entretien',
    applicantRetakeText: 'Le candidat peut-il repasser l’intégralité de l’entretien plusieurs fois ?',
    applicantResumeText: 'La notation du CV est-elle requise lorsque le candidat postule ?',
    applicantResponseText: 'Langue de réponse attendue du candidat',
    workmapErrorMessage: '(Veuillez sélectionner au moins une compétence)',
    enableSocialMediaText: 'Choisir de demander des profils de réseaux sociaux aux candidats',
    selectQuestions: 'Sélectionnez des questions',
    selectTwoQuestions: 'Veuillez sélectionner au moins deux questions.',
    selectAllQuestions: 'Sélectionner toutes les questions',
    updateJobInterview: 'Mise à jour de l\'entretien d\'embauche',
    createJobInterview: 'Créer un entretien d\'embauche',
    newJobInterview: 'Nouvel entretien d\'embauche',
    workmapAssessment: 'Évaluation de Workmap',
    addIntroQuestions: 'Questions supplémentaires sur le formulaire',
    addVideoQuestions: 'Ajouter des questions vidéo',
    editYourQuestion: 'Modifier une question supplémentaire du formulaire',
    editingYourQuestion: 'Modifier...',
    noIntroCategories: 'Aucune catégorie n\'a encore été créée...',
    interviewSettings: 'Paramètres de l\'entretien',
    basicJobInfo: 'Informations de base concernant le poste',
    qualifierQuestion: 'Question de qualification',
    identityFraudToggleText: 'La vérification d\'identité est-elle requise ?',
    qualifierQuestionErrorMessage: 'La question de qualification est requise si elle est activée.',
    addLogo: 'Ajouter un logo',
    companyLogo: 'Logo de l\'entreprise',
    addCompanyLogo: 'Ajouter un logo',
    logoChangeInstruction: 'Cliquez sur le logo pour le modifier ou l\'ajouter.',
    logoChangeInstruction2: 'max. 500kb',
    companyLogoRequired: 'Le logo de l\'entreprise est requis',
    companyName: 'Nom de l\'entreprise',
    companyNameRequired: 'Le nom de l\'entreprise est requis.',
    jobTitleInstruction1: 'Intitulé du poste',
    jobTitleInstruction2: 'Le nom de l\'entretien est requis.',
    jobFunction: 'Fonction',
    chooseJobFunction: 'Choisir une fonction',
    createCustomJobFunction: 'Créer une fonction personnalisée',
    questions: 'Des questions',
    jobFunctionInstruction: {
      instruction1: 'Nom de la fonction*',
      instruction2: 'Le nom de la fonction du poste est requis.',
    },
    updatingJobInterview: 'Mise à jour de l\'entretien d\'embauche...',
    workmapSkills: {
      instruction1: 'Sélectionnez les compétences',
      instruction2: 'L\'évaluation de la feuille de travail utilise l\'échelle de Likert. Une échelle de Likert est couramment utilisée pour mesurer les attitudes, les connaissances, les perceptions, les valeurs et les changements de comportement. Une échelle de type Likert implique une série d\'énoncés parmi lesquels les répondants peuvent choisir afin d\'évaluer leurs réponses aux questions d\'évaluation (Vogt, 1999).',
      instruction3: 'Des compétences sont requises.',
      instruction4: 'Choisissez plusieurs :',
      relevantSkills: 'Relevant Skills',
      instruction5: 'Liste des compétences qui seront testées dans l’évaluation Interviewer.AI Workmap. L\'évaluation WorkMap est un outil psychométrique conçu pour mesurer les aptitudes et les compétences liées aux facteurs de réussite d\'emplois spécifiques.',
      instruction6: 'Choisissez plusieurs',
      workmapAssessmentEnabled: 'Évaluation de la feuille de travail activée',
      instruction7: 'Échelle de Likert',

    },
    creating: 'Création',
    create: 'Créer',
    interviewStartDate: 'Date de début de l\'entretien',
    jobExpiry: 'Date d\'expiration du poste',
    expirydateRequired: 'Localisation de l\'emploi',
    jobLocationInstruction: {
      jobLocation: 'Localisation de l\'emploi',
      instruction1: 'Le pays est requis.',
      instruction2: 'La ville est obligatoire.',
    },
    jobVisibility: {
      jobVisibilityInstruction: 'Visibilité du travail',
      jobVisibilityInstruction1: 'La visibilité des emplois contrôle les emplois qui apparaissent sur votre page Carrière.',
      jobVisibilityInstruction2: 'Le travail est',

    },
    private: 'privé',
    public: 'public',
    qrCodeInstructions: {
      qrCodeInstructions1: 'Visibilité du code QR',
      qrCodeInstructions2: 'La visibilité du code QR affiche le code QR sur les offres d\'emploi.',
      qrCodeInstructions3: 'Code QR',
    },
    enabled: 'Activé',
    disabled: 'Désactivé',
    roleType: 'Type de fonction',
    roleTypeRequired: 'Le type de fonction est requis.',
    uniqueCandidatesLabel: 'Étiquette d\'identification unique du candidat',
    uniqueCandidatesLabel2: 'Il peut s\'agir de n\'importe quelle valeur unique que vous avez attribuée au candidat pour l\'identifier.',
    uniqueCandidatesLabel3: '1 - La valeur spécifiée apparaîtra dans le formulaire de candidature sous forme d\'étiquette avec le champ de saisie.',
    uniqueCandidatesLabel4: '2 - Nous vous recommandons de ne pas spécifier de valeur dans ce champ si vous ne disposez pas d\'identifiants uniques ou si vous n\'avez pas fourni ces identifiants aux candidats, car ce champ sera obligatoire pour ces derniers.',
    candidateCoachingId: 'Par exemple, le numéro de siège/le numéro de candidat/le numéro de matricule.',
    jobDescription: {
      jobDescription: 'Description de l\'emploi',
      jobDescriptionInstruction1: 'Une description est requise.',
      jobDescriptionInstruction2: 'Envoie des rapports aux candidats à la fin de l\'évaluation',
      jobDescriptionInstruction3: 'L\'activation de cette fonctionnalité permettra d\'envoyer des rapports aux candidats individuellement après avoir terminé cet entretien vidéo.',
    },
    moreOptions: 'Plus d\'options',
    resumeInstructions: {
      resumeInstructions1: 'Les candidats doivent-ils fournir un CV pour postuler ?',
      resumeInstructions2: 'Imposer l\'URL du profil LinkedIn',
      resumeInstructions3: 'Le candidat peut-il repasser plusieurs fois l\'ensemble de l\'entretien ?',
      resumeInstructions4: 'Imposer l\'URL du profil Facebook',
      resumeInstructions5: 'Imposer l\'URL du profil Instagram',
      resumeInstructions6: 'Rendre la réponse idéale obligatoire"',
      resumeInstructions7: 'Bascule de Pertinence du Contenu',
    },
    showLinkedin: '{{option}} Espace URL LinkedIn',
    showFacebook: '{{option}} Espace URL Facebook',
    showInstagram: '{{option}} Espace URL Instagram',
    videoScoreInstruction: 'Souhaitez-vous fixer un score vidéo minimum pour repasser l\'entretien ?',
    thresholdInstruction: 'La valeur seuil doit être supérieure à 20',
    thresholdInstruction1: 'Interdire aux candidats de continuer au delà du formulaire de candidature',
    thresholdInstruction2: 'Veuillez noter que l\'activation de cette option empêchera les candidats d\'aller au-delà du formulaire de candidature. Les candidats devront recevoir une invitation de votre part pour poursuivre le processus.',
    overwriteInterviewCompanyName: 'Remplacer le nom de l\'entreprise pour l\'entretien',
    overwriteCompanyName: 'Écraser le nom de l\'entreprise',
    customiseInterviewLogo: 'Personnaliser le logo de l\'entretien (JPG, JPEG, PNG)',
    redirectURL: 'URL de redirection',
    redirectUrlInstructions: 'Cette option définit l\'URL de redirection lorsque le candidat termine l\'entretien vidéo, par ex : La page LinkedIn de votre entreprise. L\'URL doit être préfixée du protocole http ou https.',
    manualRatingParameter: 'Paramètres de notation manuelle',
    instruction5: 'Press Enter or Tab to add separate rating parameter',
    instruction6: 'Langue de réponse attendue du candidat (bêta)',
    instruction7: 'Cette configuration définit la langue attendue de la réponse du candidat et effectue la synthèse vocale en conséquence. Notez que certains scores (ex : communication) seront désactivés pour les langues autres que l\'anglais.',
    english: 'Anglais',
    mandarin: 'Mandarin',
    polish: 'polonais',
    spanish: 'Espagnol',
    bahasa: 'Bahasa (indonésien)',
    french: 'Français (France)',
    vietnamese: 'vietnamien',
    german: 'Allemand',
    country: 'Pays',
    city: 'Ville',
    cognitiveAssessment: 'Évaluation cognitive',
    selectTime: 'Choisir le moment',
    categoryName: 'Nom de la catégorie',
    categoryDescription: 'Description de la catégorie',
    enterCtaegoryDescription: 'Saisir la description de la catégorie',
    createCategory: 'Créer une catégorie',
    categoryNameInstruction: 'Nom pour ce groupe de questions',
    listMore: 'Lister plus +',
    listLess: 'Lister moins -',
    expired: 'Expiration',
    copyJobLink: 'Copier le lien du poste',
    required: 'Requis',
    'On-site': 'Sur place', // Purposely kept key like this
    Remote: 'À distance', // Purposely kept key like this
    Hybrid: 'Hybride', // Purposely kept key like this
    'Part Time': 'Temps partiel', // Purposely kept key like this
    'Full Time': 'Temps plein', // Purposely kept key like this
    Internship: 'Stage', // Purposely kept key like this
    Contract: 'Contrat de travail', // Purposely kept key like this
    Training: 'Formation', // Purposely kept key like this
    'Mock Interview': 'Entretien fictif', // Purposely kept key like this
  },
  introQuestions: {
    introQuestionsTitle: 'Questions supplémentaires sur le formulaire',
    addQuestions: 'Questions complémentaires',
    instruction1: 'Les candidats peuvent sélectionner plusieurs options',
    instruction2: 'Les candidats peuvent sélectionner une seule option',
    edit: 'Modificateur',
    question: 'Question',
    instruction3: 'Permet de choisir plus d\'une option',
    notNow: 'Pas maintenant',
    addYourQuestion: 'Ajoutez votre question',
    randomize: 'Randomiser',
    selectTime: 'Sélectionnez l\'heure',
    selectCategory: 'Choisir une catégorie',
    videoQuestionBank: 'Banque de questions vidéo',
    introQuestionBank: 'Banque de questions du formulaire supplémentaire',
  },
  editInterview: {
    editInterviewTemplate: 'Modifier le modèle d\'entretien',
    interviewExpiry: 'Expiration de l\'entretien',
    modifyQuestions: 'Modifiez vos questions et choisissez jusqu\'à',
    withLimit: 'avec une limite de',
  },
  profileSlider: {
    logout: 'Déconnexion',
    userProfile: 'Profil de l\'utilisateur',
    companyProfile: 'Profil de l\'entreprise',
    instituteProfile: 'Profil de l\'institut',
    billing: 'Facturation',
    integrations: 'Intégration',
    notificationSettings: 'Paramètres de notification',
    promoCode: 'Code promo',
    contactSupport: 'Contacter le service d\'assistance',
    shareFeedback: 'Partager le feedback',
    changePassword: 'Changer de mot de passe',
    termsAndConditions: 'Conditions générales d\'utilisation',
    help: 'Aide',
    videoTutorial: 'Tutoriels vidéo',
    howToCreateAnInterview: 'Comment créer un entretien',
    howToAddQuestionsToAnInterview: 'Comment ajouter des questions à un entretien',
    howToInviteCandidates: 'Comment inviter des candidats',
    howToShareCandidates: 'Comment partager les candidats',
    aFeelOfTheDashboard: 'Aperçu du tableau de bord',
    reCalibrateAIMatrics: 'Recalibrez vos indicateurs d\'IA',
    knowMoreAboutTalentPool: 'En savoir plus sur le bassin de talents',
    addTeamMember: 'Ajouter des membres de l\'équipe',
  },
  userProfile: {
    back: 'Retour',
    editProfile: 'Modifier le profil',
    profileImage: 'Image du profil',
    upload: 'Télécharger',
    maxImgSize: 'Taille maximale de l\'image 500 x 500*',
    personalInfo: 'Informations personnelles',
    firstName: 'Prénom',
    lastName: 'Nom de famille',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    save: 'Sauvegarder',
    saving: 'Sauvegarde...',
    placeHolderFirstName: 'Saisir le prénom',
    placeHolderLastName: 'Saisir le nom de famille',
  },
  companyProfile: {
    back: 'Retour',
    myCompany: 'Mon entreprise',
    myInstitute: 'Mon Institut',
    basicInformation: 'Informations de base',
    companyLogo: 'Logo d\'entreprise',
    instituteLogo: 'Logo de l\'Institut',
    upload: 'Télécharger',
    businessInformation: 'Informations d\'affaires',
    companyName: 'Nom de l\'entreprise',
    instituteName: 'Nom de l\'Institut',
    companyNamePlaceholder: 'Entrez le nom de l\'entreprise',
    yourCareerPage: 'Votre page carrière',
    yourPageIs: 'Votre page carrière est',
    public: 'public',
    private: 'privé',
    categories: 'Category *',
    industryType: 'Veuillez sélectionner un type d\'industrie.',
    description: 'Description',
    maxCharacter: '(max. {{count}} caractères)',
    siteNamePlaceHolder: 'Saisir le nom du site',
    createNew: 'Créer un nouveau site',
    subOrganisation: 'Sous-organisations',
    urlCopyurl: 'URL copiée dans le presse-papiers',
    saveProfile: 'Sauvegarder le profil',
    saving: 'Économie',
    hideEmploymentType: 'Masquer les informations sur le type d\'emploi dans le formulaire de candidature du candidat.',
    hideLocation: 'Masquer les informations sur le lieu de travail dans le formulaire de candidature du candidat.',
  },
  billingPage: {
    overall: 'Dans l\'ensemble',
    byInterview: 'Par entretien',
    heading: 'Maintenant tu es dessus',
    changePlan: 'Modifier le forfait',
    particulars: 'Détails',
    credits: 'Crédits',
    totalCredits: 'Crédits totaux',
    to: 'à',
    used: 'Utilisé(es)',
    balance: 'Solde',
    numberOfLiveInterview: 'Nombre d\'entretiens en direct',
    numberOfUser: 'Nombre d\'utilisateurs',
    candidateResponse: 'Réponses des candidats (crédits)',
    billingInformation: 'Informations de facturation',
    nextBillingDate: 'Prochaine date de facturation',
    on: 'le',
    freeCredit: 'Crédits gratuits d\'une valeur de 100',
    continue: 'Continuer',
    usePromoCode: 'Utilisez notre code promo pour obtenir 20 % de réduction sur n\'importe quel forfait',
    copiedSuccess: 'Copié(e) avec succès',
    notApplicable: 'Sans objet',
    hiringIsSeasonal: 'L\'embauche est saisonnière et nous le comprenons',
    hiringDesc1: 'Découvrez notre nouvelle tarification annuelle basée sur les crédits. Accédez à un nombre illimité d\'emplois',
    hiringDesc2: 'et à un nombre illimité d\'utilisateurs lorsque vous achetez 100 crédits ou plus.',
  },
  integrationPage: {
    calendayApiKey: 'CLÉ API DE CALENDLY',
    save: 'Sauvegarder',
    zapierIntegration: 'INTÉGRATION ZAPIER',
    beta: 'Bêta',
    generate: 'Générer',
    whatZapier: 'QUE POUVEZ-VOUS FAIRE AVEC ZAPIER ?',
    placeHolderCalendly: 'Saisissez l\'API de Calendly',
    placeholderGenerateZapierKey: 'Cliquez sur « générer » pour obtenir la clé API de zapier',
  },
  notificationPreference: {
    heading: 'Préférence de notification par courrier électronique du résumé du candidat',
    daily: 'Quotidien',
    weekly: 'Hebdomadaire',
    monthly: 'Mensuel',
    save: 'Sauvegarder',
  },
  resetPassword: {
    heading: 'Réinitialisez votre mot de passe',
    enterEmailInstr: 'Saisissez votre adresse e-mail ci-dessous et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
    email: 'E-mail',
    sendResetLink: 'Envoyer l\'e-mail de réinitialisation du mot de passe',
    login: 'Connexion',
    resetting: 'Réinitialiser...',
  },
  talentpool: {
    resume: 'Reanudar',
    viewResume: 'Ver currículum',
    'date applied': 'Date d\'application',
    searchApplicants: 'Rechercher des candidats',
    talentpoolInsights: 'Aperçu du bassin de talents',
    errorOccured: 'Une erreur s\'est produite. Veuillez nous contacter pour plus de détails',
    reachOut: 'Nous vous contacterons bientôt !',
    hi: 'Bonjour à tous !',
    contactUsMessage: `Vous en avez assez de passer des entretiens pour accéder à vos propres candidats ?

    La fonctionnalité « Bassin de talents » vous simplifie la tâche ! Accédez à tous vos candidats sur une seule page et utilisez des filtres pour affiner votre recherche et trouver ce qui vous intéresse.

    Pour accéder à cette fonctionnalité premium, veuillez nous contacter pour plus de détails. Vous pouvez également cliquer sur le bouton ci-dessous pour que nous vous contactions par e-mail !
  `,
    contactUsMessageCoaching: `Vous en avez assez de passer des entretiens pour accéder à vos propres candidats ?

    La fonctionnalité « Bassin de talents » vous simplifie la tâche ! Accédez à tous vos candidats sur une seule page et utilisez des filtres pour affiner votre recherche et trouver ce qui vous intéresse.

    Pour accéder à cette fonctionnalité premium, veuillez nous contacter pour plus de détails. Vous pouvez également cliquer sur le bouton ci-dessous pour que nous vous contactions par e-mail !
  `,
    contactUsTitle: 'Bassin de talent',
    demographicData: 'Données démographiques',
    applicationsBtn: 'Application',
    usage: 'Usage',
    educationBackground: 'Contexte de l\'éducation',
    emailPerformance: 'Performances des e-mails',
    dataWithPhoneNumber: 'Données avec numéro de téléphone',
    appliedToMultipleRoles: ' Appliqué à plusieurs rôles',
    timeToHire: 'Il est temps d\'embaucher',
    totalLiveInterviews: '  Total des entretiens en direct',
    totalUsers: 'Nombre total d\'utilisateurs',
    allJobs: 'Tous les emplois',
    contactUsToUpgrade: 'Contactez-nous pour mettre à niveau',
    contactUsFeature: 'Fonctionnalité d\'informations sur le vivier de talents',
    interested: 'Je suis intéressé!',
    companyOverall: 'Société dans son ensemble',
    interviews: 'Entretiens',
    applicants: 'Candidats',
    resumeScoring: 'Reprendre la notation',
    workmapScoring: 'Notation de la feuille de travail',
    videoScoring: 'Entretien vidéo',
    shortlisted: 'Présélectionné(e)',
    scheduled: 'Programmé',
    rejected: 'Rejeté',
    kiv: 'Gardé en vue',
    completedApplications: 'Completed Application',
    incompleteApplications: 'Demande incomplète',
    searchCandidatePlaceholder: 'Rechercher un candidat',
    allJobTitle: 'Tous les titres d\'emploi',
    allLocations: 'Tous les emplacements',
    allStatus: 'Tous les statuts',
    allApplication: 'Toutes les candidatures',
    complete: 'Complété',
    inComplete: 'Incomplet',
    name: 'Nom',
    jobTitle: 'Titre d\'emploi',
    'job title': 'Titre d\'emploi',
    company: 'Entreprise',
    location: 'Location',
    interviewName: 'Nom de l\'entretien',
    'interview name': 'Nom de l\'entretien',
    status: 'Statut',
    applications: 'Application',
    all: 'Tout',
    completed: 'Complété',
    incomplete: 'Incomplet',
    videoInterview: 'Entretien vidéo',
    'Action Pending': 'Action en attente',
    Shortlist: 'Présélectionné(e)',
    Rejected: 'Rejeté',
    Kiv: 'Kiv',
    application: 'Statut',
  },
  candidateDetails: {
    idealAnswer: 'Réponse idéale',
    idealAnsNotAvail: 'Réponse idéale non disponible',
    idVerifyTrueText: 'Vérification d\'identité vérifiée',
    idVerifyFalseText: 'Vérification d\'identité non vérifiée',
    audioDetectedTrue: 'Audio détecté',
    audioDetectedFalse: 'Aucun son détecté',
    browserToggleTrue: 'Basculement du navigateur détecté',
    browserToggleFalse: 'Aucune bascule de navigateur détectée',
    pastedAnsTrue: 'Réponse collée détectée',
    pastedAnsFalse: 'Aucune réponse collée détectée',
    thirdPartyVoiceTrue: 'Voix tierce détectée',
    thirdPartyVoiceFalse: 'Aucune voix tierce détectée',
    eyeContactTrue: 'Contact visuel détecté',
    eyeContactFalse: 'Aucun contact visuel détecté',
    suspiciousObjectTrue: 'Objet suspect détecté',
    suspiciousObjectFalse: 'Kein verdächtiges Objekt erkannt',
    shareWith: 'Partagé avec',
    sendReportCoaching: 'Envoyer le rapport au demandeur',
    applicantShortlisted: 'Candidat présélectionné',
    shortlistApplicant: 'Candidat présélectionné',
    applicants: 'Candidats',
    searchApplicants: 'Rechercher des candidats',
    selectMe: 'Sélectionnez-moi',
    selected: 'Choisi',
    applicantSummary: 'Résumé du candidat',
    all: 'Tout',
    candidates: 'Candidats',
    searchCandidates: 'Recherche de candidats',
    scoreSettings: 'Paramètres du score',
    resumeScore: 'Score du CV',
    workmapScore: 'Score de Workmap',
    aiVideoScore: 'Score vidéo de l\'IA',
    professionalism: 'Professionnalisme',
    positiveAttitude: 'Attitude positive',
    communication: 'Communication',
    sociability: 'Sociabilité',
    contentRelevancy: 'Pertinence du contenu',
    reset: 'Réinitialiser',
    saving: 'Économie...',
    save: 'Sauvegarder',
    score: 'Score',
    feedback: 'Retour',
    new: 'Nouveau',
    unlockCandidate: 'Débloquer ce candidat',
    scoreSettingsInfo: 'Ceci vous permet de contrôler le poids du CV, de WorkMap et du score vidéo de l\'IA. Choisissez la pondération que vous souhaitez, le total devrait être de 100.',
    deleteCandidateInfo: 'Le candidat a supprimé son profil, vous ne pouvez donc plus accéder à ses informations.',
    overallScore: 'Score global',
    overall: 'Global(e)',
    hired: 'Embauché',
    hire: 'Embauchez-moi',
    appliedDate: 'Date appliquée',
    changeSchedule: 'Modifier le calendrier',
    scheduleInterview: 'Planifier l\'entretien',
    downloadReport: 'Télécharger le rapport',
    shareProfile: 'Partager ce profil',
    sendReport: 'Envoyer le rapport au candidat',
    candidateShortlisted: 'Candidat présélectionné',
    shortlistCandidate: 'Candidat présélectionné',
    candidateKived: 'Candidat gardé en mémoire',
    kivCandidate: 'Garder le candidat en mémoire',
    keepInView: 'Garder en mémoire',
    keptInView: 'Gardé en mémoire',
    candidateRejected: 'Candidat rejeté',
    rejectCandidate: 'Rejeter le candidat',
    rejected: 'Rejeté',
    reject: 'Rejeter',
    inviteActivity: 'Activité de convocation',
    interviewScheduledOn: ' Entretien prévu le',
    activity: 'Activité',
    contactedUser: 'Contact avec l\'utilisateur le',
    noQuestionsAttempted: 'Aucune question n\'a été posée',
    videoTranscript: 'Transcription de la vidéo',
    candidateSummary: 'Synthèse du candidat',
    insightsDisclaimer: 'Clause de non-responsabilité : Vous pouvez utiliser ces informations à votre guise pour présélectionner des candidats en vue d\'un entretien ou pour passer des appels de recrutement.',
    skill: 'Compétences',
    education: 'Formation',
    experience: 'Expérience',
    shareShortlist: 'Partager la présélection',
    inviteSent: 'Invitation envoyée',
    interviewVideo: 'Transcription vidéo',
    location: 'Emplacement',
    email: 'E-mail',
    phone: 'Téléphone',
    linkedIn: 'Linkedin',
    includeEmail: 'Inclure un e-mail',
    rejectCandidateInfo: 'Veuillez confirmer si vous souhaitez rejeter le(s) candidat(s) suivant(s) :',
    emailSubject: 'Objet de l\'e-mail',
    dear: 'Cher(e)',
    rejectReason: 'Raison du rejet',
    rejecting: 'Rejeter',
    emailPreview: 'Aperçu de l\'e-mail',
    selectEventType: 'Sélectionnez le type d\'événement Calendly',
    calendlyEventCreated: 'Assurez-vous d\'avoir créé un événement Calendly ou créez-en un ici.',
    noCalendlyEventFound: 'Aucun événement Calendly trouvé',
    sender: '2. Expéditeur',
    editEmailSubject: '3. Modifier l\'objet de l\'e-mail',
    checkEditEmailContent: '4. Vérifier et modifier le contenu de l\'e-mail',
    lookForwardMeetingYou: 'nous nous réjouissons de vous rencontrer bientôt',
    sending: 'Envoyer...',
    scheduleAndSend: 'Planifier et envoyer',
    yesRejectCandidate: 'Oui, refuser le candidat',
    andSendEmail: ' et envoyer l\'e-mail',
    yourFeedback: 'Votre feedback',
    feedbackInstruction1: 'Maximum characters: 5000',
    overallFeedback: 'Feedback global',
    hiringManager: 'Responsable de l\'embauche',
    notes: 'Remarques',
    status: 'Statut',
    noSummary: 'Aucune synthèse',
    videoScoreTooltip: 'Le cadre exclusif d\'Interviewer.AI permet d\'évaluer les propos, la voix et les expressions faciales des candidats au cours de l\'entretien vidéo. Les candidats reçoivent un score sur cent pour chaque paramètre d\'évaluation. Ces notes sont regroupées en une note globale pour l\'entretien vidéo. Lorsque le score est plus élevé, cela signifie que le candidat s\'est mieux comporté sur la base d\'évaluations objectives de son comportement lors de l\'entretien vidéo, par rapport aux entretiens vidéo réalisés sur la plateforme et aux ensembles de données publiques.',
    professionalismTooltip: 'Elle est mesurée en fonction du comportement du candidat (formel/informel) et de la manière dont il se présente dans la vidéo. Elle mesure également le contact visuel durant la réponse à la vidéo, ainsi que la présence éventuelle de gesticulations. Oui, s\'habiller de manière formelle donne un avantage.',
    positiveAttitudeTooltip: 'L\'attitude, comme son nom l\'indique, tient compte des impressions et d\'une combinaison d\'énergie et d\'émotion. Ce modèle prend en compte une combinaison d\'indicateurs de communication et de sociabilité (par exemple : confiant, encouragé, positif).',
    communicationTooltip: 'Il s\'agit principalement de mesurer la clarté du discours du candidat. Elle examine également la fluidité de la communication verbale et la modulation du discours. Parler clairement et avec le bon rythme est un avantage. Il convient de varier le ton de la voix aux moments opportuns tout au long de la vidéo.',
    sociabilityTooltip: 'Ce modèle d\'IA se concentre davantage sur l\'évaluation des sentiments du candidat, en se basant sur la manière dont le candidat communique et fait preuve de compétences interpersonnelles positives et de tact. Il recherche généralement les termes positifs et les niveaux d\'énergie d\'un candidat.',
    contentRelevancyTooltip: 'La pertinence de la réponse est affichée sous forme de pourcentage pour indiquer dans quelle mesure elle répond à la question. Un pourcentage plus élevé indique un niveau de pertinence plus élevé, tandis qu’un pourcentage plus faible suggère une réponse potentiellement moins pertinente. Ces informations vous aident à évaluer l\'exactitude et la pertinence de la réponse fournie.',
  },
  scheduleInterview: {
    email: 'Aperçu du courrier électronique',
    selectDateTimeDuration: '1. Sélectionnez la date, l\'heure et la durée',
    scheduleDescription: 'Vous pouvez planifier une invitation à un entretien avec le candidat ici. Nous vous enverrons un e-mail ainsi qu\'à votre candidat.',
    dateAndTime: 'Date et heure',
    duration: 'Durée de l\'entretien',
    sender: '2. Expéditeur',
    editEmailSubject: '3. Modifiez le sujet de l\'e-mail',
    checkEditEmailContent: '4. Vérifiez et modifiez le contenu de l\'e-mail',
  },
  candidateListing: {
    removeSelectedApplicants: 'Supprimer les candidats sélectionnés',
    removeSelectedApplicantInfo: 'Êtes-vous sûr de vouloir supprimer ces candidats ?',
    editApplicantDetail: 'Modifier les détails du candidat',
    unlockApplicant: 'Débloquez ce candidat',
    kivApplicant: 'Demandeur KIV',
    deleteApplicant: 'Supprimer le candidat',
    copyApplicantInterviewLink: 'Copier le lien d\'entretien avec le candidat',
    editApplicant: 'Modifier le candidat',
    applicantDateBetween: 'Date des candidats Entre',
    filterApplicants: 'Filtrer les candidats',
    selectToDeleteCoaching: 'Sélectionnez les candidats à supprimer',
    deleteSelectedCandidatesCoaching: 'Supprimer les candidats sélectionnés',
    downloadApplicantDetails: 'Télécharger les détails du candidat',
    selectToRejectCoaching: 'Sélectionnez les candidats à rejeter',
    rejectSelectedCoaching: 'Rejeter les candidats sélectionnés',
    selectToShareCoaching: 'Sélectionnez les candidats à partager',
    shareSelectedCoaching: 'Partager les candidats sélectionnés',
    addApplicant: 'Ajouter un nouveau candidat',
    addApplicantToInterview: 'Ajouter le candidat suivant à l\'entretien',
    enterCandidateName: 'Entrez le nom du candidat',
    enterApplicantName: 'Entrez le nom du candidat',
    enterCandidateEmail: 'Entrez l\'adresse e-mail du candidat',
    enterApplicanteEmail: 'Entrez l\'adresse e-mail du candidat',
    enterCandidateNumber: 'Entrez le numéro de contact du candidat',
    enterApplicantNumber: 'Entrez le numéro de contact du candidat',
    applicantsTo: 'Les candidats à',
    notifyApplicants: 'Informer les candidats',
    sendMessagesToMultipleApplicants: 'Envoyez des messages à plusieurs candidats à partir d’ici.',
    searchApplicants: 'Rechercher des candidats',
    newApplicant: 'Nouveau candidat',
    totalApplicants: 'Nombre total de candidats',
    totalCandidates: 'Nombre total de candidats',
    yetToRespond: 'Encore à répondre',
    editCandidate: 'Modifier le candidat',
    copyInterviewLink: 'Copier le lien de l\'entretien avec le candidat',
    deleteCandidate: 'Supprimer le candidat',
    editCandidateDetail: 'Modifier les détails du candidat',
    name: 'Nom',
    email: 'E-mail',
    phoneNumber: 'Numéro de téléphone',
    selectToDelete: 'Sélectionner les candidats à supprimer',
    deleteSelectedCandidates: 'Supprimer le candidat',
    selectToReject: 'Sélectionner les candidats à supprimer',
    rejectSelected: 'Rejeter les candidats sélectionnés',
    selectToShare: 'Sélectionner les candidats à partager',
    shareSelected: 'Partager les candidats sélectionnés',
    newCandidate: 'Nouveau candidat',
    videoScore: 'Score vidéo',
    sendVideoInterviewLink: 'Envoyer le lien de l\'entretien vidéo',
    select: 'Sélectionner',
    selected: 'sélectionné',
    candidatesTo: 'Candidats à',
    candidate: 'Candidat',
    applicant: 'Demandeur',
    notify: 'Notifier',
    importByCSV: 'Importer en CSV',
    addCandidate: 'Ajouter un nouveau candidat',
    addCandidateToInterview: 'Ajouter le candidat suivant à l\'entretien',
    enterContactNumber: 'Saisir le numéro de contact du candidat',
    removeSelectedCandidates: 'Supprimer les candidats sélectionnés',
    removeSelectedCandidateInfo: 'Êtes-vous sûr de vouloir supprimer ces candidats ?',
    noCandidatesInfo: 'Il n\'y a pas encore de candidats. Envoyez l\'entretien aux candidats dès maintenant !',
    notifyCandidate: 'Notifier les candidats',
    sendMessagesToMultipleCandidates: 'Envoyez des messages à plusieurs candidats à partir de cette page.',
    sendEmailsTo: 'Envoyer des e-mails à',
    emailPreviewInfo: 'Vous trouverez ci-dessous un aperçu de l\'e-mail à envoyer aux candidats. Vous pouvez modifier la zone en jaune pour qu\'elle corresponde mieux à vos messages.',
    emailPreviewInfo2: 'Veuillez cliquer sur le lien ci-dessous et enregistrer votre réponse.',
    recordResponse: 'Enregistrez votre réponse',
    lookForwardForResponse: 'Nous attendons votre réponse, bonne chance !',
    filterCandidates: 'Filtrer les candidats',
    downloadCandidateDetails: 'Télécharger les informations sur le candidat',
    importCandidates: 'Importer des candidats',
    fileName: 'Nom du fichier',
    bulkImportCandidateInfo1: 'Le contenu du fichier CSV doit comprendre l\'adresse e-mail, le nom et le numéro de téléphone (facultatif). Peut être présenté dans un ordre différent.',
    actions: 'Actions',
    chooseFile: 'Choisir un fichier',
    addCandidates: 'Ajouter des candidats',
    candidateImportInfo1: 'Aucune donnée sur le candidat à importer',
    duplicateEntry: 'Duplication de la saisie de l\'e-mail',
    duplicateEntryPhone: 'Double saisie pour le téléphone',
    duplicateEntryInfo: 'Duplication de la saisie pour',
    inCSVsheet: 'sur la feuille CSV',
    alreadyExist: 'existe déjà dans la liste des candidats',
    enterValidName: 'Veuillez saisir un nom valide',
    enterValidEmail: 'Veuillez saisir un e-mail valide',
    enterValidPhone: 'Veuillez saisir un numéro de téléphone valide',
    enterValid: 'Veillez à ce que l\'élément suivant soit valide :',
    invalidEmail: 'Le contenu CSV contient un e-mail invalidel',
    invalidPhone: 'Le contenu du CSV contient un numéro de téléphone invalide',
    isMandatoryForAllCandidates: 'est obligatoire pour tous les candidats',
    csvContailsInvalid: 'Le contenu du fichier CSV est invalide',
    csvImportInfo1: 'Le fichier n\'est pas un fichier CSV ou Excel',
    csvImport2: 'Le fichier CSV/Excel doit comporter une colonne « nom » ou « e-mail »',
    columnInHeader: 'dans l\'en-tête',
    reactivateCandidateLink: 'Réactiver le lien candidat',
    reactivateApplicantLink: 'Réactiver le lien du candidat',
  },
  insights: {
    candidateLocation: 'Localisation du candidat',
    genderDiversity: 'Diversité des sexes',
    jobTitle: 'Titre d\'emploi',
    colleges: 'Collèges',
    educationGraph: 'Graphique de l\'éducation',
    sourceOfApplication: 'Source de la demande',
    resetMap: 'Réinitialiser la carte',
    emailSentOpened: 'E-mails envoyés, ouverts et répondus',
    showingLatest: 'Affichage des 5 dernières interviews',
    emptyGenderInfo: 'Aucune information sur le sexe pour l\'instant',
    insights: 'Perspectives',
    interested: 'Je suis intéressé!',
    contactUs: 'Contactez-nous',
    noEmailEventInfo: 'Aucune information sur les événements par e-mail pour l\'instant',
    noSourceInfo: 'Aucune information sur la source pour l\'instant',
  },
  footer: {
    copyRightText: 'Copyright © {{currentYear}} Interviewer.AI. Tous droits réservés.',
  },
  shareProfile: {
    shareThisProfile: 'Partager ce profil',
    shareCandidates: 'Partager les candidats',
    enterEmailSubject: 'Entrez un objet d\'e-mail',
    selectedCandidate: 'Candidat sélectionné',
    listName: 'List Name',
    shareWith: 'Partager avec',
    info1: 'Appuyez sur Entrée pour ajouter des e-mails distincts',
    secureAccess: 'Accès sécurisé',
    optional: 'optionnel',
    enterAccessCodeHere: 'Saisir le code d\'accès ici',
    shareLink: 'Partager le lien',
    copyLink: 'Copier le lien',
    note: 'Remarque',
    enterNote: 'Saisir la remarque',
    undoKIV: 'Annuler l\'action de garder en mémoire',
    shortlist: 'Liste restreinte',
    undoShortlist: 'Annuler la liste de présélection',
    undoReject: 'Annuler le rejet',
    feedbackInfo1: 'Faites-nous part de votre avis sur ce candidat, par exemple en prévoyant une réunion avec moi le...',
  },
  candidateFilter: {
    candidateDateBetween: 'Date du candidat entre',
    selectStartDate: 'Sélectionner la date de début',
    selectEndDate: 'Sélectionner la date de fin',
    kived: 'Gardé en mémoire',
    filterKeyworkds: 'Filtrer les mots-clés dans la légende/le CV',
    resetFilter: 'Réinitialiser le filtrer',
    applyFilter: 'Appliquer le filtre',
    keywordFilterInfo: 'Appuyez sur « Entrer » ou « Onglet » pour ajouter un mot-clé séparé',
  },
  emailers: {
    reminder: 'Saisissez votre message ici [Remarque : Ceci n\'enverra qu\'un message au candidat. Aucun lien d\'entretien ne sera partagé].',
  },
  insightsPayWall: {
    coachingHeading: 'Libérez la puissance de la sélection basée sur les données',
    coachingMessage: 'Libérez le potentiel caché de votre processus de sélection des candidats en vous apportant un trésor de données inestimables et d\'analyses intelligentes. Plongez dans un monde où chaque sélection d\'entretien devient une opportunité d\'obtenir des informations approfondies sur les candidats, vous aidant ainsi à prendre des décisions de sélection de premier ordre à chaque fois.',
    heading: 'Libérez la puissance du recrutement basé sur les données',
    message: 'Libérez le potentiel caché de votre processus de sélection des candidats en vous apportant un trésor de données inestimables et d\'analyses intelligentes. Plongez dans un monde où chaque sélection d\'entretien devient une opportunité d\'obtenir des informations approfondies sur les candidats, vous aidant ainsi à prendre des décisions d\'embauche de premier ordre à chaque fois.',
    interested: 'Je suis intéressé',
  },
  candidateFilters: {
    filterCandidates: 'Filtrer les candidats',
    applciationRecievedBetween: 'Candidatures reçues entre',
    startDate: 'Date de début',
    endDate: 'Date de fin',
    location: 'Emplacement',
    selectLocation: 'Sélectionnez l\'emplacement',
    candidateStatus: 'Statut du candidat',
    selectStatus: 'Sélectionnez le statut',
    recruiterStatus: 'Statut du recruteur',
    introQuestions: 'Questions d\'introduction',
    selectOptions: 'Sélectionnez les options',
    reset: 'Réinitialiser',
    applyFilter: 'Appliquer le filtre',
  },
  newPlansPage: {
    expiryMessage: 'Les crédits de votre compte Interviewer.AI ont expiré. Achetez des crédits pour continuer à utiliser nos interviews vidéo.',
    selectCredits: 'sélectionnez les crédits',
    egText: 'Ex. : 500 crédits = 500 interviews vidéo',
    pricingMainText: 'Consultez les options de tarification pour automatiser votre embauche',
    pricingMainSecondaryText: 'Bénéficiez d\'une gamme de fonctionnalités et d\'avantages conçus pour vous.',
    assessmentScoreIncHeading: 'Que comprennent les résultats d’évaluation des candidats?',
    resumeScore: 'Reprendre le score',
    assessmentScorePrimHeading: 'Interviewer.AI fournit la solution de sélection de recrutement la plus complète et la plus complète qui comprend trois composants de base',
    resumeSectionPara1: 'Resume Score utilise des algorithmes de reconnaissance optique de caractères (OCR) et de traitement profond du langage naturel (NLP) pour extraire le texte des CV et le faire correspondre à la description du poste.',
    resumeSectionPara2: 'Le score IA remplace la recherche par mot-clé traditionnelle (que les recruteurs utilisent largement) et la remplace par des algorithmes d\'apprentissage en profondeur qui comprennent le contexte derrière chaque mot d\'un CV en utilisant la reconnaissance d\'entité nommée.',
    resumeSectionPara3: 'Il vous donne des scores IA absolus, basés sur une base de données de 120 millions de CV.',
    workmapScore: 'Feuille de travail',
    workmapSectionPara1: 'Le WorkMap évalue les aptitudes et les compétences des candidats en fonction des rôles spécifiques pour lesquels ils ont postulé.',
    workmapSectionPara2: 'Les compétences requises pour effectuer certaines tâches professionnelles varient selon les secteurs, fonctions ou rôles. Avec le WorkMap, les candidats sont évalués sur les compétences essentielles qui les aideront à réussir dans leurs fonctions à l\'avenir.',
    workmapSectionPara3: 'Le score est basé sur une échelle de Likert d\'agréabilité en 5 points contenant 25 à 35 questions où chaque compétence est évaluée à l\'aide de 5 à 7 énoncés.',
    aiVideoScore: 'Score vidéo IA',
    aiVideoSectionPara1: 'Le cadre exclusif d\'Interviewer.Al fournit des évaluations des paroles, de la voix et des expressions faciales des candidats lors de l\'entretien vidéo.',
    aiVideoSectionPara2: 'Les candidats reçoivent une note sur cent pour chaque paramètre d\'évaluation. Ces scores sont regroupés dans un score global pour leur entretien vidéo.',
    aiVideoSectionPara3: 'A higher score on the bar would indicate that the candidate fared better based on objective ratings of their behaviour on the video interview based on public and on platform datasets.',
    quickPreviewText: 'Voici un aperçu rapide',
    all: 'Plan',
    plans: 'Caractéristiques',
    yourAiCredits: 'Vos crédits d\'entretien IA',
    myCurrentPlan: 'Mon forfait actuel',
    active: 'Actif',
    expDate: 'Date d\'expiration',
    addYourCred: 'Ajoutez vos crédits',
    videoInt: 'entretiens vidéo',
    availCred: 'crédit disponible',
    addCred: 'Ajouter des crédits',
    newCred: 'Nouveaux crédits',
    validOn: 'Valable jusqu\'au',
    price: 'Prix',
    congText: 'Toutes nos félicitations!',
    unlockPlan: 'Vous avez débloqué le {{activeCurrentPlan}} Plan.',
    unlockEnterprisePlan: 'Vous êtes admissible à notre {{activeCurrentPlan}} Plan.',
    schedDemo: 'Planifier une démo',
    buyNow: 'Acheter maintenant',
    unlocked: 'Débloqué',
    starterPlanHeaderText: 'Pour une embauche limitée',
    growthPlanHeaderText: 'Pour un recrutement accéléré',
    premiumPlanHeaderText: 'Pour les équipes évolutives',
    benifits: 'Avantages',
    userLicense: 'Licence utilisateur',
    admin: 'Administrateur',
    recuiter: 'Recruteur',
    accountAlloc: 'Allocations de compte',
    noOfJobsInterviews: 'Nombre d\'entretiens d\'embauche',
    noOfAIInterAssessments: 'Number of AI Interview Assessments',
    generativeAiAssist: 'Assistance IA générative',
    autoGenJobDes: 'Description de poste générée automatiquement',
    candSummay: 'Résumé du candidat',
    genAiQuesGeneration: 'Génération de questions Gen AI',
    socialFeatures: 'Fonctionnalités sociales',
    careerPage: 'Page Carrière',
    linkWithJobPost: 'Lien avec les offres d\'emploi LinkedIn',
    stackRankAlgo: 'Algorithme de classement de pile',
    resumeScoring: 'Reprendre la notation',
    workmapAssess: 'Évaluations WorkMap',
    scoreSettings: 'Paramètres de partition',
    contentReleScore: 'Score de pertinence du contenu',
    contentReleScoreBreakdown: 'Répartition du score de pertinence du contenu',
    support: 'Soutien',
    emailSupport: 'Assistance par e-mail',
    dedicatedAccountManger: 'Gestionnaire de compte dédié',
    expired: 'Expiré',
    infoIconText: 'Crédit = une critique vidéo',
  },
  expiryPlanModal: {
    firstMesssage: 'Vos crédits gratuits expireront dans',
    firstNonTrialMesssage: 'Vos crédits expireront dans',
    day: 'jour.',
    days: 'jours.',
    secondMessage: 'Achetez des crédits maintenant avant leur expiration et obtenez 20 % de réduction sur n\'importe quel forfait.',
    thirdMessage: 'Utiliser le code',
    fourthMessage: 'à la caisse pour profiter de cette offre',
  },
  creditsAlertText: {
    message: 'Vous avez dépassé les crédits alloués sur votre compte. Pour accéder aux réponses et évaluations des nouveaux candidats, veuillez acheter des crédits immédiatement.',
  },
};
