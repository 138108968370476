import alt from '../alt';
import ShareActions from '../actions/ShareActions';
import { CREDITS_PLANS_PRICE } from '../utils/constants';
import { getAverage, convertToDecimal } from '../utils/utils';

const getTotalScore = (candidates, scoreParams, enabledScoreCategory) => {
  const { video } = scoreParams;
  const {
    professionalism,
    energyLevel,
    sociability,
    communication,
  } = video;

  const updatedTotalScore = candidates.map((c) => {
    let totalResumeScore = 0;
    let totalWorkmapScore = 0;
    if (c.metadata && c.metadata.parsed_resume && c.metadata.parsed_resume.score) {
      totalResumeScore = c.metadata.parsed_resume.score.overall;
    }
    let totalVideoScore = 0;

    if (c.total_relevancy_score) {
      const relevancyScore = true;
      totalVideoScore = (convertToDecimal(c.professionalismScore) * professionalism
        + convertToDecimal(c.sociabilityScore) * sociability
        + convertToDecimal(c.energyLevelScore) * energyLevel
        + convertToDecimal(c.totalCommunicationScore) * communication
        + convertToDecimal(c.total_relevancy_score) * relevancyScore)
        / (professionalism + sociability + energyLevel + communication + relevancyScore);
    } else {
      totalVideoScore = (convertToDecimal(c.professionalismScore) * professionalism
        + convertToDecimal(c.sociabilityScore) * sociability
        + convertToDecimal(c.energyLevelScore) * energyLevel
        + convertToDecimal(c.totalCommunicationScore) * communication)
        / (professionalism + sociability + energyLevel + communication);
    }
    if (c.metadata && c.metadata.workmap_score) {
      const workmapScoreObject = c.metadata.workmap_score;
      const workmapScores = Object.values(workmapScoreObject);
      totalWorkmapScore = getAverage(workmapScores);
    }
    const {
      workmapScoreEnabled,
      resumeScoreEnabled,
      videoScoreEnabled,
    } = enabledScoreCategory;

    if (workmapScoreEnabled && resumeScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+totalWorkmapScore + +totalVideoScore + +totalResumeScore) / 3;  //eslint-disable-line
    } else if (workmapScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+totalWorkmapScore + +totalVideoScore) / 2;  //eslint-disable-line
    } else if (resumeScoreEnabled && videoScoreEnabled) {
      c.totalScore = (+totalResumeScore + +totalVideoScore) / 2;  //eslint-disable-line
    } else {
      c.totalScore = totalVideoScore;     //eslint-disable-line
    }
    return c;
  });
  return updatedTotalScore;
};
class ShareStore {
  constructor() {
    this.interviewId = '';
    this.interviewName = '';
    this.companyName = '';
    this.displayData = [];
    this.questions = [];
    this.isDataLoading = true;
    this.loadDataFail = false;
    this.errMessage = '';
    this.videoCache = {};
    this.videoUrl = '';
    this.videoCaption = '';
    this.getVideoFail = true;
    this.hiringManagerEmail = '';
    this.newBillingPlans = CREDITS_PLANS_PRICE;
    this.autoplay = false;
    this.selectedCandidatesIndex = 0;
    this.candidateQuestions = [];
    this.selectedQuestionIndex = 0;
    this.feedbackParametersType = 'workmapFeedback';
    this.enabledScoreCategory = {
      resumeScoreEnabled: true,
      workmapScoreEnabled: true,
      videoScoreEnabled: true,
    };
    this.scoreParams = {
      workmap: {},
      video: {
        professionalism: true,
        energyLevel: true,
        communication: true,
        sociability: true,
      },
      resume: {
        education: true,
        skill: true,
        experience: true,
      },
    };
    this.interviewDetail = {};

    this.bindListeners({
      handleGetInterviewInfoFail: ShareActions.GET_INTERVIEW_INFO_FAIL,
      handleGetInterviewInfoPass: ShareActions.GET_INTERVIEW_INFO_PASS,
      handleGetVideoUrlPass: ShareActions.GET_VIDEO_URL_PASS,
      handleGetVideoUrlFail: ShareActions.GET_VIDEO_URL_FAIL,
      handleSetVideoCache: ShareActions.SET_VIDEO_CACHE,
      handleSubmitConsentSuccessful: ShareActions.SUBMIT_CONSENT_SUCCESSFUL,
      handleUpdatePlanUserCount: ShareActions.UPDATE_PLAN_USER_COUNT,
      handleSetAutoPlay: ShareActions.SET_AUTO_PLAY,
      handleUpdateIndex: ShareActions.UPDATE_INDEX,
    });
  }

  handleGetInterviewInfoFail(msg) {
    this.loadDataFail = true;
    this.errMessage = msg;
    this.isDataLoading = false;
  }

  handleGetInterviewInfoPass(data) {
    this.loadDataFail = false;
    this.interviewId = data.interviewId;
    this.interviewName = data.interview.interviewname;
    this.companyName = data.interview.company_name;
    this.displayData = data.candidatesData;
    this.isDataLoading = false;
  }

  handleGetVideoUrlPass(data) {
    this.getVideoFail = false;
    this.videoUrl = data.videoUrl;
    this.videoCaption = data.captionUrl;
  }

  handleGetVideoUrlFail(msg) {
    this.getVideoFail = true;
    this.errMessage = msg;
  }

  handleSetVideoCache(data) {
    this.videoCache[data.candidateId] = data.videoCache;
  }

  handleSubmitConsentSuccessful(data) {
    const {
      candidatesData, email, feedbackParametersType, interviewData,
    } = data;
    this.interviewDetail = interviewData;
    this.enabledScoreCategory = {
      ...this.enabledScoreCategory,
      workmapScoreEnabled: interviewData.workmap_enabled || false,
      resumeScoreEnabled:
        (interviewData.interview_description
          && interviewData.resume_mandatory)
        || false,
    };
    this.interviewId = candidatesData[0].interviewid;
    this.displayData = getTotalScore(candidatesData, this.scoreParams, this.enabledScoreCategory);
    this.hiringManagerEmail = email;
    console.log('data in share', data);
    this.candidateQuestions = candidatesData.map(candidate => ({
      candidateId: candidate.candidateid,
      questions: candidate.questions,
    }));
    // by default first candidate and first question
    const updateIndexObj = {
      selectedCandidatesIndex: 0,
      selectedQuestionIndex: 0,
    };
    this.feedbackParametersType = feedbackParametersType;
    this.handleUpdateIndex(updateIndexObj);
  }

  handleUpdatePlanUserCount(data) {
    const { userType, count } = data;
    this.newBillingPlans[userType] = count;
  }

  handleSetAutoPlay() {
    this.autoplay = true;
  }

  handleUpdateIndex(data) {
    this.selectedCandidatesIndex = data.selectedCandidatesIndex;
    this.selectedQuestionIndex = data.selectedQuestionIndex;
    this.currentCandidateId = this.displayData[this.selectedCandidatesIndex]
      ? this.displayData[this.selectedCandidatesIndex].candidateid
      : '';
  }
}

export default alt.createStore(ShareStore, 'ShareStore');
