import React from 'react';
import { ClipLoader } from 'react-spinners';
import { css } from 'react-emotion';
import PropTypes from 'prop-types';

const ClipLoadingComponent = ({ inverse, floatRight }) => (
  <ClipLoader color={inverse ? '#ffffff' : '#fd6144'} size={20} className={floatRight ? css`float: right;  width: 20px !important` : css`float: left; margin-left: 180px; margin-bottom: 15px; width: 20px !important`} />
);

ClipLoadingComponent.propTypes = {
  inverse: PropTypes.bool,
  floatRight: PropTypes.bool,
};

ClipLoadingComponent.defaultProps = {
  inverse: false,
  floatRight: true,
};

export default ClipLoadingComponent;
