// @flow
import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import Auth from '../auth/Auth';
import integrations from './integrations';

class CommonApiCalls {
  changeInterviewBackground = async (
    interviewId: string,
    interviewBackgroundUrl: string,
  ) => (
    fetch(`/api/interview/${interviewId}/backgroundImage`, {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        interviewBackgroundUrl,
      }),
    })
  );

  uploadInterviewBackground = async (
    interviewBackground: Object,
    interviewId: ?string,
  ) => {
    // upload logo to S3, get public URL
    const formData = new FormData();
    formData.append('file', interviewBackground);
    if (interviewId) {
      formData.append('interviewId', interviewId);
    }

    return fetch('/api/interview/backgroundImage', {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
      },
      method: 'POST',
      body: formData,
    });
  };

  getWordcloud = async (
    interviewId: string,
    candidateId: string,
    candidateSlug: string,
  ) => (
    fetch(`/shareApi/wordcloudPresignedUrl?interviewId=${interviewId}&candidateId=${candidateId}&candidateSlug=${candidateSlug}`, {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })
  );

  getCalendlyEligibleCandidatesAndToken = async (
    interviewId: string,
    candidateData: Array<object>,
  ): [Array<object>, string] => {
    // 1. unsuccessful calendly callback or no keys -> not eligible
    // 2. candidateIds in scheduled interview table -> not eligible
    // 3. candidateIds not in scheduled interview table && successful calendly callback -> eligible
    const serviceKeysJson = await integrations.getKeys();

    const calendly = serviceKeysJson.servicesKeysSecrets.find(obj => obj.service === 'calendly');
    if (calendly) {
      const calendlyToken = calendly.api_key;
      const callBackRes = await fetch('https://calendly.com/api/v1/echo', {
        headers: {
          'X-TOKEN': calendlyToken,
        },
      });
      if (callBackRes.status !== 200) {
        const newCandidateData = candidateData.map(c => ({ ...c, eligibleForCalendly: false }));
        return [newCandidateData, null];
      }
      const candidateIdsReq = await fetch(`/api/schedule-interview?interviewId=${interviewId}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });
      const candidateIdsData = await candidateIdsReq.json();
      const ineligibleCandidates = candidateIdsData.candidateId;
      // If ineligible for calendly candidates found in candidateData,
      // set eligibleForCalendly to false, else otherwise
      const newCandidateData = candidateData.map(c => ({
        ...c,
        eligibleForCalendly: !ineligibleCandidates.includes(c.candidateid),
      }));
      return [newCandidateData, calendlyToken];
    }
    const newCandidateData = candidateData.map(c => ({ ...c, eligibleForCalendly: false }));
    return [newCandidateData, null];
  };
}

export default new CommonApiCalls();
