import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from '../css/CandidateProfileButtonMobile.module.scss';
import { getInitial } from '../utils/utils';
import CandidateStore from '../stores/CandidateStore';
import CandidateActions from '../actions/CandidateActions';
import ShareActions from '../actions/ShareActions';

const thumbnailGenerator = (img) => { // eslint-disable-line

  return (
    <React.Fragment>
      {
        img && (
          <div
            className="avatars__item  ProfileInitials candidateCardImages"
            style={{
              backgroundImage: `url(${img})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              minWidth: '100%',
              minHeight: '100%',
              borderRadius: '50%',
            }}
          />
        )
      }
    </React.Fragment>
  );
};

const CandidateProfileButtonMobile = (props) => {
  const {
    individualCandidate,
    // onCandidateClick,
    isCurrentlyViewedCandidate,
    displayData,
    interviewId,
    parentComponent,
    changeCandidateIndex,
  } = props;
  const [candidateDetails, setCandidateDetails] = useState({});
  const [videoCache, setVideoCache] = useState(null);
  const [candidateQuestions, setCandidateQuestions] = useState(null);


  const { img } = individualCandidate;
  // const navigateToCandidate = () => {
  //   onCandidateClick(candidateid);b
  // };
  console.log('individualCandidate', individualCandidate.name);

  useEffect(() => {
    const receivedVideoCache = CandidateStore.getState().videoCache;
    const receivedCandidateQuestions = CandidateStore.getState().candidateQuestions;
    setVideoCache(receivedVideoCache);
    setCandidateQuestions(receivedCandidateQuestions);
  }, []);

  const getVideoUrl = async (candidateIndex, questionIndex) => {
    if (parentComponent !== 'ShareCandidate') {
      const candidateData = displayData[candidateIndex];
      const { questions } = candidateQuestions.find(
        data => data.candidateId === candidateData.candidateid,
      );
      CandidateActions.getVideoUrl(
        interviewId,
        candidateIndex,
        questionIndex,
        displayData,
        questions,
        videoCache,
      );
    }
  };

  const onCandidateClick = async (candidateId) => {
    const index = displayData.findIndex(
      candidate => candidate.candidateid === candidateId,
    );
    if (parentComponent === 'ShareCandidate') {
      changeCandidateIndex(candidateId);
      await ShareActions.updateIndex({
        selectedCandidatesIndex: index,
        selectedQuestionIndex: 0, // reset question index to 0
      });
      await getVideoUrl(index, 0);
    } else {
      await CandidateActions.getCandidateDetail(
        interviewId,
        candidateId,
        true,
        '',
        true,
      );
    }
    // await getVideoUrl(index, 0);
  };

  useState(() => {
    setCandidateDetails(individualCandidate);
  }, [individualCandidate]);

  return (
    <div className={styles.candidateProfileContainer}>
      <div
        className={
          isCurrentlyViewedCandidate ? styles.candidateScoreFillActive : styles.candidateScoreFillInactive // eslint-disable-line
        }
      >
        <button
          type="button"
          className={styles.candidateProfileButton}
          onClick={() => { onCandidateClick(candidateDetails.candidateid); }}
        >
          {isCurrentlyViewedCandidate && console.log('candidateDetails.name', candidateDetails.name)}
          {
            img ? (
              thumbnailGenerator(img)
            ) : (
              <span className="f18-500-blue">{getInitial(candidateDetails.name)}</span>
            )
          }
        </button>
      </div>
    </div>
  );
};


CandidateProfileButtonMobile.propTypes = {
  // onCandidateClick: PropTypes.func.isRequired,
  isCurrentlyViewedCandidate: PropTypes.bool.isRequired, // eslint-disable-line
  individualCandidate: PropTypes.object, // eslint-disable-line
  displayData: PropTypes.arrayOf(PropTypes.shape({
    candidateid: PropTypes.string.isRequired,
  })).isRequired,
  interviewId: PropTypes.string.isRequired,
  parentComponent: PropTypes.string.isRequired,
  changeCandidateIndex: PropTypes.func.isRequired,
};


export default CandidateProfileButtonMobile;
