import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import draftToHtml from 'draftjs-to-html';
import { convertToRaw } from 'draft-js';
import alt from '../alt';
import Auth from '../auth/Auth';
import {
  imageFileValidation,
  mxTrackEvent,
} from '../utils/utils';
import OrganizationActions from './OrganizationActions';
import 'react-toastify/dist/ReactToastify.min.css';
import integrations from '../utils/integrations';
import { DEFAULT_API_DATA } from '../utils/constants';
import { successToast, alertToast } from '../components/Toast';

class SettingActions {
  constructor() {
    this.getProfileBasicInfoSuccess = profileData => profileData;
    this.getProfileBasicInfoFail = () => true;
    this.getIntegrationInfo = apiData => apiData;
    this.inputChange = profileData => profileData;
    this.inputApiChange = formHtmlData => formHtmlData;
    this.isSavingProfile = () => true;
    this.saveProfileSuccess = msg => msg;
    this.saveProfileFail = msg => msg;
    this.updateCompanySuccess = () => true;
    this.updateCompanyFail = () => true;
    this.companyLogoInvalid = () => true;
    this.companyLogoIsValid = file => file;
    this.changeCompanyDescription = e => e;
    this.userProfileImageInvalid = () => true;
    this.userProfileImageValid = file => file;
    this.profileInfoSaveCompleted = data => data;
    this.setOrgIntroductionVideoUrl = url => url;
    this.setCompanyIntroductionVideoObj = obj => obj;
    this.setCompanyDummyIntroductionVideoObj = obj => obj;
    this.fetchOrgSettingsSuccess = obj => obj;
  }

  async getProfileBasicInfo() {
    try {
      mxTrackEvent('Profile screen loading');
      const req = await fetch('/api/settings/profile', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
      });

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        const {
          company_name: companyName,
          company_logo_url: companyLogoUrl,
          description,
          industry_name, // eslint-disable-line
          career_site_active, // eslint-disable-line
          career_site_company_name, // eslint-disable-line
        } = res.organization;
        const {
          firstName,
          lastName,
          orgId,
          userId,
        } = res.profile;
        const { isAppsumo } = res;
        const { redirect_params, user_logo_url, phone } = res.userInfo; // eslint-disable-line
        this.setOrgIntroductionVideoUrl(res.introductionPresignedUrl);
        this.setCompanyIntroductionVideoObj({
          name: (res.introductionPresignedUrl.split('/').pop()).split('?')[0],
        });
        const data = {
          firstName,
          lastName,
          email: Auth.getProfileInfo('email'),
          companyName,
          companyLogoPreviewUrl: companyLogoUrl,
          description,
          redirect_params, // eslint-disable-line
          industry_name, // eslint-disable-line
          career_site_company_name, // eslint-disable-line
          user_logo_url, // eslint-disable-line
          phone,
          career_site_active, // eslint-disable-line
          orgId,
          userId,
          isAppsumo,
        };

        this.getProfileBasicInfoSuccess(data);
        mxTrackEvent('Profile screen loaded');
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.error(e);
      this.getProfileBasicInfoFail();
      mxTrackEvent('Profile screen load failed');
    }
  }

  async uploadCompanyLogo(companyLogo) {    //eslint-disable-line
    try {
      if (companyLogo) {
        const formData = new FormData();
        formData.append('file', companyLogo);
        const uploadLogoReq = await fetch('/api/settings/companyLogo', {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
          body: formData,
          method: 'POST',
        });
        const uploadLogoRes = await uploadLogoReq.json();
        if (uploadLogoReq.status !== 200) {
          console.log('error in uploading companyLogo');
          throw new Error(uploadLogoRes.error);
        }
        await OrganizationActions.fetchOrganizationData();
      }
    } catch (e) {
      console.log(e);
    }
  }

  async saveProfile(
    profileData,
    companyLogo,
    companyLogoValid,
    companyDescription,
    changeCareerSiteUrl = false,
  ) {
    // let isSaveApiSuccess = true;
    try {
      this.isSavingProfile();
      mxTrackEvent('Saving profile', {
        profileData, companyLogo, companyLogoValid, companyDescription,
      });
      if (companyLogo != null && companyLogoValid) {
        // upload logo to S3
        const formData = new FormData();
        formData.append('file', companyLogo);
        const uploadLogoReq = await fetch('/api/settings/companyLogo', {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
          body: formData,
          method: 'POST',
        });
        const uploadLogoRes = await uploadLogoReq.json();

        if (uploadLogoReq.status !== 200) {
          console.log(`Error uploading logo to s3: ${uploadLogoRes.error}`);
          throw new Error(uploadLogoRes.error);
        }
      }
      await this.updateProfile(profileData, companyDescription, changeCareerSiteUrl);
      this.saveProfileSuccess('Profile saved successfully');
      mxTrackEvent('Profile saved successfully');
    } catch (e) {
      console.log(e.toString());
      // const errorMessage = e.description || e;
      this.saveProfileFail(e.message);
    }

    return true;
  }

  async updateProfile(
    profileData,
    companyDescription,
    changeCareerSiteUrl,
    formInterviewCreation = false,
  ) { //eslint-disable-line
    const descriptionContent = companyDescription.getCurrentContent();
    const interviewDescription = draftToHtml(convertToRaw(descriptionContent));
    this.profileInfoSaveCompleted(false);
    const updateProfile = await fetch('/api/settings/profile', {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'PATCH',
      body: JSON.stringify({
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        companyName: profileData.companyName,
        description: interviewDescription,
        industry: profileData.industry ? profileData.industry.value : null,
        changeCareerSiteUrl,
        phone: profileData.phone ? profileData.phone : null,
        careerSiteActive: profileData.career_site_active,
        careerSiteCompanyName: profileData.career_site_company_name,
      }),
    });

    const updateProfileRes = await updateProfile.json();
    if (updateProfile.status !== 200 && updateProfileRes.reason !== 'duplicate_career_url') {
      alertToast('Profile information updation failed');
      throw new Error(updateProfileRes.error);
    } else if (updateProfile.status === 400) {
      alertToast(updateProfileRes.error);
    } else {
      if (!formInterviewCreation) {   //eslint-disable-line
        successToast('Profile information updated successfully');
      }
    }


    this.getProfileBasicInfo();
    this.profileInfoSaveCompleted(true);

    return true;
  }

  async fileInputChange(profileData, logoType = 'companyLogo') {
    const { files } = profileData;
    const file = files[0];
    const imageFileValid = imageFileValidation(file, 1048576, '1MB');
    if (!file.name || !file.name.toString().match(/\.(jpg|jpeg|png)$/)) { // check for invalid filename
      alertToast('Invalid file extension. We support png, jpg, jpeg file extensions only.');
    } else {
      if (!imageFileValid) {                           //eslint-disable-line
        if (logoType === 'userProfileImage') {
          this.userProfileImageInvalid();
        } else {
          console.log('logo is invalid');
          this.companyLogoInvalid();
        }
      } else {
        mxTrackEvent('Profile Logo Changed');
        if (logoType === 'userProfileImage') {
          this.userProfileImageValid(file);
        } else {
          this.companyLogoIsValid(file);
        }
      }
    }
  }

  async loadApiKeys() {
    const apiData = DEFAULT_API_DATA;
    const serviceKeysJson = await integrations.getKeys();
    if (serviceKeysJson.servicesKeysSecrets.length === 0) {
      this.getIntegrationInfo(DEFAULT_API_DATA);
    } else {
      // eslint-disable-next-line array-callback-return
      Object.keys(apiData).map((key) => {
        const apiOfKey = serviceKeysJson.servicesKeysSecrets.find(e => e.service === key);
        if (apiOfKey) {
          apiData[key] = {
            api_key: apiOfKey.api_key,
            api_secret: apiOfKey.api_secret,
          };
        }
      });
      this.getIntegrationInfo(apiData);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async saveApiKey(apiKey, apiSecret, service) {
    return integrations.addKeys(apiKey, apiSecret, service);
  }

  // eslint-disable-next-line class-methods-use-this
  async removeApiKey(service) {
    return integrations.removeKey(service);
  }

  async uploadUserImage(userProfileImage, userProfileImageValid) {
    try {
      mxTrackEvent('Saving User Profile', {
        userProfileImage,
      });
      if (userProfileImage != null && userProfileImageValid) {
        // upload logo to S3
        const formData = new FormData();
        formData.append('file', userProfileImage);
        this.profileInfoSaveCompleted(false);
        const uploadLogoReq = await fetch('/api/settings/personalLogo', {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
          body: formData,
          method: 'POST',
        });
        const uploadLogoRes = await uploadLogoReq.json();

        if (uploadLogoReq.status !== 200) {
          console.log(`Error uploading logo to s3: ${uploadLogoRes.error}`);
          alertToast('Update profile image failed');

          throw new Error(uploadLogoRes.error);
        }
        successToast('Profile image updated successfully');
        mxTrackEvent('Profile image updated successfully');
      }
    } catch (e) {
      console.log(e.toString());
      this.saveProfileFail(e.message);
      alertToast('Update profile image failed');
    }

    return true;
  }

  async fetchOrgSettings() {    //eslint-disable-line
    try {
      const orgSettingsReq = await fetch('/api/settings/fetch-orgsettings', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
        },
        method: 'GET',
      });
      const orgSettingsResponse = await orgSettingsReq.json();
      const {
        orgSettings,
      } = orgSettingsResponse;
      if (orgSettingsReq.status !== 200) {
        console.log('error in uploading companyLogo');
        throw new Error(orgSettings.error);
      }
      await this.fetchOrgSettingsSuccess(orgSettingsResponse.orgSettings);
    } catch (e) {
      console.log(e);
    }
  }
}

export default alt.createActions(SettingActions);
