import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  ProgressBar,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import {
  fetchScoreBreakDowns,
  isObjectEmpty,
  getProgressColorClass,
  getAverage,
} from '../../utils/utils';
import styles from '../../css/AiScoreComponent.module.scss';
import MoveDown from '../../assets/ArrowDown.svg';
import MoveUp from '../../assets/ArrowUp.svg';
import infoIcon from '../../assets/icons-info.svg';

import OrganizationStore from '../../stores/OrganizationStore';
import ScoreCircle from '../../components/CircularScoreChart';
import ShareStore from '../../stores/ShareStore';


const AiScoreComponent = ({
  aiScores,
  candidateData,
  source = 'candidateDetail',
  isFromShareShortList,
  orgId = null,
}) => {             //eslint-disable-line

  const {
    energyLevelScore,
    totalCommunicationScore,
    professionalismScore,
    sociabilityScore,
    videoScore,
    total_relevancy_score: totalRelevancyScore,
    metadata,
  } = candidateData;

  const { score } = candidateData;
  const { t } = useTranslation();

  const parsedCandidateAIScore = !isObjectEmpty(
    aiScores,
  ) ? JSON.parse(aiScores) : JSON.parse(score);
  const [feedbackParametersType, setFeedbackParametersType] = useState('workmapFeedback');
  const [activeAccordion, setActiveAccordian] = useState({
    relevancyScore: false,
    professionalism: false,
    positiveAttitude: false,
    communication: false,
    sociability: false,
  });

  const [relevancyScore, setRelevancyScore] = useState(null);

  const videoScorePopoverClick = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.videoScoreTooltip')}
    </Popover>
  );

  const ProfessionalismPopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.professionalismTooltip')}
    </Popover>
  );

  const PositiveAttitudePopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.positiveAttitudeTooltip')}
    </Popover>
  );

  const CommunicationPopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.communicationTooltip')}
    </Popover>
  );

  const SociabilityPopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.sociabilityTooltip')}
    </Popover>
  );

  const contentRelevancyPopover = (
    <Popover id="popover-trigger-click" className={styles.popupText}>
      {t('candidateDetails.contentRelevancyTooltip')}
    </Popover>
  );

  const handleSetActiveTab = (activeTab) => {
    setActiveAccordian({
      ...activeAccordion, [activeTab]: !activeAccordion[activeTab],
    });
  };

  useEffect(() => {
    if (source === 'sharedCandidate') {
      const { feedbackParametersType: feedbackType } = ShareStore.getState();
      setFeedbackParametersType(feedbackType);
    } else {
      const { feedbackParametersType: feedbackType } = OrganizationStore.getState();
      setFeedbackParametersType(feedbackType);
    }

    const {
      answer_relevancy: answerRelevancy,
    } = metadata;

    let parsedRelevancy;
    if (answerRelevancy) {
      if (typeof answerRelevancy === 'string') {
        parsedRelevancy = JSON.parse(answerRelevancy);
      } else {
        parsedRelevancy = answerRelevancy;
      }
      setRelevancyScore(parsedRelevancy);
    }
  }, []);


  const handleFetchScoreLabel = (label, cf) => {
    const scoreNames = [
      {
        key: 'dressingScore',
        label: 'Dressing',
      },
      {
        key: 'hairTidinessScore',
        label: 'Grooming',
      },
      {
        key: 'bodyLanguageScore',
        label: 'Body Language',
      },
      {
        key: 'arousalScore',
        label: 'Energy Levels',
      },
      {
        key: 'communicationScore',
        label: 'Pace & Clarity of Speech',
      },
      {
        key: 'articulationScore',
        label: 'Articulation',
      },
      {
        key: 'sentimentScore',
        label: 'Sentiments',
      },
      {
        key: 'emotionScore',
        label: 'Emotion',
      },
      {
        key: 'grammarScore',
        label: 'Grammar Score',
      },
    ];

    if (!label) {
      console.log('Coming from :::', cf);
      return 'others';
    }

    const result = scoreNames.filter(scoreName => scoreName.key === label);

    return result[0] ? result[0].label : 'Others';
  };

  const getAverageofVideoScores = arr => getAverage(arr);

  const getColorForProgressBar = Score => getProgressColorClass(Score);

  if (
    totalCommunicationScore === 0
    && professionalismScore === 0
    && energyLevelScore === 0
    && sociabilityScore === 0
  ) {
    return (
      <div>
        <div className={styles.scoreHeading}>
          <div className={styles.aiscorePercentage}>
            0%
          </div>
          <div className={styles.scoreHeadingName}>
            {t('candidateDetails.aiVideoScore')}
          </div>
        </div>
        <ScoreCircle score={0} />
      </div>
    );
  }

  return (
    <div>
      <div className={styles.popOverHeading}>
        <div className={styles.scoreHeading}>
          <div className={styles.aiscorePercentage}>
            {
              !isFromShareShortList
                ? videoScore.toFixed(0)
                : getAverageofVideoScores([
                  +totalCommunicationScore.toFixed(0),
                  +professionalismScore.toFixed(0),
                  +energyLevelScore.toFixed(0),
                  +sociabilityScore.toFixed(0),
                ]).toFixed(0)
            }
            %
          </div>
          <div className={styles.scoreHeadingName}>
            {t('candidateDetails.aiVideoScore')}
          </div>
        </div>
        {
          source === 'sharedCandidate' && (
            <OverlayTrigger trigger="click" rootClose placement="left" overlay={videoScorePopoverClick}>
              <button type="button"><img src={infoIcon} alt="video score tooltip" /></button>
            </OverlayTrigger>

          )
        }
      </div>

      <div className={styles.sectionContainer}>

        {/* ------ Content Relevancy starts------ */}
        {
          totalRelevancyScore !== null && totalRelevancyScore !== undefined && (
            <div className={styles.aiScoreSection}>
              <div className={styles.accordianHeading}>
                <div className={styles.scoreTitle}>
                  <span className={styles.scoreLabel}>
                    {t('candidateDetails.contentRelevancy')}
                  </span>
                  <span className={styles.betaVersionLabel}>Beta</span>
                  <OverlayTrigger
                    trigger={['hover', 'focus']}
                    rootClose
                    placement="left"
                    overlay={contentRelevancyPopover}
                  >
                    <span
                      className="glyphicon glyphicon-info-sign"
                      style={{
                        marginLeft: '5px',
                      }}
                    />
                  </OverlayTrigger>
                </div>
                <div className={styles.accordianButtonSection}>
                  <span className={styles.scorePercentage}>
                    {totalRelevancyScore.toFixed(0)}
                    %
                  </span>
                  {
                    feedbackParametersType === 'aiFeedback'
                    && relevancyScore
                    && relevancyScore.length > 0
                    && (
                      <button
                        type="button"
                        className={styles.chevronButton}
                        onClick={() => handleSetActiveTab('relevancyScore')}
                      >
                        <img
                          src={activeAccordion.relevancyScore ? MoveUp : MoveDown}
                          alt="open"
                          className={styles.chevronIcon}
                        />
                      </button>
                    )
                  }
                </div>
              </div>
              <ProgressBar
                now={totalRelevancyScore}
                bsStyle={getColorForProgressBar(totalRelevancyScore)}
              />
              {
                feedbackParametersType === 'aiFeedback' && relevancyScore !== null && (
                  <div
                    className={
                      classnames(
                        activeAccordion
                          .relevancyScore ? styles
                          .activeAccordianBody : styles
                          .inActiveAccordianBody,
                      )
                    }
                  >
                    {
                      relevancyScore && relevancyScore.map(elem => (
                        <div className={styles.breakDownSection}>
                          <span className={styles.breakDownLabel}>
                            {
                              elem.category ? elem.category : elem.question_text
                            }
                          </span>
                          <div className={styles.breakDown}>
                            <ProgressBar
                              now={elem.relevancy_score === 'SKIPPED' ? 0 : elem.relevancy_score}
                              bsStyle={getColorForProgressBar(elem.relevancy_score)}
                            />
                            <span className={styles.breakDownPercentage}>
                              {
                                elem.relevancy_score === 'SKIPPED'   //eslint-disable-line
                                  ? 'Skipped'
                                  : elem.relevancy_score
                                    ? `${Number(elem.relevancy_score).toFixed(0)}%` : '0%'
                              }
                            </span>
                          </div>
                        </div>
                      ))
                    }


                  </div>
                )
              }
            </div>
            // relevancyScore
          )
        }

        {/* ------ Sociability ends------ */}

        {/* ------ Professionalism starts------ */}
        <div className={styles.aiScoreSection}>
          <div className={styles.accordianHeading}>
            <div className={styles.scoreTitle}>
              <span className={styles.scoreLabel}>{t('candidateDetails.professionalism')}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                rootClose
                placement="left"
                overlay={ProfessionalismPopover}
              >
                <span
                  className="glyphicon glyphicon-info-sign"
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className={styles.accordianButtonSection}>
              <span className={styles.scorePercentage}>
                {professionalismScore.toFixed(0)}
                %
              </span>
              {
                feedbackParametersType === 'aiFeedback' && (
                  <button
                    type="button"
                    className={styles.chevronButton}
                    onClick={() => handleSetActiveTab('professionalism')}
                  >
                    <img
                      src={activeAccordion.professionalism ? MoveUp : MoveDown}
                      alt="open"
                      className={styles.chevronIcon}
                    />
                  </button>
                )
              }
            </div>

          </div>
          <ProgressBar
            now={professionalismScore}
            bsStyle={getColorForProgressBar(professionalismScore)}
          />
          {
            feedbackParametersType === 'aiFeedback' && (
              <div
                className={
                  classnames(
                    activeAccordion
                      .professionalism ? styles.activeAccordianBody : styles.inActiveAccordianBody,
                  )
                }
              >
                {
                  fetchScoreBreakDowns(parsedCandidateAIScore, 'professionalism').map(elem => (
                    <div className={styles.breakDownSection}>
                      <span className={styles.breakDownLabel}>
                        {
                          handleFetchScoreLabel(Object.keys(elem)[0], '1')
                        }
                      </span>
                      <div className={styles.breakDown}>
                        <ProgressBar
                          now={Object.values(elem)[0]}
                          bsStyle={getColorForProgressBar(Object.values(elem)[0])}
                        />
                        <span className={styles.breakDownPercentage}>
                          {Object.values(elem)[0] ? Object.values(elem)[0].toFixed(0) : 0}
                          %
                        </span>
                      </div>
                    </div>
                  ))
                }


              </div>
            )
          }
        </div>
        {/* ------ Professionalism ends------ */}

        {/* ------ Positive Attitude starts------ */}
        <div className={styles.aiScoreSection}>
          <div className={styles.accordianHeading}>
            <div className={styles.scoreTitle}>
              <span className={styles.scoreLabel}>{t('candidateDetails.positiveAttitude')}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                rootClose
                placement="left"
                overlay={PositiveAttitudePopover}
              >
                <span
                  className="glyphicon glyphicon-info-sign"
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className={styles.accordianButtonSection}>
              <span className={styles.scorePercentage}>
                {energyLevelScore.toFixed(0)}
                %
              </span>
              {
                feedbackParametersType === 'aiFeedback' && (
                  <button
                    type="button"
                    className={styles.chevronButton}
                    onClick={() => handleSetActiveTab('positiveAttitude')}
                  >
                    <img
                      src={activeAccordion.positiveAttitude ? MoveUp : MoveDown}
                      alt="open"
                      className={styles.chevronIcon}
                    />
                  </button>
                )
              }
            </div>

          </div>
          <ProgressBar
            now={energyLevelScore}
            bsStyle={getColorForProgressBar(energyLevelScore)}
          />
          {
            feedbackParametersType === 'aiFeedback' && (
              <div
                className={
                  classnames(
                    activeAccordion
                      .positiveAttitude ? styles.activeAccordianBody : styles.inActiveAccordianBody,
                  )
                }
              >
                {
                  fetchScoreBreakDowns(parsedCandidateAIScore, 'positiveAttitude').map(elem => (
                    <div className={styles.breakDownSection}>
                      <span className={styles.breakDownLabel}>
                        {
                          handleFetchScoreLabel(Object.keys(elem)[0], '2')
                        }
                      </span>
                      <div className={styles.breakDown}>
                        <ProgressBar
                          now={Object.values(elem)[0]}
                          bsStyle={getColorForProgressBar(Object.values(elem)[0])}
                        />
                        <span className={styles.breakDownPercentage}>
                          {Object.values(elem)[0] ? Object.values(elem)[0].toFixed(0) : 0}
                          %
                        </span>
                      </div>
                    </div>
                  ))
                }


              </div>
            )
          }
        </div>
        {/* ------ Positive Attitude ends------ */}


        {/* ------ Communication starts------ */}
        <div className={styles.aiScoreSection}>
          <div className={styles.accordianHeading}>
            <div className={styles.scoreTitle}>
              <span className={styles.scoreLabel}>{t('candidateDetails.communication')}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                rootClose
                placement="left"
                overlay={CommunicationPopover}
              >
                <span
                  className="glyphicon glyphicon-info-sign"
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className={styles.accordianButtonSection}>
              <span className={styles.scorePercentage}>
                {totalCommunicationScore.toFixed(0)}
                %
              </span>
              {
                feedbackParametersType === 'aiFeedback' && (
                  <button
                    type="button"
                    className={styles.chevronButton}
                    onClick={() => handleSetActiveTab('communication')}
                  >
                    <img
                      src={activeAccordion.communication ? MoveUp : MoveDown}
                      alt="open"
                      className={styles.chevronIcon}
                    />
                  </button>
                )
              }
            </div>

          </div>
          <ProgressBar
            now={totalCommunicationScore}
            bsStyle={getColorForProgressBar(totalCommunicationScore)}
          />
          {
            feedbackParametersType === 'aiFeedback' && (
              <div
                className={
                  classnames(
                    activeAccordion
                      .communication ? styles.activeAccordianBody : styles.inActiveAccordianBody,
                  )
                }
              >
                {
                  fetchScoreBreakDowns(parsedCandidateAIScore, 'communication', orgId).map(elem => (
                    <div className={styles.breakDownSection}>
                      <span className={styles.breakDownLabel}>
                        {
                          handleFetchScoreLabel(Object.keys(elem)[0], '3')
                        }
                      </span>
                      <div className={styles.breakDown}>
                        <ProgressBar
                          now={Object.values(elem)[0]}
                          bsStyle={getColorForProgressBar(Object.values(elem)[0])}
                        />
                        <span className={styles.breakDownPercentage}>
                          {Object.values(elem)[0] ? Object.values(elem)[0].toFixed(0) : 0}
                          %
                        </span>
                      </div>
                    </div>
                  ))
                }


              </div>
            )
          }
        </div>
        {/* ------ Communication ends------ */}


        {/* ------ Sociability starts------ */}
        <div className={styles.aiScoreSection}>
          <div className={styles.accordianHeading}>
            <div className={styles.scoreTitle}>
              <span className={styles.scoreLabel}>{t('candidateDetails.sociability')}</span>
              <OverlayTrigger
                trigger={['hover', 'focus']}
                rootClose
                placement="left"
                overlay={SociabilityPopover}
              >
                <span
                  className="glyphicon glyphicon-info-sign"
                  style={{
                    marginLeft: '5px',
                  }}
                />
              </OverlayTrigger>
            </div>
            <div className={styles.accordianButtonSection}>
              <span className={styles.scorePercentage}>
                {sociabilityScore.toFixed(0)}
                %
              </span>
              {
                feedbackParametersType === 'aiFeedback' && (
                  <button
                    type="button"
                    className={styles.chevronButton}
                    onClick={() => handleSetActiveTab('sociability')}
                  >
                    <img
                      src={activeAccordion.sociability ? MoveUp : MoveDown}
                      alt="open"
                      className={styles.chevronIcon}
                    />
                  </button>
                )
              }
            </div>

          </div>
          <ProgressBar
            now={sociabilityScore}
            bsStyle={getColorForProgressBar(sociabilityScore)}
          />
          {
            feedbackParametersType === 'aiFeedback' && (
              <div
                className={
                  classnames(
                    activeAccordion
                      .sociability ? styles.activeAccordianBody : styles.inActiveAccordianBody,
                  )
                }
              >
                {
                  fetchScoreBreakDowns(parsedCandidateAIScore, 'sociability').map(elem => (
                    <div className={styles.breakDownSection}>
                      <span className={styles.breakDownLabel}>
                        {
                          handleFetchScoreLabel(Object.keys(elem)[0], '4')
                        }
                      </span>
                      <div className={styles.breakDown}>
                        <ProgressBar
                          now={Object.values(elem)[0]}
                          bsStyle={getColorForProgressBar(Object.values(elem)[0])}
                        />
                        <span className={styles.breakDownPercentage}>
                          {Object.values(elem)[0] ? Object.values(elem)[0].toFixed(0) : 0}
                          %
                        </span>
                      </div>
                    </div>
                  ))
                }
              </div>
            )
          }
        </div>
        {/* ------ Sociability ends------ */}
      </div>
    </div>
  );
};

AiScoreComponent.defaultProps = {
  source: 'candidateDetail',
  aiScores: {},
};


AiScoreComponent.propTypes = {
  candidateData: PropTypes.shape({
    energyLevelScore: PropTypes.number,
    totalCommunicationScore: PropTypes.number,
    professionalismScore: PropTypes.number,
    sociabilityScore: PropTypes.number,
    videoScore: PropTypes.number,
    total_relevancy_score: PropTypes.number,
    score: PropTypes.object, // eslint-disable-line
    metadata: PropTypes.shape({ // eslint-disable-line
      answer_relevancy: PropTypes.array, // eslint-disable-line
    }).object,  // eslint-disable-line
  }).isRequired,
  aiScores: PropTypes.object, // eslint-disable-line
  source: PropTypes.string,
  isFromShareShortList: PropTypes.bool.isRequired,
  orgId: PropTypes.string.isRequired,
};

export default AiScoreComponent;
