import React from 'react';
import {
  Button,
  Col,
  ControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Row,
  HelpBlock,
  Checkbox,
} from 'react-bootstrap';
import 'es6-promise/auto';
import fetch from 'isomorphic-fetch';
import { withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import {
  isCommonEmail,
  mxTrackEvent,
  getUrlParams,
} from '../utils/utils';
import ClipLoadingComponent from '../components/ClipLoadingComponent';
import BrandImg from '../assets/Brand-Dark.png';
import AppsumoLogo from '../assets/AppSumo-Logo.png';
import '../css/LoginScreen.css';

import Auth from '../auth/Auth';
import styles from '../css/AppSumoSignUp.module.scss';
import LoadingComponent from '../components/LoadingComponent';
import MultivalueInput from '../components/MultivalueInput';


class AppSumoSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialFirstName: true,
      initialLastName: true,
      initialEmail: true,
      initialCompanyName: true,
      initialPassword: true,
      initialConfirmPassword: true,
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      password: '',
      confirmPassword: '',
      firstNameValid: false,
      lastNameValid: false,
      emailValid: false,
      companyNameValid: false,
      passwordValid: false,
      passwordHasLowerCase: false,
      passwordHasUpperCase: false,
      passwordHasDigit: false,
      passwordHasSpecialChar: false,
      passwordLongEnough: false,
      confirmPasswordValid: false,
      isSigningUp: false,
      signUpFailure: false,
      signUpFailureMessage: '',
      termsAndPrivacyAgreed: false,
      disableCompanyNameField: false,
      disableEmailField: false,
      brandLogo: BrandImg,
      promoCodes: [],
      validatingCode: false,
      message: 'Fill up the information below to get started',
      signUpText: 'Sign Up',
      isLoading: true,
      emailValidationMsg: '',
    };

    this.handlePromoCodesChange = this.handlePromoCodesChange.bind(this);
    this.checkPromoCodeValidity = this.checkPromoCodeValidity.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSignUp = this.handleSignUp.bind(this);
    this.checkboxOnClick = this.checkboxOnClick.bind(this);
    this.topRef = React.createRef();
  }

  componentDidMount() {
    const { history } = this.props;

    if (Auth.isAuthenticated()) {
      history.replace('/interviews');
    }

    // read promo code(s) from the URL
    const params = getUrlParams(window.location.search);
    let appsumoPromoCodes = params.APPSUMO_PROMO || [];
    if (!Array.isArray(appsumoPromoCodes)) { // single promo code
      appsumoPromoCodes = [appsumoPromoCodes];
    }

    if (appsumoPromoCodes.length === 0) {
      this.setState({
        signUpFailureMessage: 'You need to purchase a license on AppSumo to sign up.',
        signUpFailure: true,
      });
      this.setState({ isLoading: false });
    } else {
      this.checkPromoCodeValidity(appsumoPromoCodes);
    }
  }

  async checkPromoCodeValidity(codes) {
    const promoCodes = Array.from(new Set(codes));
    const promoCodesQuery = promoCodes.join('&promoCodes=');
    this.setState({
      validatingCode: true,
      signUpFailure: false,
    }, async () => {
      try {
        const req = await fetch(`/signUpApi/appsumo/promo-code/?promoCodes=${promoCodesQuery}`, {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'GET',
        });

        const res = await req.json();
        if (req.status >= 400 && req.status <= 500) {
          throw new Error(res.message);
        }
        this.setState({
          promoCodes,
          signUpFailure: false,
        });
        this.setState({ isLoading: false });
      } catch (err) {
        this.setState({
          signUpFailureMessage: err.message,
          signUpFailure: true,
        });
        console.error(err);
        this.setState({ isLoading: false });
      } finally {
        this.setState({ validatingCode: false });
      }
    });
  }

  handleInputChange(e) {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, () => {
      this.validateField(name, value);
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleSignUp();
    }
  }

  handlePromoCodesChange(promoCodes) {
    if (promoCodes.length > 20) {
      this.setState({
        signUpFailure: true,
        signUpFailureMessage: 'Stack up to 10 codes only.',
      });
    } else {
      this.checkPromoCodeValidity(promoCodes);
    }
  }

  validateField(fieldName, value) {
    let {
      firstNameValid,
      lastNameValid,
      companyNameValid,
      passwordValid,
      confirmPasswordValid,
      passwordHasLowerCase,
      passwordHasUpperCase,
      passwordHasDigit,
      passwordHasSpecialChar,
      passwordLongEnough,
    } = this.state;

    const {
      password,
      confirmPassword,
    } = this.state;

    switch (fieldName) {
      case 'firstName':
        firstNameValid = !!value;
        this.setState({
          firstNameValid,
          initialFirstName: false,
        });
        break;
      case 'lastName':
        lastNameValid = !!value;
        this.setState({
          lastNameValid,
          initialLastName: false,
        });
        break;
      case 'email':
        if (!value.match(/\S+@\S+\.\S+/i)) {
          this.setState({
            emailValid: false,
            initialEmail: false,
            emailValidationMsg: 'Invalid email.',
          });
        } else if (isCommonEmail(value)) {
          this.setState({
            emailValid: false,
            initialEmail: false,
            emailValidationMsg: 'You will need a business email ID to create an account.',
          });
        } else {
          this.setState({
            emailValid: true,
            initialEmail: false,
            emailValidationMsg: '',
          });
        }
        break;
      case 'companyName':
        companyNameValid = !!value;
        this.setState({
          companyNameValid,
          initialCompanyName: false,
        });
        break;
      case 'password':
        passwordHasLowerCase = !!value.match(/[a-z]/);
        passwordHasUpperCase = !!value.match(/[A-Z]/);
        passwordHasDigit = !!value.match(/[0-9]/);
        passwordHasSpecialChar = !!value.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?`~]/);
        passwordLongEnough = value.length >= 8 && value.length <= 128;

        passwordValid = !!value && passwordHasLowerCase && passwordHasUpperCase
          && passwordHasDigit && passwordHasSpecialChar && passwordLongEnough;
        confirmPasswordValid = value === confirmPassword;
        this.setState({
          passwordValid,
          passwordHasLowerCase,
          passwordHasUpperCase,
          passwordHasDigit,
          passwordHasSpecialChar,
          passwordLongEnough,
          confirmPasswordValid,
          initialPassword: false,
        });
        break;
      case 'confirmPassword':
        confirmPasswordValid = value === password;
        this.setState({
          confirmPasswordValid,
          initialConfirmPassword: false,
        });
        break;
      default:
        break;
    }
  }

  checkboxOnClick(e) {
    const { checked } = e.target;
    this.setState({
      termsAndPrivacyAgreed: checked,
    });
  }

  handleSignUp() {
    const {
      firstName,
      lastName,
      email,
      companyName,
      password,
      firstNameValid,
      lastNameValid,
      emailValid,
      companyNameValid,
      passwordValid,
      promoCodes,
    } = this.state;
    const { history } = this.props;
    if (!emailValid || !companyNameValid
      || !passwordValid || !firstNameValid || !lastNameValid) {
      return false;
    }

    this.setState({
      isSigningUp: true,
    }, async () => {
      try {
        mxTrackEvent('Signing up', {
          email,
        });

        const updateDbReq = await fetch('/signUpApi/company', {
          headers: {
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            firstName,
            lastName,
            source: 'APPSUMO',
            email,
            password,
            companyName,
            promoCodes,
            service: 'APPSUMO',
          }),
        });

        const updateDbRes = await updateDbReq.json();
        if (updateDbReq.status !== 200) {
          throw new Error(updateDbRes.message);
        }

        mxTrackEvent('Sign up successful', {
          email,
        });

        this.setState({
          isSigningUp: false,
        });
        history.replace('/signup/successful');
      } catch (e) {
        let msg = e.message || 'Error signing up, please try again later';
        if (e.code === 'user_exists') {
          msg = 'The user already exists.';
        }

        if (e.code === 'invalid_password') {
          msg = 'Password is too weak. Should contain at least 6 characters.';
        }
        this.setState({
          isSigningUp: false,
          signUpFailure: true,
          signUpFailureMessage: msg,
        });
        mxTrackEvent('Sign up error', {
          email,
          err: e,
        });
        this.topRef.current.scrollIntoView();
      }
    });

    return true;
  }

  render() {
    const {
      initialFirstName,
      initialLastName,
      initialEmail,
      initialCompanyName,
      initialPassword,
      initialConfirmPassword,
      firstName,
      lastName,
      email,
      companyName,
      password,
      confirmPassword,
      firstNameValid,
      lastNameValid,
      emailValid,
      companyNameValid,
      passwordValid,
      confirmPasswordValid,
      isSigningUp,
      signUpFailure,
      signUpFailureMessage,
      termsAndPrivacyAgreed,
      disableCompanyNameField,
      disableEmailField,
      brandLogo,
      message,
      signUpText,
      promoCodes,
      isLoading,
      validatingCode,
      emailValidationMsg,
      passwordHasLowerCase,
      passwordHasUpperCase,
      passwordHasDigit,
      passwordHasSpecialChar,
      passwordLongEnough,
    } = this.state;

    if (isLoading) {
      return (
        <LoadingComponent />
      );
    }

    return (
      <div className="login-screen">
        <Grid fluid>
          <Row className="flex">
            <Col sm={3} xsHidden className="login-screen__background" />
            <Col xs={12} sm={8}>
              <div className={styles.logosContainer} ref={this.topRef}>
                <a href="https://interviewer.ai">
                  <img src={brandLogo} alt="Interviewer.ai logo" className={styles.brandLogo} />
                </a>
                <div className={styles.vertical} />
                <img src={AppsumoLogo} alt="Appsumo logo" className={styles.appsumoLogo} />
              </div>
              <div className="login-screen__content">
                <Grid fluid>
                  <Col sm={12}>
                    <h3>
                      {message}
                    </h3>
                    <div>
                      <ControlLabel>
                        AppSumo Code(s):
                        {' '}
                        {
                          validatingCode && <ClipLoadingComponent />
                        }
                      </ControlLabel>
                      <MultivalueInput
                        values={promoCodes}
                        onValuesChange={this.handlePromoCodesChange}
                        placeholder="Press Enter or Tab to add separate promo code"
                      />
                    </div>
                    <div className={styles.signUpFailureMessage}>
                      {
                        signUpFailure && <p>{signUpFailureMessage}</p>
                      }
                      {' '}
                      {signUpFailure && promoCodes.length === 0 && <a href="https://appsumo.com/products/interviewerai-deal/">Click here to purchase.</a>}
                    </div>
                    <div className={styles.signUpForm}>
                      <Row>
                        <Col sm={6} md={6} lg={6}>
                          <FormGroup
                            controlId="firstName"
                            validationState={initialFirstName || firstNameValid ? null : 'error'}
                          >
                            <ControlLabel>First Name *</ControlLabel>
                            <FormControl
                              type="text"
                              placeholder="The First"
                              name="firstName"
                              value={firstName}
                              onChange={this.handleInputChange}
                              className={firstName ? 'form-control-with-value' : ''}
                            />
                            <FormControl.Feedback />
                          </FormGroup>
                        </Col>

                        <Col sm={6} md={6} lg={6}>
                          <FormGroup
                            controlId="lastName"
                            validationState={initialLastName || lastNameValid ? null : 'error'}
                          >
                            <ControlLabel>Last Name *</ControlLabel>
                            <FormControl
                              type="text"
                              placeholder="The Last"
                              name="lastName"
                              value={lastName}
                              onChange={this.handleInputChange}
                              className={lastName ? 'form-control-with-value' : ''}
                            />
                            <FormControl.Feedback />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup
                        controlId="email"
                        validationState={initialEmail || emailValid ? null : 'error'}
                      >
                        <ControlLabel>Email *</ControlLabel>
                        <FormControl
                          type="text"
                          placeholder="Example@email.com"
                          name="email"
                          value={email}
                          onChange={this.handleInputChange}
                          className={email ? 'form-control-with-value' : ''}
                          disabled={disableEmailField}
                        />
                        <FormControl.Feedback />
                        <HelpBlock>
                          <small>
                            { emailValidationMsg }
                          </small>
                        </HelpBlock>
                      </FormGroup>

                      <FormGroup
                        controlId="companyName"
                        validationState={initialCompanyName || companyNameValid ? null : 'error'}
                      >
                        <ControlLabel>Company Name *</ControlLabel>
                        <FormControl
                          type="text"
                          placeholder="Company Name Pte Ltd"
                          name="companyName"
                          value={companyName}
                          onChange={this.handleInputChange}
                          className={companyName ? 'form-control-with-value' : ''}
                          disabled={disableCompanyNameField}
                        />
                        <FormControl.Feedback />
                      </FormGroup>

                      <FormGroup
                        controlId="password"
                        validationState={initialPassword || passwordValid ? null : 'error'}
                      >
                        <ControlLabel>Password *</ControlLabel>
                        <FormControl
                          type="password"
                          placeholder="Your password"
                          name="password"
                          value={password}
                          onChange={this.handleInputChange}
                          className={password ? 'form-control-with-value' : ''}
                        />
                        <FormControl.Feedback />
                        <HelpBlock>
                          <small>
                            <ul>
                              <li className={passwordLongEnough ? styles.greenText : ''}>
                                Min 8 - max 128 characters
                              </li>
                              <li className={passwordHasLowerCase ? styles.greenText : ''}>
                                At least 1 lowercase (a-z)
                              </li>
                              <li className={passwordHasUpperCase ? styles.greenText : ''}>
                                At least 1 uppercase (A-Z)
                              </li>
                              <li className={passwordHasDigit ? styles.greenText : ''}>
                                At least one digit (0-9)
                              </li>
                              <li className={passwordHasSpecialChar ? styles.greenText : ''}>
                                At least 1 special character (punctuation)
                              </li>
                            </ul>
                          </small>
                        </HelpBlock>
                      </FormGroup>

                      <FormGroup
                        controlId="confirmPassword"
                        validationState={initialConfirmPassword || confirmPasswordValid ? null : 'error'}
                      >
                        <ControlLabel>Confirm Password</ControlLabel>
                        <FormControl
                          type="password"
                          placeholder="Confirm your password"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={this.handleInputChange}
                          onKeyPress={this.handleKeyPress}
                          className={confirmPassword ? 'form-control-with-value' : ''}
                        />
                        <FormControl.Feedback />
                      </FormGroup>

                      <FormGroup>
                        <Checkbox
                          onClick={this.checkboxOnClick}
                        >
                          I agree with the&nbsp;
                          <a
                            href="https://interviewer.ai/terms/customer"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="login-screen__content--terms-link"
                          >
                            Interviewer.AI Customer Terms of Use
                          </a>
                        </Checkbox>
                      </FormGroup>

                      <Col sm={12} className="no-padding">
                        <Button
                          type="button"
                          className="btn btn-block btn-primary-custom login-screen__content--submit-btn"
                          onClick={this.handleSignUp}
                          disabled={promoCodes.length === 0 || !(emailValid
                            && passwordValid
                            && firstNameValid
                            && lastNameValid
                            && companyNameValid
                            && passwordValid
                            && confirmPasswordValid
                            && termsAndPrivacyAgreed) || isSigningUp}
                        >
                          {
                            isSigningUp ? (
                              <span>
                                Signing up...
                                <ClipLoadingComponent inverse />
                              </span>
                            ) : signUpText
                          }
                        </Button>
                      </Col>
                      <Col sm={12}>
                        <div className="login-screen__content--signup-btn text-center">
                          <a href="/login">
                            I already have an account
                          </a>
                        </div>
                      </Col>
                    </div>
                  </Col>
                </Grid>
              </div>
            </Col>
            <Col sm={3} xsHidden className="login-screen__background" />
          </Row>
        </Grid>
        <ToastContainer autoClose={2000} />
      </div>
    );
  }
}

AppSumoSignUp.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(AppSumoSignUp);
