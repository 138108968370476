// @flow
import React, { Fragment, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  Modal,
} from 'react-bootstrap';
import { css } from 'react-emotion';
import { ClipLoader } from 'react-spinners';
import { useTranslation } from 'react-i18next';
import CandidateStore from '../../stores/CandidateStore';
import CandidateActions from '../../actions/CandidateActions';
import ClipLoadingComponent from '../ClipLoadingComponent';
import { EMAIL_TEMPLATE_DATA, EMAIL_TYPE } from '../../utils/constants';
import Auth from '../../auth/Auth';
import { mxTrackEvent } from '../../utils/utils';

import styles from '../../css/EmailPreview.module.scss';

const RejectModalEmailPreview = ({
  interviewId,
  interviewName,
  companyName,
  companyLogoUrl,
  candidatesData,
  interviewLanguage,
  removeBranding,
}: {
  interviewId: string,
  interviewName: string,
  companyName: string,
  companyLogoUrl: string,
  interviewLanguage: string,
  candidatesData: Array<Object>,
  removeBranding: boolean,
}) => {
  const emailData = EMAIL_TEMPLATE_DATA[EMAIL_TYPE.REJECTION_EMAIL];
  const [rejectCandidateModalShow, setRejectCandidateModalShow] = useState(false);
  const [rejectingCandidate, setRejectingCandidate] = useState(false);
  const [rejectMessage, setRejectMessage] = useState('');
  const [requireEmail, setRequireEmail] = useState(false);

  const [content, setContent] = useState(emailData.body({ jobName: interviewName, companyName }));
  const [emailSubject, setEmailSubject] = useState(emailData.subject({ companyName }));
  const [loading, setLoading] = useState(false);

  const onChange = (storeData) => {
    setRejectCandidateModalShow(storeData.rejectCandidateModalShow);
    setRejectingCandidate(storeData.rejectingCandidate);
  };
  const { t } = useTranslation();

  useEffect(() => {
    const fetchTemplate = async () => {
      setLoading(true);
      try {
        const req = await fetch(`/api/email/template/${interviewId}?type=${EMAIL_TYPE.REJECTION_EMAIL}`, {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
          },
        });

        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          setEmailSubject(res.subject || emailSubject);
          setContent(res.message || content);
        }
      } catch (e) {
        console.log(e);
      } finally { // if there is error retrieving template, we should display the default message
        setLoading(false);
      }
    };

    CandidateStore.listen(onChange);
    fetchTemplate();

    return () => {
      CandidateStore.unlisten(onChange);
    };
  }, []);

  const handleRejectSubmit = async () => {
    const email = {
      rejectMessage,
      emailSubject,
      emailMessage: content,
    };
    const candidates = candidatesData.map(c => ({
      candidateid: c.candidateid,
      email: c.email,
      name: c.name,
    }));
    await CandidateActions.rejectCandidates(
      candidates,
      interviewId,
      interviewName,
      email,
      requireEmail,
      interviewLanguage,
      removeBranding,
    );

    setRejectMessage('');
  };

  const isCandidates = candidatesData.length > 1;

  return (
    <div className="reject-candidate-div">
      <Modal
        show={rejectCandidateModalShow}
        onHide={CandidateActions.closeRejectCandidateModal}
        className="reject-candidate-modal"
        bsSize="large"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title>
            {t('candidateDetails.reject')}
            {' '}
            {isCandidates && candidatesData.length.toString()}
            {' '}
            {t('candidateDetails.candidate')}
            {isCandidates && 's'}
          </Modal.Title>
        </Modal.Header>
        {
          loading ? (
            <Modal.Body>
              <ClipLoader color="#fd6144" className={css`margin: 20px auto`} />
            </Modal.Body>
          ) : (
            <Fragment>
              <Modal.Body>
                <Checkbox
                  checked={requireEmail}
                  onChange={() => setRequireEmail(!requireEmail)}
                >
                  <p style={{ position: 'absolute', bottom: '-5px' }}>
                    {t('candidateDetails.includeEmail')}
                  </p>
                </Checkbox>
                <p>
                  {t('candidateDetails.rejectCandidateInfo')}
                </p>
                <p>
                  <b>{candidatesData.map(c => c.name).join(', ')}</b>
                </p>
                {requireEmail && (
                  <div className={styles.emailPreviewContainer}>
                    <h4 style={{ textAlign: 'center' }}>{t('candidateDetails.emailPreview')}</h4>
                    <hr />
                    <Form>
                      <FormGroup controlId="emailSubject">
                        <ControlLabel>
                          {t('candidateDetails.emailSubject')}
                          :
                        </ControlLabel>
                        <FormControl
                          type="text"
                          name="emailSubject"
                          className="email-preview__content--editable email-preview__content--editable-small"
                          value={emailSubject}
                          onChange={e => setEmailSubject(e.target.value)}
                        />
                      </FormGroup>
                    </Form>
                    <div className="email-preview__content">
                      <img
                        src={companyLogoUrl}
                        alt="Company logo"
                        className="email-preview__content--logo img-responsive"
                      />
                      <h4>
                        {t('candidateDetails.dear')}
                        {' '}
                        [candidate-name],
                      </h4>
                      <textarea
                        className="email-preview__content--editable"
                        value={content}
                        onChange={e => setContent(e.target.value)}
                        onBlur={() => mxTrackEvent('Email content area changed')}
                        maxLength={1500}
                      />
                    </div>
                  </div>
                )}
                <hr />
                <FormGroup controlId="short-description">
                  <ControlLabel>{t('candidateDetails.rejectReason')}</ControlLabel>
                  <textarea
                    name="rejectMessage"
                    className="form-control"
                    maxLength="500"
                    rows="4"
                    placeholder="The rejection reason is only internal references only. It will not be added into the email sent to the candidate"
                    onChange={e => setRejectMessage(e.target.value)}
                    value={rejectMessage}
                  />
                </FormGroup>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={CandidateActions.closeRejectCandidateModal}>Close</Button>
                <Button
                  className="btn-primary-custom"
                  onClick={handleRejectSubmit}
                  disabled={rejectingCandidate}
                >
                  {
                    rejectingCandidate ? (
                      <span>
                        {t('candidateDetails.rejecting')}
                        <ClipLoadingComponent inverse />
                      </span>
                    ) : `Yes, reject candidate${isCandidates ? 's' : ''}${requireEmail ? ' and send email.' : '.'}`
                  }
                </Button>
              </Modal.Footer>
            </Fragment>
          )
        }
      </Modal>
    </div>
  );
};

export default RejectModalEmailPreview;
