import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CandidateStore from '../../stores/CandidateStore';
import ShareStore from '../../stores/ShareStore';
import styles from '../../css/CandidateDetail.module.scss';
import { ReactComponent as AttachmentImg } from '../../assets/Attachment.svg';

const AdditionalQuestion = (props) => {
  const { candidateEmail, parentComponent } = props;
  let displayData;
  if (parentComponent === 'candidatesDetails') {
    displayData = CandidateStore.getState().displayData; // eslint-disable-line
  } else {
    displayData = ShareStore.getState().displayData; // eslint-disable-line
  }

  const [additonalQuestions, setAddionalQuestion] = useState([]);

  const fetchCandidateAdditionalQuestion = () => {
    const candidateData = displayData.filter(
      candidate => candidate.email === candidateEmail,
    );
    if (!candidateData
      || candidateData.length === 0
      || !candidateData[0].metadata
      || !candidateData[0].metadata.additional_fields) {
      setAddionalQuestion([]);
      return [];
    }
    const { additional_fields } = candidateData[0].metadata; // eslint-disable-line

    const formattedQuestionAnswer = Object.keys(additional_fields).map(key => (
      { question: key, answer: additional_fields[key] }
    ));

    setAddionalQuestion(formattedQuestionAnswer);
    return; // eslint-disable-line
  };

  useEffect(() => {
    fetchCandidateAdditionalQuestion();
    return; // eslint-disable-line
  }, []);

  useEffect(() => {
    fetchCandidateAdditionalQuestion();
    return; // eslint-disable-line
  }, [candidateEmail]);

  const isValidUrl = (urlString) => {
    const urlPattern = new RegExp('^(https?:\\/\\/)?'
      + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'
      + '((\\d{1,3}\\.){3}\\d{1,3}))'
      + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'
      + '(\\?[;&a-z\\d%_.~+=-]*)?'
      + '(\\#[-a-z\\d_]*)?$', 'i');
    return !!urlPattern.test(urlString);
  };

  return (
    <React.Fragment>
      {
        additonalQuestions.length > 0 && (
          <div className={styles.addtionalSection}>
            <h4>Intro Questions</h4>
            {
              additonalQuestions.map((questiondata, index) => (
                <div className={styles.addtionalSectionQuestion}>
                  <div className={styles.questionNumber}>
                    {`${index + 1}`}
                  </div>
                  <div className={styles.additionalQuestion}>
                    <span>
                      {questiondata.question}
                    </span>
                    <p className={styles.addtionalSectionAnswers}>
                      {
                        isValidUrl(questiondata.answer) ? (
                          <a
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              cursor: 'pointer',
                              textDecoration: 'none',
                              color: '#6196FE',
                              fontWeight: '500',
                            }}
                            href={questiondata.answer}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <AttachmentImg height="15" width="15" stroke="#7A91AB" />
                            <span style={{ marginLeft: '.5rem' }}>Attachment</span>
                          </a>
                        ) : (
                          questiondata.answer
                        )
                      }
                    </p>
                  </div>
                </div>
              ))
            }
          </div>
        )
      }
    </React.Fragment>
  );
};

AdditionalQuestion.propTypes = {
  candidateEmail: PropTypes.string.isRequired,
  parentComponent: PropTypes.string.isRequired,
};

export default AdditionalQuestion;
