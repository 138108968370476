import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import NavigationBar from '../../components/NavigationBar/NavigationBar';
import PrivateRoute from '../../components/PrivateRoute';
import NotFoundScreen from '../NotFoundScreen';
import TeamMembers from './TeamMembers';
import CopyrightScreen from '../CopyrightScreen';

import '../../css/Settings.css';

const OrganizationMain = () => (
  <div className="organization">
    <header className="App-header">
      <NavigationBar />
    </header>
    <Switch>
      <PrivateRoute
        path="/organization/members"
        component={TeamMembers}
      />
      <Route component={NotFoundScreen} />
    </Switch>
    <ToastContainer autoClose={2000} />
    <CopyrightScreen />
  </div>
);

export default OrganizationMain;
