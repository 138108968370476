import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  OverlayTrigger,
  Tooltip,
  FormGroup,
  FormControl,
  HelpBlock,
  Button,
} from 'react-bootstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import ReactStars from 'react-rating-stars-component';
import ClipLoadingComponent from '../ClipLoadingComponent';
import { successToast, alertToast } from '../Toast';
import {
  ASSESSMENT_REPORT_PARAMETERS,
} from '../../utils/constants';
import Auth from '../../auth/Auth';
import { isCoachingUser, mxTrackEvent, fetchFeedBackParamaters } from '../../utils/utils';
import CandidateActions from '../../actions/CandidateActions';
import styles from '../../css/CandidateAssessmentForm.module.scss';
import { ReactComponent as StarIcon } from '../../assets/StarIcon.svg';
import InfoTooltip from '../InfoTooltip';
import OrganizationStore from '../../stores/OrganizationStore';
import LoadingComponent from '../LoadingComponent';

const RatingScale = ({ score, text, tooltip }) => (
  <OverlayTrigger
    placement="right"
    overlay={<Tooltip id={score}>{tooltip}</Tooltip>}
    trigger={['hover', 'focus']}
  >
    <span>{`${score} - ${text}`}</span>
  </OverlayTrigger>
);

const CandidateAssessmentForm = ({
  interviewId,
  candidateId,
  candidateName,
  candidatePicUrl,
  candidateEmail,
  // isModal,
  workmapSkills,
  currentUserId,
}) => {
  const [assessmentFormSkills, setAssessmentFormSkills] = useState(
    ASSESSMENT_REPORT_PARAMETERS.COACHES,
  );

  const [isFetchingAssessment, setIsFetchingAssessment] = useState(true);
  const [fetchAssessmentFailed, setFetchAssessmentFailed] = useState(false);
  const [fetchAssessmentFailedMessage, setFetchAssessmentFailedMessage] = useState('');
  const [candidateScore, setCandidateScore] = useState({});

  const [otherFeedback, setOtherFeedback] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false); // eslint-disable-line
  const [isSending, setIsSending] = useState(false); // eslint-disable-line
  const [teamFeedback, setTeamFeedback] = useState({});
  const [teamMemberFeedback, setTeamMemberFeedback] = useState({});
  const [selectedUserScore, setSelectedUserScore] = useState({});
  const [averageRating, setAverageRating] = useState(0);
  const [feedbackParamType, setFeedbackParamType] = useState('workmapFeedback');
  const { t } = useTranslation();

  async function fetchManualAssessment() {
    setIsFetchingAssessment(true);
    try {
      const req = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'get',
        },
      );

      if (req.status >= 200 && req.status < 300) {
        const res = await req.json();
        const {
          ratings, ratingParameters, feedbackUsers, averageRating, candidateTeamFeedback // eslint-disable-line
        } = res;

        candidateTeamFeedback.map((feedback) => { // eslint-disable-line
          if (feedback.rating_draft) {
            const draft = JSON.parse(feedback.rating_draft);
            const draft_keys = Object.keys(draft).filter(item => item !== 'otherFeedback'); // eslint-disable-line
            const newCandidateScore = { ...candidateScore };
            draft_keys.map((item) => { // eslint-disable-line
              newCandidateScore[item] = draft[item];
              setCandidateScore(newCandidateScore);
            });
            setOtherFeedback(draft.otherFeedback);
          }
        });

        // const { otherFeedback: resOtherFeedback, ...manualAssessment } = ratings;
        const { otherFeedback: resOtherFeedback } = ratings; // eslint-disable-line

        const filteredFeedbackUsers = feedbackUsers; // eslint-disable-line
        // const updatedScore = Object.assign(
        //   {},
        //   candidateScore,
        //   manualAssessment,
        // );
        if (ratingParameters.length > 0) {
          setAssessmentFormSkills(ratingParameters);
        }

        setFetchAssessmentFailed(false);
        // setCandidateScore(updatedScore);
        // setOtherFeedback(resOtherFeedback || '');
        setTeamFeedback(filteredFeedbackUsers || []);
        setAverageRating(averageRating);
        if (filteredFeedbackUsers.length > 0) {
          setSelectedUserScore(filteredFeedbackUsers[0]);
          setTeamMemberFeedback([filteredFeedbackUsers[0]]);
        }
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      setFetchAssessmentFailed(true);
      setFetchAssessmentFailedMessage(
        'Assessment for the candidate could not be fetched. :( Try again later',
      );
    } finally {
      setIsFetchingAssessment(false);
    }
  }

  useEffect(() => {
    if (!isCoachingUser()) {
      // for recruiter, we need to add workmap related skills as well
      setAssessmentFormSkills(
        workmapSkills.concat(ASSESSMENT_REPORT_PARAMETERS.HIRING_MANAGERS),
      );
    }
    const { feedbackParametersType } = OrganizationStore.getState();
    setFeedbackParamType(feedbackParametersType);
    fetchManualAssessment();
  }, []);

  useEffect(() => {
    if (feedbackParamType === 'aiFeedback') {
      const feedbackParameters = fetchFeedBackParamaters();
      setAssessmentFormSkills(feedbackParameters);
    }
  }, [feedbackParamType]);

  const savefeedbackDraft = async (draftScores = {}, remarks = otherFeedback) => {
    const userId = await Auth.getProfileInfo('userId');
    const draftFeedback = JSON.stringify({
      otherFeedback: remarks,
      ...draftScores,
    }); // { ...otherFeedback , ...candidateScore};
    const req = await fetch(
      `/api/candidate/savedraft/${candidateId}/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          interviewId,
          draftFeedback,
        }),
      },
    );
    if (req.status >= 200 && req.status < 300) {
      const res = await req.json();
      console.log(res);
    } else {
      throw new Error(req.statusText);
    }
  };

  const selectScore = (category, score) => {
    const newCandidateScore = { ...candidateScore };
    newCandidateScore[category] = score;
    setCandidateScore(newCandidateScore);
    savefeedbackDraft(newCandidateScore);
    // submitAssessment(true)
  };

  const assesmentFeedbackTextbox = {
    height: '150px',
    borderRadius: '15px',
  };

  const showFeedback = (userDetail, feedbackNumber) => {
    const feedback = teamFeedback.filter(user => (
      (user.hiring_manager_email === userDetail.toString()
        || user.user.userId === userDetail) && user.number === feedbackNumber
    ));
    setTeamMemberFeedback(feedback);
    setSelectedUserScore(feedback[0]);
  };

  const submitAssessment = async (downloadReport, sendReport = false) => {
    setOtherFeedback('');
    setCandidateScore({});
    mxTrackEvent('Manual candidate assessment button clicked');
    if (sendReport) {
      setIsSending(true);
    } else if (downloadReport) {
      setIsDownloading(true);
    } else {
      setIsSubmitting(true);
    }

    try {
      // call API to update candidate assessment
      const req = await fetch(
        `/api/interview/${interviewId}/candidate/${candidateId}/assessment`,
        {
          headers: {
            Authorization: `Bearer ${Auth.getIdToken()}`,
            'Content-Type': 'application/json',
          },
          method: 'post',
          body: JSON.stringify({
            candidateAssessment: candidateScore,
            otherFeedback,
            candidateName,
            candidatePicUrl,
            downloadReport,
            sendReport,
            candidateEmail,
          }),
        },
      );

      if (req.status >= 200 && req.status < 300) {
        const contentType = req.headers.get('content-type');
        if (
          downloadReport
          && contentType
          && contentType.indexOf('application/pdf') !== -1
        ) {
          const resp = await req.blob();

          const link = document.createElement('a');
          // create a blobURI pointing to our Blob
          link.href = URL.createObjectURL(resp);
          link.download = `${candidateName}.pdf`;
          // some browser needs the anchor to be in the doc
          document.body.append(link);
          link.click();
          link.remove();
        } else if (sendReport) {
          successToast(`Report sent to ${candidateEmail}`);
        } else {
          successToast('Assessment updated successfully.');
        }
        mxTrackEvent('Manual candidate assessment updated successfully.');
        CandidateActions.candidateAssessmentUpdated(
          candidateId,
          candidateScore,
        );
      } else {
        const res = await req.json();
        throw new Error(res.error);
      }
    } catch (e) {
      console.log(e);
      alertToast(e.toString());
      mxTrackEvent('Manual candidate assessment failed.', {
        error: e.toString(),
      });
    } finally {
      setIsDownloading(false);
      setIsSending(false);
      setIsSubmitting(false);
      fetchManualAssessment();
    }
  };

  if (isFetchingAssessment) {
    return (
      // <RingLoader
      //   color="#fd6144"
      //   className={css`
      //     margin: 20px auto;
      //   `}
      //   size={90}
      // />
      <LoadingComponent />
    );
  }

  if (fetchAssessmentFailed) {
    return <div className="red-font">{fetchAssessmentFailedMessage}</div>;
  }


  const getFeedbackUserName = (feedback) => {
    if (!feedback) {
      return {};
    }

    if (!feedback.user) {
      return {};
    }

    if (feedback.hiring_manager_email && Object.keys(feedback.user).length === 0) {
      return feedback.hiring_manager_email;
    }
    const firstname = feedback.user.firstName;
    let lastName;
    if (feedback.user.lastName) {
      lastName = feedback.user.lastName.charAt(0);
    }
    return `${firstname} ${lastName}.`;
  };

  const getUserNameInitial = (name) => {
    if (!name) {
      return {};
    }
    const nameArray = name.split(' ');
    // In case we don't have name for hiring manager (share shortlist /Share this profile)
    if (nameArray.length === 1) {
      return nameArray[0].charAt(0);
    }

    if (nameArray.length > 1) {
      return `${nameArray[0].charAt(0)}${nameArray[1].charAt(0)}`;
    }

    return {};
  };

  const responseDisabledStarts = inputRating => (
    <div className={styles.responseStars}>
      {
        [1, 2, 3, 4, 5].map((item, index) => (
          <StarIcon width="22" height="21" fill={index < inputRating ? '#6196FE' : '#E4E7EF'} />
        ))
      }
    </div>
  );


  return (
    <div className={styles.feedbackSectionParent}>
      <div className={styles.feedbackSection}>
        <div>
          <h4>{t('candidateDetails.yourFeedback')}</h4>
        </div>
        {
          (currentUserId && teamFeedback.length !== 0)
            && teamFeedback.some(item => item.userid === currentUserId)
            ? (
              <div className={styles.feedbackSubmittedText}>
                Great! We have recorded your feedback.
              </div>
            )
            : (
              <>
                <div className={styles.feedbackInputconatiner}>
                  <div className={styles.feedbackRatingContainer}>
                    {assessmentFormSkills.map(i => (
                      <div className={styles.rating}>
                        <label>{i}</label> {/* eslint-disable-line */}
                        <ReactStars
                          count={5}
                          value={candidateScore[i]}
                          size={20}
                          edit
                          color="#E4E7EF"
                          activeColor="#6196FE"
                          onChange={e => selectScore(i, e)}
                          classNames={styles.starModule}
                        />
                      </div>
                    ))}
                  </div>


                  <div className={styles.feedbackNoteContainer}>
                    <FormGroup controlId="other-feedback">
                      <FormControl
                        componentClass="textarea"
                        placeholder="eg: Used too many filler words"
                        maxLength="5000"
                        value={otherFeedback}
                        onChange={(e) => {
                          setOtherFeedback(e.target.value);
                          savefeedbackDraft(candidateScore, e.target.value);
                        }}
                        style={assesmentFeedbackTextbox}
                        className={`App-content-video-div--assessment-textarea ${styles.candidate_feedback}`}
                      />
                      <HelpBlock>{t('candidateDetails.feedbackInstruction1')}</HelpBlock>
                    </FormGroup>
                  </div>
                  <div className={styles.feedbackSubmitButton}>
                    <Button
                      className={`btn ${styles.submit_assesment_button}`}
                      onClick={() => submitAssessment(false)}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <span>
                          {t('submitting')}
                          <ClipLoadingComponent inverse />
                        </span>
                      ) : (
                        (t('submit'))
                      )}
                    </Button>
                  </div>
                </div>
              </>
            )
        }
        <hr />
        {
          teamFeedback && teamFeedback.length > 0 && (

            <div className={styles.ratingResponseSection}>
              <div className={styles.overallRatingSection}>
                <div className={styles.overallRating}>
                  {
                    // max 5 stars
                    [1, 2, 3, 4, 5].map((value, index) => (
                      <StarIcon width="22" height="21" fill={index < averageRating ? '#6196FE' : '#E4E7EF'} />
                    ))
                  }

                  <p className="f20-500-bold-dark">{averageRating}</p>
                </div>
                <label>Overall Feedback</label> {/* eslint-disable-line */}
              </div>
              <div className={styles.userResponseSection}>
                {
                  teamFeedback && teamFeedback.map(feedback => (
                    <React.Fragment>

                      <div
                        role="button"
                        tabIndex={0}
                        onKeyDown={() => { }}
                        className={
                          // eslint-disable-next-line max-len
                          classnames(
                            // eslint-disable-next-line max-len
                            selectedUserScore.number === feedback.number ? styles.respondedUsersActive : styles.respondedUsers,
                          )}
                        onClick={() => {
                          showFeedback(
                            feedback.hiring_manager_email || feedback.user.userId,
                            feedback.number,
                          );
                        }}
                      >
                        <div className={styles.userInitial}>
                          {getUserNameInitial(getFeedbackUserName(feedback))}
                        </div>
                        <InfoTooltip placement="top" tooltipContent={getFeedbackUserName(feedback)}>

                          <div>
                            <p className={styles.textWrap}>
                              {getFeedbackUserName(feedback)}
                            </p>
                            {
                              !feedback.hiring_manager_email ? (
                                <p>
                                  {feedback.user.role}
                                </p>
                              ) : (
                                <p>
                                  {t('candidateDetails.hiringManager')}
                                </p>
                              )
                            }

                          </div>
                        </InfoTooltip>
                      </div>

                    </React.Fragment>

                  ))
                }
              </div>
            </div>
          )
        }

        {
          teamMemberFeedback && Object.keys(teamMemberFeedback).length > 0 && (
            <div className={classnames(styles.feedbackInputconatiner, styles.mt30)}>
              <div className={styles.feedbackRatingContainer}>

                {Object.keys(JSON.parse(selectedUserScore.rating)).map(i => (
                  <div className={styles.rating}>
                    {i && i !== 'otherFeedback' && (
                      <React.Fragment>
                        <label>{i}</label> {/* eslint-disable-line */}
                        {
                          responseDisabledStarts(JSON.parse(selectedUserScore.rating)[i])
                        }
                      </React.Fragment>
                    )
                    }
                  </div>
                ))}
              </div>

              <div className={styles.feedbackNoteContainer}>
                <span>
                  {t('candidateDetails.notes')}
                  :
                </span>
                <p>
                  {JSON.parse(selectedUserScore.rating).otherFeedback ? JSON.parse(selectedUserScore.rating).otherFeedback : ''}
                  {' '}
                </p>
                <span>
                  {t('candidateDetails.status')}
                  :
                </span>
                <p>
                  {selectedUserScore.status ? selectedUserScore.status : ''}
                </p>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

CandidateAssessmentForm.propTypes = {
  interviewId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  candidateName: PropTypes.string.isRequired,
  candidatePicUrl: PropTypes.string.isRequired,
  candidateEmail: PropTypes.string.isRequired,
  currentUserId: PropTypes.string.isRequired,
  isModal: PropTypes.bool, // eslint-disable-line
  workmapSkills: PropTypes.arrayOf(PropTypes.string),
};

CandidateAssessmentForm.defaultProps = {
  isModal: false,
  workmapSkills: [],
};

RatingScale.propTypes = {
  score: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
};

export default CandidateAssessmentForm;
