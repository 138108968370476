export default {
  candidateDetails: {
    idealAnswer: 'Réponse idéale',
    idealAnsNotAvail: 'Réponse idéale non disponible',
    idVerifyTrueText: 'Verifikasi ID diverifikasi',
    idVerifyFalseText: 'Verifikasi ID tidak diverifikasi',
    audioDetectedTrue: 'Audio terdeteksi',
    audioDetectedFalse: 'Tidak ada audio yang terdeteksi',
    browserToggleTrue: 'Pengalih browser terdeteksi',
    browserToggleFalse: 'No browser toggle detected',
    pastedAnsTrue: 'Jawaban yang ditempel terdeteksi',
    pastedAnsFalse: 'Tidak ada jawaban yang ditempel terdeteksi',
    thirdPartyVoiceTrue: 'Suara pihak ketiga terdeteksi',
    thirdPartyVoiceFalse: 'Tidak ada suara pihak ketiga yang terdeteksi',
    eyeContactTrue: 'Kontak mata terdeteksi',
    eyeContactFalse: 'Tidak ada kontak mata yang terdeteksi',
    suspiciousObjectTrue: 'Objek mencurigakan terdeteksi',
    suspiciousObjectFalse: 'Tidak ada objek mencurigakan yang terdeteksi',
    shareWith: 'Dibagi dengan',
    all: 'Semua',
    candidates: 'Kandidat',
    searchCandidates: 'Cari Kandidat',
    scoreSettings: 'Pengaturan Skor',
    resumeScore: 'Lanjutkan Skor',
    workmapScore: 'Penilaian Profil',
    aiVideoScore: 'Penilaian Video AI',
    professionalism: 'Profesionalisme',
    positiveAttitude: 'Sikap positif',
    communication: 'Komunikasi',
    sociability: 'Keramahan',
    recommended: 'Direkomendasikan',
    reset: 'Mengatur ulang',
    saving: 'Penghematan...',
    save: 'Menyimpan',
    score: 'Skor',
    feedback: 'Masukan',
    new: 'Baru',
    unlockCandidate: 'Buka kunci Kandidat ini',
    scoreSettingsInfo:
      'Ini memungkinkan Anda untuk mengontrol bobot Resume, WorkMap, dan AI Video Score. Pilih bobot yang Anda inginkan dan harus berjumlah 100.',
    deleteCandidateInfo:
      'Kandidat telah menghapus profilnya, sehingga Anda tidak dapat lagi mengakses informasinya.',
    overallScore: 'Penilaian keseluruhan',
    overall: 'Keseluruhan',
    hired: 'Dipekerjakan',
    hire: 'Mempekerjakan saya',
    appliedDate: 'Tanggal Diterapkan',
    changeSchedule: 'Ubah Jadwal',
    scheduleInterview: 'Schedule Interview',
    downloadReport: 'Unduh Laporan',
    shareProfile: 'Bagikan Profil ini',
    sendReport: 'Kirim Laporan ke Kandidat',
    candidateShortlisted: 'Calon terpilih',
    shortlistCandidate: 'Kandidat daftar pendek',
    candidateKived: 'Kandidat kived',
    kivCandidate: 'calon KIV',
    keepInView: 'Perhatikan',
    keptInView: 'Tetap terlihat',
    candidateRejected: 'Kandidat ditolak',
    rejectCandidate: 'Menolak calon',
    rejected: 'Ditolak',
    reject: 'Menolak',
    inviteActivity: 'Ajak Aktivitasy',
    interviewScheduledOn: 'Wawancara dijadwalkan',
    activity: 'Aktivitas',
    contactedUser: 'Menghubungi Pengguna di',
    noQuestionsAttempted: 'Tidak ada pertanyaan yang dicoba',
    videoTranscript: 'Transkrip Video',
    candidateSummary: 'Ringkasan Kandidat',
    insightsDisclaimer:
      'Penafian: Silakan gunakan wawasan sesuai kebijaksanaan Anda untuk memilih kandidat untuk wawancara atau untuk melakukan panggilan perekrutan.',
    skill: 'Keahlian',
    education: 'Pendidikan',
    experience: 'Pengalaman',
    shareShortlist: 'Bagikan Daftar Pendek',
    inviteSent: 'Undangan terkirim',
    interviewVideo: 'Transkrip Video',
    location: 'Lokasi',
    email: 'Surel',
    phone: 'Telepon',
    linkedIn: 'Linkedin',
    includeEmail: 'Sertakan email',
    rejectCandidateInfo:
      'Harap konfirmasi jika Anda ingin menolak kandidat berikut:',
    emailSubject: 'Subjek Email',
    dear: 'Sayang',
    rejectReason: 'Menolak Alasan',
    rejecting: 'Menolak',
    emailPreview: 'Pratinjau Email',
    selectEventType: 'Pilih Jenis Acara Calendly',
    calendlyEventCreated:
      'Pastikan Anda memiliki acara Calendly yang dibuat atau dibuat di sini',
    noCalendlyEventFound: 'Tidak ada Acara Calendly yang ditemukan',
    sender: 'Pengirim',
    editEmailSubject: 'Edit Subjek Email',
    checkEditEmailContent: 'Periksa dan edit Konten Email',
    lookForwardMeetingYou: 'kami berharap dapat bertemu dengan Anda segera',
    sending: 'Mengirim...',
    scheduleAndSend: 'Jadwalkan dan kirim',
    yesRejectCandidate: 'Ya, tolak calon',
    andSendEmail: 'dan kirim email',
    yourFeedback: 'Tanggapan Anda',
    feedbackInstruction1: 'Maksimum karakter: 5000',
    overallFeedback: 'Umpan Balik Keseluruhan',
    hiringManager: 'Mempekerjakan manajer',
    notes: 'Catatan',
    status: 'Status',
    noSummary: 'Tidak ada Ringkasan',
    videoScoreTooltip:
      'Kerangka kerja milik Interviewer.AI memberikan peringkat untuk kata-kata yang diucapkan, suara, serta ekspresi wajah kandidat selama wawancara video. Kandidat diberi skor dari seratus untuk setiap parameter penilaian. Skor ini digabungkan menjadi Penilaian keseluruhan untuk wawancara video mereka. Skor yang lebih tinggi di bar akan menunjukkan bahwa kandidat bernasib lebih baik berdasarkan peringkat objektif dari perilaku mereka pada wawancara video dibandingkan dengan wawancara video yang diambil di platform serta kumpulan data publik.',
    professionalismTooltip:
      'Diukur dari perilaku pelamar (formal/informal) dan melihat bagaimana kandidat menampilkan dirinya dalam video. Ini juga mengukur di mana kontak mata dipertahankan selama respons video, menangkap jika ada kegelisahan sama sekali. Ya, berpakaian formal akan menguntungkan.',
    positiveAttitudeTooltip:
      'Sikap, seperti namanya, mencari sentimen bersama dengan kombinasi energi dan emosi. Model ini melihat kombinasi metrik dari komunikasi dan Sosiabilitas (misalnya percaya diri, terdorong, positif)',
    communicationTooltip:
      'Ini terutama mengukur kejelasan ucapan pelamar. Ini juga melihat kelancaran komunikasi verbal dan modulasi ucapan. Berbicara dengan jelas dan dengan kecepatan yang tepat akan menguntungkan di sini. Variasi nada bicara pada waktu yang tepat sepanjang video akan bermanfaat.',
    sociabilityTooltip:
      'Model AI ini lebih fokus untuk menilai sentimen pelamar, menggunakan cara kandidat berkomunikasi dan menunjukkan keterampilan dan kebijaksanaan interpersonal yang positif. Biasanya mencari kata-kata sentimen positif dan tingkat energi pelamar',
  },
};
