import 'bootstrap/dist/css/bootstrap.min.css';
import React, { lazy } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import './css/index.css';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import App from './App';
import AppAIA from './AppAIA';
import clientConfig from './config/config';
import WaitingComponent from './components/WaitingComponent';
import PrivateRoute from './components/PrivateRoute';
import CallbackComponent from './components/CallbackComponent';
import CheckInvitation from './pages/Organization/CheckInvitation';
import Notifications from './pages/Notifications/Notifications';
import OrganizationMain from './pages/Organization/OrganizationMain';
import AppSumoSignUp from './pages/AppSumoSignUp';
import TermsAcceptedScreen from './pages/TermsAcceptedScreen';
import ErrorComponent from './pages/ErrorHandling/ErrorBoundaryComponent';
import TermsAndConditions from './pages/TermsAndConditions';
// import clientConfig from "./config/config";


const LoginScreen = lazy(() => import('./pages/LoginScreen'));
const ResetPasswordScreen = lazy(() => import('./pages/ResetPasswordScreen'));
const SignUpScreen = lazy(() => import('./pages/SignUpScreen'));
const SignUpSuccessfulScreen = lazy(() => import('./pages/SignUpSuccessfulScreen'));
const NotFoundScreen = lazy(() => import('./pages/NotFoundScreen'));
const LogoutComponent = lazy(() => import('./components/LogoutComponent'));
const InterviewMain = lazy(() => import('./pages/Interviews/InterviewMain'));
const SettingsMain = lazy(() => import('./pages/Settings/SettingsMain'));
const NewShareScreen = lazy(() => import('./pages/NewShareScreen'));
const ShareCandidatesScreen = lazy(() => import('./pages/Candidates/ShareCandidatesScreen/ShareCandidatesScreen'));
const IndividualCandidateScreen = lazy(() => import('./pages/IndividualCandidateScreen'));
const InsightsMain = lazy(() => import('./pages/Insights/InsightsScreen'));
const OverviewMain = lazy(() => import('./pages/Overview/OverviewMain'));
const TalentPoolScreen = lazy(() => import('./pages/TalentPool/TalentPool'));
const NotificationSettings = lazy(() => import('./pages/NotificationSettings'));
// const MaintenancePage = lazy(() => import('./pages/Maintenance'));

const stripePromise = loadStripe(clientConfig.default.STRIPE_PUBLISHABLE_KEY);


Mixpanel.init(clientConfig.default.MIXPANEL_TOKEN);
TagManager.initialize({
  gtmId: clientConfig.default.GTM.id,
  auth: clientConfig.default.GTM.auth,
  preview: clientConfig.default.GTM.env,
});

if (clientConfig.default.SENTRY_DSN) {
  console.log('Sentry Initialised');
  Sentry.init({
    dsn: clientConfig.default.SENTRY_DSN,
    environment: process.env.NODE_ENV,
  });
}

ReactDOM.render((
  <Elements stripe={stripePromise}>
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorComponent}
      >
        <Switch>
          {/* <Route exact path="/*" component={WaitingComponent(MaintenancePage)} /> */}
          <Route exact path="/" component={WaitingComponent(LoginScreen)} />
          <Route exact path="/login" component={WaitingComponent(LoginScreen)} />
          <Route exact path="/reset" component={WaitingComponent(ResetPasswordScreen)} />
          <Route exact path="/notification-settings" component={WaitingComponent(NotificationSettings)} />
          <Route exact path="/termsandconditions" component={WaitingComponent(TermsAcceptedScreen)} />
          <Route exact path="/signup/successful" component={WaitingComponent(SignUpSuccessfulScreen)} />
          <Route exact path="/signup/:planParam?" component={WaitingComponent(SignUpScreen)} />
          <Route exact path="/appsumo/signup" component={WaitingComponent(AppSumoSignUp)} />
          <Route exact path="/accept-invite" component={WaitingComponent(CheckInvitation)} />
          <Route path="/logout" component={WaitingComponent(LogoutComponent)} />
          <Route path="/callback" component={CallbackComponent} />
          <Route path="/shortlist/interview/:interviewId" component={WaitingComponent(NewShareScreen)} />
          <Route path="/share/:listId" component={WaitingComponent(ShareCandidatesScreen)} />
          <Route path="/settings" component={WaitingComponent(SettingsMain)} />
          <PrivateRoute path="/candidate/:candidateId" component={WaitingComponent(IndividualCandidateScreen)} />
          <PrivateRoute path="/interview/:interviewId/:candidateId/candidates" component={WaitingComponent(App)} />
          <Route path="/:interviewId/:candidateId/candidates" component={WaitingComponent(AppAIA)} />
          <PrivateRoute path="/interviews" component={WaitingComponent(InterviewMain)} />
          <PrivateRoute path="/insights" component={WaitingComponent(InsightsMain)} />
          <PrivateRoute path="/overview" component={WaitingComponent(OverviewMain)} />
          <PrivateRoute path="/notifications" component={WaitingComponent(Notifications)} />
          <PrivateRoute path="/organization" component={WaitingComponent(OrganizationMain)} />
          <PrivateRoute path="/talent-pool" component={WaitingComponent(TalentPoolScreen)} />
          <Route path="/termsAndCondition" component={WaitingComponent(TermsAndConditions)} />
          <Route component={WaitingComponent(NotFoundScreen)} />
        </Switch>
      </ErrorBoundary>
    </BrowserRouter>
  </Elements>
), document.getElementById('root'));
