import React, { Fragment } from 'react';
import {
  Col,
  FormGroup,
  Checkbox,
  ControlLabel,
  Grid,
  Row,
  Button,
  Modal,

} from 'react-bootstrap';
import orderBy from 'lodash/orderBy';
import Flatpickr from 'react-flatpickr';
import Slider from 'rc-slider';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import { isCoachingUser } from '../utils/utils';
import { ReactComponent as FilterIcon } from '../assets/FilterIcon.svg';
import CandidateActions from '../actions/CandidateActions';
import CandidateStore from '../stores/CandidateStore';
import styles from '../css/ScoreFilter.module.scss';
import MultivalueInput from './MultivalueInput';
import calendarIcon from '../assets/calendarIcon.svg';
import InfoTooltip from './InfoTooltip';


class ScoreFilter extends React.Component {
  static stopDropdownAutoBlur(e) {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  }

  constructor(props) {
    super(props);
    this.state = CandidateStore.getState();
    this.state.startDateError = null;
    this.state.endDateError = null;
    this.state.isFormValid = false;
    this.state.interviewId = null;
    this.state.skillList = [];
    this.state.workmap_enabled = false;
    this.state.importedIn = 'candidateListing';
    this.onChange = this.onChange.bind(this);
    this.handleToggleFilterModal = this.handleToggleFilterModal.bind(this);
    this.handleApplyFilterBtnOnClick = this.handleApplyFilterBtnOnClick.bind(this);
  }

  componentDidMount() {
    CandidateStore.listen(this.onChange);
    // fetchFilter()

    this.setState({
      show: false,
      interviewId: this.props.interviewId, // eslint-disable-line
      importedIn: this.props.importedIn, // eslint-disable-line
      workmap_enabled: this.props.interviewDetail // eslint-disable-line
        ? this.props.interviewDetail.workmap_enabled : null, // eslint-disable-line
    });


    if (this.props.interviewDetail && this.props.interviewDetail.workmap_enabled) { // eslint-disable-line
      CandidateActions.fetchFilter(this.props.interviewId); // eslint-disable-line
    }
  }

  componentWillUnmount() {
    CandidateStore.unlisten(this.onChange);
  }

  onChange(state) {
    this.setState(state);
  }

  handleToggleFilterModal() {
    const { show } = this.state;
    this.setState({
      show: !show,
    });
  }

  handleApplyFilterBtnOnClick() {
    this.handleToggleFilterModal();
    this.validateFilters(this.state.filterData); // eslint-disable-line
    CandidateActions.applyFilter();
  }


  validateFilters(e) { // eslint-disable-line
    // all have values or none have values
    // start should be greater than end values

    // if () {

    // }

  }

  handleSelectQuestion(option, value) { // eslint-disable-line
    console.log('Received option and value', option, value);
  }


  render() {
    const {
      candidateFilters,
      // filterLocationOptions,
      filterAdditionalFormFields,
      filterKeywords,
      show,
      // workmapScoreSliderValue,
      // resumeScoreSliderValue,
      // videoScoreSliderValue,
      // skillList,
      resumeScoreFilterParameterList,
      videoScoreFilterParameterList,
      workmapScoreParameterList,
      workmap_enabled, // eslint-disable-line
      // resume_mandatory,
      importedIn,
    } = this.state;
    const { t } = this.props; // eslint-disable-line
    return (
      <Fragment>
        <InfoTooltip
          placement="top"
          tooltipContent={
            isCoachingUser()
              ? t('candidateListing.filterApplicants')
              : t('candidateListing.filterCandidates')
          }
        >
          <button
            type="button"
            className={`${importedIn === 'candidateDetail' ? styles.filterButtonMobile : styles.filterButton}`}
            onClick={this.handleToggleFilterModal}
          >
            {/* <div> */}
            <FilterIcon />
            {/* Filter by */}
            {/* <Glyphicon glyph="filter" /> */}
            {/* </div> */}
          </button>
        </InfoTooltip>
        <Modal
          show={show}
          onHide={this.handleToggleFilterModal}
          backdrop={false}
          dialogClassName="modal-90w"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {
                isCoachingUser()
                  ? t('candidateListing.filterApplicants')
                  : t('candidateListing.filterCandidates')
              }
            </Modal.Title>
          </Modal.Header>
          <Modal.Body
            className="filter-modal-content"
          >
            <Grid fluid>

              <Row>
                <Col>
                  {
                    orderBy(filterAdditionalFormFields, ['name'])
                      .map(field => (
                        field.options.length > 0 && (
                          <Col xs={12} key={field.name}>
                            <FormGroup
                              controlId="formControlsSelect"
                              className="App-content-candidates-list-div--filter-params-checkbox-group"
                            >
                              <ControlLabel
                                className="App-content-candidates-list-div--header"
                                capitalize
                              >
                                {field.name}
                              </ControlLabel>


                              {
                                field.options.map(option => (
                                  <Checkbox
                                    className="App-content-candidates-list-div--checkbox"
                                    value={option}
                                    checked={
                                      candidateFilters
                                        .filterAdditionalFormValues[field.name]
                                      && candidateFilters
                                        .filterAdditionalFormValues[field.name]
                                        .includes(option)}
                                    onChange={() => {
                                      CandidateActions
                                        .changeAdditionalFormFieldFilter(field.name, option);
                                    }}
                                  >
                                    {option}
                                  </Checkbox>
                                ))
                              }


                            </FormGroup>
                            {/* <FormGroup>
                              <ControlLabel
                                className="App-content-candidates-list-div--header"
                              >
                                {field.name}
                              </ControlLabel>
                              <FormControl
                                componentClass="select"
                                name={field.name}
                                onChange={CandidateActions.changeAdditionalFormFieldFilter}
                                value={
                                  candidateFilters.filterAdditionalFormValues[field.name]
                                  || ''}
                              >
                                <option
                                  value=""
                                >
                                  --- Select ---
                                </option>
                                {
                                  field.options.map(option => (
                                    <option
                                      value={option}
                                      key={option}
                                    >
                                      {option}
                                    </option>
                                  ))
                                }
                              </FormControl>
                            </FormGroup> */}
                          </Col>
                        )
                      ))
                  }
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="App-content-candidates-list-div--filter-params-header mb-2">
                    {
                      isCoachingUser()
                        ? t('candidateListing.applicantDateBetween')
                        : t('candidateFilter.candidateDateBetween')
                    }
                  </div>
                </Col>
              </Row>

              {/* <Row>
                <Col md={12}>
                <DatePicker
      closeOnScroll={(e) => e.target === document}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />


                </Col>
              </Row> */}


              <Row>
                {/* <Col md={12}>
                  <ControlLabel
                    className="App-content-candidates-list-div--header"
                  >
                    Show candidates between
                  </ControlLabel>
                </Col> */}
                <Col md={12} className>
                  <FormGroup className="d-flex-row">
                    {/* <DateRangePicker
                      dayPlaceholder=" -- "
                      monthPlaceholder=" -- "
                      yearPlaceholder=" ---- "
                      format="dd/MM/yyyy"
                      value={[filterData.filterStartDate, filterData.filterEndDate]}
                      onChange={CandidateActions.changeStartEndDateFilter}
                    /> */}
                    <div className={`${styles.filterInputContainter} mr-5`}>
                      <img src={calendarIcon} alt="calendar icon" />
                      <Flatpickr
                        // data-enable-time
                        options={{
                          static: true,
                          maxDate: candidateFilters.filterEndDate,
                        }}
                        value={candidateFilters.filterStartDate}
                        onChange={CandidateActions.changeStartDateFilter} // eslint-disable-line
                        className={`${styles.filterinput} bgWhite noBorder form-control`}
                        placeholder={t('candidateFilter.selectStartDate')}
                      />
                    </div>

                    <div className={styles.filterInputContainter}>
                      <img src={calendarIcon} alt="calendar icon" />
                      <Flatpickr
                        // data-enable-time
                        options={{
                          static: true,
                          minDate: candidateFilters.filterStartDate,
                        }}
                        value={candidateFilters.filterEndDate}
                        onChange={CandidateActions.changeEndDateFilter}
                        className={`${styles.filterinput} bgWhite noBorder form-control`}
                        placeholder={t('candidateFilter.selectEndDate')}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={12}>
                  <div className="App-content-candidates-list-div--filter-params-header">{t('interviewListing.status')}</div>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Checkbox
                      className="App-content-candidates-list-div--checkbox"
                      checked={candidateFilters.statusFilterParameteras.Shortlisted}
                      onChange={CandidateActions.changeShowShortlistedCheckbox}
                    >
                      {t('overviewPage.shortlisted')}
                    </Checkbox>
                  </FormGroup>
                  <FormGroup
                    controlId="formControlsSelect"
                    className="App-content-candidates-list-div--filter-params-checkbox-group"
                  >
                    <Checkbox
                      className="App-content-candidates-list-div--checkbox"
                      checked={candidateFilters.statusFilterParameteras.Rejected}
                      onChange={CandidateActions.changeRejectedCheckbox}
                    >
                      {t('candidateDetails.rejected')}
                    </Checkbox>
                  </FormGroup>
                  <FormGroup
                    controlId="formControlsSelect"
                    className="App-content-candidates-list-div--filter-params-checkbox-group"
                  >
                    <Checkbox
                      className="App-content-candidates-list-div--checkbox"
                      checked={candidateFilters.statusFilterParameteras.showKIV}
                      onChange={CandidateActions.changeShowKivCheckbox}
                    >
                      {t('candidateFilter.kived')}
                    </Checkbox>
                  </FormGroup>
                </Col>

                <Col md={4}>
                  <FormGroup>
                    <Checkbox
                      className="App-content-candidates-list-div--checkbox"
                      checked={candidateFilters.statusFilterParameteras.New}
                      onChange={CandidateActions.changeShowNewStatusCheckbox}
                    >
                      {t('candidateDetails.new')}
                    </Checkbox>
                  </FormGroup>
                  <FormGroup
                    controlId="formControlsSelect"
                    className="App-content-candidates-list-div--filter-params-checkbox-group"
                  >
                    <Checkbox
                      className="App-content-candidates-list-div--checkbox"
                      checked={candidateFilters.statusFilterParameteras.showRejected}
                      onChange={CandidateActions.changeRejectedCheckbox}
                    >
                      {t('overviewPage.responded')}
                    </Checkbox>
                  </FormGroup>
                </Col>
              </Row>
              <hr />

              <Row>

                {
                  workmap_enabled && ( // eslint-disable-line
                    <Col md={4}>
                      <div className="App-content-candidates-list-div--filter-params-header">{t('talentpool.workmapScoring')}</div>
                      <p className="f24-normal-dark ">
                        {candidateFilters.workmapScoreSliderValue}
                        %
                      </p>
                      <Slider
                        min={0}
                        max={100}
                        value={candidateFilters.workmapScoreSliderValue}
                        onChange={CandidateActions.changeWorkmapScoreSlider}
                        trackStyle={{
                          backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0), rgba(97, 150, 254,1)',
                        }}
                        handleStyle={{
                          backgroundColor: '#FFFFFF',
                          border: '4px solid #6196FE',
                        }}
                      />
                    </Col>
                  )
                }

                {
                  true && (
                    <Col md={4}>
                      <div className="App-content-candidates-list-div--filter-params-header">{t('talentpool.resumeScoring')}</div>
                      <p className="f24-normal-dark ">
                        {candidateFilters.resumeScoreSliderValue}
                        %
                      </p>

                      <Slider
                        min={0}
                        max={100}
                        value={candidateFilters.resumeScoreSliderValue}
                        onChange={CandidateActions.changeResumeScoreSlider}
                        trackStyle={{
                          backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0), rgba(97, 150, 254,1)',
                        }}
                        handleStyle={{
                          backgroundColor: '#FFFFFF',
                          border: '4px solid #6196FE',
                        }}
                      />
                    </Col>

                  )
                }
                <Col md={4}>
                  <div className="App-content-candidates-list-div--filter-params-header">{t('talentpool.videoScoring')}</div>
                  {/* <input type="range" id="volume" name="volume" min="0" max="11"></input> */}
                  <p className="f24-normal-dark ">
                    {candidateFilters.videoScoreSliderValue}
                    %
                  </p>

                  <Slider
                    min={0}
                    max={100}
                    value={candidateFilters.videoScoreSliderValue}
                    onChange={CandidateActions.changeVideoScoreSlider}
                    trackStyle={{
                      backgroundImage: 'linear-gradient(to right, rgba(97, 150, 254,0), rgba(97, 150, 254,1)',
                    }}
                    handleStyle={{
                      backgroundColor: '#FFFFFF',
                      border: '4px solid #6196FE',
                    }}
                  />
                </Col>
              </Row>

              <Row>
                {
                  workmap_enabled && ( // eslint-disable-line
                    <Col md={4}>
                      {
                        workmapScoreParameterList && workmapScoreParameterList.map(parameter => (
                          <FormGroup
                            controlId="formControlsSelect"
                            className="App-content-candidates-list-div--filter-params-checkbox-group"
                          >
                            <Checkbox
                              className={classnames(
                                styles.capitalize,
                                'App-content-candidates-list-div--checkbox',
                              )}
                              checked={candidateFilters.workmapFilterParameters[parameter]}
                              value={parameter}
                              onChange={() => {
                                CandidateActions.checkWorkmapFilterParameter(parameter);
                              }}
                            >
                              {parameter}
                            </Checkbox>
                          </FormGroup>

                        ))
                      }

                      {/* <Checkbox
                          className="App-content-candidates-list-div--checkbox"
                          checked={filterData.showShortlisted}
                          onChange={CandidateActions.changeShowShortlistedCheckbox}
                        >
                          Shortlisted
                        </Checkbox>
                      </FormGroup>
                      <FormGroup
                        controlId="formControlsSelect"
                        className="App-content-candidates-list-div--filter-params-checkbox-group"
                      >
                        <Checkbox
                          className="App-content-candidates-list-div--checkbox"
                          checked={filterData.showRejected}
                          onChange={CandidateActions.changeRejectedCheckbox}
                        >
                          Rejected
                        </Checkbox>
                      </FormGroup>
                      <FormGroup
                        controlId="formControlsSelect"
                        className="App-content-candidates-list-div--filter-params-checkbox-group"
                      >
                        <Checkbox
                          className="App-content-candidates-list-div--checkbox"
                          checked={filterData.showKIV}
                          onChange={CandidateActions.changeShowKivCheckbox}
                        >
                          KIVed
                        </Checkbox>
                      </FormGroup> */}
                    </Col>

                  )
                }

                {
                  true && (
                    <Col md={4}>

                      {
                        resumeScoreFilterParameterList.map(parameter => (
                          <FormGroup
                            controlId="formControlsSelect"
                            className="App-content-candidates-list-div--filter-params-checkbox-group"
                            key={`key-${parameter}`}
                          >
                            <Checkbox
                              className={classnames(
                                styles.capitalize,
                                'App-content-candidates-list-div--checkbox',
                              )}
                              checked={candidateFilters.resumeFilterParameters[parameter]}
                              onChange={() => {
                                CandidateActions.checkResumeScoreFilterParameter(parameter);
                              }}
                            >
                              {parameter}
                            </Checkbox>
                          </FormGroup>
                        ))
                      }
                    </Col>

                  )
                }

                <Col md={4}>
                  {videoScoreFilterParameterList.map(parameter => (
                    <FormGroup
                      controlId="formControlsSelect"
                      className="App-content-candidates-list-div--filter-params-checkbox-group"
                      key={`key-${parameter}`}
                    >
                      <Checkbox
                        className={classnames(
                          styles.capitalize,
                          'App-content-candidates-list-div--checkbox',
                        )}
                        checked={candidateFilters.videoScoreFilterParameter[parameter]}
                        onChange={() => {
                          CandidateActions.checkVideoScoreFilterParameter(parameter);
                        }}
                      >
                        {parameter}
                      </Checkbox>
                    </FormGroup>
                  ))

                  }
                </Col>
              </Row>
              {/* <Row>
                <Col md={12} className="no-padding">
                  {
                    filterLocationOptions.length > 0 && (
                      <Col xs={12}>
                        <FormGroup>
                          <ControlLabel
                            className="App-content-candidates-list-div--header"
                          >
                            Interview Location
                          </ControlLabel>
                          <FormControl
                            componentClass="select"
                            name="interviewLocation"
                            onChange={CandidateActions.changeLocationFilter}
                            value={filterData.filterLocation}
                          >
                            <option
                              value=""
                            >
                              --- Select ---
                            </option>
                            {
                              filterLocationOptions.map(option => (
                                <option
                                  value={option}
                                  key={option}
                                >
                                  {option}
                                </option>
                              ))
                            }
                          </FormControl>
                        </FormGroup>
                      </Col>
                    )
                  }
                  {
                    orderBy(filterAdditionalFormFields, ['name'])
                      .map(field => (
                        field.options.length > 0 && (
                          <Col xs={12} key={field.name}>
                            <FormGroup>
                              <ControlLabel
                                className="App-content-candidates-list-div--header"
                              >
                                {field.name}
                              </ControlLabel>
                              <FormControl
                                componentClass="select"
                                name={field.name}
                                onChange={CandidateActions.changeAdditionalFormFieldFilter}
                                value={filterData.filterAdditionalFormValues[field.name] || ''}
                              >
                                <option
                                  value=""
                                >
                                  --- Select ---
                                </option>
                                {
                                  field.options.map(option => (
                                    <option
                                      value={option}
                                      key={option}
                                    >
                                      {option}
                                    </option>
                                  ))
                                }
                              </FormControl>
                            </FormGroup>
                          </Col>
                        )
                      ))
                  }
                </Col>
              </Row> */}
              <hr />
              <Row>
                <Col md={12}>
                  <div className="App-content-candidates-list-div--filter-params-header mb-2">{t('candidateFilter.filterKeyworkds')}</div>
                </Col>
              </Row>
              <div className={`${styles.keywordInputFilter}`}>
                {/* <Col md={12}> */}
                {/* <ControlLabel
                    className="App-content-candidates-list-div--header"
                  >
                    Filter keywords in caption / resume
                  </ControlLabel> */}
                {/* </Col> */}
                {/* <Col md={8}> */}
                <MultivalueInput
                  values={filterKeywords}
                  className="keywordInputFilter"
                  onValuesChange={CandidateActions.changeKeywordFilter}
                  placeholder={t('candidateFilter.keywordFilterInfo')}
                />
                {/* </Col> */}
              </div>
              <Row>
                <Col md={12}>
                  <button
                    type="button"
                    className={`btn btn-default filter-modal-content--reset-btn ${styles.resetButton}`}
                    onClick={CandidateActions.resetFilters}
                  >
                    {t('candidateFilter.resetFilter')}
                  </button>
                  <Button
                    bsClass="btn-primary-custom"
                    className={`btn btn-primary-custom filter-modal-content--apply-btn ${styles.applyButton}`}
                    onClick={this.handleApplyFilterBtnOnClick}
                  >
                    {t('candidateFilter.applyFilter')}
                  </Button>
                </Col>
              </Row>
            </Grid>
          </Modal.Body>
          {/* <Modal.Footer>
            <Button
              className="btn btn-default filter-modal-content--reset-btn"
              onClick={CandidateActions.resetFilters}
            >
              Reset filter
            </Button>
            <Button
              bsClass="btn-primary-custom"
              className="btn btn-primary-custom filter-modal-content--apply-btn"
              onClick={this.handleApplyFilterBtnOnClick}
            >
              Apply Filter
            </Button>
          </Modal.Footer> */}
        </Modal>
      </Fragment>
    );
  }
}

export default withTranslation()(ScoreFilter);
