import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../css/Copyright.module.scss';

const CopyrightScreen = () => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  const [scrollDirection, setScrollDirection] = useState(null);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const { scrollY } = window;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection
        && (scrollY - lastScrollY > 10
          || scrollY - lastScrollY < -10)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return (
    <div className={`${scrollDirection === 'down' ? styles.footerHide : styles.footer}`}>
      <p className={styles.copyRightText}>
        {t('footer.copyRightText', { currentYear })}
      </p>
    </div>
  );
};

export default CopyrightScreen;
