// @flow
import * as React from 'react';
import styles from '../css/Loader.module.scss';

const InterviewerLogo = ({
  width,
  height,
}: {
  width: Number,
  height: Number,
}) => {   //eslint-disable-line

  return (
    <svg width={width} height={height} viewBox="0 0 33 39" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.newImage}>
      <path fillRule="evenodd" clipRrule="evenodd" d="M15.8884 5.80542H0.53125V33.5987H8.20982L10.5313 28.7998H4.81696V10.8042L18.9241 10.6043L23.2098 0.806641L15.8884 5.80542Z" fill="#FE5819" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.8153 5.80469L22.3153 10.6035H28.0296V28.5991L13.9224 28.999L9.63672 38.7967L16.9581 33.5979H32.4939V5.80469H24.8153Z" fill="#6196FE" />
    </svg>
  );
};

const Loader = () => (
  <div className={styles.newLoaderContainer}>
    <div className={styles.newLoader}>
      <div className={styles.eyeContainer}>
        <div className={styles.eye} />
        <div className={styles.eye} />
      </div>
      <InterviewerLogo width="25%" height="25%" />
    </div>
  </div>
);

export default Loader;
