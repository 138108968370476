import React from 'react';
import PropTypes from 'prop-types';
import styles from '../../css/Backdrop.module.scss';

const BackDrop = (props) => {
  const { profileHandler } = props;
  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={profileHandler}
      className={styles.backdrop}
      onClick={profileHandler}
    />
  );
};

BackDrop.propTypes = {
  profileHandler: PropTypes.func.isRequired,
};

export default BackDrop;
