import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import Pagination from 'react-js-pagination';
import { isMobile } from 'react-device-detect';
import { ReactComponent as FirstButton } from '../assets/NewPagFirstBack.svg';
import { ReactComponent as LastButton } from '../assets/NewPagLastBack.svg';
import { ReactComponent as PrevButton } from '../assets/NewPagPrev.svg';
import { ReactComponent as NextButton } from '../assets/NewPagNext.svg';
import styles from '../css/Pagination.module.scss';
import CandidateStore from '../stores/CandidateStore';
import '../css/_common.scss';

const PaginationNewWidgit = ({
  totalItems, defaultPageItems, sortOrder = false,
  handlePaginationClick,
  activePage,
  startIndex,
  endIndex,
}) => {
  const [pageItems, setPageItems] = useState(defaultPageItems); // eslint-disable-line
  const [currentPage, setCurrentPage] = useState(activePage || 1);
  const [startingIndex, setStartingIndex] = useState(activePage === 1 ? 1 : (startIndex || 1));
  const [endingIndex, setEndingIndex] = useState(endIndex ? ((endIndex > totalItems) ? totalItems : endIndex) : 10);  //eslint-disable-line
  const [maxPages, setMaxPages] = useState(Math.ceil(totalItems / pageItems));
  const [stotalItems, setStotalItems] = useState(totalItems);  //eslint-disable-line
  const [menuItemsOfPages, setMenuItemsOfPages] = useState(<React.Fragment />); // eslint-disable-line

  const handleCurrentPageChange = (key) => {
    setCurrentPage(key);
    handlePaginationClick(key);
  };

  const handlePageChange = (key) => {
    setCurrentPage(key);
    setStartingIndex(key * +defaultPageItems - 9);
    if (key * +defaultPageItems > totalItems) {
      setEndingIndex(totalItems);
    } else {
      setEndingIndex(key * +defaultPageItems);
    }
    handlePaginationClick(key);
  };

  const generatePagesItems = () => {
    const newList = [...new Array(maxPages)];
    setMenuItemsOfPages(newList.map((data, index) => (
      <button
        type="button"
        key={`page-${index}`} // eslint-disable-line 
        eventKey={index + 1}
        className={styles.pageButton}
        name="pageNumber"
        onSelect={handleCurrentPageChange}
        active={(index + 1) === currentPage}
      >
        {index + 1}
      </button>
      // <MenuItem
      //   key={`page-${index}`} // eslint-disable-line
      //   eventKey={index + 1}
      //   name="pageNumber"
      //   onSelect={handleCurrentPageChange}
      //   active={(index + 1) === currentPage}
      // >
      //   {index + 1}
      // </MenuItem>
    )));
  };

  const onChange = (states) => {
    setCurrentPage(states.currentPage);
  };

  useEffect(() => {
    CandidateStore.listen(onChange);
    generatePagesItems();
  }, []);

  useEffect(() => {
    setMaxPages(Math.ceil(totalItems / pageItems));
    generatePagesItems(); // to show active current page in menu item
    const startingPoint = (currentPage - 1) * defaultPageItems + 1;
    let endingPoint = currentPage * defaultPageItems;
    if (endingPoint > totalItems) {
      endingPoint = totalItems;
    }
    setStartingIndex(startingPoint);
    setEndingIndex(endingPoint);
    // onScrollOnTop();
  }, [currentPage]);

  useEffect(() => {
    const startingPoint = (currentPage - 1) * defaultPageItems + 1;
    let endingPoint = currentPage * defaultPageItems;
    if (endingPoint > totalItems) {
      endingPoint = totalItems;
    }
    setStartingIndex(startingPoint);
    setEndingIndex(endingPoint);
  }, [totalItems]);

  useEffect(() => {
    setMaxPages(Math.ceil(totalItems / pageItems));
  }, [pageItems]);

  useEffect(() => {
    generatePagesItems();
  }, [maxPages]);

  useEffect(() => {
    setMaxPages(Math.ceil(totalItems / pageItems)); // to update max page and generate pages items
  }, [totalItems, sortOrder]);

  return (

    <React.Fragment>

      {
        !isMobile && (
          <div className={styles.pageItemsContainerWidgit}>
            <div className={styles.newPaginationContainer}>
              <button
                className={styles.firstButtonSection}
                onClick={() => handlePageChange(1)}
                disabled={currentPage <= 1}
                type="button"
              >
                <FirstButton />
                <span className={styles.centerText}>
                  First
                </span>
              </button>
              <div className={styles.paginationCenterSection}>
                <button
                  className={styles.center}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage <= 1}
                  type="button"
                >
                  <PrevButton />
                </button>
                <div className={styles.centerText}>
                  {`${startingIndex} - ${endingIndex} of ${totalItems}`}
                </div>
                <button
                  className={styles.center}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!((currentPage * +defaultPageItems) <= totalItems)}
                  type="button"
                >
                  <NextButton />
                </button>
              </div>
              <button
                className={styles.firstButtonSection}
                onClick={() => handlePageChange(Math.ceil(totalItems / defaultPageItems))}
                disabled={!((currentPage * +defaultPageItems) <= totalItems)}
                type="button"
              >
                {console.log(`${startingIndex} - ${endingIndex}`)}
                <span className={styles.centerText}>
                  Last
                </span>
                <LastButton />
              </button>
            </div>
          </div>
        )
      }

      {

        isMobile && (
          <div className={styles.pageItemsContainerWidgit}>
            <div className={styles.newPaginationContainer}>
              <button
                className={styles.firstButtonSection}
                onClick={() => handlePageChange(1)}
                disabled={currentPage <= 1}
                type="button"
              >
                <FirstButton />
                <span className={styles.centerText}>
                  First
                </span>
              </button>
              <div className={styles.paginationCenterSection}>
                <button
                  className={styles.center}
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage <= 1}
                  type="button"
                >
                  <PrevButton />
                </button>
                <div className={styles.centerText}>
                  {`${startingIndex} - ${endingIndex} of ${totalItems}`}
                </div>
                <button
                  className={styles.center}
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={!((currentPage * +defaultPageItems) <= totalItems)}
                  type="button"
                >
                  <NextButton />
                </button>
              </div>
              <button
                className={styles.firstButtonSection}
                onClick={() => handlePageChange(Math.ceil(totalItems / defaultPageItems))}
                disabled={!((currentPage * +defaultPageItems) <= totalItems)}
                type="button"
              >
                {console.log(`${startingIndex} - ${endingIndex}`)}
                <span className={styles.centerText}>
                  Last
                </span>
                <LastButton />
              </button>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

PaginationNewWidgit.defaultProps = {
  defaultPageItems: 999,
  sortOrder: false,
};

PaginationNewWidgit.propTypes = {
  totalItems: PropTypes.number.isRequired,
  defaultPageItems: PropTypes.number,
  sortOrder: PropTypes.string,
  handlePaginationClick: PropTypes.func.isRequired,
  activePage: PropTypes.number.isRequired,
  startIndex: PropTypes.number.isRequired,
  endIndex: PropTypes.number.isRequired,
};

export default PaginationNewWidgit;
