import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CandidateStore from '../../stores/CandidateStore';
import styles from '../../css/DomainQuestion.module.scss';
import CandidateActions from '../../actions/CandidateActions';

const DomainQuestion = (props) => {
  const { candidateData, interviewId } = props;
  const {
    email,
  } = candidateData;
  const [domainScore, setDomainScore] = useState(null);
  const [colorArray] = useState(['rgb(255, 99, 132)',
    'rgb(255, 159, 64)',
    'rgb(255, 205, 86)',
    'rgb(75, 192, 192)',
    'rgb(54, 162, 235)',
    'rgb(153, 102, 255)',
    'rgb(201, 203, 207)']);

  const onChange = (candidateStoreData) => {
    const {
      candidateDomainScore,
    } = candidateStoreData;
    setDomainScore(candidateDomainScore);
  };


  useEffect(() => {
    CandidateActions.fetchDomainQuestionResponse(interviewId, email);
    CandidateStore.listen(onChange);
  }, []);

  if (!domainScore || Object.keys(domainScore).length === 0) {
    return (
      <React.Fragment>
        <p>
          Not available
        </p>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.domainQuestionContainer}>
        <h4>Domain Question Score</h4>
        <hr />
        <div className={styles.domainQuestionCardContainer}>
          {
            Object.keys(domainScore).map((x, index) => (
              <div className={styles.singleCard}>
                <div
                  className={styles.circle}
                  style={{ border: `7px solid ${colorArray[index]}` }}
                >
                  <span>
                    {domainScore[x].actualScore}
                  </span>
                  <span>/</span>
                  <span>{domainScore[x].totalScore}</span>
                </div>
                <div className={styles.label}>{x}</div>
              </div>
            ))
          }
        </div>
      </div>
    </React.Fragment>
  );
};

DomainQuestion.propTypes = {
  interviewId: PropTypes.string.isRequired,
  candidateData: PropTypes.shape({
    email: PropTypes.string,
  }).isRequired,
};

export default DomainQuestion;
