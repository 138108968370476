import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../css/TeamMembers.module.scss';
import { getPackageName, isObjectEmpty } from '../utils/utils';
import RightArrow from '../assets/rightArrow.svg';


const PlanDetails = (props) => {
  const {
    subscription,
    adminLimit,
    teamMembers,
    memberLimit,
  } = props;
  const { t } = useTranslation();


  const [subscriptionText, setSubscriptionText] = useState();
  const [adminCount, setAdminCount] = useState();
  const [userCount, setUserCount] = useState();

  const handleSetSubscription = () => {
    const subscriptionTextLocal = t(`navbarSection.${getPackageName(subscription)}`);
    setSubscriptionText(subscriptionTextLocal);
  };

  const handleMemberCount = (members) => {
    const tempAdminCount = members.filter(m => m.role === 'admin').length;
    const tempUserCount = members.filter(m => m.role === 'user').length;
    setAdminCount(tempAdminCount);
    setUserCount(tempUserCount);
  };

  useEffect(() => {
    if (!isObjectEmpty(subscription)) {
      handleSetSubscription();
    }
    handleMemberCount(teamMembers);
  });

  return (
    <div className={styles.planContainer}>
      <div className={styles.proFlexContainer}>
        <div className={styles.leftContainer}>
          <h3 className={styles.proText}>{subscriptionText}</h3>
          <p className={styles.planText}>{t('planDetails.yourPlan')}</p>
        </div>
        <div className={styles.rightContainer}>
          <Link
            className={styles.upgradeLink}
            to="/settings/billing/plan"
          >
            {t('userList.upgrade')}
            <img
              alt="interviewer ai organization"
              src={RightArrow}
              className={styles.rightArrow}
            />
          </Link>
        </div>
      </div>
      <p className={styles.entitlementText}>{t('planDetails.planEntitlements')}</p>
      <ul className={styles.listContainer}>
        <li className={styles.nonDisplay}>
          <p className={styles.lightTextEn}>{t('planDetails.superAdmin')}</p>
          <p className={styles.lightTextEn}>
            <b className={styles.countBold}>2</b>
            <span className={styles.countLight}>
              /3
            </span>
          </p>
        </li>
        <hr className={styles.nonDisplay} />
        <li className={styles.listFlexContainer}>
          <p className={styles.lightTextEn}>{t('planDetails.admin')}</p>
          <p className={styles.lightTextEn}>
            <b className={styles.countBold}>{adminCount}</b>
            <span className={styles.countLight}>
              /
              {adminLimit}
            </span>
          </p>
        </li>
        <hr />
        <li className={styles.listFlexContainer}>
          <p className={styles.lightTextEn}>{t('planDetails.user')}</p>
          <p className={styles.lightTextEn}>
            <b className={styles.countBold}>{userCount}</b>
            <span className={styles.countLight}>
              /
              {memberLimit - adminLimit}
            </span>
          </p>
        </li>
        <hr className={styles.nonDisplay} />
        <li className={styles.nonDisplay}>
          <p className={styles.lightTextEn}>{t('planDetails.contributors')}</p>
          <p className={styles.lightTextEn}>
            <b className={styles.countBold}>2</b>
            <span className={styles.countLight}>
              /3
            </span>
          </p>
        </li>
      </ul>
    </div>
  );
};

PlanDetails.propTypes = {
  subscription: PropTypes.shape.isRequired,
  adminLimit: PropTypes.number.isRequired,
  teamMembers: PropTypes.shape.isRequired,
  memberLimit: PropTypes.number.isRequired,
};

export default PlanDetails;
