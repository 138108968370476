import React, { useRef } from 'react';
import {
  FormGroup,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { isMobile } from 'react-device-detect';
import PropTypes from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation, withTranslation } from 'react-i18next';  //eslint-disable-line
import Flatpickr from 'react-flatpickr';
import classnames from 'classnames';
import 'rc-slider/assets/index.css';
import { ReactComponent as DownArrow } from '../assets/ArrowDown.svg';
import { ReactComponent as CloseIcon } from '../assets/NewCloseIcon.svg';
import { ReactComponent as LocationIcon } from '../assets/LocationIcon.svg';
import styles from '../css/FilterCandidate.module.scss';
import CandidateActions from '../actions/CandidateActions';
import calendarIcon from '../assets/calendarIcon.svg';

const FilterCandidate = (props) => {
  const {
    selectCandidateStatusToFilter,
    selectRecruiterStatusToFilter,
    additionalIntroQuestion,
    introQuestionDpObject,
    handleFilterButton,
    filterStartDate,
    filterEndDate,
    handleFilterReset,
    candidateLocations,
    selectedCountriesDisplay,
    candidateStatusCount,
    recruiterStatusCount,
    // relevancyScoreCounts,
  } = props;
  const { t } = useTranslation();
  const [
    flatpickrRef1,
    flatpickrRef2,
    locationRef,
    candidateStatusRef,
    RecruiterStatusRef,
    // RelevancyScoreRef,
  ] = Array(5).fill().map(() => useRef());

  const introQuestionRef = useRef(null);

  if (additionalIntroQuestion
    && additionalIntroQuestion.length !== 0
    && !introQuestionRef.current) {
    introQuestionRef.current = additionalIntroQuestion.map(() => ({}));
  }

  const openDatePicker = (reference) => {
    if (reference.current) {
      if (reference.current.flatpickr.isOpen) {
        reference.current.flatpickr.close();
      } else {
        reference.current.flatpickr.open();
      }
    }
  };

  const triggerTypehead = (reference) => {
    if (reference.current) {
      reference.current.focus();
    }
  };

  const removeSelectedCountry = (country) => {
    CandidateActions.removeSelectedCountry(country);
  };

  const removeCandidateStatus = (status) => {
    CandidateActions.removeSelectedStatus(status);
  };

  const removeRecruiterStatus = (status) => {
    CandidateActions.removeSelectedRecruiterStatus(status);
  };

  return (
    <div className={styles.filterSectionContainer}>
      <div className={styles.filterApplicationDate}>
        <div className={styles.filterLabel}>
          {t('candidateFilters.applciationRecievedBetween')}
        </div>
        <FormGroup className={styles.dateContainer}>
          <div className={`${styles.filterInputContainter} mr-5`}>
            <Flatpickr
              ref={flatpickrRef1}
              options={{
                static: true,
                maxDate: filterEndDate,
              }}
              value={filterStartDate}
              onChange={CandidateActions.changeStartDateFilter} // eslint-disable-line
              className={`${styles.filterinput} bgWhite noBorder form-control`}
              placeholder={t('candidateFilters.startDate')}
            />
            {
              !isMobile && (
                <button
                  className={styles.flatPickerImg}
                  onClick={() => openDatePicker(flatpickrRef1)}
                  type="button"
                >
                  <img
                    src={calendarIcon}
                    alt="calendar icon"
                    style={{ marginRight: '0.5rem' }}
                  />
                </button>
              )
            }
          </div>

          <div className={styles.filterInputContainter}>
            <Flatpickr
              ref={flatpickrRef2}
              options={{
                static: true,
                minDate: filterStartDate,
              }}
              value={filterEndDate}
              onChange={CandidateActions.changeEndDateFilter}
              className={`${styles.filterinput} bgWhite noBorder form-control`}
              placeholder={t('candidateFilters.endDate')}
            />
            {
              !isMobile && (
                <button
                  className={styles.flatPickerImg}
                  onClick={() => openDatePicker(flatpickrRef2)}
                  type="button"
                >
                  <img
                    src={calendarIcon}
                    alt="calendar icon"
                    style={{ marginRight: '0.5rem' }}
                  />
                </button>
              )
            }
          </div>
        </FormGroup>
      </div>
      <div className={styles.scrollContainer}>
        <div className={styles.filterLocationSection}>
          <div className={styles.filterLabel}>
            {t('candidateFilters.location')}
          </div>
          <div className={styles.filterSelectCountry}>
            {
              candidateLocations && (
                <FormGroup
                  controlId="countryId"
                  style={{ position: 'relative' }}
                >
                  <Typeahead
                    ref={locationRef}
                    id="basic-typeahead-multiple-cand-status"
                    labelKey="Select Location"
                    options={candidateLocations}
                    placeholder={t('candidateFilters.selectLocation')}
                    className={classnames(styles.jobLocationIputTypehead, styles.locationInput)}
                    onChange={CandidateActions.setCountryId}
                    multiple
                  />
                  <div className={styles.locationIcon}>
                    <LocationIcon />
                  </div>
                  <button
                    className={styles.arrowIcon}
                    onClick={() => triggerTypehead(locationRef)}
                    type="button"
                  >
                    <DownArrow />
                  </button>
                </FormGroup>
              )
            }
            <div className={styles.countryListSection}>
              {
                selectedCountriesDisplay && selectedCountriesDisplay.map(country => (
                  <div className={styles.countryList}>
                    <span className={styles.selectedTextValue}>{country}</span>
                    <button
                      className={styles.centerElement}
                      onClick={() => removeSelectedCountry(country)}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className={styles.filterCandidateStatusSection}>
          <div className={styles.filterLabel}>
            {t('candidateFilters.candidateStatus')}
          </div>
          <div className={styles.filterCandidateStatus}>
            <FormGroup
              controlId="candidateStatusId"
              style={{ position: 'relative' }}
            >
              <Typeahead
                ref={candidateStatusRef}
                id="basic-typeahead-multiple-cand-status"
                labelKey="name"
                options={candidateStatusCount}
                placeholder={t('candidateFilters.selectStatus')}
                className={styles.jobLocationIputTypehead}
                onChange={CandidateActions.selectFilterCandidateStatus}
                multiple
              />
              <button
                className={styles.arrowIcon}
                onClick={() => triggerTypehead(candidateStatusRef)}
                type="button"
              >
                <DownArrow />
              </button>
            </FormGroup>
            <div className={styles.countryListSection}>
              {
                selectCandidateStatusToFilter && selectCandidateStatusToFilter.map(status => (
                  <div className={styles.countryList}>
                    <span className={styles.selectedTextValue}>{status}</span>
                    <button
                      className={styles.centerElement}
                      onClick={() => removeCandidateStatus(status)}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
        <div className={styles.filterCandidateStatusSection}>
          <div className={styles.filterLabel}>
            {t('candidateFilters.recruiterStatus')}
          </div>
          <div className={styles.filterCandidateStatus}>
            <FormGroup
              controlId="recruiterStatusId"
              style={{ position: 'relative' }}
            >
              <Typeahead
                ref={RecruiterStatusRef}
                id="basic-typeahead-multiple-cand-status"
                labelKey="name"
                options={recruiterStatusCount}
                placeholder={t('candidateFilters.selectStatus')}
                className={styles.jobLocationIputTypehead}
                onChange={CandidateActions.selectFilterRecruiterStatus}
                multiple
              />
              <button
                className={styles.arrowIcon}
                onClick={() => triggerTypehead(RecruiterStatusRef)}
                type="button"
              >
                <DownArrow />
              </button>
            </FormGroup>
            <div className={styles.countryListSection}>
              {
                selectRecruiterStatusToFilter && selectRecruiterStatusToFilter.map(status => (
                  <div className={styles.countryList}>
                    <span className={styles.selectedTextValue}>{status}</span>
                    <button
                      className={styles.centerElement}
                      onClick={() => removeRecruiterStatus(status)}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        </div>

        {/* <div className={styles.filterCandidateStatusSection}>
          <div className={styles.filterLabel}>
            {t('candidateFilters.relevancyScore')}
          </div>
          <div className={styles.filterCandidateStatus}>
            <FormGroup
              controlId="relevancyScoreId"
              style={{ position: 'relative' }}
            >
              <Typeahead
                ref={RelevancyScoreRef}
                id="basic-typeahead-relevancy-score"
                labelKey="label"
                options={relevancyScoreCounts}
                placeholder={t('candidateFilters.selectStatus')}
                className={styles.jobLocationIputTypehead}
                onChange={CandidateActions.selectFilterRelevancyScore}
              />
              <button
                className={styles.arrowIcon}
                onClick={() => triggerTypehead(RelevancyScoreRef)}
                type="button"
              >
                <DownArrow />
              </button>
            </FormGroup>
            <div className={styles.countryListSection}>
              {
                selectRecruiterStatusToFilter && selectRecruiterStatusToFilter.map(status => (
                  <div className={styles.countryList}>
                    <span className={styles.selectedTextValue}>{status}</span>
                    <button
                      className={styles.centerElement}
                      onClick={() => removeRecruiterStatus(status)}
                      type="button"
                    >
                      <CloseIcon />
                    </button>
                  </div>
                ))
              }
            </div>
          </div>
        </div> */}
        {
          additionalIntroQuestion && additionalIntroQuestion.length !== 0 && (
            <div className={styles.introQuestionContainer}>
              <div className={styles.introQuestionHeading}>
                {t('candidateFilters.introQuestions')}
              </div>
              {
                additionalIntroQuestion.map((question, index) => (
                  <div
                    className={styles.filterCandidateStatusSection}
                  >
                    <div className={styles.filterLabel}>
                      {question.name}
                    </div>
                    <div className={styles.filterCandidateStatus}>
                      <FormGroup
                        controlId={`${question}id`}
                        style={{ position: 'relative' }}
                      >
                        <Typeahead
                          ref={introQuestionRef.current[index]}
                          id="basic-typeahead-multiple-cand-status"
                          labelKey="name"
                          options={question.options}
                          placeholder={t('candidateFilters.selectOptions')}
                          className={styles.jobLocationIputTypehead}
                          onChange={e => CandidateActions
                            .setSelectedIntroQuestionsOptions(question.name, e)}
                          multiple
                        />
                        <button
                          className={styles.arrowIcon}
                          onClick={() => triggerTypehead(introQuestionRef.current[index])}
                          type="button"
                        >
                          <DownArrow />
                        </button>
                      </FormGroup>
                      <div className={styles.countryListSection}>
                        {
                          introQuestionDpObject && introQuestionDpObject[question.name].map(op => (
                            <div className={styles.countryList}>
                              <span className={styles.selectedTextValue}>{op}</span>
                              <button
                                className={styles.centerElement}
                                onClick={() => CandidateActions
                                  .removeSelectedIntroQuestionChoice(question.name, op)}
                                type="button"
                              >
                                <CloseIcon />
                              </button>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
      <div className={styles.buttonSection}>
        <button
          className={styles.resetButton}
          type="button"
          onClick={handleFilterReset}
        >
          {t('candidateFilters.reset')}
        </button>
        <button
          className={styles.applyFilterButton}
          type="button"
          onClick={handleFilterButton}
        >
          {t('candidateFilters.applyFilter')}
        </button>
      </div>
    </div>
  );
};
FilterCandidate.propTypes = {
  countryList: PropTypes.shape({}).isRequired,
  selectedCountries: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  selectCandidateStatusToFilter: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  selectRecruiterStatusToFilter: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  candidateFilters: PropTypes.shape({
    filterEndDate: PropTypes.string.isRequired,
    filterStartDate: PropTypes.string.isRequired,
  }).isRequired,
  additionalIntroQuestion: PropTypes.shape({
    map: PropTypes.func.isRequired,
    length: PropTypes.number.isRequired,
  }).isRequired,
  introQuestionDpObject: PropTypes.shape({}).isRequired,
  handleFilterButton: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  filterStartDate: PropTypes.string.isRequired,
  filterEndDate: PropTypes.string.isRequired,
  candidateLocations: PropTypes.object.isRequired, // eslint-disable-line
  candidateLocationsData: PropTypes.object.isRequired, // eslint-disable-line
  selectedCountriesDisplay: PropTypes.shape({
    map: PropTypes.func.isRequired,
  }).isRequired,
  candidateStatusCount: PropTypes.shape({}).isRequired,
  recruiterStatusCount: PropTypes.shape({}).isRequired,
  relevancyScoreCounts: PropTypes.shape({}).isRequired,
};

export default FilterCandidate;
