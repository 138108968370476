import React from 'react';
import '../css/NotFoundScreen.css';

const NotFoundScreen = () => (
  <div className="not-found">
    <div className="not-found__container text-center">
      <div className="container">
        <h1>{'We can\'t find the page you are looking for.'}</h1>
        <p>Here are a few links that may be useful:</p>
        <ul className="not-found__container--list">
          <li>
            <a href="/login">Login</a>
          </li>
          <li>
            <a href="https://interviewer.ai">Home</a>
          </li>
          <li>
            <a href="https://interviewer.ai/about/">About Us</a>
          </li>
          <li>
            <a href="https://interviewer.ai/terms/customer/">Terms and Conditions</a>
          </li>
          <li>
            <a href="https://interviewer.ai/terms/privacy-policy/">Privacy</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default NotFoundScreen;
