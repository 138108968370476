import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  isCoachingUser,
} from '../../../utils/utils';
import '../../../css/NavigationBar/ProfileSidebar/ProfileSidebar.scss';
// import ProfileTemp from '../../../assets/ProfileTemp.png';
import ProfileLogo from '../../../assets/ProfileLogo.svg';
import BillingIcon from '../../../assets/BillingIcon.svg';
import IntegrationIcon from '../../../assets/IntegrationIcon.svg';
import PromoCodeIcon from '../../../assets/PromoCodeIcon.svg';
import SupportIcon from '../../../assets/SupportIcon.svg';
import { ReactComponent as BellIcon } from '../../../assets/BellIcon.svg';
import VideoTutorialIcon from '../../../assets/VideoTutorialIcon.svg';
// import ShareFeedbackIcon from '../../../assets/ShareFeedbackIcon.svg';
import ChangePasswordIcon from '../../../assets/ChangePasswordIcon.svg';
import TermsConditionIcon from '../../../assets/TermsConditionIcon.svg';
import CompanyProfile from '../../../assets/CompanyProfile.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/LogoutIcon.svg';
import SettingStore from '../../../stores/SettingStore';
import SettingActions from '../../../actions/SettingActions';
import OrganizationStore from '../../../stores/OrganizationStore';
import HelpModal from '../../HelpModal';
import { ReactComponent as HelpIcon } from '../../../assets/HelpIcon.svg';
import InfoTooltip from '../../InfoTooltip';

const ProfileSidebar = (props) => {
  const { show } = props;
  const isDrawerOpen = show ? 'profileSliderContainer openProfileslider' : 'profileSliderContainer';
  const [profileSliderClass, toggleOpenClassHandler] = useState(isDrawerOpen);
  const [profileData, setProfileData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const { outerWidth } = window;
  const { subscription, userRole } = OrganizationStore.getState();
  const { t } = useTranslation();
  const isUserCoaching = isCoachingUser();


  function onChange() {
    setProfileData(SettingStore.getState().profileData);
  }

  useEffect(() => {
    const fetchUserData = async () => {
      OrganizationStore.listen(onChange);
      try {
        await SettingActions.getProfileBasicInfo();
      } catch (e) {
        console.log(e);
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    toggleOpenClassHandler(isDrawerOpen);
    onChange();
  }, [isDrawerOpen]);

  const hideProfileDrawerHandler = () => {
    toggleOpenClassHandler('profileSliderContainer');
    props.profileHandler(); // eslint-disable-line
  };


  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const getInitial = (stringValue) => {
    if (stringValue) {
      return stringValue.charAt(0);
    }
    return '';
  };

  return (
    <div className={profileSliderClass}>
      <div className="ProfileSection">
        <div className="ProfileContainer">
          <button type="button" className="closeProfileDrawer" onClick={hideProfileDrawerHandler}><Glyphicon glyph={outerWidth <= 600 ? 'chevron-left' : 'remove'} /></button>
          <div>
            {!profileData.user_logo_url ? (
              <div className="userProfileImage">
                <h1>
                  {getInitial(profileData.firstName)}
                  {getInitial(profileData.lastName)}
                </h1>
              </div>

            ) : (
              <div className="userProfileImage">
                <img
                  src={profileData.user_logo_url}
                  alt={profileData.firstName}
                />
              </div>
            )}
          </div>
          <div className="ProfileInfo">
            <InfoTooltip
              placement="bottom"
              tooltipContent={`${profileData.firstName} ${profileData.lastName}`}
            >
              <span className="userName">
                {profileData.firstName}
                {' '}
                {profileData.lastName}
              </span>
            </InfoTooltip>
            <span>{profileData.email}</span>

            <div className="logoutButton">
              <Link className="links" to="/logout">
                <div>
                  <LogoutIcon width="15" height="15" fill="#FF5B5B" />
                  <span>{t('profileSlider.logout')}</span>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="linksSection">
        <Link className="links" to="/settings/profile" onClick={hideProfileDrawerHandler}>
          <img src={ProfileLogo} alt={`${ProfileLogo} logo`} />
          <span>{t('profileSlider.userProfile')}</span>
        </Link>

        <Link className="links" to="/settings/company-profile" onClick={hideProfileDrawerHandler}>
          <img src={CompanyProfile} alt="Company icon" />
          <span>
            {
              isUserCoaching
                ? t('profileSlider.instituteProfile')
                : t('profileSlider.companyProfile')
            }
          </span>
        </Link>

        <Link className="links" to="/settings/billing" onClick={hideProfileDrawerHandler}>
          <img src={BillingIcon} alt={`${BillingIcon} logo`} />
          <span>{t('profileSlider.billing')}</span>
        </Link>
        <Link className="links" to="/settings/integrations" onClick={hideProfileDrawerHandler}>
          <img src={IntegrationIcon} alt={`${IntegrationIcon} logo`} />
          <span>{t('profileSlider.integrations')}</span>
        </Link>
        <Link className="links" to="/notification-settings" onClick={hideProfileDrawerHandler}>
          <BellIcon width="25" height="25" stroke="#7A91AB" strokeWidth="2.5" />
          <span>{t('profileSlider.notificationSettings')}</span>
        </Link>
        {subscription.name && (subscription.name.includes('ASelect') || subscription.name.includes('ACredit') || subscription.name.includes('APlus'))
          && userRole === 'admin' && (
            <Link className="links" to="/settings/stackcodes" onClick={hideProfileDrawerHandler}>
              <img src={PromoCodeIcon} alt={`${PromoCodeIcon} logo`} />
              <span>{t('profileSlider.promoCode')}</span>
            </Link>
          )}


        <a className="links" href="mailto:support@interviewer.ai?subject=Need Help">
          <img src={SupportIcon} alt={`${SupportIcon} logo`} />
          <span>{t('profileSlider.contactSupport')}</span>
        </a>

        <button
          type="button"
          className="links btn-link"
          onClick={() => handleModalOpen()}
          onKeyDown={() => handleModalOpen()}
        >
          <img src={VideoTutorialIcon} alt={`${VideoTutorialIcon} logo`} />
          {t('profileSlider.videoTutorial')}
        </button>

        {/* <a className="links" href="https://interviewer.ai/terms/customer/">
          <img src={ShareFeedbackIcon} alt={`${ShareFeedbackIcon} logo`} />
          <span>{t('profileSlider.shareFeedback')}</span>
        </a> */}

        <a className="links" href="/reset">
          <img src={ChangePasswordIcon} alt={`${ChangePasswordIcon} logo`} />
          <span>{t('profileSlider.changePassword')}</span>
        </a>

        {/* <a className="links" href="/notification-settings">
          <img src={bellIcon} alt={`${bellIcon} logo`} />
          <span>Notification Settings </span>
        </a> */}

        <a className="links" href="https://interviewer.ai/terms/customer/">
          <img src={TermsConditionIcon} alt={`${TermsConditionIcon} logo`} />
          <span>{t('profileSlider.termsAndConditions')}</span>
        </a>

        {
          isMobile && (
            <a className="links" href="https://support.interviewer.ai/">
              <HelpIcon height="25" width="25" stroke="#7A91AB" />
              <span>{t('profileSlider.help')}</span>
            </a>
          )
        }

        <HelpModal
          modalOpen={modalOpen}
          handleModalClose={handleModalClose}
        />
      </div>
    </div>
  );
};

ProfileSidebar.propTypes = {
  show: PropTypes.bool.isRequired,
  profileHandler: PropTypes.func.isRequired,
};

export default ProfileSidebar;
