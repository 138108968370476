// @flow
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Image3 from '../assets/creditsAlert.jpg';
import OrganizationStore from '../stores/OrganizationStore';
import OrganizationActions from '../actions/OrganizationActions';
import '../css/UpgradePlanModal.scss';
import {
  mxTrackEvent,
} from '../utils/utils';

const redirectToPlanPage = (history) => {
  mxTrackEvent('Credits alert to billing page');
  localStorage.setItem('creditsAlertPopup', true);
  OrganizationActions.setCreditsAlertModalOpen(false);
  history.push('/settings/billing/plan');
};

const Button = withRouter(({ history }) => (
  <button
    type="button"
    onClick={() => { redirectToPlanPage(history); }}
  >
    Buy Now
  </button>
));

const NegativeAlertModal = () => {
  const { t } = useTranslation();
  const [isModalShow, setIsModalShow] = useState(false);

  const closeUpgradePlanModal = () => {
    OrganizationActions.setCreditsAlertModalOpen(false);
  };

  const onChange = (storeData) => {
    const { isCreditsAlertModalOpen } = storeData;
    if (isCreditsAlertModalOpen) {
      mxTrackEvent('Credits alert popup opened');
    }
    setIsModalShow(isCreditsAlertModalOpen);
  };

  useEffect(() => {
    if (isModalShow) {
      mxTrackEvent('Credits alert popup opened');
    }
  }, [isModalShow]);

  const handleClosePopup = () => {
    mxTrackEvent('Credits alert popup closed');
    localStorage.setItem('creditsAlertPopup', true);
    OrganizationActions.setCreditsAlertModalOpen(false);
  };

  useEffect(() => {
    OrganizationStore.listen(onChange);
  }, []);

  return (
    <React.Fragment>
      <Modal show={isModalShow} className="planModalContainer" onExit={handleClosePopup}>
        <Modal.Body className="paymentBody">
          <div className="imaageAndCancelContainer">
            <div>
              <img src={Image3} alt="planImage" className="imageSection" />
            </div>
            <button
              type="button"
              onClick={closeUpgradePlanModal}
            >
              <Glyphicon glyph="remove" />
            </button>
          </div>
          <div className="textSection">
            <div
              style={{
 textAlign: 'center', marginTop: '.3rem', fontWeight: '400', fontSize: '1.1em',
}}
            >
              {t('creditsAlertText.message')}
            </div>
            <Button closeModal={closeUpgradePlanModal} />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default NegativeAlertModal;
