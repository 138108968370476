/* eslint-disable*/
import React from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import Auth from '../auth/Auth';
import { mxTrackEvent } from '../utils/utils';
import NavigationBar from '../components/NavigationBar/NavigationBar';
import styles from '../css/TermsAcceptedScreen.module.scss'
import { ToastContainer } from 'react-toastify';
import { Col, Row, FormControl, FormGroup, Checkbox, ControlLabel, Button, HelpBlock } from 'react-bootstrap';
import SettingActions from '../actions/SettingActions';
import SettingStore from '../stores/SettingStore';
import OrganizationStore from '../stores/OrganizationStore';
import { INDUSTRY_LIST } from '../utils/constants';
import { successToast, alertToast } from '../components/Toast';
import LoadingComponent from '../components/LoadingComponent';

class TermsAcceptedScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // initial: true,
      companyName: '',
      email: '',
      password: '',
      emailValid: false,
      passwordValid: false, //eslint-disable-line
      isLoggingIn: false,
      loginFailure: false,
      loginFailureMessage: '',
      verifiedSuccess: false,
      verifiedSuccessMessage: '',
      ...SettingStore.getState(),
      ...OrganizationStore.getState(),
      selectedIndustry: '',
      isTermsAccepted: false,
      isLoading: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    // this.handleLogin = this.handleLogin.bind(this);
    this.handleIndustryChange = this.handleIndustryChange.bind(this);
    this.handleSignup = this.handleSignup.bind(this);
    this.checkboxOnClick = this.checkboxOnClick.bind(this);
    this.handleCompanyNameChange = this.handleCompanyNameChange.bind(this);
    this.onChange = this.onChange.bind(this);

  }



  componentWillMount() {
    const { history } = this.props;
    if (Auth.isAuthenticated()) {
    } else {
      Auth.login();
    }
  }

  componentDidMount() {
    SettingStore.listen(this.onChange);
    OrganizationStore.listen(this.onChange);
    mxTrackEvent('Terms and condition page loaded');
  }

  onChange(state) {
    this.setState(state);
  }

  handleIndustryChange(value) {
    // this.setState({ selectedIndustry: value['value']});
    SettingActions.inputChange({ name: 'industry', value: value })
    mxTrackEvent('Entered Industry name', {
      name: 'industry',
      value: value
    });
  }



  handleInputChange(e) {
    this.setState({ initial: false });
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    }, () => {
      this.validateField(name, value);
    });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.handleLogin();
    }
  }

  validateField(fieldName, value) {
    let { emailValid, passwordValid } = this.state;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/\S+@\S+\.\S+/i);
        break;
      case 'password':
        passwordValid = !!value;
        break;
      default:
        break;
    }

    this.setState({
      emailValid,
      passwordValid,
    });
  }



  // handleLogin() {
  //   const {
  //     email,
  //     password,
  //     emailValid,
  //     passwordValid,
  //   } = this.state;

  //   if (!emailValid || !passwordValid) {
  //     return false;
  //   }

  //   this.setState({
  //     verifiedSuccess: false,
  //     verifiedSuccessMessage: '',
  //   });

  //   mxTrackEvent('Logging in', {
  //     email,
  //   });
  //   this.setState({
  //     isLoggingIn: true,
  //   }, async () => {
  //     try {
  //       await Auth.signIn(email, password);
  //       mxTrackEvent('Login successful', {
  //         email,
  //       });
  //     } catch (e) {
  //       this.setState({
  //         loginFailure: true,
  //         loginFailureMessage: e.code === 'access_denied' ? e.description : 'Error logging in, please try again later',
  //         email: '',
  //         password: '',
  //       });
  //       mxTrackEvent('Login error', {
  //         email,
  //         err: e,
  //       });
  //     } finally {
  //       this.setState({
  //         isLoggingIn: false,
  //       });
  //     }
  //   });

  //   return true;
  // }

  async handleSignup() {
    

    try {
      const {
        profileData,
        companyLogo,
        companyLogoValid,
        selectedIndustry,
        companyName,
        companyDescription,
      } = this.state;
      mxTrackEvent('Save button click on terms and condition page.', {
        profileData, companyLogo, companyLogoValid, companyDescription,
      });
      const changeCareerSiteUrl = true;
      await SettingActions.saveProfile(
        profileData, companyLogo, companyLogoValid, companyDescription,changeCareerSiteUrl,
      );
      const {
        profileSaved,
        successMessage,
        errorMessage,
      } = this.state;

      if (profileSaved) {
        mxTrackEvent('Company details saved', {
          profileData, companyLogo, companyLogoValid, companyDescription,
        });
        successToast(successMessage);
        window.location.href = '/settings/billing';
        
      } else {
        alertToast(errorMessage);
      }
    } catch (e) {
      console.log(e);
    }
  }

  checkboxOnClick(e) {
    const { checked } = e.target;
    this.setState({ isTermsAccepted: checked });
    const {
      profileData,
      companyLogo,
      companyLogoValid,
      companyDescription,
    } = this.state;
    mxTrackEvent('Terms and conditions accepted', {
      profileData, companyLogo, companyLogoValid, companyDescription,
    });
  }

  handleCompanyNameChange(value) {
    this.setState({
      companyName: value
    })
  }



  render() {
    const {
      companyLogo,
      companyLogoValid,
      companyName,
      profileData,
      savingProfile,
      inputChanged,
      isTermsAccepted,
      isLoading,
    } = this.state;
    const validName = name => (name.length >= 1) && (name.length <= 50);
    const validCompanyName = profileData.companyName && validName(profileData.companyName);
    return (
      <React.Fragment>
        {
          isLoading ? (
            <div className={styles.loadContainer}>
            <LoadingComponent />
          </div>
          ) : (
            <div>
        <header className={`App-header ${styles.hide_navbar}`}>
          <NavigationBar />
        </header>
        <div className={styles.parentContainer}>
          <Row>
            <Col sm={2} md={2} lg={2}>
            </Col>
            <Col sm={8} md={8} lg={8}
            className="text-center">
              {/* fill the content here */}
              <Row className={`text-center ${styles.margin_bottom}`}>
                {/* <Col md={6} sm={6} lg={6}
                >
                  <Row > */}
                    <Col md={6} sm={6} lg={6}>
                      <label>Company name*</label>
                    </Col>
                    <Col md={6} sm={6} lg={6} className="text-center">
                    <FormGroup
                      controlId="CompanyName"
                      validationState={validCompanyName ? null: 'error'}
                    >
                      <FormControl
                        type="text"
                        name="companyName"
                        placeholder="Enter company name"
                        value={profileData.companyName}
                        onChange={e => SettingActions.inputChange(e.target)}
                        className={`settings__content--profile-tb ${styles.background_white}`}
                        // validationState={ profileData.companyName ? null : 'Please enter company name'}
                      />
                      {
                        !validCompanyName && (
                          <HelpBlock>
                            <FormControl.Feedback />
                            <small>Please enter company name.</small>
                          </HelpBlock>
                        )
                      }
                      
                      </FormGroup>
                    </Col>
                  </Row>


                {/* </Col>
              </Row> */}
              <Row className={`${styles.margin_bottom}`}>
              
                <Col md={6} sm={6} lg={6} className="text-center">
                {/* <FormGroup
                      controlId="industry"
                      validationState={profileData.industry ? null: 'error'}
                    > */}
                <label>Industry type*</label>
                {/* </FormGroup> */}
                </Col>
                <Col md={6} sm={6} lg={6} className="text-center">
                <FormGroup
                      controlId="industry"
                      validationState={profileData.industry ? null: 'error'}
                    > 
                  <Select
                    onChange={this.handleIndustryChange}
                    // onChange={e => SettingActions.inputChange({industry: e.target.value})}
                    value={profileData.industry}
                    options={INDUSTRY_LIST}
                  />
                  
                  {
                  !(profileData.industry) && (
                    <HelpBlock>
                      <FormControl.Feedback />
                    <small>Please select industry type.</small>
                  </HelpBlock>
                  )
                }
                </FormGroup>
                </Col>
                
                
                
              </Row>
              
              <Row className={`${styles.margin_bottom}`}>
              <FormGroup
                  controlId="CompanyLogo"
                  validationState={companyLogoValid || companyLogo ? null : 'error'}
                >
                <Col sm={6} lg={6} md={6}>
                <label>Company Logo</label>
                </Col>
                <Col sm={6} lg={6} md={6}>
                <FormControl
                    type="file"
                    name="companyLogo"
                    accept="image/*"
                    onChange={e => SettingActions.fileInputChange(e.target)}
                  />
                </Col>
                
                  
                  
                  {/* <FormControl.Feedback /> */}
                </FormGroup>
              </Row>
              <Row className={`text-center ${styles.margin_bottom}`}>
                <Col md={6} lg={6} sm={6} className="text-center">
                </Col>
                <Col md={6} lg={6} sm={6} className="text-center">
                  {
                    profileData.companyLogoPreviewUrl && (
                      <div className="imgPreview">
                        <img
                          src={profileData.companyLogoPreviewUrl}
                          alt="Company logo preview"
                          className="profile-company-logo-preview img-responsive"
                        />
                      </div>
                    )
                  }
                </Col>
              </Row>
              <Row className={`text-center ${styles.margin_bottom}`}>
                <FormGroup>
                  <Checkbox onClick={this.checkboxOnClick}>
                    I agree with the&nbsp;
                    <a
                      href="https://interviewer.ai/terms/customer/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="login-screen__content--terms-link"
                    >
                      Interviewer.AI Customer Terms of Use
                    </a>
                  </Checkbox>
                </FormGroup>
              </Row>
              <Row className={`text-center ${styles.margin_bottom}`}>
                <Button
                  type="button"
                  className={styles.saveButton}
                  onClick={this.handleSignup}
                  disabled={!(
                     validCompanyName && isTermsAccepted
                    && (inputChanged || companyLogo)) || !companyLogoValid || savingProfile}
                >
                  {/* <Glyphicon glyph="link" /> */}
                  
                  {
              savingProfile ? 'Saving...' : 'Save Profile'
            }
                </Button>
              </Row>
            </Col>
            <Col sm={2} md={2} lg={2}>
            </Col>
          </Row>

        </div>
        <ToastContainer autoClose={2000} />
      </div>
          )
        }
      </React.Fragment>
      
    );
  }
}

export default withRouter(TermsAcceptedScreen);
