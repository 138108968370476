import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as MenuIcon } from '../../../assets/Menu.svg';
import { ReactComponent as Interviews } from '../../../assets/Interviews.svg';
import { ReactComponent as Insights } from '../../../assets/Insights.svg';
import { ReactComponent as Talentpool } from '../../../assets/TalentPool.svg';
import { ReactComponent as Team } from '../../../assets/Teams.svg';
import ExpiryActionButton from '../../ExpiryActionButton';
import Brand from '../../../assets/Brand-Dark.png';
import '../../../css/NavigationBar/NavigationSlider/NavigationSlider.scss';

const NavigationSlider = (props) => {
  const { show, subscription } = props;
  const isDrawerOpen = show ? 'navigationSliderContainer openNavigationslider' : 'navigationSliderContainer';
  const [navigationSliderClass, toggleOpenClassHandler] = useState(isDrawerOpen);

  useEffect(() => {
    toggleOpenClassHandler(isDrawerOpen);
  }, [isDrawerOpen]);


  const hideNavigationDrawerHandler = () => {
    toggleOpenClassHandler('navigationSliderContainer');
    // props.openNavigationDrawerHandler();
  };

  return (
    <React.Fragment>
      {/* <div className={navigationSliderClass}> */}
      <div
        role="button"
        tabIndex={0}
        className={navigationSliderClass}
        onClick={() => { hideNavigationDrawerHandler(); }}
        onKeyDown={() => { hideNavigationDrawerHandler(); }}
      >

        <div className="brandContainer">
          <img
            src={Brand}
            alt="settings icon"
            className="sidebarBrandIcon"
          />
        </div>
        <div className="expiryActionNavButtonContainer">
          <ExpiryActionButton subscription={subscription} className="expiryActionNavButtonContainer" />
        </div>

        <div className="menuContainer">

          <a
            className={
              classnames('mobileNavLink')
            }
            // onClick={() => { setActiveNavItem('/overview') }}
            href="/overview"
          >
            <MenuIcon width="18" height="18" fill="#7A91AB" />
            <span>Overview</span>

          </a>
          {/* </div> */}

          <a
            className={classnames('mobileNavLink')
            }
            // onClick={() => { setActiveNavItem('/interviews') }}
            href="/interviews"
          >
            <Interviews width="20" height="20" fill="#7A91AB" />
            Interviews
          </a>

          <a
            className={classnames('mobileNavLink')
            }
            // onClick={() => { setActiveNavItem('/insights') }}
            href="/insights"
          >
            <Insights width="20" height="20" fill="#7A91AB" />
            Insights
          </a>

          <a
            className={classnames('mobileNavLink')
            }
            // onClick={() => { setActiveNavItem('/talent-pool') }}
            href="/talent-pool"
          >
            <Talentpool width="20" height="20" fill="#7A91AB" />
            Talent Pool
          </a>

          <a
            className={classnames('mobileNavLink')}
            // onClick={() => { setActiveNavItem('/organization/members') }}
            href="/organization/members"
          >
            <Team width="20" height="20" fill="#7A91AB" />
            Users
          </a>
        </div>

      </div>
      {/* </div> */}
    </React.Fragment>
  );
};

NavigationSlider.propTypes = {
  show: PropTypes.bool.isRequired,
  subscription: PropTypes.object.isRequired, // eslint-disable-line
};

export default NavigationSlider;
