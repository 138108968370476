import alt from '../alt';
import OrganizationActions from '../actions/OrganizationActions';

class OrganizationStore {
  constructor() {
    this.teamMembers = [];
    this.isFetchingMembers = false;
    this.isDeletingMember = false;
    this.isResendingInvite = false;
    this.isUpdatingMemberRole = false;
    this.companyName = '';
    this.companyLogoUrl = '';
    this.userRole = 'user';
    this.adminLimit = 1;
    this.memberLimit = 0;
    this.subscription = {};
    this.hasSubscription = false;
    this.subscriptionFetched = false;
    this.hasOrganization = false;
    this.fetchSubscriptionError = '';
    this.subscriptionLimits = {};
    this.removeBranding = false;
    this.planSelectedOnWebsite = null;
    this.planTypeSelectedOnWebsite = null;
    this.isUpgradeModalOpen = false;
    this.restricUpgradeModalOpen = false;
    this.organizationSignupDetails = {};
    this.overviewPageActionButtonsVisitedStatus = {
      hire: false,
    };
    this.feedbackParametersType = 'workmapFeedback';
    this.isSuperadmin = false;
    this.superadminOrgs = [];
    this.createNewOrganizationStart = false;
    this.isCreatingOrganisation = false;
    this.superAdminuserId = null;
    this.redirectionToOverview = false;
    this.showNotificationOnOverview = false;
    this.userNotifications = [];
    this.isUsersNotificationsLoading = true;
    this.userUnreadNotifications = 0;
    this.noUserNotifications = false;
    this.markingAllAsRead = false;
    this.userLanguage = '';
    // this.insightAccess = false;
    this.hasUserGivenFeedback = false;
    this.billingPlanCreditsValue = '--';
    this.organizationType = 'Default';
    this.paymentPending = false;
    this.hideEmploymentType = false;
    this.hideJobLocation = false;
    this.isCreditsAlertModalOpen = false;
    this.isOnBoardingModal = false;
    this.creditsBalance = null;
    this.defaultLangModal = false;
    this.userGeoLocation = {};
    this.enableFeatures = false;
    this.bindListeners({
      handleUserLanguage: OrganizationActions.SET_USER_LANGUAGE,
      handleMarkingAllAsRead: OrganizationActions.SET_MARKING_ALL_AS_READ,
      handleUserNotificationsLoading: OrganizationActions.SET_IS_USER_NOTIFICATIONS_LOADING,
      handleNotifications: OrganizationActions.SET_NOTIFICATIONS,
      handleShowNotificationOnOverview: OrganizationActions.SET_SHOW_NOTIFICATION_ON_OVERVIEW,
      handleChangeCompanyname: OrganizationActions.CHANGE_COMPANYNAME,
      handleFetchTeamMembersStart: OrganizationActions.FETCH_TEAM_MEMBERS_START,
      handleFetchTeamMembersSuccess: OrganizationActions.FETCH_TEAM_MEMBERS_SUCCESS,
      handleFetchTeamMembersFailed: OrganizationActions.FETCH_TEAM_MEMBERS_FAILED,
      handleFetchOrganizationStart: OrganizationActions.FETCH_ORGANIZATION_START,
      handleFetchOrganizationSuccess: OrganizationActions.FETCH_ORGANIZATION_SUCCESS,
      handleFetchOrganizationFailed: OrganizationActions.FETCH_ORGANIZATION_FAILED,
      handleFetchLimitSuccess: OrganizationActions.FETCH_LIMIT_SUCCESS,
      handleDeleteTeamMembersStart: OrganizationActions.DELETE_MEMBER_START,
      handleDeleteTeamMembersSuccess: OrganizationActions.DELETE_MEMBER_SUCCESS,
      handleDeleteTeamMembersFailed: OrganizationActions.DELETE_MEMBER_FAILED,
      handleUpdateMemberRoleStart: OrganizationActions.UPDATE_MEMBER_ROLE_START,
      handleUpdateMemberRoleSuccess: OrganizationActions.UPDATE_MEMBER_ROLE_SUCCESS,
      handleUpdateMemberRoleFailed: OrganizationActions.UPDATE_MEMBER_ROLE_FAILED,
      handleResendInviteStart: OrganizationActions.RESEND_INVITE_START,
      handleResendInviteSuccess: OrganizationActions.RESEND_INVITE_SUCCESS,
      handleResendInviteFailed: OrganizationActions.RESEND_INVITE_FAILED,
      handleSetUserRole: OrganizationActions.SET_USER_ROLE,
      handleFetchSubscriptionStart: OrganizationActions.FETCH_SUBSCRIPTION_START,
      handleFetchSubscriptionSuccess: OrganizationActions.FETCH_SUBSCRIPTION_SUCCESS,
      handleFetchSubscriptionFailed: OrganizationActions.FETCH_SUBSCRIPTION_FAILED,
      handleSetPlanSelectedOnWebsite: OrganizationActions.SET_PLAN_SELECTED_ON_WEBSITE,
      handleSetPlanTypeSelectedOnWebsite: OrganizationActions.SET_PLAN_TYPE_SELECTED_ON_WEBSITE,
      handleSetUpgradeModalOpen: OrganizationActions.SET_UPGRADE_MODAL_OPEN,
      handleSetUpgradeModalClose: OrganizationActions.SET_UPGRADE_MODAL_CLOSE,
      handleSetOrganizationDetails: OrganizationActions.SET_ORGANIZATION_DETAILS,
      handleOverviewActionButtonsVisitedStatus:
        OrganizationActions.SET_OVERVIEW_ACTION_BUTTONS_VISITED_STATUS,
      handleRedirectionToOverview: OrganizationActions.SET_REDIRECTION_TO_OVERVIEW,
      // handleCreateNewOrganizationStart: OrganizationActions.CREATE_NEW_ORGANIZATION_START,
      handleCreateNewOrganizationStart: OrganizationActions.SET_CREATE_ORGANISATION,
      // handleSetInsightAccess: OrganizationActions.SET_INSIGHT_ACCESS,
      handleSetHasUserGivenFeedback: OrganizationActions.SET_HAS_USER_GIVEN_FEEDBACK,
      handleChangeBillingPlanCreditsValue: OrganizationActions
        .CHANGE_BILLING_PLAN_CREDITS_VALUE,
      handleToggleHideEmploymentType: OrganizationActions.TOGGLE_HIDE_EMPLOYMENT_TYPE,
      handleToggleHideLocation: OrganizationActions.TOGGLE_HIDE_LOCATION,
      handleSetCreditsAlertModalOpen: OrganizationActions.SET_CREDITS_ALERT_MODAL_OPEN,
      handleSetCreditsDetails: OrganizationActions.SET_CREDITS_DETAILS,
      handleSetOnBoardingModal: OrganizationActions.SET_ON_BOARDING_MODAL,
      handleSetLangDefaultModal: OrganizationActions.SET_LANG_DEFAULT_MODAL,
      handleUpdateUserGeoLocation: OrganizationActions.UPDATE_USER_GEO_LOCATION,
    });
  }

  handleSetCreditsAlertModalOpen = (value) => {
    this.isCreditsAlertModalOpen = value;
  }

  handleUserLanguage = (lang) => {
    this.userLanguage = lang;
  }

  handleMarkingAllAsRead = (value) => {
    this.markingAllAsRead = value;
  }

  handleUserNotificationsLoading = (value) => {
    this.isUsersNotificationsLoading = value;
  }

  handleNotifications = (data) => {
    this.userNotifications = data.allNotifs;
    this.userUnreadNotifications = data.unreadNotifsCount;
    this.noUserNotifications = data.allNotifs.length === 0;
  }

  handleShowNotificationOnOverview = (value) => {
    this.showNotificationOnOverview = value;
  }

  handleChangeCompanyname(value) {
    this.companyName = value;
  }

  handleRedirectionToOverview = (value) => {
    this.redirectionToOverview = value;
  }

  handleCreateNewOrganizationStart = (value) => {
    this.isCreatingOrganisation = value;
  }

  handleFetchSubscriptionStart = () => {

  }

  handleFetchSubscriptionFailed = (error) => {
    this.fetchSubscriptionError = error;
    this.subscriptionFetched = true;
  }

  handleFetchSubscriptionSuccess = (data) => {
    const { limits, ...subscription } = data;
    this.subscription = subscription;
    this.subscriptionLimits = limits;
    this.subscriptionFetched = true;
    this.hasSubscription = !data.isLocked;
    this.paymentPending = data.paymentPending;
  }

  handleFetchOrganizationStart = () => {

  }

  handleFetchOrganizationSuccess = (data) => {
    const {
      company_name: companyName,
      company_logo_url: companyLogoUrl,
      remove_branding: removeBranding,
      feedbackParametersType,
      isSuperadmin,
      superadminOrgs,
      superAdminuserId,
      org_type: orgType,
      hide_employment_type: hideEmploymentType,
      hide_job_location: hideJobLocation,
      enable_features: enableFeatures,
    } = data;
    this.enableFeatures = enableFeatures;
    this.organizationType = orgType;
    this.companyName = companyName;
    this.companyLogoUrl = companyLogoUrl;
    this.hasOrganization = true;
    this.removeBranding = removeBranding;
    this.feedbackParametersType = feedbackParametersType;
    this.isSuperadmin = isSuperadmin;
    this.superAdminuserId = superAdminuserId;
    this.hideEmploymentType = hideEmploymentType;
    this.hideJobLocation = hideJobLocation;
    if (this.isSuperadmin) {
      this.superadminOrgs = superadminOrgs;
    }
  }

  handleFetchOrganizationFailed = () => {

  }

  handleUpdateMemberRoleStart() {
    this.isUpdatingMemberRole = true;
  }

  handleCreateNewOrganizationStart() {
    this.createNewOrganizationStart = true;
  }

  handleUpdateMemberRoleSuccess({ email, role }) {
    const userIndex = this.teamMembers.findIndex(m => m.email === email);
    if (userIndex !== -1) {
      this.teamMembers[userIndex].role = role;
    }
    this.isUpdatingMemberRole = false;
  }

  handleUpdateMemberRoleFailed() {
    this.isUpdatingMemberRole = false;
  }

  handleFetchTeamMembersStart() {
    this.isFetchingMembers = true;
  }

  handleFetchTeamMembersFailed() {
    this.isFetchingMembers = false;
  }

  handleFetchTeamMembersSuccess(data) {
    console.log('coming');
    this.teamMembers = data.map((member) => {
      if (member.status === null) {
        return {
          ...member,
          status: 'ACTIVE',
        };
      }

      return member;
    });
    this.isFetchingMembers = false;
  }

  handleFetchLimitSuccess(limit) {
    this.adminLimit = limit.admin_count;
    this.memberLimit = limit.admin_count + limit.user_count;
  }

  handleDeleteTeamMembersStart() {
    this.isDeletingMember = true;
  }

  handleDeleteTeamMembersFailed() {
    this.isDeletingMember = false;
  }

  handleDeleteTeamMembersSuccess(email) {
    this.teamMembers = this.teamMembers.filter(m => m.email !== email);
    this.isDeletingMember = false;
  }

  handleResendInviteStart() {
    this.isResendingInvite = true;
  }

  handleResendInviteFailed() {
    this.isResendingInvite = false;
  }

  handleResendInviteSuccess() {
    this.isResendingInvite = false;
  }

  handleSetUserRole(role) {
    this.userRole = role;
  }

  handleSetPlanSelectedOnWebsite(plan) {
    this.planSelectedOnWebsite = plan;
  }

  handleSetPlanTypeSelectedOnWebsite(planType) {
    this.planTypeSelectedOnWebsite = planType;
  }

  handleSetUpgradeModalOpen(value) {
    this.isUpgradeModalOpen = value;
    this.restricUpgradeModalOpen = true;
  }

  handleSetUpgradeModalClose(value) {
    this.isUpgradeModalOpen = !value;
  }

  handleSetOrganizationDetails(details) {
    this.organizationSignupDetails = details;
  }

  handleOverviewActionButtonsVisitedStatus(data) {
    const { name, value } = data;
    this.overviewPageActionButtonsVisitedStatus[name] = value;
  }

  // handleSetInsightAccess(flag) {
  //   this.insightAccess = flag;
  // }

  handleSetHasUserGivenFeedback(value) {
    this.hasUserGivenFeedback = value;
  }

  handleChangeBillingPlanCreditsValue(value) {
    this.billingPlanCreditsValue = value;
  }

  handleToggleHideEmploymentType(value) {
    this.hideEmploymentType = value;
  }

  handleToggleHideLocation(value) {
    this.hideJobLocation = value;
  }

  handleSetCreditsDetails(data) {
    const { interviewsWithResponsesCounts, assessmentsLimit } = data;
    const respondedCandidates = interviewsWithResponsesCounts
      .map(interview => (interview.responded ? parseInt(interview.responded, 10) : 0));
    const totalRespondedCandidate = respondedCandidates.reduce((sum, element) => sum + element, 0);
    this.creditsBalance = totalRespondedCandidate;
    this.creditsBalance = assessmentsLimit - totalRespondedCandidate;
  }

  handleSetOnBoardingModal(value) {
    this.isOnBoardingModal = value;
  }

  handleSetLangDefaultModal(value) {
    this.defaultLangModal = value;
  }

  handleUpdateUserGeoLocation(value) {
    this.userGeoLocation = value;
  }
}

export default alt.createStore(OrganizationStore, 'OrganizationStore');
