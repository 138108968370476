import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styles from '../css/HelpModal.module.scss';

const HelpModal = (props) => {
  const { t } = useTranslation();
  const { handleModalClose, modalOpen } = props;
  return (
    <Modal show={modalOpen} onHide={handleModalClose} bsSize="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {t('profileSlider.videoTutorial')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.helpModalSize}>
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/7rBhBGHEV0U?rel=0&enablejsapi=1?&autoplay=1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

HelpModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  handleModalClose: PropTypes.func.isRequired,
  videoInfo: PropTypes.shape({
    timeStart: PropTypes.number,
    timeEnd: PropTypes.number,
    title: PropTypes.string,
    languageKey: PropTypes.string,
  }).isRequired,
};

export default HelpModal;
