import Auth from '../auth/Auth';

const integrations = {
  async addKeys(apiKey, apiSecret, service) {
    try {
      const req = await fetch(`/api/settings/integrations/${service}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({
          apiKey,
          apiSecret,
        }),
      });
      return req.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  // eslint-disable-next-line consistent-return,class-methods-use-this
  async getKeys() {
    try {
      const req = await fetch('/api/settings/integrations/user/me', {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
      });
      if (req.status === 200) {
        return await req.json();
      }
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  // eslint-disable-next-line class-methods-use-this
  async removeKey(service) {
    try {
      const req = await fetch(`/api/settings/integrations/${service}`, {
        headers: {
          Authorization: `Bearer ${Auth.getIdToken()}`,
          'Content-Type': 'application/json',
        },
        method: 'DELETE',
      });
      return req.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
};

export default integrations;
