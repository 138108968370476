export default {
  cancel: 'Cancelar',
  save: 'Guardar',
  back: 'Volver',
  create: 'Crear',
  edit: 'Editar',
  delete: 'Borrar',
  submit: 'Enviar',
  submitting: 'Enviando...',
  confirm: 'Confirmar',
  share: 'Compartir',
  marking: 'Marcado...',
  markAllAsRead: 'Marcar todo como leído',
  adding: 'Añadiendo...',
  error: 'Error',
  clear: 'Borrar',
  all: 'Todo',
  navbarSection: {
    overviewTab: 'Visión general',
    interviewsTab: 'Entrevistas',
    insights: 'Perspectivas',
    talentpoolTab: 'Grupo de talentos',
    applicantpoolTab: 'Grupo de solicitantes',
    usersTab: 'Usuarios',
    buyCredits: 'Comprar créditos',
    'Upgrade Plan': 'Plan de actualización',
    viewAll: 'Ver todo',
    notifications: 'Notificaciones',
    help: 'Ayuda',
    notificationInfo1: 'No tiene notificaciones nuevas',
    today: 'Hoy',
    yesterday: 'Ayer',
    english: 'English',
    vietnamese: 'Vietnamese',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
  },
  overviewPage: {
    messageText1: '¡Bienvenido! Hazte cargo de tu página de empleo y construye tu presencia.',
    messageText2: 'Puede vincular su página de carrera a su sitio web.',
    messageButton: 'Reclama tu página de carrera',
    surveyText: '¡Bienvenido! Ayúdanos a entender mejor la plataforma. Nuestra encuesta debería llevarle menos de 30 segundos. ¡Nos encantaría saber de usted!',
    thankyouSurveyText: 'Gracias por tus comentarios.',
    getStarted: 'Empezar',
    totalInterviews: 'Entrevistas totales',
    totalApplicants: 'Total de solicitantes',
    totalVideoResponses: 'Total de respuestas en vídeo',
    viewMoreInsights: 'Ver más estadísticas',
    weeklyOverall: 'Total semanal',
    createInterview: '+ Crear entrevista',
    text1: 'Únase a Pulse of Hiring en LinkedIn para obtener información y temas sobre el mundo del trabajo y la tecnología.',
    text2: 'Suscribir',
    text3: 'gratis por 15 días.',
    jobInterview: 'Entrevista de trabajo',
    sourcingFunnel: 'Canal de contratación',
    applicantFunnel: 'Embudo de solicitantes',
    totalCandidates: 'Total de candidatos',
    yetToRespond: 'Pendientes de respuesta',
    responded: 'Respondido',
    rated: 'Calificados',
    newResponses: 'Nuevas respuestas',
    shortlisted: 'Preseleccionado',
    hello: 'Hola',
    welcome: 'Bienvenido',
    recentResponses: 'Respuestas recientes',
    noRecentResponses: 'No hay respuestas recientes',
    applications: 'Solicitud',
    videoResponses: 'Respuestas en vídeo',
    createFirstInterview: 'Cree su primera entrevista',
    addCompanyLogo: 'Añadir logotipo de la empresa',
    viewSampleProfile: 'Ver una muestra de un perfil',
    addTeamMember: 'Añadir miembros del equipo',
    instruction1: 'No te pierdas nuestras últimas y actualizadas',
    instruction2: 'Experiencia de notificaciones',
  },
  interviewListing: {
    archieveInterview: 'Archivar entrevista',
    deleteInterview: 'Eliminar entrevista',
    duplicateInterview: 'Entrevista duplicada',
    editInterview: 'Editar entrevista',
    manageApplicants: 'Administrar solicitantes',
    archiveInterview: 'Archivo Entrevista',
    interviewOwner: 'Propietario de la entrevista',
    searchInterview: 'Buscar entrevista',
    details: 'Detalles',
    created: 'Creado',
    expiry: 'Expiración',
    candidates: 'Candidatos',
    applicants: 'Solicitantes',
    responses: 'Respuestas',
    analyzed: 'Analizado',
    shortlisted: 'Preseleccionado',
    createdBy: 'Creado por',
    status: 'Estado',
    share: 'Compartir',
    filter: 'Filtrar',
    newJobInterview: 'Nueva entrevista de trabajo',
    newInterview: 'Nueva entrevista',
    manageCandidates: 'Gestionar candidatos',
    assignTo: 'Asignar a',
    changeBaground: 'Cambiar fondo',
    editJobInterview: 'Editar entrevista de trabajo',
    duplicateJobInterview: 'Duplicar entrevista de trabajo',
    deleteJobInterview: 'Eliminar entrevista de trabajo',
    archieveJobInterview: 'Archivar entrevista de trabajo',
    downloadQRCode: 'Descargar código QR',
    results: 'Resultados',
    jobOwner: 'Propietario del trabajo',
    contributor: 'Colaborador',
    randomiseInfo1: 'Seleccionar las preguntas para aleatorizar',
    youHaveSelected: 'Ha seleccionado',
    questions: 'Preguntas',
    interviewStatus: 'Estado de la entrevista',
    filterForInterview: 'Filtro para la entrevista',
    pleaseSelect: '-- Seleccione --',
    expiryMessage: 'Esta entrevista expirará inmediatamente y los candidatos ya no podrán responder a la entrevista.',
    sureMessage: '¿Está seguro de archivar la entrevista?',
    archieving: 'Archivando...',
  },
  userList: {
    team: 'Equipo',
    inviteNewUsers: 'Invitar a nuevos usuarios',
    emailAddresses: 'Direcciones de correo electrónico',
    organizationMembers: 'Miembros de la organización',
    addEmailAddress: 'Añada direcciones de correo electrónico aquí',
    addEmail: 'Pulse la tecla "entrar" o "tabulador" para añadir direcciones de correo electrónico',
    cancel: 'Cancelar',
    share: 'Compartir',
    emails: 'correos electrónicos',
    sentInvitesTo: 'Invitaciones enviadas correctamente a',
    inviteError: 'Error al enviar invitaciones, inténtelo más tarde',
    confirm: 'Confirmar',
    adminUserMessage: 'Los administradores pueden gestionar los usuarios y todas las entrevistas creadas dentro de la organización',
    inOrganization: '¿En su organización?',
    areSureYouWantToAssignPermissionTo: '¿Está seguro de que desea asignar permisos administrativos a',
    toAdmin: 'para admin',
    upgrade: 'Actualizar',
    resendInvitation: '¿Está seguro de que desea reenviar una invitación a',
    fromOrganization: 'desde su organización?',
    resendInviteTo: 'desde su organización?',
    eraseAccessMessage: 'desde su organización? Esto borrará la cuenta y el acceso desde nuestra plataforma.',
    irreversibleMessage: '¡Esta acción es irreversible!',
    removeUserMessage: 'Está seguro de que desea eliminar',
    remove: 'Eliminar',
    noMemberFound: 'No se han encontrado miembros. Invite por correo electrónico a nuevos miembros a unirse a su organización',
    joined: 'Se ha unido a',
    status: 'Estado',
    email: 'Correo electrónico',
    role: 'Función',
    members: 'Miembros',
    removeUser: 'Remover usuario',
    resendInvite: 'Reenviar invitación',
    upgradeToAdmin: 'Actualizar a Admin',
    exceededUserAdditionMessage: 'Se ha superado el número de cuentas admin en su organización.',
    membersContactUsPageProps: {
      contactUsMessage: 'La gestión de equipos y la invitación de miembros es una característica ampliada. Por favor contáctenos para más detalles. ¡O haga clic en el botón a continuación para que nos comuniquemos con usted por correo electrónico!',
      contactUsTitle: 'Equipo',
      contactUsFeature: 'Función de gestión de organización',
    },
    searchMember: 'Buscar por nombre o correo electrónico',
    inviteMember: 'Invitar a nuevos usuarios',
    memberLimitExceededMessage: 'Ha superado el límite de miembros de su organización',
    incorrectEmailMessage: 'Ha introducido una dirección de correo electrónico incorrecta',
    sameCompanyDomainMessage: 'Introduzca la(s) dirección(es) de correo electrónico del mismo dominio de la empresa',
    hasAlreadyInvited: 'ya ha sido invitado',
  },
  planDetails: {
    planEntitlements: 'Beneficios del Plan',
    superAdmin: 'Super Admin',
    admin: 'Admin',
    user: 'Usuario',
    contributors: 'Contribuyentes',
    yourPlan: 'Su Plan',

  },
  newInterview: {
    reportSend: 'envía informes a los solicitantes sobre la finalización de la evaluación',
    cancel: 'Cancelar',
    createNewInterview: 'Crear nueva entrevista',
    createInterview: 'Crear entrevista',
    creatingInterview: 'Creando entrevista...',
    updateInterview: 'Actualizar entrevista',
    updatingInterview: 'Actualizando entrevista...',
    newInterview: 'Nueva entrevista',
    basicInfo: 'Información básica',
    instituteLogo: 'Logotipo del Instituto',
    addInstituteLogo: 'Agregar logotipo del instituto',
    instLogoRequired: 'Institute Logo is required',
    instituteName: 'nombre del Instituto',
    instituteNameRequired: 'El nombre del instituto es obligatorio',
    departmentTitle: 'Título del departamento',
    departmentTitleRequired: 'Se requiere el título del departamento',
    chooseFunction: 'Elige una función',
    createFunction: 'Crear función personalizada',
    functionName: 'Nombre de la función',
    interviewExpiryDate: 'Fecha de vencimiento de la entrevista',
    interviewDescription: 'Descripción de la entrevista',
    applicantRetakeText: '¿Puede el solicitante volver a realizar toda la entrevista varias veces?',
    applicantResumeText: '¿Se requiere puntuación del currículum cuando el solicitante presenta la solicitud?',
    applicantResponseText: 'Idioma de respuesta esperado del solicitante',
    workmapErrorMessage: '(Seleccione al menos una habilidad)',
    enableSocialMediaText: 'Elija solicitar perfiles de redes sociales a los candidatos',
    selectQuestions: 'Seleccionar preguntas',
    selectTwoQuestions: 'Por favor seleccione al menos 2 preguntas.',
    selectAllQuestions: 'Seleccionar todas las preguntas',
    updateJobInterview: 'Actualizar entrevista de trabajo',
    createJobInterview: 'Crear entrevista de trabajo',
    newJobInterview: 'Nueva entrevista de trabajo',
    workmapAssessment: 'Evaluación del mapa de trabajo',
    addIntroQuestions: 'Preguntas adicionales del formulario',
    addVideoQuestions: 'Añadir preguntas de vídeo',
    editYourQuestion: 'Editar pregunta de formulario adicional',
    editingYourQuestion: 'Editando...',
    noIntroCategories: 'Aún no se han creado categorías...',
    interviewSettings: 'Configuración de la entrevista',
    basicJobInfo: 'Información básica del puesto de trabajo',
    qualifierQuestion: 'Pregunta calificadora',
    identityFraudToggleText: '¿Se requiere verificación de identidad?',
    qualifierQuestionErrorMessage: 'La pregunta calificadora es obligatoria si está habilitada.',
    addLogo: 'Añadir logotipo',
    companyLogo: 'Logotipo de la empresa',
    addCompanyLogo: 'Agregar logotipo de la empresa',
    logoChangeInstruction: 'Haga clic en el logotipo para cambiarlo o añadirlo',
    logoChangeInstruction2: 'máx. 500kB',
    companyLogoRequired: 'El logotipo de la empresa es obligatorio',
    companyName: 'Nombre de la empresa',
    companyNameRequired: 'El nombre de la empresa es obligatorio.',
    jobTitleInstruction1: 'Título de la entrevista',
    jobTitleInstruction2: 'Se requiere el nombre de la entrevista.',
    jobFunction: 'Puesto de trabajo',
    chooseJobFunction: 'Elija un puesto de trabajo',
    createCustomJobFunction: 'Crear puesto de trabajo personalizado',
    questions: 'Preguntas',
    jobFunctionInstruction: {
      instruction1: 'Nombre de la función laboral*',
      instruction2: 'Se requiere el nombre de la función del trabajo.',
    },
    updatingJobInterview: 'Actualizando entrevista de trabajo...',
    workmapSkills: {
      instruction1: 'Seleccionar habilidades',
      instruction2: 'La evaluación del mapa de trabajo utiliza la escala Likert. Una escala Likert se utiliza comúnmente para medir actitudes, conocimientos, percepciones, valores y cambios de comportamiento. Una escala tipo Likert implica una serie de afirmaciones entre las que los encuestados pueden elegir para calificar sus respuestas a preguntas evaluativas (Vogt, 1999).',
      instruction3: 'Se requieren habilidades.',
      instruction4: 'Elija Múltiple:',
      relevantSkills: 'Habilidades relevantes',
      instruction5: 'Lista de habilidades que se probarán en la evaluación del mapa de trabajo de Interviewer.AI. La Evaluación WorkMap es una herramienta psicométrica diseñada para medir habilidades y competencias que están vinculadas a factores de éxito de trabajos específicos.',
      instruction6: 'Elija Múltiple',
      workmapAssessmentEnabled: 'Evaluación del mapa de trabajo activada',
      instruction7: 'Escala Likert',

    },
    creating: 'Creando',
    create: 'Crear',
    interviewStartDate: 'Fecha de inicio de la entrevista',
    jobExpiry: 'Fecha de vencimiento del trabajo',
    expirydateRequired: 'Se requiere fecha de vencimiento.',
    jobLocationInstruction: {
      jobLocation: 'Ubicación del trabajo',
      instruction1: 'Se requiere país.',
      instruction2: 'Se requiere ciudad.',
    },
    jobVisibility: {
      jobVisibilityInstruction: 'Visibilidad de la oferta de trabajo',
      jobVisibilityInstruction1: 'La visibilidad de la oferta de trabajo determina qué ofertas de trabajo aparecen en su página de empleo.',
      jobVisibilityInstruction2: 'El trabajo es',

    },
    private: 'privado',
    public: 'público',
    qrCodeInstructions: {
      qrCodeInstructions1: 'Visibilidad del código QR',
      qrCodeInstructions2: 'Visibilidad del código QR, muestra el código QR en las ofertas de empleo',
      qrCodeInstructions3: 'Código QR',
    },
    enabled: 'Activado',
    disabled: 'Desactivado',
    roleType: 'Tipo de función',
    roleTypeRequired: 'El tipo de función es obligatorio.',
    uniqueCandidatesLabel: 'Etiqueta de identificación única del candidato',
    uniqueCandidatesLabel2: 'Este puede ser cualquier valor único que haya asignado al candidato para identificarlo.',
    uniqueCandidatesLabel3: '1 - El valor especificado se mostrará en el formulario de solicitud como una etiqueta con campo de entrada.',
    uniqueCandidatesLabel4: '2 - Le recomendamos que no especifique ningún valor en este campo si aún no dispone de identificadores únicos o no los ha proporcionado a los candidatos, ya que este campo es obligatorio para ellos.',
    candidateCoachingId: 'P. ej. Número de asiento / Número de candidato / Número de lista',
    jobDescription: {
      jobDescription: 'Descripción del puesto',
      jobDescriptionInstruction1: 'Se requiere descripción.',
      jobDescriptionInstruction2: 'Enviar informes a los candidatos al finalizar la evaluación',
      jobDescriptionInstruction3: 'Si activa esta opción, podrá enviar informes a los candidatos individualmente después de que hayan completado la entrevista en vídeo.',
    },
    moreOptions: 'Más opciones',
    resumeInstructions: {
      resumeInstructions1: '¿Es necesario el currículo cuando los candidatos se presentan?',
      resumeInstructions2: 'Hacer que la URL del perfil de LinkedIn sea obligatoria',
      resumeInstructions3: '¿Puede el candidato repetir la entrevista varias veces?',
      resumeInstructions4: 'Hacer que la URL del perfil de Facebook sea obligatoria',
      resumeInstructions5: 'Hacer que la URL del perfil de Instagram sea obligatoria',
      resumeInstructions6: 'Hacer obligatoria la respuesta ideal',
      resumeInstructions7: 'Interruptor de Puntuación de Relevancia de Contenido',

    },
    showLinkedin: '{{option}} Espacio para la URL de LinkedIn',
    showFacebook: '{{option}} Espacio para la URL de Facebook',
    showInstagram: '{{option}} Espacio para la URL de Instagram',
    videoScoreInstruction: '¿Desea establecer una puntuación mínima del vídeo para volver a realizar la entrevista??',
    thresholdInstruction: 'El valor umbral debe ser superior a 20',
    thresholdInstruction1: 'Restringir a los candidatos el acceso más allá del formulario de la solicitud',
    thresholdInstruction2: 'Tenga en cuenta que al activar esta opción, los candidatos no podrán continuar más allá del formulario de solicitud. Para continuar con el proceso, los candidatos deberán recibir una invitación de su parte.',
    overwriteInterviewCompanyName: 'Sobrescribir el nombre de la empresa de la entrevista',
    overwriteCompanyName: 'Sobrescribir nombre de la empresa',
    customiseInterviewLogo: 'Personalizar el logotipo de la entrevista (JPG, JPEG, PNG)',
    redirectURL: 'URL de redireccionamiento',
    redirectUrlInstructions: 'Esto establece la dirección URL a la que se redirigirá al candidato cuando finalice la entrevista en vídeo, p. ej.: La página de LinkedIn de su empresa. La URL debe ir precedida del protocolo http o https.',
    manualRatingParameter: 'Parámetros de clasificación manual',
    instruction5: 'Presione Enter o Tab para agregar un parámetro de calificación separado',
    instruction6: 'Idioma de respuesta esperado del candidato (Beta)',
    instruction7: 'Esta configuración establece el idioma esperado de la respuesta del candidato y realiza la conversión de voz a texto en consecuencia. Tenga en cuenta que algunas puntuaciones (por ejemplo, comunicación) se desactivarán para idiomas distintos del inglés.',
    english: 'Inglés',
    mandarin: 'Mandarin',
    polish: 'Polaco',
    spanish: 'Español',
    bahasa: 'Bahasa (indonesio)',
    french: 'Francés (Francia)',
    vietnamese: 'vietnamita',
    german: 'Alemán',
    country: 'País',
    city: 'Ciudad',
    cognitiveAssessment: 'Evaluación Cognitiva',
    selectTime: 'Seleccionar hora',
    categoryName: 'Nombre de la categoría',
    categoryDescription: 'Descripción de la categoría',
    enterCtaegoryDescription: 'Introduzca la descripción de la categoría',
    createCategory: 'Crear Categoría',
    categoryNameInstruction: 'Nombre para este grupo de preguntas',
    listMore: 'Listar más +',
    listLess: 'Listar Menos -',
    expired: 'Expirado',
    copyJobLink: ' Copiar enlace del trabajo',
    required: 'Requerido',
    'On-site': 'Presencial', // Purposely kept key like this
    Remote: 'A distancia', // Purposely kept key like this
    Hybrid: 'Híbrido', // Purposely kept key like this
    'Part Time': 'A tiempo parcial', // Purposely kept key like this
    'Full Time': 'A tiempo completo', // Purposely kept key like this
    Internship: 'Prácticas externas', // Purposely kept key like this
    Contract: 'Contrato', // Purposely kept key like this
    Training: 'Formación', // Purposely kept key like this
    'Mock Interview': 'Simulacro de entrevista', // Purposely kept key like this
  },
  introQuestions: {
    introQuestionsTitle: 'Preguntas adicionales del formulario',
    addQuestions: 'Añadir preguntas',
    instruction1: 'Los candidatos pueden seleccionar múltiples opciones.',
    instruction2: 'Los candidatos pueden seleccionar solo una opción.',
    edit: 'Editar',
    question: 'Pregunta',
    instruction3: 'Habilitar para elegir más de 1 opción',
    notNow: 'Ahora no',
    addYourQuestion: 'Añade tu pregunta',
    randomize: 'Aleatorizar',
    selectTime: 'Seleccionar hora',
    selectCategory: 'selecciona una categoría',
    videoQuestionBank: 'Banco de preguntas en vídeo',
    introQuestionBank: 'Banco de preguntas de introducción',
  },
  editInterview: {
    editInterviewTemplate: 'Editar plantilla de entrevista',
    interviewExpiry: 'Expiración de la entrevista',
    modifyQuestions: 'Modifique sus preguntas y elija hasta',
    withLimit: 'con límite de',
  },
  profileSlider: {
    logout: 'Cerrar sesión',
    userProfile: 'Perfil del usuario',
    companyProfile: 'Perfil de la empresa',
    instituteProfile: 'Perfil del Instituto',
    billing: 'Facturación',
    integrations: 'Integración',
    notificationSettings: 'Configuración de las notificaciones',
    promoCode: 'Código promocional',
    contactSupport: 'Contactar con Soporte',
    shareFeedback: 'Compartir comentarios',
    changePassword: 'Cambiar contraseña',
    termsAndConditions: 'Términos y condiciones',
    help: 'Ayuda',
    videoTutorial: 'Tutoriales en vídeo',
    howToCreateAnInterview: 'Cómo crear una entrevista',
    howToAddQuestionsToAnInterview: 'Cómo añadir preguntas a una entrevista',
    howToInviteCandidates: 'Cómo invitar candidatos',
    howToShareCandidates: 'Cómo compartir candidatoss',
    aFeelOfTheDashboard: 'Un vistazo al panel de control',
    reCalibrateAIMatrics: 'Recalibrar sus métricas de IA',
    knowMoreAboutTalentPool: 'Conozca más sobre el pool de talentos',
    addTeamMember: 'Agregar miembros del equipo',
  },
  userProfile: {
    back: 'Atrás',
    editProfile: 'Editar perfil',
    profileImage: 'Imagen del perfil',
    upload: 'Subir',
    maxImgSize: 'Tamaño máximo de imagen 500 x 500*',
    personalInfo: 'Información personal',
    firstName: 'Nombres',
    lastName: 'Apellidos',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    save: 'Ahorrar',
    saving: 'Guardando...',
    placeHolderFirstName: 'Introducir nombre',
    placeHolderLastName: 'Introducir apellido',
  },
  companyProfile: {
    back: 'Atrás',
    myCompany: 'Mi empresa',
    myInstitute: 'mi instituto',
    basicInformation: 'Información básica',
    companyLogo: 'Logo de la compañía',
    instituteLogo: 'Logotipo del Instituto',
    upload: 'Subir',
    businessInformation: 'Información de la empresa',
    companyName: 'nombre de empresa',
    instituteName: 'nombre del Instituto',
    companyNamePlaceholder: 'Introduzca el nombre de la empresa',
    yourCareerPage: 'Su página profesional es',
    yourPageIs: 'Su página de carrera es',
    public: 'público',
    private: 'privado',
    categories: 'Categorías *',
    industryType: 'Seleccione el tipo de industria.',
    description: 'Descripción',
    maxCharacter: '(máx. {{count}} caracteres)',
    siteNamePlaceHolder: 'Introduzca el nombre del sitio',
    createNew: 'Crear nuevo',
    subOrganisation: 'Suborganizacioness',
    urlCopyurl: 'URL copiada en el portapapeles',
    saveProfile: 'Guardar perfil',
    saving: 'Ahorro',
    hideEmploymentType: 'Ocultar información sobre el tipo de empleo en el formulario de solicitud de candidatos.',
    hideLocation: 'Ocultar información de la ubicación del trabajo en el formulario de solicitud de candidatos.',
  },
  billingPage: {
    overall: 'En general',
    byInterview: 'Por entrevista',
    heading: 'Ahora estás en',
    changePlan: 'Cambio de plan',
    particulars: 'Informe detallado',
    credits: 'Créditos',
    totalCredits: 'Creditos totales',
    to: 'a',
    used: 'Usado',
    balance: 'Balance',
    numberOfLiveInterview: 'Número de entrevistas en vivo',
    numberOfUser: 'Numero de usuarios',
    candidateResponse: 'Respuestas de los candidatos (créditos)',
    billingInformation: 'Información de facturación',
    nextBillingDate: 'Próxima fecha de facturación',
    on: 'en',
    freeCredit: 'Créditos gratuitos por un valor de 100 $.',
    continue: 'Continuar',
    usePromoCode: 'Utilice nuestro código promocional para obtener un 20 % de descuento en cualquier plan',
    copiedSuccess: 'Copiado correctamente',
    notApplicable: 'No aplicable',
    hiringIsSeasonal: 'La contratación es temporal y lo entendemos',
    hiringDesc1: 'Descubra nuestros nuevos precios anuales basados en créditos. Acceda a un número ilimitado de empleos',
    hiringDesc2: 'y usuarios ilimitados al comprar 100 créditos o más.',
  },
  integrationPage: {
    calendayApiKey: 'CLAVE DE API DE CALENDLY',
    save: 'Ahorrar',
    zapierIntegration: 'INTEGRACIÓN CON ZAPIER',
    beta: 'Versión Beta',
    generate: 'Generar',
    whatZapier: '¿QUÉ PUEDE HACER CON ZAPIER??',
    placeHolderCalendly: 'Introduzca la API de Calendly',
    placeholderGenerateZapierKey: 'Haga clic en "generar" para obtener la clave API de Zapier',
  },
  notificationPreference: {
    heading: 'Resumen del candidato Preferencia de notificación por correo electrónico',
    daily: 'A diario',
    weekly: 'Semanalmente',
    monthly: 'Mensual',
    save: 'Ahorrar',
  },
  resetPassword: {
    heading: 'Restablecer su contraseña',
    enterEmailInstr: 'Introduzca su dirección de correo electrónico y le enviaremos un enlace para restablecer su contraseña.',
    email: 'Correo electrónico',
    sendResetLink: 'Enviar correo electrónico de restablecimiento de contraseña',
    login: 'Iniciar sesión',
    resetting: 'Restablecimiento...',
  },
  talentpool: {
    resume: 'Reanudar',
    viewResume: 'Ver currículum',
    searchApplicants: 'Buscar solicitantes',
    'date applied': 'Fecha aplicada',
    talentpoolInsights: 'Ideas para el pool de talentos',
    errorOccured: 'Se ha producido un error. Póngase en contacto con nosotros para obtener más información',
    reachOut: 'Pronto nos pondremos en contacto con usted.',
    hi: '¡Hola!',
    contactUsMessage: `¿Estás cansado de intercambiar entrevistas para acceder a tus propios candidatos?

    ¡La función Talent Pool te lo pone fácil! Acceda a todos sus candidatos en una sola página con filtros para limitar su búsqueda y encontrar su interés.

    Para obtener acceso a esta función premium, contáctenos para obtener más detalles. ¡O haga clic en el botón a continuación para que nos comuniquemos con usted por correo electrónico!
  `,
    contactUsMessageCoaching: `¿Estás cansado de intercambiar entrevistas para acceder a tus propios solicitantes?

    ¡La función Talent Pool te lo pone fácil! Acceda a todos sus solicitantes en una sola página con filtros para limitar su búsqueda y encontrar su interés.

    Para obtener acceso a esta función premium, contáctenos para obtener más detalles. ¡O haga clic en el botón a continuación para que nos comuniquemos con usted por correo electrónico!
  `,
    contactUsTitle: 'Grupo de talentos',
    demographicData: 'Datos demográficos',
    applicationsBtn: 'Solicitud',
    usage: 'Uso',
    educationBackground: 'Antecedentes educacionales',
    emailPerformance: ' Rendimiento del correo electrónico',
    dataWithPhoneNumber: 'Datos con número de teléfono',
    appliedToMultipleRoles: ' Aplicado a múltiples roles',
    timeToHire: 'hora de contratar',
    totalLiveInterviews: '  Total de entrevistas en vivo',
    totalUsers: 'Total de usuarios',
    allJobs: 'Todos los trabajos',
    contactUsToUpgrade: 'Contacta con nosotros para actualizar',
    contactUsFeature: 'Función de información sobre el grupo de talentos',
    interested: 'Estoy interesado',
    companyOverall: 'Compañía en general',
    interviews: 'Entrevistas',
    applicants: 'Solicitantes',
    resumeScoring: 'Reanudar puntuación',
    workmapScoring: 'Puntuación del mapa de trabajo',
    videoScoring: 'Entrevista en vídeo',
    shortlisted: 'Preseleccionado',
    scheduled: 'Programado',
    rejected: 'Rechazados',
    kiv: 'Mantenido a la vista',
    completedApplications: 'Solicitud completada',
    incompleteApplications: 'Solicitud incompleta',
    searchCandidatePlaceholder: 'Buscar candidato',
    allJobTitle: 'Todos los puestos de trabajo',
    allLocations: 'Todas las ubicaciones',
    allStatus: 'Todos los estados',
    allApplication: 'Todas las solicitudes',
    complete: 'Completado',
    inComplete: 'Incompleta',
    name: 'Nombre',
    jobTitle: 'Puesto de trabajo',
    'job title': 'Puesto de trabajo',
    company: 'Empresa',
    location: 'Lugar',
    interviewName: 'Nombre de la entrevista',
    'interview name': 'Nombre de la entrevista',
    status: 'Estado',
    applications: 'Solicitud',
    all: 'Todo',
    completed: 'Completado',
    incomplete: 'Incompleto',
    videoInterview: 'Entrevista en vídeo',
    'Action Pending': 'Acción pendiente',
    Shortlist: 'Preseleccionado',
    Rejected: 'Rechazado',
    Kiv: 'Kiv',
    application: 'Estado',
  },
  candidateDetails: {
    idealAnswer: 'Respuesta ideal',
    idealAnsNotAvail: 'La respuesta ideal no está disponible',
    idVerifyTrueText: 'Verificación de identidad verificada',
    idVerifyFalseText: 'Verificación de identidad no verificada',
    audioDetectedTrue: 'Audio detectado',
    audioDetectedFalse: 'No se detecta audio',
    browserToggleTrue: 'Se detectó cambio de navegador',
    browserToggleFalse: 'No se detectó ningún cambio de navegador',
    pastedAnsTrue: 'Respuesta pegada detectada',
    pastedAnsFalse: 'No se detectó ninguna respuesta pegada',
    thirdPartyVoiceTrue: 'Voz de terceros detectada',
    thirdPartyVoiceFalse: 'No se detectó ninguna voz de terceros',
    eyeContactTrue: 'Contacto visual detectado',
    eyeContactFalse: 'No se detectó contacto visual',
    suspiciousObjectTrue: 'Objeto sospechoso detectado',
    suspiciousObjectFalse: 'No se detectó ningún objeto sospechoso',
    shareWith: 'Compartido con',
    sendReportCoaching: 'Enviar informe al solicitante',
    applicantShortlisted: 'Candidato preseleccionado',
    shortlistApplicant: 'Candidato preseleccionado',
    applicants: 'Solicitantes',
    searchApplicants: 'Buscar solicitantes',
    selectMe: 'Seleccióname',
    selected: 'Seleccionado',
    applicantSummary: 'Resumen del solicitante',
    all: 'Todo',
    candidates: 'Candidatos',
    searchCandidates: 'Buscar candidatos',
    scoreSettings: 'Ajustes de puntuación',
    resumeScore: 'Puntuación del currículo',
    workmapScore: 'Puntuación del mapa de trabajo',
    aiVideoScore: 'Puntuación del vídeo por IA',
    professionalism: 'Profesionalismo',
    positiveAttitude: 'Actitud Positiva',
    communication: 'Comunicación',
    sociability: 'Sociabilidad',
    contentRelevancy: 'Relevancia del contenido',
    reset: 'Restablecer',
    saving: 'Ahorro...',
    save: 'Ahorrar',
    score: 'Puntaje',
    feedback: 'Comentario',
    new: 'Nuevo',
    unlockCandidate: 'Desbloquear este candidato',
    scoreSettingsInfo: 'Esto le permite controlar la ponderación del currículo, mapa de trabajo y la puntuación de vídeo por IA. Elija la ponderación que desee y debería sumar 100.',
    deleteCandidateInfo: 'El candidato ha eliminado su perfil, así que ya no se puede acceder a su información.',
    overallScore: 'Puntuación general',
    overall: 'En general',
    hired: 'Contratado',
    hire: 'Contratarme',
    appliedDate: 'Fecha de la solicitud',
    changeSchedule: 'Cambiar horario',
    scheduleInterview: 'Programar entrevista',
    downloadReport: 'Descargar informe',
    shareProfile: 'Compartir este perfil',
    sendReport: 'Enviar informe al candidato',
    candidateShortlisted: 'Candidato preseleccionado',
    shortlistCandidate: 'Preseleccionar candidato',
    candidateKived: 'Candidato kived',
    kivCandidate: 'Candidato KIV',
    keepInView: 'Mantener a la vista',
    keptInView: 'Mantenido a la vista',
    candidateRejected: 'Candidato rechazado',
    rejectCandidate: 'Rechazar candidato',
    rejected: 'Rechazar candidato',
    reject: 'Rechazar',
    inviteActivity: 'Invitar a actividad',
    interviewScheduledOn: ' Entrevista programada para el',
    activity: 'Actividad',
    contactedUser: 'Usuario contactado el',
    noQuestionsAttempted: 'No se ha intentado ninguna pregunta',
    videoTranscript: 'Transcripción del vídeo',
    candidateSummary: 'Resumen del candidato',
    insightsDisclaimer: 'Descargo de responsabilidad: utilice esta información a su discreción para preseleccionar candidatos para entrevistas o para realizar llamadas de contratación.',
    skill: 'Habilidad',
    education: 'Formación',
    experience: 'Experiencia',
    shareShortlist: 'Compartir lista de finalistas',
    inviteSent: 'Invitación enviada',
    interviewVideo: 'Transcripción del vídeo',
    location: 'Ubicación',
    email: 'Correo electrónico',
    phone: 'Phone',
    linkedIn: 'Linkedin',
    includeEmail: 'Incluir un correo electrónico',
    rejectCandidateInfo: 'Confirme si desea rechazar al(los) siguiente(s) candidato(s):',
    emailSubject: 'Asunto del correo electrónico',
    dear: 'Estimado',
    rejectReason: 'Motivo del rechazo',
    rejecting: 'Rechazando',
    emailPreview: 'Vista previa del correo electrónico',
    selectEventType: 'Seleccione el tipo de evento de Calendly',
    calendlyEventCreated: 'Asegúrese de que tiene un evento de Calendly creado o cree uno aquí',
    noCalendlyEventFound: 'No se ha encontrado ningún evento de Calendly',
    sender: 'Remitente',
    editEmailSubject: 'Editar el asunto del correo electrónico',
    checkEditEmailContent: 'Verifique y edite el contenido del correo electrónico',
    lookForwardMeetingYou: 'Esperamos encontrarnos contigo pronto',
    sending: 'Enviando...',
    scheduleAndSend: 'Programar y enviar',
    yesRejectCandidate: 'Sí, rechazar candidato',
    andSendEmail: ' y enviar correo electrónico',
    yourFeedback: 'Tu retroalimentación',
    feedbackInstruction1: 'Máximo de caracteres: 5000',
    overallFeedback: 'Valoración en general',
    hiringManager: 'Director de contratación',
    notes: 'Notas',
    status: 'Estado',
    noSummary: 'Sin resumen',
    videoScoreTooltip: 'El exclusivo marco de Interviewer.AI proporciona valoraciones de las palabras habladas, la voz y las expresiones faciales de los candidatos durante la entrevista en vídeo. Los candidatos reciben una puntuación sobre cien para cada parámetro evaluado. La suma de estos resultados da una puntuación global de la entrevista en vídeo. Una puntuación más alta en la barra indicaría que el candidato obtuvo mejores resultados según las valoraciones objetivas de su comportamiento en la entrevista en vídeo, en comparación con las entrevistas en vídeo realizadas en la plataforma, así como con los conjuntos de datos de carácter público.',
    professionalismTooltip: 'La medición se realiza a partir del comportamiento del candidato (formal/informal) y analiza cómo se presenta en el vídeo. También evalúa si se mantiene el contacto visual durante la respuesta en vídeo y si hay algún tipo de inquietud. Sí, vestir formalmente resultaría ventajoso.',
    positiveAttitudeTooltip: 'Actitud, como su nombre lo indica, busca sentimientos junto con una combinación de energía y emoción. Este modelo se basa en una combinación de parámetros de comunicación y sociabilidad (por ej., confianza, ánimo, actitud positiva).',
    communicationTooltip: 'Esta prueba mide principalmente la claridad del discurso del candidato. También se evalúa la fluidez de la comunicación verbal y la modulación del discurso. Aquí se valorará la claridad y el ritmo al hablar. También se examinará la variación del tono de voz en los momentos adecuados del vídeo.',
    sociabilityTooltip: 'Este modelo de IA se centra más en calificar los sentimientos del candidato, usando la forma en que el candidato se comunica y muestra habilidades interpersonales positivas y tacto. Suele buscar palabras de sentimientos positivos y niveles de energía de un candidato.',
    contentRelevancyTooltip: 'La relevancia de la respuesta se muestra como un porcentaje para indicar en qué medida aborda la pregunta. Un porcentaje más alto indica un mayor nivel de relevancia, mientras que un porcentaje más bajo sugiere una respuesta potencialmente menos relevante. Esta información le ayuda a evaluar la exactitud e idoneidad de la respuesta proporcionada.',
  },
  scheduleInterview: {
    email: 'Vista previa de correo electrónico',
    selectDateTimeDuration: '1. Seleccione fecha, hora y duración',
    scheduleDescription: 'Puede programar una entrevista con el candidato aquí. Le enviaremos un correo electrónico a usted y a su candidato.',
    dateAndTime: 'Fecha y hora',
    duration: 'Duración',
    sender: '2. Remitente',
    editEmailSubject: ' 3. Edite el asunto del correo electrónico',
    checkEditEmailContent: '4. Verifique y edite el contenido del correo electrónico',
  },
  candidateListing: {
    removeSelectedApplicants: 'Eliminar solicitantes seleccionados',
    removeSelectedApplicantInfo: '¿Está seguro de que desea eliminar a estos solicitantes?',
    editApplicantDetail: 'Editar detalles del solicitante',
    unlockApplicant: 'Desbloquear este solicitante',
    kivApplicant: 'Solicitante KIV',
    deleteApplicant: 'Eliminar solicitante',
    copyApplicantInterviewLink: 'Copiar el enlace de la entrevista del solicitante',
    editApplicant: 'Editar solicitante',
    applicantDateBetween: 'Fecha de los solicitantes entre',
    filterApplicants: 'Filtrar solicitantes',
    selectToDeleteCoaching: 'Select applicants to delete',
    deleteSelectedCandidatesCoaching: 'Eliminar solicitantes seleccionados',
    downloadApplicantDetails: 'Descargar datos del solicitante',
    selectToRejectCoaching: 'Seleccionar solicitantes para rechazar',
    rejectSelectedCoaching: 'Rechazar solicitantes seleccionados',
    selectToShareCoaching: 'Seleccione solicitantes para compartir',
    shareSelectedCoaching: 'Compartir solicitantes seleccionados',
    addApplicant: 'Agregar nuevo solicitante',
    addApplicantToInterview: 'Agregue el siguiente solicitante a la entrevista',
    enterCandidateName: 'Ingrese el nombre del candidato',
    enterApplicantName: 'Ingrese el nombre del solicitante',
    enterCandidateEmail: 'Ingrese la dirección de correo electrónico del candidato',
    enterApplicanteEmail: 'Ingrese la dirección de correo electrónico del solicitante',
    enterCandidateNumber: 'Ingrese el número de contacto del candidato',
    enterApplicantNumber: 'Ingrese el número de contacto del solicitante',
    applicantsTo: 'Solicitantes de',
    notifyApplicants: 'Notificar a los solicitantes',
    sendMessagesToMultipleApplicants: 'Envíe mensajes a varios solicitantes desde aquí.',
    searchApplicants: 'Buscar solicitantes',
    newApplicant: 'Nuevo solicitante',
    totalApplicants: 'Total de solicitantes',
    totalCandidates: 'Total de candidatos',
    yetToRespond: 'Aún por responder',
    editCandidate: 'Editar candidato',
    copyInterviewLink: 'Copiar enlace de entrevista del candidato',
    deleteCandidate: 'Eliminar candidato',
    editCandidateDetail: 'Editar detalles del candidato',
    name: 'Nombre',
    email: 'Correo electrónico',
    phoneNumber: 'Número de teléfono',
    selectToDelete: 'Seleccionar candidatos para eliminar',
    deleteSelectedCandidates: 'Eliminar candidatos seleccionados',
    selectToReject: 'Seleccionar candidatos para rechazar',
    rejectSelected: 'Rechazar candidatos seleccionados',
    selectToShare: 'Seleccionar candidatos para compartir',
    shareSelected: 'Compartir candidatos seleccionados',
    newCandidate: 'Nuevo candidato',
    videoScore: 'Partitura de vídeo',
    sendVideoInterviewLink: 'Enviar enlace de entrevista en video',
    select: 'Seleccionar',
    selected: 'seleccionado',
    candidatesTo: 'Candidatos a',
    candidate: 'Candidato',
    applicant: 'Solicitante',
    notify: 'Notify',
    importByCSV: 'Importar por CSV',
    addCandidate: 'Agregar nuevo candidato',
    addCandidateToInterview: 'Agregue el siguiente candidato a la entrevista',
    enterContactNumber: 'Ingrese el número de contacto del candidato',
    removeSelectedCandidates: 'Eliminar candidatos seleccionados',
    removeSelectedCandidateInfo: '¿Está seguro de que desea eliminar a estos candidatos?',
    noCandidatesInfo: 'Aún no hay candidatos. ¡Envíe entrevista a los candidatos ahora!',
    notifyCandidate: 'Notificar a los candidatos',
    sendMessagesToMultipleCandidates: 'Envíe mensajes a varios candidatos desde aquí.',
    sendEmailsTo: 'Enviar mensajes de correo electrónico a',
    emailPreviewInfo: 'A continuación se muestra la vista previa del correo electrónico que se enviará a los candidatos. Puedes cambiar el área en amarillo para adaptarla mejor a tus mensajes.',
    emailPreviewInfo2: 'Por favor haga clic en el enlace a continuación y registre su respuesta.',
    recordResponse: 'Graba tu respuesta',
    lookForwardForResponse: 'Esperamos su respuesta, ¡mucha suerte!',
    filterCandidates: 'Candidatos de filtro',
    downloadCandidateDetails: 'Descargar datos del candidato',
    importCandidates: 'Candidatos de importación',
    fileName: 'Nombre del archivo',
    bulkImportCandidateInfo1: 'El contenido CSV debe incluir correo electrónico, nombre y teléfono (opcional). Puede estar en diferente orden',
    actions: 'Comportamiento',
    chooseFile: 'Elija el archivo',
    addCandidates: 'Agregar candidatos',
    candidateImportInfo1: 'No se importarán datos de candidatos',
    duplicateEntry: 'Entrada duplicada para correo electrónico',
    duplicateEntryPhone: 'Entrada duplicada para teléfono',
    duplicateEntryInfo: 'Entrada duplicada para',
    inCSVsheet: 'en hoja CSV',
    alreadyExist: 'ya existe en la lista de candidatos',
    enterValidName: 'Por favor ingresa un nombre válido',
    enterValidEmail: 'Por favor introduzca un correo electrónico válido',
    enterValidPhone: 'Por favor introduce un teléfono válido',
    enterValid: 'Por favor ingrese válido',
    invalidEmail: 'El contenido CSV contiene un correo electrónico no válido',
    invalidPhone: 'El contenido CSV contiene un número de teléfono no válido',
    isMandatoryForAllCandidates: 'Es obligatorio para todos los candidatos.',
    csvContailsInvalid: 'El contenido CSV contiene inválido',
    csvImportInfo1: 'El archivo no es un archivo CSV o Excel.',
    csvImport2: 'El archivo CSV/Excel debe tener nombre o correo electrónico',
    columnInHeader: 'columna en el encabezado',
    reactivateCandidateLink: 'Reactivar enlace candidato',
    reactivateApplicantLink: 'Reactivar enlace del solicitante',
  },
  insights: {
    candidateLocation: 'Ubicación del candidato',
    genderDiversity: 'Diversidad de género',
    jobTitle: 'Título profesional',
    colleges: 'colegios',
    educationGraph: 'Gráfico de educación',
    sourceOfApplication: 'Fuente de aplicación',
    resetMap: 'Restablecer mapa',
    emailSentOpened: 'Correos electrónicos enviados, abiertos y respondidosd',
    showingLatest: 'Mostrando 5 últimas entrevistas',
    emptyGenderInfo: 'Aún no hay información de género',
    insights: 'Perspectivas',
    interested: 'Estoy interesado',
    contactUs: 'Contacta con nosotros',
    noEmailEventInfo: 'Aún no hay información de eventos por correo electrónico',
    noSourceInfo: 'Aún no hay información de la fuente',
  },
  footer: {
    copyRightText: 'Copyright © {{currentYear}} Entrevistador.AI. Reservados todos los derechos.',
  },
  shareProfile: {
    shareThisProfile: 'Comparte este perfil',
    shareCandidates: 'Compartir candidatos',
    enterEmailSubject: 'Introduzca un asunto de correo electrónico',
    selectedCandidate: 'Candidato seleccionado',
    listName: 'Lista de nombres',
    shareWith: 'Compartir con',
    info1: 'Presione Entrar para agregar correos electrónicos separados',
    secureAccess: 'Acceso seguro',
    optional: 'opcional',
    enterAccessCodeHere: 'Introduzca aquí el código de acceso',
    shareLink: 'Compartir enlace',
    copyLink: 'Copiar link',
    note: 'Nota',
    enterNote: 'Introducir nota',
    undoKIV: 'Deshacer KIV',
    shortlist: 'Lista corta',
    undoShortlist: 'Deshacer lista corta',
    undoReject: 'Deshacer Rechazar',
    feedbackInfo1: 'Comparta sus opiniones sobre este candidato, p. Agenda una reunión conmigo el...',
  },
  candidateFilter: {
    candidateDateBetween: 'Fecha de candidatos Entre',
    selectStartDate: 'Seleccione fecha de inicio',
    selectEndDate: 'Seleccionar fecha de finalización',
    kived: 'KIVed',
    filterKeyworkds: 'Filtrar palabras clave en título/currículum',
    resetFilter: 'Restablecer filtro',
    applyFilter: 'Aplicar filtro',
    keywordFilterInfo: 'Presione Entrar o Tabulador para agregar una palabra clave separada',
  },
  emailers: {
    reminder: 'Ingrese su mensaje aquí [Nota: Esto solo enviará un mensaje al candidato. No se compartirán enlaces de entrevistas.]',
  },
  insightsPayWall: {
    coachingHeading: 'Libere el poder de la selección basada en datos',
    coachingMessage: 'Descubra el potencial oculto de su proceso de selección de candidatos mientras le brindamos un tesoro de datos invaluables y análisis inteligentes. Sumérgete en un mundo donde cada selección de entrevistas se convierte en una oportunidad para obtener información detallada sobre los solicitantes, lo que te ayudará a tomar decisiones de selección de primer nivel en todo momento.',
    heading: 'Libere el poder de la contratación basada en datos',
    message: 'Descubra el potencial oculto de su proceso de selección de candidatos mientras le brindamos un tesoro de datos invaluables y análisis inteligentes. Sumérgete en un mundo donde cada selección de entrevistas se convierte en una oportunidad para obtener información detallada sobre los candidatos, lo que te ayudará a tomar decisiones de contratación de primer nivel en todo momento.',
    interested: 'Estoy interesado',
  },
  candidateFilters: {
    filterCandidates: 'Candidatos de filtro',
    applciationRecievedBetween: 'Solicitudes recibidas entre',
    startDate: 'Fecha de inicio',
    endDate: 'Fecha final',
    location: 'Ubicación',
    selectLocation: 'Seleccionar Ubicación',
    candidateStatus: 'Estado candidato',
    selectStatus: 'Seleccionar estado',
    recruiterStatus: 'Estado del reclutador',
    introQuestions: 'Preguntas de introducción',
    selectOptions: 'Seleccione opciones',
    reset: 'Reiniciar',
    applyFilter: 'Aplicar filtro',
  },
  newPlansPage: {
    expiryMessage: 'Los créditos de su cuenta Interviewer.AI han caducado. Compra créditos para seguir utilizando nuestras entrevistas en vídeo.',
    selectCredits: 'seleccionar créditos',
    egText: 'Ej.: 500 créditos = 500 entrevistas en vídeo',
    pricingMainText: 'Vea opciones de precios para automatizar su contratación',
    pricingMainSecondaryText: 'Desbloquee una variedad de características y beneficios diseñados para usted.',
    assessmentScoreIncHeading: '¿Qué incluyen las puntuaciones de la evaluación de los candidatos?',
    resumeScore: 'Reanudar puntuación',
    assessmentScorePrimHeading: 'Interviewer.AI proporciona la solución de selección de reclutamiento más completa y superior del embudo que incluye tres componentes básicos',
    resumeSectionPara1: 'Resume Score utiliza algoritmos de reconocimiento óptico de caracteres (OCR) y procesamiento profundo del lenguaje natural (NLP) para extraer el texto de los currículums y compararlo con la descripción del trabajo.',
    resumeSectionPara2: 'La puntuación de IA reemplaza la búsqueda tradicional de palabras clave (que los reclutadores emplean en gran medida) y la reemplaza con algoritmos de aprendizaje profundo que comprenden el contexto detrás de cada palabra en un currículum mediante el reconocimiento de entidades nombradas.',
    resumeSectionPara3: 'Le brinda puntuaciones absolutas de IA, basadas en una base de datos de 120 millones de currículums.',
    workmapScore: 'puntuación del mapa de trabajo',
    workmapSectionPara1: 'El WorkMap evalúa las habilidades y competencias de los candidatos en función de los roles específicos que solicitaron.',
    workmapSectionPara2: 'Las competencias requeridas para realizar determinadas tareas laborales varían según las diferentes industrias, funciones o roles. Con WorkMap, los candidatos son evaluados en función de las competencias críticas que les ayudarán a tener éxito en sus funciones en el futuro.',
    workmapSectionPara3: 'La puntuación se basa en una escala Likert de Amabilidad de 5 puntos que contiene entre 25 y 35 preguntas en las que cada habilidad se evalúa mediante entre 5 y 7 afirmaciones.',
    aiVideoScore: 'Partitura de vídeo con IA',
    aiVideoSectionPara1: 'El marco patentado de Interviewer.Al proporciona calificaciones para las palabras habladas, la voz y las expresiones faciales de los candidatos durante la entrevista en video..',
    aiVideoSectionPara2: 'Los candidatos reciben una puntuación sobre cien para cada parámetro de evaluación. Estos puntajes se agregan en un puntaje general para su entrevista en video.',
    aiVideoSectionPara3: 'Una puntuación más alta en la barra indicaría que al candidato le fue mejor según las calificaciones objetivas de su comportamiento en la entrevista en video según el público y los conjuntos de datos de la plataforma.',
    quickPreviewText: 'Aquí hay una vista previa rápida',
    all: 'Plan',
    plans: 'Características',
    yourAiCredits: 'Créditos de su entrevista de IA',
    myCurrentPlan: 'Mi plan actual',
    active: 'Activo',
    expDate: 'Fecha de caducidad',
    addYourCred: 'Agregue sus créditos',
    addNewCred: 'Comprar créditos',
    videoInt: 'entrevistas en vídeo',
    availCred: 'crédito disponible',
    addCred: 'Agregar créditos',
    newCred: 'New Credits',
    validOn: 'Válido hasta el',
    price: 'Precio',
    congText: 'Felicidades!',
    unlockPlan: 'Has desbloqueado el {{activeCurrentPlan}} Plan.',
    unlockEnterprisePlan: 'Usted califica para nuestro {{activeCurrentPlan}} Plan.',
    schedDemo: 'Programe una demostración',
    buyNow: 'Comprar ahora',
    unlocked: 'Desbloqueado',
    starterPlanHeaderText: 'Para contratación limitada',
    growthPlanHeaderText: 'Para contratación acelerada',
    premiumPlanHeaderText: 'Para escalar equipos',
    benifits: 'Beneficios',
    userLicense: 'Licencia de usuario',
    admin: 'Administrador',
    recuiter: 'Reclutador',
    accountAlloc: 'Asignaciones de cuentas',
    noOfJobsInterviews: 'Número de entrevistas de trabajo',
    noOfAIInterAssessments: 'Número de evaluaciones de entrevistas de IA',
    generativeAiAssist: 'Asistencia de IA generativa',
    autoGenJobDes: 'Descripción de trabajo generada automáticamente',
    candSummay: 'Resumen del candidato',
    genAiQuesGeneration: 'Generación de preguntas Gen AI',
    socialFeatures: 'Funciones sociales',
    careerPage: 'Página de carrera',
    linkWithJobPost: 'Enlace con publicaciones de empleo de LinkedIn',
    stackRankAlgo: 'Algoritmo de clasificación de pila',
    resumeScoring: 'Reanudar puntuación',
    workmapAssess: 'Evaluaciones del mapa de trabajo',
    scoreSettings: 'Configuración de puntuación',
    contentReleScore: 'Content Relevancy Score',
    contentReleScoreBreakdown: 'Desglose de la puntuación de relevancia del contenido',
    support: 'Apoyo',
    emailSupport: 'Soporte de correo electrónico',
    dedicatedAccountManger: 'Gerente de cuenta dedicado',
    expired: 'Caducado',
    infoIconText: 'Crédito = reseña de un video',
  },
  expiryPlanModal: {
    firstMesssage: 'Tus créditos gratuitos caducan en',
    firstNonTrialMesssage: 'Tus créditos caducan en',
    day: 'día.',
    days: 'días.',
    secondMessage: 'Compre créditos ahora antes de que caduquen y obtenga un 20% de descuento en cualquier plan.',
    thirdMessage: 'Usar código',
    fourthMessage: 'al finalizar la compra para aprovechar esta oferta',
  },
  creditsAlertText: {
    message: 'Ha excedido los créditos asignados en su cuenta. Para acceder a las respuestas y evaluaciones de los nuevos candidatos, compre créditos inmediatamente.',
  },
};
