// @flow
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Glyphicon } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Image3 from '../assets/newPromoCode.png';
import OrganizationStore from '../stores/OrganizationStore';
import OrganizationActions from '../actions/OrganizationActions';
import { ReactComponent as CopyIcon } from '../assets/CopyIcon.svg';
import '../css/UpgradePlanModal.scss';
import {
  mxTrackEvent,
} from '../utils/utils';
import { successToast } from './Toast';

const redirectToPlanPage = (history) => {
  mxTrackEvent('CTA go to offer clicked');
  localStorage.setItem('popup', true);
  OrganizationActions.setUpgradeModalClose();
  history.push('/settings/billing/plan');
};

const Button = withRouter(({ history }) => (
  <button
    type="button"
    onClick={() => { redirectToPlanPage(history); }}
  >
    Get the offer
  </button>
));

const UpgradePlanModal = ({ daysDiff }: { daysDiff: Number }) => {
  const { t } = useTranslation();
  const [isModalShow, setIsModalShow] = useState(false);

  const closeUpgradePlanModal = () => {
    OrganizationActions.setUpgradeModalClose();
  };

  const onChange = (storeData) => {
    const { isUpgradeModalOpen } = storeData;
    if (isUpgradeModalOpen) {
      mxTrackEvent('Offer popup opened');
    }
    setIsModalShow(isUpgradeModalOpen);
  };

  useEffect(() => {
    if (isModalShow) {
      mxTrackEvent('Offer popup opened');
    }
  }, [isModalShow]);

  const handleClosePopup = () => {
    mxTrackEvent('Popup closed');
    localStorage.setItem('popup', true);
    OrganizationActions.setUpgradeModalClose();
  };

  useEffect(() => {
    OrganizationStore.listen(onChange);
  }, []);

  const copyCoupon = () => {
    navigator.clipboard.writeText('IAI20OFF');
    successToast('Coupon copied.');
  };

  return (
    <React.Fragment>
      <Modal show={isModalShow} className="planModalContainer" onExit={handleClosePopup}>
        <Modal.Body className="paymentBody">
          <div className="imaageAndCancelContainer">
            <div>
              <img src={Image3} alt="planImage" className="imageSection" />
            </div>
            <button
              type="button"
              onClick={closeUpgradePlanModal}
            >
              <Glyphicon glyph="remove" />
            </button>
          </div>
          <div className="textSection">
            <span className="textSectionHeading">
              {t('expiryPlanModal.firstMesssage')}
              {' '}
              {daysDiff}
              {' '}
              {daysDiff <= 1 ? t('expiryPlanModal.day') : t('expiryPlanModal.days')}
            </span>
            <div style={{ textAlign: 'center', marginTop: '.3rem', fontWeight: '400' }}>
              {t('expiryPlanModal.secondMessage')}
            </div>
            <div style={{ textAlign: 'center', marginTop: '.3rem', fontWeight: '400' }}>
              {t('expiryPlanModal.thirdMessage')}
              {' '}
              <span
                style={{
                  textAlign: 'center',
                  marginTop: '.3rem',
                  fontWeight: '400',
                  color: '#6196fe',
                }}
              >
                IAI20OFF
              </span>
              {' '}
              <span                           //eslint-disable-line
                className="copyPopIcon"
                onClick={copyCoupon}
                type="button"
              >
                <CopyIcon width="18" height="18" stroke="#6196fe" strokeWidth="1.5" />
              </span>
              {t('expiryPlanModal.fourthMessage')}
            </div>
            <Button closeModal={closeUpgradePlanModal} />
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UpgradePlanModal;
