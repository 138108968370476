/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RingLoader } from 'react-spinners';
import { css } from 'react-emotion';
import { useTranslation } from 'react-i18next';
import { mxTrackEvent } from '../../../utils/utils';
import { successToast, alertToast } from '../../../components/Toast';
import styles from '../../../css/HiringManagerActionsComponent.module.scss';
import { CANDIDATE_STATUS_HIRING_MANAGER } from '../../../utils/constants';
import { ReactComponent as TickIcon } from '../../../assets/TickIcon.svg';
import { ReactComponent as EyeIcon } from '../../../assets/EyeIcon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/CrossIcon.svg';

import InfoTooltip from '../../../components/InfoTooltip';

const HiringManagerActionsComponent = (
  { candidateID: candidateId, hiringManagerEmail },
) => {
  const [isFetchingCandidateStatus, setIsFetchingCandidateStatus] = useState(true);
  const [candidateStatus, setCandidateStatus] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const defaultStatus = {
    rejected: false,
    shortlisted: false,
    kived: false,
  };

  useEffect(() => {
    async function fetchCandidateStatus() {
      setIsFetchingCandidateStatus(true);

      try {
        const req = await fetch(`/shareApi/candidate/${candidateId}/status?email=${hiringManagerEmail}`);

        if (req.status >= 200 && req.status < 300) {
          const res = await req.json();
          setCandidateStatus(res);
        } else {
          throw new Error(req.statusText);
        }
      } catch (e) {
        console.log(e);
        alertToast('Status for the candidate could not be fetched.');
        setCandidateStatus(defaultStatus);
      } finally {
        setIsFetchingCandidateStatus(false);
      }
    }
    fetchCandidateStatus();
  }, [candidateId]);

  const submitAction = async (action) => {
    mxTrackEvent('Manual candidate submit action button clicked');
    const currentStatus = candidateStatus;
    let newStatus;
    if (action === CANDIDATE_STATUS_HIRING_MANAGER.SHORTLISTED) {
      newStatus = {
        ...defaultStatus,
        shortlisted: !currentStatus.shortlisted,
      };
    } else if (action === CANDIDATE_STATUS_HIRING_MANAGER.KIVED) {
      newStatus = {
        ...defaultStatus,
        kived: !currentStatus.kived,
      };
    } else {
      newStatus = {
        ...defaultStatus,
        rejected: !currentStatus.rejected,
      };
    }
    setCandidateStatus(newStatus);
    setIsSubmitting(true);

    let status = action;
    if (!newStatus.shortlisted && !newStatus.kived && !newStatus.rejected) {
      // case where Hiring Manager reverted all his/her action on the candidate
      status = null;
    }

    try {
      // rating by Hiring Manager, we use an endpoint that doesn't have auth
      const req = await fetch(`/shareApi/candidate/${candidateId}/status`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'post',
        body: JSON.stringify({
          email: hiringManagerEmail,
          status,
        }),
      });

      if (req.status >= 200 && req.status < 300) {
        if (action === CANDIDATE_STATUS_HIRING_MANAGER.SHORTLISTED) {
          successToast(`Candidate ${status == null ? 'removed from shortlist' : 'successfully shortlisted'}`);
        } else if (action === CANDIDATE_STATUS_HIRING_MANAGER.KIVED) {
          successToast(`Candidate ${status == null ? 'removed from keep in view' : 'successfully added to keep in view'}`);
        } else {
          successToast(`Candidate ${status == null ? 'removed from rejected list' : 'successfully rejected'}`);
        }
        mxTrackEvent('Action taken by hiring manager.', { hiringManagerEmail });
      } else {
        throw new Error(req.statusText);
      }
    } catch (e) {
      console.log(e);
      setCandidateStatus(currentStatus);
      alertToast(e.toString());
      mxTrackEvent('Update of candidate status failed.', {
        error: e.toString(),
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isFetchingCandidateStatus) {
    return (
      <RingLoader
        color="#fd6144"
        className={css`
            margin: 20px auto;
          `}
        size={30}
      />
    );
  }

  const {
    rejected,
    shortlisted,
    kived,
  } = candidateStatus;

  return (
    <React.Fragment>
      <div className={styles.desktopButtonContainer}>
        <InfoTooltip placement="top" tooltipContent={shortlisted ? t('candidateDetails.candidateShortlisted') : t('candidateDetails.shortlistCandidate')}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${shortlisted ? styles.candidateShortlistButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.SHORTLISTED)}
            disabled={isSubmitting}
          >
            <TickIcon width="16" height="12" stroke={shortlisted ? '#31BE0E' : '#7989b1'} />
            <span className={` ${shortlisted ? styles.buttonTextActive : styles.buttonText}`}>{shortlisted ? t('shareProfile.undoShortlist') : t('shareProfile.shortlist')}</span>
          </button>
        </InfoTooltip>

        <InfoTooltip placement="top" tooltipContent={kived ? t('candidateDetails.candidateKived') : t('candidateDetails.kivCandidate')}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${kived ? styles.candidateKivButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.KIVED)}
            disabled={isSubmitting}
          >
            <EyeIcon width="20" height="21" stroke={kived ? '#6196FE' : '#7989b1'} />
            <span className={` ${kived ? styles.buttonTextActive : styles.buttonText}`}>{kived ? (t('shareProfile.undoKIV')) : (t('candidateDetails.keepInView'))}</span>
          </button>
        </InfoTooltip>

        <InfoTooltip placement="top" tooltipContent={rejected ? (t('candidateDetails.candidateRejected')) : (t('candidateDetails.rejectCandidate'))}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${rejected ? styles.candidateRejectButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.REJECTED)}
            disabled={isSubmitting}
          >
            <CrossIcon width="20" height="21" stroke={rejected ? '#FE5819' : '#7989b1'} />
            <span className={` ${rejected ? styles.buttonTextActive : styles.buttonText}`}>{rejected ? t('shareProfile.undoReject') : t('candidateDetails.reject')}</span>
          </button>
        </InfoTooltip>
      </div>
      <div className={styles.mobileButtonContainer}>
        <div className={styles.buttonTextContainer}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${shortlisted ? styles.candidateShortlistButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.SHORTLISTED)}
            disabled={isSubmitting}
          >
            <TickIcon width="16" height="12" stroke={shortlisted ? '#31BE0E' : '#7989b1'} />
          </button>
          <p className={` ${shortlisted ? styles.buttonTextActive : styles.buttonText}`}>{shortlisted ? (t('shareProfile.undoShortlist')) : (t('shareProfile.shortlist'))}</p>
        </div>
        <div className={styles.buttonTextContainer}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${kived ? styles.candidateKivButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.KIVED)}
            disabled={isSubmitting}
          >
            <EyeIcon width="20" height="21" stroke={kived ? '#6196FE' : '#7989b1'} />
          </button>
          <p className={` ${kived ? styles.buttonTextActive : styles.buttonText}`}>{kived ? (t('shareProfile.undoKIV')) : (t('candidateDetails.keepInView'))}</p>
        </div>
        <div className={styles.buttonTextContainer}>
          <button
            type="button"
            className={`${styles.candidateSelectionButtons} ${rejected ? styles.candidateRejectButton : ''}`}
            onClick={() => submitAction(CANDIDATE_STATUS_HIRING_MANAGER.REJECTED)}
            disabled={isSubmitting}
          >
            <CrossIcon width="20" height="21" stroke={rejected ? '#FE5819' : '#7989b1'} />
          </button>
          <p className={` ${rejected ? styles.buttonTextActive : styles.buttonText}`}>{rejected ? (t('shareProfile.undoReject')) : (t('candidateDetails.reject'))}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

HiringManagerActionsComponent.propTypes = {
  candidateID: PropTypes.string.isRequired,
  hiringManagerEmail: PropTypes.string.isRequired,
};

export default HiringManagerActionsComponent;
