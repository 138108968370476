export default {
  cancel: 'Cancel',
  save: 'Save',
  back: 'Back',
  create: 'Create',
  edit: 'Edit',
  delete: 'Delete',
  submit: 'Submit',
  submitting: 'Submitting..',
  confirm: 'Confirm',
  share: 'Share',
  marking: 'Marking...',
  markAllAsRead: 'Mark all as read',
  adding: 'Adding...',
  error: 'Error',
  clear: 'Clear',
  all: 'All',
  navbarSection: {
    overviewTab: 'Overview',
    interviewsTab: 'Interviews',
    insights: 'Insights',
    talentpoolTab: 'Talent Pool',
    applicantpoolTab: 'Applicant Pool',
    usersTab: 'Users',
    buyCredits: 'Buy Credits',
    'Upgrade Plan': 'Upgrade Plan',
    viewAll: 'View All',
    notifications: 'Notifications',
    help: 'Help',
    notificationInfo1: 'You have no new notifications',
    today: 'Today',
    yesterday: 'Yesterday',
    english: 'English',
    vietnamese: 'Vietnamese',
    german: 'German',
    french: 'French',
    spanish: 'Spanish',
  },
  overviewPage: {
    messageText1: 'Welcome! Take charge of your Career Page and build your presence.',
    messageText2: 'You can link your career page to your website.',
    messageButton: 'Claim your Career Page',
    ratingError: 'Rating stars are required.',
    feedback: 'Feedback',
    feedbackQuestion: 'Tell us about your experience with Interviewer.AI. How statisfied are you ?',
    veryUnsatisfied: 'Very unsatisfied',
    NitherSatUsat: 'Neither satisfied nor unsatisfied',
    verySatisfied: 'Very satisfied',
    feedbackTextLabel: 'Do you have any other comments for us ? Please leave them below:',
    surveyText: 'Welcome! Help us understand the platform better. Our survey should take you less than 30 seconds. We would love to heard from you!',
    thankyouSurveyText: 'Thank you for your feedback.',
    whatDoYouThink: 'Tell us what you think',
    secondsAgo: '{{seconds}} seconds ago',
    minuteAgo: '1 minute ago',
    hourAgo: '1 hour ago',
    monthAgo: '1 month ago',
    yearAgo: '1 year ago',
    getStarted: 'Get Started',
    totalInterviews: 'Total Interviews',
    totalApplicants: 'Total Applicants',
    totalVideoResponses: 'Total Video Responses',
    viewMoreInsights: 'View More Insights',
    weeklyOverall: 'Weekly Overall',
    createInterview: '+ Create Interview',
    text1: 'Join Pulse of Hiring on LinkedIn for insights and topics about the world of work and technology.',
    text2: 'Subscribe',
    text3: 'free for 15 days.',
    jobInterview: 'Job Interview',
    sourcingFunnel: 'Sourcing Funnel',
    applicantFunnel: 'Applicant Funnel',
    totalCandidates: 'Total Candidates',
    yetToRespond: 'Yet to Respond',
    responded: 'Responded',
    rated: 'Rated',
    newResponses: 'New Responses',
    shortlisted: 'Shortlisted',
    hello: 'Hello',
    welcome: 'Welcome',
    recentResponses: 'Recent Responses',
    noRecentResponses: 'No Recent Responses',
    applications: 'Applications',
    videoResponses: 'Video Responses',
    createFirstInterview: 'Create your first  interview',
    addCompanyLogo: 'Add your Company logo',
    viewSampleProfile: 'View a sample profile',
    addTeamMember: 'Add a team member',
    instruction1: 'Don\'t miss our latest and updated',
    instruction2: 'Notifications experience',
  },
  interviewListing: {
    archieveInterview: 'Archive Interview',
    deleteInterview: 'Delete Interview',
    duplicateInterview: 'Duplicate Interview',
    editInterview: 'Edit Interview',
    manageApplicants: 'Manage Applicants',
    archiveInterview: 'Archive Interview',
    interviewOwner: 'Interview Owner',
    searchInterview: 'Search Interview',
    details: 'Details',
    created: 'Created',
    expiry: 'Expiry',
    candidates: 'Candidates',
    applicants: 'Applicants',
    responses: 'Responses',
    analyzed: 'Analyzed',
    shortlisted: 'Shortlisted',
    createdBy: 'Created By',
    status: 'Status',
    share: 'Share',
    filter: 'Filter',
    newJobInterview: 'New Job Interview',
    newInterview: 'New Interview',
    manageCandidates: 'Manage Candidates',
    assignTo: 'Assign To',
    changeBaground: 'Change Background',
    editJobInterview: 'Edit Job Interview',
    duplicateJobInterview: 'Duplicate Job Interview',
    deleteJobInterview: 'Delete Job Interview',
    archieveJobInterview: 'Archive Job Interview',
    downloadQRCode: 'Download QR code',
    results: 'Results',
    jobOwner: 'Job Owner',
    contributor: 'Contributor',
    randomiseInfo1: 'Select questions to randomize',
    youHaveSelected: 'You have selected',
    questions: 'questions',
    interviewStatus: 'Interview Status',
    filterForInterview: 'Filter for Interview',
    pleaseSelect: '-- Please Select --',
    expiryMessage: 'This interview will expire immediately and candidates would not be able to respond to the interview anymore.',
    sureMessage: 'Are you sure to archive the interview',
    archieving: 'Archiving...',
  },
  userList: {
    team: 'Team',
    inviteNewUsers: 'Invite New Users',
    emailAddresses: 'Email addresses',
    organizationMembers: 'Organization members',
    addEmailAddress: 'Add email addresses here',
    addEmail: 'Press Enter or Tab to add email addresses',
    cancel: 'Cancel',
    share: 'Share',
    emails: 'emails',
    sentInvitesTo: 'Successfully sent invites to',
    inviteError: 'Error sending invitations, please try again later',
    confirm: 'Confirm',
    adminUserMessage: 'Admins can manage users and all interviews created within the organization',
    inOrganization: 'in your organization?',
    areSureYouWantToAssignPermissionTo: 'Are you sure you want to assign administrative permissions to',
    toAdmin: 'to admin',
    upgrade: 'Upgrade',
    resendInvitation: 'Are you sure you want to resend an invitation to',
    fromOrganization: 'from your organization?',
    resendInviteTo: 'Resend invite to',
    eraseAccessMessage: 'from your organization? This will erase the account and access from our platform.',
    irreversibleMessage: 'This action is irreversible!',
    removeUserMessage: 'Are you sure you want to remove',
    remove: 'Remove',
    noMemberFound: 'No members found. Invite new members by email to join your organization',
    joined: 'Joined',
    status: 'Status',
    email: 'Email',
    role: 'Role',
    members: 'Members',
    removeUser: 'Remove User',
    resendInvite: 'Resend Invite',
    upgradeToAdmin: 'Upgrade To Admin',
    exceededUserAdditionMessage: 'Exceeded number of admin count in your organization.',
    membersContactUsPageProps: {
      contactUsMessage: 'Team management and members invitation is an extended feature. Please contact us for for details. Or click the button below for us to contact you via email!',
      contactUsTitle: 'Team',
      contactUsFeature: 'Organization Management Feature',
    },
    searchMember: 'Search by name or email',
    inviteMember: 'Invite New Users',
    memberLimitExceededMessage: 'Exceeded the limit of the number of members in your organization',
    incorrectEmailMessage: 'Incorrect email address entered',
    sameCompanyDomainMessage: 'Please enter email address(s) from the same company domain',
    hasAlreadyInvited: 'has already been invited',
  },
  planDetails: {
    planEntitlements: 'Plan Entitlements',
    superAdmin: 'Super Admin',
    admin: 'Admin',
    user: 'User',
    contributors: 'Contributors',
    yourPlan: 'Your Plan',

  },
  newInterview: {
    reportSend: 'sends reports to applicants on completing assessment',
    cancel: 'Cancel',
    createNewInterview: 'Create new interview',
    createInterview: 'Create Interview',
    creatingInterview: 'Creating Interview...',
    updateInterview: 'Update Interivew',
    updatingInterview: 'Updating Interview...',
    newInterview: 'New Interview',
    basicInfo: 'Basic Info',
    instituteLogo: 'Institute Logo',
    addInstituteLogo: 'Add Institute Logo',
    instLogoRequired: 'Institute Logo is required',
    instituteName: 'Institute Name',
    instituteNameRequired: 'Institute Name is required',
    departmentTitle: 'Department Title',
    departmentTitleRequired: 'Department Title is required',
    chooseFunction: 'Choose a function',
    createFunction: 'Create custom Function',
    functionName: 'Function Name',
    interviewExpiryDate: 'Interview Expiry Date',
    interviewDescription: 'Interview Description',
    applicantRetakeText: 'Can applicant retake the whole interview multiple times?',
    applicantResumeText: 'Is resume scoring required when applicant apply?',
    applicantResponseText: 'Expected Applicant Response language',
    workmapErrorMessage: '(Please select atleast one skill)',
    enableSocialMediaText: 'Choose to ask social media profiles from candidates',
    selectQuestions: 'Select Questions',
    selectTwoQuestions: 'Please select as least 2 questions.',
    selectAllQuestions: 'Select All Questions',
    updateJobInterview: 'Update Job Interview',
    createJobInterview: 'Create Job Interview',
    newJobInterview: 'New Job Interview',
    workmapAssessment: 'Workmap Assessment',
    addIntroQuestions: 'Additional Form Questions',
    addVideoQuestions: 'Add Video Questions',
    editYourQuestion: 'Edit additional form question',
    editingYourQuestion: 'Editing...',
    noIntroCategories: 'No categories created yet...',
    interviewSettings: 'Interview Settings',
    basicJobInfo: 'Basic Job Info',
    qualifierQuestion: 'Qualifier Question',
    identityFraudToggleText: 'Is Identity verification required?',
    qualifierQuestionErrorMessage: 'Qualifier Question is required if it is enabled.',
    addLogo: 'Add Logo',
    companyLogo: 'Company Logo',
    addCompanyLogo: 'Add Company Logo',
    logoChangeInstruction: 'Click on the logo to change or add the logo',
    logoChangeInstruction2: 'max. 500kb',
    companyLogoRequired: 'Company logo is required',
    companyName: 'Company Name',
    companyNameRequired: 'Company name is required.',
    jobTitleInstruction1: 'Job Title',
    jobTitleInstruction2: 'Interview name is required.',
    jobFunction: 'Job Function',
    chooseJobFunction: 'Choose a Job Function',
    createCustomJobFunction: 'Create custom Job Function',
    questions: 'Questions',
    jobFunctionInstruction: {
      instruction1: 'Job function name*',
      instruction2: 'Job function name is required.',
    },
    updatingJobInterview: 'Updating Job Interview...',
    workmapSkills: {
      instruction1: 'Select skills',
      instruction2: 'Workmap Assessment uses Likert Scale. A Likert scale is commonly used to measure attitudes, knowledge, perceptions, values, and behavioral changes. A Likert-type scale involves a series of statements that respondents may choose from in order to rate their responses to evaluative questions (Vogt, 1999).',
      instruction3: 'Skills are required.',
      instruction4: 'Choose Multiple:',
      relevantSkills: 'Relevant Skills',
      instruction5: 'List of skills that will be tested in Interviewer.AI Workmap Assessment. The WorkMap Assessment is a psychometric tool designed to measure skills and competencies that are linked to success factors of specific jobs.',
      instruction6: 'Choose Multiple',
      workmapAssessmentEnabled: 'Workmap Assessment Enabled',
      instruction7: 'Likert Scale',

    },
    creating: 'Creating',
    create: 'Create',
    interviewStartDate: 'Interview Start Date',
    jobExpiry: 'Job Expiry Date',
    expirydateRequired: 'Expiry date is required.',
    jobLocationInstruction: {
      jobLocation: 'Job Location',
      instruction1: 'Country is required.',
      instruction2: 'City is required.',
    },
    jobVisibility: {
      jobVisibilityInstruction: 'Job Visibity',
      jobVisibilityInstruction1: 'Job Visibility controls what jobs show up on your Career Page',
      jobVisibilityInstruction2: 'Job is',

    },
    private: 'Private',
    public: 'Public',
    qrCodeInstructions: {
      qrCodeInstructions1: 'QR Code Visibility',
      qrCodeInstructions2: 'QR Code Visibility shows Qr code on jobs listings',
      qrCodeInstructions3: 'Qr Code',
    },
    enabled: 'Enabled',
    disabled: 'Disabled',
    roleType: 'Role Type',
    roleTypeRequired: 'Role type is required.',
    uniqueCandidatesLabel: 'Unique Candidate Identifier Label',
    uniqueCandidatesLabel2: 'This can be any unique value that you have assigned to candidate to identify them.',
    uniqueCandidatesLabel3: '1 - Specified value will show up in the application form as a label with input field.',
    uniqueCandidatesLabel4: '2 - We recommend, Do not specify any value in this field if you do not have such a unique ids or have not provided these ids with candidates as this field will be mandatory for candidates.',
    candidateCoachingId: 'E.g. Seat number / Candidate number / Roll number',
    jobDescription: {
      jobDescription: 'Job Description',
      jobDescriptionInstruction1: 'Description is required.',
      jobDescriptionInstruction2: 'Sends reports to candidates on completing assessment',
      jobDescriptionInstruction3: 'Turning this feature on will enable sending reports to candidates individually after they have completed this video interview.',
    },
    moreOptions: 'More Options',
    resumeInstructions: {
      resumeInstructions1: 'Is resume required when candidates apply?',
      resumeInstructions2: 'Make LinkedIn profile URL mandatory',
      resumeInstructions3: 'Can candidate retake the whole interview multiple times?',
      resumeInstructions4: 'Make Facebook profile URL mandatory',
      resumeInstructions5: 'Make Instagram profile URL mandatory',
      resumeInstructions6: 'Add Ideal Response',
      resumeInstructions7: 'Measure Response Relevancy Score',
    },
    showLinkedin: '{{option}} LinkedIn URL space',
    showFacebook: '{{option}} Facebook URL space',
    showInstagram: '{{option}} Instagram URL space',
    videoScoreInstruction: 'Do you want to set a minimum video score to retake interview ?',
    thresholdInstruction: 'Threshold value should be greater than 20',
    thresholdInstruction1: 'Restrict candidates from proceeding beyond the application form',
    thresholdInstruction2: 'Please note that activating this option will restrict candidates from proceeding beyond the application form. Candidates will need to receive an invite from you in order to continue with the process',
    overwriteInterviewCompanyName: 'Overwrite Interview Company Name',
    overwriteCompanyName: 'Overwrite Company Name',
    customiseInterviewLogo: 'Customize Interview logo (JPG, JPEG, PNG)',
    redirectURL: 'Redirect URL',
    redirectUrlInstructions: 'This sets the redirect URL when the candidate finishes the video interview, eg: Your company LinkedIn page. URL has to prefixed with http or https protocol.',
    manualRatingParameter: 'Manual Rating Parameters',
    instruction5: 'Press Enter or Tab to add separate rating parameter',
    instruction6: 'Expected Candidate Response Language (Beta)',
    instruction7: 'This configuration set the expected language from the candidate response and perform speech to text accordingly. Do note that some scoring (eg: communication) will be disabled for languages other than English.',
    english: 'English',
    mandarin: 'Mandarin',
    polish: 'Polish',
    spanish: 'Spanish',
    bahasa: 'Bahasa (Indonesian)',
    french: 'French (France)',
    vietnamese: 'Vietnamese',
    german: 'German',
    country: 'Country',
    city: 'City',
    cognitiveAssessment: 'Congnitive Assessment',
    selectTime: 'Select Time',
    categoryName: 'Category Name',
    categoryDescription: 'Category Description',
    enterCtaegoryDescription: 'Enter category description',
    createCategory: 'Create Category',
    categoryNameInstruction: 'Name for this group of questions',
    listMore: 'List More +',
    listLess: 'List Less -',
    expired: 'Expiry',
    copyJobLink: ' Copy Job link',
    required: 'Required',
    'On-site': 'On-site', // Purposely kept key like this
    Remote: 'Remote', // Purposely kept key like this
    Hybrid: 'Hybrid', // Purposely kept key like this
    'Part Time': 'Part Time', // Purposely kept key like this
    'Full Time': 'Full Time', // Purposely kept key like this
    Internship: 'Internship', // Purposely kept key like this
    Contract: 'Contract', // Purposely kept key like this
    Training: 'Training', // Purposely kept key like this
    'Mock Interview': 'Mock Interview', // Purposely kept key like this
    applicantSopText: 'Is SOP (Statement Of Purpose) document required when applicant apply?',
    idealAnswer: 'Insert your Ideal Response',
  },
  introQuestions: {
    introQuestionsTitle: 'Additional Form Questions',
    addQuestions: 'Add Questions',
    instruction1: 'Candidates can select multiple options',
    instruction2: 'Candidates can select only one option',
    edit: 'Edit',
    question: 'Question',
    instruction3: 'Enable to choose more than 1 option',
    notNow: 'Not Now',
    addYourQuestion: 'Add Your Question',
    randomize: 'Randomize',
    selectTime: 'Select Time',
    selectCategory: 'Select Category',
    videoQuestionBank: 'Video Question Bank',
    introQuestionBank: 'Additional Form Question Bank',
    generateQuestion: 'Generate Questions',
  },
  editInterview: {
    editInterviewTemplate: 'Edit Interview Template',
    interviewExpiry: 'Interview Expiry',
    modifyQuestions: 'Modify your questions and choose up to',
    withLimit: 'with limit of',
  },
  profileSlider: {
    logout: 'Logout',
    userProfile: 'User Profile',
    companyProfile: 'Company Profile',
    instituteProfile: 'Institute Profile',
    billing: 'Billing',
    integrations: 'Integration',
    notificationSettings: 'Notification Settings',
    promoCode: 'PromoCode',
    contactSupport: 'Contact Support',
    shareFeedback: 'Share Feedback',
    changePassword: 'Change Password',
    termsAndConditions: 'Terms and Conditions',
    help: 'Help',
    videoTutorial: 'Video Tutorial',
    howToCreateAnInterview: 'How to create an interview',
    howToAddQuestionsToAnInterview: 'How to add questions to an interview',
    howToInviteCandidates: 'How to Invite Candidates',
    howToShareCandidates: 'How to Share Candidates',
    aFeelOfTheDashboard: 'A feel of the Dashboard',
    reCalibrateAIMatrics: 'Re-calibrate your AI metrics',
    knowMoreAboutTalentPool: 'Know more about Talent Pool',
    addTeamMember: 'Add team members',
  },
  userProfile: {
    back: 'Back',
    editProfile: 'Edit Profile',
    profileImage: 'Profile Image',
    upload: 'Upload',
    maxImgSize: 'Max 500 x 500 Image size*',
    personalInfo: 'Personal Info',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    save: 'Save',
    saving: 'Saving',
    placeHolderFirstName: 'Enter first name',
    placeHolderLastName: 'Enter last name',
  },
  companyProfile: {
    back: 'Back',
    myCompany: 'My Company',
    myInstitute: 'My Institute',
    basicInformation: 'Basic Information',
    orgIntroVideo: 'Organization Introduction Video',
    choose: 'Choose',
    orgIntroVideoFormat: 'Upload Video (<30 MB, .mp4)',
    remove: '(Remove)',
    companyLogo: 'Company Logo',
    instituteLogo: 'Institute Logo',
    upload: 'Upload',
    businessInformation: 'Business Information',
    companyName: 'Company Name',
    instituteName: 'Institute Name',
    companyNamePlaceholder: 'Enter company name',
    yourCareerPage: 'Your Career Page',
    yourPageIs: 'Your career page is',
    public: 'public',
    private: 'private',
    categories: 'Category *',
    industryType: 'Please select industry type.',
    description: 'Description',
    maxCharacter: '(max {{count}} characters)',
    siteNamePlaceHolder: 'Enter site name',
    createNew: 'Create New',
    subOrganisation: 'Sub Organisations',
    urlCopyurl: 'URL copied to clipboard',
    saveProfile: 'Save Profile',
    saving: 'Saving',
    hideEmploymentType: 'Hide "employment type" info in the candidate application form',
    hideLocation: 'Hide job "location" info in the candidate application form',
  },
  billingPage: {
    overall: 'Overall',
    byInterview: 'By Interview',
    heading: 'Now you are on',
    changePlan: 'Change Plan',
    particulars: 'Particulars',
    credits: 'Credits',
    totalCredits: 'Total Credits',
    to: 'to',
    used: 'Used',
    balance: 'Balance',
    numberOfLiveInterview: 'Number of live interviews',
    numberOfUser: 'Number of users',
    candidateResponse: 'Candidate Responses (Credits)',
    billingInformation: 'Billing Information',
    nextBillingDate: 'Next billing date',
    on: 'on',
    freeCredit: 'Free credits worth $100',
    continue: 'Continue',
    usePromoCode: 'Use our Promo code to get 20% off on any plan',
    copiedSuccess: 'Copied successfully',
    notApplicable: 'Not applicable',
    hiringIsSeasonal: 'Hiring is Seasonal and we get that',
    hiringDesc1: 'Explore our new annual credits-based pricing. Get access to unlimited jobs',
    hiringDesc2: 'and unlimited users when you purchase 100 credits or more.',
  },
  integrationPage: {
    calendayApiKey: 'CALENDLY API KEY',
    save: 'Save',
    zapierIntegration: 'ZAPIER INTEGRATION',
    beta: 'Beta',
    generate: 'Generate',
    whatZapier: 'WHAT CAN YOU DO WITH ZAPIER ?',
    placeHolderCalendly: 'Enter Calendly API',
    placeholderGenerateZapierKey: 'Click generate to generate zapier API key',
  },
  notificationPreference: {
    heading: 'Candidate Summary Email Notification Preference',
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
    save: 'Save',
  },
  resetPassword: {
    heading: 'Reset Your Password',
    enterEmailInstr: 'Enter your email address below and we\'ll send you a link to reset your password.',
    email: 'Email',
    sendResetLink: 'Send Reset Password Email',
    login: 'Login',
    resetting: 'Resetting...',
  },
  talentpool: {
    resume: 'Resume',
    viewResume: 'View Resume',
    'date applied': 'Date Applied',
    searchApplicants: 'Search Applicants',
    talentpoolInsights: 'Talent Pool Insights',
    errorOccured: 'An error has occurred. Please contact us for more details',
    reachOut: 'We\'ll reach out soon!',
    hi: 'Hi there!',
    contactUsMessage: `Tired of swapping through interviews to access your own candidates?

  Talent Pool feature makes it simple for you! Access all your candidates under one page with filters to narrow your search and find your interest.

  To gain access to this premium feature, please contact us for for details. Or click the button below for us to contact you via email!
  `,
    contactUsMessageCoaching: `Tired of swapping through interviews to access your own applicants?

  Talent Pool feature makes it simple for you! Access all your applicants under one page with filters to narrow your search and find your interest.

  To gain access to this premium feature, please contact us for for details. Or click the button below for us to contact you via email!
  `,
    contactUsTitle: 'Talent Pool',
    demographicData: 'Demographic Data',
    applicationsBtn: 'Application',
    usage: 'Usage',
    educationBackground: 'Education Background',
    emailPerformance: ' Email Performance',
    dataWithPhoneNumber: 'Data with phone number',
    appliedToMultipleRoles: ' Applied to multiple roles',
    timeToHire: 'Time to hire',
    totalLiveInterviews: '  Total Live Interviews',
    totalUsers: 'Total Users',
    allJobs: 'All Jobs',
    contactUsToUpgrade: 'Contact Us to Upgrade',
    contactUsFeature: 'Talent Pool Insights Feature',
    interested: 'I\'m Interested!',
    companyOverall: 'Company Overall',
    interviews: 'Interviews',
    applicants: 'Applicants',
    resumeScoring: 'Resume Scoring',
    workmapScoring: 'Workmap Scoring',
    videoScoring: 'Video Interview',
    shortlisted: 'Shortlisted',
    scheduled: 'Scheduled',
    rejected: 'Rejected',
    kiv: 'Kept In View',
    completedApplications: 'Completed Application',
    incompleteApplications: 'Incomplete Application',
    searchCandidatePlaceholder: 'Search Candidate',
    allJobTitle: 'All Job Titles',
    allLocations: 'All Locations',
    allStatus: 'All Status',
    allApplication: 'All Application',
    complete: 'Completed',
    inComplete: 'Incomplete',
    name: 'Name',
    jobTitle: 'Job Title',
    'job title': 'Job Title',
    company: 'Company',
    location: 'Location',
    interviewName: 'Interview Name',
    'interview name': 'Interview Name',
    status: 'Status',
    applications: 'Application',
    all: 'All',
    completed: 'Completed',
    incomplete: 'Incomplete',
    videoInterview: 'Video Interview',
    'Action Pending': 'Action Pending',
    Shortlist: 'Shortlisted',
    Rejected: 'Rejected',
    Kiv: 'Kiv',
    application: 'Status',
  },
  candidateDetails: {
    idealAnswer: 'Ideal Answer',
    idealAnsNotAvail: 'Ideal answer not available',
    idVerifyTrueText: 'ID Verification verified',
    idVerifyFalseText: 'ID Verification not verified',
    audioDetectedTrue: 'Audio detected',
    audioDetectedFalse: 'No audio detected',
    browserToggleTrue: 'Browser toggle detected',
    browserToggleFalse: 'No browser toggle detected',
    pastedAnsTrue: 'Pasted answer detected',
    pastedAnsFalse: 'No pasted answer detected',
    thirdPartyVoiceTrue: 'Third party voice detected',
    thirdPartyVoiceFalse: 'No third party voice detected',
    eyeContactTrue: 'Eye contact detected',
    eyeContactFalse: 'No eye contact detected',
    suspiciousObjectTrue: 'Suspicious object detected',
    suspiciousObjectFalse: 'No suspicious object detected',
    candidateNotViewed: 'Candidate not viewed',
    shareWith: 'Shared With',
    sendReportCoaching: 'Send Report to Applicant',
    applicantShortlisted: 'Applicant shortlisted',
    shortlistApplicant: 'Shortlist applicant',
    applicants: 'Applicants',
    searchApplicants: 'Search Applicants',
    selectMe: 'Select Me',
    selected: 'Selected',
    applicantSummary: 'Applicant Summary',
    all: 'All',
    candidates: 'Candidates',
    searchCandidates: 'Search Candidates',
    scoreSettings: 'Score Settings',
    resumeScore: 'Resume Score',
    workmapScore: 'Workmap Score',
    aiVideoScore: 'AI Video Score',
    professionalism: 'Professionalism',
    positiveAttitude: 'Positive Attitude',
    communication: 'Communication',
    sociability: 'Sociability',
    recommended: 'Recommended',
    contentRelevancy: 'Content Relevancy',
    reset: 'Reset',
    saving: 'Saving...',
    save: 'Save',
    score: 'Score',
    feedback: 'Feedback',
    new: 'New',
    unlockCandidate: 'Unlock this Candidate',
    scoreSettingsInfo: 'This allows you to control the weightage of Resume, WorkMap, and AI Video Score. Choose the weightage you want and it should add up to 100.',
    deleteCandidateInfo: 'The candidate has deleted their profile, so you can no longer access their information.',
    overallScore: 'Overall Score',
    overall: 'Overall',
    hired: 'Hired',
    hire: 'Hire Me',
    appliedDate: 'Applied Date',
    changeSchedule: 'Change Schedule',
    scheduleInterview: 'Schedule Interview',
    downloadReport: 'Download Report',
    shareProfile: 'Share this Profile',
    sendReport: 'Send Report to Candidate',
    candidateShortlisted: 'Candidate shortlisted',
    shortlistCandidate: 'Shortlist candidate',
    candidateKived: 'Candidate kived',
    kivCandidate: 'KIV candidate',
    keepInView: 'Keep in view',
    keptInView: 'Kept in view',
    candidateRejected: 'Candidate rejected',
    rejectCandidate: 'Reject candidate',
    rejected: 'Rejected',
    reject: 'Reject',
    inviteActivity: 'Invite Activity',
    interviewScheduledOn: ' Interview scheduled on',
    activity: 'Activity',
    contactedUser: 'Contacted User on',
    noQuestionsAttempted: 'No questions attempted',
    videoTranscript: 'Video Transcript',
    candidateSummary: 'Candidate Summary',
    insightsDisclaimer: 'Disclaimer: Please use the insights at your discretion to shortlist candidates for interviews or for making hiring calls.',
    skill: 'Skill',
    education: 'Education',
    experience: 'Experience',
    shareShortlist: 'Share Shortlist',
    inviteSent: 'Invite Sent',
    interviewVideo: 'Video Transcript',
    location: 'Location',
    email: 'Email',
    phone: 'Phone',
    linkedIn: 'Linkedin',
    includeEmail: 'Include an email',
    rejectCandidateInfo: 'Please confirm if you would like to reject the following candidate(s):',
    emailSubject: 'Email Subject',
    dear: 'Dear',
    rejectReason: 'Reject Reason',
    rejecting: 'Rejecting',
    emailPreview: 'Email Preview',
    selectEventType: 'Select Calendly Event Type',
    calendlyEventCreated: 'Ensure you have a Calendly event created or create one here',
    noCalendlyEventFound: 'No Calendly Event found',
    sender: 'Sender',
    editEmailSubject: 'Edit the Email Subject',
    checkEditEmailContent: 'Check and edit the Email Content',
    lookForwardMeetingYou: 'we look forward to meeting you soon',
    sending: 'Sending...',
    scheduleAndSend: 'Schedule and send',
    yesRejectCandidate: 'Yes, reject candidate',
    andSendEmail: ' and send email',
    yourFeedback: 'Your Feedback',
    feedbackInstruction1: 'Maximum characters: 5000',
    overallFeedback: 'Overall Feedback',
    hiringManager: 'Hiring manager',
    notes: 'Notes',
    status: 'Status',
    noSummary: 'No Summary',
    videoScoreTooltip: 'Interviewer.AI’s proprietary framework provides ratings for the candidates’ spoken words, voice, as well as facial expressions during the video interview. Candidates are given a score out of a hundred for each assessment parameter. These scores are aggregated into an overall score for their video interview. A higher score on the bar would indicate that the candidate fared better based on objective ratings of their behaviour on the video interview compared to video interviews taken on the platform as well as public datasets.',
    professionalismTooltip: 'It is measured from the applicant’s behavior (formal/informal) and looks at how the candidate presents himself/herself in the video. It also measures where eye contact is maintained during the video response, captures if there is any fidgeting at all. Yes, dressing formally would be advantageous.',
    positiveAttitudeTooltip: 'Attitude, as the name suggests, looks for sentiments along with a combination of energy and emotion. This model looks at a combination of metrics from communication and Sociability (for eg. confident, encouraged, positive)',
    communicationTooltip: 'This is primarily measuring the applicant’s clarity of speech. It also looks at verbal communication fluency and modulation of speech. Speaking clearly and with the right pace would be advantageous here. Variation in spoken pitch at appropriate times throughout the video would be beneficial.',
    sociabilityTooltip: 'This AI model is more focused on scoring applicant’s sentiments, using how the candidate communicates and shows positive interpersonal skills and tactfulness. It usually looks for positive sentiment words and energy levels of an applicant',
    contentRelevancyTooltip: 'The relevancy of the answer is displayed as a percentage to indicate the extent to which it addresses the question. A higher percentage indicates a higher level of relevancy, while a lower percentage suggests a potentially less relevant response. This information helps you assess the accuracy and suitability of the provided answer',
    paceAndClarityOfSpeech: 'Speech',
    articulation: 'Articulation',
    sentiments: 'Sentiments',
    emotion: 'Emotion',
    energyLevel: 'Energy Levels',
    dressing: 'Dressing',
    grooming: 'Grooming',
    bodyLanguage: 'Body Language',
    retakeCount: 'Attempts',
    grammarScore: 'Grammar',
  },
  scheduleInterview: {
    email: 'Email Preview',
    selectDateTimeDuration: '1. Select date time and duration',
    scheduleDescription: 'You can schedule an interview invite with candidate here.We will send an email to you and your candidate.',
    dateAndTime: 'Date and time',
    duration: 'Duration',
    sender: '2. Sender',
    editEmailSubject: ' 3. Edit the Email Subject',
    checkEditEmailContent: '4. Check and edit the Email Content',
  },
  candidateListing: {
    removeSelectedApplicants: 'Remove selected applicants',
    removeSelectedApplicantInfo: 'Are you sure you want to remove these applicants?',
    editApplicantDetail: 'Edit applicant details',
    unlockApplicant: 'Unlock this Applicant',
    kivApplicant: 'KIV Applicant',
    deleteApplicant: 'Delete applicant',
    copyApplicantInterviewLink: 'Copy Applicant interview link',
    editApplicant: 'Edit Applicant',
    applicantDateBetween: 'Applicants date Between',
    filterApplicants: 'Filter Applicants',
    selectToDeleteCoaching: 'Select applicants to delete',
    deleteSelectedCandidatesCoaching: 'Delete selected applicants',
    downloadApplicantDetails: 'Download applicant details',
    selectToRejectCoaching: 'Select applicants to Reject',
    rejectSelectedCoaching: 'Reject selected applicants',
    selectToShareCoaching: 'Select applicants to share',
    shareSelectedCoaching: 'Share selected applicants',
    addApplicant: 'Add New Applicant',
    addApplicantToInterview: 'Add the following applicant to the interview',
    enterCandidateName: 'Enter Candidate Name',
    enterApplicantName: 'Enter Applicant Name',
    enterCandidateEmail: 'Enter candidate’s email address',
    enterApplicanteEmail: 'Enter applicant’s email address',
    enterCandidateNumber: 'Enter candidate contact number',
    enterApplicantNumber: 'Enter applicant contact number',
    applicantsTo: 'Applicants',
    notifyApplicants: 'Notify Applicants',
    sendMessagesToMultipleApplicants: 'Send messages to multiple applicants from here.',
    searchApplicants: 'Seach Applicants',
    newApplicant: 'New Applicant',
    totalApplicants: 'Total Applicants',
    totalCandidates: 'Total candidates',
    yetToRespond: 'Yet To Respond',
    editCandidate: 'Edit Candidate',
    copyInterviewLink: 'Copy Candidate interview link',
    deleteCandidate: 'Delete candidate',
    editCandidateDetail: 'Edit candidate details',
    name: 'Name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    selectToDelete: 'Select candidates to delete',
    deleteSelectedCandidates: 'Delete selected candidates',
    selectToReject: 'Select candidates to Reject',
    rejectSelected: 'Reject selected candidates',
    selectToShare: 'Select candidates to share',
    shareSelected: 'Share selected candidates',
    newCandidate: 'New candidate',
    videoScore: 'Video Score',
    sendVideoInterviewLink: 'Send Video Interview Link',
    select: 'Select',
    selected: 'Selected',
    candidatesTo: 'Candidates',
    candidate: 'Candidate',
    applicant: 'Applicant',
    notify: 'Notify',
    importByCSV: 'Import By CSV',
    addCandidate: 'Add new candidate',
    addCandidateToInterview: 'Add the following candidate to the interview',
    enterContactNumber: 'Enter candidate contact number',
    removeSelectedCandidates: 'Remove selected candidates',
    removeSelectedCandidateInfo: 'Are you sure you want to remove these candidates?',
    noCandidatesInfo: 'No candidates yet. Send interview to candidates now!',
    notifyCandidate: 'Notify Candidates',
    sendMessagesToMultipleCandidates: 'Send messages to multiple candidates from here.',
    sendEmailsTo: 'Send emails to',
    emailPreviewInfo: 'Below is the preview of the email to be sent to candidates. You can change the area in yellow to better suit your messages.',
    emailPreviewInfo2: 'Kindly click on the link below and record your response',
    recordResponse: 'Record your response',
    lookForwardForResponse: 'We look forward to your response, best of luck!',
    filterCandidates: 'Filter Candidates',
    downloadCandidateDetails: 'Download candidate details',
    importCandidates: 'Import Candidates',
    fileName: 'File name',
    bulkImportCandidateInfo1: 'CSV content should include email, name and phone (optional). Can be in different order',
    actions: 'Actions',
    chooseFile: 'Choose File',
    addCandidates: 'Add candidates',
    candidateImportInfo1: 'No candidate data to be imported',
    duplicateEntry: 'Duplicate entry for email',
    duplicateEntryPhone: 'Duplicate entry for phone',
    duplicateEntryInfo: 'Duplicate entry for',
    inCSVsheet: 'in CSV sheet',
    alreadyExist: 'already exists in candidate list',
    enterValidName: 'Please enter valid name',
    enterValidEmail: 'Please enter valid email',
    enterValidPhone: 'Please enter valid phone',
    enterValid: 'Please enter valid',
    invalidEmail: 'CSV content contain invalid email',
    invalidPhone: 'CSV content contain invalid phone number',
    isMandatoryForAllCandidates: 'is mandatory for all candidates',
    csvContailsInvalid: 'CSV content contain invalid',
    csvImportInfo1: 'The file is not a CSV or excel file',
    csvImport2: 'CSV/Excel file must have name or email',
    columnInHeader: 'column in the header',
    eventId: 'Event ID',
    reactivateCandidateLink: 'Reactivate candidate link',
    reactivateApplicantLink: 'Reactivate applicant link',
  },
  insights: {
    candidateLocation: 'Candidate Location',
    genderDiversity: 'Gender Diversity',
    jobTitle: 'Job Title',
    colleges: 'Colleges',
    educationGraph: 'Education Graph',
    sourceOfApplication: 'Source of Application',
    resetMap: 'Reset Map',
    emailSentOpened: 'Emails Sent, Opened, and Responded',
    showingLatest: 'Showing 5 latest interviews',
    emptyGenderInfo: 'No gender info yet',
    insights: 'Insights',
    interested: 'I\'m Interested!',
    contactUs: 'Contact Us',
    noEmailEventInfo: 'No email events info yet',
    noSourceInfo: 'No source info yet',
  },
  footer: {
    copyRightText: 'Copyright © {{currentYear}} Interviewer.AI. All rights reserved.',
  },
  shareProfile: {
    shareThisProfile: 'Share This Profile',
    shareCandidates: 'Share Candidates',
    enterEmailSubject: 'Enter an email subject',
    selectedCandidate: 'Selected Candidate',
    listName: 'List Name',
    shareWith: 'Share with',
    info1: 'Press Enter to add separate emails',
    secureAccess: 'Secure Access',
    optional: 'optional',
    enterAccessCodeHere: 'Enter access code here',
    shareLink: 'Share Link',
    copyLink: 'Copy Link',
    note: 'Note',
    enterNote: 'Enter Note',
    undoKIV: 'Undo KIV',
    shortlist: 'Shortlist',
    undoShortlist: 'Undo Shortlist',
    undoReject: 'Undo Reject',
    feedbackInfo1: 'Share your views on this candidate e.g. schedule a meeting with me on...',
  },
  candidateFilter: {
    candidateDateBetween: 'Candidates date Between',
    selectStartDate: 'Select start date',
    selectEndDate: 'Select end date',
    kived: 'KIVed',
    filterKeyworkds: 'Filter Keywords in Caption/Resume',
    resetFilter: 'Reset filter',
    applyFilter: 'Apply Filter',
    keywordFilterInfo: 'Press Enter or Tab to add separate keyword',
  },
  emailers: {
    reminder: 'Enter your message here [Note: This will only send a message to the candidate. No interview links will be shared.]',
  },
  insightsPayWall: {
    coachingHeading: 'Unleash the Power of Data-driven Selection',
    coachingMessage: 'Unlock the hidden potential of your applicant screening process as we bring you a treasure trove of invaluable data and intelligent analytics. Dive into a world where every interview screening becomes an opportunity to gain deep insights into applicants, helping you make top-notch selection decisions every single time.',
    heading: 'Unleash the Power of Data-driven Hiring',
    message: 'Unlock the hidden potential of your applicant screening process as we bring you a treasure trove of invaluable data and intelligent analytics. Dive into a world where every interview screening becomes an opportunity to gain deep insights into candidates, helping you make top-notch hiring decisions every single time.',
    interested: 'I\'m Interested',
  },
  candidateFilters: {
    filterCandidates: 'Filter Candidates',
    applciationRecievedBetween: 'Applications Received Between',
    startDate: 'Start Date',
    endDate: 'End Date',
    location: 'Location',
    selectLocation: 'Select Location',
    candidateStatus: 'Candidate Status',
    selectStatus: 'Select Status',
    recruiterStatus: 'Recruiter Status',
    introQuestions: 'Intro Questions',
    selectOptions: 'Select Options',
    reset: 'Reset',
    applyFilter: 'Apply Filter',
    relevancyScore: 'Content Relevancy',
  },

  newPlansPage: {
    expiryMessage: 'Your Interviewer.AI account credits have expired. Buy credits to continue using our video interviews.',
    selectCredits: 'select credits',
    egText: 'E.g.: 500 credits = 500 video interviews',
    pricingMainText: 'View Pricing Options to Automate your Hiring',
    pricingMainSecondaryText: 'Unlock a range of features and benefits designed for you.',
    assessmentScoreIncHeading: 'What do candidate assessment scores include ?',
    resumeScore: 'Resume Score',
    assessmentScorePrimHeading: 'Interviewer.AI provides the most comprehensive, top of funnel recruitment screening solution that includes three basic components',
    resumeSectionPara1: 'Resume Score uses Optical Character Recognition (OCR) and deep natural language processing (NLP) algorithms to exact text from resumes and match it against the job Description.',
    resumeSectionPara2: 'The AI score replaces traditional keyword search (that recruiters largely employ), and replaces it with deep learning algorithms that understand the context behind every word in a resume using named entity recognition.',
    resumeSectionPara3: 'It gives you absolute AI scores, based on a database of 120M resumes.',
    workmapScore: 'Workmap Score',
    workmapSectionPara1: 'The WorkMap evaluates candidates skills and competencies based on specific roles they applied for.',
    workmapSectionPara2: 'The required competencies to perform certain work tasks varies across different industries, functions, or roles. With the WorkMap, candidates are assessed on critical competencies that will help them succeed at their roles in the future.',
    workmapSectionPara3: 'The score is based on a 5-point Agreeableness Likert scale containing 25-35 questions where each skill is assessed using 5-7 statements',
    aiVideoScore: 'AI Video Score',
    aiVideoSectionPara1: 'Interviewer.Al\'s proprietary framework provides ratings for the candidates spoken words, voice, as well as facial expressions during the video interview.',
    aiVideoSectionPara2: 'Candidates are given a score out of a hundred for each assessment parameter. These scores are aggregated into an overall score for their video interview.',
    aiVideoSectionPara3: 'A higher score on the bar would indicate that the candidate fared better based on objective ratings of their behaviour on the video interview based on public and on platform datasets.',
    quickPreviewText: 'Here is a quick preview',
    all: 'Plan',
    plans: 'Features',
    yourAiCredits: 'Your AI Interview Credits',
    myCurrentPlan: 'My Current Plan',
    active: 'Active',
    expDate: 'Expiry Date',
    addYourCred: 'Add Your Credits',
    addNewCred: 'Purchase Credits',
    videoInt: 'video interviews',
    availCred: 'Available Credits',
    addCred: 'Add Credits',
    newCred: 'New Credits',
    validOn: 'Valid through',
    price: 'Price',
    congText: 'Congratulations!',
    unlockPlan: 'You have unlocked the {{activeCurrentPlan}} Plan.',
    unlockEnterprisePlan: 'You qualify for our {{activeCurrentPlan}} Plan.',
    schedDemo: 'Schedule a Demo',
    buyNow: 'Buy Now',
    unlocked: 'Unlocked',
    starterPlanHeaderText: 'For limited hiring',
    growthPlanHeaderText: 'For accelerated hiring',
    premiumPlanHeaderText: 'For scaling teams',
    benifits: 'Benefits',
    userLicense: 'User License',
    admin: 'Admin',
    recuiter: 'Recruiter',
    accountAlloc: 'Account Allocations',
    noOfJobsInterviews: 'Number of Job Interviews',
    noOfAIInterAssessments: 'Number of AI Interview Assessments',
    generativeAiAssist: 'Generative AI Assistance',
    autoGenJobDes: 'Auto-Generated Job Description',
    candSummay: 'Candidate Summary',
    genAiQuesGeneration: 'Gen AI Question Generation',
    socialFeatures: 'Social Features',
    careerPage: 'Career Page',
    linkWithJobPost: 'Link with LinkedIn Job Posts',
    stackRankAlgo: 'Stack ranking Algorithm',
    resumeScoring: 'Resume Scoring',
    workmapAssess: 'WorkMap Assessments',
    scoreSettings: 'Score Settings',
    contentReleScore: 'Content Relevancy Score',
    contentReleScoreBreakdown: 'Content Relevancy Score Breakdown',
    support: 'Support',
    emailSupport: 'Email Support',
    dedicatedAccountManger: 'Dedicated Account Manager',
    expired: 'Expired',
    infoIconText: 'Credits = one video assessment',
  },
  expiryPlanModal: {
    firstMesssage: 'Your free credits will expire in',
    firstNonTrialMesssage: 'Your credits will expire in',
    day: 'day.',
    days: 'days.',
    secondMessage: 'Buy credits now before expiry and get 20% off on any plan.',
    thirdMessage: 'Use Code',
    fourthMessage: 'at checkout to avail this offer',
  },
  creditsAlertText: {
    message: 'You have exceeded the allocated credits in your account. To access the new candidate responses and assessments, please buy credits immediately.',
  },
};
