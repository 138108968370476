import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clientConfig from '../../config/config';
import styles from '../../css/ErrorBoundaryComponent.module.scss';
import { mxTrackEvent } from '../../utils/utils';
import { ReactComponent as ErrorIcon } from '../../assets/ErrorIcon.svg';

const ErrorComponent = ({ error }) => {
  useEffect(() => {
    const url = window.location.href;
    mxTrackEvent('Error boundary triggered', {
      message: error.message,
      userUrl: url,
    });
  }, []);
  return (
    <div className={styles.workMapSurvey}>
      <div className={styles.section}>
        <div className={styles.ErrorContent}>
          <div className={styles.errorDiv}>
            <div><ErrorIcon /></div>
            <span className={styles.errormessage1}>
              Whoops, something went wrong.
            </span>
            <span className={styles.errormessage2}>
              Please refresh the page or try to login again
            </span>
            <div className={styles.center}>
              <span className={styles.errormessage3}>
                If the issue persists, please get in touch
              </span>
              <a
                href="mailto:support@interviewer.ai"
                className={styles.mailText}
              >
                support@interviewer.ai
              </a>
            </div>
            <a
              className={styles.loginButton}
              type="button"
              href={`${clientConfig.default.DOMAIN}/logout`}
            >
              Login Again
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

ErrorComponent.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }).isRequired,
};

export default ErrorComponent;
