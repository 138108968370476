import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Glyphicon, Col } from 'react-bootstrap';

import '../css/Toast.css';

// Tips: to break message line, use \n

const SuccessToast = ({ msg, actionLink, actionLinkText }) => (
  <div>
    <Col xs={2}>
      <Glyphicon glyph="ok-circle" />
    </Col>
    <Col xs={10}>
      <span className="msg">{msg}</span>
      {
        actionLink && (
          <div>
            <a href={actionLink}>{actionLinkText}</a>
          </div>
        )
      }
    </Col>
  </div>
);

const AlertToast = ({ msg }) => (
  <div>
    <Col xs={2}>
      <Glyphicon glyph="exclamation-sign" />
    </Col>
    <Col xs={10}>
      <span className="msg">{msg}</span>
    </Col>
  </div>
);

SuccessToast.propTypes = {
  msg: PropTypes.string.isRequired,
  actionLink: PropTypes.string,
  actionLinkText: PropTypes.string,
};

SuccessToast.defaultProps = {
  actionLink: '',
  actionLinkText: '',
};

AlertToast.propTypes = {
  msg: PropTypes.string.isRequired,
};

export const successToast = (msg, action) => toast(
  <SuccessToast
    msg={msg}
    actionLink={action ? action.link : ''}
    actionLinkText={action ? action.text : ''}
  />, {
    className: 'successToast',
    bodyClassName: 'successToastBody',
    progressClassName: 'successToastProgress',
  },
);

export const alertToast = (msg, props) => toast(<AlertToast msg={msg} />, {
  className: 'alertToast',
  bodyClassName: 'alertToastBody',
  progressClassName: 'alertToastProgress',
  ...props,
});
