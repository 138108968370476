import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import CandidatesDetailsAIA from './pages/Candidates/CandidateDetailsAIA';

import './css/App.css';
import 'react-toastify/dist/ReactToastify.min.css';


const AppAIA = ({ match }) => (
  (
    <div className="App">
      <CandidatesDetailsAIA
        interviewId={match.params.interviewId}
        candidateId={match.params.candidateId}
      />
      <ToastContainer autoClose={2000} />
    </div>
  )
);

AppAIA.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      interviewId: PropTypes.string.isRequired,
      candidateId: PropTypes.string.isRequired,
    }).isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
};


export default AppAIA;
