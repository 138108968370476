import React, { useState, useEffect } from 'react';
import {
  Grid,
  Table,
  Button,
  Modal,
  ControlLabel,
  Glyphicon,
  ButtonToolbar,
  DropdownButton,
  MenuItem,
} from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/vi';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { useTranslation } from 'react-i18next';

import OrganizationStore from '../../stores/OrganizationStore';
import OrganizationActions from '../../actions/OrganizationActions';
import MultivalueInput from '../../components/MultivalueInput';
import { isEmailValid } from '../../utils/utils';
import ClipLoadingComponent from '../../components/ClipLoadingComponent';
import LoadingComponent from '../../components/LoadingComponent';
import PlanDetails from '../../components/PlanDetails';
import Auth from '../../auth/Auth';
import { successToast, alertToast } from '../../components/Toast';
import ContactUs from '../ContactUs';
import More from '../../assets/More.svg';
import searchIcon from '../../assets/fi_search.svg';

import '../../css/TeamMembers.css';
import styles from '../../css/TeamMembers.module.scss';

const membersContactUsPageProps = {
  contactUsMessage: `Team management and members invitation is an extended feature. 
  Please contact us for for details. 
  Or click the button below for us to contact you via email!`,
  contactUsTitle: 'Team',
  contactUsFeature: 'Organization Management Feature',
};

const TeamMembers = () => {
  const { t } = useTranslation();
  const currentUserId = Auth.getProfileInfo('userId');
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDeleteModal, setShowDeleteModal] = useState(false);
  const [showConfirmUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showConfirmResendModal, setShowResendModal] = useState(false);
  const [members, setMembers] = useState(OrganizationStore.getState().teamMembers);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [organization, setOrganization] = useState({ companyName: '', companyLogoUrl: '' });
  const [userRole, setUserRole] = useState(
    OrganizationStore.getState().userRole,
  ); // by default, user is member
  const [adminLimit, setAdminLimit] = useState(1);
  const [memberLimit, setMemberLimit] = useState(1);
  const [subscription, setSubscription] = useState();
  const [isSuperadminUser, setIsSuperadminUser] = useState(OrganizationStore.getState().isSuperadmin); // eslint-disable-line
  const [userLanguage, setLanguage] = useState(OrganizationStore.getState().userLanguage); // eslint-disable-line

  const onChange = (storeData) => {
    setSubscription(storeData.subscription);
    setMembers(storeData.teamMembers);
    setAdminLimit(storeData.adminLimit);
    setMemberLimit(storeData.memberLimit);
    setOrganization({
      companyName: storeData.companyName,
      companyLogoUrl: storeData.companyLogoUrl,
    });
    setIsLoading(storeData.isFetchingMembers
      || storeData.isDeletingMember
      || storeData.isUpdatingMemberRole
      || storeData.isResendingInvite);
    setUserRole(storeData.userRole);
    setIsSuperadminUser(storeData.isSuperadmin);
    setLanguage(storeData.userLanguage);
    moment.locale(userLanguage === 'en-US' ? 'en' : userLanguage || 'en');
    // if (storeData.userLanguage === 'vi') {
    //   console.log('VI');
    //   moment.locale('vi');
    // } else {
    //   console.log('EN-US');
    //   moment.locale('en');
    // }
  };

  useEffect(() => {
    OrganizationStore.listen(onChange);
    const fetchInfo = async () => {
      setIsPageLoading(true);
      OrganizationActions.fetchTeamMembers.defer();
      OrganizationActions.getUserRole.defer();
      OrganizationActions.fetchSubscription.defer();
      setTimeout(() => setIsPageLoading(false), 2000); // hack to show loading animation
    };
    fetchInfo();
    return () => {
      OrganizationStore.unlisten(onChange);
    };
  }, [userLanguage]);

  const handleDeleteUser = () => {
    OrganizationActions.deleteTeamMember(members[selectedMember]);
    setShowDeleteModal(false);
  };

  const handleUpgradeUser = () => {
    const member = members[selectedMember];
    // Unable to upgrade user if they are inactive or already an admin
    if (member.role === 'admin' || member.status !== 'ACTIVE') {
      return;
    }

    const totalAdmin = members.filter(m => m.role === 'admin').length;
    if (totalAdmin === adminLimit) {
      alertToast('Exceeded number of admin count in your organization.');
      return;
    }

    OrganizationActions.updateMemberRole(member, 'admin');
    setShowUpgradeModal(false);
  };

  const handleResendInvite = () => {
    const member = members[selectedMember];
    OrganizationActions.resendMemberInvite(member);
    setShowResendModal(false);
  };

  const handleShowDeleteModal = (index) => {
    const member = members[index];
    if (member.userId === currentUserId) {
      return;
    }
    setSelectedMember(index);
    setShowDeleteModal(true);
  };

  const handleShowUpgradeModal = (index) => {
    const member = members[index];
    if (member.role === 'admin' || member.status !== 'ACTIVE') {
      return;
    }
    setSelectedMember(index);
    setShowUpgradeModal(true);
  };

  const handleShowResendModal = (index) => {
    const member = members[index];
    if (member.userId === currentUserId) {
      return;
    }
    setSelectedMember(index);
    setShowResendModal(true);
  };

  const handleSearchTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleDateFormat = (d) => {
    const separeted = d.split(',');
    const first = separeted[0].split(' ');
    return `${first.reverse().join(' ')} ${separeted[1] || ''}`;
  };

  if (isPageLoading) {
    return (
      <LoadingComponent />
    );
  }

  if (userRole === 'user' && members.length <= 1) {
    return <ContactUs {...membersContactUsPageProps} />;
  }

  const mappedMembers = members.filter(m => m.email.search(searchText) >= 0).map(
    (member, index) => (
      <tr className={styles.row} key={member.email}>
        <td className={styles.flexContainerCenter}>
          {
            member.userLogo ? (
              <img
                src={member.userLogo}
                alt={member.firstName}
                className="navProfileImage"
              />
            ) : (
              <div className={styles.nameLogo}>
                <span>
                  {member.firstName ? member.firstName.charAt(0).toUpperCase() : ''}
                  {member.lastName ? member.lastName.charAt(0).toUpperCase() : ''}
                </span>
              </div>
            )
          }
          <span className={styles.fullName}>
            {member.firstName}
            &nbsp;
            {member.lastName}
          </span>
        </td>
        <td>{member.email}</td>
        {userRole === 'admin' && <td style={{ textTransform: 'capitalize' }}>{member.role}</td>}
        <td>
          <Switch
            onColor="#6196FE"
            checkedIcon={false}
            uncheckedIcon={false}
            height={18}
            width={30}
            checked={member.status === 'ACTIVE'}
            disabled
          />
        </td>
        <td>{member.createdOn ? handleDateFormat(moment(member.createdOn).format('ll')) : '-'}</td>
        {userRole === 'admin' && (
          <td>
            <ButtonToolbar className="pull-right dropdownMenu">
              <DropdownButton
                noCaret
                pullRight
                bsStyle="default"
                id={`member-actions-${member.email}`}
                title={<img src={More} className="pull-right" alt="More function" />}
              >
                <MenuItem
                  eventKey="invite-candidates-item"
                  className={styles.menuItem}
                  onClick={() => handleShowUpgradeModal(index)}
                  disabled={member.role === 'admin' || member.status !== 'ACTIVE'}
                >
                  {t('userList.upgradeToAdmin')}
                </MenuItem>
                {member.status === 'PENDING'
                  && (
                    <MenuItem
                      eventKey="invite-candidates-item"
                      className={styles.menuItem}
                      onClick={() => handleShowResendModal(index)}
                      disabled={member.userId === currentUserId}
                    >
                      {t('userList.resendInvite')}
                    </MenuItem>
                  )
                }
                <MenuItem
                  eventKey="invite-candidates-item"
                  className={styles.menuItem}
                  onClick={() => handleShowDeleteModal(index)}
                  disabled={member.userId === currentUserId}
                >
                  {t('userList.removeUser')}
                </MenuItem>
              </DropdownButton>
            </ButtonToolbar>
          </td>
        )}
      </tr>
    ),
  );

  const currentEmailsList = members.map(m => m.email);
  const selectedUserEmail = members[selectedMember] ? members[selectedMember].email : '';

  return (
    <div>
      <div className={styles.header}>
        <Grid fluid>
          <h3>{t('userList.team')}</h3>
        </Grid>
      </div>
      <Grid>
        <div className={styles.flexContainerRoot}>
          <div className={styles.content}>
            <div className={styles.actionToolbar}>
              <div className={styles.flexContainerCenter}>
                <img
                  src={searchIcon}
                  className={styles.searchIcon}
                  alt="search members"
                />
                <input
                  className={styles.searchInput}
                  type="text"
                  placeholder={t('userList.searchMember')}
                  value={searchText}
                  onChange={handleSearchTextChange}
                />
              </div>
              {userRole === 'admin' && (
                <Button
                  className={`btn btn-primary-custom ${styles.mainButton}`}
                  onClick={() => setShowModal(true)}
                  bsClass="btn-primary-custom"
                >
                  +
                  {' '}
                  {t('userList.inviteMember')}
                </Button>
              )}
            </div>
            <hr className={styles.whiteBlock} />
            <div className={styles.companyFlexContainer}>
              <img
                alt="interviewer organization"
                className={styles.organizationName}
                src={organization.companyLogoUrl}
              />
              <h2 className={styles.companyText}>{organization.companyName}</h2>
            </div>
            <InviteModal
              show={showModal}
              onHide={() => setShowModal(false)}
              refresh={OrganizationActions.fetchTeamMembers}
              currentEmailsList={currentEmailsList}
              companyName={organization.companyName}
              companyLogoUrl={organization.companyLogoUrl}
              memberLimit={memberLimit}
            />
            <ConfirmDeleteModal
              isLoading={isLoading}
              show={showConfirmDeleteModal}
              onHide={() => setShowDeleteModal(false)}
              onSubmit={handleDeleteUser}
              email={selectedUserEmail}
            />
            <ConfirmResendModal
              isLoading={isLoading}
              show={showConfirmResendModal}
              onHide={() => setShowResendModal(false)}
              onSubmit={handleResendInvite}
              email={selectedUserEmail}
            />
            <ConfirmUserUpgrade
              isLoading={isLoading}
              show={showConfirmUpgradeModal}
              onHide={() => setShowUpgradeModal(false)}
              onSubmit={handleUpgradeUser}
              email={selectedUserEmail}
            />
            {members.length > 0 && (
              <Table responsive className={styles.table}>
                <thead className={styles.tableHeader}>
                  <tr>
                    <th>{t('userList.members')}</th>
                    <th>{t('userList.email')}</th>
                    {userRole === 'admin' && (<th>{t('userList.role')}</th>)}
                    <th>{t('userList.status')}</th>
                    <th>{t('userList.joined')}</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {mappedMembers}
                </tbody>
              </Table>
            )}
            {members.length === 0 && !isLoading
              && (
                <div className={styles.loading}>
                  {t('userList.noMemberFound')}
                </div>
              )}
            {isLoading && (
              <div className={styles.loading}>
                <ClipLoadingComponent />
              </div>
            )}
          </div>
          <PlanDetails
            subscription={subscription}
            adminLimit={adminLimit}
            teamMembers={members}
            memberLimit={memberLimit}
          />
        </div>
      </Grid>
    </div>
  );
};

const ConfirmDeleteModal = ({
  show, onHide, onSubmit, email, isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <p className={styles.title}>
            <Glyphicon glyph="alert" />
            <span className={styles.titleText}>
              {t('userList.remove')}
              {' '}
              {email}
            </span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('userList.removeUserMessage')}
        {' '}
        <b>{email}</b>
        {' '}
        {t('userList.eraseAccessMessage')}
        <i>
          {t('userList.irreversibleMessage')}
        </i>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-default-custom" onClick={onHide}>
          {t('userList.cancel')}
        </Button>
        <Button
          className="btn btn-primary-custom"
          onClick={onSubmit}
          bsClass="btn-primary-custom"
          disabled={isLoading}
        >
          {t('userList.confirm')}
          {
            isLoading && <ClipLoadingComponent inverse />
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};


const ConfirmResendModal = ({
  show, onHide, onSubmit, email, isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <p className={styles.title}>
            <Glyphicon glyph="alert" />
            <span className={styles.titleText}>
              {t('userList.resendInviteTo')}
              {' '}
              {email}
            </span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('userList.resendInvitation')}
        {' '}
        <b>{email}</b>
        {' '}
        {t('userList.fromOrganization')}
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-default-custom" onClick={onHide}>
          {t('userList.cancel')}
        </Button>
        <Button
          className="btn btn-primary-custom"
          onClick={onSubmit}
          bsClass="btn-primary-custom"
          disabled={isLoading}
        >
          {t('userList.confirm')}
          {
            isLoading && <ClipLoadingComponent inverse />
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ConfirmUserUpgrade = ({
  show, onHide, onSubmit, email, isLoading,
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header>
        <Modal.Title>
          <p className={styles.title}>
            <Glyphicon glyph="info-sign" />
            <span className={styles.titleText}>
              {t('userList.upgrade')}
              {' '}
              {email}
              {' '}
              {t('userList.toAdmin')}
            </span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {t('userList.areSureYouWantToAssignPermissionTo')}
        {' '}
        <b>{email}</b>
        {' '}
        {t('userList.inOrganization')}
        <br />
        <i>{t('userList.adminUserMessage')}</i>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-default-custom" onClick={onHide}>
          {t('userList.cancel')}
        </Button>
        <Button
          className="btn btn-primary-custom"
          onClick={onSubmit}
          bsClass="btn-primary-custom"
          disabled={isLoading}
        >
          {t('userList.confirm')}
          {
            isLoading && <ClipLoadingComponent inverse />
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const InviteModal = ({
  show, onHide, refresh, currentEmailsList, companyName, companyLogoUrl,
  memberLimit,
}) => {
  const { t } = useTranslation();
  const userEmail = Auth.getProfileInfo('email');
  const companyDomain = userEmail ? userEmail.split('@')[1] : '';
  const [emails, setEmails] = useState([]);
  const [emailsError, setEmailsError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const isSuperadminUser = OrganizationStore.getState().isSuperadmin;

  const totalMembers = currentEmailsList.length + emails.length;

  const handleSendInvites = async () => {
    setIsSending(true);
    const req = await fetch('/api/organization/members-invite', {
      headers: {
        Authorization: `Bearer ${Auth.getIdToken()}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        emails,
        createdBy: Auth.getProfileInfo('userId'),
        companyName,
        companyLogoUrl,
      }),
    });
    if (req.status >= 200 && req.status < 300) {
      successToast(`Successfully sent invites to ${emails.length} emails`);
      refresh();
      onHide();
    } else {
      alertToast('Error sending invitations, please try again later');
    }

    setEmails([]);
    setEmailsError(false);
    setIsSending(false);
  };

  const handleHide = () => {
    setEmails([]);
    setEmailsError(false);
    onHide();
  };

  const onEmailsChange = (emailsList) => {
    if ((currentEmailsList.length + emailsList.length) > memberLimit) {
      setEmailsError(t('userList.memberLimitExceededMessage'));
    } else if (emailsList.filter(email => !isEmailValid(email)).length > 0) {
      setEmailsError(t('userList.incorrectEmailMessage'));
    } else if (!isSuperadminUser && emailsList.filter(email => (email.split('@')[1].toLowerCase()) !== companyDomain.toLowerCase()).length > 0) {
      setEmailsError(t('userList.sameCompanyDomainMessage'));
    } else if (emailsList.filter(email => currentEmailsList.includes(email)).length > 0) {
      setEmailsError(`${emailsList[emailsList.length - 1]} ${t('userList.hasAlreadyInvited')}`);
    } else {
      setEmails(emailsList);
      setEmailsError(false);
    }
  };
  return (
    <Modal
      className="add-team-member-modal"
      show={show}
      onHide={handleHide}
    >
      <Modal.Header>
        <Modal.Title>
          <p className={styles.title}>
            <Glyphicon glyph="envelope" />
            <span className={styles.titleText}>{t('userList.inviteNewUsers')}</span>
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={styles.formContainer}>
          <p className={styles.inviteLabel}>
            <ControlLabel>{t('userList.emailAddresses')}</ControlLabel>
            <span className={styles.totalMembersCount}>
              {t('userList.organizationMembers')}
              :
              {' '}
              {totalMembers}
              /
              {memberLimit}
            </span>
          </p>
          <MultivalueInput
            values={emails}
            error={emailsError}
            placeholder={t('userList.addEmailAddress')}
            onValuesChange={onEmailsChange}
          />
        </div>
        <div className={styles.text}>
          {t('userList.addEmail')}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-default-custom" onClick={onHide}>
          {t('userList.cancel')}
        </Button>
        <Button
          className="btn btn-primary-custom"
          onClick={handleSendInvites}
          disabled={emails.length < 1 || isSending}
          bsClass="btn-primary-custom"
        >
          {t('userList.share')}
          {
            isSending && <ClipLoadingComponent inverse />
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmDeleteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConfirmDeleteModal.defaultProps = {
  email: '',
  isLoading: false,
};

ConfirmUserUpgrade.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConfirmUserUpgrade.defaultProps = {
  email: '',
  isLoading: false,
};

ConfirmResendModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoading: PropTypes.bool,
};

ConfirmResendModal.defaultProps = {
  email: '',
  isLoading: false,
};

InviteModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  refresh: PropTypes.func,
  currentEmailsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  companyName: PropTypes.string.isRequired,
  companyLogoUrl: PropTypes.string.isRequired,
  memberLimit: PropTypes.number.isRequired,
};

InviteModal.defaultProps = {
  refresh: () => { },
};

export default TeamMembers;
